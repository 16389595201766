import React from 'react'

import PropTypes from 'prop-types'

import { DATA_TESTID, EXIT_LESSON_TEXT } from './constants'
import { ExitLessonButton } from './style'

const ExitLessonComponent = ({ onClick }) => {
  return (
    <ExitLessonButton data-testid={DATA_TESTID} onClick={onClick}>
      {EXIT_LESSON_TEXT}
    </ExitLessonButton>
  )
}

ExitLessonComponent.propTypes = {
  onClick: PropTypes.func,
}

ExitLessonComponent.defaultProps = {
  onClick() {},
}

export default ExitLessonComponent
