import React, { useEffect } from 'react'

import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { dragWordInitialize } from '../../lib/state/actions/player'
import {
  BUTTON_STATES,
  DRAG_AND_DROP_TYPES,
} from '../../utils/dragAndDropHelper'
import LayoutTitle from '../LayoutTitle'
import Text from '../modules/Text'

import DragWordComponent from './component'
import { DragWordStyleContainer } from './style'

const DragWordContainer = props => {
  const {
    id,
    title,
    question: { task: { dropZones = [], elements = [] } = {} } = {},
  } = props
  const dispatch = useDispatch()
  const shouldInitialize = useSelector(
    ({ player }) => !player.interactiveStates[id],
  )

  useEffect(() => {
    shouldInitialize && dispatch(dragWordInitialize(id, defaultState))
  }, [])

  const draggables = elements
    .filter(
      draggable =>
        draggable.type.metadata.title !== 'BodyText' &&
        draggable.type.metadata.extraTitle !== 'BodyText',
    )
    .map((draggable, index) => ({
      dropped: false,
      id: draggable.type.subContentId,
      index: index,
      interactiveType: DRAG_AND_DROP_TYPES.DRAG_WORD,
      label: draggable.type.params.text,
      type: 'DraggableItem',
      zones: draggable.dropZones.map(zone => parseInt(zone)),
    }))
  const questions = dropZones.map((zone, index) => ({
    correct: false,
    draggable: null,
    graded: false,
    id: index,
    label: zone.label,
  }))

  const defaultState = {
    buttons: BUTTON_STATES.ON_RESET,
    draggables,
    isErrorVisible: false,
    questions,
  }

  const bodyText = elements.find(
    el =>
      el.type.metadata.title === 'BodyText' ||
      el.type.metadata.extraTitle === 'BodyText',
  ) || { type: { params: {} } }

  const parsedTitle = title && title.split('::')[0]

  return (
    <DragWordStyleContainer>
      <DragWordComponent id={id}>
        <LayoutTitle title={parsedTitle} />
        <Text content={bodyText.type.params.text} />
      </DragWordComponent>
    </DragWordStyleContainer>
  )
}

DragWordContainer.propTypes = {
  id: PropTypes.string,
  question: PropTypes.object,
  title: PropTypes.string,
}

export default DragWordContainer
