import { LOCALES } from 'utils/locale'

export const TEXT_LESSON_COMPLETE = {
  [LOCALES.EN_US]: 'Lesson Complete',
  [LOCALES.ES_US]: 'Lección completa',
}
export const TEXT_MARK_LESSON_DONE = {
  [LOCALES.EN_US]: 'MARK LESSON DONE',
  [LOCALES.ES_US]: 'MARCAR LECCIÓN HECHA',
}
