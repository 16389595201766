import React from 'react'

import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

export const StyledButtonNavigation = styled.button`
  font-size: 1em;
  height: 2.1em;
  color: black;
  border: none;
  border-radius: ${props => props.theme.borderRadius};
  display: flex;
  padding: 0 2em;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  transition: background-color 0.2s ease-in, color 0.2s ease-in;

  &:hover:enabled {
    background-color: ${props => props.theme.colorVars.buttonNavigation.hover};
    color: ${props => props.theme.colorVars.buttonNavigation.hoverInner};
    path {
      fill: ${props => props.theme.colorVars.buttonNavigation.hoverInner};
    }
  }

  ${props =>
    props.disabled &&
    css`
      background-color: ${props =>
        props.theme.colorVars.buttonNavigation.disabled};
      cursor: default;
      &:hover:enabled {
        background-color: ${props =>
          props.theme.colorVars.buttonNavigation.disabled};
        color: black;
        path {
          fill: black;
        }
      }
    `}
`

export const LeftButton = styled(StyledButtonNavigation)`
  color: ${props => props.theme.colorVars.buttonNavigation.white};
  padding: 0.4em 1em 0.6em;
  margin-left: 1em;
  background-color: ${props => props.theme.colorVars.buttonNavigation.blue};
  height: 2.15em;
  outline: none;

  svg {
    top: 0;
    left: -1rem;
  }

  path {
    fill: ${props => props.theme.colorVars.buttonNavigation.blue};
    transition: fill 0.2s ease-in;
  }

  :focus:after {
    content: '';
    position: absolute;
    width: 125%;
    height: 105%;
    border: 3px groove ${props => props?.theme?.colorVars?.blueFocus};
    left: -20px;
    top: -4px;
    z-index: -1;
  }

  &:hover:enabled {
    background-color: ${props =>
      props.theme.colorVars.buttonNavigation.darkestBlue};
    path {
      fill: ${props => props.theme.colorVars.buttonNavigation.darkestBlue};
    }
  }

  ${props =>
    props.disabled &&
    css`
      background-color: ${props =>
        props.theme.colorVars.buttonNavigation.lightGrey};
      color: ${props => props.theme.colorVars.buttonNavigation.white};
      path {
        fill: ${props => props.theme.colorVars.buttonNavigation.lightGrey};
      }
      &:hover:enabled {
        background-color: ${props => props.theme.colorVars.lightGrey};
        path {
          fill: ${props => props.theme.colorVars.buttonNavigation.lightGrey};
        }
      }
    `}
`

export const RightButton = styled(LeftButton)`
  margin-left: 0;
  margin-right: 1em;
  svg {
    left: 96%;
  }
  :focus:after {
    left: -4px;
  }
`

export const ArrowStyle = styled.svg`
  height: 100%;
  width: 1em;
  padding-left: 1em;
`

export const ArrowEndStyle = styled.svg`
  height: 100%;
  position: absolute;
`

export const SpinStyle = styled.svg`
  width: 1.5em;
  height: 100%;
  padding-right: 0.8em;

  path {
    transition: fill 0.2s ease-in;
  }
`

export const ArrowIconSvg = props => {
  const { color = 'black' } = props
  return (
    <ArrowStyle
      {...props}
      viewBox="0 0 21 21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M2.474.04c-2.598 0-2.902 20.919 0 20.919s18.116-8.242 18.116-10.46S5.072.04 2.474.04z"
          fill={color}
        />
      </g>
    </ArrowStyle>
  )
}

ArrowIconSvg.propTypes = {
  color: PropTypes.string,
}

export const ArrowEndLeftSvg = props => (
  <ArrowEndStyle
    {...props}
    viewBox="0 0 18 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.66 0H18v32h-2.34a3 3 0 0 1-2.185-.945l-12.225-13a3 3 0 0 1 0-4.11l12.225-13A3 3 0 0 1 15.66 0z"
      fillRule="nonzero"
    />
  </ArrowEndStyle>
)

export const ArrowEndRightSvg = props => (
  <ArrowEndStyle
    {...props}
    viewBox="0 0 17 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        stroke="null"
        transform="rotate(-180 7.7821855545043945,16) "
        id="svg_1"
        d="m14.224371,0l2.34,0l0,32l-2.34,0a3,3 0 0 1 -2.185,-0.945l-12.225,-13a3,3 0 0 1 0,-4.11l12.225,-13a3,3 0 0 1 2.185,-0.945z"
      />
    </g>
  </ArrowEndStyle>
)

export const SpinSvg = props => {
  const { color = 'black' } = props
  return (
    <SpinStyle
      {...props}
      viewBox="0 0 23 20"
      width="23"
      height="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.764 4.285c-.174-.241-.379-.52-.678-.926a67.568 67.568 0 0 1-1.055-1.456l-.128-.184.976-1.465.28.357c.921 1.172 1.607 2.108 3.11 4.212l.136.191-.136.191a104.07 104.07 0 0 1-1.555 2.106 103.58 103.58 0 0 0-1.545 2.094l-.277.388-.265-.397a3.788 3.788 0 0 0-.188-.256 27.05 27.05 0 0 1-.501-.629l-.148-.197 1.553-2.071H5.801c-1.626 0-2.9 1.377-3.275 3.62-.287 1.914.394 3.874 1.622 4.757.35.175.497.533.382.943v1.383l-.476-.238c-.992-.496-1.655-1.06-2.144-1.94-1.073-2.039-1.33-4.126-.723-6.354.74-2.324 2.034-3.697 3.91-4.112.182-.06.343-.072.537-.053l.135.017c.11.014.166.019.232.019h9.763zm2.137 10c1.912 0 3.364-1.955 3.272-4.552-.092-1.563-.719-2.791-1.72-3.525-.325-.163-.381-.33-.381-.794 0-.1-.011-.189-.046-.396a2.785 2.785 0 0 1-.054-.504v-.506l.463.205c.913.406 1.665 1.107 2.352 2.138 2.1 3.675.425 9.033-3.082 9.775a2.222 2.222 0 0 1-.527.103 5.235 5.235 0 0 1-.477.014H8.23c.158.219.33.447.531.707l.3.383c.3.386.435.559.603.784l.14.186-.13.193c-.313.47-.452.662-.64.85l-.263.263-.229-.292c-.458-.584-.813-1.038-1.6-2.051a481.37 481.37 0 0 0-1.6-2.049l-.158-.202 3.537-4.564.985 1.478-1.832 2.356H17.9z"
        fill={color}
        fillRule="nonzero"
      />
    </SpinStyle>
  )
}

SpinSvg.propTypes = {
  color: PropTypes.string,
}
