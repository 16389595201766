import React from 'react'
import PropTypes from 'prop-types'
import StyledBaseModal from './component.styles'

const Modal = props => {
  const {
    children,
    className,
    dataTestId,
    isModalOpen,
    modalClose,
    contentLabel,
    overlayBgColor,
  } = props
  return (
    <StyledBaseModal
      className={className}
      contentLabel={contentLabel}
      dataTestId={dataTestId}
      isModalOpen={isModalOpen}
      modalClose={modalClose}
      overlayBgColor={overlayBgColor}
    >
      {children}
    </StyledBaseModal>
  )
}

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
  contentLabel: PropTypes.string,
  dataTestId: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool,
  modalClose: PropTypes.func,
}

export default Modal
