import React from 'react'

import PropTypes from 'prop-types'

import {
  DATA_TEST_ID,
  FEEDBACK_LINK_DATA_TEST_ID,
  NEW_WINDOW,
} from './constants'
import { Link } from './style'


const LinkButton = props => {
  const { altText, buttonText, id, url } = props
  const testId = [
    DATA_TEST_ID,
    (id || '').replace(' ', '-').toLowerCase(),
  ].join('-')

  let conditionalProps = {}
  let dataTestId = FEEDBACK_LINK_DATA_TEST_ID

  if (url) {
    dataTestId = testId

    conditionalProps = {
      href: url,
      target: NEW_WINDOW,
    }
  }

  if (buttonText) {
    return (
      <Link
        aria-label={altText}
        data-testid={dataTestId}
        title={altText}
        {...conditionalProps}
      >
        {buttonText}
      </Link>
    )
  }

  return null
}

LinkButton.propTypes = {
  altText: PropTypes.string,
  buttonText: PropTypes.string,
  id: PropTypes.string,
  url: PropTypes.string,
}

export default LinkButton
