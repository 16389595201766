/* istanbul ignore file */
// we don't unit test SVGs
import React from 'react'
import { string } from 'prop-types'

const ReportingDone = ({ fill, ...rest }) => (
  <svg className="done-icon" {...rest}>
    <title>ss_reporting_donecheck</title>
    <g
      fill="none"
      fillRule="evenodd"
      id="ss_reporting_donecheck"
      stroke="none"
      strokeWidth="1"
    >
      <circle cx="10" cy="10" fill="#00BC82" id="Oval" r="10" />
      <polyline
        id="Path-21"
        points="5.54224134 10 9.49795227 14.2186392 14.7802225 7"
        stroke="#FFFFFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
)
ReportingDone.defaultProps = {
  height: '20px',
  width: '20px',
  fill: 'none',
  viewBox: '0 0 20 20',
}
ReportingDone.propTypes = {
  fill: string,
  height: string,
  viewBox: string,
  width: string,
}
export default ReportingDone

/* istanbul ignore file */
// we don't unit test SVGs
// import React from 'react'
// import { string } from 'prop-types'

// const ReportingDone = ({ fill, ...rest }) => (
//   <svg className="right-arrow-icon" {...rest}>
//     <title>ss_platform_sidepanelhoverarrow</title>
//     <g
//       fill="none"
//       fillRule="evenodd"
//       id="ss_platform_sidepanelhoverarrow"
//       stroke="none"
//       strokeLinecap="round"
//       strokeLinejoin="round"
//       strokeWidth="1"
//     >
//       <g
//         id="Group-3"
//         stroke={fill}
//         strokeWidth="1.5"
//         transform="translate(8.500000, 6.000000) rotate(-270.000000) translate(-8.500000, -6.000000) translate(3.500000, -1.500000)"
//       >
//         <line
//           id="Path-2"
//           x1="4.55900669"
//           x2="4.55900669"
//           y1="1.50199003"
//           y2="15"
//         />
//         <polyline
//           id="Path-3"
//           points="-5.32907052e-16 5.07161093 4.61240598 9.05941988e-15 9.22481196 5.07161093"
//         />
//       </g>
//     </g>
//   </svg>
// )
// ReportingDone.defaultProps = {
//   height: '11px',
//   width: '17px',
//   fill: 'currentColor',
//   viewBox: '0 0 17 11',
// }
// ReportingDone.propTypes = {
//   fill: string,
//   height: string,
//   viewBox: string,
//   width: string,
// }

// export default ReportingDone
