import React from 'react'

import Navigation from '../Navigation'
import Progress from '../Progress'

import { Wrapper } from './style'

const Menu = () => (
  <Wrapper>
    <Progress />
    <Navigation />
  </Wrapper>
)

export default Menu
