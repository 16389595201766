import React, { useEffect, useRef } from 'react'

import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { toggleIdle } from '../../lib/state/actions/player'
import { PLAYER_MULTIMEDIA_STATUS } from '../../lib/state/constants'
import { playerMultimediaStatus } from '../../lib/state/selectors'
import { getPlayerSessionTimeManager } from '../../utils/sessionHelper'

import IdleTimer from './IdleTimer'

const TimeoutGuardContainer = ({ timeout }) => {
  const dispatch = useDispatch()
  const idleTimerRef = useRef(null)
  const multiMediaStatus = useSelector(playerMultimediaStatus)

  const setPlayerIdle = () => {
    dispatch(toggleIdle(true))
    const sessionManager = getPlayerSessionTimeManager()
    if (sessionManager) {
      sessionManager.pausePoll()
    }
  }

  const setPlayerActive = () => {
    dispatch(toggleIdle(false))
    const sessionManager = getPlayerSessionTimeManager()
    if (sessionManager) {
      sessionManager.resumePoll()
    }
  }

  useEffect(() => {
    if (multiMediaStatus === PLAYER_MULTIMEDIA_STATUS.PLAYING) {
      idleTimerRef?.current?.pause()
    } else {
      idleTimerRef?.current?.reset()
    }
  }, [multiMediaStatus])

  return (
    <>
      <IdleTimer
        timeout={timeout}
        onIdle={setPlayerIdle}
        onActive={setPlayerActive}
        ref={idleTimerRef}
      />
    </>
  )
}

TimeoutGuardContainer.propTypes = {
  timeout: PropTypes.number,
}

export default TimeoutGuardContainer
