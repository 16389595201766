import styled from 'styled-components'

// Note: It is very important for this component to be performant, otherwise dragging and dropping will be really
// choppy. Use "style" object for dynamic styling as much as possible. Keep props and logic limited.

export const Layer = styled.div`
  height: 100%;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
`

export const Preview = styled.div.attrs(({ x, y }) => ({
  style: {
    transform: `translate(${x}px, ${y}px)`,
    WebkitTransform: `translate(${x}px, ${y}px)`,
  },
}))``
