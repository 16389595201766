import styled from 'styled-components'
import PropTypes from 'prop-types'
import { themeGet } from 'styled-system'
import { Heading } from 'grommet'
import {
  toNoBreakContentArray,
  toContentArray,
} from '../../utils/componentHelpers'

const HeaderOne = styled(Heading).attrs(({ dataTestId, children }) => {
  const contentArray = toContentArray(children)
  const noBreakContentArray = toNoBreakContentArray(contentArray)
  return {
    a11yTitle: 'Header',
    'data-testid': dataTestId,
    level: 1,
    responsive: false,
    children: noBreakContentArray,
  }
})`
  color: ${themeGet('colors.heading.level.1')};
  letter-spacing: 0.0563rem;

  ${themeGet('breakpoints.tablet')} {
    font-size: ${themeGet('heading.level.1.tablet.size')};
    line-height: ${themeGet(`heading.level.1.tablet.height`)};
  }

  ${themeGet('breakpoints.mobile')} {
    font-size: ${themeGet(`heading.level.1.mobile.size`)};
    line-height: ${themeGet(`heading.level.1.mobile.height`)};
  }
`

HeaderOne.propTypes = {
  children: PropTypes.node.isRequired,
  dataTestId: PropTypes.string.isRequired,
  textAlign: PropTypes.string,
}

HeaderOne.defaultProps = {
  textAlign: 'start',
}

HeaderOne.displayName = 'HeaderOne'

export default HeaderOne
