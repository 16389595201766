import React from 'react'

import AudioPlayer from '@cfc/secondstep-audio'
import PropTypes from 'prop-types'

const Audio = props => {
  return <AudioPlayer src={props.file.fields.file.url} />
}

Audio.propTypes = {
  file: PropTypes.object,
}

export default Audio
