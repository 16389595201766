const FALSE_STRING = 'false'
const TRUE_STRING = 'true'

export const getBoolFromString = previewString => {
  if (previewString === TRUE_STRING) {
    return true
  } else if (previewString === FALSE_STRING) {
    return false
  } else {
    return null
  }
}
