import React, { Component } from 'react'
import PropTypes from 'prop-types'

import FlagsContext from '../FlagsContext'
import FlagsSingleton from '../../FlagsManager'

class FlagsProvider extends Component {
  constructor(props) {
    super(props)

    const flags = FlagsSingleton.getFlags()
    this.subscribeToChanges(FlagsSingleton, flags)
    this.state = {
      flags,
    }
  }

  subscribeToChanges(FlagsSingleton, flags) {
    if (this.props.softReload) {
      FlagsSingleton.updateAppOnFlagChanges((...args) => {
        this.setState(...args)
      }, flags)
    } else {
      FlagsSingleton.reloadAppOnFlagChanges()
    }
  }

  render() {
    const { children } = this.props

    return (
      <FlagsContext.Provider value={this.state}>
        {children}
      </FlagsContext.Provider>
    )
  }
}

FlagsProvider.propTypes = {
  children: PropTypes.element,
  softReload: PropTypes.bool,
}

export default FlagsProvider
