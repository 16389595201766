import React from 'react'
import PropTypes from 'prop-types'

const DEFAULT_COLOR = 'currentColor'
const DEFAULT_WIDTH = 19
const DEFAULT_HEIGHT = 19

const ClassChallengeIcon = ({ color, height, width }) => {
  return (
    <svg
      height={height}
      version="1.1"
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>ss_classchallenges</title>
      <g
        fill="none"
        fillRule="evenodd"
        id="ss_classchallenges"
        stroke="none"
        strokeWidth="1"
      >
        <path
          d="M12.7959184,1.55102041 L15.122449,1.55102041 L15.122449,3.48979592 L12.7959184,1.55102041 Z M16.2855927,7.1830825 L16.2855927,0.678267491 C16.2855927,0.303816178 15.9817697,0 15.6068845,0 L10.8572036,0 C10.4823184,0.000425512855 10.178921,0.304241691 10.1793465,0.679118517 C10.179772,0.876981994 10.2661531,1.06463316 10.4159369,1.19356356 L14.9286018,5.06232644 L14.9286018,7.1830825 L8.82150456,13.2904685 L5.90795279,10.3774075 C5.64285234,10.112313 5.21392578,10.112313 4.94882533,10.3774075 L0.198718954,15.1274075 C0.0714877606,15.2546359 0,15.4269686 0,15.6069605 L0,18.321307 C0,18.6961838 0.303822983,19 0.678282681,19 L18.3212918,19 C18.696177,19 19,18.6961838 19,18.321307 L19,10.1782675 C19,9.99870107 18.9285122,9.82594285 18.801281,9.6987145 L16.2855927,7.1830825 Z"
          fill={color}
          id="class-challenges-icon"
        />
      </g>
    </svg>
  )
}

ClassChallengeIcon.defaultProps = {
  color: DEFAULT_COLOR,
  height: DEFAULT_HEIGHT,
  width: DEFAULT_WIDTH,
}

ClassChallengeIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
}

export default ClassChallengeIcon
