/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

const CircleRightArrowIcon = props => (
  <svg
    height="26px"
    version="1.1"
    viewBox="0 0 26 26"
    width="26px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <circle cx="13" cy="13" fill="#EEF0F2" r="13" />
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="1.82"
        transform="translate(7.000000, 8.000000)"
      >
        <line x1="10.4327061" x2="0" y1="5.05788659" y2="4.99392139" />
        <polyline
          points="4.35394212 7.64605788 9.35394212 2.35394212 14.3539421 7.64605788"
          transform="translate(9.353942, 5.000000) rotate(-270.000000) translate(-9.353942, -5.000000) "
        />
      </g>
    </g>
  </svg>
)

export default CircleRightArrowIcon
