import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Provider } from 'react-redux'

import GlobalStyles from '../../../styles/GlobalStyles'
import ThemeProvider from '../../../styles/ThemeProvider'
import CFCPlayer from '../../CFCPlayer'
import HttpErrorMessage from '../../HttpErrorMessage'

import { BLANK_PAGE } from './constants'

class AppWrapperComponent extends Component {
  getContentComponent = () => {
    const { error } = this.props || {}

    if (error) {
      const { data, status } = error || {}
      const { message } = data || {}

      return <HttpErrorMessage message={message} statusCode={status} />
    }

    return (
      <DndProvider backend={HTML5Backend}>
        <GlobalStyles />
        <CFCPlayer />
      </DndProvider>
    )
  }

  render = () => {
    const { store } = this.props || {}
    const contentComponent = this.getContentComponent()

    if (!store) {
      return BLANK_PAGE
    }

    return (
      <Provider store={store}>
        <ThemeProvider>{contentComponent}</ThemeProvider>
      </Provider>
    )
  }
}

AppWrapperComponent.propTypes = {
  error: PropTypes.object,
  store: PropTypes.object,
}

export default AppWrapperComponent
