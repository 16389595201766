/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

const Icon = () => (
  <svg height="3" viewBox="0 0 13 3" width="13">
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="currentColor">
        <circle cx="11.5" cy="1.5" r="1.5" />
        <circle cx="6.5" cy="1.5" r="1.5" />
        <circle cx="1.5" cy="1.5" r="1.5" />
      </g>
    </g>
  </svg>
)

export default Icon
