/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const PipeIcon = ({ className }) => (
  <svg className={className} height="17" viewBox="0 0 2 17" width="2">
    <rect
      fill="currentColor"
      fillRule="evenodd"
      height="17"
      rx="0.75"
      stroke="none"
      strokeWidth="1"
      transform="translate(-784 -668) translate(20 637) translate(615 21)"
      width="1.5"
      x="149"
      y="10"
    />
  </svg>
)

PipeIcon.propTypes = {
  className: PropTypes.string,
}

export default styled(PipeIcon)``
