import styled from 'styled-components'
import { themeGet } from 'styled-system'
import FormErrorMessage from '../FormErrorMessage'

export const FormFieldStyled = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.875rem;
  position: relative;
  input {
    border: 2px solid ${themeGet('colors.input.border')};
    border-radius: 0.3125rem;
  }
  ${FormErrorMessage} {
    margin-top: 0.5rem;
  }
`

export const LabelStyled = styled.label.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  color: ${themeGet('colors.darkerGray')};
  font-family: ${themeGet('fontFamilyBold')};
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.125rem;
  margin-left: 0;
`
