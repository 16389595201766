import styled from 'styled-components/native'

const ZERO_PX = '0px'

export const Container = styled.View.attrs(
  ({
    theme: {
      colorVars: { layout },
    },
  }) => ({
    padding: layout.container.padding,
  }),
)`
  flex: 1;
  padding: ${props => props.padding};
  width: 100%;
`

export const ElementsContainer = styled.View.attrs(
  ({
    foregroundColor,
    theme: {
      colorVars: { layout },
    },
  }) => ({
    backgroundColor: foregroundColor,
    borderRadius: foregroundColor ? layout.textBubble.borderRadius : ZERO_PX,
    padding: foregroundColor ? layout.textBubble.padding : ZERO_PX,
  }),
)`
  background-color: ${props => props.backgroundColor};
  border-radius: ${props => props.borderRadius};
  flex-direction: row;
  flex: 1;
  padding: ${props => props.padding};
`

export const Element = styled.View.attrs(
  ({
    isFirst,
    isLast,
    theme: {
      colorVars: { layout },
    },
  }) => ({
    marginLeft: isFirst ? ZERO_PX : layout.element.marginLeft,
    marginRight: isLast ? ZERO_PX : layout.element.marginRight,
    flex: isFirst ? 1 : 2,
  }),
)`
  flex-direction: row;
  flex: ${props => props.flex};
  margin-left: ${props => props.marginLeft};
  margin-right: ${props => props.marginRight};
`
