import React from 'react'

function ArticleIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="22"
      viewBox="0 0 18 22"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="currentColor" fillRule="nonzero">
          <path d="M11.766 0l6.129 5.206v14.557c0 1.182-.917 2.15-2.077 2.231l-.16.006H2.237a2.237 2.237 0 01-2.231-2.077L0 19.763V2.237C0 1.055.916.087 2.077.006L2.237 0h9.53zm2.484 17.3H3.75a.75.75 0 100 1.5h10.5a.75.75 0 100-1.5zm0-4H3.75a.75.75 0 00-.102 1.493l.102.007h10.5a.75.75 0 100-1.5zm0-4H3.75a.75.75 0 00-.102 1.493l.102.007h10.5a.75.75 0 100-1.5zm-2.813-4H3.564c-.311 0-.563.336-.563.75 0 .38.212.693.486.743l.076.007h7.876c.31 0 .562-.336.562-.75s-.252-.75-.563-.75z" />
        </g>
      </g>
    </svg>
  )
}

export default ArticleIcon
