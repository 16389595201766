import React from 'react'

import DialogcardTurnArrow from '../../DialogcardTurnArrow'

import { ArrowContainer, TurnIndicatorContainer } from './style'

const DialogcardTurnIndicator = () => {
  return (
    <TurnIndicatorContainer>
      Turn
      <ArrowContainer>
        <DialogcardTurnArrow />
      </ArrowContainer>
    </TurnIndicatorContainer>
  )
}

export default DialogcardTurnIndicator
