import styled, { css } from 'styled-components'

import { H1 } from '../../../text'

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;

  ${props =>
    props.background &&
    css`
      background-size: cover;
      background-position: center;
    `}

  ${props =>
    props.heroPositionIsTopOrBottom &&
    css`
      flex-direction: column;
    `}
`

export const HeroModal = styled.div`
  align-items: center;
  display: flex;
  height: ${props => (props.height ? props.height : '100%')};
  justify-content: center;
  width: auto;

  img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
`

export const Columns = styled.div`
  display: flex;
  flex: 1 1;
  ${props =>
    props.isDoneSlide &&
    css`
      margin: 0 auto;
      width: 70%;
      text-align: center;
    `}

  ${props =>
    props.center &&
    css`
      flex: 0 auto;
    `}

  > * {
    margin: 0 1.375em;
    ${({ isDoneSlide }) =>
      isDoneSlide &&
      css`
        padding-right: 5%;
        padding-left: 5%;
        min-height: 60%;
        align-self: center;
        justify-content: center;
      `}

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  ${props =>
    props.hasTextBackgroundBubble &&
    css`
      background-color: ${props => props.foregroundColor};
      border-radius: 10px;
      padding: 44px;
    `}
`

export const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  padding: 2.75em;
  position: relative;
  ${props =>
    props.isVideo &&
    css`
      padding: 0;
    `}
`

export const Header = styled(H1)`
  ${props =>
    props.center &&
    css`
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    `}
`
