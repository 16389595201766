import styled from 'styled-components'

import BannerComponent from '../../Banner'

export const AdditionalDetailsText = styled.span`
  margin-left: 5rem;
  text-align: left;
  font-size: 1.125rem;
  line-height: 1.375rem;
  letter-spacing: 0.05rem;
  display: block;
  ${({ theme }) => theme.colorVars.hsPreviewBanner};
  font-family: Molde-Semibold;
  font-size: 1.125rem;
  line-height: 1.25rem;
  margin-top: 0.441rem;
`

export const StyledBanner = styled(BannerComponent)`
  border-radius: 0.6rem;
  width: 35%;
  height: 3.75rem;
  background-color: #faf495;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TelescopeIconWrapper = styled.div`
  float: left;
  margin-left: 0.6rem;
`
