import styled from 'styled-components'

export const BottomContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 1;
  justify-content: space-between;
`

export const InteractiveContainer = styled.div`
  align-items: center;
  background-color: ${props =>
    props.theme.colorVars.multichoice.container.background};
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  margin-top: 0.625em;
`

export const TextContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`
