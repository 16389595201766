import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  updateCmiInteractives,
  updateLMSSuspendData,
} from '../../lib/state/actions/cmi'
import {
  completeSlide,
  dragQuestionInitialize,
  dragQuestionSubmit,
  dragQuestionUpdate,
} from '../../lib/state/actions/player'
import {
  getNewDropZoneIndexForKeyboardAction,
  isActivationKey,
  isCancelKey,
  isMovementKey,
} from '../../utils/keyboardControlHelper'

import DragQuestionComponent from './component'
import { BODYTEXT, EMPTY_STRING, ORIGIN_LOCATION } from './constants'
import {
  createDraggablesAndDropZonesWithIds,
  createDropHandler,
  filterBodyTextFromDraggables,
  indexOfDropZoneInDropZones,
  resetDraggables,
  setCurrentDraggablesFieldToOriginalState,
  sortByAscendDraggablesByYIndex,
  sortByAscendDropZonesByXIndex,
} from './utils'

export class DragQuestionContainer extends Component {
  constructor(props) {
    super(props)
    const { dragQuestionUpdate, id: interactiveId } = this.props

    this.state = {
      isMovingDraggableWithKeyboard: false,
      isOverDropZoneId: null,
      placeholderHeight: null,
    }
    this.dropHandler = createDropHandler(dragQuestionUpdate, interactiveId)
  }

  componentDidMount() {
    const { shouldInitialize } = this.props

    shouldInitialize && this.initializeQuestion()
  }

  canSubmitForm = () => {
    const { draggables } = this.props || []

    if (draggables.length <= 0) {
      return false
    }

    const draggablesAtOrigin = draggables.filter(
      element => element.location === ORIGIN_LOCATION,
    )

    return draggablesAtOrigin.length === 0
  }

  // Data from the Api comes with short index ids, and ids in inconvenient places.
  // Put it in easy to access places and return dropzones and draggables.
  conditionDataFromRedux = () => {
    const { question } = this.props
    const { task } = question || {}
    const { elements: rawDraggables, dropZones: rawDropZones } = task || {}
    const sortedDraggables = rawDraggables.sort(sortByAscendDraggablesByYIndex)
    const sortedDropZones = rawDropZones.sort(sortByAscendDropZonesByXIndex)
    const enrichedDropZones = sortedDropZones.map(
      setCurrentDraggablesFieldToOriginalState,
    )

    return (
      createDraggablesAndDropZonesWithIds(
        sortedDraggables,
        enrichedDropZones,
      ) || {}
    )
  }

  initializeQuestion = () => {
    const { dragQuestionInitialize, id, isSubmitted } = this.props
    const { draggables, dropZones } = this.conditionDataFromRedux() || {}
    const draggablesOnly = filterBodyTextFromDraggables(draggables)

    dragQuestionInitialize({
      interactiveId: id,
      draggables: draggablesOnly,
      dropZones,
      isSubmitted,
    })
  }

  getBodyText = () => {
    const { question } = this.props
    const { task } = question || {}
    const { elements = [] } = task || {}
    const bodyTextElements = elements.filter(element => {
      const { type } = element || {}
      const { metadata } = type || {}
      const { title } = metadata || EMPTY_STRING
      return title.toLowerCase() === BODYTEXT
    })
    const { type } = bodyTextElements[0] || {}
    const { params } = type || {}
    const { text } = params || EMPTY_STRING

    return text
  }

  getHeaderText = () => {
    const { currentSlideData } = this.props || {}
    const { fields: currentSlideFields } = currentSlideData || {}
    const { layout: currentSlideLayout } = currentSlideFields || {}
    const { fields: currentSlideLayoutFields } = currentSlideLayout || {}
    const { header } = currentSlideLayoutFields || EMPTY_STRING
    return header
  }

  getScore = () => {
    const { draggables } = this.props
    const correctAnswers = draggables.filter(draggable => {
      const correctDropZone = draggable.dropZones[0] || ''
      return draggable.location === correctDropZone
    })

    return correctAnswers.length
  }

  handleDrop = (item, monitor, destinationDropZoneId) => {
    const {
      draggables,
      dropZones,
      showErrorMessage: errorMessageAlreadyShowing,
    } = this.props

    this.dropHandler(
      item,
      monitor,
      draggables,
      dropZones,
      destinationDropZoneId,
      errorMessageAlreadyShowing,
    )
    this.exitKeyboardMoveMode()
    this.updateSuspendData()
  }

  isResetButtonDisabled = () => {
    const { draggables } = this.props || []

    if (draggables.length <= 0) {
      return true
    }

    const draggablesAtOrigin = draggables.filter(
      element => element.location === ORIGIN_LOCATION,
    )

    return !(draggablesAtOrigin.length < draggables.length)
  }

  isSlideAnsweredCorrectly = () => {
    const { draggables } = this.props

    return draggables.every(
      ({ dropZones, location }) => dropZones[0] === location,
    )
  }

  onReset = async () => {
    const {
      draggables: currentDraggables,
      dragQuestionInitialize,
      dropZones,
      id,
      endTime,
      startTime,
      attempts,
    } = this.props
    const newDraggables = resetDraggables(currentDraggables)
    const updatedDropZones = dropZones.map(
      setCurrentDraggablesFieldToOriginalState,
    )
    const newStartTime = endTime || startTime

    this.exitKeyboardMoveMode()
    await dragQuestionInitialize({
      interactiveId: id,
      draggables: newDraggables,
      dropZones: updatedDropZones,
      isSubmitted: false,
      showErrorMessage: false,
      startTime: newStartTime,
      attempts,
    })
    this.updateSuspendData()
  }

  onSubmit = async () => {
    const {
      completeSlide,
      currentSlide,
      dragQuestionSubmit,
      draggables,
      dragQuestionUpdate,
      dropZones,
      id,
    } = this.props

    const isAnsweredCorrectly = this.isSlideAnsweredCorrectly()
    const score = this.getScore()

    this.exitKeyboardMoveMode()

    if (this.canSubmitForm()) {
      await dragQuestionSubmit(id, score)
    } else {
      await dragQuestionUpdate({
        interactiveId: id,
        draggables,
        isSubmitted: true,
        showErrorMessage: true,
        dropZones,
      })
    }
    this.updateSuspendData()
    isAnsweredCorrectly && completeSlide(currentSlide.toString())
  }

  updateSuspendData = () => {
    const {
      attempts,
      draggables,
      dropZones,
      endTime,
      id,
      isSubmitted,
      showErrorMessage,
      startTime,
      updateCmiInteractives,
      updateLMSSuspendData,
    } = this.props

    updateCmiInteractives({
      interactives: {
        [id]: {
          draggables,
          dropZones,
          isSubmitted,
          showErrorMessage,
          startTime,
          endTime,
          attempts,
        },
      },
    })
    updateLMSSuspendData()
  }

  moveWithKeyboard = (currentLocation, domElement) => {
    const { dropZones, isSubmitted } = this.props
    const { offsetHeight: placeholderHeight } = domElement || {}
    const currentDropZoneIndex = indexOfDropZoneInDropZones(
      currentLocation,
      dropZones,
    )
    const { id: currentDropZoneId } = dropZones[currentDropZoneIndex] || {}
    const canSubmit = this.canSubmitForm()
    const canMove = !(canSubmit && isSubmitted)

    if (!canMove) {
      return
    }

    this.setState({
      isMovingDraggableWithKeyboard: true,
      isOverDropZoneId: currentDropZoneId,
      placeholderHeight: placeholderHeight,
    })
  }

  dropWithKeyboard = (currentLocation, draggableId) => {
    const { dropZones } = this.props
    const { isOverDropZoneId } = this.state || {}
    const newDropZoneIndex = indexOfDropZoneInDropZones(
      isOverDropZoneId,
      dropZones,
    )
    const { id: newDropZoneId } = dropZones[newDropZoneIndex] || {}

    if (!newDropZoneId) {
      return
    }

    const monitor = { isOver: () => true }
    const item = {
      id: draggableId,
      currentLocation,
    }

    this.handleDrop(item, monitor, newDropZoneId)
  }

  exitKeyboardMoveMode = () =>
    this.setState({
      isMovingDraggableWithKeyboard: false,
      isOverDropZoneId: null,
      placeholderHeight: null,
    })

  moveDraggableWithKeyboard = (key, domElement) => {
    const { dropZones } = this.props
    const { isOverDropZoneId } = this.state || {}
    const { offsetHeight: placeholderHeight } = domElement || {}
    const currentDropZoneIndex = indexOfDropZoneInDropZones(
      isOverDropZoneId,
      dropZones,
    )
    const newDropZoneIndex = getNewDropZoneIndexForKeyboardAction(
      key,
      dropZones,
      currentDropZoneIndex,
    )
    const didMove = newDropZoneIndex !== currentDropZoneIndex

    if (!didMove) {
      return
    }

    const { id: newDropZoneId } = dropZones[newDropZoneIndex] || {}

    this.setState({
      isOverDropZoneId: newDropZoneId,
      placeholderHeight: placeholderHeight,
    })
  }

  onKeyDown = (event, draggableId, currentLocation, domElement) => {
    const { isMovingDraggableWithKeyboard } = this.state || {}
    const { key } = event || {}
    const isActivating = isActivationKey(key)

    // If space or enter key press, jump into or out of keyboard move mode
    if (isActivating) {
      isMovingDraggableWithKeyboard
        ? this.dropWithKeyboard(currentLocation, draggableId)
        : this.moveWithKeyboard(currentLocation, domElement)

      return
    }

    //if right/left arrow key and in keyboard move mode, move the draggable accordingly
    const isMoving = isMovementKey(key) && isMovingDraggableWithKeyboard

    if (isMoving) {
      this.moveDraggableWithKeyboard(key, domElement)

      return
    }

    //if pressing escape or tab, cancel keyboard move mode
    const isExitingKeyboardMoveMode = isCancelKey(key)
    const shouldCancelKeyboardControls =
      isMovingDraggableWithKeyboard && isExitingKeyboardMoveMode

    shouldCancelKeyboardControls && this.exitKeyboardMoveMode()
  }

  render() {
    const {
      draggables,
      dropZones,
      isSubmitted,
      resetText,
      showErrorMessage,
      submitText,
      id,
    } = this.props
    const { isOverDropZoneId, placeholderHeight } = this.state || {}
    const { isResetButtonDisabled = this.isResetButtonDisabled() } = this.props
    const bodyText = this.getBodyText()
    const headerText = this.getHeaderText()

    return (
      <DragQuestionComponent
        bodyText={bodyText}
        canSubmitForm={this.canSubmitForm}
        draggables={draggables}
        dropZones={dropZones}
        handleDrop={this.handleDrop}
        headerText={headerText}
        id={id}
        isOverDropZoneId={isOverDropZoneId}
        isResetButtonDisabled={isResetButtonDisabled}
        isSubmitted={isSubmitted}
        onKeyDown={this.onKeyDown}
        onReset={this.onReset}
        onSubmit={this.onSubmit}
        placeholderHeight={placeholderHeight}
        resetText={resetText}
        showErrorMessage={showErrorMessage}
        submitText={submitText}
      />
    )
  }
}

DragQuestionContainer.propTypes = {
  attempts: PropTypes.number,
  completeSlide: PropTypes.func,
  currentSlide: PropTypes.array,
  currentSlideData: PropTypes.object,
  draggables: PropTypes.array,
  dragQuestionInitialize: PropTypes.func,
  dragQuestionSubmit: PropTypes.func,
  dragQuestionUpdate: PropTypes.func,
  dropZones: PropTypes.array,
  endTime: PropTypes.string,
  id: PropTypes.string,
  isResetButtonDisabled: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  onSubmit: PropTypes.func,
  question: PropTypes.object,
  resetText: PropTypes.string,
  shouldInitialize: PropTypes.bool,
  showErrorMessage: PropTypes.bool,
  startTime: PropTypes.string,
  submitText: PropTypes.string,
  updateCmiInteractives: PropTypes.func,
  updateLMSSuspendData: PropTypes.func,
}

DragQuestionContainer.defaultProps = {
  currentSlideData: {},
  draggables: [],
  dragQuestionInitialize() {},
  dragQuestionSubmit() {},
  dragQuestionUpdate() {},
  dropZones: [],
  isSubmitted: false,
  onSubmit() {},
  showErrorMessage: false,
  updateCmiInteractives() {},
  updateLMSSuspendData() {},
}

const mapDispatchToProps = dispatch => ({
  completeSlide: slidePosition => dispatch(completeSlide(slidePosition)),
  dragQuestionInitialize: initializedData =>
    dispatch(dragQuestionInitialize({ ...initializedData })),
  dragQuestionUpdate: ({
    interactiveId,
    draggables,
    isSubmitted,
    showErrorMessage,
    dropZones,
  }) =>
    dispatch(
      dragQuestionUpdate({
        interactiveId,
        draggables,
        isSubmitted,
        showErrorMessage,
        dropZones,
      }),
    ),
  dragQuestionSubmit: (id, score) => dispatch(dragQuestionSubmit(id, score)),
  updateCmiInteractives: data => dispatch(updateCmiInteractives(data)),
  updateLMSSuspendData: () => dispatch(updateLMSSuspendData()),
})

const mapStateToProps = ({ player }, ownProps) => {
  const { id } = ownProps || {}
  const interactiveState = player.interactiveStates[id] || null

  return {
    ...interactiveState,
    currentSlide: player.currentSlide,
    currentSlideData: player.currentSlideData,
    shouldInitialize: interactiveState === null,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DragQuestionContainer)
