import React, { Fragment, useState } from 'react'

import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import {
  getConstantByLocale,
  getLocaleFromSession,
  LOCALES,
  setLocale,
} from 'utils/locale'

import LanguageToggleComponent from './component.js'
import { EN_US_LANGUAGE, ES_US_LANGUAGE } from './constants'

let enUS = ''
let esUS = ''
const currentLocale = getLocaleFromSession()
if (currentLocale === EN_US_LANGUAGE[LOCALES.EN_US]) {
  enUS = getConstantByLocale(EN_US_LANGUAGE, LOCALES.ES_US)
  esUS = getConstantByLocale(ES_US_LANGUAGE, LOCALES.EN_US)
} else {
  enUS = getConstantByLocale(EN_US_LANGUAGE, LOCALES.EN_US)
  esUS = getConstantByLocale(ES_US_LANGUAGE, LOCALES.ES_US)
}

export const localeTree = { [enUS]: 'en-US', [esUS]: 'es-US' }
export const currentLangFromLocale = currentLocale => {
  const possiblePair = Object.entries(localeTree).filter(
    ([, locale]) => locale === currentLocale,
  )
  const possibleLang = possiblePair.length > 0 ? possiblePair[0][0] : null

  return possibleLang
}
export const getLocaleFromTree = lang => localeTree[lang]

const LanguageToggleContainer = props => {
  const availableLocales = useSelector(state => state.player.availableLocales)
  const currentLang = currentLangFromLocale(props.currentLocale) || enUS
  const languages = [enUS, esUS]

  const shouldToggleDisplay = availableLocales.length > 1

  const [isActive, setIsActive] = useState(false)

  const handleClick = () => {
    setIsActive(!isActive)
  }
  const handleItemClick = language => {
    setIsActive(!isActive)
    const locale = getLocaleFromTree(language)

    setLocale(locale)
  }
  const filteredLanguages = languages.filter(lang => {
    if (lang !== currentLang) return lang
  })

  return (
    <Fragment>
      {shouldToggleDisplay && (
        <LanguageToggleComponent
          handleClick={handleClick}
          isActive={isActive}
          handleItemClick={handleItemClick}
          language={currentLang}
          languages={filteredLanguages}
        />
      )}
    </Fragment>
  )
}
LanguageToggleContainer.defaultProps = {
  languages: [],
  handleClick() {},
  handleItemClick() {},
}
LanguageToggleContainer.propTypes = {
  isActive: PropTypes.bool,
  handleClick: PropTypes.func,
  language: PropTypes.string,
  handleItemClick: PropTypes.func,
  languages: PropTypes.array,
  currentLocale: PropTypes.string,
}

const mapStateToProps = ({ cmi }) => ({
  currentLocale: cmi?.student_preference?.language,
})

export default connect(mapStateToProps, null)(LanguageToggleContainer)
