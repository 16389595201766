import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { H5P_IMG_ORIGIN } from '../../lib/config/index'
import { updateDialogCardLMSSuspendData } from '../../lib/state/actions/cmi'
import {
  completeSlide,
  dialogCardFlip,
  dialogCardInitialize,
} from '../../lib/state/actions/player'

import DialogcardComponent from './component'

const DATA_TEST_ID = 'dialog-card'

class DialogcardContainer extends Component {
  constructor(props) {
    super(props)

    const {
      dialogCardInitialize,
      id,
      isComplete = false,
      isFlipped,
    } = this.props

    dialogCardInitialize(id, isFlipped, isComplete)
  }

  flipCard = () => {
    const {
      completeSlide,
      currentSlide,
      dialogCardFlip,
      id,
      isFlipped,
      updateDialogCardLMSSuspendData,
    } = this.props
    const interactives = {
      [id]: {
        isComplete: true,
        isFlipped: !isFlipped,
      },
    }

    dialogCardFlip(id, !isFlipped)
    completeSlide(currentSlide)
    updateDialogCardLMSSuspendData(interactives)
  }

  handleEnter = e => {
    const isActivationKey = e.key == 'Enter' || e.key === ' '

    isActivationKey && this.flipCard()
  }

  getAriaLabel = () => {
    const { isFlipped } = this.props
    const side = isFlipped ? 'front' : 'back'
    const label = `Flip card to ${side}`

    return label
  }

  render() {
    const { dialogs, id, isFlipped } = this.props
    const { text, answer, image } = dialogs[0] || {}
    const imageUrl = image ? H5P_IMG_ORIGIN + image.path : undefined

    return (
      <DialogcardComponent
        answer={answer}
        dataTestId={`${DATA_TEST_ID}-${(id || '').split(' ')[1]}`}
        flipCard={this.flipCard}
        getAriaLabel={this.getAriaLabel}
        handleEnter={this.handleEnter}
        imageUrl={imageUrl}
        isFlipped={isFlipped}
        text={text}
      />
    )
  }
}

DialogcardContainer.propTypes = {
  completeSlide: PropTypes.func,
  completeStatus: PropTypes.object,
  currentSlide: PropTypes.array,
  dialogCardFlip: PropTypes.func,
  dialogCardInitialize: PropTypes.func,
  dialogs: PropTypes.array.isRequired,
  id: PropTypes.string,
  isComplete: PropTypes.bool,
  isFlipped: PropTypes.bool,
  updateDialogCardLMSSuspendData: PropTypes.func,
}

DialogcardContainer.defaultProps = {
  completeSlide() {},
  completeStatus: {},
  currentSlide: [],
  dialogCardFlip() {},
  dialogCardInitialize() {},
  dialogs: [],
  isFlipped: false,
  updateDialogCardLMSSuspendData() {},
}

const mapDispatchToProps = dispatch => ({
  completeSlide: slide => dispatch(completeSlide(slide)),
  dialogCardFlip: (id, isFlipped) => dispatch(dialogCardFlip(id, isFlipped)),
  dialogCardInitialize: (id, isFlipped, isComplete) =>
    dispatch(dialogCardInitialize(id, isFlipped, isComplete)),
  updateDialogCardLMSSuspendData: interactives =>
    dispatch(updateDialogCardLMSSuspendData(interactives)),
})

const mapStateToProps = ({ player }, ownProps) => {
  const { id } = ownProps || {}
  const interactiveState = player.interactiveStates[id] || null

  return {
    ...interactiveState,
    completeStatus: player.completeStatus,
    currentSlide: player.currentSlide,
    currentSlideData: player.currentSlideData,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogcardContainer)
