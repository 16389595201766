import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import BannerComponent from '../../Banner'

import { BANNER_TEXT, BANNER_TEXT_BOLD, DATA_TEST_ID } from './constants'

const PrepBannerComponent = ({ isPrepMode }) => {
  if (isPrepMode) {
    return (
      <BannerComponent
        data-testid={DATA_TEST_ID}
        message={BANNER_TEXT_BOLD}
        additionalDetails={BANNER_TEXT}
      />
    )
  }

  return null
}

PrepBannerComponent.propTypes = {
  isPrepMode: PropTypes.bool,
}

PrepBannerComponent.defaultProps = {
  isPrepMode: false,
}

const mapStateToProps = ({ player: { isPrepMode } }) => ({ isPrepMode })

export default connect(mapStateToProps, {})(PrepBannerComponent)
