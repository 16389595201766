export function initializePlayer(json) {
  return {
    type: 'INITIALIZE_PLAYER',
    json,
  }
}

export function toggleSession(isActive, startDate) {
  return {
    type: 'TOGGLE_SESSION',
    isActive,
    startDate,
  }
}

export function addSections(sections) {
  return {
    type: 'ADD_SECTIONS',
    sections,
  }
}

export function changeTheme(themeName) {
  return {
    type: 'CHANGE_THEME',
    themeName,
  }
}

export function addTheme(themeName) {
  return {
    type: 'ADD_THEME',
    themeName,
  }
}

export function togglePrepMode(isPrepMode) {
  return {
    type: 'TOGGLE_PREP_MODE',
    isPrepMode,
  }
}

export function setFamilyMode(isFamilyMode) {
  return {
    type: 'SET_FAMILY_MODE',
    isFamilyMode,
  }
}

export function setCurriculumPreviewMode(isCurriculumPreviewMode) {
  return {
    type: 'SET_CURRICULUM_PREVIEW_MODE',
    isCurriculumPreviewMode,
  }
}

export function setHighSchoolMode(isHighSchoolMode) {
  return {
    type: 'SET_HIGH_SCHOOL_MODE',
    isHighSchoolMode,
  }
}

export function setHighSchoolPreviewMode(isHighSchoolPreviewMode) {
  return {
    type: 'SET_HIGH_SCHOOL_PREVIEW_MODE',
    isHighSchoolPreviewMode,
  }
}

export function setVtts(entriesVtts) {
  return {
    type: 'SET_VTTS',
    entriesVtts,
  }
}

export function setMultimediaStatus(multimediaStatus) {
  return {
    type: 'SET_MULTIMEDIA_STATUS',
    multimediaStatus,
  }
}

export function toggleFullScreen(isFullScreen) {
  return {
    type: 'TOGGLE_FULLSCREEN',
    isFullScreen,
  }
}

export function toggleIdle(isIdle) {
  return {
    type: 'TOGGLE_IDLE',
    isIdle,
  }
}

export function toggleModal(modalContent) {
  return {
    type: 'TOGGLE_MODAL',
    modalContent,
  }
}

export function nextSlide() {
  return {
    type: 'NEXT_SLIDE',
  }
}

export function prevSlide() {
  return {
    type: 'PREV_SLIDE',
  }
}

export function gotoSlide(sectionIndex, slideIndex) {
  return {
    type: 'GOTO_SLIDE',
    sectionIndex,
    slideIndex,
  }
}

export function gotoDoneSlide(sectionIndex, slideIndex) {
  return {
    type: 'GOTO_DONE_SLIDE',
    sectionIndex,
    slideIndex,
  }
}

export function completeSlide(slide) {
  return {
    type: 'COMPLETE_SLIDE',
    slide,
  }
}

export function setHotspotRef(reference) {
  return {
    type: 'HOTSPOT_REF',
    reference,
  }
}

export function viewedSlide(slide, viewedBool = true) {
  return {
    type: 'VIEWED_SLIDE',
    slide,
    viewedBool,
  }
}

export function visitButton(buttonId, visitedBool = true) {
  return {
    type: 'VISIT_BUTTON',
    buttonId,
    visitedBool,
  }
}

export function initializeRatioWrapper() {
  return {
    type: 'RATIO_WRAPPER_INITIALIZE',
  }
}

export function setRatioWrapperHeight(height) {
  return {
    type: 'RATIO_WRAPPER_SET_HEIGHT',
    height,
  }
}

export function updateRatioWrapper(scale, width, height) {
  return {
    type: 'RATIO_WRAPPER_UPDATE',
    height,
    scale,
    width,
  }
}

export function setDeviceResolutionTier(tier) {
  return {
    type: 'SET_DEVICE_RESOLUTION_TIER',
    tier,
  }
}

export function multichoiceInitialize({
  interactiveId,
  options,
  isAnswered,
  isSubmitted,
  score,
  startTime,
  endTime,
  attempts,
}) {
  return {
    type: 'MULTICHOICE_INITIALIZE',
    interactiveId,
    options,
    isAnswered,
    isSubmitted,
    score,
    startTime,
    endTime,
    attempts,
  }
}

export function multichoiceUpdate({
  interactiveId,
  isAnswered,
  isSubmitted,
  options,
  score,
  startTime,
  endTime,
  attempts,
}) {
  return {
    type: 'MULTICHOICE_UPDATE',
    interactiveId,
    isAnswered,
    isSubmitted,
    options,
    score,
    startTime,
    endTime,
    attempts,
  }
}

export function multichoiceSubmit(interactiveId, score) {
  return {
    type: 'MULTICHOICE_SUBMIT',
    interactiveId,
    score,
  }
}

export function dragQuestionInitialize({
  interactiveId,
  draggables,
  dropZones,
  isSubmitted,
  startTime,
  endTime,
  attempts,
  showErrorMessage,
}) {
  return {
    type: 'DRAG_QUESTION_INITIALIZE',
    interactiveId,
    draggables,
    dropZones,
    isSubmitted,
    startTime,
    endTime,
    attempts,
    showErrorMessage,
  }
}

export function dragQuestionUpdate({
  interactiveId,
  draggables,
  isSubmitted,
  showErrorMessage,
  dropZones,
}) {
  return {
    type: 'DRAG_QUESTION_UPDATE_SELECTION',
    draggables,
    dropZones,
    isSubmitted,
    interactiveId,
    showErrorMessage,
  }
}

export function dragQuestionSubmit(interactiveId, score) {
  return {
    type: 'DRAG_QUESTION_SUBMIT',
    interactiveId,
    score,
  }
}

export function dragLabelInitialize({
  interactiveId,
  draggables,
  dropZones,
  buttons,
  helperText,
  startTime,
  endTime,
  attempts,
  score,
}) {
  return {
    type: 'DRAG_LABEL_INITIALIZE',
    interactiveId,
    draggables,
    dropZones,
    buttons,
    helperText,
    startTime,
    endTime,
    attempts,
    score,
  }
}

export function dragLabelUpdate(
  interactiveId,
  draggables,
  dropZones,
  buttons,
  helperText,
) {
  return {
    type: 'DRAG_LABEL_UPDATE',
    buttons,
    helperText,
    draggables,
    dropZones,
    interactiveId,
  }
}

export function dragLabelSubmit(interactiveId, dropZones, buttons, score) {
  return {
    type: 'DRAG_LABEL_SUBMIT',
    buttons,
    dropZones,
    interactiveId,
    score,
  }
}

export function dragLabelShowFrameDropZones(interactiveId, showFrameDropZones) {
  return {
    type: 'DRAG_LABEL_SHOW_FRAME_DROP_ZONES',
    interactiveId,
    showFrameDropZones,
  }
}

export function dragWordInitialize(
  interactiveId,
  {
    buttons,
    draggables,
    isErrorVisible,
    questions,
    startTime,
    attempts,
    endTime,
  },
) {
  return {
    type: 'DRAG_WORD_INITIALIZE',
    interactiveId,
    newState: {
      buttons,
      draggables,
      isErrorVisible,
      questions,
      startTime,
      attempts,
      endTime,
    },
  }
}

export function dragWordUpdate(interactiveId, newState) {
  return {
    type: 'DRAG_WORD_UPDATE',
    interactiveId,
    newState,
  }
}

export function dragWordSubmit(interactiveId, newState) {
  return {
    type: 'DRAG_WORD_SUBMIT',
    interactiveId,
    newState,
  }
}

export function imageHotspotsInitialize(interactiveId, targets) {
  return {
    type: 'IMAGE_HOTSPOTS_INITIALIZE',
    interactiveId,
    targets,
  }
}

export function imageHotspotsTargetClick(interactiveId, targetIndex) {
  return {
    type: 'IMAGE_HOTSPOTS_TARGET_CLICK',
    interactiveId,
    targetIndex,
  }
}

export function imageJuxtapositionInitialize(interactiveId, position) {
  return {
    type: 'IMAGE_JUXTAPOSITION_INITIALIZE',
    interactiveId,
    position,
  }
}

export function imageJuxtapositionUpdate(
  interactiveId,
  position,
  maxThresholdReached,
) {
  return {
    type: 'IMAGE_JUXTAPOSITION_UPDATE',
    interactiveId,
    position,
    maxThresholdReached,
  }
}

export function dialogCardInitialize(interactiveId, isFlipped, isComplete) {
  return {
    type: 'DIALOG_CARD_INITIALIZE',
    interactiveId,
    isFlipped,
    isComplete,
  }
}

export function dialogCardFlip(interactiveId, isFlipped) {
  return {
    type: 'DIALOG_CARD_FLIP',
    interactiveId,
    isFlipped,
  }
}
