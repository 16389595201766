import React from 'react'

const VideoPlayIcon = () => (
  <svg
    height="15"
    viewBox="0 0 15 15"
    width="15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        d="M7.5 0a7.5 7.5 0 110 15 7.5 7.5 0 010-15zM5.2 4.4A1 1 0 005 5v5a1 1 0 001.6.8L11 7.5 6.6 4.2a1 1 0 00-1.4.2z"
        fill="currentColor"
      />
    </g>
  </svg>
)

export default VideoPlayIcon
