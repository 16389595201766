import React, { Component } from 'react'

import PropTypes from 'prop-types'

import ImageJuxtFrameComponent from './component'

class ImageJuxtFrameContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      componentHeightScaled: 0,
      componentWidth: 0,
      componentWidthScaled: 0,
      isDragging: false,
      pageXOffset: 0,
    }
  }

  // Update measurements if the scale supplied to the component changes. For our purposes, scale
  // would likely change if the player is wrapped in RatioWrapperController and there is a window resize.
  componentDidUpdate(prevProps) {
    const didScaleChange = prevProps?.scale !== this.props.scale
    didScaleChange && this.measureComponent(this.view)
  }

  // Update measurements if the component changes size due to window resize. For our purposes, will most
  // likely get called if the player is not wrapped in RatioWrapperController and there is a window resize.
  // If it is wrapped in RatioWrapperController, this will likely only get called on first load.
  onLayout = () => {
    this.measureComponent(this.view)
  }

  measureComponent = view => view.measure && view.measure(this.onMeasure)

  //If the frame component is measured again (ie. on browser resize) Update what we know about
  //the components dimensions and where it is relative to the left edge of the brwoser viewport.
  onMeasure = (fx, fy, width, height, px) => {
    const { scale, updateFrameMeasurements } = this.props
    const {
      componentHeightScaled: prevComponentHeightScaled,
      componentWidthScaled: prevComponentWidthScaled,
      pageXOffset: prevPageXOffset,
    } = this.state || {}
    const pageXOffsetDidChange = prevPageXOffset != px
    const componentHeightScaled = height * scale
    const componentWidthScaled = width * scale
    const heightDidChange = prevComponentHeightScaled != componentHeightScaled
    const widthDidChange = prevComponentWidthScaled != componentWidthScaled
    const dimensionsDidChange = heightDidChange || widthDidChange
    const isNewParams = px
      ? pageXOffsetDidChange || dimensionsDidChange
      : dimensionsDidChange

    if (!isNewParams) {
      return
    }

    const newState = {
      componentHeightScaled,
      componentWidth: width,
      componentWidthScaled,
      pageXOffset: px,
    }

    this.setState(newState, () => updateFrameMeasurements(newState))
  }

  onDragStart = () => {
    this.setState({ isDragging: true })
  }

  onDragEnd = () => {
    const { onDragEnd } = this.props

    this.setState({ isDragging: false }, onDragEnd)
  }

  getRightImageErrorStyles = () => {
    const { position } = this.props
    const { componentWidth } = this.state || {}
    const rightErrorMessageWidth = componentWidth - position
    const rightErrorMessageTextWidth = componentWidth / 2

    return { rightErrorMessageTextWidth, rightErrorMessageWidth }
  }

  setComponentRef = view => (this.view = view)

  render = () => {
    const {
      height,
      imageLeftAltText,
      imageLeftUrl,
      imageRightAltText,
      imageRightUrl,
      onDrag,
      onKeyDown,
      position,
      scale,
      width,
    } = this.props
    const { componentWidth, isDragging, pageXOffset } = this.state || {}
    const { rightErrorMessageTextWidth, rightErrorMessageWidth } =
      this.getRightImageErrorStyles()

    return (
      <ImageJuxtFrameComponent
        componentRef={this.setComponentRef}
        height={height}
        imageLeftAltText={imageLeftAltText}
        imageLeftUrl={imageLeftUrl}
        imageRightAltText={imageRightAltText}
        imageRightUrl={imageRightUrl}
        isDragging={isDragging}
        onDrag={onDrag}
        onDragEnd={this.onDragEnd}
        onDragStart={this.onDragStart}
        onKeyDown={onKeyDown}
        onLayout={this.onLayout}
        pageXOffset={pageXOffset}
        position={position}
        rightErrorMessageTextWidth={rightErrorMessageTextWidth}
        rightErrorMessageWidth={rightErrorMessageWidth}
        scale={scale}
        selfRenderedWidth={componentWidth}
        width={width}
      />
    )
  }
}

ImageJuxtFrameContainer.propTypes = {
  height: PropTypes.number,
  imageLeftAltText: PropTypes.string,
  imageLeftUrl: PropTypes.string,
  imageRightAltText: PropTypes.string,
  imageRightUrl: PropTypes.string,
  onDrag: PropTypes.func,
  onDragEnd: PropTypes.func,
  onKeyDown: PropTypes.func,
  position: PropTypes.number,
  updateFrameMeasurements: PropTypes.func,
  width: PropTypes.number,
  scale: PropTypes.number,
}

ImageJuxtFrameContainer.defaultProps = {
  onDrag() {},
  onDragEnd() {},
  onKeyDown() {},
  position: 0,
  scale: 1,
  updateFrameMeasurements() {},
}

export default ImageJuxtFrameContainer
