import styled from 'styled-components'

export const Wrapper = styled.div.attrs(
  ({ canShowAccessibilityMenu, menuBarRenderedForEnglish }) => ({
    height:
      canShowAccessibilityMenu && menuBarRenderedForEnglish ? '92%' : '100%',
  }),
)`
  background-color: ${props => props.theme.colorVars.black};
  height: ${({ height }) => height};
  display: flex;
  position: absolute;
  width: 100%;
`
export const TranscriptOuterWrapper = styled.div`
  height: 95.5%;
  font-family: 'Molde-Regular', sans-serif;
  display: block;
  flex-basis: 30%;
  background-color: ${props => props.theme.colorVars.general.white};
  border-radius: 5px;
  border: 1px solid ${props => props.theme.colorVars.general.lightWhite};
  overflow-y: scroll;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 0.93rem;

  .transcript-heading {
    font-family: 'Molde-Medium', sans-serif;
  }
  .transcript {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .description .transcript-line {
    color: #3c6e90;
  }
`
export const OuterWrapper = styled.div`
  height: 100%;
  width: 100%;
`

export const ItemBar = styled.div`
  height: 3.375rem;
  background-color: ${props => props.theme.colorVars.black};
  display: flex;
  vertical-align: top;
  justify-content: flex-end;
  align-items: center;
  column-gap: 0.75rem;
  padding-right: 1.3rem;
  div {
    cursor: pointer;
  }
  button {
    max-height: 30px;
  }
  svg {
    width: 31px;
  }
`
export const Tooltip = styled.span`
  box-sizing: border-box;
  height: 1.938rem;
  width: auto;
  padding: 0.313rem 1.25rem 0.313rem 1.25rem;
  border: 0.063rem solid #ced8df;
  border-radius: 0.375rem;
  background-color: ${props => props.theme.colorVars.black};
  box-shadow: 0 0 0.75rem 0 rgba(0, 0, 0, 0.1);
  color: ${props => props.theme.colorVars.grey};
  font-family: Molde-Regular;
  font-size: 1rem;
  letter-spacing: 0;
  text-align: center;
  position: absolute;
  right: ${props => props.right}rem;
`
