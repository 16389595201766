import styled from 'styled-components/native'

export const Container = styled.View`
  flex: 1;
`

export const ElementTitleContainer = styled.View.attrs(
  ({
    theme: {
      colorVars: { layout },
    },
  }) => ({
    padding: `0 ${layout.container.padding} 0`,
  }),
)`
  align-items: center;
  height: 20%;
  justify-content: center;
  padding: ${props => props.padding};
  text-align: center;
  width: 100%;
`

export const ElementsContainer = styled.View`
  background-color: ${props => props.backgroundColor};
  border-radius: ${props => props.borderRadius};
  flex-direction: column;
  flex: 1;
  justify-content: center;
`

export const Element = styled.View.attrs(({ height, width }) => ({
  frameWidth: width,
  frameHeight: height,
}))`
  height: ${props => props.frameHeight};
  width: ${props => props.frameWidth};
`
