import React, { useEffect, useRef, useState } from 'react'

import { setHotspotRef } from 'lib/state/actions/player'
import PropTypes from 'prop-types'
import { Animated, Easing } from 'react-native-web'
import { connect } from 'react-redux'

import ImageHotspotsTargetComponent from './component'

const ICON_ANIMATION_DURATION = 100
const ICON_DEFAULT_SCALE = 1
const ICON_GROW = 1.3

export const ImageHotspotsTargetContainer = props => {
  const scaleAnimRef = useRef(new Animated.Value(ICON_DEFAULT_SCALE)).current
  const [isHovered, setIsHovered] = useState(false)
  const reference = useRef(null)
  const { modal, setHotspotReference, hotSpotRef } = props

  useEffect(() => {
    if (!modal && hotSpotRef) {
      hotSpotRef.current?.focus()
      setHotspotReference(null)
    }
  }, [modal, hotSpotRef])

  const animate = (endValue, callback) => {
    Animated.timing(scaleAnimRef, {
      toValue: endValue,
      duration: ICON_ANIMATION_DURATION,
      easing: Easing.in,
    }).start(callback)
  }

  const handleEnter = () => {
    animate(ICON_GROW, () => setIsHovered(true))
  }

  const handleLeave = () => {
    animate(ICON_DEFAULT_SCALE, () => setIsHovered(false))
  }

  const onClickTarget = () => {
    const { id, index, onClick } = props || {}

    onClick(id, index)
  }

  const getRenderProps = () => {
    const {
      id,
      index,
      isTargetClicked,
      isToolTipFlipped,
      left,
      toolTip,
      top,
      setHotspotReference,
      modal,
      hotSpotRef,
    } = props || {}
    const targetProps = {
      id,
      index,
      isHovered,
      isTargetClicked,
      isToolTipFlipped,
      left,
      onClick: onClickTarget,
      handleEnter,
      handleLeave,
      scaleAnimRef,
      toolTip,
      top,
      reference,
      setHotspotReference,
      hotSpotRef,
      modal,
    }

    return targetProps
  }

  const targetProps = getRenderProps() || {}

  return <ImageHotspotsTargetComponent {...targetProps} reference={reference} />
}

const mapDispatchToProps = dispatch => ({
  setHotspotReference: reference => dispatch(setHotspotRef(reference)),
})

const mapStateToProps = ({ player }) => ({
  hotSpotRef: player.hotSpotRef,
  modal: player.modal,
})

ImageHotspotsTargetContainer.propTypes = {
  id: PropTypes.string,
  index: PropTypes.number,
  interactiveId: PropTypes.string,
  isTargetClicked: PropTypes.bool,
  isToolTipFlipped: PropTypes.bool,
  left: PropTypes.number,
  onClick: PropTypes.func,
  top: PropTypes.number,
  modal: PropTypes.object,
  hotSpotRef: PropTypes.object,
  setHotspotReference: PropTypes.func,
}

ImageHotspotsTargetContainer.defaultProps = {
  left: 0,
  isTargetClicked: false,
  onClick() {},
  top: 0,
  modal: null,
  hotSpotRef: null,
  setHotspotReference: () => {},
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ImageHotspotsTargetContainer)
