import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Button } from 'grommet'
import VideoPlayButtonIcon from './assets/VideoPlayButtonIcon.jsx'

const VideoPlayButton = styled(Button).attrs(({ theme }) => {
  if (!theme.videoPlayButton?.image) {
    return {
      label: <VideoPlayButtonIcon />,
    }
  }
  return {
    label: <img alt="Play video" src={theme.videoPlayButton.image} />,
  }
})`
  padding: 0;
  margin: 0;
  border: none;
  svg,
  img {
    transition: all 0.2s ease-in-out;
    ${({ theme }) =>
      theme.videoPlayButton?.boxShadow &&
      `filter: drop-shadow(${theme.videoPlayButton.boxShadow});`}

    circle {
      fill: ${themeGet(
        'videoPlayButton.background',
        'colors.buttons.primaryBackground',
      )};
    }
  }

  &:hover {
    box-shadow: none;
    svg,
    img {
      ${({ theme }) => theme.videoPlayButton?.throb && 'transform: scale(1.3);'}

      circle {
        fill: ${themeGet(
          'videoPlayButton.backgroundHover',
          'colors.buttons.primaryBackgroundHover',
        )};
      }
    }
  }
`

VideoPlayButton.propTypes = {
  onClick: PropTypes.func,
}

export default VideoPlayButton
