import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  ADDITIONAL_DETAILS_ACTION_TEXT,
  ADDITIONAL_DETAILS_BOLD_TEXT,
} from '../CurriculumPreview/constants'
import { StyledTelescopeIcon } from '../CurriculumPreview/component.styles'
import {
  extractClassesLink,
  getClassesLinkForGrade,
} from '../CurriculumPreview/helpers'

import {
  Container,
  StyledLink,
  Subtitle,
  TelescopeIconWrapper,
  Title,
} from './component.styles'

const CurriculumPreviewTitleModal = ({
  currentSectionIndex,
  currentSlideIndex,
  isCurriculumPreviewMode,
}) => {
  if (
    currentSectionIndex === 0 &&
    currentSlideIndex === 0 &&
    isCurriculumPreviewMode
  ) {
    const actionTextParts = extractClassesLink(ADDITIONAL_DETAILS_ACTION_TEXT)
    const classesLink = getClassesLinkForGrade()
    return (
      <Container>
        <TelescopeIconWrapper>
          <StyledTelescopeIcon />
        </TelescopeIconWrapper>
        <Title>{ADDITIONAL_DETAILS_BOLD_TEXT}</Title>
        <Subtitle>
          {actionTextParts[1]}
          <StyledLink href={classesLink}>{actionTextParts[2]}</StyledLink>
          {actionTextParts[3]}
        </Subtitle>
      </Container>
    )
  }

  return null
}

CurriculumPreviewTitleModal.propTypes = {
  currentSectionIndex: PropTypes.number,
  currentSlideIndex: PropTypes.number,
  isCurriculumPreviewMode: PropTypes.bool,
}

CurriculumPreviewTitleModal.defaultProps = {
  isCurriculumPreviewMode: false,
}

const mapStateToProps = ({
  player: { currentSlide, isCurriculumPreviewMode },
}) => ({
  currentSectionIndex: currentSlide[0],
  currentSlideIndex: currentSlide[1],
  isCurriculumPreviewMode,
})

export default connect(mapStateToProps, {})(CurriculumPreviewTitleModal)
