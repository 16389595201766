import surveyBgImage from '../../../assets/images/k8-g7-survey-slide.jpg'
import * as guide from '../styleGuide'

const { borderRadius, colors } = guide || {}

//TODO: We've accumulated a lot of non-color values in the colorVars object.  Break off any non-color
//values into a separate object
const colorVars = {
  blueFocus: colors.lightBlue,
  black: colors.black,
  teal: colors.lighterTeal,
  grey: colors.gray92,
  general: {
    white: colors.white,
    lightWhite: colors.lightWhite,
  },
  button: {
    cornerRadius: '4px',
    borderWidth: '2px',
    borderStyle: 'solid',
    stroke: '2px',
    height: 33,
    margin: '0 0 5px 0',
    width: 196,
    enabled: {
      background: colors.white,
      color: colors.black,
      border: colors.black,
    },
    disabled: {
      background: 'rgba(255,255,255,.5)',
      color: 'rgba(0,0,0,.5)',
      border: 'rgba(0,0,0,.5)',
      opacity: 0.5,
    },
    hover: {
      background: colors.white,
      color: colors.black,
      border: colors.black,
      dropshadow: '2px 2px 2px rgba(0,0,0,.2)',
    },
    selected: {
      background: 'rgb(83, 82, 80)',
      color: colors.white,
      border: colors.black,
    },
  },
  buttonNavigation: {
    blue: colors.darkBlue,
    disabled: colors.lightYellow,
    hover: colors.darkYellow,
    hoverInner: colors.white,
    lightGrey: colors.lighterGrey,
    darkestBlue: colors.darkestBlue,
    orange: colors.orange,
    white: colors.white,
    grey: colors.darkGrey,
  },
  detailsRevealer: {
    button: {
      color: colors.black,
    },
    message: {
      color: colors.darkestGrey,
    },
  },
  dialogCard: {
    focus: {
      outlineColor: colors.lightBlue,
    },
    textContainer: {
      background: colors.white,
      fill: colors.black,
    },
    turnIndicator: {
      backgroundDefault: colors.flipCardTurnIndicator,
      backgroundHover: colors.lighterGrey,
      fillDefault: colors.white,
      fillHover: colors.black,
    },
  },
  doneSlide: {
    activeState: {
      backgroundColor: colors.doneSlideActiveBackground,
    },
    hoverState: {
      backgroundColor: colors.doneSlideHoverBackground,
    },
  },
  markLessonDoneButton: {
    completedState: {
      backgroundColor: colors.white,
      fontColor: colors.lightGreen,
    },
    defaultState: {
      backgroundColor: colors.white,
      borderColor: colors.lighterGrey,
      checkmarkColor: colors.babyBlue,
      fontColor: colors.babyBlue,
    },
    hoverState: {
      backgroundColor: colors.darkestBlue,
      checkmarkColor: colors.white,
      color: colors.white,
    },
    lastSlideState: {
      backgroundColor: colors.babyBlue,
      checkmarkColor: colors.white,
      fontColor: colors.white,
    },
  },
  dragAndDrop: {
    draggable: {
      backgroundColor: colors.white,
      borderColor: colors.black,
      boxShadowColor: 'rgba(0, 0, 0, 0.5)',
      color: colors.black,
    },
    grip: {
      color: colors.black,
      textShadowColor: colors.black,
    },
    hoverIndicator: {
      colorHover: colors.black,
      colorNoHover: colors.transparent,
    },
  },
  dragLabel: {
    container: {
      background: colors.transparent,
    },
    dropZones: {
      background: colors.transparent,
    },
    dropZone: {
      border: colors.black,
      borderOff: colors.transparent,
      background: 'rgba(255,255,255,.4)',
      backgroundHover: 'rgba(0,0,0,.4)',
      placeholder: {
        background: 'rgba(255, 255, 255, .4)',
        borderLeft: colors.black,
      },
      titleBar: {
        background: colors.white,
        color: colors.black,
      },
    },
  },
  dragQuestion: {
    button: {
      selected: {
        background: 'rgba(0,0,0,.7)',
      },
    },
    container: {
      background: colors.transparent,
    },
    draggable: {
      backgroundColorInactive: 'rgba(255,255,255,.6)',
    },
    dropZone: {
      border: colors.white,
      background: 'rgba(255,255,255,.3)',
      backgroundHover: 'rgba(0,0,0,.3)',
      placeholder: {
        background: 'rgba(255, 255, 255, .4)',
        borderLeft: colors.black,
      },
      titleBar: {
        background: colors.white,
        color: colors.black,
      },
    },
    submissionAnswerIcon: {
      correct: {
        background: colors.darkGreen,
        stroke: '2px solid black',
        color: colors.black,
      },
      incorrect: {
        background: colors.darkRed,
        stroke: '2px solid black',
        color: colors.black,
      },
    },
  },
  dragWord: {
    container: {
      background: colors.teal,
    },
    draggable: {
      backgroundColorInactive: 'rgba(255,255,255,.4)',
    },
    dropZone: {
      border: colors.white,
      borderHover: colors.black,
      background: 'rgba(255,255,255,.3)',
      backgroundHover: 'rgba(0,0,0,.4)',
      placeholder: {
        background: 'rgba(255, 255, 255, .4)',
        borderLeft: colors.black,
      },
      titleBar: {
        background: colors.white,
        color: colors.black,
      },
    },
    button: {
      selected: {
        background: 'rgba(0,0,0,.7)',
      },
    },
    submissionAnswerIcon: {
      correct: {
        background: colors.darkGreen,
        stroke: '2px solid black',
        color: colors.black,
      },
      incorrect: {
        background: colors.darkRed,
        stroke: '2px solid black',
        color: colors.black,
      },
    },
  },
  errorMessage: {
    color: colors.darkerBlue,
  },
  fullScreenButton: {
    maximized: {
      backgroundColor: colors.white,
      borderColor: colors.lighterGrey,
      backgroundColorHover: colors.darkestBlue,
      fill: colors.darkBlue,
      fillHover: colors.white,
    },
    hover: {
      background: colors.blackWithOpacity,
      color: colors.white,
    },
    windowed: {
      backgroundColor: colors.darkBlue,
      borderColor: colors.transparent,
      backgroundColorHover: colors.darkestBlue,
      fill: colors.white,
      fillHover: colors.white,
    },
    transitioningToFullScreen: {
      backgroundColorHover: colors.white,
      fillHover: colors.darkBlue,
      fill: colors.darkBlue,
    },
    transitioningToWindowed: {
      backgroundColorHover: colors.darkestBlue,
      fillHover: colors.white,
      fill: colors.darkBlue,
    },
  },
  httpErrorMessage: {
    button: {
      enabled: {
        backgroundColor: colors.white,
        color: colors.black,
      },
      selected: {
        backgroundColor: colors.darkerGrey,
        color: colors.white,
      },
    },
    message: {
      color: colors.darkestGrey,
    },
    title: {
      color: colors.black,
    },
  },
  imageHotspots: {
    frame: {
      background: 'rgba(0,0,0,.7)',
    },
    target: {
      default: {
        background: colors.white,
        innerBorder: colors.black,
        outerBorder: colors.black,
      },
      hover: {
        background: colors.grey,
        innerBorder: colors.grey,
        outerBorder: colors.black,
      },
      active: {
        background: colors.hotspotsActiveBackground,
        innerBorder: colors.white,
        outerBorder: colors.black,
      },
      selected: {
        background: colors.black,
        innerBorder: colors.white,
        outerBorder: colors.black,
      },
      toolTip: {
        background: '#fff',
        border: '#000',
      },
    },
  },
  imageJuxtaposition: {
    background: 'transparent',
    frame: {
      background: 'rgba(0,0,0,.7)',
      divider: colors.black,
    },
    handle: {
      background: colors.white,
      backgroundDrag: colors.black,
      border: colors.black,
      borderDrag: colors.white,
      dropShadow: colors.black,
    },
  },
  layout: {
    container: {
      padding: '2.75rem',
    },
    element: {
      marginLeft: '1.375rem',
      marginRight: '1.375rem',
    },
    textBubble: {
      borderRadius: '10px',
      padding: '44px',
    },
  },
  lessonComplete: {
    container: {
      backgroundColor: colors.white,
    },
    body: {
      color: colors.lessonCompleteGrey,
    },
    exitLesson: {
      background: colors.exitLessonLightBlue,
      color: colors.white,
    },
    lessonButtonFilled: {
      background: colors.exitLessonLightBlue,
      color: colors.white,
      border: 'none',
    },
    lessonButtonDefault: {
      background: colors.white,
      color: colors.exitLessonLightBlue,
      border: `2px solid ${colors.gray93}`,
    },
    markCompleteModal: {
      blankButton: {
        background: colors.white,
        color: colors.gray94,
        border: `2px solid ${colors.gray93}`,
      },
    },
    exitLessonHover: {
      background: colors.exitLessonDarkBlue,
      color: colors.white,
    },
    feedbackUrl: {
      color: colors.exitLessonLightBlue,
    },
    feedbackUrlHover: {
      color: colors.exitLessonDarkBlue,
    },
    title: {
      color: colors.darkestBlue,
    },
  },
  modal: {
    background: colors.white,
    border: colors.black,
    color: colors.black,
    overlay: {
      background: 'rgba(0, 0, 0, 0.8)',
    },
  },
  multichoice: {
    button: {
      selected: {
        background: 'rgba(0,0,0,.7)',
      },
    },
    container: {
      background: 'transparent',
    },
    optionsContainer: {
      background: colors.white,
    },
    option: {
      defaultBackground: colors.white,
      selectedBackground: colors.white,
      wrongBackground: colors.white,
      color: colors.black,
      correct: colors.darkGreen,
      hover: colors.white,
      incorrect: colors.darkRed,
      correctMarkedIncorrect: colors.white,
      indicator: {
        default: colors.white,
        selected: colors.black,
        disabled: colors.grey,
        hover: colors.grey,
      },
    },
  },
  prepBanner: {
    backgroundColor: 'rgba(0, 40, 85, 0.8)',
    color: colors.white,
  },
  hsPreviewBanner: {
    backgroundColor: 'rgba(250, 244, 149, 0.85)',
    color: '#023125',
  },
  ratioWrapper: {
    backgroundColor: colors.black,
  },
  titleBar: {
    height: 2.6, // rem
  },
  menuBar: {
    height: 3.9, // rem
  },
  slide: {
    backgroundColor: colors.white,
  },
}

const fonts = {
  display: {
    fontFamily: 'filson-pro, sans-serif',
    fontStyle: 'normal',
    fontSize: '1.83em',
    fontWeight: 400,
    lineHeight: 'normal',
    margin: 0,
  },
  generic: {
    fontFamily: 'filson-pro, sans-serif',
    fontSize: '175%',
    lineHeight: '1em',
    fontWeight: 300,
  },
  p: {
    fontFamily: 'filson-pro, sans-serif',
    fontSize: '2.05rem',
    lineHeight: '3.3125rem',
    fontWeight: 300,
  },
  li: {
    fontFamily: 'filson-pro, sans-serif',
    fontSize: '2em',
    lineHeight: '1.3em',
    fontWeight: 300,
  },
  h1: {
    fontSize: '3rem',
    fontWeight: 'bold',
    lineHeight: '4rem',
  },
  h2: {
    fontSize: '4.25em',
    fontWeight: 'bold',
  },
  h3: {
    fontSize: '3em',
    fontWeight: 'bold',
  },
  h4: {
    fontSize: '2em',
  },
  h5: {
    fontSize: '1.53em',
    lineHeight: '1.8em',
    fontWeight: 300,
  },
  h6: {
    fontSize: '1.25em',
  },
  handWriting: {
    fontFamily: 'Schoolbell, cursive',
    fontWeight: '400',
    fontSize: '407%',
    lineHeight: '1.5',
    paddingBottom: '18px',
    letterSpacing: '0.12em',
    wordSpacing: '0.16em',
  },
  button: {
    fontFamily: 'filson-pro, sans-serif',
    fontSize: '22px',
    fontWeight: 500,
  },
  detailsRevealer: {
    button: {
      fontFamily: 'filson-pro, sans-serif',
      fontSize: '.9rem',
      fontWeight: 500,
    },
    message: {
      fontFamily: 'filson-pro, sans-serif',
      fontSize: '.9rem',
    },
  },
  dialogCard: {
    fontFamily: 'filson-pro, sans-serif',
    title: {
      fontSize: '2.0625em',
    },
    turnIndicator: {
      fontSize: '1.5626em',
      fontStyle: 'normal',
      fontWeight: 400,
    },
  },
  dragAndDrop: {
    draggable: {
      fontFamily: 'filson-pro, sans-serif',
      fontSize: '1.45', //rem
      fontWeight: 300,
    },
    grip: {
      fontFamily: 'sans-serif',
      fontSize: 0.75, //rem
      letterSpacing: '2px',
      lineHeight: '5px',
    },
  },
  dragQuestion: {
    dropZone: {
      title: {
        fontFamily: 'filson-pro, sans-serif',
        fontSize: '30px',
        fontWeight: 700,
      },
    },
    resetButton: {
      fontFamily: 'filson-pro, sans-serif',
      fontSize: '1.5625em',
      height: '1.5em',
      fontWeight: 500,
    },
    submitButton: {
      fontFamily: 'filson-pro, sans-serif',
      fontSize: '1.5625em',
      height: '1.5em',
      fontWeight: 700,
    },
  },
  dragWord: {
    dropZone: {
      title: {
        fontFamily: 'filson-pro, sans-serif',
        fontSize: '40px',
        fontWeight: 700,
      },
    },
    resetButton: {
      fontFamily: 'filson-pro, sans-serif',
      fontSize: '1.5625em',
      height: '1.5em',
      fontWeight: 500,
    },
    submitButton: {
      fontFamily: 'filson-pro, sans-serif',
      fontSize: '1.5625em',
      height: '1.5em',
      fontWeight: 700,
    },
  },
  feedback: {
    fontFamily: 'filson-pro, sans-serif',
    fontSize: '165%',
    fontWeight: 300,
    letterSpacing: '0.0075em',
    wordSpacing: '0.01em',
  },
  helper: {
    fontFamily: 'filson-pro, sans-serif',
    fontSize: '1.5625rem',
    fontWeight: 300,
    fontStyle: 'italic',
    letterSpacing: '0.0075em',
    wordSpacing: '0.01em',
  },
  httpErrorMessage: {
    button: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    title: {
      fontFamily: 'filson-pro, sans-serif',
      fontSize: '1.8rem',
      fontWeight: 600,
    },
    message: {
      fontFamily: 'filson-pro, sans-serif',
      fontSize: '1.2rem',
    },
  },
  imageHotspots: {
    tooltip: {
      fontFamily: "'filson-pro, sans-serif'",
      fontSize: '1.125rem',
      fontStyle: 'normal',
      fontWeight: 400,
    },
  },
  lessonComplete: {
    body: {
      fontFamily: 'molde-medium, sans-serif',
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
    exitLesson: {
      fontFamily: 'molde-medium, sans-serif',
      fontSize: '1rem',
      lineHeight: '1.1875rem',
    },
    title: {
      fontFamily: 'SharpSlab-Extrabold, sans-serif',
      lineHeight: '1.65rem',
      fontSize: '1.625rem',
    },
  },
  multiChoiceButton: {
    fontFamily: 'filson-pro, sans-serif',
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: '1.5625em',
    height: '1.5em',
  },
  prepBannerBold: {
    fontFamily: 'molde-medium',
    fontSize: '1rem',
    lineHeight: '1.25rem',
    textAlign: 'center',
  },
  prepBannerRegular: {
    fontFamily: 'molde-regular',
    fontSize: '1rem',
    lineHeight: '1.25rem',
    textAlign: 'center',
  },
}

export default {
  borderRadius,
  colors,
  colorVars,
  fonts,
  backgrounds: {
    surveySlideImage: surveyBgImage,
    surverySlideForeground: 'white 70% opacity',
  },
}
