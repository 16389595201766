import React, { useState } from 'react'

import PropTypes from 'prop-types'

import DetailsRevealerComponent from './component'
import { CLICK, ENTER_KEY, SPACE_KEY } from './constants'

const DetailsRevealerContainer = ({ children }) => {
  const [showDetails, setShowDetails] = useState(false)

  const onClick = event => {
    const { key, type } = event || {}
    const isClick = type === CLICK
    const isEnterKey = key === ENTER_KEY
    const isSpaceKey = key === SPACE_KEY
    const actionIsValid = isClick || isEnterKey || isSpaceKey

    actionIsValid && setShowDetails(prevState => !prevState)
  }

  return (
    <DetailsRevealerComponent
      message={children}
      onClick={onClick}
      showMessage={showDetails}
    />
  )
}

DetailsRevealerContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
}

export default DetailsRevealerContainer
