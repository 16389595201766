/* istanbul ignore file */
// we don't test SVGs without arguments
import PropTypes from 'prop-types'
import React from 'react'

const NavArrow = props => {
  return (
    <svg height="14px" viewBox="0 0 8 14" width="8px">
      <g
        fill="none"
        fillRule="evenodd"
        id="SELA_MLheader_backarrow"
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <polyline
          id="Path"
          points="-2 4 3.91301546 10 10 4"
          stroke={props.strokeColor}
          strokeWidth="2"
          transform="translate(4.000000, 7.000000) rotate(-270.000000) translate(-4.000000, -7.000000) "
        />
      </g>
    </svg>
  )
}
NavArrow.defaultProps = { strokeColor: 'black' }

NavArrow.propTypes = {
  strokeColor: PropTypes.string,
}

export default NavArrow
