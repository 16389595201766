/* istanbul ignore file */
// we don't unit test SVGs
import React from 'react'
import PropTypes from 'prop-types'

const YieldIcon = ({ color = '#FFBF3F', dataTestId }) => (
  <svg
    className="yield-icon"
    data-testid={dataTestId}
    height="69"
    viewBox="0 0 79 69"
    width="79"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-344 -316)">
        <g transform="translate(146 260)">
          <g transform="translate(194 50)">
            <path
              d="M48.693 8.976l32.99 57.02A6 6 0 0176.49 75H10.51a6 6 0 01-5.193-9.005l32.99-57.019a6 6 0 0110.386 0z"
              fill={color}
            />
            <path
              d="M43.5 26L43.5 49.815"
              stroke="#FFF"
              strokeLinecap="round"
              strokeWidth="7"
            />
            <circle cx="43.5" cy="61.5" fill="#FFF" r="3.5" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

YieldIcon.propTypes = {
  color: PropTypes.string,
  dataTestId: PropTypes.string.isRequired,
}

export default YieldIcon
