import styled from 'styled-components'
import { themeGet } from 'styled-system'
import CollapsibleButton from '../CollapsibleButton'
import HeaderOne from '../HeaderOne'
import HeaderTwo from '../HeaderTwo'
import BodyCopy from '../BodyCopy'
import BaseContainer from '../BaseContainer'

export const StyledContainer = styled(BaseContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.875rem 0 1.125rem;
  text-align: center;
  width: 100%;
`

export const ContentWrapper = styled.div`
  ${themeGet('breakpoints.tablet')} {
    padding: 0;
  }
`

export const CollapsibleButtonStyled = styled(CollapsibleButton)`
  padding-bottom: 0.5rem;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;

  button {
    &:not(:last-of-type) {
      margin-right: 1rem;
    }
  }
`

export const Header = styled(HeaderOne)`
  margin: 0.875rem 0 1.125rem;
  text-align: center;
`

export const SubHeader = styled(HeaderTwo)`
  color: ${themeGet('colors.darkGray')};
  font-family: ${themeGet('fontFamilySemibold')};
  margin-bottom: 0.25rem;
  text-align: center;
`

export const BodyCopyStyled = styled(BodyCopy)`
  margin-bottom: 0.5rem;
  padding: 0.5rem 0;
  ${themeGet('breakpoints.tablet')} {
    padding: 0.5rem 0;
  }
`
export const ErrorBodyCopy = styled(BodyCopy)`
  margin-bottom: 0;
`
