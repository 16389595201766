import styled from 'styled-components'
import { themeGet } from 'styled-system'
import ContainedButton from '../ContainedButton'

const OutlinedButton = styled(ContainedButton)`
  background: none;
  color: ${themeGet('colors.buttons.primaryBackground')};
  border: 2px solid ${themeGet('colors.buttons.primaryBackground')};
  padding-left: 0.8438rem;
  padding-right: 0.8438rem;

  &:not([disabled]):hover {
    background: ${themeGet('colors.buttons.primaryBackgroundHover')};
    border-color: ${themeGet('colors.buttons.primaryBackgroundHover')};
    color: ${themeGet('colors.buttons.primaryFont')};
  }
`

export default OutlinedButton
