import React from 'react'

import PropTypes from 'prop-types'

import DragLabelEvaluation from './DragLabelEvaluation'
import DragLabelFrame from './DragLabelFrame'
import DragLabelHeader from './DragLabelHeader'
import DragLabelOriginNodes from './DragLabelOriginNodes'
import { ColumnsContainer, Container, LeftColumn } from './style'

const DragLabelComponent = ({
  bodyText,
  draggables,
  dropZones,
  imageAlt,
  imageSource,
  canCheck,
  canReset,
  helperText,
  longLabels,
  numOfOriginColumns,
  onCheck,
  onDragEnd,
  onDragStart,
  onDrop,
  onReset,
  questions,
  showFrameDropZones,
  title,
}) => {
  return (
    <Container>
      <ColumnsContainer>
        <LeftColumn>
          <DragLabelHeader bodyText={bodyText} title={title} />
          <DragLabelOriginNodes
            draggables={draggables}
            longLabels={longLabels}
            numOfColumns={numOfOriginColumns}
            onDragEnd={onDragEnd}
            onDragStart={onDragStart}
          />
        </LeftColumn>
        <DragLabelFrame
          dropZones={dropZones}
          imageAlt={imageAlt}
          imageSource={imageSource}
          longLabels={longLabels}
          onDragEnd={onDragEnd}
          onDragStart={onDragStart}
          onDrop={onDrop}
          questions={questions}
          showDropZones={showFrameDropZones}
        />
      </ColumnsContainer>
      <DragLabelEvaluation
        canCheck={canCheck}
        canReset={canReset}
        helperText={helperText}
        onCheck={onCheck}
        onReset={onReset}
      />
    </Container>
  )
}

DragLabelComponent.propTypes = {
  bodyText: PropTypes.string,
  buttonState: PropTypes.object,
  canCheck: PropTypes.bool,
  canReset: PropTypes.bool,
  draggables: PropTypes.arrayOf(PropTypes.object),
  dropZones: PropTypes.arrayOf(PropTypes.object),
  helperText: PropTypes.string,
  imageAlt: PropTypes.string,
  imageSource: PropTypes.string,
  longLabels: PropTypes.bool,
  numOfOriginColumns: PropTypes.number,
  onCheck: PropTypes.func,
  onDragEnd: PropTypes.func,
  onDragStart: PropTypes.func,
  onDrop: PropTypes.func,
  onReset: PropTypes.func,
  questions: PropTypes.object,
  showFrameDropZones: PropTypes.bool,
  title: PropTypes.string,
}

DragLabelComponent.defaultProps = {
  canCheck: true,
  canReset: false,
  draggables: [],
  dropZones: [],
  numOfOriginColumns: 2,
  onCheck() {},
  onDragEnd() {},
  onDragStart() {},
  onDrop() {},
  onReset() {},
}

export default DragLabelComponent
