import React, { Component } from 'react'

import PropTypes from 'prop-types'

import DragQuestionEvaluationTextComponent from './component'

export class DragQuestionEvaluationTextContainer extends Component {
  render = () => {
    const { showErrorMessage } = this.props

    return (
      <DragQuestionEvaluationTextComponent
        showErrorMessage={showErrorMessage}
      />
    )
  }
}

DragQuestionEvaluationTextContainer.propTypes = {
  showErrorMessage: PropTypes.bool,
}

export default DragQuestionEvaluationTextContainer
