import React from 'react'
import PropTypes from 'prop-types'
import { Cell, Row } from './component.styles'

const AutoTableRow = React.forwardRef(
  (
    {
      columns,
      dataTestId,
      handleRowClick,
      id,
      multiColSpanValue,
      resolveCellValue,
      resolveClassName,
      rowClassName,
      rowData,
      rowIndex,
    },
    ref,
  ) => {
    const resolvedClassName = resolveClassName(rowClassName, rowData)
    const role = handleRowClick ? 'button' : null
    const tabIndex = handleRowClick ? 0 : null

    return (
      <Row
        className={resolvedClassName}
        data-testid={dataTestId}
        key={`${id}_tr_${rowIndex}`}
        onClick={e => handleRowClick && handleRowClick(e, rowData)}
        onKeyDown={e => handleRowClick && handleRowClick(e, rowData, rowIndex)}
        ref={ref}
        role={role}
        tabIndex={tabIndex}
      >
        {columns.map((column, colIndex) => {
          const resolvedCellData = resolveCellValue(column, rowData)
          const resolvedCellClassName = resolveClassName(
            column.cellClassName,
            rowData,
          )
          const colSpan = typeof rowData === 'string' ? multiColSpanValue : '1'

          if (!resolvedCellData) return null
          return (
            <Cell
              colSpan={colSpan}
              data-testid={dataTestId}
              key={`${id}_td_${(rowIndex + 1) * colIndex}`}
            >
              <div className={resolvedCellClassName}>{resolvedCellData}</div>
            </Cell>
          )
        })}
      </Row>
    )
  },
)

AutoTableRow.propTypes = {
  columns: PropTypes.array.isRequired,
  dataTestId: PropTypes.string,
  handleRowClick: PropTypes.func,
  id: PropTypes.string.isRequired,
  multiColSpanValue: PropTypes.number,
  resolveCellValue: PropTypes.func,
  resolveClassName: PropTypes.func,
  rowClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowData: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.string.isRequired,
  ]),
  rowIndex: PropTypes.number.isRequired,
}

export default AutoTableRow
