/* istanbul ignore file */
// we don't test SVGs without arguments
const LearnAudioDescriptionDefault = () => {
  return (
    <svg height="30px" viewBox="0 0 30 30" width="30px">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-1271.000000, -938.000000)">
          <rect fill="#FFFFFF" height="988" width="1366" x="0" y="0" />
          <rect
            fill="#FFFFFF"
            height="987"
            rx="20"
            stroke="#979797"
            width="1367"
            x="0.5"
            y="0.5"
          />
          <g fillRule="nonzero" transform="translate(1271.000000, 938.000000)">
            <path
              d="M0,6 L0,24 C4.05812251e-16,27.3137085 2.6862915,30 6,30 L24,30 C27.3137085,30 30,27.3137085 30,24 L30,6 C30,2.6862915 27.3137085,-6.08718376e-16 24,0 L6,0 C2.6862915,6.08718376e-16 -4.05812251e-16,2.6862915 0,6 Z"
              fill="#FFFFFF"
              stroke="#002855"
            />
            <g fill="#002855" transform="translate(3.000000, 10.000000)">
              <path d="M17.8104395,0.219567323 L18.2867279,0.219567323 C18.2867279,0.219567323 20.0209063,2.7042299 19.9857953,4.64158863 C19.9216795,8.30319664 18.3340514,9.7933484 18.3340514,9.7933484 L17.8180723,9.7933484 C17.8180723,9.7933484 19.3354784,7.52018082 19.2851017,4.90636099 C19.2270922,2.37487892 17.8104395,0.219567323 17.8104395,0.219567323 Z" />
              <path d="M19.8087137,0.219567323 C19.8087137,0.219567323 21.367337,2.3522764 21.3352792,4.99031321 C21.297115,7.72360349 19.8087137,9.788505 19.8087137,9.788505 L20.3292725,9.788505 C20.3292725,9.788505 21.8787365,8.18372619 22.0084945,5.29867614 C22.147412,2.25217953 20.3292725,0.219567323 20.3292725,0.219567323 L19.8087137,0.219567323 Z" />
              <path d="M21.8588911,0.219567323 L22.3321264,0.219567323 C22.3321264,0.219567323 23.9579187,2.17952858 23.9991359,4.99031321 C24.0434063,8.01259283 22.3718171,9.788505 22.3718171,9.788505 L21.810041,9.788505 C22.790374,8.40011545 23.3252725,6.71659783 23.3366065,4.98385534 C23.3564519,2.68969971 21.8588911,0.219567323 21.8588911,0.219567323 Z" />
              <path d="M6.7397866,0 L0,10 L3.37370971,9.99031321 L4.18736911,8.71972877 L7.23286725,8.71972877 L7.27255795,9.98869874 L9.56240617,9.98869874 L9.56240617,0 L6.7397866,0 Z M5.41625433,6.49015176 L7.32598774,3.35808847 L7.35193936,6.49015176 L5.41625433,6.49015176 Z" />
              <path d="M13.9070115,0.0129157249 L10.1974574,0.0129157249 L10.1974574,9.97255408 L13.8810599,9.97255408 C13.8810599,9.97255408 17.9295116,9.29932192 17.8852412,4.9483371 C17.8424973,0.820148531 13.9070115,0.0129157249 13.9070115,0.0129157249 Z M12.3804461,7.41039716 L12.4231899,2.58314498 C12.4231899,2.58314498 15.1572687,2.46044559 15.1603318,4.84339684 C15.1664281,7.78495318 12.3804461,7.41039716 12.3804461,7.41039716 Z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default LearnAudioDescriptionDefault
