import styled from 'styled-components'

import { ChromeTitleBar } from '../../text'

export const Wrapper = styled.div`
  display: flex;
  flex: 1 1 0%;
  padding-left: 0.9rem;
`
export const TitleText = styled(ChromeTitleBar)`
  font-size: 1em;
  padding-right: 0.2rem;
`
