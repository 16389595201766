import React from 'react'

import PropTypes from 'prop-types'

import { ToolTip } from './style'

const ImageHotSpotsToolTip = ({
  index,
  isToolTipFlipped,
  isVisible,
  toolTip,
}) => {
  const dataTestId = `tool-tip-${index}`
  return (
    <ToolTip
      data-testid={dataTestId}
      isToolTipFlipped={isToolTipFlipped}
      isVisible={isVisible}
    >
      {toolTip}
    </ToolTip>
  )
}

ImageHotSpotsToolTip.propTypes = {
  index: PropTypes.number,
  isToolTipFlipped: PropTypes.bool,
  isVisible: PropTypes.bool,
  toolTip: PropTypes.string,
}

ImageHotSpotsToolTip.defaultProps = {
  toolTip: '',
}

export default ImageHotSpotsToolTip
