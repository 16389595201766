import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="25"
      viewBox="0 0 32 25"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-1 2)">
          <path
            fill="#3C6E90"
            fillRule="nonzero"
            d="M22.562 4.842c-.75-.005-1.34.563-1.334 1.288l.002.21L5.41 10.05a1.393 1.393 0 00-1.194-.734c-.75-.006-1.34.563-1.334 1.287l.042 5.341c.006.725.605 1.303 1.355 1.308.531.005.966-.294 1.182-.714l3.07.778 9.896 2.49 2.913.717.002.212c.005.724.604 1.302 1.354 1.308.75.005 1.34-.563 1.334-1.288L23.916 6.15c-.005-.724-.635-1.302-1.354-1.308z"
            transform="rotate(-30 13.457 13.443)"
          />
          <path
            stroke="#3C6E90"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M26.363 6.214L29.075 4.439"
          />
          <path
            stroke="#3C6E90"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M27.719 10.209L30.883 10.653"
          />
          <path
            stroke="#3C6E90"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M24.556 0L23.544 2.981"
          />
          <path
            stroke="#3C6E90"
            strokeLinejoin="round"
            strokeWidth="1.571"
            d="M12.73 16.225l5.224-1.471a.898.898 0 011.105.612l.992 3.397a.898.898 0 01-.619 1.116l-5.223 1.47a.898.898 0 01-1.105-.612l-.992-3.397a.898.898 0 01.618-1.115z"
          />
        </g>
      </g>
    </svg>
  )
}

export default Icon
