import React from 'react'

import PropTypes from 'prop-types'
import { useDrop } from 'react-dnd'
import { useDispatch, useSelector } from 'react-redux'

import {
  updateCmiInteractives,
  updateLMSSuspendData,
} from '../../lib/state/actions/cmi'
import {
  completeSlide,
  dragWordSubmit,
  dragWordUpdate,
} from '../../lib/state/actions/player'
import {
  BUTTON_STATES,
  getDraggableWithId,
} from '../../utils/dragAndDropHelper'

import DragWordDraggables from './DragWordDraggables'
import DragWordEvaluation from './DragWordEvaluation'
import DragWordQuestions from './DragWordQuestions'
import { DragWordDropZoneContainer } from './style'

const noDropZonesOccupied = questions =>
  questions.every(question => question.draggable === null)
const allDropZonesOccupied = questions =>
  questions.every(question => question.draggable)
const allDropZonesCorrect = questions =>
  questions.every(question => question.correct)

const DragWordComponent = props => {
  const { children, id } = props
  const dispatch = useDispatch()
  const {
    buttons,
    draggables,
    isErrorVisible,
    questions,
    attempts,
    startTime,
    endTime,
  } = useSelector(({ player }) => player.interactiveStates[id]) || {}
  const completeDragWord = slide => dispatch(completeSlide(slide))
  const submitDragWord = newState => dispatch(dragWordSubmit(id, newState))
  const currentSlide = useSelector(({ player }) => player.currentSlide)
  const updateDragWordState = state => {
    dispatch(dragWordUpdate(id, state))

    // If connected to LMS, save data to cmi in Redux and send to db
    updateSuspendData(state)
  }

  const updateSuspendData = state => {
    // If connected to LMS, save data to cmi in Redux and send to db
    const { buttons, draggables, isErrorVisible, questions, attempts } =
      state || {}
    dispatch(
      updateCmiInteractives({
        interactives: {
          [id]: {
            buttons,
            draggables,
            isErrorVisible,
            questions,
            attempts,
          },
        },
      }),
    )
    dispatch(updateLMSSuspendData())
  }

  // Check if draggable is dropped outside of a valid dropzone
  const [, drop] = useDrop({
    accept: 'DraggableItem',
    drop: (draggable, monitor) => {
      if (!monitor.didDrop()) {
        clearDraggable(draggable)
      }
    },
  })

  const onCheck = () => {
    if (!allDropZonesOccupied(questions)) {
      updateDragWordState({
        buttons: BUTTON_STATES.ON_CHECK,
        draggables,
        isErrorVisible: true,
        questions,
        attempts,
      })

      return
    }

    let score = 0
    const updatedQuestions = questions.map(question => {
      const isCorrect =
        question.draggable && question.draggable?.zones?.includes(question.id)
      if (isCorrect) score++
      return {
        ...question,
        graded: true,
        correct: isCorrect,
      }
    })
    const updatedAttempts = attempts + 1

    allDropZonesCorrect(updatedQuestions) && completeDragWord(currentSlide)
    submitDragWord({
      buttons: BUTTON_STATES.ON_CHECK,
      questions: updatedQuestions,
      attempts: updatedAttempts,
      score,
    })
    updateSuspendData({
      buttons: BUTTON_STATES.ON_CHECK,
      draggables,
      isErrorVisible: false,
      questions: updatedQuestions,
      attempts: updatedAttempts,
    })
  }

  const onReset = () => {
    const updatedQuestions = questions.map(question => ({
      ...question,
      draggable: null,
      graded: false,
    }))
    const updatedDraggables = draggables.map(draggable => ({
      ...draggable,
      dropped: false,
    }))

    const newStartTime = endTime || startTime

    updateDragWordState({
      buttons: BUTTON_STATES.ON_RESET,
      draggables: updatedDraggables,
      isErrorVisible: false,
      questions: updatedQuestions,
      startTime: newStartTime,
      endTime: null,
    })
  }

  const clearDraggable = draggableItem => {
    const { id: draggableToClearId } = draggableItem || {}
    const updatedDraggables = draggables.map(draggable => {
      const { id: currentDraggableId } = draggable || {}
      const foundMatchingDraggable = currentDraggableId === draggableToClearId

      if (foundMatchingDraggable) {
        draggable.dropped = false
      }

      return draggable
    })
    const updatedQuestions = questions.map(question => {
      const { draggable: questionDraggable } = question || {}
      const { id: questionDraggableId } = questionDraggable || {}
      const foundMatchingDraggable = questionDraggableId === draggableToClearId

      if (foundMatchingDraggable) {
        question.draggable = null
      }

      return question
    })
    const updatedButtons = noDropZonesOccupied(updatedQuestions)
      ? BUTTON_STATES.ON_RESET
      : buttons

    updateDragWordState({
      buttons: updatedButtons,
      draggables: updatedDraggables,
      isErrorVisible,
      questions: updatedQuestions,
    })
  }

  const onDrop = (questionIndex, draggableId) => {
    const formattedDraggable = getDraggableWithId(draggables, draggableId)
    const getUpdatedQuestions = () => {
      const newQuestions = questions.map(question => {
        if (question.draggable && question.draggable?.id === draggableId) {
          question.draggable = null
        }

        return question
      })

      formattedDraggable.dropped = true
      newQuestions[questionIndex].draggable = formattedDraggable

      return [...newQuestions]
    }
    const updatedQuestions = getUpdatedQuestions()
    const updatedDraggables = draggables.map(draggable => {
      if (draggable.id === formattedDraggable.id) {
        draggable.dropped = true
      }

      return draggable
    })
    const updatedIsErrorVisible = allDropZonesOccupied(updatedQuestions)
      ? false
      : isErrorVisible

    updateDragWordState({
      buttons: BUTTON_STATES.ON_DROP,
      draggables: updatedDraggables,
      isErrorVisible: updatedIsErrorVisible,
      questions: updatedQuestions,
    })

    return formattedDraggable
  }

  return (
    <DragWordDropZoneContainer ref={drop}>
      {children}
      <DragWordDraggables draggables={draggables} />
      <DragWordQuestions questions={questions} onDrop={onDrop} />
      <DragWordEvaluation
        buttons={buttons}
        isErrorVisible={isErrorVisible}
        onCheck={onCheck}
        onReset={onReset}
      />
    </DragWordDropZoneContainer>
  )
}

DragWordComponent.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  id: PropTypes.string,
}

export default DragWordComponent
