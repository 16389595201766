import styled from 'styled-components'
import { Box, Select } from 'grommet'
import { themeGet } from 'styled-system'

import { fontWeightNormal } from '../../theme/defaultValues'
import FormField from '../FormField'
import IconCaretSelect from '../IconCaretSelect'

export const FormFieldStyled = styled(FormField)`
  button {
    background: ${themeGet('colors.white')};
    border: 2px solid
      ${themeGet('colors.borders.primary', 'colors.input.border')};
    border-radius: ${themeGet('borders.radius', '5px')};
  }

  label {
    font-family: ${themeGet('font.labels.primary')};
  }

  input {
    border: none;
    font-family: ${themeGet('font.inputs.primary')};
  }
`

export const SelectStyled = styled(Select).attrs(
  ({ dataTestId, defaultValue }) => ({
    'data-testid': dataTestId,
    defaultValue,
  }),
)`
  font-family: ${themeGet('font.inputs.primary', 'fontFamilySemibold')};
  font-weight: ${fontWeightNormal};
  padding: 0.6875rem 0.6875rem 0.4375rem;

  &::placeholder {
    font-family: ${themeGet('font.inputs.primary', 'fontFamily')};
    font-weight: 400;
    opacity: 1;
  }
`

export const SelectOptionsWrapper = styled(Box)`
  margin: ${themeGet('select.options.container.margin')};
  overflow: ${themeGet('select.options.container.overflow')};
  padding: ${themeGet('select.options.container.pad')};
  // TODO: update styles https://secondstep.atlassian.net/browse/LEARN-8382
`

export const IconStyled = styled(IconCaretSelect).attrs(({ theme }) => ({
  color: theme.colors?.icon?.light || 'white',
  rotation: 180,
}))``
