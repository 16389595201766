/* istanbul ignore file */
// no logic to test
import styled from 'styled-components'
import { themeGet } from 'styled-system'

const Hairline = styled.div`
  height: 1px;
  width: 100%;
  border-radius: 0.5px;
  background-color: ${themeGet('colors.gray500')};
`

export default Hairline
