/* istanbul ignore file */
// only visual differences from default ProgressBar
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  Background,
  Indicator,
  ProgressOutsideBar,
} from '../ProgressBarIndicator/component.styles'

export const StyledProgressBarContainer = styled.div`
  ${Background} {
    background-color: ${props =>
      themeGet('colors.progressBar.background.medium')(props) || '#eef0f2'};
    width: ${props => themeGet('progressBar.width.medium')(props) || '10rem'};
    height: ${props =>
      themeGet('progressBar.height.medium')(props) || '0.625rem'};
    margin-top: 0.5625rem;
    border: 0;
  }
  ${Indicator} {
    margin-top: 0;
    height: ${props =>
      themeGet('progressBar.height.medium')(props) || '0.625rem'};
    background-color: ${props => themeGet('colors.blue')(props) || '#3c6e90'};
    border: 0;
  }
  ${ProgressOutsideBar} {
    font-family: ${props =>
      themeGet('progressBar.fontFamily')(props) || 'inherit'};
  }
`

StyledProgressBarContainer.displayName = 'StyledProgressBarContainer'
