import styled from 'styled-components'

export const DropZone = styled.div.attrs(
  ({
    isActive,
    theme: {
      colorVars: {
        dragWord: { dropZone },
      },
    },
  }) => ({
    backgroundColor: isActive ? dropZone.backgroundHover : dropZone.background,
    backgroundColorHover: dropZone.backgroundHover,
    borderColor: isActive ? dropZone.borderHover : dropZone.border,
    marginRight: '10px',
  }),
)`
  background-color: ${props => props.backgroundColor};
  border: 1px solid ${props => props.borderColor};
  margin-right: ${props => props.marginRight};
  border-radius: 5px;
  height: 45px;
  min-width: 253px;
`
