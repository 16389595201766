/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'
import PropTypes from 'prop-types'

const IconDoc = ({ color = 'currentColor' }) => {
  const height = 22
  const width = 18
  const heightWithUnits = `${height}px`
  const widthWithUnits = `${width}px`

  const viewBox = `0 0 ${width} ${height}`

  return (
    <svg height={heightWithUnits} viewBox={viewBox} width={widthWithUnits}>
      <title>Open Document</title>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-100.000000, -99.000000)">
          <g id="doc-icon" transform="translate(100.000000, 99.000000)">
            <path
              d="M2.24911987,0 C1.0078793,0 0,1.002 0,2.238 L0,2.238 L0,19.764 C0,20.999 1.0078793,22.001 2.24911987,22.001 L2.24911987,22.001 L15.7488684,22.001 C16.9921207,22.001 18,20.999 18,19.764 L18,19.764 L18,5.207 L11.8350377,0 L2.24911987,0 Z"
              fill={color}
            />
            <path
              d="M3.36403999,18 C5.07464993,18 6.06508986,17.18754 6.05408986,15.55195 L6.05409003,12.6951399 C6.05409003,11.0878199 5.06452995,10.3 3.36492001,10.3 L1.61031007,10.3 L1.61031007,18 L3.36403999,18 Z M3.36403983,16.7672299 L3.12016985,16.7672299 L3.12016985,11.5327699 L3.36491985,11.5327699 C4.10345984,11.5327699 4.49692989,11.8644198 4.49692989,12.6951399 L4.49692989,15.55195 C4.49692989,16.4109399 4.10345984,16.7672299 3.36403983,16.7672299 Z M9.08018993,18.10076 C10.5066698,18.10076 11.4029498,17.20591 11.4029498,15.7316899 L11.4029498,12.53454 C11.4029498,11.08342 10.5072199,10.20122 9.08139993,10.19979 C7.65755991,10.2002299 6.76281995,11.08254 6.76281995,12.53454 L6.76281995,15.7316899 C6.76281995,17.20591 7.65711998,18.10076 9.08018993,18.10076 Z M9.08018993,16.87371 C8.59618993,16.87371 8.32008986,16.49322 8.32008986,15.7597401 L8.32008986,12.5064901 C8.32008986,11.7961101 8.59673979,11.4262902 9.08139977,11.4257401 C9.56836981,11.4271702 9.84600987,11.7968802 9.84600987,12.5064901 L9.84600987,15.7597401 C9.84600987,16.49322 9.56781995,16.87371 9.08018993,16.87371 Z M14.4343302,18.1002103 C15.70329,18.1002103 16.5448999,17.3392301 16.5681099,16.0964501 L16.5694301,15.1713501 L15.1567,15.1713501 L15.1558199,16.0690601 C15.1439399,16.56571 14.8597,16.86876 14.4457699,16.86876 C13.98399,16.86876 13.67709,16.49399 13.67709,15.7421399 L13.67709,12.5337699 C13.67709,11.78654 13.98575,11.4308 14.44522,11.4308 C14.84551,11.4322301 15.1454801,11.7029401 15.1558201,12.16076 L15.1567001,12.8967701 L16.5694301,12.8967701 L16.5681101,12.1340301 C16.5468802,10.9485601 15.6825002,10.2011101 14.4379602,10.1993499 C13.0268802,10.1989101 12.1198201,11.0183002 12.1198201,12.5225502 L12.1198201,15.7533603 C12.1198201,17.2729003 13.0315002,18.1002103 14.4343302,18.1002103 Z"
              fill="#FFFFFF"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

IconDoc.propTypes = {
  color: PropTypes.string,
}

export default IconDoc
