import styled from 'styled-components'

export const Container = styled.div`
  width: 74%;
  height: 40%;
  position: fixed;
  margin: 11rem auto;
  z-index: 1;
  text-align: center;
  letter-spacing: 0.05rem;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 0.6rem;
  ${({ theme }) => theme.colorVars.prepBanner};
`

export const StyledLink = styled.a`
  color: white;
`

export const Subtitle = styled.div`
  font-family: 'Molde-Medium';
`

export const TelescopeIconWrapper = styled.div`
  margin-top: 6rem;
`

export const Title = styled.h1`
  font-family: 'SharpSlab-SemiBold';
  font-size: 1.4375rem;

  b {
    font-family: 'SharpSlab-ExtraBold';
  }
`
