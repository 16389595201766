import styled from 'styled-components'

export const DIAMETER = '27px'

export const CorrectAnswer = styled.div.attrs(
  ({
    theme: {
      colorVars: { dragQuestion: colors },
    },
  }) => ({
    background: colors.submissionAnswerIcon.correct.background,
    border: colors.submissionAnswerIcon.correct.stroke,
    color: colors.submissionAnswerIcon.correct.color,
    height: DIAMETER,
    width: DIAMETER,
  }),
)`
  align-items: center;
  align-self: center;
  background-color: ${props => props.background};
  border: ${props => props.border};
  border-radius: 45px;
  box-sizing: border-box;
  color: ${props => props.color};
  display: flex;
  height: ${props => props.height};
  justify-content: center;
  padding: 1px;
  position: absolute;
  right: -14px;
  width: ${props => props.width};

  img {
    height: 12px;
    width: 12px;
  }
`

export const IncorrectAnswer = styled.div.attrs(
  ({
    theme: {
      colorVars: { dragQuestion: colors },
    },
  }) => ({
    background: colors.submissionAnswerIcon.incorrect.background,
    border: colors.submissionAnswerIcon.incorrect.stroke,
    color: colors.submissionAnswerIcon.incorrect.color,
    height: DIAMETER,
    width: DIAMETER,
  }),
)`
  align-items: center;
  align-self: center;
  background-color: ${props => props.background};
  border-radius: 45px;
  border: ${props => props.border};
  box-sizing: border-box;
  color: ${props => props.color};
  display: flex;
  height: ${props => props.height};
  justify-content: center;
  padding: 1px;
  position: absolute;
  right: -14px;
  width: ${props => props.width};

  img {
    height: 12px;
    width: 12px;
  }
`
