/* istanbul ignore file */
// we don't unit test SVGs
import React from 'react'
import PropTypes from 'prop-types'

const WarningIcon = ({
  className,
  dataTestId,
  height = '70',
  width = '70',
}) => {
  return (
    <svg
      className={className}
      data-testid={dataTestId}
      height={height}
      viewBox="0 0 70 70"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-349 -334)">
          <g transform="translate(147 260)">
            <g transform="translate(202 74)">
              <circle cx="35" cy="35" fill="#F3544C" r="35" />
              <g transform="translate(32 18)">
                <g>
                  <path
                    d="M3.5 0L3.5 23.815"
                    stroke="#FFF"
                    strokeLinecap="round"
                    strokeWidth="7"
                  />
                  <circle cx="3.5" cy="35.5" fill="#FFF" r="3.5" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

WarningIcon.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
export default WarningIcon
