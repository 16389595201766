import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { TextInput } from 'grommet'
import { fontWeightNormal } from '../../theme/defaultValues'

export const TextInputContainer = styled.div`
  position: relative;
`

export const TextInputStyled = styled(TextInput).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  box-shadow: none;
  color: ${themeGet('colors.input.text')};
  font-family: ${themeGet('fontFamilySemibold')};
  font-weight: ${fontWeightNormal};
  padding: 0.6875rem 0.6875rem 0.4375rem;
  &::placeholder {
    font-family: ${themeGet('fontFamily')};
    opacity: 1;
  }
  padding-right: ${({ maxLength }) => (maxLength ? '4.313rem' : '')};
`
