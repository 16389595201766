import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { imageSourceWithOptions } from '../../utils/contentfulHelper'
import { getPlayerContentDimensions } from '../../utils/playerHelper'

import ContentfulImageBackgroundComponent from './component'

const ContentfulImageBackgroundContainer = props => {
  const {
    accessibilityLabel,
    children,
    deviceResolutionTier,
    height,
    source,
    theme,
    width,
  } = props || {}
  const { height: requestHeight, width: requestWidth } =
    getPlayerContentDimensions(theme, deviceResolutionTier) || {}
  const sourceWithOptions = imageSourceWithOptions(
    source,
    requestWidth,
    requestHeight,
  )

  return (
    <ContentfulImageBackgroundComponent
      accessibilityLabel={accessibilityLabel}
      height={height}
      source={sourceWithOptions}
      width={width}
    >
      {children}
    </ContentfulImageBackgroundComponent>
  )
}

ContentfulImageBackgroundContainer.propTypes = {
  accessibilityRole: PropTypes.string,
  children: PropTypes.element,
  deviceResolutionTier: PropTypes.object,
  height: PropTypes.string, //percentage string
  source: PropTypes.string,
  theme: PropTypes.object,
  width: PropTypes.string, //percentage string
}

const mapStateToProps = ({ player }) => ({
  deviceResolutionTier: player.deviceResolutionTier,
  theme: player.theme,
})

export default connect(mapStateToProps)(ContentfulImageBackgroundContainer)
