import React from 'react'

import PropTypes from 'prop-types'

import DragWordDraggableItem from '../DragWordDraggableItem'
import DragWordDropZone from '../DragWordDropZone'

import { Question, QuestionContainer, QuestionCopy } from './style'

const DATA_TEST_ID = 'drop-zone'

const DragWordQuestions = props => {
  const { questions, onDrop } = props

  const questionZones = questions.filter(Boolean).map((question, idx) => {
    const { correct, draggable, graded, id, label } = { ...question }
    //https://reactjs.org/docs/reconciliation.html#keys
    const key = id || `${idx}-${label}`
    const onItemDrop = item => {
      if (draggable) {
        return
      }

      onDrop(id, item.id)
    }

    return (
      <Question key={key}>
        <DragWordDropZone
          dataTestId={`${DATA_TEST_ID}-${id}`}
          onDrop={onItemDrop}
        />
        {draggable && (
          <DragWordDraggableItem
            correct={correct}
            graded={graded}
            item={draggable}
            onDrop={onItemDrop}
          />
        )}
        <QuestionCopy>{label}</QuestionCopy>
      </Question>
    )
  })

  return <QuestionContainer>{questionZones}</QuestionContainer>
}

DragWordQuestions.propTypes = {
  onDrop: PropTypes.func,
  questions: PropTypes.arrayOf(PropTypes.object),
}

DragWordQuestions.defaultProps = {
  onDrop: () => {},
  questions: [],
}

export default DragWordQuestions
