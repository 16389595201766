import React from 'react'

import PropTypes from 'prop-types'

import DragLabelNode from '../DragLabelNode'

import { Container } from './style'

const DROP_ZONE_DATA_TEST_ID_PREFIX = 'drop-zone-'
const FRAME_NODES_DATA_TEST_ID = 'frame-nodes-container'

const DragLabelFrameNodesComponent = ({
  dropZones,
  height,
  longLabels,
  onDragEnd,
  onDragStart,
  onDrop,
  showDropZones,
  top,
  width,
}) => {
  const getFrameNodes = () => {
    const frameNodeComponents = dropZones.map((dropZone, index) => {
      const {
        correct,
        draggable,
        graded,
        id: dropZoneId,
        left,
        top,
      } = dropZone || {}
      const { id: draggableId, label, index: draggableIndex } = draggable || {}
      const dropZoneDataTestIdPrefix = DROP_ZONE_DATA_TEST_ID_PREFIX
      const dropZoneDataTestId = `${dropZoneDataTestIdPrefix}${index}`
      const showDraggable = draggable != null
      const frameNodeComponent = (
        <DragLabelNode
          correct={correct}
          draggableId={draggableId}
          draggableIndex={draggableIndex}
          dropZoneDataTestId={dropZoneDataTestId}
          dropZoneId={dropZoneId}
          graded={graded}
          isLong={longLabels}
          key={dropZoneId}
          label={label}
          left={left}
          onDragEnd={onDragEnd}
          onDragStart={onDragStart}
          onDrop={onDrop}
          showDraggable={showDraggable}
          showDropZone={showDropZones}
          top={top}
        />
      )

      return frameNodeComponent
    })

    return frameNodeComponents
  }

  const frameNodeComponents = getFrameNodes()

  return (
    <Container
      data-testid={FRAME_NODES_DATA_TEST_ID}
      height={height}
      top={top}
      width={width}
    >
      {frameNodeComponents}
    </Container>
  )
}

DragLabelFrameNodesComponent.propTypes = {
  dropZones: PropTypes.arrayOf(PropTypes.object),
  height: PropTypes.number,
  longLabels: PropTypes.bool,
  onDragEnd: PropTypes.func,
  onDragStart: PropTypes.func,
  onDrop: PropTypes.func,
  showDropZones: PropTypes.bool,
  top: PropTypes.number,
  width: PropTypes.number,
}

DragLabelFrameNodesComponent.defaultProps = {
  dropZones: [],
  height: 0,
  onDragEnd() {},
  onDragStart() {},
  onDrop() {},
  showDropZones: false,
  top: 0,
  width: 0,
}

export default DragLabelFrameNodesComponent
