import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const TextEntryLabel = styled.label.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  color: ${themeGet('colors.inputLabel')};
  font-size: 1rem;
  font-family: ${themeGet('fontFamilyBold')};
  margin-bottom: 1rem;
`
