import React from 'react'

import styled from 'styled-components'

const HsTelescopeIcon = props => {
  return (
    <svg
      width="47px"
      height="33px"
      viewBox="0 0 47 33"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Group 3 Copy</title>
      <g
        id="UI---Program-Preview-+-Supports"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="hs_student-activity_player-intro"
          transform="translate(-620, -312)"
        >
          <g id="Group-3-Copy-2" transform="translate(165, 199)">
            <g id="Group-3" transform="translate(266, 114)">
              <g id="Group-3-Copy" transform="translate(190, 0)">
                <g
                  id="Group"
                  transform="translate(22.4907, 12.0208) rotate(-17) translate(-22.4907, -12.0208)translate(0.7867, 6.0863)"
                >
                  <path
                    d="M46.2526468,8.76524325 C46.2526468,5.68724763 43.731448,3.19203917 40.6213916,3.19203917 C37.5113352,3.19203917 34.9901363,5.68724763 34.9901363,8.76524325"
                    id="Oval"
                    fill="#F7AACC"
                    transform="translate(40.6214, 5.9786) rotate(-270) translate(-40.6214, -5.9786)"
                  ></path>
                  <rect
                    id="Rectangle"
                    stroke="#023125"
                    strokeWidth="1.5"
                    strokeLinejoin="round"
                    x="10.5100334"
                    y="1.22205837"
                    width="11.7465079"
                    height="9.37015336"
                  ></rect>
                  <rect
                    id="Rectangle"
                    stroke="#023125"
                    strokeWidth="1.5"
                    strokeLinejoin="round"
                    transform="translate(30.2398, 5.9344) scale(-1, 1) translate(-30.2398, -5.9344)"
                    x="22.4372963"
                    y="3.284209e-14"
                    width="15.6049714"
                    height="11.8688609"
                  ></rect>
                  <rect
                    id="Rectangle"
                    stroke="#023125"
                    strokeWidth="1.5"
                    strokeLinejoin="round"
                    transform="translate(7.1097, 5.9071) scale(-1, 1) translate(-7.1097, -5.9071)"
                    x="3.70942354"
                    y="3.09608905"
                    width="6.80060982"
                    height="5.62209202"
                  ></rect>
                  <rect
                    id="Rectangle"
                    stroke="#023125"
                    strokeWidth="1.5"
                    strokeLinejoin="round"
                    x="-7.45739329e-14"
                    y="1.22205837"
                    width="3.70942354"
                    height="9.37015336"
                    rx="1.85471177"
                  ></rect>
                </g>
                <line
                  x1="19.7595716"
                  y1="18.0233833"
                  x2="15.4319109"
                  y2="30.6410304"
                  id="Line-4"
                  stroke="#023125"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                ></line>
                <line
                  x1="19.7595716"
                  y1="18.0233833"
                  x2="24.7054697"
                  y2="30.6410304"
                  id="Line-4"
                  stroke="#023125"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                ></line>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export const StyledTelescopeIcon = styled(HsTelescopeIcon)`
  rect {
    stroke: #023125;
  }
  path {
    fill: #f7aacc;
  }
  line {
    stroke: #023125;
  }
`
