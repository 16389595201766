import styled from 'styled-components'

export const AutoTableFiltersTh = styled.th`
  padding-top: 0;
`

export const AutoTableFiltersDiv = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  position: relative;
`

export const AutoTableFiltersI = styled.i.attrs(({ query }) => ({
  iconColor: query && query.length > 0 ? '#5d9f00' : '#dee2e6',
}))`
  position: absolute;
  top: 0.6rem;
  right: 0.5rem;
  font-size: 1.25rem;
  color: ${({ iconColor }) => iconColor};
`
