import ReactGA from 'react-ga4'
import { MESSAGE_SUCCESS, SKIP_INITIALIZE } from './constants'

/**
 * @param param0.globalPrefix string
 * @param param0.context string
 * @returns string
 */
const resolvePagePrefix = ({ globalPrefix, context }) => {
  if (!globalPrefix) {
    return ''
  }

  if (!context || context === 'LMS') {
    return globalPrefix
  }

  return `${globalPrefix}/${context.toLowerCase()}`
}

let analyticsInitialized = false

/**
 * Initialized Google Analytics and sets page depending on context
 */
export class GoogleAnalytics {
  static init({ globalPrefix, pageTitle, trackingId, context }) {
    if (!pageTitle || !trackingId) {
      console.warn(SKIP_INITIALIZE)
      return
    }

    const prefix = resolvePagePrefix({
      globalPrefix,
      context: context || null,
    })
    const currentPage = `${prefix}/${pageTitle}`

    if (analyticsInitialized) {
      ReactGA.send({ hitType: 'pageview', page: currentPage })
      return
    }

    ReactGA.initialize(trackingId)
    analyticsInitialized = true
    ReactGA.send({ hitType: 'pageview', page: currentPage })
    console.info(MESSAGE_SUCCESS)
  }
}
