import styled from 'styled-components'
import PropTypes from 'prop-types'
import { themeGet } from 'styled-system'
import { TextInput } from 'grommet'

export const FormInputTextInput = styled(TextInput).attrs(
  ({ dataTestId, disabled, label, onChange }) => ({
    'aria-label': label,
    'data-testid': dataTestId,
    onChange,
    plain: true,
    borderColor: disabled
      ? themeGet('colors.input.textarea.borderDisabled')
      : themeGet('colors.input.textarea.borderEnabled'),
    hoverBorderColor: !disabled && themeGet('colors.input.textarea.hover'),
  }),
)`
  background-color: ${themeGet('colors.input.textarea.background')};
  border: 2px solid ${({ borderColor }) => borderColor};
  border-radius: 5px;
  color: ${themeGet('colors.input.textarea.foreground')};
  font-weight: 400;
  line-height: 1.625rem;
  padding: 0.75rem;

  :focus {
    border-color: ${themeGet('colors.input.textarea.focus')};
  }

  :hover {
    border-color: ${({ hoverBorderColor }) => hoverBorderColor};
  }

  ::placeholder {
    font-style: italic;
    color: ${themeGet('colors.input.textarea.placeholder')};
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

FormInputTextInput.propTypes = {
  dataTestId: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}
