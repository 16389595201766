import React from 'react'
import PropType from 'prop-types'
import CaretIcon from '../CaretIcon'
import { Nav } from './component.styles.js'

const range = (start, end) =>
  end < start
    ? []
    : Array.from(Array(end - start + 1).keys()).map(x => x + start)

const Pagination = ({
  padding,
  pageCount,
  currentPage,
  gotoPage,
  createURL,
}) => {
  // Any change to any variable in this block will cause the component to re-render

  const justGoThere = (e, pageNumber) => {
    // We do this so that we have an href yet bypass the browser's routing
    e.preventDefault()
    gotoPage(pageNumber)
  }

  const previousPage = currentPage - 1
  const nextPage = currentPage + 1
  const lastPage = pageCount - 1
  const paddedChunkStart = Math.max(
    Math.min(Math.max(1, currentPage - padding), lastPage - padding * 2),
    1,
  )
  const paddedChunkEnd = Math.min(paddedChunkStart + padding * 2, lastPage - 1)
  const hasPrevious = currentPage > 0
  const hasNext = currentPage < lastPage
  const needsPreviousEllipsis = paddedChunkStart > 2
  const needsNextEllipsis = paddedChunkEnd < lastPage - 1

  const previousPageItem = (
    <li className="previous">
      <a
        hidden={!hasPrevious}
        href={createURL(previousPage)}
        onClick={e => justGoThere(e, previousPage)}
      >
        <CaretIcon rotation={270} /> Previous{' '}
        <span className="visuallyhidden">page</span>
      </a>
    </li>
  )

  const nextPageItem = (
    <li className="next">
      <a
        hidden={!hasNext}
        href={createURL(nextPage)}
        onClick={e => justGoThere(e, nextPage)}
      >
        Next <span className="visuallyhidden">page</span> <CaretIcon />
      </a>
    </li>
  )

  const middlePages = range(paddedChunkStart, paddedChunkEnd).map(
    pageNumber => (
      <li className="nonmobile" key={pageNumber}>
        <a
          aria-current={pageNumber === currentPage ? 'page' : false}
          href={createURL(pageNumber)}
          onClick={e => justGoThere(e, pageNumber)}
        >
          <span className="visuallyhidden">page</span> {pageNumber + 1}
        </a>
      </li>
    ),
  )

  if (needsPreviousEllipsis) {
    middlePages.unshift(<li className="nonmobile">&hellip;</li>)
  }

  if (needsNextEllipsis) {
    middlePages.push(<li className="nonmobile">&hellip;</li>)
  }

  const firstPageItem = (
    <li className="spacer nonmobile">
      <a
        aria-current={currentPage === 0 ? 'page' : false}
        href={createURL(0)}
        onClick={e => justGoThere(e, 0)}
      >
        <span className="visuallyhidden">page</span> 1
        <span className="visuallyhidden">(first page)</span>
      </a>
    </li>
  )

  const lastPageItem =
    lastPage === 0 ? (
      <></>
    ) : (
      <li className="spacer nonmobile">
        <a
          aria-current={currentPage === lastPage ? 'page' : false}
          href={createURL(lastPage)}
          onClick={e => justGoThere(e, lastPage)}
        >
          <span className="visuallyhidden">page</span> {lastPage + 1}
          <span className="visuallyhidden">(last page)</span>
        </a>
      </li>
    )

  const mobileSelect = (
    <li className="mobile-only middle">
      <div className="select-wrapper">
        <select onChange={e => justGoThere(e, parseInt(e.target.value))}>
          {range(0, pageCount - 1).map(pageNumber => (
            <option
              key={pageNumber}
              selected={currentPage === pageNumber}
              value={pageNumber}
            >
              Page {pageNumber + 1} of {pageCount}
            </option>
          ))}
        </select>
      </div>
    </li>
  )

  return (
    <Nav aria-label="pagination">
      <ul>
        {previousPageItem}
        {firstPageItem}
        {middlePages}
        {lastPageItem}
        {mobileSelect}
        {nextPageItem}
      </ul>
    </Nav>
  )
}

Pagination.propTypes = {
  createURL: PropType.func.isRequired,
  currentPage: PropType.number.isRequired,
  gotoPage: PropType.func.isRequired,
  padding: PropType.number,
  pageCount: PropType.number.isRequired,
}

Pagination.defaultProps = {
  currentPage: 0,
  padding: 3,
}

export default Pagination
