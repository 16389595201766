import styled from 'styled-components'

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 1.75rem;
`

export const ListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 1.25rem;
  }
`
