/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'
import PropTypes from 'prop-types'

const IconExcel = ({ color = 'currentColor' }) => {
  const height = 22
  const width = 18
  const heightWithUnits = `${height}px`
  const widthWithUnits = `${width}px`

  const viewBox = `0 0 ${width} ${height}`

  return (
    <svg height={heightWithUnits} viewBox={viewBox} width={widthWithUnits}>
      <title>Open Excel</title>
      <g
        fill="none"
        fillRule="evenodd"
        id="ss_excel_icon"
        stroke="none"
        strokeWidth="1"
      >
        <path
          d="M11.8354366,0 L18,5.20619023 L18,19.7631579 C18,20.9448198 17.0837227,21.9124736 15.922904,21.9943837 L15.7631579,22 L2.23684211,22 C1.05518023,22 0.0875263673,21.0837227 0.00561631885,19.922904 L0,19.7631579 L0,2.23684211 C1.12963111e-15,1.05518023 0.916277256,0.0875263673 2.07709598,0.00561631885 L2.23684211,0 L11.8354366,0 Z M15,17 L10,17 L10,19 L15,19 L15,17 Z M8,17 L3,17 L3,19 L8,19 L8,17 Z M15,13 L10,13 L10,15 L15,15 L15,13 Z M8,13 L3,13 L3,15 L8,15 L8,13 Z M8.47989927,3.30104076 L6.5,5.281 L4.52010129,3.30104076 C4.21581778,2.99675725 3.73823153,2.97140029 3.40506423,3.22496989 L3.31801976,3.30104076 C3.01373625,3.60532427 2.98837929,4.08291053 3.24194889,4.41607783 L3.31801976,4.50312229 L5.297,6.483 L3.31801976,8.46292027 C2.98607412,8.79486591 2.98607412,9.33305615 3.31801976,9.66500179 C3.64996541,9.99694744 4.18815564,9.99694744 4.52010129,9.66500179 L6.5,7.685 L8.47989927,9.66500179 C8.78418278,9.96928531 9.26176903,9.99464226 9.59493633,9.74107267 L9.68198079,9.66500179 C9.98626431,9.36071828 10.0116213,8.88313203 9.75805167,8.54996473 L9.68198079,8.46292027 L7.702,6.483 L9.68198079,4.50312229 C10.0139264,4.17117664 10.0139264,3.63298641 9.68198079,3.30104076 C9.35003515,2.96909512 8.81184491,2.96909512 8.47989927,3.30104076 Z"
          fill={color}
          id="Combined-Shape"
        />
      </g>
    </svg>
  )
}

IconExcel.propTypes = {
  color: PropTypes.string,
}

export default IconExcel
