import React from 'react'
import PropTypes from 'prop-types'
import { CharCountText } from './component.styles'

const CharacterCounter = ({ charValue = '', dataTestId, label, maxLength }) => (
  <CharCountText
    aria-atomic="true"
    aria-describedby={label}
    aria-live="polite"
    data-testid={`character-counter-${dataTestId}`}
  >
    {charValue.length} / {maxLength}
  </CharCountText>
)

CharacterCounter.propTypes = {
  charValue: PropTypes.string.isRequired,
  dataTestId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  maxLength: PropTypes.number.isRequired,
}

export default CharacterCounter
