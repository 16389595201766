import React from 'react'
import PropTypes from 'prop-types'
import { TabButtonContainer } from './component.styles'

function TabButtonOutlined({ label, onClick, onKeyPress }) {
  return (
    <>
      <TabButtonContainer
        aria-label={label}
        dataTestId={'k8-admin-dashboard-tab-button'}
        onClick={onClick}
        onKeyPress={onKeyPress}
        role="tab"
        tabIndex={0}
      />
      <svg
        enableBackground="new 0 0 159 41"
        height="41"
        version="1.1"
        viewBox="0 0 159 41"
        width="159"
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.7 38c2.1-.7 4-1.7 5.7-2.8 4.8-3.2 8-8 9.3-14.4 1.3-5.9 3.7-10.3 7.5-13.3S46 3 52.2 3h55.3c.8 0 1.2 0 1.5.1 5.7.4 10.2 2 13.6 5 3.4 3 5.7 7.2 6.9 12.8 1.4 6.4 4.5 11.2 9.3 14.4 1.7 1.1 3.6 2 5.7 2.8"
          fill="currentColor"
          stroke="currentColor"
          strokeLinejoin="round"
          strokeMiterlimit="10"
        />
        <path
          d="M52.2 3h55.3c.8 0 1.2 0 1.5.1 5.7.4 10.2 2 13.6 5 3.4 3 5.7 7.2 6.9 12.8 1.4 6.4 4.5 11.2 9.3 14.4 1.7 1.1 3.6 2 5.7 2.8H14.7c2.1-.7 4-1.7 5.7-2.8 4.8-3.2 8-8 9.3-14.4C31 15 33.4 10.6 37.2 7.6S46 3.1 52.2 3z"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
        <text dominantBaseline="middle" textAnchor="middle" x="50%" y="53%">
          {label}
        </text>
      </svg>
    </>
  )
}

TabButtonOutlined.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
}

export default TabButtonOutlined
