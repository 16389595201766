/* istanbul ignore file */
// we don't unit test SVGs
const SelaTranscriptMobile = () => {
  return (
    <svg height="42px" viewBox="0 0 50 42" width="50px">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-305.000000, -395.000000)">
          <rect fill="#FFFFFF" height="667" width="375" x="0" y="0" />
          <line
            stroke="#007980"
            strokeLinecap="round"
            strokeWidth="2"
            x1="322"
            x2="326"
            y1="406"
            y2="406"
          />
          <line
            stroke="#007980"
            strokeLinecap="round"
            strokeWidth="2"
            x1="330"
            x2="338"
            y1="406"
            y2="406"
          />
          <line
            stroke="#007980"
            strokeLinecap="round"
            strokeWidth="2"
            x1="322"
            x2="338"
            y1="411"
            y2="411"
          />
          <line
            stroke="#007980"
            strokeLinecap="round"
            strokeWidth="2"
            x1="322"
            x2="330"
            y1="416"
            y2="416"
          />
          <line
            stroke="#007980"
            strokeLinecap="round"
            strokeWidth="2"
            x1="334"
            x2="338"
            y1="416"
            y2="416"
          />
          <g stroke="#007980" transform="translate(305.000000, 390.000000)">
            <rect fill="#FFFFFF" height="30" rx="6" width="50" x="0" y="5" />
            <line
              strokeLinecap="round"
              strokeWidth="2"
              x1="17"
              x2="21"
              y1="16"
              y2="16"
            />
            <line
              strokeLinecap="round"
              strokeWidth="2"
              x1="25"
              x2="33"
              y1="16"
              y2="16"
            />
            <line
              strokeLinecap="round"
              strokeWidth="2"
              x1="17"
              x2="33"
              y1="21"
              y2="21"
            />
            <line
              strokeLinecap="round"
              strokeWidth="2"
              x1="17"
              x2="25"
              y1="26"
              y2="26"
            />
            <line
              strokeLinecap="round"
              strokeWidth="2"
              x1="29"
              x2="33"
              y1="26"
              y2="26"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SelaTranscriptMobile
