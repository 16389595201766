import React, { Component } from 'react'

import PropTypes from 'prop-types'

import { HelperCopy } from '../../text'
import { ERROR_MESSAGE } from '../constants'
import { ErrorMessageText } from '../ImageJuxtFrame/style'

import {
  LeftErrorMessageContainer,
  LeftErrorMessageTextContainer,
  LeftImage,
  LeftImageContainer,
} from './style'

const ACCESSIBILITY_LABEL = 'Image juxtaposition left image container'

class ImageJuxtLeftImageComponent extends Component {
  getRenderProps = () => {
    const {
      alt: accessibilityLabel,
      containerWidth,
      imageWidth,
      onError,
      showError,
      source,
    } = this.props
    const imageContainerProps = {
      accessibilityLabel: ACCESSIBILITY_LABEL,
      width: containerWidth,
    }
    const imageProps = {
      accessibilityLabel,
      onError,
      source,
      width: imageWidth,
    }
    const errorMessageContainerProps = {
      width: imageWidth,
    }

    return {
      errorMessageContainerProps,
      imageContainerProps,
      imageProps,
      showError,
    }
  }

  getComponent = () => {
    const { imageProps, errorMessageContainerProps, showError } =
      this.getRenderProps()
    const leftImageComponent = showError ? (
      <LeftErrorMessageContainer {...errorMessageContainerProps}>
        <LeftErrorMessageTextContainer>
          <ErrorMessageText>
            <HelperCopy>{ERROR_MESSAGE}</HelperCopy>
          </ErrorMessageText>
        </LeftErrorMessageTextContainer>
      </LeftErrorMessageContainer>
    ) : (
      <LeftImage {...imageProps} />
    )

    return leftImageComponent
  }

  render = () => {
    const { imageContainerProps } = this.getRenderProps()
    const leftImageComponent = this.getComponent()

    return (
      <LeftImageContainer {...imageContainerProps}>
        {leftImageComponent}
      </LeftImageContainer>
    )
  }
}

ImageJuxtLeftImageComponent.propTypes = {
  alt: PropTypes.string,
  containerWidth: PropTypes.number,
  imageWidth: PropTypes.number,
  onError: PropTypes.func,
  showError: PropTypes.bool,
  source: PropTypes.string,
}

ImageJuxtLeftImageComponent.defaultProps = {
  alt: "' '",
  containerWidth: 0,
  imageWidth: 0,
  onError() {},
}

export default ImageJuxtLeftImageComponent
