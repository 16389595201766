import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import DragAndDrop from '../../../lib/dragAndDrop/singleton'
import { usePrevious } from '../../../utils/hooksHelper'
import { getAddedDraggables } from '../utils'

import DragQuestionDropZoneComponent from './component'

//Use functional component instead of a class since it calls react-dnd webhooks
//Using a class will fail. See https://reactjs.org/warnings/invalid-hook-call-warning.html

const DragQuestionDropZoneContainer = ({
  canSubmitForm,
  draggables,
  handleDrop,
  id: dropZoneId,
  index,
  isLast,
  isOver,
  isSubmitted,
  label,
  onKeyDown,
  placeholderHeight,
}) => {
  const [mousePlaceholderHeight, setMousePlaceholderHeight] = useState(0)
  const [recentlyAddedDraggableId, setRecentlyAddedDraggableId] = useState(null)
  const prevProps = usePrevious({ draggables })

  useEffect(() => {
    const { draggables: prevDraggables } = prevProps || {}

    if (!prevDraggables) {
      return
    }

    const draggableWasAddedToDropZone =
      draggables.length - prevDraggables.length === 1

    if (!draggableWasAddedToDropZone) {
      setRecentlyAddedDraggableId(null)
      return
    }

    const addedDraggableId = getRecentlyAddedDraggableId()

    setRecentlyAddedDraggableId(addedDraggableId)
  }, [draggables])

  const getRecentlyAddedDraggableId = () => {
    const { draggables: prevDraggables } = prevProps || {}
    const addedDraggableId = getAddedDraggables(prevDraggables, draggables)[0]

    return addedDraggableId
  }

  const handleMouseDrop = (item, monitor) => {
    setMousePlaceholderHeight(null)
    handleDrop(item, monitor, dropZoneId)
  }

  const [collectedProps, dropRef] = DragAndDrop.setupUseDrop(
    dropZoneId,
    handleMouseDrop,
    setMousePlaceholderHeight,
  )
  const { isOverDropZone: mouseIsOver } = collectedProps || {}

  return (
    <DragQuestionDropZoneComponent
      canSubmitForm={canSubmitForm}
      draggables={draggables}
      dropRef={dropRef}
      focusOnDraggable={recentlyAddedDraggableId}
      index={index}
      isLast={isLast}
      isOver={mouseIsOver || isOver}
      isSubmitted={isSubmitted}
      label={label}
      onKeyDown={onKeyDown}
      placeholderHeight={mousePlaceholderHeight || placeholderHeight}
    />
  )
}

DragQuestionDropZoneContainer.propTypes = {
  canDrop: PropTypes.bool,
  canSubmitForm: PropTypes.func,
  draggables: PropTypes.array,
  handleDrop: PropTypes.func,
  id: PropTypes.string,
  index: PropTypes.number,
  isLast: PropTypes.bool,
  isOver: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  label: PropTypes.string,
  onKeyDown: PropTypes.func,
  placeholderHeight: PropTypes.number,
}

DragQuestionDropZoneContainer.defaultProps = {
  canSubmitForm: () => {},
  draggables: [],
  handleDrop() {},
  onKeyDown() {},
}

export default DragQuestionDropZoneContainer
