import styled from 'styled-components'
import PropTypes from 'prop-types'

const Svg = styled.svg.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  min-width: 86px;
`

Svg.propTypes = {
  dataTestId: PropTypes.string.isRequired,
}

export default Svg
