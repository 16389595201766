/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

const DashboardIcon = () => (
  <svg height="16px" viewBox="0 0 16 16" width="16px">
    <g
      fill="none"
      fillRule="evenodd"
      id="dashboard"
      stroke="none"
      strokeWidth="1"
    >
      <g fill="currentColor" id="dashboard-icon">
        <rect height="4" id="Rectangle" rx="0.75" width="4" x="0" y="0" />
        <rect height="4" id="Rectangle" rx="0.75" width="4" x="0" y="6" />
        <rect height="4" id="Rectangle" rx="0.75" width="4" x="0" y="12" />
        <rect height="4" id="Rectangle" rx="0.75" width="4" x="6" y="0" />
        <rect height="4" id="Rectangle" rx="0.75" width="4" x="6" y="6" />
        <rect height="4" id="Rectangle" rx="0.75" width="4" x="6" y="12" />
        <rect height="4" id="Rectangle" rx="0.75" width="4" x="12" y="0" />
        <rect height="4" id="Rectangle" rx="0.75" width="4" x="12" y="6" />
        <rect height="4" id="Rectangle" rx="0.75" width="4" x="12" y="12" />
      </g>
    </g>
  </svg>
)

export default DashboardIcon
