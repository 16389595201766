import React from 'react'
import FlagsContext from '../../FlagsSetup/FlagsContext'
import FlagsSingleton from '../../FlagsManager'

export default function FlagsComponentRouter({
  flagSubscriptions = [],
  OnComponent,
  OffComponent,
  onPropsOverrides = {},
  offPropsOverrides = {},
  flagsChecker = FlagsSingleton.checkAllFlagValuesTrue,
  // Params for onCondition are consuming component props and subscribedFlags
  onCondition = () => true,
}) {
  return React.forwardRef(function FlagRouterHOC(props, ref) {
    return (
      <FlagsContext.Consumer>
        {({ flags }) => {
          const subscribedFlags = FlagsSingleton.getUpdatedSubscribedFlags(
            flags,
            flagSubscriptions,
          )
          const flagsChecked = flagsChecker(subscribedFlags)
          const shouldShowOnComponent =
            flagsChecked && onCondition(props, subscribedFlags)

          if (shouldShowOnComponent) {
            return <OnComponent {...props} {...onPropsOverrides} ref={ref} />
          } else {
            return <OffComponent {...props} {...offPropsOverrides} ref={ref} />
          }
        }}
      </FlagsContext.Consumer>
    )
  })
}
