export const fontWeightNormal = 400

export const bodySizes = {
  font: {
    weight: fontWeightNormal,
    extend: 'margin-top: 0',
  },
  extend: 'margin-block-start: 0; margin-block-end: 0; margin-bottom: 0.5rem',
  small: {
    size: '0.875rem',
    height: '1.25rem',
  },
  medium: {
    size: '1rem',
    height: '1.375rem',
  },
  large: {
    size: '1.25rem',
    height: '1.75rem',
  },
  xlarge: {
    size: '1.5rem',
    height: '2rem',
  },
  xxlarge: {
    size: '2rem',
    height: '2.5rem',
  },
}
export const headingLevels = {
  '1': {
    font: {
      weight: fontWeightNormal,
    },
    small: {
      size: '1.6875rem',
      height: '2.75rem',
    },
    medium: {
      size: '2.1875rem',
      height: '3.25rem',
    },
    large: {
      size: '3.1875rem',
      height: '4.25rem',
    },
    xlarge: {
      size: '4.1875rem',
      height: '5.25rem',
    },
  },
  '2': {
    font: {
      weight: fontWeightNormal,
    },
    small: {
      size: '1.25rem',
      height: '1.5rem',
    },
    medium: {
      size: '1.75rem',
      height: '1.5rem',
    },
    large: {
      size: '2.75rem',
      height: '3rem',
    },
    xlarge: {
      size: '3.75rem',
      height: '4rem',
    },
  },
  '3': {
    font: {
      weight: fontWeightNormal,
    },
    small: {
      size: '1.25rem',
      height: '1.75rem',
    },
    medium: {
      size: '1.5rem',
      height: '2rem',
    },
    large: {
      size: '2rem',
      height: '2.5rem',
    },
    xlarge: {
      size: '2.5rem',
      height: '3rem',
    },
  },
  '4': {
    font: {
      weight: fontWeightNormal,
    },
    small: {
      size: '1.0625rem',
      height: '1.5rem',
    },
    medium: {
      size: '1.25rem',
      height: '1.625rem',
    },
    large: {
      size: '1.8125rem',
      height: '2.25rem',
    },
    xlarge: {
      size: '2.3125rem',
      height: '2.75rem',
    },
  },
  '5': {
    font: {
      weight: fontWeightNormal,
    },
    small: {
      size: '1rem',
      height: '1.3125rem',
    },
    medium: {
      size: '1.25rem',
      height: '1.5625rem',
    },
    large: {
      size: '1.75rem',
      height: '2.0625rem',
    },
    xlarge: {
      size: '2.25rem',
      height: '2.5625rem',
    },
  },
  '6': {
    font: {
      weight: fontWeightNormal,
    },
    small: {
      size: '0.875rem',
      height: '1.125rem',
    },
    medium: {
      size: '1.125rem',
      height: '1.375rem',
    },
    large: {
      size: '1.625rem',
      height: '1.875rem',
    },
    xlarge: {
      size: '2.125rem',
      height: '2.375rem',
    },
  },
}
export const colors = {
  darkBlue: '#002855',
  backgroundNeutralGrayOneAccent: '#999',
  grayBlue: '#CED8DF',
  paleGray: '#eef0f2',
  gray400: '#cccccc',
  secondStepOrange: '#E1523D',
}
export const spacing = {
  blue16_1rem: '1rem',
  yellow12_0_75rem: '0.75rem',
  pink8_0_5rem: '0.5rem',
  green4_0_25rem: '0.25rem',
}
export const fontFamily = 'Molde-regular'
export const allGradesBackground = '#D9DFE6'
export const allGradesForeground = '#002855'
export const headingMarginExtension =
  'margin-block-start: 0; margin-block-end: 0; margin-bottom: 0.5rem'
export const blueFocus = '#9FD5FD'
export const hoverFontColor = '#000'
export const secondaryHoverFontColor = '#FFF'
export const hoverButtonColor = '#000'
export const hoverButtonFontColor = '#FFF'
export const iconColor = '#666'
export const iconColorLight = '#999'
export const disabledButtonColor = '#CCC'
export const enabledFontColor = '#666'
export const enabledButtonColor = '#666'
export const fontColor = '#666'
export const fontColorSecondary = '#25282a'
export const modalBodyCopyColor = '#555'
export const singleGradeBackground = '#E0EBEB'
export const singleGradeForeground = '#247470'
export const black = '#000'
export const secondStepBlue = '#002855'

let breakpointSizes = {
  large: '1366px',
  medium: '992px',
  tablet: '768px',
  mobile: '375px',
  mobileLarge: '580px',
}

export const breakpoints = {
  large: `@media screen and (max-width: ${breakpointSizes.large})`,
  medium: `@media screen and (max-width: ${breakpointSizes.medium})`,
  tablet: `@media screen and  (max-width: ${breakpointSizes.tablet})`,
  mobile: `@media screen and (max-width: ${breakpointSizes.mobile})`,
  mobileLarge: `@media screen and (max-width: ${breakpointSizes.mobileLarge})`,
  sizes: breakpointSizes,
}
