/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

const LaptopIcon = () => (
  <svg height="90px" version="1.1" viewBox="0 0 116 90" width="116px">
    <defs>
      <polygon points="0 0.55356 115.846395 0.55356 115.846395 14.7 0 14.7" />
    </defs>
    <g
      fill="none"
      fillRule="evenodd"
      id="training-wizard"
      stroke="none"
      strokeWidth="1"
    >
      <g transform="translate(-438.000000, -220.000000)">
        <g id="training-icon" transform="translate(438.000000, 220.000000)">
          <path
            d="M106.291605,72.347625 L106.291605,4.638375 C106.291605,2.080575 104.210505,0.000525 101.652705,0.000525 L14.194005,0.000525 C11.636205,0.000525 9.556155,2.080575 9.556155,4.638375 L9.556155,72.347625 L106.291605,72.347625 Z"
            fill="#002855"
          />
          <path
            d="M97.66806,64.6695 L18.17886,64.6695 C17.92791,64.6695 17.72421,64.4238 17.72421,64.1214 L17.72421,10.15035 C17.72421,9.84795 17.92791,9.60225 18.17886,9.60225 L97.66806,9.60225 C97.91901,9.60225 98.12376,9.84795 98.12376,10.15035 L98.12376,64.1214 C98.12376,64.4238 97.91901,64.6695 97.66806,64.6695"
            fill="#FFFFFF"
          />
          <g transform="translate(0.000000, 75.201525)">
            <mask fill="white">
              <use xlinkHref="#path-1" />
            </mask>
            <path
              d="M114.040395,0.55356 L1.805895,0.55356 C0.810495,0.55356 -0.000105,1.36311 -0.000105,2.36061 L-0.000105,5.05806 C-0.000105,10.37526 4.325895,14.70021 9.642045,14.70021 L106.204245,14.70021 C111.521445,14.70021 115.846395,10.37526 115.846395,5.05806 L115.846395,2.36061 C115.846395,1.36311 115.035795,0.55356 114.040395,0.55356"
              fill="#002855"
              mask="url(#mask-2)"
            />
          </g>
          <path
            d="M65.54205,84.1407 L50.30445,84.1407 C49.57995,84.1407 48.99195,83.5527 48.99195,82.8282 C48.99195,82.1037 49.57995,81.5157 50.30445,81.5157 L65.54205,81.5157 C66.26655,81.5157 66.85455,82.1037 66.85455,82.8282 C66.85455,83.5527 66.26655,84.1407 65.54205,84.1407"
            fill="#FFFFFF"
          />
          <path
            d="M88.387215,56.11473 L28.160265,56.11473 C27.289815,56.11473 26.585265,55.40913 26.585265,54.53973 C26.585265,53.67033 27.289815,52.96473 28.160265,52.96473 L88.387215,52.96473 C89.257665,52.96473 89.962215,53.67033 89.962215,54.53973 C89.962215,55.40913 89.257665,56.11473 88.387215,56.11473"
            fill="#487A7B"
          />
          <path
            d="M88.387215,56.11473 L28.160265,56.11473 C27.289815,56.11473 26.585265,55.40913 26.585265,54.53973 C26.585265,53.67033 27.289815,52.96473 28.160265,52.96473 L88.387215,52.96473 C89.257665,52.96473 89.962215,53.67033 89.962215,54.53973 C89.962215,55.40913 89.257665,56.11473 88.387215,56.11473 Z"
            stroke="#487A7B"
            strokeWidth="1.05"
          />
          <path
            d="M71.62722,31.44897 C71.62722,23.88057 65.49207,17.74437 57.92367,17.74437 C50.35527,17.74437 44.21907,23.88057 44.21907,31.44897 C44.21907,39.01737 50.35527,45.15252 57.92367,45.15252 C65.49207,45.15252 71.62722,39.01737 71.62722,31.44897"
            fill="#E1523D"
          />
          <path
            d="M29.8641,52.81605 C28.8477,52.81605 28.02135,53.6424 28.02135,54.6588 C28.02135,55.6752 28.8477,56.5026 29.8641,56.5026 C30.8805,56.5026 31.70685,55.6752 31.70685,54.6588 C31.70685,53.6424 30.8805,52.81605 29.8641,52.81605 M29.8641,58.6026 C27.68955,58.6026 25.92135,56.83335 25.92135,54.6588 C25.92135,52.4853 27.68955,50.71605 29.8641,50.71605 C32.03865,50.71605 33.80685,52.4853 33.80685,54.6588 C33.80685,56.83335 32.03865,58.6026 29.8641,58.6026"
            fill="#487A7B"
          />
          <path
            d="M29.8641,52.81605 C28.8477,52.81605 28.02135,53.6424 28.02135,54.6588 C28.02135,55.6752 28.8477,56.5026 29.8641,56.5026 C30.8805,56.5026 31.70685,55.6752 31.70685,54.6588 C31.70685,53.6424 30.8805,52.81605 29.8641,52.81605 Z M29.8641,58.6026 C27.68955,58.6026 25.92135,56.83335 25.92135,54.6588 C25.92135,52.4853 27.68955,50.71605 29.8641,50.71605 C32.03865,50.71605 33.80685,52.4853 33.80685,54.6588 C33.80685,56.83335 32.03865,58.6026 29.8641,58.6026 Z"
            stroke="#487A7B"
            strokeWidth="1.05"
          />
          <path
            d="M54.775455,38.355975 C54.636855,38.355975 54.497205,38.355975 54.359655,38.287725 C54.081405,38.148075 53.942805,37.870875 53.942805,37.592625 L53.942805,26.004825 C53.942805,25.728675 54.081405,25.450425 54.359655,25.311825 C54.636855,25.172175 54.914055,25.172175 55.121955,25.311825 L64.904805,31.071075 C65.113755,31.209675 65.321655,31.486875 65.321655,31.765125 C65.321655,32.042325 65.183055,32.319525 64.904805,32.459175 L55.121955,38.217375 C55.052655,38.287725 54.914055,38.355975 54.775455,38.355975"
            fill="#FFFFFF"
          />
          <path
            d="M31.689315,54.658695 C31.689315,53.650695 30.872415,52.833795 29.864415,52.833795 C28.856415,52.833795 28.038465,53.650695 28.038465,54.658695 C28.038465,55.666695 28.856415,56.483595 29.864415,56.483595 C30.872415,56.483595 31.689315,55.666695 31.689315,54.658695"
            fill="#FFFFFF"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default LaptopIcon
