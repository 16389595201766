import FlagsSingleton from '../../FlagsManager'

// make sure that any params passed match default types in parameters below
export default function({
  flagSubcriptions = [],
  onCode = () => {},
  onCodeArgs = [],
  offCode = () => {},
  offCodeArgs = [],
}) {
  return function() {
    if (FlagsSingleton.checkAllFlagsTrue(flagSubcriptions)) {
      const onCodeWithArgs = onCode(...onCodeArgs)

      return onCodeWithArgs
    } else {
      const offCodeWithArgs = offCode(...offCodeArgs)

      return offCodeWithArgs
    }
  }
}
