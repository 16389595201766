/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

const AddIcon = () => (
  <svg
    height="20"
    style={{ marginRight: '-4px' }}
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="none"
      strokeWidth="1"
      transform="translate(-806 -155) translate(806 152) translate(0 3)"
    >
      <circle cx="10" cy="10" fill="currentColor" r="10" />
      <path
        d="M5.455 10L14.545 10"
        stroke="#FFF"
        strokeLinecap="round"
        strokeWidth="1.818"
      />
      <path
        d="M10 5.455L10 14.545"
        stroke="#FFF"
        strokeLinecap="round"
        strokeWidth="1.818"
      />
    </g>
  </svg>
)

export default AddIcon
