import React from 'react'
import PropTypes from 'prop-types'
import FormErrorMessage from '../FormErrorMessage'
import { FormFieldStyled, LabelStyled } from './component.styles'

const FormField = ({
  children,
  className,
  dataTestId,
  inputErrors,
  label,
  labelFor,
}) => {
  const htmlFor = labelFor || dataTestId
  const errorMessage =
    inputErrors && inputErrors.errors && inputErrors.errors.toString()
  return (
    <FormFieldStyled
      className={className}
      dataTestId={`form-field-${dataTestId}`}
    >
      {label && (
        <LabelStyled dataTestId={`label-${dataTestId}`} htmlFor={htmlFor}>
          {label}
        </LabelStyled>
      )}
      {children}
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormFieldStyled>
  )
}

FormField.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dataTestId: PropTypes.string.isRequired,
  inputErrors: PropTypes.object,
  label: PropTypes.string,
  labelFor: PropTypes.string,
}

export default FormField
