/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'
import PropTypes from 'prop-types'

const CheckIcon = ({ color = 'currentColor', ...props }) => {
  return (
    <svg
      height="10"
      viewBox="0 0 12 10"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          d="M10.195.385A.98.98 0 0111.64.261c.416.377.48 1.032.155 1.491l-.038.05L5.53 9.615a.98.98 0 01-1.483.087l-.043-.045L.28 5.438a1.147 1.147 0 01.043-1.546.978.978 0 011.405 0l.043.045 2.941 3.33L10.195.386z"
          fill={color}
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

CheckIcon.propTypes = {
  color: PropTypes.string,
}

export default CheckIcon
