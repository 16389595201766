import React, { Component } from 'react'

import PropTypes from 'prop-types'
import Markdown from 'react-markdown'

import { GenericCopy } from '../../text'

import { QuestionTextContainer } from './style'

const ARIA_LABEL = 'Image juxtaposition bodyText'
const PARAGRAPH = 'paragraph'
const DISALLOWED_QUESTION_MARKDOWN_TYPES = [PARAGRAPH]

class ImageJuxtBodyComponent extends Component {
  render = () => {
    const { textElements } = this.props

    return textElements.map(text => (
      <QuestionTextContainer key={text}>
        <GenericCopy aria-label={ARIA_LABEL}>
          <Markdown
            disallowedTypes={DISALLOWED_QUESTION_MARKDOWN_TYPES}
            unwrapDisallowed
          >
            {text}
          </Markdown>
        </GenericCopy>
      </QuestionTextContainer>
    ))
  }
}

ImageJuxtBodyComponent.propTypes = {
  textElements: PropTypes.arrayOf(PropTypes.string),
}

ImageJuxtBodyComponent.defaultProps = {
  textElements: [],
}

export default ImageJuxtBodyComponent
