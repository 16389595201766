import styled from 'styled-components'

import { BaseContainer } from '../../DraggableBase/style'
import {
  DRAGGABLE_HEIGHT,
  LARGE_DRAGGABLE_WIDTH,
  SMALL_DRAGGABLE_WIDTH,
} from '../style'

export { BaseGrip as Grip, BaseText as Text } from '../../DraggableBase/style'

export const Container = styled(BaseContainer).attrs(
  ({
    left,
    isLong,
    theme: {
      colorVars: { dragAndDrop: colors },
    },
    isVisible,
    top,
  }) => ({
    boxShadow: `4px 4px 4px ${colors.draggable.boxShadowColor}`,
    display: isVisible ? 'flex' : 'none',
    marginVertical: top && left ? 0 : '6px',
    position: top && left ? 'absolute' : 'relative',
    style: {
      border: `1px solid ${colors.draggable.borderColor}`,
      height: DRAGGABLE_HEIGHT,
      left: `${left}%`,
      top: `${top}%`,
      width: isLong ? LARGE_DRAGGABLE_WIDTH : SMALL_DRAGGABLE_WIDTH,
    },
  }),
)`
  align-items: center;
  display: ${props => props.display};
  margin-bottom: ${props => props.marginVertical};
  margin-top: ${props => props.marginVertical};
  position: ${props => props.position};

  :focus,
  :hover {
    box-shadow: ${props => props.boxShadow};
  }
`
