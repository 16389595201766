// Beware! Browser detection methods change often. Sources:
// https://github.com/mbasso/react-browser-detection/blob/master/src/index.js
// https://stackoverflow.com/questions/49328382/browser-detection-in-reactjs

export const isBrowserSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === '[object SafariRemoteNotification]'
  })(
    !window['safari'] ||
      // eslint-disable-next-line no-undef
      (typeof safari !== 'undefined' && safari.pushNotification),
  )
const isGoogleBot =
  navigator.userAgent.toLowerCase().indexOf('googlebot') !== -1
// eslint-disable-next-line
export const isBrowserIe = /*@cc_on!@*/ false || !!document.documentMode
export const isBrowserEdge = !isBrowserIe && !!window.StyleMedia
export const isBrowserFirefox = typeof InstallTrigger !== 'undefined'
export const isBrowserOpera =
  (!!window.opr && !!window.opr.addons) ||
  !!window.opera ||
  navigator.userAgent.indexOf(' OPR/') >= 0
export const isBrowserChrome =
  !isGoogleBot &&
  !isBrowserEdge &&
  !isBrowserOpera &&
  !!window.chrome &&
  (!!window.chrome.webstore ||
    navigator.vendor.toLowerCase().indexOf('google inc.') !== -1)
export const isBrowserHeadlessChrome =
  navigator.userAgent.indexOf('HeadlessChrome') >= 0

export const isWebpSupported =
  !isBrowserHeadlessChrome &&
  (isBrowserChrome || isBrowserFirefox || isBrowserEdge || isBrowserOpera)

// The fullscreen helpers here work for what we have control over, which is "document" full screen.

export const FULLSCREEN_CHANGE_EVENT =
  isBrowserSafari && !isBrowserChrome
    ? 'webkitfullscreenchange'
    : 'fullscreenchange'

export const fullscreenEvent = fn => () => fn(isBrowserFullscreen())

export const addFullscreenEventListener = fn => {
  document.addEventListener(FULLSCREEN_CHANGE_EVENT, fn)
}

export const removeFullscreenEventListener = fn => {
  document.removeEventListener(FULLSCREEN_CHANGE_EVENT, fn)
}

export const requestFullscreen = async element => {
  const fullscreenFn =
    element.requestFullscreen ||
    element.webkitRequestFullscreen ||
    element.msRequestFullscreen

  return fullscreenFn.apply(element)
}

export const exitFullscreen = async () => {
  const fullscreenFn =
    document.exitFullscreen ||
    document.webkitCancelFullScreen ||
    document.msExitFullscreen

  return fullscreenFn.apply(document)
}

export const getFullscreenElement = () => {
  const fullScreenElement =
    document.fullscreenElement ||
    document.mozFullScreenElement ||
    document.webkitFullscreenElement ||
    document.msFullscreenElement

  return fullScreenElement
}

export const isBrowserFullscreen = () => !!getFullscreenElement()

export const toggleBrowserFullscreen = element => {
  const fn = isBrowserFullscreen()
    ? exitFullscreen()
    : requestFullscreen(element)

  return fn.then(isBrowserFullscreen)
}

const VISIBILITY_CHANGE_EVENT = 'visibilitychange'
const VISIBILITY_STATES = {
  HIDDEN: 'hidden',
  VISIBLE: 'visible',
}

const createVisibilityListener =
  (onHidden, onHiddenParams, onVisible, onVisibleParams) => () => {
    const { visibilityState } = document || {}
    const isHidden = visibilityState === VISIBILITY_STATES.HIDDEN
    const isVisible = visibilityState == VISIBILITY_STATES.VISIBLE

    if (isHidden) {
      onHidden(...onHiddenParams)
    } else if (isVisible) {
      onVisible(...onVisibleParams)
    }
  }

let visibilityListener

export const startVisibilityListener = (
  onHidden,
  onHiddenParams,
  onVisible,
  onVisibleParams,
) => {
  visibilityListener = createVisibilityListener(
    onHidden,
    onHiddenParams,
    onVisible,
    onVisibleParams,
  )
  visibilityListener() //Run it once the first time to initialize correctly for current visibility state
  document.addEventListener(VISIBILITY_CHANGE_EVENT, visibilityListener, true)
}

export const stopVisibilityListener = () => {
  document.removeEventListener(
    VISIBILITY_CHANGE_EVENT,
    visibilityListener,
    true,
  )
}
