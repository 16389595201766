/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

export default () => (
  <svg
    height="22px"
    version="1.1"
    viewBox="0 0 18 22"
    width="18px"
    x="0px"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
  >
    <g transform="translate(-71.000000, -571.000000)">
      <g transform="translate(40.000000, 398.000000)">
        <g transform="translate(27.000000, 137.547350)">
          <g id="values-icon" transform="translate(4.000000, 35.452650)">
            <defs>
              <filter
                filterUnits="userSpaceOnUse"
                height="21.6"
                width="17.7"
                x="0"
                y="0"
              >
                <feColorMatrix
                  type="matrix"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                />
              </filter>
            </defs>
            <mask
              height="21.6"
              maskUnits="userSpaceOnUse"
              width="17.7"
              x="0"
              y="0"
            >
              <g className="st0">
                <polygon
                  className="st1"
                  points="0,0 17.7,0 17.7,21.6 0,21.6 							"
                />
              </g>
            </mask>
            <path
              className="st2"
              d="M13.3,9.3c0,0.1-0.1,0.1-0.1,0.1c-0.5,0.5-1,1.1-1.5,1.6c-0.2,0.3-0.3,0.6-0.3,0.9
						c0.1,0.7,0.2,1.5,0.2,2.2c0,0.2-0.1,0.4-0.4,0.3c-0.7-0.3-1.3-0.6-2-0.9c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1,0-0.3,0-0.4,0.1
						c-0.7,0.3-1.3,0.6-2,0.9c0,0-0.1,0-0.1,0c-0.2,0.1-0.3,0-0.3-0.2c0-0.4,0.1-0.7,0.1-1.1c0-0.4,0.1-0.8,0.1-1.2
						c0-0.3-0.1-0.6-0.3-0.9c-0.5-0.5-1-1.1-1.5-1.6c0,0,0,0,0,0C4.3,9.2,4.3,9,4.6,9C5,8.9,5.5,8.8,5.9,8.7
						c0.3-0.1,0.6-0.1,0.9-0.2C7.1,8.4,7.3,8.2,7.5,8c0.4-0.6,0.7-1.3,1.1-1.9C8.6,6,8.7,5.8,8.8,5.8C9,5.8,9,6,9.1,6.1
						c0.3,0.6,0.7,1.2,1,1.8c0.2,0.4,0.5,0.6,0.9,0.7c0.7,0.1,1.4,0.3,2.1,0.4c0,0,0.1,0,0.1,0C13.3,9.1,13.4,9.2,13.3,9.3
						 M17.7,6.9L17.7,6.9c0-1,0-2.1,0-3.1c0-0.6-0.3-0.9-0.9-0.9c-0.8-0.1-1.6-0.1-2.4-0.3c-1.9-0.4-3.6-1.1-5-2.4
						C9-0.1,8.6-0.1,8.3,0.2C7.8,0.6,7.3,1,6.7,1.4C4.9,2.4,2.9,2.9,0.9,2.9C0.3,2.9,0,3.2,0,3.8c0,1.9,0,3.8,0,5.7
						c0,1.5,0.3,3,0.8,4.4c1.4,3.6,3.8,6.2,7.5,7.5c0.4,0.1,0.8,0.2,1.1,0c0.5-0.2,1-0.4,1.4-0.6c4.4-2.4,6.6-6.2,6.8-11.1
						C17.7,8.8,17.7,7.9,17.7,6.9"
              fill="currentColor"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
