import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { H5P_IMG_ORIGIN } from '../../lib/config'
import {
  updateCmiInteractives,
  updateLMSSuspendData,
} from '../../lib/state/actions/cmi'
import {
  completeSlide,
  imageHotspotsInitialize,
  imageHotspotsTargetClick,
  toggleModal,
} from '../../lib/state/actions/player'

import ImageHotspotsComponent from './component'
import {
  createContentfulLayoutForHotspot,
  getHotspotById,
  sortHotspotsLeftToRight,
} from './utils'

const HotspotsContainer = props => {
  const { targets } = props || {}
  const [hotspots, setHotspots] = useState([])
  // Runs any time targets are updated. Using an anonymous function for useEffect does not cause performance issues.
  useEffect(() => initializeHotspotsInteractive(), [])
  useEffect(() => {
    checkIfAllTargetsClicked()
    updateSuspendData()
  }, [targets])

  const initializeHotspotsInteractive = () => {
    const {
      hotspots,
      id,
      imageHotspotsInitialize,
      interactiveState,
      shouldInitialize,
    } = props || {}
    const orderedHotspots = hotspots.sort(sortHotspotsLeftToRight)

    setHotspots(orderedHotspots)
    shouldInitialize && imageHotspotsInitialize(id, interactiveState)
  }

  const onClickTarget = (hotspotId, hotspotIndex) => {
    const { id, imageHotspotsTargetClick, openModal } = props || {}
    const hotspot = getHotspotById(hotspots, hotspotId)
    const modalLayout = createContentfulLayoutForHotspot(hotspot) || {}

    // Need to get hotspot index
    imageHotspotsTargetClick(id, hotspotIndex)
    openModal(modalLayout)
  }

  const checkIfAllTargetsClicked = () => {
    const { completeSlide, currentSlide, targets } = props || {}
    const totalNumTargets = (hotspots && hotspots.length) || 0
    const totalNumTargetsClicked = targets && Object.keys(targets).length

    if (
      totalNumTargets &&
      totalNumTargetsClicked &&
      totalNumTargets === totalNumTargetsClicked
    ) {
      completeSlide(currentSlide)
    }
  }

  const updateSuspendData = () => {
    const { id, targets, updateCmiInteractives, updateLMSSuspendData } =
      props || {}

    updateCmiInteractives({
      interactives: {
        [id]: {
          targets,
        },
      },
    })
    updateLMSSuspendData()
  }

  const getHotspotsProps = () => {
    const {
      backgroundImageAltText: imageAltText,
      id,
      image,
      targets: targetsClicked,
    } = props || {}
    const { path } = image || {}
    const imageSource = H5P_IMG_ORIGIN + path
    const imageHotspotsProps = {
      hotspots,
      id,
      imageAltText,
      imageSource,
      onClickTarget,
      targetsClicked,
    }

    return imageHotspotsProps
  }

  const imageHotspotsProps = getHotspotsProps()

  return <ImageHotspotsComponent {...imageHotspotsProps} />
}

HotspotsContainer.propTypes = {
  backgroundImageAltText: PropTypes.string,
  config: PropTypes.object,
  hotspots: PropTypes.array,
  id: PropTypes.string,
  image: PropTypes.object,
  imageHotspotsInitialize: PropTypes.func,
  onSubmit: PropTypes.func,
  openModal: PropTypes.func,
  targets: PropTypes.object,
  updateCmiInteractives: PropTypes.func,
  updateLMSSuspendData: PropTypes.func,
}

HotspotsContainer.defaultProps = {
  hotspots: [],
  onSubmit() {},
  openModal() {},
  targets: {},
}

const mapDispatchToProps = dispatch => ({
  openModal: section => dispatch(toggleModal(section)),
  completeSlide: slidePosition => dispatch(completeSlide(slidePosition)),
  imageHotspotsInitialize: (id, targets) =>
    dispatch(imageHotspotsInitialize(id, targets)),
  imageHotspotsTargetClick: (id, index) =>
    dispatch(imageHotspotsTargetClick(id, index)),
  updateCmiInteractives: data => dispatch(updateCmiInteractives(data)),
  updateLMSSuspendData: () => dispatch(updateLMSSuspendData()),
})

const mapStateToProps = ({ player }, ownProps) => {
  const { id } = ownProps || {}
  const interactiveState = player.interactiveStates[id] || null

  return {
    ...interactiveState,
    currentSlide: player.currentSlide,
    shouldInitialize: interactiveState === null,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HotspotsContainer)
