import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import BannerComponent from '../../Banner'

import { ADDITIONAL_DETAILS, DATA_TEST_ID, EMPHATIC_MESSAGE } from './constants'

const FamilyBannerComponent = ({ isFamilyMode }) => {
  if (isFamilyMode) {
    return (
      <BannerComponent
        data-testid={DATA_TEST_ID}
        message={EMPHATIC_MESSAGE}
        additionalDetails={ADDITIONAL_DETAILS}
      />
    )
  }

  return null
}

FamilyBannerComponent.propTypes = {
  isFamilyMode: PropTypes.bool,
}

FamilyBannerComponent.defaultProps = {
  isFamilyMode: false,
}

const mapStateToProps = ({ player: { isFamilyMode } }) => ({ isFamilyMode })

export default connect(mapStateToProps, {})(FamilyBannerComponent)
