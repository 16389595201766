import React from 'react'
import PropTypes from 'prop-types'

import { ModalBackdropLight } from '../ModalBackdrop'
import SecondStepLogo from '../SecondStepLogo'
import LoadingIndicator from '../LoadingIndicator'

import {
  LoadingContainer,
  LoadingMessage,
  LoadingWrapper,
  LoadingText,
} from './Loading.styles'

// eslint-disable-next-line
const Loader = ({ loadingText }) => (
  <LoadingWrapper>
    <LoadingContainer dataTestId="loading-logo">
      <SecondStepLogo />
      <LoadingMessage>
        <LoadingIndicator />
        <LoadingText>{loadingText}</LoadingText>
      </LoadingMessage>
    </LoadingContainer>
  </LoadingWrapper>
)

const Loading = ({ background, isLoading, loadingText }) => {
  if (!isLoading) return null

  return (
    <>
      {!background && <Loader loadingText={loadingText} />}
      {background && (
        <ModalBackdropLight>
          <Loader loadingText={loadingText} />
        </ModalBackdropLight>
      )}
    </>
  )
}

Loading.propTypes = {
  background: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string,
}

Loading.defaultProps = {
  background: true,
  isLoading: true,
  loadingText: 'Loading...',
}

export default Loading
