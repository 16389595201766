import React from 'react'
import PropTypes from 'prop-types'

function HalfCircleProgressBar({ progress = 0 }) {
  const barProgress =
    progress >= 0 && progress <= 100 ? `${179 * (progress / 100.0 - 1)}%` : 0
  return (
    <svg
      height="179"
      viewBox="0 0 332 179"
      width="332"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeLinecap="round"
        strokeWidth="1"
      >
        <path
          d="M318.475 165c0-83.395-68.159-151-152.237-151C82.159 14 14 81.605 14 165"
          stroke="#CED8DF"
          strokeWidth="25"
        />
      </g>
      <defs>
        <linearGradient
          id="linearGradient-1"
          x1="0%"
          x2="100%"
          y1="63.062%"
          y2="62.298%"
        >
          <stop offset="0%" stopColor="#002855" />
          <stop offset="100%" stopColor="#C62D70" />
        </linearGradient>
      </defs>
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeDasharray="179%"
        strokeDashoffset={barProgress}
        strokeLinecap="round"
        strokeWidth="1"
      >
        <path
          d="M317.475 164c0-83.395-68.159-151-152.237-151C81.159 13 13 80.605 13 164"
          stroke="url(#linearGradient-1)"
          strokeWidth="25"
        />
      </g>
    </svg>
  )
}

HalfCircleProgressBar.propTypes = {
  progress: PropTypes.number,
}

export default HalfCircleProgressBar
