import React from 'react'
import PropTypes from 'prop-types'
import {
  ControlsBox,
  FormAdd,
  FormInputNumberInputWrapper,
  FormSubtract,
  Input,
  Vr,
} from './component.styles'

const FormInputNumberInput = ({ dataTestId, onChange, placeholder, value }) => {
  return (
    <FormInputNumberInputWrapper>
      <Input
        dataTestId={dataTestId}
        onChange={({ target: { value } }) => onChange(value)}
        placeholder={placeholder}
        type="number"
        value={value}
      />
      <ControlsBox>
        <FormSubtract
          onClick={() => onChange(value - 1)}
          onKeyUp={event => {
            if (event.key === 'Enter') {
              onChange(value - 1)
            }
          }}
        />
        <Vr noshade />
        <FormAdd
          onClick={() => onChange(value + 1)}
          onKeyUp={event => {
            if (event.key === 'Enter') {
              onChange(value - 1)
            }
          }}
        />
      </ControlsBox>
    </FormInputNumberInputWrapper>
  )
}

FormInputNumberInput.propTypes = {
  dataTestId: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.number,
}

export default FormInputNumberInput
