import React from 'react'
import PropTypes from 'prop-types'

import { TextEntryLabel } from './component.styles'

const FormInputLabel = ({ dataTestId, htmlFor, label }) => {
  return (
    <TextEntryLabel dataTestId={dataTestId} htmlFor={htmlFor || label}>
      {label}
    </TextEntryLabel>
  )
}

FormInputLabel.propTypes = {
  dataTestId: PropTypes.string,
  htmlFor: PropTypes.string,
  label: PropTypes.string.isRequired,
}

export default FormInputLabel
