import React from 'react'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { DateInput } from 'grommet-controls'
import { Box } from 'grommet'
import FormField from '../FormField'
import { fontWeightNormal } from '../../theme/defaultValues'
import IconCalendar from '../IconCalendar'

export const FormFieldStyled = styled(FormField)`
  button {
    padding: 0.6875rem 0.75rem;
    svg {
      width: 1.125rem;
    }
  }
`

export const DateInputContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`

export const DateInputStyled = styled(DateInput).attrs(
  ({ dataTestId, theme }) => ({
    'data-testid': dataTestId,
    dropIcon: <IconCalendar color={theme.colors?.icon?.light || 'white'} />,
  }),
)`
  color: ${themeGet('colors.input.text')};
  font-family: ${themeGet('fontFamilySemibold')};
  font-weight: ${fontWeightNormal};
  padding: 0.6875rem 0.6875rem 0.4375rem;
  &::placeholder {
    font-family: ${themeGet('fontFamily')};
    font-weight: 400;
    opacity: 1;
  }
`
