import styled from 'styled-components'
import { themeGet } from 'styled-system'

// Many of these styles copied straight from https://design-system.w3.org/
export const Nav = styled.nav`
  font-family: ${themeGet('font.labels.primary')};
  font-size: 0.9375rem;
  line-height: 1.5rem;
  color: #555555;

  [hidden] {
    visibility: hidden;
  }

  .middle {
    flex-grow: 2;
  }

  .mobile-only {
    display: none;
    visibility: hidden;
    select {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      padding-right: 0.5rem !important;
      height: 2.625rem;
      width: 7.625rem;
      border: 1px solid rgba(133, 133, 133, .7);
      border-radius: 0.125rem;
    }
    .select-wrapper {
      padding-right: 0.688rem;
    }
  }
  .nonmobile {
    display: flex;
    visibility: visible;
  }

  ${themeGet('breakpoints.mobileLarge')} {
    ul {
      width: 100%;
      margin: 0 !important;
      padding: 0 !important;
      .previous {
        align-self: center !important;
      }
      .next {
        align-self: center !important;
      }
    }
  }

  ${themeGet('breakpoints.tablet')} {
    .mobile-only {
      display: flex;
      visibility: visible;
    }
    .nonmobile {
      display: none;
      visibility: hidden;
    }
  }

  width: 100%;

  .visuallyhidden:not(:focus):not(:active) {
    border: 0;
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(100%);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    margin: 2.5rem;
    padding: 0;
    .previous {
      align-self: flex-start;
    }
    .next {
      align-self: flex-end;
    }

    li {
      justify-content: center;
      &:first-child {
        justify-content: flex-start;
      }
      &:nth-child(2) {
        flex-grow: 1;
        justify-content: flex-end;
      }
      &:last-child {
        flex-grow: 1;
        justify-content: flex-end;
      }

      display: flex;
      list-style: none;
      padding: 0;
      margin-left: 0.25rem;
      margin-right: 0.25rem;

      a {
        padding: 0;
        padding-top: 0.125rem; // hack to vertically align 
        position: relative;
        color: #555555;
        &:visited {
          color: #555555;
        }
        svg {
          width: 1rem;
          height: 1rem;
          margin-right: 0.5rem;
          margin-left: 0.5rem;
        }

        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 1.5rem;
        text-decoration: none;
        :hover {
          color: ${themeGet('colors.buttons.primaryBackground')};
        }
        &[aria-current='page'] {
            border-radius: 0.125rem;
            background-color: ${themeGet('colors.buttons.primaryBackground')};
            color: ${themeGet('colors.buttons.primaryFont')};
          }
        }
      }
    }
  }
`
