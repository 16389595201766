import PropTypes from 'prop-types'
import React from 'react'
import { StyledTab, TabButtonWrapper, Underline } from './component.styles'

const TabButton = ({
  ariaControls,
  dataTestId,
  className,
  isActive,
  label,
  onClick,
}) => (
  <TabButtonWrapper className={className}>
    <StyledTab
      ariaControls={ariaControls}
      dataTestId={dataTestId}
      isActive={isActive}
      onClick={onClick}
      title={label}
    />

    <Underline isActive={isActive} />
  </TabButtonWrapper>
)

TabButton.propTypes = {
  ariaControls: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default TabButton
