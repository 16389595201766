import React from 'react'

import PropTypes from 'prop-types'

import { DropdownItem, ItemText } from './style'

const LanguageToggleItemComponent = ({ itemName, onClick }) => {
  return (
    <DropdownItem
      data-testid="locale-toggle-switch-item"
      onClick={onClick}
      tabIndex={0}
      onKeyPress={e => {
        if (e.charCode === 13) {
          onClick()
        }
      }}
    >
      <ItemText> {itemName}</ItemText>
    </DropdownItem>
  )
}
LanguageToggleItemComponent.propTypes = {
  itemName: PropTypes.string,
  onClick: PropTypes.func,
}

export default LanguageToggleItemComponent
