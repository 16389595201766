/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'
import PropTypes from 'prop-types'

const CertificateIcon = ({
  height = 43,
  width = 65,
  dataTestId = 'certificate-icon',
}) => (
  <svg
    data-testid={dataTestId}
    height="43px"
    viewBox="0 0 65 43"
    width="65px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <style>
      {'.prefix__st3{fill-rule:evenodd;clip-rule:evenodd;fill:#00a3a0}'}
    </style>
    <g id="prefix__certificate">
      <g id="prefix__Group-3" transform="translate(39.667 11.362)">
        <defs>
          <filter
            filterUnits="userSpaceOnUse"
            height={21.1}
            id="prefix__Adobe_OpacityMaskFilter"
            width={14}
            x={0}
            y={0}
          >
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
          </filter>
        </defs>
        <mask
          height={21.1}
          id="prefix__mask-2_1_"
          maskUnits="userSpaceOnUse"
          width={14}
          x={0}
          y={0}
        >
          <g filter="url(#prefix__Adobe_OpacityMaskFilter)">
            <path
              clipRule="evenodd"
              d="M0 0h14.1v21.1H0z"
              fill="#fff"
              fillRule="evenodd"
              id="prefix__path-1_1_"
            />
          </g>
        </mask>
        <path
          clipRule="evenodd"
          d="M9.8 18.3v-4.9c-1.8.8-3.6.7-5.5 0v4.9c.7-.3 1.3-.6 2-1 .5-.3 1-.3 1.5 0 .6.4 1.3.7 2 1M2.2 6.9c0 2.7 2.2 4.9 4.8 4.9 2.6 0 4.8-2.2 4.8-4.8 0-2.7-2.1-4.9-4.8-4.9-2.6 0-4.7 2.1-4.8 4.8m-.1 9.2v-3.7c0-.3-.1-.6-.3-.8C0 9.4-.5 7 .6 4.3 1.7 1.7 3.8.2 6.7 0c2.9-.1 5.2 1.2 6.5 3.8 1.4 2.7 1.1 5.2-.8 7.6-.3.4-.5.8-.5 1.3v7c0 1.3-.7 1.7-1.8 1.1-.9-.4-1.8-.9-2.7-1.3-.2-.1-.6-.1-.8 0-.9.4-1.9.9-2.8 1.3-1 .5-1.7 0-1.7-1.1v-3.6"
          fill="#00a3a0"
          fillRule="evenodd"
          id="prefix__Fill-1"
        />
      </g>
      <path
        className="prefix__st3"
        d="M22.7 30.8H11.9c-.5-.1-.9-.5-.9-1s.3-1 .9-1.1h21.5c.6.1 1 .5 1 1.1 0 .5-.4 1-1 1H22.7"
        id="prefix__Fill-4"
      />
      <path
        className="prefix__st3"
        d="M22.8 13.1h10.6c.5.1.9.5 1 1 0 .6-.3 1-.8 1.1-.2.1-.5 0-.7 0H12.7c-1 0-1.5-.4-1.5-1.1 0-.7.5-1.1 1.6-1.1 3.2.1 6.6.1 10 .1"
        id="prefix__Fill-6"
      />
      <path
        className="prefix__st3"
        d="M22.6 23H12c-.6 0-1-.5-1-1.1 0-.6.4-1 1-1H33.3c.6 0 1.1.5 1.1 1.1 0 .6-.4 1-1 1.1h-.6C29.4 23 26 23 22.6 23"
        id="prefix__Fill-8"
      />
      <path
        d="M59 43H6c-3.3 0-6-2.7-6-6V6c0-3.3 2.7-6 6-6h53c3.3 0 6 2.7 6 6v31c0 3.3-2.7 6-6 6zM6 2C3.8 2 2 3.8 2 6v31c0 2.2 1.8 4 4 4h53c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4H6z"
        fill="#00a3a0"
      />
    </g>
  </svg>
)

CertificateIcon.propTypes = {
  dataTestId: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
}

export default CertificateIcon
