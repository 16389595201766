/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

const AppleAndBooksIcon = () => {
  return (
    <svg
      data-name="Layer 1"
      data-testid="icon-apple-and-books"
      height="90"
      id="prefix__Layer_1"
      viewBox="0 0 110 90"
      width="110"
    >
      <defs>
        <style>
          {
            '.prefix__cls-4{fill:#002855}.prefix__cls-5{fill:#657d98}.prefix__cls-6{fill:#487a7b}'
          }
        </style>
      </defs>
      <title>{'k8_books_icon'}</title>
      <path
        d="M64.6.37a.71.71 0 00-.68-.16A9.51 9.51 0 0058.66 3a4.53 4.53 0 00-1.28 3.25 4 4 0 00.1.9v.18a1 1 0 001.06.89h.11a4.87 4.87 0 003.09-1.08A5.88 5.88 0 0063 5.87a10.3 10.3 0 001.8-4.82.73.73 0 00-.2-.68z"
        fill="#46b446"
      />
      <path
        d="M71.78 20.21a14.28 14.28 0 00-1-5.39 8.3 8.3 0 00-8.22-5.31A13.9 13.9 0 0056.69 11l-.28.14a7.91 7.91 0 01-1 .46.92.92 0 01-.69 0c-.51-.18-1-.42-1.55-.67l-.21-.1a12.66 12.66 0 00-6.37-1.35 8.13 8.13 0 00-7.46 5.54 14.31 14.31 0 00-.9 5.51 19.71 19.71 0 003.64 11.16 12.52 12.52 0 003.42 3.35 7.52 7.52 0 007.24.7c.22-.08.43-.18.65-.27a5.59 5.59 0 011.17-.42 3.76 3.76 0 012.23.34l.36.16a8.57 8.57 0 001.88.71 7 7 0 001.5.16 7.75 7.75 0 003.65-1 12.8 12.8 0 004.62-4.44 19.72 19.72 0 003-8.7c.08-.65.12-1.34.14-2.11v-.05zm-25.91-4.8a1.19 1.19 0 01-.47.78s-2.19 1.74-1.14 5.48a1.33 1.33 0 010 .86 1.18 1.18 0 01-2.24-.1c-1.59-5.41 1.82-8 2-8.15a1.19 1.19 0 011.66.24 1.23 1.23 0 01.19.89z"
        fill="#e1523d"
      />
      <path
        d="M51 4.25l-.15.13a12 12 0 012.22 4.31c.38.16.76.33 1.15.52l.29.14a1 1 0 00.91 0l.58-.23.14-.12a11 11 0 00-.69-2.6 15.83 15.83 0 00-2.35-4 1.47 1.47 0 00-2.59.84 1.45 1.45 0 00.35 1.07z"
        fill="#472001"
      />
      <path
        className="prefix__cls-4"
        d="M30.78 40.62h52.75v13.91H30.78zM85.52 40.62h4.3v13.91h-4.3zM24.49 40.62h4.3v13.91h-4.3zM101.33 40.62H91.8v13.92h9.53a2.81 2.81 0 002.81-2.81v-8.3a2.81 2.81 0 00-2.81-2.81zM22.5 40.62h-9.37a2.81 2.81 0 00-2.81 2.81v8.3a2.81 2.81 0 002.81 2.81h9.37z"
      />
      <path
        className="prefix__cls-5"
        d="M91.89 58.73H78.3v13.91h13.59a2.81 2.81 0 002.81-2.81v-8.29a2.8 2.8 0 00-2.81-2.81zM19.18 72.64h57.13V58.73H19.18zm9.34-8h38.35a1.15 1.15 0 110 2.29H28.52a1.15 1.15 0 110-2.29zM17.19 58.73H3.69a2.81 2.81 0 00-2.81 2.81v8.29a2.81 2.81 0 002.81 2.81h13.5z"
      />
      <path
        className="prefix__cls-6"
        d="M29.38 76.82h65.65v13H29.38zM106.31 76.82H97v13h9.29a2.81 2.81 0 002.83-2.82v-7.37a2.81 2.81 0 00-2.81-2.81zm-3.24 8a1.51 1.51 0 111.51-1.51 1.52 1.52 0 01-1.51 1.51zM18.11 76.82a2.81 2.81 0 00-2.81 2.81V87a2.81 2.81 0 002.81 2.81h9.28v-13zm3.25 8a1.51 1.51 0 111.51-1.51 1.5 1.5 0 01-1.51 1.5z"
      />
    </svg>
  )
}

export default AppleAndBooksIcon
