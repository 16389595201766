import styled from 'styled-components'
import PropTypes from 'prop-types'
import { themeGet } from 'styled-system'
import { Heading } from 'grommet'
import {
  toNoBreakContentArray,
  toContentArray,
} from '../../utils/componentHelpers'

const HeaderFive = styled(Heading).attrs(({ dataTestId, children }) => {
  const contentArray = toContentArray(children)
  const noBreakContentArray = toNoBreakContentArray(contentArray)
  return {
    a11yTitle: 'SubHeader',
    'data-testid': dataTestId,
    level: 5,
    responsive: false,
    children: noBreakContentArray,
  }
})`
  max-width: 100%;
  color: ${themeGet('colors.heading.level.5')};
  letter-spacing: 0;
  font-size: 1.125rem;

  ${themeGet('breakpoints.tablet')} {
    font-size: ${themeGet('heading.level.5.tablet.size')};
    line-height: ${themeGet(`heading.level.5.tablet.height`)};
  }

  ${themeGet('breakpoints.mobile')} {
    font-size: ${themeGet(`heading.level.5.mobile.size`)};
    line-height: ${themeGet(`heading.level.5.mobile.height`)};
  }
`

HeaderFive.propTypes = {
  dataTestId: PropTypes.string.isRequired,
}

HeaderFive.defaultProps = {
  textAlign: 'start',
}

HeaderFive.displayName = 'HeaderFive'

export default HeaderFive
