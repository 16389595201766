import { Logger } from 'logging'

export const AI_PRESENTATION_LOADED_KEY = 'ai_presentation_loaded'
export const AI_PRESENTATION_MARK_DONE_KEY = 'ai_presentation_mark_done'
export const AI_PRESENTATION_SLIDE_NAVIGATION =
  'ai_presentation_slide_navigation'
export const AI_PRESENTATION_MODAL_OPENED = 'ai_presentation_modal_opened'

export const sendApplicationInsightsEvent = (key, data) => {
  try {
    if (!key) throw new Error('invalid event')
    Logger.logEvent({
      name: key,
      properties: data || {},
    })
  } catch (e) {
    console.error('Cannot log event')
  }
}
