/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

export default () => (
  <svg
    height="19px"
    version="1.1"
    viewBox="0 0 23 19"
    width="23px"
    x="0px"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
  >
    <g transform="translate(-68.000000, -296.000000)">
      <g transform="translate(40.000000, 40.000000)">
        <g transform="translate(27.000000, 138.547350)">
          <g
            id="bullying-and-harrassment-icon"
            transform="translate(1.000000, 117.452650)"
          >
            <path
              className="st0"
              d="M4.3,7.1c0.6,0.3,1.2,0.4,1.9,0.3c1.3-0.3,2.1-1.7,1.8-3C7.7,3.4,6.7,2.6,5.6,2.6
						c-0.2,0-0.4,0-0.6,0.1C3.7,3,2.9,4.3,3.2,5.6C3.3,6.3,3.7,6.8,4.3,7.1"
              fill="currentColor"
            />
            <path
              className="st0"
              d="M17.6,10.2c1.1,0.2,2.2-0.6,2.3-1.7c0.2-1.1-0.6-2.2-1.7-2.3c-0.1,0-0.2,0-0.3,0
						c-1,0-1.9,0.7-2,1.7c-0.1,0.5,0,1.1,0.4,1.5C16.6,9.9,17.1,10.2,17.6,10.2"
              fill="currentColor"
            />
            <g transform="translate(8.970000, 0.251277)">
              <defs>
                <filter
                  filterUnits="userSpaceOnUse"
                  height="2.6"
                  width="3.4"
                  x="0.3"
                  y="0.1"
                >
                  <feColorMatrix
                    type="matrix"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                  />
                </filter>
              </defs>
              <mask
                height="2.6"
                maskUnits="userSpaceOnUse"
                width="3.4"
                x="0.3"
                y="0.1"
              >
                <g className="st1">
                  <polygon
                    className="st2"
                    points="0.3,0.1 3.7,0.1 3.7,2.8 0.3,2.8 								"
                  />
                </g>
              </mask>
              <path
                className="st3"
                d="M1,2.8c0.1,0,0.3,0,0.4-0.1l1.9-1.1c0.4-0.2,0.5-0.7,0.3-1.1C3.5,0.3,3.3,0.2,3.1,0.2
							c-0.2-0.1-0.4,0-0.6,0.1L0.6,1.3C0.3,1.6,0.2,2,0.4,2.4C0.5,2.6,0.8,2.8,1,2.8"
                fill="currentColor"
              />
            </g>
            <path
              className="st0"
              d="M9.8,4.8L9.8,4.8c0,0.4,0.4,0.7,0.8,0.7c0,0,0.1,0,0.1,0l2.2-0.2c0.4,0,0.7-0.4,0.7-0.8
						c0-0.2-0.1-0.4-0.3-0.5c-0.2-0.1-0.4-0.2-0.6-0.2L10.5,4C10.1,4,9.8,4.4,9.8,4.8"
              fill="currentColor"
            />
            <path
              className="st0"
              d="M10.6,6.5L10.6,6.5c-0.2-0.1-0.4,0-0.6,0C9.8,6.7,9.7,6.8,9.6,7c-0.1,0.4,0.1,0.8,0.5,1
						l2,0.7c0.1,0,0.1,0,0.2,0c0.3,0,0.6-0.2,0.7-0.5c0.1-0.4-0.1-0.8-0.5-1L10.6,6.5z"
              fill="currentColor"
            />
            <path
              className="st0"
              d="M13.9,10.2c-0.1,0-0.3,0.1-0.4,0.2c-0.5,0.4-0.8,0.6-1,0.7c-0.3,0.2-0.5,0.4-1.1,0.8
						c-0.4,0.3-0.7,0.3-1.1,0c-0.9-0.7-1.7-1.4-2.6-2.1L6.6,8.9C5.4,9.2,4.2,9,3.2,8.4C3.1,8.4,3,8.3,3,8.3C2.7,8.4,2.5,8.6,2.3,8.8
						c-0.7,0.8-1.3,1.8-1.6,3.1c-0.5,1.6-0.7,5-0.7,6.9C0,18.9,0.1,19,0.2,19h7.1c0.1,0,0.2-0.1,0.2-0.2c0-1.2,0-3.8,0-4.8l0-0.8
						c0-0.1,0-0.1,0-0.2l0.1-1.1l2.6,2.1c0.5,0.4,0.8,0.4,1.3,0c0.6-0.5,1.1-0.8,1.5-1.1c0.5-0.3,0.9-0.7,1.7-1.2
						c0.3-0.2,0.5-0.7,0.3-1C14.7,10.4,14.3,10.2,13.9,10.2"
              fill="currentColor"
            />
            <path
              className="st0"
              d="M22.2,13.6L22.2,13.6c-0.3-0.9-0.6-1.7-1.1-2.4c-0.2-0.3-0.4-0.5-0.6-0.6
						c-0.9,0.8-2.1,1.2-3.3,1c0,0-0.1,0-0.1,0c-0.4,0.8-0.5,1.6-0.6,2.4c-0.1,1-0.1,3.4,0,4.8c0,0.1,0.1,0.2,0.2,0.2h5.8
						c0.1,0,0.2-0.1,0.2-0.2C22.5,17.2,22.4,14.4,22.2,13.6"
              fill="currentColor"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
