export const dateToMMDDYYYY = date => {
  const pattern = /(\d{4})-(\d{2})-(\d{2})/
  if (date && date.match(pattern)) {
    date = date.replace(pattern, '$2/$3/$1')
  }
  return date
}
/* istanbul ignore next */
export const toPascalCase = string => {
  if (!string) {
    return ''
  }
  const arrayOfWords = string.replace('-', ' ').split(' ')

  arrayOfWords.forEach((word, index) => {
    arrayOfWords[index] = word.charAt(0).toUpperCase() + word.substring(1)
  })

  const pascalString = arrayOfWords.join('')
  return pascalString
}
/* istanbul ignore next */
export const toUrlSafe = (str, toLower = true) => {
  if (!str || str.length === 0)
    throw new Error(`toUrlSafe: invalid input:${str}`)

  const spaceReplacement = '-'
  const avoid = new RegExp('[.!$&\'"()*+,;=]', 'g')
  const intermediate = str.replace(avoid, '').replace(/\s/g, spaceReplacement)
  if (toLower) return intermediate.toLowerCase()
  return intermediate
}

export const noBreakEnEmDash = string =>
  string?.replace(
    /(?=\w*[–—])([\w–—]+)/g,
    '<span style="white-space: nowrap;">$&</span>',
  ) || ''
/* istanbul ignore next */
export const contentHasDash = content =>
  typeof content === 'string' && content.match(/—|–/g)
