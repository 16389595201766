import { getLocaleFromSession, getLocaleObject } from 'utils/locale'

export const GET_CAPTION_TRACK_WITH_SOURCE = (source = '') => ({
  kind: 'captions',
  label: 'English',
  src: source,
  srclang: 'en',
})

export const GET_CAPTION_TRACK_OBJECT = videoReference => {
  const url = GET_CAPTION_TRACK(videoReference)
  const locale = videoReference?.sys?.locale || 'en-US'

  return [
    {
      kind: 'captions',
      src: url,
      ...getLocaleObject(locale),
    },
  ]
}

export const ShowMenuBarForEnglish = () => {
  const currentLocale = getLocaleFromSession()
  if (currentLocale === 'es-US') {
    return false
  }
  return true
}
const getFieldsForModule = module => {
  const { fields } = module || {}
  return fields
}

const GET_CAPTION_TRACK = module => {
  const parentFields = getFieldsForModule(module)
  const { vttFile } = parentFields || module || {}
  const { fields } = vttFile || {}
  const { file } = fields || {}
  const { url } = file || {}

  return url
}
