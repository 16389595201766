import React from 'react'

import PropTypes from 'prop-types'

import { ARIA_LABEL, DATA_TEST_ID } from './constants'
import { DragQuestionResetStyledButton } from './style'

const DragQuestionResetButton = ({ disabled, onClick, setRef, text }) => {
  return (
    <DragQuestionResetStyledButton
      aria-label={ARIA_LABEL}
      data-testid={DATA_TEST_ID}
      disabled={disabled}
      onClick={onClick}
      ref={setRef}
    >
      {text}
    </DragQuestionResetStyledButton>
  )
}

DragQuestionResetButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  setRef: PropTypes.object,
  text: PropTypes.string,
}

DragQuestionResetButton.defaultProps = {
  onClick() {},
}

export default DragQuestionResetButton
