import { colors } from '../../../styles/styleGuide'

export const DEFAULT_FONT_COLOR = `${colors.black};`
export const DIALOG_CARDS = 'Dialogcards'
export const ERROR_LINKED_OBJECT_MISSING =
  'Error: Linked object was not retrieved. Ensure the section has been published.'
export const ERROR_NO_LESSON_CONTENT_FOUND = 'Error: No lesson content found.'
export const ERROR_NO_SLIDES = `Error: This section contains no slides. Be sure to publish at least
one slide per section.`
export const FIRST_SECTION = 0
export const INTERACTIVE = 'interactive'
export const LINK = 'Link'
