import React from 'react'
import PropTypes from 'prop-types'
import CaretIcon from '../CaretIcon/component.jsx'
import { AnchorStyled, NoBreak } from './component.styles'

const Words = ({ linkWords }) => linkWords.map(word => <span>{word} </span>)

const CaretLink = ({ children, dataTestId = 'caret-link', href }) => {
  const linkWords = children.trim().split(' ')
  const lastWord = linkWords.splice(-1, 1).toString()

  return (
    <AnchorStyled data-testid={dataTestId} href={href} target="_blank">
      <Words linkWords={linkWords} />
      <NoBreak>
        {lastWord}
        <CaretIcon />
      </NoBreak>
    </AnchorStyled>
  )
}

CaretLink.propTypes = {
  children: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  href: PropTypes.string,
}

export default CaretLink
