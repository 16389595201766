import React, { Component } from 'react'

import PropTypes from 'prop-types'

import { EMPTY_STRING } from '../constants'

import ImageJuxtLeftImageComponent from './component'

const ACCESSIBILITY_LABEL = 'Image juxtaposition left image container'

class ImageJuxtLeftImageContainer extends Component {
  constructor(props) {
    super(props)

    this.state = { isError: false }
  }

  onError = () => this.setState({ isError: true })

  getRenderProps = () => {
    const { alt, containerWidth, imageWidth, source } = this.props
    const { isError } = this.state || {}
    const showError = isError || !source || source === EMPTY_STRING
    const imageProps = {
      accessibilityLabel: ACCESSIBILITY_LABEL,
      alt,
      containerWidth,
      imageWidth,
      onError: this.onError,
      showError,
      source,
    }

    return { imageProps }
  }

  render = () => {
    const { imageProps } = this.getRenderProps()

    return <ImageJuxtLeftImageComponent {...imageProps} />
  }
}

ImageJuxtLeftImageContainer.propTypes = {
  alt: PropTypes.string,
  containerWidth: PropTypes.number,
  imageWidth: PropTypes.number,
  source: PropTypes.string,
}

ImageJuxtLeftImageContainer.defaultProps = {
  containerWidth: 0,
  imageWidth: 0,
}

export default ImageJuxtLeftImageContainer
