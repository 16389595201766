import styled from 'styled-components'

export const ButtonWrapper = styled.button`
  align-items: center;
  background-color: ${props =>
    props.isButtonActive
      ? props.theme.colorVars.markLessonDoneButton.lastSlideState
          .backgroundColor
      : props.theme.colorVars.markLessonDoneButton.defaultState
          .backgroundColor};
  border-radius: ${props => (props.isButtonActive ? 'none' : ' 0.1875rem')};
  border-style: ${props => (props.isButtonActive ? 'none' : 'solid')};
  border-width: ${props => (props.isButtonActive ? '0px' : '2px')};
  border-color: ${props =>
    props.isButtonActive
      ? 'none'
      : props.theme.colorVars.markLessonDoneButton.defaultState.borderColor};
  color: ${props =>
    props.isButtonActive
      ? props.theme.colorVars.markLessonDoneButton.lastSlideState.fontColor
      : props.theme.colorVars.markLessonDoneButton.lastSlideState
          .backgroundColor};
  cursor: pointer;
  display: flex;
  height: 100%;
  transition: background-color, border-color, color 0.2s ease-in;

  :hover {
    background-color: ${props =>
      props.theme.colorVars.markLessonDoneButton.hoverState.backgroundColor};
    color: ${props =>
      props.theme.colorVars.markLessonDoneButton.hoverState.color};
    border-color: ${props =>
      props.theme.colorVars.markLessonDoneButton.hoverState.backgroundColor};

    line {
      stroke: ${props =>
        props.theme.colorVars.markLessonDoneButton.hoverState.color};
      transition: stroke 0.2s ease-in;
    }
  }
  :focus {
    outline: 3px groove ${props => props.theme.colorVars.blueFocus};
  }
`

export const ButtonTextWrapper = styled.div`
  line-height: 1rem;
  font-size: 1rem;
  font-weight: 600;
`

export const CheckMarkWrapper = styled.svg`
  height: 0.7em;
  width: 0.8em;
  margin-right: 0.35rem;
  margin-left: 0.125em;
  line {
    stroke: ${props =>
      props.isButtonActive
        ? props.theme.colorVars.markLessonDoneButton.lastSlideState
            .checkmarkColor
        : props.theme.colorVars.markLessonDoneButton.defaultState
            .checkmarkColor};
  }
`

//will render when mark lesson done is clicked
export const DoneButtonWrapper = styled.button`
  align-items: center;
  background-color: ${props =>
    props.theme.colorVars.markLessonDoneButton.defaultState.backgroundColor};
  border: none;
  cursor: pointer;
  display: flex;
  height: 100%;
`

export const CheckImage = styled.img`
  height: 1.25em;
  width: 1.25em;
  margin-right: 0.5em;
`

export const DoneButtonTextWrapper = styled.div`
  color: ${props =>
    props.theme.colorVars.markLessonDoneButton.completedState.fontColor};
  font-size: 1em;
  font-weight: 600;
`
