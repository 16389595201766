import React, { Component } from 'react'

import PropTypes from 'prop-types'

import ImageJuxtBodyComponent from './component'

const NEW_LINE = '\n'
const STRING_TO_REPLACE = /\\\\n/g

class ImageJuxtBodyContainer extends Component {
  splitTextIntoLines = () => {
    const { text } = this.props

    //Use the first two lines of whatever text is passed into this component.
    const textElements = text
      .replace(STRING_TO_REPLACE, '\n') //H5P newlines come in as \\n. Replace with \n
      .split(NEW_LINE)
      .filter(Boolean)
      .slice(0, 2)

    return textElements
  }

  render = () => {
    const textElements = this.splitTextIntoLines()

    return <ImageJuxtBodyComponent textElements={textElements} />
  }
}

ImageJuxtBodyContainer.propTypes = {
  text: PropTypes.string,
}

ImageJuxtBodyContainer.defaultProps = {
  text: '',
}

export default ImageJuxtBodyContainer
