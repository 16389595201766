import React from 'react'

import PropTypes from 'prop-types'

import SubmitAnswerIcon from '../../SubmitAnswerIcon'
import { EMPTY_STRING } from '../constants'

import { DRAG_ITEM_FOCUS_CONTAINER_SUFFIX, DRAG_ITEM_PREFIX } from './constants'
import {
  AccessibilityContainer,
  DraggableContainer,
  Grip,
  HoverIndicator,
  Text,
} from './style'

const DragQuestionDraggableComponent = ({
  draggableId,
  inCorrectLocation,
  isDragging,
  isHovered,
  isInDropZone,
  onBlur,
  onFocus,
  onKeyDown,
  onMouseEvent,
  refCallback,
  shouldShowSubmitAnswer,
  text,
  accessibilityRef,
}) => {
  const draggableDataTestId = `${DRAG_ITEM_PREFIX}${draggableId}`
  const accessibilityContainerTestId = `${draggableDataTestId}${DRAG_ITEM_FOCUS_CONTAINER_SUFFIX}`

  return (
    <AccessibilityContainer
      data-testid={accessibilityContainerTestId}
      onBlur={onBlur}
      onClick={onFocus}
      onFocus={onFocus}
      onKeyDown={event => onKeyDown(event, draggableId)}
      ref={accessibilityRef}
      tabIndex={0}
    >
      <DraggableContainer
        aria-label={DRAG_ITEM_PREFIX}
        data-testid={draggableDataTestId}
        isDragging={isDragging}
        isInDropZone={isInDropZone}
        onMouseEnter={onMouseEvent}
        onMouseLeave={onMouseEvent}
        ref={refCallback}
      >
        <HoverIndicator isHovered={isHovered} isInDropZone={isInDropZone} />
        <Grip />
        <Text>{text}</Text>
        <SubmitAnswerIcon
          inCorrectLocation={inCorrectLocation}
          isSubmitted={shouldShowSubmitAnswer}
        />
      </DraggableContainer>
    </AccessibilityContainer>
  )
}

DragQuestionDraggableComponent.propTypes = {
  accessibilityRef: PropTypes.object,
  draggableId: PropTypes.string,
  inCorrectLocation: PropTypes.bool,
  isDragging: PropTypes.bool,
  isHovered: PropTypes.bool,
  isInDropZone: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onMouseEvent: PropTypes.func,
  refCallback: PropTypes.func,
  shouldShowSubmitAnswer: PropTypes.bool,
  text: PropTypes.string,
}

DragQuestionDraggableComponent.defaultProps = {
  onFocus() {},
  onBlur() {},
  onKeyDown() {},
  onMouseEvent() {},
  refCallback() {},
  text: EMPTY_STRING,
}

export default DragQuestionDraggableComponent
