import PropTypes from 'prop-types'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Button } from 'grommet'

const TextButton = styled(Button).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
  plain: true,
}))`
  line-height: 1.5rem;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  font-family: ${themeGet('heading.font.family')};
  color: ${themeGet('colors.buttons.primaryBackground')};
  &:hover {
    color: ${themeGet('colors.buttons.primaryBackgroundHover')};
  }
`
TextButton.propTypes = {
  dataTestId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

TextButton.defaultProps = {
  disabled: false,
}

TextButton.displayName = 'TextButton'

export default TextButton
