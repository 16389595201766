import React from 'react'

import PropTypes from 'prop-types'

import check_mark from '../../../assets/images/checkBlack.svg'
import incorrect_x from '../../../assets/images/xBlack.svg'

import { CorrectAnswer, IncorrectAnswer } from './style'

const CORRECT_ANSWER = 'Correct Answer'
const DATA_TEST_ID = 'submit-answer-icon'
const INCORRECT_ANSWER = 'Incorrect Answer'

export const getProperAnswerStatus = props => {
  const { inCorrectLocation, isSubmitted } = props

  if (isSubmitted) {
    const AnswerComponent = inCorrectLocation ? (
      <CorrectAnswer>
        <img
          alt={CORRECT_ANSWER}
          aria-label={CORRECT_ANSWER}
          data-testid={DATA_TEST_ID}
          src={check_mark}
        />
      </CorrectAnswer>
    ) : (
      <IncorrectAnswer>
        <img
          alt={INCORRECT_ANSWER}
          aria-label={INCORRECT_ANSWER}
          data-testid={DATA_TEST_ID}
          src={incorrect_x}
        />
      </IncorrectAnswer>
    )

    return AnswerComponent
  } else {
    return null
  }
}

const SubmitAnswerIcon = props => {
  const AnswerIcon = getProperAnswerStatus(props)

  return AnswerIcon
}

SubmitAnswerIcon.propTypes = {
  inCorrectLocation: PropTypes.bool,
  isSubmitted: PropTypes.bool,
}

export default SubmitAnswerIcon
