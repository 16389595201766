import React from 'react'
import PropTypes from 'prop-types'
import { StyledButton, StyledCaret, Wrapper } from './component.styles'

export const SortButton = ({
  children,
  className,
  handleOnClick,
  isActive,
  isCaretUp,
  title,
}) => {
  const direction = isActive ? (isCaretUp ? 'up' : 'down') : 'down'
  return (
    <Wrapper className={className}>
      <StyledButton isActive={isActive} onClick={handleOnClick}>
        <StyledCaret active={isActive} direction={direction} />
        {title}
      </StyledButton>
      {children}
    </Wrapper>
  )
}
SortButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  handleOnClick: PropTypes.func,
  isActive: PropTypes.bool,
  isCaretUp: PropTypes.bool,
  title: PropTypes.string,
}

export default SortButton
