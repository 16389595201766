import { IS_CONNECTED_TO_API } from '../config'

import { getApiClient } from '.'

//Hydrate the redux store with saved cmi values retrieved from API
export default async () => {
  if (!IS_CONNECTED_TO_API) {
    return
  }

  const api = await getApiClient()
  const suspendDataURI = await api.GetValue('cmi.suspend_data')
  const hydratedData = {
    completion_status: await api.GetValue('cmi.completion_status'),
    location: await api.GetValue('cmi.location'),
    progress_measure: await api.GetValue('cmi.progress_measure'),
  }

  if (typeof suspendDataURI === 'string' && suspendDataURI !== '') {
    hydratedData.suspend_data = JSON.parse(
      window.decodeURIComponent(suspendDataURI),
    )
  }

  return hydratedData
}
