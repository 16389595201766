import React from 'react'

import { COMPLETION_STATUS } from '@cfc/clifford/dist/enums'
import { updateCompletionStatus } from 'lib/state/actions/cmi'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getLocaleFromSession } from 'utils/locale'

import { gotoDoneSlide } from '../../../lib/state/actions/player'

import ConfirmationModalComponent from './component'
import { MESSAGE } from './constants'

const ConfirmationModalContainer = props => {
  let message

  if (props.lessonName) {
    const currentLocale = getLocaleFromSession()
    message = MESSAGE(props.lessonName, currentLocale)
  }
  return <ConfirmationModalComponent {...props} message={message} />
}

ConfirmationModalContainer.propTypes = {
  feedbackUrl: PropTypes.string,
  lessonName: PropTypes.string,
  closeModal: PropTypes.func,
}

const mapDispatchToProps = dispatch => ({
  handleConfirm: () => {
    dispatch(updateCompletionStatus(COMPLETION_STATUS.Completed, true))
  },
  goToDoneSlide: (sectionIndex, slideIndex) =>
    dispatch(gotoDoneSlide(sectionIndex, slideIndex)),
})

const mapStateToProps = ({ player, cmi }) => {
  const { sectionIndex, slideIndex } = player.modal.metadata
  return {
    lessonName: player.title,
    sectionIndex,
    slideIndex,
    hasCompletionStatus: cmi.completion_status === COMPLETION_STATUS.Completed,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfirmationModalContainer)
