import React, { Component } from 'react'

import PropTypes from 'prop-types'

import { HelperCopy } from '../../text'

import { EvaluationTextContainer } from './style'

const DATA_TEST_ID = 'multiChoiceEvaluationText'

class MultiChoiceEvaluationTextComponent extends Component {
  render = () => {
    const { getSubmitMessage } = this.props
    const submitMessage = getSubmitMessage()

    return (
      <EvaluationTextContainer data-testid={DATA_TEST_ID}>
        <HelperCopy>{submitMessage}</HelperCopy>
      </EvaluationTextContainer>
    )
  }
}

MultiChoiceEvaluationTextComponent.propTypes = {
  getSubmitMessage: PropTypes.func,
}

MultiChoiceEvaluationTextComponent.defaultProps = {
  getSubmitMessage() {},
}

export default MultiChoiceEvaluationTextComponent
