import React from 'react'
import PropTypes from 'prop-types'
import {
  AutoTableFiltersDiv,
  AutoTableFiltersI,
  AutoTableFiltersTh,
} from './component.styles'

const AutoTableFilters = ({
  columns,
  filter,
  id,
  resolveClassName,
  updateFilter,
}) => {
  return (
    <tr>
      {columns.map((column, colIndex) => {
        const resolvedClassName = resolveClassName(
          column.headerClassName,
          column,
        )
        if (
          column.filter === false ||
          (typeof column.fieldValue === 'function' && !column.filterValue)
        )
          return <th key={`${id}_f_${colIndex}`}>&nbsp;</th>

        const colFilter = filter.find(
          filter => filter.columnTitle === column.title,
        ) || { query: '' }
        return (
          <AutoTableFiltersTh
            className={resolvedClassName}
            key={`${id}_f_${colIndex}`}
          >
            <AutoTableFiltersDiv dataTestId={colFilter.query}>
              <input
                onChange={e => updateFilter(column.title, e.target.value)}
                type="text"
                value={colFilter.query}
              />
              <AutoTableFiltersI
                className="fas fa-filter"
                query={colFilter.query}
              />
            </AutoTableFiltersDiv>
          </AutoTableFiltersTh>
        )
      })}
    </tr>
  )
}

AutoTableFilters.propTypes = {
  columns: PropTypes.array.isRequired,
  filter: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  resolveClassName: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
}

export default AutoTableFilters
