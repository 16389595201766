import { useEffect, useState } from 'react'

/* istanbul ignore next */
const useMediaQuery = query => {
  const [matches, setMatches] = useState(false)
  useEffect(
    () => {
      const mediaQuery = window.matchMedia(query)
      setMatches(mediaQuery.matches)
      const handler = event => setMatches(event.matches)
      mediaQuery.addEventListener('change', handler)
      return () => mediaQuery.removeEventListener('change', handler)
    },
    [], // Empty array ensures effect is only run on mount and unmount
  )
  return matches
}

export const useBreakpoints = (
  isResponsive = true,
  viewPort = '(max-width: 768px)',
) => {
  if (!isResponsive) {
    return {
      isSm: false,
    }
  }
  const breakpoints = {
    isSm: useMediaQuery(viewPort),
  }

  if (breakpoints.isSm) breakpoints.active = 'sm'
  return breakpoints
}

export const viewportParams = {
  viewport: {
    defaultViewport: 'desktop',
    viewports: {
      desktop: {
        name: 'Desktop',
        styles: {
          width: '1920px',
          height: '1080px',
        },
      },
      tablet: {
        name: 'Tablet',
        styles: {
          width: '768px',
          height: '1024px',
        },
      },
      mobile: {
        name: 'Mobile',
        styles: {
          width: '414px',
          height: '736px',
        },
      },
    },
  },
}

export default useBreakpoints
