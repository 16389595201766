/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

const AppleIcon = () => (
  <svg
    height="72"
    viewBox="0 0 72 72"
    width="72"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M0.0116453383 0.0871895768L9.46245915 0.0871895768 9.46245915 10.3267717 0.0116453383 10.3267717z"
        id="path-1"
      />
      <path
        d="M0 0.169850718L42.6322319 0.169850718 42.6322319 34.4385162 0 34.4385162z"
        id="path-3"
      />
    </defs>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g>
        <circle cx="36" cy="36" fill="#EEF0F2" r="36" />
        <g transform="translate(15 11)">
          <g transform="translate(24.326 .186)">
            <mask fill="#fff" id="mask-2">
              <use xlinkHref="#path-1" />
            </mask>
            <path
              d="M9.192.314c-.153-.141-.427-.29-.868-.2-2.8.578-4.985 1.753-6.68 3.591C.528 4.916-.006 6.275.012 7.835c-.008.36.06.758.124 1.143l.037.219c.067.412.33 1.107 1.339 1.128l.146.002c1.443 0 2.764-.459 3.927-1.363A7.71 7.71 0 007.16 7.31c1.25-1.783 2.019-3.835 2.286-6.125.02-.155.072-.568-.255-.871"
              fill="#46B446"
              mask="url(#mask-2)"
            />
          </g>
          <g transform="translate(0 11.939)">
            <mask fill="#fff" id="mask-4">
              <use xlinkHref="#path-3" />
            </mask>
            <path
              d="M9.713 7.671a1.5 1.5 0 01-.591.995c-.027.02-2.79 2.206-1.447 6.966.1.36.126.75-.013 1.095a1.508 1.508 0 01-2.854-.126C2.788 9.722 7.123 6.386 7.312 6.245a1.513 1.513 0 012.401 1.426zm32.919 6.106c-.037-2.71-.437-4.887-1.256-6.848C39.53 2.51 35.716.053 30.937.18c-2.527.069-4.97.704-7.468 1.941l-.358.18c-.45.226-.873.439-1.28.586-.323.116-.574.12-.869.015-.646-.233-1.29-.539-1.97-.861l-.269-.128C15.908.584 13.26.025 10.631.202 6.084.507 2.807 2.942 1.154 7.24.338 9.361-.048 11.716.004 14.238c.111 5.21 1.667 9.984 4.625 14.19a16.306 16.306 0 004.347 4.253c2.832 1.873 5.927 2.173 9.198.893.28-.11.555-.23.83-.35.559-.244 1.04-.456 1.488-.528.939-.154 1.868-.014 2.837.427.151.069.302.14.452.21.692.327 1.477.698 2.39.897a8.916 8.916 0 001.907.209c1.55 0 3.1-.41 4.63-1.228 2.257-1.207 4.179-3.05 5.874-5.632 2.1-3.2 3.405-6.92 3.88-11.056.095-.827.15-1.704.17-2.68v-.066z"
              fill="#E1523D"
              mask="url(#mask-4)"
            />
          </g>
          <path
            d="M16.25 5.43l-.197.169c.027.032 2.135 2.558 2.828 5.48.483.2.966.414 1.451.654l.369.185.006.003a1.33 1.33 0 001.159.02c.212-.098.434-.203.668-.314l.228-.108a13.561 13.561 0 00-.88-3.3c-1.139-2.857-2.907-4.953-2.981-5.041a1.872 1.872 0 00-3.288 1.06c-.04.497.116.98.44 1.36l.198-.167z"
            fill="#472001"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default AppleIcon
