import React from 'react'
import PropTypes from 'prop-types'
import { formatLastUpdatedDate } from '../../utils/dateHelper'
import { StyledBodyCopy } from './component.styles'

const LastUpdatedStatus = ({ className, dataTestId, timestamp }) => {
  const refDate = new Date(timestamp)

  return (
    <StyledBodyCopy className={className} data-testid={dataTestId}>
      {timestamp ? formatLastUpdatedDate(refDate) : ''}
    </StyledBodyCopy>
  )
}

LastUpdatedStatus.defaultProps = {
  dataTestId: 'last-updated-status',
}

LastUpdatedStatus.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  timestamp: PropTypes.string,
}

export default LastUpdatedStatus
