import React, { Component, Fragment } from 'react'

import PropTypes from 'prop-types'

import ErrorMessage from '../ErrorMessage'
import Modal from '../Modal'

import { DEFAULT_LAYOUT_DEPRECATED, PAGE_LAYOUT_COMPONENTS } from './routes'

class PageLayoutRouterComponent extends Component {
  getLayoutComponent = () => {
    const { route } = this.props
    const layoutNotFoundMessage = `Could not find page layout ${route}. Using default layout "${DEFAULT_LAYOUT_DEPRECATED}".`

    console.assert(route in PAGE_LAYOUT_COMPONENTS, layoutNotFoundMessage)

    const componentData = PAGE_LAYOUT_COMPONENTS[route] || {}
    const { component: LayoutComponent } = componentData || {}

    return LayoutComponent
  }

  getRenderProps = () => {
    const {
      elements,
      error,
      foregroundColor,
      heroAccessibilityLabel,
      heroImage,
      heroPosition,
      heroSize,
      onSubmit,
      slideBackgroundColor,
      title,
    } = this.props
    let ErrorComponent, LayoutComponent

    if (error) {
      ErrorComponent = ErrorMessage
    } else {
      LayoutComponent = this.getLayoutComponent()
    }

    const errorComponentProps = {
      error,
    }
    const layoutComponentProps = {
      elements,
      foregroundColor,
      heroAccessibilityLabel,
      heroImage,
      onSubmit,
      slideBackgroundColor,
      title,
      //TODO: These 3 props should only necessary for layout default. New layouts will not require these props.
      heroPosition,
      heroSize,
    }
    const renderProps = {
      ErrorComponent,
      errorComponentProps,
      LayoutComponent,
      layoutComponentProps,
    }

    return renderProps
  }

  render = () => {
    const {
      ErrorComponent,
      errorComponentProps,
      LayoutComponent,
      layoutComponentProps,
    } = this.getRenderProps() || {}
    const { error } = errorComponentProps || {}

    if (ErrorComponent) {
      const { url, message } = error

      return <ErrorComponent message={message} url={url} />
    }

    return LayoutComponent ? (
      <Fragment>
        <LayoutComponent {...layoutComponentProps} />
        <Modal />
      </Fragment>
    ) : null
  }
}

PageLayoutRouterComponent.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.array),
  error: PropTypes.shape({
    message: PropTypes.string,
    url: PropTypes.string,
  }),
  foregroundColor: PropTypes.string,
  heroAccessibilityLabel: PropTypes.string,
  heroImage: PropTypes.string,
  heroPosition: PropTypes.string,
  heroSize: PropTypes.string,
  onSubmit: PropTypes.func,
  route: PropTypes.string,
  slideBackgroundColor: PropTypes.string,
  title: PropTypes.string,
}

PageLayoutRouterComponent.defaultProps = {
  elements: [],
  onSubmit() {},
  route: DEFAULT_LAYOUT_DEPRECATED,
}

export default PageLayoutRouterComponent
