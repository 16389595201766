/* istanbul ignore file */

import { FlagsComponentRouter } from 'flags'

import OffComponent from './flag.off/index'
import OnComponent from './flag.on/index'

const FlagToggler = FlagsComponentRouter({
  flagSubscriptions: ['feature-LEARN-11000-language-toggle'],
  OnComponent,
  OffComponent,
})

export default FlagToggler
export * as AppWrapperConstants from './constants'
