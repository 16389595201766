import { createType, get } from '../../utils/apiHelpers'
import Auth0Manager from '../../utils/auth0Manager'
import {
  FAILURE_TYPE,
  REQUEST_TYPE,
  SUCCESS_TYPE,
  SET_SITE_ID_TYPE,
} from './types'

export async function fetchUserContext(dispatch, secondStepDomain) {
  const token = await Auth0Manager.getAccessToken()
  dispatch(getUserContext(secondStepDomain, token))
}

export async function fetchUserContextWithToken(
  dispatch,
  secondStepDomain,
  token,
) {
  dispatch(getUserContext(secondStepDomain, token))
}

export const getUserContext = (secondStepDomain, token) => {
  const fullUrl = createEndpoint(secondStepDomain)
  const typesOrdered = [
    createType(REQUEST_TYPE),
    createType(SUCCESS_TYPE),
    createType(FAILURE_TYPE),
  ]
  return get(fullUrl, typesOrdered, token)
}

export const createEndpoint = secondStepDomain => {
  const method = `context`
  const url = `${secondStepDomain}${method}`

  return url
}

export async function simpleFetchUserContext(secondStepDomain, token) {
  const response = await fetch(secondStepDomain, {
    headers: {
      Authorization: token,
    },
  })
  return await response.json()
}

export const setSiteContext = siteId => {
  return async dispatch => {
    dispatch(createType(SET_SITE_ID_TYPE, { siteId }))
  }
}

export default {
  fetchUserContext,
  fetchUserContextWithToken,
  setSiteContext,
}
