import styled from 'styled-components'

export const Dialog = styled.div`
  &:not(.explicitly-open) {
    display: none;
  }
  overflow-y: auto;
  z-index: 99999999999999;
  padding: 1rem;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @media screen and (min-width: 768px) {
    display: none;
  }
`
