// eslint-disable-next-line no-unused-vars
import {
  getDeviceResolutionTier,
  getScreenDimensions,
} from 'utils/deviceHelper'
import { getGradeFromThemeParam } from 'utils/gradeHelper'
import { getLocaleFromSession, LOCALES } from 'utils/locale'
import {
  isCurriculumPreviewMode,
  isFamilyMode,
  isHighSchoolPreviewMode,
  isPrepMode,
  isSampleMode,
} from 'utils/playerHelper'
import {
  AI_PRESENTATION_LOADED_KEY,
  AI_PRESENTATION_MODAL_OPENED,
  AI_PRESENTATION_SLIDE_NAVIGATION,
  sendApplicationInsightsEvent,
} from 'utils/sendAnalyticsDataHelper'

import themes from '../../../styles/themes'
import {
  DONE_SECTION_SLIDE,
  DONE_SLIDE_CONTENT_STRINGS,
  SLIDE_STATUS,
} from '../constants'

export const initialState = {
  buttonsVisitedStatus: {},
  completeStatus: {},
  currentSlide: [0, 0],
  currentSlideData: {},
  deviceResolutionTier: {},
  feedbackUrl: '',
  flags: {},
  grade: null,
  interactiveStates: {},
  isFullScreen: false,
  isNextSlide: false,
  isPrepMode: false,
  isFamilyMode: false,
  isCurriculumPreviewMode: false,
  isHighSchoolPreviewMode: false,
  isPrevSlide: false,
  modal: null,
  objectives: [],
  ratioWrapper: {
    //using aspect ratio of 16:9
    height: 768,
    scale: 1,
    width: 1366,
  },
  sections: [],
  session: {
    startDate: null,
    isActive: undefined,
  },
  theme: themes[Object.keys(themes)[0]],
  title: '',
  availableLocales: [],
  vtts: {},
}

function initializePlayer(state, { json }) {
  const { fields, sys } = json || {}
  const {
    displayTitle,
    grade,
    lessonFeedbackUrl = '',
    objectives,
    sections,
    languageToggle,
    theme,
  } = fields || {}
  const { id } = sys || {}
  const deviceResolutionTier = getDeviceResolutionTier(getScreenDimensions())
  state = addSections(state, { sections })
  state = setDeviceResolutionTier(state, deviceResolutionTier)

  const initialPlayerState = {
    ...state,
    availableLocales:
      languageToggle && languageToggle.fields
        ? getAvailableLocales(languageToggle.fields)
        : [],
    currentSlideData: getCurrentSlideData(state, 0, 0),
    feedbackUrl: lessonFeedbackUrl,
    grade,
    presentationId: id,
    objectives,
    title: displayTitle,
    isDoneSlide: false,
  }
  sendApplicationInsightsEvent(AI_PRESENTATION_LOADED_KEY, {
    sections,
    title: displayTitle,
    presentationId: id,
    totalOfSections: sections.length,
    totalOfSlides: sections.reduce(
      (acc, cur) => acc + cur.fields.slides.length,
      0,
    ),
    theme,
  })
  return initialPlayerState
}

function toggleSession(state, { isActive, startDate }) {
  return {
    ...state,
    session: {
      ...state.session,
      startDate,
      isActive,
    },
  }
}

export function addSections(state, { sections }) {
  const isSample = isSampleMode()
  const isCurriculumPreview = isCurriculumPreviewMode()
  const isHighSchoolPreview = isHighSchoolPreviewMode()

  const clonedState = {
    ...state,
    modes: {
      isSampleMode: isSample,
      isFamilyMode: isFamilyMode(),
      isCurriculumPreviewMode: isCurriculumPreview,
      isHighSchoolPreviewMode: isHighSchoolPreview,
      isPrepMode: isPrepMode(),
    },
  }

  if (
    isSample ||
    isPrepMode() ||
    isFamilyMode() ||
    isCurriculumPreview ||
    isHighSchoolPreview
  ) {
    if (!Array.isArray(sections)) {
      return state
    }

    return {
      ...state,
      sections,
      isPrevSlide: false,
      isNextSlide:
        sections.length > 1 ||
        (sections.length === 1 && sections[0].fields.slides.length > 1),
      modes: { ...clonedState.modes },
    }
  }

  const currentLocale = getLocaleFromSession()
  const fields = {
    ...DONE_SECTION_SLIDE,
    ...DONE_SECTION_SLIDE[currentLocale],
  }
  sections.push({ fields: { ...fields } })

  if (!Array.isArray(sections)) {
    return state
  }

  return {
    ...state,
    sections,
    isPrevSlide: false,
    isNextSlide:
      sections.length > 1 ||
      (sections.length === 1 && sections[0].fields.slides.length > 1),
  }
}

function changeTheme(state, { themeName }) {
  return {
    ...state,
    theme: themes[themeName],
  }
}

function addTheme(state, { themeName }) {
  return {
    ...state,
    theme: { ...themes.generic, grade: themes[themeName] },
  }
}

function togglePrepMode(state, { isPrepMode }) {
  return {
    ...state,
    isPrepMode,
  }
}

function setFamilyMode(state, { isFamilyMode }) {
  return {
    ...state,
    isFamilyMode,
  }
}

function setCurriculumPreviewMode(state, { isCurriculumPreviewMode }) {
  return {
    ...state,
    isCurriculumPreviewMode,
  }
}

function setHighSchoolPreviewMode(state, { isHighSchoolPreviewMode }) {
  return {
    ...state,
    isHighSchoolPreviewMode,
  }
}

function setHighSchoolMode(state, { isHighSchoolMode }) {
  return {
    ...state,
    isHighSchoolMode,
  }
}

function setVtts(state, { entriesVtts }) {
  return {
    ...state,
    vtts: { ...entriesVtts },
  }
}

export function setMultimediaStatus(state, { multimediaStatus }) {
  return {
    ...state,
    multimediaStatus,
  }
}

function toggleFullScreen(state, { isFullScreen }) {
  return {
    ...state,
    isFullScreen,
  }
}

export function toggleIdle(state, { isIdle }) {
  return {
    ...state,
    isIdle,
  }
}

function toggleModal(state, { modalContent }) {
  if (modalContent === undefined) {
    modalContent = null
    sendApplicationInsightsEvent(AI_PRESENTATION_MODAL_OPENED)
  }

  return {
    ...state,
    modal: modalContent,
  }
}

function getCurrentSlideData(state, sectionIndex, slideIndex) {
  const { sections, vtts } = state || []
  const { fields = [] } = sections[sectionIndex] || []
  const { slides = [] } = fields || []
  const slide = slides[slideIndex] || {}
  const entryId = slide?.sys?.id || null
  // LEARN-13214: sometimes the entryId of the vtts does not match the slide id
  const vttFiles = vtts[entryId] || Object.values(vtts)[0] || null

  const slideData = {
    ...slide,
    metadata: {
      ...slide.metadata,
      sectionIndex,
      slideIndex,
    },
    fields: {
      ...slide.fields,
      vtts: vttFiles || {},
    },
  }
  return slideData
}

//transform locales in the format enUs to en-US
export function toCorrectLocalesFormat(locales) {
  return locales.map(locale => {
    if (locale.includes('-')) {
      return locale
    }
    const rename = locale
      .split(/(?=[A-Z]{1})/)
      .join('-')
      .replace(/[a-z]$/, l => l.toUpperCase())

    return rename
  })
}

export function getAvailableLocales(obj) {
  const asArray = Object.entries(obj)
  const blackList = ['internalTitle', 'boundary']
  const filtered = asArray.filter(
    ([key, value]) => !blackList.includes(key) && value === true,
  )
  const availableLocales = Object.keys(Object.fromEntries(filtered))
  return toCorrectLocalesFormat(availableLocales)
}

export function nextSlide(state) {
  let [sectionIndex, slideIndex] = state.currentSlide

  if (
    state.sections[sectionIndex].fields.slides &&
    slideIndex < state.sections[sectionIndex].fields.slides.length - 1
  ) {
    slideIndex++
  } else if (sectionIndex < state.sections.length - 1) {
    sectionIndex++
    slideIndex = 0
  }

  if (
    !isSampleMode() &&
    !isPrepMode() &&
    !isFamilyMode() &&
    !isCurriculumPreviewMode() &&
    !isHighSchoolPreviewMode() &&
    sectionIndex === state.sections.length - 1
  ) {
    return getDoneSectionState(state, { sectionIndex, slideIndex })
  }

  const {
    currentSlide,
    isPrevSlide,
    isNextSlide,
    currentSlideData,
  } = extractSlideInfo(state, sectionIndex, slideIndex)

  sendApplicationInsightsEvent(AI_PRESENTATION_SLIDE_NAVIGATION, {
    currentSlide,
    hasPrevSlide: isPrevSlide,
    hasNextSlide: isNextSlide,
  })

  return {
    ...state,
    currentSlide,
    currentSlideData,
    isPrevSlide,
    isNextSlide,
    modal: null,
  }
}

function prevSlide(state /*, action*/) {
  let [sectionIndex, slideIndex] = state.currentSlide

  if (slideIndex > 0) {
    slideIndex--
  } else if (sectionIndex > 0) {
    sectionIndex--
    slideIndex = state.sections[sectionIndex].fields.slides
      ? state.sections[sectionIndex].fields.slides.length - 1
      : 0
  }

  const {
    currentSlide,
    isPrevSlide,
    isNextSlide,
    currentSlideData,
  } = extractSlideInfo(state, sectionIndex, slideIndex)

  sendApplicationInsightsEvent(AI_PRESENTATION_SLIDE_NAVIGATION, {
    currentSlide,
    hasPrevSlide: isPrevSlide,
    hasNextSlide: isNextSlide,
  })

  return {
    ...state,
    currentSlide,
    currentSlideData,
    isPrevSlide,
    isNextSlide,
    modal: null,
  }
}

function getDoneSlideBackgroundImageTheme(gradeTheme) {
  const url = gradeTheme.backgrounds.surveySlideImage

  return {
    backgroundImage: {
      metadata: {
        tags: [],
      },
      sys: {
        type: 'Asset',
        id: '7bLCbIH76vkxi13aSwYNm7',
        locale: 'en-US',
      },
      fields: {
        title: 'u4g5-titlecard-slide',
        file: {
          url,
          details: {
            size: 1650340,
            image: {
              width: 3840,
              height: 1868,
            },
          },
          fileName: 'u4g5-titlecard-slide.jpg',
          contentType: 'image/jpeg',
        },
      },
    },
  }
}

function gotoDoneSlide(state, { sectionIndex, slideIndex }) {
  const grade = getGradeFromThemeParam()
  const slideData = getCurrentSlideData(state, sectionIndex, slideIndex)

  const hasKinderGarden =
    grade &&
    ['1', '2', '3', '4', '5', 'gradek'].some(gradeNum =>
      grade.includes(gradeNum),
    )

  const currentLocale = getLocaleFromSession()

  const slideContentStrings =
    DONE_SLIDE_CONTENT_STRINGS[
      !grade ? 'default' : hasKinderGarden ? 'KG' : 'MD'
    ][currentLocale || LOCALES.EN_US]

  const [
    modalContent,
    bodySlideText,
    buttonSlideText,
  ] = slideData.fields.layout.fields.column1

  slideData.fields.layout.fields = {
    ...slideData.fields.layout.fields,
    ...getDoneSlideBackgroundImageTheme(state.theme.grade, grade),
    header: slideContentStrings.title,
    column1: [
      modalContent,
      {
        ...bodySlideText,
        fields: {
          ...bodySlideText.fields,
          content: slideContentStrings.body,
        },
      },
      {
        ...buttonSlideText,
        fields: {
          ...buttonSlideText.fields,
          buttonText: slideContentStrings.button,
          url: state.feedbackUrl,
        },
      },
    ],
  }
  const {
    currentSlide,
    isPrevSlide,
    isNextSlide,
    currentSlideData,
  } = extractSlideInfo(state, sectionIndex, slideIndex)
  return {
    ...state,
    currentSlide,
    currentSlideData,
    isPrevSlide,
    isNextSlide,
    sectionIndex,
    isDoneSlide: true,
    modal: null,
  }
}
// eslint-disable-next-line no-unused-vars
const getDoneSectionState = (state, { sectionIndex, slideIndex }) => {
  const doneSection = state.sections[sectionIndex]
  const doneSlide = doneSection?.fields?.slides[slideIndex] || null
  const { layout } = doneSlide?.fields || null
  const { column1 } = layout?.fields || null
  const { hasPortal, hasModalCompletion } = layout?.metadata || false
  const { modalContent } = column1[0]?.fields || null
  modalContent.metadata = {
    ...modalContent.metadata,
    hasPortal,
    hasModalCompletion,
    sectionIndex,
    slideIndex,
  }

  if (!state.isDoneSlide) {
    return toggleModal(state, { modalContent })
  }

  return {
    ...state,
    currentSlide: [sectionIndex, slideIndex],
    isNextSlide: false,
    currentSlideData: getCurrentSlideData(state, sectionIndex, slideIndex),
    ...gotoDoneSlide(state, { sectionIndex, slideIndex }),
  }
}

function gotoSlide(state, { sectionIndex, slideIndex }) {
  if (
    !isSampleMode() &&
    !isPrepMode() &&
    !isFamilyMode() &&
    !isCurriculumPreviewMode() &&
    !isHighSchoolPreviewMode() &&
    sectionIndex === state.sections.length - 1
  ) {
    return getDoneSectionState(state, { sectionIndex, slideIndex })
  }
  if (
    sectionIndex < 0 ||
    slideIndex < 0 ||
    sectionIndex >= state.sections.length
  ) {
    return state
  }
  const {
    currentSlide,
    isPrevSlide,
    isNextSlide,
    currentSlideData,
  } = extractSlideInfo(state, sectionIndex, slideIndex)

  sendApplicationInsightsEvent(AI_PRESENTATION_SLIDE_NAVIGATION, {
    currentSlide,
    hasPrevSlide: isPrevSlide,
    hasNextSlide: isNextSlide,
  })
  return {
    ...state,
    currentSlide,
    currentSlideData,
    isPrevSlide,
    isNextSlide,
    modal: null,
  }
}

function completeSlide(state, { slide }) {
  const completeStatus = {
    ...state.completeStatus,
    [slide]: SLIDE_STATUS.COMPLETED,
  }

  const isDoneSlide =
    !isSampleMode() &&
    !isPrepMode() &&
    !!completeStatus[`${state.sections.length - 1},0`]
  return {
    ...state,
    completeStatus,
    isDoneSlide,
  }
}

function viewedSlide(state, { slide, viewedBool }) {
  const completeStatus = {
    ...state.completeStatus,
    [slide]: viewedBool ? SLIDE_STATUS.VIEWED : SLIDE_STATUS.NOT_VIEWED,
  }
  return {
    ...state,
    completeStatus,
  }
}

function visitButton(state, { buttonId }) {
  const buttonsVisitedStatus = {
    ...state.buttonsVisitedStatus,
    [buttonId]: true,
  }
  return {
    ...state,
    buttonsVisitedStatus,
  }
}

function initializeRatioWrapper(state) {
  return {
    ...state,
    ratioWrapper: {
      ...initialState.ratioWrapper,
    },
  }
}

function setRatioWrapperHeight(state, { height }) {
  return {
    ...state,
    ratioWrapper: {
      ...state.ratioWrapper,
      height,
    },
  }
}

function updateRatioWrapper(state, { scale, height, width }) {
  return {
    ...state,
    ratioWrapper: {
      ...state.ratioWrapper,
      height,
      scale,
      width,
    },
  }
}

function setDeviceResolutionTier(state, resolutionTier) {
  return {
    ...state,
    deviceResolutionTier: resolutionTier,
  }
}

function multichoiceInitialize(
  state,
  {
    interactiveId,
    options,
    isAnswered = false,
    isSubmitted = false,
    score = 0,
    startTime = new Date().toISOString(),
    endTime = null,
    attempts = 0,
  },
) {
  return {
    ...state,
    interactiveStates: {
      ...state.interactiveStates,
      [interactiveId]: {
        ...state.interactiveStates[interactiveId],
        interactiveId,
        isAnswered,
        isSubmitted,
        options,
        score,
        startTime,
        endTime,
        attempts,
      },
    },
  }
}

function multichoiceUpdate(
  state,
  {
    interactiveId,
    isAnswered,
    isSubmitted,
    options,
    score,
    startTime,
    endTime,
    attempts,
  },
) {
  return {
    ...state,
    interactiveStates: {
      ...state.interactiveStates,
      [interactiveId]: {
        ...state.interactiveStates[interactiveId],
        options,
        isAnswered,
        isSubmitted,
        score,
        startTime,
        endTime,
        attempts,
      },
    },
  }
}

function multichoiceSubmit(state, { interactiveId, score }) {
  const { attempts } = state.interactiveStates[interactiveId]
  const isAnswered = true
  const isSubmitted = true
  const endTime = new Date().toISOString()
  return {
    ...state,
    interactiveStates: {
      ...state.interactiveStates,
      [interactiveId]: {
        ...state.interactiveStates[interactiveId],
        isAnswered,
        isSubmitted,
        score,
        endTime,
        attempts: attempts + 1,
      },
    },
  }
}

function dragQuestionInitialize(
  state,
  {
    interactiveId,
    draggables,
    dropZones,
    isSubmitted,
    startTime = new Date().toISOString(),
    endTime = null,
    attempts = 0,
    showErrorMessage = false,
  },
) {
  return {
    ...state,
    interactiveStates: {
      ...state.interactiveStates,
      [interactiveId]: {
        ...state.interactiveStates[interactiveId],
        draggables,
        dropZones,
        isSubmitted,
        score: null,
        startTime,
        endTime,
        attempts,
        showErrorMessage,
      },
    },
  }
}

function dragQuestionUpdate(
  state,
  { interactiveId, draggables, isSubmitted, showErrorMessage, dropZones },
) {
  const updatedDropZones =
    Array.isArray(dropZones) && dropZones.length > 0
      ? dropZones
      : state.interactiveStates[interactiveId]['dropZones']

  return {
    ...state,
    interactiveStates: {
      ...state.interactiveStates,
      [interactiveId]: {
        ...state.interactiveStates[interactiveId],
        draggables,
        dropZones: updatedDropZones,
        isSubmitted,
        showErrorMessage,
      },
    },
  }
}

function dragQuestionSubmit(state, { interactiveId, score }) {
  const attempts = state.interactiveStates[interactiveId].attempts
  const endTime = new Date().toISOString()
  return {
    ...state,
    interactiveStates: {
      ...state.interactiveStates,
      [interactiveId]: {
        ...state.interactiveStates[interactiveId],
        isSubmitted: true,
        score,
        showErrorMessage: false,
        endTime,
        attempts: attempts + 1,
      },
    },
  }
}

function dragLabelInitialize(
  state,
  {
    interactiveId,
    draggables,
    dropZones,
    buttons,
    helperText,
    startTime = new Date().toISOString(),
    endTime = null,
    attempts = 0,
    score = 0,
  },
) {
  return {
    ...state,
    interactiveStates: {
      ...state.interactiveStates,
      [interactiveId]: {
        ...state.interactiveStates[interactiveId],
        buttons,
        helperText,
        draggables,
        dropZones,
        startTime,
        endTime,
        attempts,
        score,
      },
    },
  }
}

function dragLabelUpdate(
  state,
  { interactiveId, draggables, dropZones, buttons, helperText },
) {
  const updatedDropZones =
    Array.isArray(dropZones) && dropZones.length > 0
      ? dropZones
      : state.interactiveStates[interactiveId]['dropZones']

  return {
    ...state,
    interactiveStates: {
      ...state.interactiveStates,
      [interactiveId]: {
        ...state.interactiveStates[interactiveId],
        buttons,
        helperText,
        draggables,
        dropZones: updatedDropZones,
        showFrameDropZones: false,
      },
    },
  }
}

function dragLabelSubmit(state, { interactiveId, dropZones, buttons, score }) {
  const endTime = new Date().toISOString()
  const interactiveState = state.interactiveStates[interactiveId]
  const updatedDropZones =
    Array.isArray(dropZones) && dropZones.length > 0
      ? dropZones
      : interactiveState.dropZones

  return {
    ...state,
    interactiveStates: {
      ...state.interactiveStates,
      [interactiveId]: {
        ...interactiveState,
        buttons,
        helperText: null,
        dropZones: updatedDropZones,
        showFrameDropZones: false,
        attempts: interactiveState.attempts + 1,
        endTime,
        score,
      },
    },
  }
}

function dragLabelShowFrameDropZones(
  state,
  { interactiveId, showFrameDropZones },
) {
  return {
    ...state,
    interactiveStates: {
      ...state.interactiveStates,
      [interactiveId]: {
        ...state.interactiveStates[interactiveId],
        showFrameDropZones,
      },
    },
  }
}

function dragWordInitialize(state, { interactiveId, newState }) {
  const {
    buttons,
    draggables,
    isErrorVisible,
    questions,
    startTime = new Date().toISOString(),
    attempts = 0,
    endTime = null,
  } = newState
  return {
    ...state,
    interactiveStates: {
      ...state.interactiveStates,
      [interactiveId]: {
        ...state.interactiveStates[interactiveId],
        buttons,
        draggables,
        isErrorVisible,
        questions,
        startTime,
        attempts,
        endTime,
      },
    },
  }
}

function dragWordUpdate(state, { interactiveId, newState }) {
  return {
    ...state,
    interactiveStates: {
      ...state.interactiveStates,
      [interactiveId]: {
        ...state.interactiveStates[interactiveId],
        ...newState,
      },
    },
  }
}

function dragWordSubmit(state, { interactiveId, newState }) {
  newState.endTime = new Date().toISOString()
  newState.isErrorVisible = false
  return {
    ...state,
    interactiveStates: {
      ...state.interactiveStates,
      [interactiveId]: {
        ...state.interactiveStates[interactiveId],
        ...newState,
      },
    },
  }
}

function imageHotspotsInitialize(state, { interactiveId, targets, score }) {
  return {
    ...state,
    interactiveStates: {
      ...state.interactiveStates,
      [interactiveId]: {
        ...state.interactiveStates[interactiveId],
        targets,
        score,
      },
    },
  }
}

function imageHotspotsTargetClick(state, { interactiveId, targetIndex }) {
  const { targets } = state.interactiveStates[interactiveId] || {}
  return {
    ...state,
    interactiveStates: {
      ...state.interactiveStates,
      [interactiveId]: {
        ...state.interactiveStates[interactiveId],
        targets: {
          ...targets,
          [targetIndex]: true,
        },
      },
    },
  }
}

function imageJuxtapositionInitialize(state, { interactiveId, position }) {
  return {
    ...state,
    interactiveStates: {
      ...state.interactiveStates,
      [interactiveId]: {
        ...state.interactiveStates[interactiveId],
        position,
      },
    },
  }
}

function imageJuxtapositionUpdate(
  state,
  { interactiveId, position, maxThresholdReached },
) {
  return {
    ...state,
    interactiveStates: {
      ...state.interactiveStates,
      [interactiveId]: {
        ...state.interactiveStates[interactiveId],
        maxThresholdReached,
        position,
      },
    },
  }
}

function dialogCardInitialize(state, { interactiveId, isFlipped, isComplete }) {
  return {
    ...state,
    interactiveStates: {
      ...state.interactiveStates,
      [interactiveId]: {
        ...state.interactiveStates[interactiveId],
        isFlipped,
        isComplete,
      },
    },
  }
}

function dialogCardFlip(state, { interactiveId, isFlipped }) {
  return {
    ...state,
    interactiveStates: {
      ...state.interactiveStates,
      [interactiveId]: {
        ...state.interactiveStates[interactiveId],
        isFlipped,
        isComplete: true,
      },
    },
  }
}

function setHotspotRef(state, { reference }) {
  return {
    ...state,
    hotSpotRef: reference,
  }
}

function createReducer(initialState, handlers) {
  return function reducer(state = initialState, action) {
    if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
      return handlers[action.type](state, action)
    }

    return state
  }
}

function extractSlideInfo(state, sectionIndex, slideIndex) {
  return {
    currentSlide: [sectionIndex, slideIndex],
    isPrevSlide: sectionIndex > 0 || slideIndex > 0,
    isNextSlide:
      sectionIndex < state.sections.length - 1 ||
      slideIndex < state.sections[sectionIndex].fields.slides.length - 1,
    currentSlideData: getCurrentSlideData(state, sectionIndex, slideIndex),
  }
}

const playerReducer = createReducer(initialState, {
  ADD_SECTIONS: addSections,
  ADD_THEME: addTheme,
  CHANGE_THEME: changeTheme,
  COMPLETE_SLIDE: completeSlide,
  DIALOG_CARD_FLIP: dialogCardFlip,
  DIALOG_CARD_INITIALIZE: dialogCardInitialize,
  DRAG_LABEL_INITIALIZE: dragLabelInitialize,
  DRAG_LABEL_SHOW_FRAME_DROP_ZONES: dragLabelShowFrameDropZones,
  DRAG_LABEL_SUBMIT: dragLabelSubmit,
  DRAG_LABEL_UPDATE: dragLabelUpdate,
  DRAG_QUESTION_INITIALIZE: dragQuestionInitialize,
  DRAG_QUESTION_SUBMIT: dragQuestionSubmit,
  DRAG_QUESTION_UPDATE_SELECTION: dragQuestionUpdate,
  DRAG_WORD_INITIALIZE: dragWordInitialize,
  DRAG_WORD_SUBMIT: dragWordSubmit,
  DRAG_WORD_UPDATE: dragWordUpdate,
  GOTO_SLIDE: gotoSlide,
  GOTO_DONE_SLIDE: gotoDoneSlide,
  IMAGE_HOTSPOTS_INITIALIZE: imageHotspotsInitialize,
  IMAGE_HOTSPOTS_TARGET_CLICK: imageHotspotsTargetClick,
  IMAGE_JUXTAPOSITION_INITIALIZE: imageJuxtapositionInitialize,
  IMAGE_JUXTAPOSITION_UPDATE: imageJuxtapositionUpdate,
  INITIALIZE_PLAYER: initializePlayer,
  MULTICHOICE_INITIALIZE: multichoiceInitialize,
  MULTICHOICE_SUBMIT: multichoiceSubmit,
  MULTICHOICE_UPDATE: multichoiceUpdate,
  NEXT_SLIDE: nextSlide,
  PREV_SLIDE: prevSlide,
  RATIO_WRAPPER_INITIALIZE: initializeRatioWrapper,
  RATIO_WRAPPER_SET_HEIGHT: setRatioWrapperHeight,
  RATIO_WRAPPER_UPDATE: updateRatioWrapper,
  SET_DEVICE_RESOLUTION_TIER: setDeviceResolutionTier,
  SET_FAMILY_MODE: setFamilyMode,
  SET_CURRICULUM_PREVIEW_MODE: setCurriculumPreviewMode,
  SET_HIGH_SCHOOL_PREVIEW_MODE: setHighSchoolPreviewMode,
  SET_HIGH_SCHOOL_MODE: setHighSchoolMode,
  TOGGLE_FULLSCREEN: toggleFullScreen,
  TOGGLE_IDLE: toggleIdle,
  TOGGLE_MODAL: toggleModal,
  TOGGLE_PREP_MODE: togglePrepMode,
  TOGGLE_SESSION: toggleSession,
  VIEWED_SLIDE: viewedSlide,
  VISIT_BUTTON: visitButton,
  SET_MULTIMEDIA_STATUS: setMultimediaStatus,
  SET_VTTS: setVtts,
  HOTSPOT_REF: setHotspotRef,
})

export default playerReducer
