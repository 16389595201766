export default class Auth0Manager {
  static auth0Manager
  static setAuth0Manager(auth0) {
    this.auth0Manager = auth0
  }
  static getAccessToken() {
    return this.auth0Manager?.getAccessTokenSilently()
  }

  static getAuth0Instance() {
    return this.auth0Manager
  }
}
