export class TranscriptLine {
  get shortCueTime() {
    const pattern = /^((\d+):)?(\d+):(\d+).(\d+)$/
    const match = pattern.exec(this.cue)
    if (match) {
      const hour = match[2]
      const minute = match[3]
      const second = match[4]
      const millisecond = match[5]
      const showHours = hour !== '00'
      const showMillisecond = false
      return (
        (showHours ? hour + ':' : '') +
        `${minute}:${second}` +
        (showMillisecond ? '.' + millisecond : '')
      )
    }
    return this.cue
  }

  constructor(cue, body) {
    this.cue = cue
    this.body = body
  }

  toString() {
    return `[${this.cue}] ${this.body}`
  }

  toHtmlString() {
    return `
    <div class="transcript-line">
        <span class="transcript-time-cue-wrapper">
            [<span class="transcript-time-cue">${this.shortCueTime}</span>]
        </span>
        <span class="transcript-line-body">${this.body}</span>
    </div>`
  }
}

class TranscriptBlock {
  constructor(heading, lines) {
    this.heading = heading
    this.lines = lines
  }

  push(line) {
    this.lines.push(line)
  }

  toString() {
    return `(${this.heading})\n${this.lines.map(
      line => line.toString() + '\n',
    )}`
  }

  toHtmlString() {
    const heading = `<header class="transcript-heading">${this.heading.replace(
      /^([A-Z])([A-Z]+)$/,
      (_, firstLetter, restOfWord) =>
        `${firstLetter}${restOfWord.toLowerCase()}`,
    )}</header>`

    return `
        <div class="transcript-block ${this.heading.toLowerCase()}">
            ${heading}
            ${this.lines.map(line => line.toHtmlString()).join('\n')}
        </div>`
  }
}

class Transcript {
  constructor(transcriptBlocks = []) {
    this.transcriptBlocks = transcriptBlocks
  }

  toString() {
    return this.transcriptBlocks.map(block => block.toString() + '').join('\n')
  }

  toHtmlString() {
    return this.transcriptBlocks.map(block => block.toHtmlString()).join('\n')
  }

  push(block) {
    this.transcriptBlocks.push(block)
  }
}

export const htmlifyTranscript = rawTranscript => {
  const lines = rawTranscript
    .split(/\n|(\r\n)/)
    .map(line => line?.trim())
    .filter(line => !!line)
  const transcript = new Transcript()

  let block = TranscriptBlock | null

  for (const line of lines) {
    const headingMatches = line.match(/^\(([A-Z]+)\)$/)

    if (headingMatches) {
      if (block) {
        transcript.push(block)
      }
      block = new TranscriptBlock(headingMatches[1], [])
      continue
    }

    if (!line?.trim()) {
      continue
    }

    if (!block) {
      block = new TranscriptBlock('', [])
    }

    const lineMatches = line.match(/^\[(.*?)\]\s+(.*?)$/)

    if (lineMatches) {
      block.push(new TranscriptLine(lineMatches[1], lineMatches[2]))
      continue
    }

    throw new Error(`Unexpected transcript line: ${line}`)
  }

  if (block) {
    transcript.push(block)
  }
  return transcript.toHtmlString()
}
