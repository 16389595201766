import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const DateInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  border: 2px solid ${themeGet('colors.gray500')};
  color: ${themeGet('colors.darkGray')};
  height: 43px;
  width: 100%;
  border-color: ${props =>
    props.hasError
      ? themeGet('colors.errorRed')
      : props.isOpen
      ? themeGet('colors.blueGray')
      : themeGet('colors.gray500')};
  cursor: default;

  button {
    padding-top: 9px;
  }

  .react-date-picker {
    margin-right: 4px;
  }

  .react-date-picker__inputGroup {
    display: none;
  }

  .react-date-picker__wrapper {
    border: none;
    display: block;
  }

  .react-date-picker__calendar .react-calendar {
    border-radius: 5px;
  }

  .react-calendar__tile--active {
    font-family: ${themeGet('font.molde.regular')};
    background: ${themeGet('colors.darkBlue')};
    color: ${themeGet('colors.white')};
  }

  .react-calendar__tile--active:enabled:focus,
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus,
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: ${themeGet('colors.blueGray')};
    color: ${themeGet('colors.white')};
  }

  .react-calendar__month-view__days__day--weekend {
    color: ${themeGet('colors.deleteRed')};
  }

  .react-calendar__tile--now {
    background: ${themeGet('colors.paleGray')};
  }
`

export const DateText = styled.span`
  padding-left: 16px;
`
