import styled from 'styled-components'
import { themeGet } from 'styled-system'
import BodyCopy from '../BodyCopy'

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: ${themeGet('spacing.green4_0_25rem')} 0;
  box-sizing: border-box;
  border-spacing: 0;
  margin-top: ${themeGet('spacing.blue16_1rem')}
  text-align: left;
  width: 100%;
  border-radius: 10px;
  border: 1px solid ${themeGet('colors.paleGray')};
  border-radius: 10px;
  tbody tr td {
    border-bottom: 2px solid ${themeGet('colors.paleGray')};
  }
  tbody tr:last-child td {
    border-width: 1px;
  }
  tbody tr:last-child td:first-child {
    border-radius: 0 0 0 10px;
  }
  tbody tr:last-child td:last-child {
    border-radius: 0 0 10px 0;
  }
`

export const TableHead = styled.thead`
  height: 49px;
  width: 100%;
`

export const EmptyTableRow = styled.td`
  text-align: center;
  padding: 4rem 0 6rem;
`

export const EmptyTablePrimaryMessage = styled(BodyCopy)`
  color: ${themeGet('colors.darkBlue')};
  font-family: ${themeGet('fontFamilySemibold')};
  font-size: 1rem;
  text-align: center;
  padding-bottom: 0.5rem;
`

export const EmptyTableSecondaryMessage = styled(BodyCopy)``
