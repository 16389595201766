import React from 'react'
import PropTypes from 'prop-types'

function TabButtonFilled({ label, onClick }) {
  return (
    <svg
      aria-label={label}
      height="41"
      role="tab"
      viewBox="0 0 159 41"
      width="159"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          d="M107 2c.187.002.374.005.559.01H108c.404 0 .803.024 1.195.07 11.73.778 18.839 6.949 21.327 18.515C133.162 32.865 142.41 39 158.269 39H118l-75 .01V39H1c15.858 0 25.107-6.135 27.747-18.405C31.387 8.325 39.227 2.126 52.27 2l-.001.036c.242-.017.486-.026.732-.026l54-.001z"
          fill="currentColor"
          onClick={onClick}
        />
      </g>
      <text
        dominantBaseline="middle"
        onClick={onClick}
        textAnchor="middle"
        x="50%"
        y="53%"
      >
        {label}
      </text>
    </svg>
  )
}

TabButtonFilled.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
}

export default TabButtonFilled
