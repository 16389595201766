/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'
import PropTypes from 'prop-types'

const CaretIcon = ({ color = 'currentColor', rotation = 90 }) => (
  <svg height="13" viewBox="0 0 9 13" width="13">
    <path
      d="M4.263 3.098a1 1 0 011.385-.086l.089.086 5 5.452a1 1 0 01-1.383 1.439l-.091-.087-4.264-4.649L.737 9.902a1 1 0 01-1.315.14l-.098-.08a1 1 0 01-.14-1.315l.079-.097 5-5.452z"
      fill={color}
      fillRule="nonzero"
      stroke="none"
      strokeWidth="1"
      transform={`rotate(${rotation} 5 6.5)`}
    />
  </svg>
)

CaretIcon.propTypes = {
  color: PropTypes.string,
  rotation: PropTypes.number,
}

export default CaretIcon
