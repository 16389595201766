import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import DragLabelDraggable from '../DragLabelDraggable'
import DragLabelDropZone from '../DragLabelDropZone'

//A drag label node consists of a dropzone and a draggable.

const DragLabelNodeComponent = ({
  canDrop,
  correct,
  draggableId,
  draggableIndex,
  dropZoneBorder,
  dropZoneDataTestId,
  dropZoneId,
  graded,
  isLong,
  label,
  left,
  onDragEnd,
  onDragStart,
  onDrop,
  questions,
  showDraggable,
  showDropZone,
  top,
}) => {
  const draggableComponent = (
    <DragLabelDraggable
      id={draggableId}
      index={draggableIndex}
      label={label}
      isLong={isLong}
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      isVisible={showDraggable}
      left={left}
      top={top}
    />
  )
  const dropZoneComponent = (
    <DragLabelDropZone
      correct={correct}
      border={dropZoneBorder}
      canDrop={canDrop}
      dataTestId={dropZoneDataTestId}
      graded={graded}
      id={dropZoneId}
      isVisible={showDropZone}
      left={left}
      isLong={isLong}
      onDrop={onDrop}
      questions={questions}
      top={top}
    />
  )

  return (
    <Fragment>
      {dropZoneComponent}
      {draggableComponent}
    </Fragment>
  )
}

DragLabelNodeComponent.propTypes = {
  canDrop: PropTypes.bool,
  correct: PropTypes.bool,
  draggableId: PropTypes.string,
  draggableIndex: PropTypes.number,
  dropZoneBorder: PropTypes.bool,
  dropZoneDataTestId: PropTypes.string,
  dropZoneId: PropTypes.string,
  graded: PropTypes.bool,
  isLong: PropTypes.bool,
  label: PropTypes.string,
  left: PropTypes.number,
  onDragEnd: PropTypes.func,
  onDragStart: PropTypes.func,
  onDrop: PropTypes.func,
  questions: PropTypes.object,
  showDraggable: PropTypes.bool,
  showDropZone: PropTypes.bool,
  top: PropTypes.number,
}

DragLabelNodeComponent.defaultProps = {
  canDrop: true,
  dropZoneBorder: true,
  onDragEnd() {},
  onDragStart() {},
  onDrop() {},
  showDraggable: true,
  showDropZone: true,
}

export default DragLabelNodeComponent
