import { all, put, takeEvery } from 'redux-saga/effects'

import { AppWrapperConstants } from '../../../components/AppWrapper'
import Ajax from '../../../lib/Ajax'
import rehydrateRedux from '../../../lib/APIConnector/rehydrateRedux'
import { CONTENT_FUNCTION_URL } from '../../../lib/config'
import { updateCmiLocale } from '../actions/cmi'
import { addTheme, initializePlayer, togglePrepMode } from '../actions/player'
import { setPresentation } from '../actions/presentation'
import { appStore } from '../store'

export function* loadPresentationJsonSaga(action) {
  const {
    cache,
    contentfulId,
    isPrepMode,
    locale,
    preview,
    setError,
    setInitialStore,
    themeId,
    token,
  } = action

  const {
    CACHE,
    ENVIRONMENT,
    FETCH_PRESENTATION_ERROR,
    FETCH_PRESENTATION_ERROR_UNPUBLISHED,
    FORMAT,
    INCLUDE,
    LOCALE,
  } = AppWrapperConstants
  const ajaxUrl = new URL(CONTENT_FUNCTION_URL)

  contentfulId && ajaxUrl.searchParams.append('entryId', contentfulId)
  // This is only needed in preview mode
  preview && ajaxUrl.searchParams.append(ENVIRONMENT, 'preview.contentful.com')
  // Tell the api to not format our data
  ajaxUrl.searchParams.append(FORMAT, false)
  // Get all the levels of data
  ajaxUrl.searchParams.append(INCLUDE, 10)
  if (cache) {
    ajaxUrl.searchParams.append(CACHE, cache)
  }
  ajaxUrl.searchParams.append(LOCALE, locale)

  const url = ajaxUrl.toString()
  let presentation
  try {
    presentation = yield Ajax.get(url, { token })
  } catch (error) {
    const errorMessage = !preview
      ? FETCH_PRESENTATION_ERROR_UNPUBLISHED
      : FETCH_PRESENTATION_ERROR
    console.error(errorMessage)
  }

  yield put(addTheme(themeId))
  yield put(updateCmiLocale(locale))
  yield put(
    setPresentation(
      presentation,
      isPrepMode,
      setError,
      setInitialStore,
      locale,
    ),
  )
}

export function* setPresentationSaga(action) {
  const {
    isPrepMode,
    locale,
    presentationJson,
    setError,
    setInitialStore,
  } = action

  const { data: presentation, status } = presentationJson || {}
  const didFail = status !== AppWrapperConstants.SUCCESS

  if (didFail) {
    console.error(AppWrapperConstants.LOAD_PRESENTATION_ERROR)

    setError && setError(presentationJson)
    return
  }

  yield put(togglePrepMode(isPrepMode))
  yield put(initializePlayer(presentation))
  yield rehydrateRedux(appStore, locale)
  setInitialStore && setInitialStore(appStore)
}

export default function* presentationSaga() {
  yield all([
    takeEvery('GET_PRESENTATION', loadPresentationJsonSaga),
    takeEvery('SET_PRESENTATION', setPresentationSaga),
  ])
}
