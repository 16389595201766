import React, { useEffect, useRef } from 'react'

import PropTypes from 'prop-types'
import { getConstantByLocale, getLocaleFromSession } from 'utils/locale'

import {
  CANCEL_TEXT,
  CONFIRM_TEXT,
  DEFAULT_MESSAGE,
  TEXT_DATA_TEST_ID,
  TITLE,
} from './constants'
import { Button, Container, ContentContainer, Row, Title } from './style'

const currentLocale = getLocaleFromSession()
const getDefaultMessage = getConstantByLocale(DEFAULT_MESSAGE, currentLocale)
const getTitle = getConstantByLocale(TITLE, currentLocale)

const ConfirmationModal = ({
  closeModal,
  handleConfirm,
  goToDoneSlide,
  sectionIndex,
  slideIndex,
  hasCompletionStatus,
}) => {
  const defaultFocusRef = useRef(null)
  useEffect(() => defaultFocusRef.current && defaultFocusRef.current?.focus(), [
    defaultFocusRef.current,
  ])
  useEffect(() => {
    if (hasCompletionStatus) {
      goToDoneSlide(sectionIndex, slideIndex)
    }
  }, [hasCompletionStatus])
  return (
    <Container>
      <ContentContainer>
        <Title>{getTitle}</Title>
        <Row data-testid={TEXT_DATA_TEST_ID}>
          <Button ref={defaultFocusRef} variant="blank" onClick={closeModal}>
            {getConstantByLocale(CANCEL_TEXT, currentLocale)}
          </Button>
          <Button
            variant="filled"
            onClick={() => handleConfirm()}
            data-testid="mark-done-button"
          >
            {getConstantByLocale(CONFIRM_TEXT, currentLocale)}
          </Button>
        </Row>
      </ContentContainer>
    </Container>
  )
}

ConfirmationModal.propTypes = {
  feedbackUrl: PropTypes.string,
  message: PropTypes.string,
  closeModal: PropTypes.func,
  handleConfirm: PropTypes.func,
  sectionIndex: PropTypes.number,
  slideIndex: PropTypes.number,
  hasCompletionStatus: PropTypes.bool,
  goToDoneSlide: PropTypes.func,
}

ConfirmationModal.defaultProps = {
  feedbackUrl: '',
  message: getDefaultMessage,
  closeModal: () => {},
  handleConfirm: () => {},
  hasCompletionStatus: () => {},
  goToDoneSlide: () => {},
}

export default ConfirmationModal
