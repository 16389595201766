import React, { useState } from 'react'
import PropTypes from 'prop-types'
import FormField from '../FormField'
import CharacterCounter from '../CharacterCounter'
import { TextInputContainer, TextInputStyled } from './component.styles'

const FormInputText = ({
  autofocus,
  dataTestId,
  customCharValue,
  customMaxLength,
  disabled,
  inputErrors,
  label,
  maxLength,
  name,
  onChange,
  placeholder,
  value,
  className,
}) => {
  const [val, setVal] = useState('')
  value = value || val

  return (
    <div className={className}>
      <FormField
        dataTestId={dataTestId}
        inputErrors={inputErrors}
        label={label}
      >
        <TextInputContainer>
          <TextInputStyled
            autoFocus={autofocus}
            dataTestId={`input-${dataTestId}`}
            disabled={disabled}
            id={dataTestId}
            maxLength={customMaxLength || maxLength}
            name={name}
            onChange={onChange || (e => setVal(e.target.value))}
            placeholder={placeholder}
            type="text"
            value={value}
          />
          {maxLength && (
            <CharacterCounter
              charValue={customCharValue || value}
              dataTestId={dataTestId}
              label={dataTestId}
              maxLength={maxLength}
            />
          )}
        </TextInputContainer>
      </FormField>
    </div>
  )
}

FormInputText.propTypes = {
  autofocus: PropTypes.bool,
  className: PropTypes.string,
  customCharValue: PropTypes.string,
  customMaxLength: PropTypes.number,
  dataTestId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  inputErrors: PropTypes.object,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
}

FormInputText.defaultProps = {
  label: 'Enter text below',
  disabled: false,
}

export default FormInputText
