import styled from 'styled-components'

export const Container = styled.div`
  width: 74%;
  height: 40%;
  position: fixed;
  margin: 11rem auto;
  z-index: 1;
  text-align: center;
  letter-spacing: 0.05rem;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 0.6rem;
  ${({ theme }) => theme.colorVars.hsPreviewBanner};
`

export const TelescopeIconWrapper = styled.div`
  margin-top: 7.125rem;
`

export const Title = styled.h1`
  font-family: Molde-Semibold;
  font-size: 1.438rem;
  line-height: 1.75rem;
  font-weight: 700;
`
