import styled from 'styled-components'

import { colors } from '../../styles/styleGuide'

export const CloseIcon = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
  width: 100%;
  border: none;
  background: none;
  opacity: ${props => (props.isCloseButtonWithBorder ? 1 : 0.38)};
  padding: 0 0.9rem;
  transition: opacity 0.2s ease;

  img {
    height: 100%;
  }
  :focus {
    outline: 3px groove ${colors.lightBlue};
  }

  :hover {
    opacity: ${props => (props.isCloseButtonWithBorder ? 0.38 : 1)};
    img {
      opacity: ${props => (props.isCloseButtonWithBorder ? 0.38 : 1)};
    }
  }
`
