import styled from 'styled-components'
import ReactModal from 'react-modal'
import { themeGet } from 'styled-system'
import CloseButton from '../../CloseButton'

export const ModalContentWrapper = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${themeGet('breakpoints.sizes.large')};
`

export const StyledReactModal = styled(ReactModal).attrs(
  ({ isModalOpen, modalClose }) => ({
    isOpen: isModalOpen,
    onRequestClose: modalClose,
    overlayClassName: 'Overlay',
  }),
)`
  ${ModalContentWrapper} {
    background-color: ${themeGet('colors.white')};
    border-radius: 0.75rem;
    display: flex;
    left: 50%;
    line-height: 1.5rem;
    width: 29.6875rem;
    outline: none;
    position: fixed;
    transform: translate(-50%, -50%);
    top: 50%;
    -webkit-font-smoothing: antialiased;

    ${themeGet('breakpoints.tablet')} {
      max-width: calc(100% - 1.25rem);
    }
  }

  ${CloseButton} {
    color: ${themeGet('colors.gray600')};
    padding: 0.5rem;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
  }
`
