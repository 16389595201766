import React from 'react'
import PropTypes from 'prop-types'
import AutoTableArrow from '../AutoTableArrow'
import {
  AutoTableHeaderContent,
  AutoTableHeaderSpan,
  AutoTableHeaderTh,
  AutoTableHeaderTr,
  AutoTableHeaderSort,
  HeaderSortContainer,
} from './component.styles'

const AutoTableHeader = ({
  columns,
  id,
  onToggleSort,
  resolveClassName,
  sort,
  sortData,
}) => {
  return (
    <AutoTableHeaderTr>
      {columns.map((column, colIndex) => {
        const resolvedClassName = resolveClassName(
          column.headerClassName,
          column,
        )
        let isSorted = false
        const canSort = sort && column.sort !== false && column.title
        if (canSort) {
          isSorted = sortData.columnTitle === column.title
        }

        return (
          <AutoTableHeaderTh
            className={resolvedClassName}
            key={`${id}_th_${colIndex}`}
          >
            <AutoTableHeaderContent active={isSorted} isSortable={sort}>
              {sort && column.sort !== false && (
                <HeaderSortContainer>
                  <AutoTableHeaderSort
                    active={isSorted}
                    data-testid={`sort-${column.title.replace(' ', '-')}`}
                    onClick={() => {
                      if (canSort) onToggleSort(column.title)
                    }}
                    onKeyDown={e => {
                      if (canSort && e.key === 'Enter')
                        onToggleSort(column.title)
                    }}
                    tabIndex={canSort ? 0 : null}
                  >
                    {sort && column.sort !== false && (
                      <AutoTableHeaderSpan>
                        <AutoTableArrow
                          active={isSorted}
                          direction={
                            isSorted
                              ? sortData.direction === 'asc'
                                ? 'up'
                                : 'down'
                              : 'up'
                          }
                        />
                      </AutoTableHeaderSpan>
                    )}
                  </AutoTableHeaderSort>
                </HeaderSortContainer>
              )}
              {column.component || column.title}
            </AutoTableHeaderContent>
          </AutoTableHeaderTh>
        )
      })}
    </AutoTableHeaderTr>
  )
}

AutoTableHeader.propTypes = {
  columns: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  onToggleSort: PropTypes.func.isRequired,
  resolveClassName: PropTypes.func.isRequired,
  sort: PropTypes.bool.isRequired,
  sortData: PropTypes.object.isRequired,
}

export default AutoTableHeader
