import React from 'react'

import { DATA_TEST_ID_ENTER_FULL_SCREEN_ICON } from './constants'
import { Svg } from './style'

export const enterFullScreenSvg = () => {
  return (
    <Svg data-testid={DATA_TEST_ID_ENTER_FULL_SCREEN_ICON} viewBox="0 0 17 16">
      <defs>
        <polygon
          id="path-1"
          points="0.0004029102 0.409240573 6.49399786 0.409240573 6.49399786 6.907032 0.0004029102 6.907032"
        ></polygon>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="17---assets" transform="translate(-41.000000, -76.000000)">
          <g
            id="enter-full-screen-btn---default"
            transform="translate(41.000000, 76.000000)"
          >
            <path
              d="M16.4568671,15.7148792 L16.4534136,9.58258596 C16.4539892,9.4208463 16.2588655,9.33968867 16.1443239,9.45423028 L10.0166354,15.5888259 C9.90209375,15.7033675 9.98325138,15.8984911 10.144991,15.8979168 L16.2761331,15.8956132 C16.3757095,15.8950376 16.4568671,15.8144556 16.4568671,15.7148792"
              id="Fill-1"
              fill="#FFF"
            ></path>
            <path
              d="M9.81092093,10.7600047 L11.3189562,9.25196936 C11.3511891,9.21973655 11.404143,9.21973655 11.4363758,9.25196936 L14.6539015,12.4694951 C14.6861343,12.5017279 14.6861343,12.5546818 14.6539015,12.5869146 L13.1458662,14.09495 C13.1136334,14.1277584 13.0606795,14.1277584 13.0278711,14.09495 L9.81092093,10.8779998 C9.77811253,10.8451914 9.77811253,10.7922375 9.81092093,10.7600047"
              id="Fill-3"
              fill="#FFF"
            ></path>
            <path
              d="M16.4568671,0.183036348 L16.4534136,6.31532959 C16.4534136,6.47706926 16.2582899,6.55822688 16.1443239,6.44368527 L10.0166354,0.309089682 C9.90209375,0.195123654 9.98325138,0 10.144991,0 L16.2767087,0.002302344 C16.3762851,0.002302344 16.4568671,0.08345997 16.4568671,0.183036348"
              id="Fill-5"
              fill="#FFF"
            ></path>
            <path
              d="M9.81092093,5.13819866 L11.3189562,6.64623398 C11.3511891,6.6784668 11.404143,6.6784668 11.4363758,6.64623398 L14.6539015,3.42870824 C14.6861343,3.39647543 14.6861343,3.34352152 14.6539015,3.3112887 L13.1458662,1.80325338 C13.1136334,1.77044498 13.0606795,1.77044498 13.0278711,1.80325338 L9.81092093,5.02020353 C9.77811253,5.05301194 9.77811253,5.10539026 9.81092093,5.13819866"
              id="Fill-7"
              fill="#FFF"
            ></path>
            <g id="Group-11" transform="translate(0.000000, 8.991402)">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1"></use>
              </mask>
              <g id="Clip-10"></g>
              <path
                d="M0.0004029102,6.72347762 L0.0038564262,0.590608795 C0.0038564262,0.428869129 0.19898008,0.348287089 0.312946108,0.462828703 L6.44063466,6.59742429 C6.55460069,6.71139032 6.47401865,6.90708956 6.31227899,6.90708956 L0.180561328,6.90421163 C0.0809849502,6.90421163 0.0004029102,6.823054 0.0004029102,6.72347762"
                id="Fill-9"
                fill="#FFF"
                mask="url(#mask-2)"
              ></path>
            </g>
            <path
              d="M6.6463491,10.7600047 L5.13831378,9.25196936 C5.10608096,9.21973655 5.05312705,9.21973655 5.02089424,9.25196936 L1.8033685,12.4694951 C1.77113568,12.5017279 1.77113568,12.5546818 1.8033685,12.5869146 L3.31140382,14.09495 C3.34421222,14.1277584 3.39659054,14.1277584 3.42939895,14.09495 L6.6463491,10.8779998 C6.6791575,10.8451914 6.6791575,10.7928131 6.6463491,10.7600047"
              id="Fill-12"
              fill="#FFF"
            ></path>
            <path
              d="M0.0001151172,0.183209024 L0.0035686332,6.31550227 C0.0035686332,6.47724193 0.198692287,6.55839956 0.312658315,6.44385795 L6.44034687,0.309262358 C6.55488849,0.19529633 6.47373086,0.0001726758 6.31199119,0.0001726758 L0.180273535,0.0024750198 C0.0806971572,0.0024750198 0.0001151172,0.0836326458 0.0001151172,0.183209024"
              id="Fill-14"
              fill="#FFF"
            ></path>
            <path
              d="M6.64606131,5.13819866 L5.13802599,6.64623398 C5.10579317,6.6784668 5.05283926,6.6784668 5.02060644,6.64623398 L1.8030807,3.42870824 C1.77084789,3.39647543 1.77084789,3.34352152 1.8030807,3.3112887 L3.31111602,1.80325338 C3.34334884,1.77044498 3.39630275,1.77044498 3.42911115,1.80325338 L6.64606131,5.02020353 C6.67886971,5.05301194 6.67886971,5.10539026 6.64606131,5.13819866"
              id="Fill-16"
              fill="#FFF"
            ></path>
          </g>
        </g>
      </g>
    </Svg>
  )
}
