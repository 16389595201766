import React from 'react'

function StepThreeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <circle cx="10" cy="10" r="10" fill="currentColor" />
        <g fill="#FFF" fillRule="nonzero" transform="translate(6.594 5.302)">
          <path d="M5.41 4.49c.79-.379 1.21-1.027 1.21-1.93v-.012C6.62.968 5.21 0 3.4 0 1.47 0 .012.969.012 2.933v.042h1.482v-.042c0-1.145.804-1.612 1.79-1.612 1.088 0 1.66.522 1.66 1.306v.013c0 .803-.66 1.287-1.595 1.287h-.483v1.186h.487c.94 0 1.71.298 1.71 1.365v.013c0 .834-.52 1.484-1.806 1.484-.995 0-1.776-.468-1.776-1.613V6.32H0v.042c0 1.965 1.436 2.934 3.365 2.934 2.112 0 3.373-1.097 3.373-2.744V6.54c0-1.056-.502-1.698-1.329-2.048z" />
        </g>
      </g>
    </svg>
  )
}

export default StepThreeIcon
