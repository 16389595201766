export const addOneDayToDate = dateString => {
  const date = new Date(dateString)
  date.setDate(date.getDate() + 1)
  const newDateString = `${('0' + (date.getMonth() + 1)).slice(-2)}/${(
    '0' + date.getDate()
  ).slice(-2)}/${date.getFullYear()}`
  return newDateString
}

export const formatLastUpdatedDate = dte => {
  const time = dte.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
  })

  const date = dte.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  })

  return `Updated at ${time} on ${date}`
}

export const dateToMMMMddyyyy = date => {
  return `${date?.toLocaleString('default', {
    month: 'long',
  })} ${date?.getDate()}, ${date?.getFullYear()}`
}
