export const LOCALES = {
  EN_US: 'en-US',
  ES_US: 'es-US',
}
const localeSessionLocation = 'player_locale'

export const isLocaleEnglishUS = locale => !locale || locale === LOCALES.EN_US

export const getLocaleObject = locale =>
  ({
    [LOCALES.EN_US]: {
      label: 'English',
      srclang: 'en',
    },
    [LOCALES.ES_US]: {
      label: 'Spanish',
      srclang: 'es',
    },
  }[locale])

/**
 * Locale can be set in the url as a query param and/or in session storage depending on the case.
 * When locale is present in the URL, we want to update it
 * @param {string} locale
 */
export const setLocale = locale => {
  window.sessionStorage.setItem(localeSessionLocation, locale)
  const url = new URL(window.location.href)

  if (url.searchParams.has('locale')) {
    url.searchParams.set('locale', locale)
  }

  window.location.replace(url.href)
}

export const getLocaleFromSession = () =>
  window.sessionStorage.getItem(localeSessionLocation) || LOCALES.EN_US

export const getConstantByLocale = (CONSTANT, locale) => {
  let value = CONSTANT[locale] || ''
  if (!value) {
    // Get value by default locale
    value = CONSTANT[LOCALES.EN_US] || ''
  }
  return value
}
