import styled from 'styled-components'
import { themeGet } from 'styled-system'
import BodyCopy from '../BodyCopy'

const ModalBodyCopy = styled(BodyCopy).attrs(() => ({
  responsive: 'true',
}))`
  color: ${themeGet('colors.modals.bodyCopy')};
  font-family: ${themeGet('fontFamily')};
  font-size: 1rem;
  line-height: 1.375rem;
  ${themeGet('breakpoints.tablet')} {
    padding: 0;
  }
  max-width: none;
`

ModalBodyCopy.displayName = 'ModalBodyCopy'

export default ModalBodyCopy
