import { PLAYER_CONTEXT } from './constants'

const hasParam = param => {
  const { search } = location || {}
  const params = search && new URLSearchParams(search)
  const previewParam = (params && params.get(param)) || false
  return !!previewParam
}

const isLMS = () => {
  const hasLMS =
    !!window.parent.__CFC_PLAYER_DATA__ &&
    !!window.parent.API_1484_11 &&
    window.location.host.includes('lms')
  window.parent.isLMS = hasLMS
  return hasLMS
}

const isPreview = () => !isLMS() && hasParam('preview')
const isFamily = () => !isLMS() && hasParam('family')

const resolvePlayerContext = () => {
  switch (true) {
    case playerUtils.isLMS():
      return PLAYER_CONTEXT.LMS.toLowerCase()
    case playerUtils.isFamily():
      return PLAYER_CONTEXT.FAMILY.toLowerCase()
    case playerUtils.isPreview():
      return PLAYER_CONTEXT.CONTENTFUL.toLowerCase()
    default:
      return PLAYER_CONTEXT.MARKETING.toLowerCase()
  }
}

const playerUtils = {
  hasParam,
  isFamily,
  isLMS,
  isPreview,
  resolvePlayerContext,
}

export default playerUtils
