import React from 'react'

import PropTypes from 'prop-types'

import DragAndDrop from '../../lib/dragAndDrop'

import DragLayerComponent from './component'

//React-dnd provides a drag layer by default, but since we scale our app to mantain an aspect
//ratio, we need a custom drag layer to represent the correct scale and positions of draggables.

const DragLayerContainer = ({ scale }) => {
  const [collectedProps] = DragAndDrop.setupUseDragLayer()
  const { currentOffset, isDragging, item } = collectedProps || {}
  const { x = 0, y = 0 } = currentOffset || {}
  const showPreviewLayer = isDragging && x && y
  const previewComponent = showPreviewLayer ? (
    <DragLayerComponent item={item} scale={scale} x={x} y={y} />
  ) : null

  return previewComponent
}

DragLayerContainer.propTypes = {
  scale: PropTypes.number,
}

DragLayerContainer.defaultProps = {
  scale: 1,
}

export default DragLayerContainer
