/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

const EditIcon = () => {
  return (
    <svg height="16" viewBox="0 0 16 16" width="16">
      <g
        fill="currentColor"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        transform="translate(-365 -234) translate(40 218) rotate(45 153.6 408.603)"
      >
        <path d="M10.57065 15.831936L8.28528 20.719566 6 15.831936z" />
        <path d="M6 14.487V2.375a2.285 2.285 0 014.57 0v12.112H6z" />
      </g>
    </svg>
  )
}

export default EditIcon
