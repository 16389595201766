import React, { useEffect, useState } from 'react'
import PropType from 'prop-types'
import { Button } from './component.styles'
import CaretIcon from '../CaretIcon'

const BackToTop = ({ thresholdPixels, mobileOnly }) => {
  const [isDisplayed, setDisplayed] = useState(false)

  const handleScroll = () => {
    setDisplayed(window.scrollY > thresholdPixels)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const returnToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <Button
      className={`${isDisplayed ? '' : 'hidden'} ${
        mobileOnly ? 'mobileOnly' : ''
      }`.trim()}
      onClick={returnToTop}
      type="button"
    >
      <CaretIcon />
      Top
    </Button>
  )
}

BackToTop.propTypes = {
  mobileOnly: PropType.bool,
  thresholdPixels: PropType.number,
}

BackToTop.defaultProps = {
  thresholdPixels: 1024,
  mobileOnly: true,
}

export default BackToTop
