/** A library wrapper for the npm package react-csv: https://www.npmjs.com/package/react-csv */
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { CSVLink } from 'react-csv'
import DownloadIcon from '../DownloadIcon'
import IconCaretSelect from '../IconCaretSelect'

import {
  DownloadButton,
  DropdownSpaceFiller,
  ErrorMsg,
  HelpLink,
  InfoBox,
  LoadingMsg,
  Messages,
  ReportLink,
  ReportLinks,
  ReportLinkSeparator,
  Wrapper,
} from './component.styles'
import {
  CURRENT_YEAR_TEXT,
  CURRENT_YEAR_TEXT_CONTENT,
  DATA_TEST_ID,
  DATA_TEST_ID_CURRENT,
  DATA_TEST_ID_SEPARATOR,
  DATA_TEST_ID_LINK,
  DATA_TEST_ID_BUTTON,
  DATA_TEST_ID_LINKS,
  DATA_TEST_ID_INFOBOX,
  DATA_TEST_ID_MESSAGES,
  DATA_TEST_ID_LOADING,
  DATA_TEST_ID_ERROR,
  DATA_TEST_ID_HELP,
  DATA_TEST_ID_CSV_LINK,
  ERROR_TEXT,
  EVENT_CLICK,
  EVENT_KEYDOWN,
  HELP_TEXT,
  KEY_ESCAPE,
  KEY_ENTER,
  KEY_ARROW_DOWN,
  KEY_ARROW_UP,
  LINK_FILE_NAME,
  LINK_LABEL_TEXT,
  LOADING_TEXT,
  SEPARATOR_TEXT,
  stringFormat,
} from './constants'

const CsvDownloadMenu = ({
  buttonIcon,
  buttonText,
  className,
  dataTestId = DATA_TEST_ID,
  disabledSelectButton = false,
  historicalReports,
  onClickCurrent,
  onClickCurrentYear,
  onClickHistorical,
  showDownloadIcons = true,
}) => {
  const linksRef = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const csvLinkRef = useRef()
  const [csvLinkData, setCsvLinkData] = useState(null)

  const toggleIsOpen = (open = null) => {
    if (open === null) {
      open = !isOpen
    }
    if (open) {
      setIsError(false)
    }
    setIsOpen(open)
  }

  const handleLinkOnClick = onClickCallback => {
    try {
      setIsLoading(true)
      setIsError(false)
      const data = onClickCallback()
      const isValid = validateData(data)
      setIsLoading(false)
      if (isValid) {
        setCsvLinkData(data)
      } else {
        setIsError(true)
      }
    } catch (error) {
      setIsError(true)
      setIsLoading(false)
    }
  }

  const validateData = ({ data, filename, headers } = {}) => {
    const isFileNameValid = typeof filename === 'string'
    const areHeadersValid =
      Array.isArray(headers) &&
      headers.length > 0 &&
      headers.every(item => {
        const { label, key } = item || {}
        return label && key
      })
    const isDataValid =
      Array.isArray(data) &&
      data.length > 0 &&
      data.every(item => {
        return typeof item === 'object' && item !== null
      })
    return isFileNameValid && areHeadersValid && isDataValid
  }

  const handleEnterKey = (e, onClick) => {
    if (e?.key === KEY_ENTER && onClick) {
      onClick(e)
    }
  }

  const handleArrowKeys = (e, onClick) => {
    if (onClick) {
      if (
        (e?.key === KEY_ARROW_DOWN && !isOpen) ||
        (e?.key === KEY_ARROW_UP && isOpen)
      ) {
        e.preventDefault()
        onClick()
      }
    }
  }

  const getHistoricalLinks = () => {
    const hasHistorical = historicalReports && historicalReports.length !== 0

    if (!hasHistorical) {
      return null
    }
    const links = [
      <ReportLinkSeparator
        dataTestId={DATA_TEST_ID_SEPARATOR}
        key={DATA_TEST_ID_SEPARATOR}
      >
        <span>{SEPARATOR_TEXT}</span>
        <div />
      </ReportLinkSeparator>,
    ]

    historicalReports.forEach((report, idx) => {
      if (report) {
        const { data, name } = report
        const year = name.substr(0, name.indexOf('.'))
        const years = year.split('-')
        const linkId = `${DATA_TEST_ID_LINK}-${idx}`
        const label = stringFormat(LINK_LABEL_TEXT, years)
        const filename = stringFormat(LINK_FILE_NAME, years)

        const linkOnClick = () =>
          handleLinkOnClick(() => onClickHistorical(data, filename))
        links.push(
          <ReportLink
            data-focusable="true"
            dataTestId={linkId}
            key={linkId}
            onClick={linkOnClick}
            onKeyDown={e => handleEnterKey(e, linkOnClick)}
            tabIndex="0"
          >
            {showDownloadIcons && <DownloadIcon />}
            <span>{label}</span>
          </ReportLink>,
        )
      }
    })
    return links
  }

  useEffect(() => {
    const close = e => {
      if (e?.code === KEY_ESCAPE || e?.type === EVENT_CLICK) {
        toggleIsOpen(false)
      }
    }
    if (isOpen) {
      document.addEventListener(EVENT_CLICK, close)
      document.addEventListener(EVENT_KEYDOWN, close)
    }
    return () => {
      document.removeEventListener(EVENT_CLICK, close)
      document.removeEventListener(EVENT_KEYDOWN, close)
    }
  }, [isOpen])

  useEffect(() => {
    if (csvLinkData && csvLinkRef.current && csvLinkRef.current.link) {
      const timeout = setTimeout(() => {
        csvLinkRef.current.link.click()
      })
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [csvLinkData])

  return (
    <Wrapper
      className={className}
      data-focusable="true"
      dataTestId={dataTestId}
    >
      <DownloadButton
        dataTestId={DATA_TEST_ID_BUTTON}
        disabled={disabledSelectButton}
        isOpen={isOpen}
        onClick={() => toggleIsOpen()}
        onKeyDown={e => handleArrowKeys(e, () => toggleIsOpen())}
        tabIndex="0"
      >
        {buttonIcon && buttonIcon()}
        <span>{buttonText}</span>
        <IconCaretSelect />
      </DownloadButton>
      {isOpen && (
        <>
          <DropdownSpaceFiller className="dropdown-space-filler" />
          <ReportLinks
            dataTestId={DATA_TEST_ID_LINKS}
            key={DATA_TEST_ID_LINKS}
            ref={linksRef}
          >
            <ReportLink
              data-focusable="true"
              dataTestId={DATA_TEST_ID_CURRENT}
              key={DATA_TEST_ID_CURRENT}
              onClick={e => {
                if (
                  e?.target?.textContent?.includes(CURRENT_YEAR_TEXT_CONTENT)
                ) {
                  onClickCurrentYear()
                } else {
                  handleLinkOnClick(onClickCurrent)
                }
              }}
              onKeyDown={e =>
                handleEnterKey(e, () => handleLinkOnClick(onClickCurrent))
              }
              tabIndex="0"
            >
              {showDownloadIcons && <DownloadIcon />}
              <span>{CURRENT_YEAR_TEXT}</span>
            </ReportLink>
            {getHistoricalLinks()}
          </ReportLinks>
        </>
      )}
      <InfoBox dataTestId={DATA_TEST_ID_INFOBOX} key={DATA_TEST_ID_INFOBOX}>
        <Messages dataTestId={DATA_TEST_ID_MESSAGES}>
          {isLoading && (
            <LoadingMsg dataTestId={DATA_TEST_ID_LOADING}>
              {LOADING_TEXT}
            </LoadingMsg>
          )}
          {isError && (
            <ErrorMsg dataTestId={DATA_TEST_ID_ERROR}>{ERROR_TEXT}</ErrorMsg>
          )}
        </Messages>
        <HelpLink dataTestId={DATA_TEST_ID_HELP}>{HELP_TEXT}</HelpLink>
      </InfoBox>
      {csvLinkData && csvLinkData?.data.length > 0 && (
        <CSVLink
          data={csvLinkData.data}
          data-testid={DATA_TEST_ID_CSV_LINK}
          filename={csvLinkData.filename}
          headers={csvLinkData.headers}
          key={DATA_TEST_ID_CSV_LINK}
          ref={csvLinkRef}
        />
      )}
    </Wrapper>
  )
}

CsvDownloadMenu.propTypes = {
  buttonIcon: PropTypes.func,
  buttonText: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  disabledSelectButton: PropTypes.bool,
  historicalReports: PropTypes.array,
  onClickCurrent: PropTypes.func,
  onClickCurrentYear: PropTypes.func,
  onClickHistorical: PropTypes.func,
  showDownloadIcons: PropTypes.bool,
}

export default CsvDownloadMenu
