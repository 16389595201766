import React from 'react'

const BackpacksIcon = () => (
  <svg height="64px" viewBox="0 0 166 64" width="166px">
    <title>ss_admindash_backpacks</title>
    <g
      fill="none"
      fillRule="evenodd"
      id="ss_admindash_backpacks"
      stroke="none"
      strokeWidth="1"
    >
      <g id="Group-5" transform="translate(2.000000, 2.000000)">
        <g id="Group" transform="translate(0.000000, 5.916667)">
          <path
            d="M19.5,6.33333333 C30.2695526,6.33333333 39,15.0637807 39,25.8333333 L39,49.3333333 C39,52.0947571 36.7614237,54.3333333 34,54.3333333 L5,54.3333333 C2.23857625,54.3333333 1.2263553e-15,52.0947571 0,49.3333333 L0,25.8333333 C-1.31888981e-15,15.0637807 8.73044738,6.33333333 19.5,6.33333333 Z"
            id="Rectangle"
            stroke="#3C6E90"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <line
            id="Line-2"
            stroke="#04817C"
            strokeLinecap="square"
            strokeWidth="1.5"
            x1="5.5"
            x2="33.5"
            y1="40.8333333"
            y2="40.8333333"
          />
          <rect
            height="12"
            id="Rectangle"
            rx="2"
            stroke="#3C6E90"
            strokeWidth="1.5"
            width="29"
            x="5"
            y="37.3333333"
          />
          <path
            d="M16.1666667,-2.16666667 L17.3333333,-2.16666667 C20.3708995,-2.16666667 22.8333333,0.295767209 22.8333333,3.33333333 C22.8333333,6.37089946 20.3708995,8.83333333 17.3333333,8.83333333 L16.1666667,8.83333333 L16.1666667,8.83333333"
            id="Rectangle"
            stroke="#04817C"
            strokeWidth="1.5"
            transform="translate(19.500000, 3.333333) rotate(-90.000000) translate(-19.500000, -3.333333) "
          />
          <path
            d="M35.763456,22.5967893 C35.763456,13.6147306 28.4820587,6.33333333 19.5,6.33333333 C10.5179413,6.33333333 3.23654403,13.6147306 3.23654403,22.5967893 C3.23654403,31.578848 35.763456,31.578848 35.763456,22.5967893 Z"
            fill="#B1C5D3"
            id="Oval"
            stroke="#3C6E90"
            strokeWidth="1.5"
            transform="translate(19.500000, 17.833333) rotate(-180.000000) translate(-19.500000, -17.833333) "
          />
          <rect
            fill="#FFFFFF"
            height="5"
            id="Rectangle"
            rx="1"
            stroke="#3C6E90"
            strokeWidth="1.5"
            width="3"
            x="18"
            y="26.3333333"
          />
          <rect
            fill="#04817C"
            height="3"
            id="Rectangle"
            rx="0.75"
            width="1.5"
            x="30"
            y="40.3333333"
          />
        </g>
        <path
          d="M140.5,19.25 L141.5,19.25 C152.821837,19.25 162,28.4281626 162,39.75 L162,55.25 C162,58.0114237 159.761424,60.25 157,60.25 L125,60.25 C122.238576,60.25 120,58.0114237 120,55.25 L120,39.75 C120,28.4281626 129.178163,19.25 140.5,19.25 Z"
          id="Rectangle"
          stroke="#3C6E90"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <rect
          fill="#C0DFDE"
          height="12"
          id="Rectangle"
          rx="2"
          stroke="#3C6E90"
          strokeWidth="1.5"
          width="14"
          x="143"
          y="43.25"
        />
        <rect
          fill="#C0DFDE"
          height="12"
          id="Rectangle"
          rx="2"
          stroke="#3C6E90"
          strokeWidth="1.5"
          width="14"
          x="125"
          y="43.25"
        />
        <path
          d="M137.666667,10.25 C141.348565,10.25 144.333333,13.2347683 144.333333,16.9166667 L144.333333,17.5833333 C144.333333,21.2652317 141.348565,24.25 137.666667,24.25 L137.666667,24.25 L137.666667,24.25"
          id="Rectangle"
          stroke="#3C6E90"
          strokeWidth="1.5"
          transform="translate(141.000000, 17.250000) rotate(-90.000000) translate(-141.000000, -17.250000) "
        />
        <path
          d="M76.75,-5.25 C80.3398509,-5.25 83.25,-2.33985087 83.25,1.25 L83.25,5.25 C83.25,8.83985087 80.3398509,11.75 76.75,11.75 L76.75,11.75 L76.75,11.75"
          id="Rectangle"
          stroke="#3C6E90"
          strokeWidth="1.5"
          transform="translate(80.000000, 3.250000) rotate(-90.000000) translate(-80.000000, -3.250000) "
        />
        <line
          id="Line-2"
          stroke="#04817C"
          strokeLinecap="round"
          strokeWidth="1.5"
          x1="125.5"
          x2="156.5"
          y1="36.75"
          y2="36.75"
        />
        <rect
          fill="#04817C"
          height="3"
          id="Rectangle"
          rx="0.75"
          width="1.5"
          x="153"
          y="36.25"
        />
        <line
          id="Line-2"
          stroke="#3C6E90"
          strokeLinecap="round"
          strokeWidth="1.5"
          x1="143.5"
          x2="156.5"
          y1="47.75"
          y2="47.75"
        />
        <rect
          fill="#3C6E90"
          height="3"
          id="Rectangle"
          rx="0.75"
          width="1.5"
          x="153"
          y="47.25"
        />
        <line
          id="Line-2"
          stroke="#3C6E90"
          strokeLinecap="round"
          strokeWidth="1.5"
          x1="125.5"
          x2="138.5"
          y1="47.75"
          y2="47.75"
        />
        <rect
          fill="#3C6E90"
          height="3"
          id="Rectangle"
          rx="0.75"
          width="1.5"
          x="135"
          y="47.25"
        />
        <path
          d="M80,6.25 C91.045695,6.25 100,15.204305 100,26.25 L100,55.25 C100,58.0114237 97.7614237,60.25 95,60.25 L65,60.25 C62.2385763,60.25 60,58.0114237 60,55.25 L60,26.25 C60,15.204305 68.954305,6.25 80,6.25 Z"
          id="Rectangle"
          stroke="#3C6E90"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <rect
          fill="#C0DFDE"
          height="35"
          id="Rectangle"
          rx="11"
          stroke="#3C6E90"
          strokeWidth="1.5"
          width="42"
          x="59"
          y="6.25"
        />
        <rect
          fill="#EEF0F2"
          height="40"
          id="Rectangle"
          rx="1.5"
          stroke="#3C6E90"
          strokeWidth="1.5"
          width="3"
          x="70"
          y="6.25"
        />
        <rect
          fill="#EEF0F2"
          height="40"
          id="Rectangle"
          rx="1.5"
          stroke="#3C6E90"
          strokeWidth="1.5"
          width="3"
          x="87"
          y="6.25"
        />
        <rect
          fill="#FFFFFF"
          height="3"
          id="Rectangle"
          rx="1"
          stroke="#04817C"
          strokeWidth="1.5"
          width="7"
          x="85"
          y="30.25"
        />
        <rect
          fill="#FFFFFF"
          height="3"
          id="Rectangle"
          rx="1"
          stroke="#04817C"
          strokeWidth="1.5"
          width="7"
          x="68"
          y="30.25"
        />
        <rect
          fill="#FFFFFF"
          height="3"
          id="Rectangle"
          rx="1"
          stroke="#04817C"
          strokeWidth="1.5"
          width="7"
          x="85"
          y="16.25"
        />
        <rect
          fill="#FFFFFF"
          height="3"
          id="Rectangle"
          rx="1"
          stroke="#04817C"
          strokeWidth="1.5"
          width="7"
          x="68"
          y="16.25"
        />
        <rect
          fill="#FFFFFF"
          height="4"
          id="Rectangle"
          rx="1"
          stroke="#04817C"
          strokeWidth="1.5"
          transform="translate(80.207107, 51.164214) rotate(-315.000000) translate(-80.207107, -51.164214) "
          width="4"
          x="78.2071068"
          y="49.1642136"
        />
        <rect
          height="5"
          id="Rectangle"
          rx="1"
          stroke="#3C6E90"
          strokeWidth="1.5"
          transform="translate(140.707107, 27.664214) rotate(-270.000000) translate(-140.707107, -27.664214) "
          width="5"
          x="138.207107"
          y="25.1642136"
        />
      </g>
    </g>
  </svg>
)

export default BackpacksIcon
