import React, { Fragment, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import SearchIcon from '../SearchIcon'
import CheckIcon from '../CheckIcon'
import IconCaretSelect from '../IconCaretSelect'
import {
  DropdownButton,
  DropdownOption,
  DropdownOptions,
  DropdownOptionSeparator,
  DropdownSpaceFiller,
  LabelWrapper,
  StyledLabel,
  SearchInput,
  Wrapper,
  InputWrapper,
  IconWrapper,
} from './component.styles'
import {
  EVENT_BLUR,
  EVENT_CLICK,
  EVENT_FOCUS,
  EVENT_KEYDOWN,
  KEY_ESCAPE,
  KEY_ENTER,
  KEY_ARROW_DOWN,
  KEY_ARROW_UP,
  KEY_TAB,
} from './constants'

const SearchSelect = ({
  buttonTextTransformer,
  className,
  dataTestId,
  defaultLabel,
  dropdownButtonIcon,
  InputIcon,
  isSearchable,
  label,
  matchPattern,
  noMatchText,
  onChange,
  options,
  placeholder,
  SelectedIcon,
  separatorIndices,
  shouldHideSelectedIcon,
  keyProp,
  Tooltip,
  selectedOption,
  labelProp,
}) => {
  const listRef = useRef(null)
  const [highlightedIndex, setHighlightedIndex] = useState(-1)
  const [isOpen, setIsOpen] = useState(false)
  const [prevSelectedOption, setPrevSelectedOption] = useState(
    selectedOption || {},
  )
  const [searchValue, setSearchValue] = useState(
    selectedOption?.[labelProp] || '',
  )

  useEffect(() => {
    setHighlightedIndex(-1)
    setIsOpen(false)
    setPrevSelectedOption({
      ...prevSelectedOption,
      ...selectedOption,
    })
    setSearchValue(selectedOption?.[labelProp])
  }, [selectedOption])

  useEffect(() => {
    getOptions(prevSelectedOption?.key, isMatch)
  }, [searchValue])

  useEffect(() => {
    if (isOpen) {
      setSearchValue('')
    } else {
      setSearchValue(prevSelectedOption[labelProp] || '')
      setHighlightedIndex(-1)
    }

    const close = e => {
      if (e?.code === KEY_ESCAPE || e?.type === EVENT_CLICK) {
        setIsOpen(false)
      }
    }
    if (isOpen) {
      document.addEventListener(EVENT_CLICK, close)
      document.addEventListener(EVENT_KEYDOWN, close)
    }
    return () => {
      document.removeEventListener(EVENT_CLICK, close)
      document.removeEventListener(EVENT_KEYDOWN, close)
    }
  }, [isOpen])

  const selectOption = option => {
    setIsOpen(false)
    setHighlightedIndex(-1)
    setPrevSelectedOption({
      ...prevSelectedOption,
      ...option,
    })
    setSearchValue(option[labelProp])
    onChange(option)
  }

  const handleKeyDown = event => {
    // early return - show events we care about at a glance
    if (
      ![EVENT_BLUR, EVENT_CLICK, EVENT_FOCUS].includes(event.type) &&
      ![KEY_ESCAPE, KEY_ENTER, KEY_ARROW_DOWN, KEY_ARROW_UP, KEY_TAB].includes(
        event.key,
      )
    )
      return

    const dropdownOptions = listRef.current?.children

    if (
      [KEY_ESCAPE, KEY_TAB].includes(event.key) ||
      event.type === EVENT_BLUR
    ) {
      setHighlightedIndex(-1)
      setIsOpen(false)
    } else if (event.key === KEY_ENTER) {
      event.preventDefault()
      if (highlightedIndex >= 0) {
        // NOTE: links aren't focused, so we have to programmatically click when KEY_ENTER pressed when an option is highlighted
        dropdownOptions[highlightedIndex].click()
      }
      setIsOpen(!isOpen)
    } else if (event.type === EVENT_CLICK) {
      event.preventDefault()
      setIsOpen(true)
    } else if ([KEY_ARROW_DOWN, KEY_ARROW_UP].includes(event.key) && isOpen) {
      event.preventDefault()

      if (event.key === KEY_ARROW_DOWN) {
        setHighlightedIndex(index =>
          index < dropdownOptions.length - 1 ? index + 1 : 0,
        )
      } else if (event.key === KEY_ARROW_UP) {
        setHighlightedIndex(index =>
          index > 0 ? index - 1 : dropdownOptions.length - 1,
        )
      }
    } else {
      setHighlightedIndex(-1)
    }
  }

  const isMatch = option => {
    const escapedInput = searchValue?.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')
    const exp = matchPattern(escapedInput)
    return exp.test(option[labelProp])
  }

  const getOptions = (defaultKey, isMatch) => {
    const links = []
    let filteredIdx = 0

    options.forEach((option, idx) => {
      if (isSearchable && !isMatch(option)) return

      if (separatorIndices.includes(idx)) {
        links.push(
          <DropdownOptionSeparator
            dataTestId={`${dataTestId}-separator`}
            key={`${dataTestId}-separator`}
          >
            <div />
          </DropdownOptionSeparator>,
        )
      }

      const { [keyProp]: key, [labelProp]: optionLabel } = option
      const isDefaultValue = key && defaultKey && key === defaultKey
      const isHighlighted = highlightedIndex === filteredIdx
      const linkId = `${dataTestId}-${key}`
      const linkOnClick = () => {
        selectOption(option)
      }

      links.push(
        <DropdownOption
          className={`option ${isHighlighted && 'arrow-selected'}`}
          data-focusable="true"
          dataTestId={linkId}
          isDefaultValue={isDefaultValue}
          key={linkId}
          onClick={linkOnClick}
          tabIndex="0"
        >
          <span>{optionLabel}</span>
          {!shouldHideSelectedIcon && SelectedIcon}
        </DropdownOption>,
      )
      filteredIdx++
    })
    if (!links.length) {
      links.push(
        <DropdownOption className={'no-match'}>
          <span>{noMatchText}</span>
        </DropdownOption>,
      )
    }
    return links
  }

  const onInputChange = event => {
    setIsOpen(true)
    setSearchValue(event.target.value)
    setHighlightedIndex(-1)
  }

  const { [keyProp]: key, [labelProp]: optionLabel } = prevSelectedOption || {}

  return (
    <Wrapper
      className={className}
      data-focusable="true"
      dataTestId={dataTestId}
    >
      <LabelWrapper>
        <StyledLabel>{label}</StyledLabel>
        {Tooltip && Tooltip}
      </LabelWrapper>
      {isSearchable ? (
        <InputWrapper>
          <SearchInput
            dataTestId={`${dataTestId}-input`}
            onChange={onInputChange}
            onClick={handleKeyDown}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            value={searchValue}
          />
          <IconWrapper>{InputIcon}</IconWrapper>
        </InputWrapper>
      ) : (
        <DropdownButton
          dataTestId={`${dataTestId}-button`}
          isOpen={isOpen}
          onClick={handleKeyDown}
          onKeyDown={handleKeyDown}
          tabIndex="0"
        >
          {dropdownButtonIcon}
          {defaultLabel ? (
            <span>{`${defaultLabel}`}</span>
          ) : (
            <span>{buttonTextTransformer(optionLabel) || placeholder}</span>
          )}
          <IconCaretSelect />
        </DropdownButton>
      )}

      {isOpen && (
        <Fragment key="is-open">
          <DropdownSpaceFiller className="dropdown-space-filler" />
          <DropdownOptions
            dataTestId={`${dataTestId}-options`}
            key={`${dataTestId}-options`}
            ref={listRef}
            role="listbox"
          >
            {getOptions(key, isMatch)}
          </DropdownOptions>
        </Fragment>
      )}
    </Wrapper>
  )
}

SearchSelect.propTypes = {
  InputIcon: PropTypes.node,
  SelectedIcon: PropTypes.node,
  Tooltip: PropTypes.node,
  buttonTextTransformer: PropTypes.func,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  defaultLabel: PropTypes.string,
  dropdownButtonIcon: PropTypes.node,
  isSearchable: PropTypes.bool,
  keyProp: PropTypes.string,
  label: PropTypes.string,
  labelProp: PropTypes.string,
  matchPattern: PropTypes.func,
  noMatchText: PropTypes.string,
  noSeparator: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  selectedOption: PropTypes.object,
  separatorIndices: PropTypes.array,
  shouldHideSelectedIcon: PropTypes.bool,
}

SearchSelect.defaultProps = {
  buttonTextTransformer: optionLabel => optionLabel,
  InputIcon: <SearchIcon />,
  keyProp: 'key',
  matchPattern: input => new RegExp('\\b' + input, 'i'),
  noMatchText: 'No Match!',
  placeholder: 'Search...',
  SelectedIcon: <CheckIcon />,
  labelProp: 'name',
  separatorIndices: [],
}

export default SearchSelect
