import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Button } from 'grommet'
import CloseIcon from '../CloseIcon'

const CloseButton = styled(Button).attrs(({ dataTestId }) => ({
  a11yTitle: 'Close',
  'data-testid': dataTestId,
  plain: true,
}))`
  :hover {
    svg {
      transition: color 0.4s ease-in-out;
      color: ${themeGet('colors.buttons.iconHover')};
    }
  }
`

CloseButton.propTypes = {
  dataTestId: PropTypes.string.isRequired,
  icon: PropTypes.node,
  onClick: PropTypes.func.isRequired,
}

CloseButton.defaultProps = {
  icon: <CloseIcon />,
}

export default CloseButton
