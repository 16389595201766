import React from 'react'

import PropTypes from 'prop-types'
import Markdown from 'react-markdown'

import DialogcardTurnIndicator from './DialogcardTurnIndicator'
import {
  CardFaceBack,
  CardFaceFront,
  ImageContainer,
  TextContainerBack,
  TextContainerFront,
  Wrapper,
} from './style'

const DialogcardComponent = ({
  answer,
  dataTestId,
  flipCard,
  handleEnter,
  imageUrl,
  isFlipped,
  getAriaLabel,
  text,
}) => (
  <Wrapper
    data-testid={dataTestId}
    onClick={flipCard}
    role={'button'}
    aria-label={getAriaLabel()}
    tabIndex={0}
    onKeyPress={handleEnter}
  >
    <CardFaceFront isFlipped={isFlipped}>
      <ImageContainer imageUrl={imageUrl} />
      <TextContainerFront imageUrl={imageUrl} text={text}>
        <Markdown
          source={text}
          renderers={{ paragraph: 'span' }}
          escapeHtml={false}
        />
      </TextContainerFront>
      <DialogcardTurnIndicator />
    </CardFaceFront>
    <CardFaceBack isFlipped={isFlipped}>
      <TextContainerBack>
        <Markdown
          source={answer}
          renderers={{ paragraph: 'span' }}
          escapeHtml={false}
        />
      </TextContainerBack>
      <DialogcardTurnIndicator />
    </CardFaceBack>
  </Wrapper>
)

DialogcardComponent.propTypes = {
  answer: PropTypes.string,
  dataTestId: PropTypes.string,
  flipCard: PropTypes.func,
  getAriaLabel: PropTypes.func,
  handleEnter: PropTypes.func,
  imageUrl: PropTypes.string,
  isFlipped: PropTypes.bool,
  returnAriaMessage: PropTypes.string,
  text: PropTypes.string,
}

DialogcardComponent.defaultProps = {
  flipCard() {},
  getAriaLabel() {},
}

export default DialogcardComponent
