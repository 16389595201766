import surveyBgImage from '../../../assets/images/k8-g4-survey-slide.jpg'

import { default as baseTheme } from './generic'

const fonts = {
  h1: {
    fontFamily: 'Rammetto One, cursive',
    fontSize: '3rem',
    lineHeight: '4rem',
    letterSpacing: '.04em',
  },
  h2: {
    fontFamily: 'swister, sans-serif',
    fontWeight: '700',
    fontSize: '2.5rem',
    lineHeight: '3.6rem',
  },
  p: {
    fontFamily: 'filson-soft, sans-serif',
    fontSize: '2.05rem',
    lineHeight: '3.3125rem',
    fontWeight: '300',
    marginBottom: '0px',
    marginTop: '0px',
  },
  li: {
    fontFamily: 'filson-soft, sans-serif',
    fontSize: '2.05rem',
    lineHeight: '3.3125rem',
    fontWeight: '300',
    marginBottom: '0px',
    marginTop: '0px',
  },
  generic: {
    fontFamily: 'filson-soft, sans-serif',
    fontSize: '2.05rem',
    lineHeight: '3.3125rem',
    fontWeight: '300',
    marginBottom: '0px',
    marginTop: '0px',
  },
  h3: {
    fontFamily: 'Rammetto One, cursive',
    fontStyle: 'normal',
    margin: '0',
    fontSize: '2.3rem',
    paddingBottom: '1.0625rem',
    letterSpacing: '0.02em',
    wordSpacing: '0.16em',
    lineHeight: '1.5em',
  },
  h4: {},
  h5: {},
  h6: {},
}

export default {
  fonts: {
    ...baseTheme.fonts,
    ...fonts,
  },
  backgrounds: {
    surveySlideImage: surveyBgImage,
    surverySlideForeground: 'rgb(170, 140, 211)',
  },
}
