/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

const MindYetiStarSvg = () => (
  <svg height="18px" viewBox="0 0 18 18" width="18px">
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        d="M8.59643689,13.7276164 L4.8028479,16.4043 C4.48696381,16.6271817 4.0502078,16.5517884 3.82732605,16.2359044 C3.70207995,16.0583965 3.66634865,15.832798 3.73061184,15.6252749 L5.10400575,11.1902166 C5.19160224,10.907344 5.09172544,10.5999548 4.85459003,10.422594 L1.13662919,7.64181717 C0.827042491,7.4102678 0.763780743,6.97159034 0.995330115,6.66200365 C1.1254469,6.4880346 1.32896227,6.38433834 1.54618691,6.38132809 L6.18858002,6.31699494 C6.48467668,6.31289171 6.74615754,6.12291475 6.84155885,5.84257805 L8.33732167,1.44727987 C8.46187066,1.08129274 8.85952876,0.885568453 9.2255159,1.01011744 C9.43117859,1.08010646 9.59268931,1.24161718 9.66267833,1.44727987 L11.1584411,5.84257805 C11.2538425,6.12291475 11.5153233,6.31289171 11.81142,6.31699494 L16.4538131,6.38132809 C16.8403753,6.38668497 17.1494033,6.70439817 17.1440464,7.09096037 C17.1410361,7.30818501 17.0373399,7.51170039 16.8633708,7.64181717 L13.14541,10.422594 C12.9082746,10.5999548 12.8083978,10.907344 12.8959943,11.1902166 L14.2693882,15.6252749 C14.3837477,15.9945728 14.1770794,16.3866546 13.8077816,16.5010142 C13.6002584,16.5652774 13.3746599,16.5295461 13.1971521,16.4043 L9.40356311,13.7276164 C9.16160406,13.5568947 8.83839594,13.5568947 8.59643689,13.7276164 Z"
        fill="#4B63DD"
      />
    </g>
  </svg>
)

export default MindYetiStarSvg
