import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { themeGet } from 'styled-system'
import { Anchor } from 'grommet'
import { setEnvSpecificLearnLink } from './data'

const TextLink = withTheme(styled(Anchor).attrs(
  ({ dataTestId, target = '_blank', href, ...rest }) => {
    const attributes = {
      'data-testid': dataTestId,
      role: 'link',
      tabIndex: '0',
      target,
      ...rest,
    }
    if (href) {
      // if a link has the prod learn domain, replace it with the env specific domain
      attributes.href = setEnvSpecificLearnLink(href)
    }
    return attributes
  },
)`
  display: flex;
  align-items: center;
  color: ${themeGet('colors.links.primary')};
  fill: ${themeGet('colors.links.primary')};
  transition: color 0.2s;

  &:hover,
  &:hover > svg {
    text-decoration: none;
    color: ${themeGet('colors.links.primaryHover')};
    fill: ${themeGet('colors.links.primaryHover')};
    transition: color 0.2s;
  }

  &:focus {
    color: ${themeGet('colors.links.primaryHover')};
  }

  svg {
    margin: 0 0.5rem 0.1875rem 0;
  }
`)

TextLink.propTypes = {
  dataTestId: PropTypes.string.isRequired,
  href: PropTypes.string,
}

TextLink.displayName = 'TextLink'

export default TextLink
