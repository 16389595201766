/* istanbul ignore file */
const defaultProps = (hasHover, hasActive) => ({
  activeLine: {
    stroke: '#CED8DF',
    strokeLinecap: 'round',
    strokeWidth: 2,
    x1: 1,
    x2: 35,
    y1: 35,
    y2: 35,
  },
  height: hasActive ? 35.5 : 32,
  width: 32,
  g1: {
    transform: 'translate(-1318.000000, -53.000000)',
  },
  g2: {},
  g3: {
    transform: 'translate(1276.000000, 53.000000)',
    ...(hasActive || hasHover
      ? {
          transform: 'translate(1318.000000, 53.000000)',
        }
      : {}),
  },
  g4: {
    stroke: '#CED8DF',
    transform: 'translate(42.000000, 0.000000)',
  },
  rect1: {
    fill: '#000000',
    height: 771,
    width: 1366,
    x: 0,
    y: 0,
  },
  rect2: {
    fill: '#CED8DF',
    stroke: '#CED9DF',
    height: 30,
    rx: 6,
    width: 30,
    x: 1,
    y: 1,

    ...(hasActive &&
      hasHover && {
        fill: '##OBSIDIAN##',
        stroke: '#CED9DF',
        strokeWidth: '1',
      }),
  },
  rect3: {
    fill: '#000000',
    height: 30,
    width: 30,
    rx: 6,
    x: 1,
    y: 1,
  },
  line1: {
    strokeLinecap: 'round',
    strokeWidth: 2,
    stroke: '#FFFFFF',
    x1: 8,
    y1: 11,
    x2: 12,
    y2: 11,
    ...(hasActive || hasHover
      ? {
          stroke: '#000000',
          x1: 7,
          x2: 11,
          y1: 9.8611111,
          y2: 9.8611111,
        }
      : {}),
    ...(hasActive &&
      hasHover && {
        stroke: '#CED8DF',
      }),
  },
  line2: {
    strokeLinecap: 'round',
    strokeWidth: 2,
    stroke: '#FFFFFF',
    x1: 16,
    y1: 11,
    x2: 24,
    y2: 11,
    ...(hasActive || hasHover
      ? {
          stroke: '#000000',
          x1: 15,
          x2: 23,
          y1: 9.8611111,
          y2: 9.8611111,
        }
      : {}),
    ...(hasActive &&
      hasHover && {
        stroke: '#CED8DF',
      }),
  },
  line3: {
    strokeLinecap: 'round',
    strokeWidth: 2,
    stroke: '#FFFFFF',
    x1: 8,
    y1: 16,
    x2: 24,
    y2: 16,
    ...(hasActive || hasHover
      ? {
          stroke: '#000000',
          x1: 7,
          x2: 23,
          y1: 14.7916667,
          y2: 14.7916667,
        }
      : {}),
    ...(hasActive &&
      hasHover && {
        stroke: '#CED8DF',
      }),
  },
  line4: {
    strokeLinecap: 'round',
    strokeWidth: 2,
    stroke: '#FFFFFF',
    x1: 8,
    y1: 21,
    x2: 16,
    y2: 21,
    ...(hasActive || hasHover
      ? {
          stroke: '#000000',
          x1: 7,
          x2: 15,
          y1: 19.7222222,
          y2: 19.7222222,
        }
      : {}),
    ...(hasActive &&
      hasHover && {
        stroke: '#CED8DF',
      }),
  },
  line5: {
    strokeLinecap: 'round',
    strokeWidth: 2,
    stroke: '#FFFFFF',
    x1: 20,
    y1: 21,
    x2: 24,
    y2: 21,
    ...(hasActive || hasHover
      ? {
          stroke: '#000000',
          x1: 19,
          x2: 23,
          y1: 19.7222222,
          y2: 19.7222222,
        }
      : {}),
    ...(hasActive &&
      hasHover && {
        stroke: '#CED8DF',
      }),
  },
  line7: {
    strokeLinecap: 'round',
    strokeWidth: 2,
    x1: 7,
    x2: 11,
    y1: 10,
    y2: 10,
  },
  line8: {
    strokeLinecap: 'round',
    strokeWidth: 2,
    x1: 15,
    x2: 23,
    y1: 10,
    y2: 10,
  },
  line9: {
    strokeLinecap: 'round',
    strokeWidth: 2,
    x1: 7,
    x2: 23,
    y1: 15,
    y2: 15,
  },
  line10: {
    strokeLinecap: 'round',
    strokeWidth: 2,
    x1: 7,
    x2: 15,
    y1: 20,
    y2: 20,
  },
  line11: {
    strokeLinecap: 'round',
    strokeWidth: 2,
    x1: 19,
    x2: 23,
    y1: 20,
    y2: 20,
  },
})

const selaProps = (hasHover, hasActive, isMobile) => {
  return {
    activeLine: {
      stroke: '#007980',
      strokeLinecap: 'round',
      strokeWidth: 2,
      x1: 1,
      x2: 35,
      y1: 35,
      y2: 35,
    },
    height: hasActive ? 35.5 : 32,
    width: 32,
    ...(isMobile && {
      width: 50,
      height: hasHover ? 36 : 42,
    }),
    rect1: {
      height: 1024,
      width: 1366,
      x: 0,
      y: 0,
    },
    rect2: {
      fill: '#FFFFFF',
      height: 30,
      rx: 6,
      width: 30,
      x: 1,
      y: 1,
      ...(isMobile && {
        fill: '#FFFFFF',
        height: 667,
        stroke: 'none',
        rx: 'none',
        width: 375,
        x: 0,
        y: 0,
      }),
      ...((hasHover || hasActive) && !isMobile
        ? {
            fill: '#007980',
            stroke: '#007980',
          }
        : {}),
      ...(hasActive &&
        hasHover && {
          fill: '##OBSIDIAN##',
          stroke: '#007980',
          strokeWidth: '1',
        }),
    },
    rect3: {
      fill: '#FFFFFF',
      height: 30,
      rx: 6,
      width: 50,
      x: 0,
      y: 5,
      ...(hasHover || hasActive
        ? {
            fill: '#007980',
          }
        : {}),
    },
    g1: {
      transform: 'translate(-1196.000000, -939.000000)',
      ...(isMobile && {
        transform: 'translate(-305.000000, -395.000000)',
      }),
    },
    g2: {
      stroke: '#007980',
      transform: 'translate(1154.000000, 939.000000)',
      ...(isMobile && {
        stroke: 'none',
        transform: 'none',
      }),
      ...((hasHover || hasActive) && !isMobile
        ? {
            transform: 'translate(1196.000000, 939.000000)',
            stroke: 'none',
          }
        : {}),
    },
    g3: {
      transform: 'translate(42.000000, 0.000000)',
      ...(isMobile && {
        stroke: '#007980',
        transform: 'translate(305.000000, 390.000000)',
      }),
      ...(hasHover || (hasActive && !isMobile)
        ? {
            transform: 'none',
          }
        : {}),
    },
    g4: {
      stroke: '#007980',
      transform: 'translate(305.000000, 390.000000)',
      ...(hasHover || hasActive
        ? {
            stroke: '#FFFFFF',
          }
        : {}),
    },
    line1: {
      strokeLinecap: 'round',
      strokeWidth: 2,
      x1: 8,
      y1: 11,
      x2: 12,
      y2: 11,
      ...(isMobile && {
        strokeLinecap: 'round',
        strokeWidth: 2,
        x1: 17,
        x2: 21,
        y1: 16,
        y2: 16,
      }),
      ...(hasHover || hasActive
        ? {
            stroke: '#FFFFFF',
          }
        : {}),
      ...(hasActive &&
        hasHover && {
          stroke: '#007980',
        }),
    },
    line2: {
      strokeLinecap: 'round',
      strokeWidth: 2,
      x1: 16,
      y1: 11,
      x2: 24,
      y2: 11,
      ...(isMobile && {
        strokeLinecap: 'round',
        strokeWidth: 2,
        x1: 25,
        x2: 33,
        y1: 16,
        y2: 16,
      }),
      ...(hasHover || hasActive
        ? {
            stroke: '#FFFFFF',
          }
        : {}),
      ...(hasActive &&
        hasHover && {
          stroke: '#007980',
        }),
    },
    line3: {
      strokeLinecap: 'round',
      strokeWidth: 2,
      x1: 8,
      y1: 16,
      x2: 24,
      y2: 16,
      ...(isMobile && {
        strokeLinecap: 'round',
        strokeWidth: 2,
        x1: 17,
        x2: 33,
        y1: 21,
        y2: 21,
      }),
      ...(hasHover || hasActive
        ? {
            stroke: '#FFFFFF',
          }
        : {}),
      ...(hasActive &&
        hasHover && {
          stroke: '#007980',
        }),
    },
    line4: {
      strokeLinecap: 'round',
      strokeWidth: 2,
      x1: 8,
      y1: 21,
      x2: 16,
      y2: 21,
      ...(isMobile && {
        strokeLinecap: 'round',
        strokeWidth: 2,
        x1: 17,
        x2: 25,
        y1: 26,
        y2: 26,
      }),
      ...(hasHover || hasActive
        ? {
            stroke: '#FFFFFF',
          }
        : {}),
      ...(hasActive &&
        hasHover && {
          stroke: '#007980',
        }),
    },
    line5: {
      strokeLinecap: 'round',
      strokeWidth: 2,
      x1: 20,
      y1: 21,
      x2: 24,
      y2: 21,
      ...(isMobile && {
        strokeLinecap: 'round',
        strokeWidth: 2,
        x1: 29,
        x2: 33,
        y1: 26,
        y2: 26,
      }),
      ...(hasHover || hasActive
        ? {
            stroke: '#FFFFFF',
          }
        : {}),
      ...(hasActive &&
        hasHover && {
          stroke: '#007980',
        }),
    },
    line6: {
      stroke: '#007980',
      strokeLinecap: 'round',
      strokeWidth: 2,
      x1: 1,
      x2: 49,
      y1: 41,
      y2: 41,
    },

    line7: {
      strokeLinecap: 'round',
      strokeWidth: 2,
      x1: 17,
      x2: 21,
      y1: 16,
      y2: 16,
    },
    line8: {
      strokeLinecap: 'round',
      strokeWidth: 2,
      x1: 25,
      x2: 33,
      y1: 16,
      y2: 16,
    },
    line9: {
      strokeLinecap: 'round',
      strokeWidth: 2,
      x1: 17,
      x2: 33,
      y1: 21,
      y2: 21,
    },
    line10: {
      strokeLinecap: 'round',
      strokeWidth: 2,
      x1: 17,
      x2: 25,
      y1: 26,
      y2: 26,
    },
    line11: {
      strokeLinecap: 'round',
      strokeWidth: 2,
      x1: 29,
      x2: 33,
      y1: 26,
      y2: 26,
    },
    hasHover,
    hasActive,
    isMobile,
  }
}

const learnProps = (hasHover, hasActive, isMobile) => {
  const {
    activeLine,
    rect2,
    line1,
    line2,
    line3,
    line4,
    line5,
    g3,
    ...rest
  } = selaProps(hasHover, hasActive, isMobile)
  return {
    ...rest,
    rect2: {
      x: 1,
      y: 1,
      ...rect2,
      ...((hasHover || hasActive) && !isMobile
        ? {
            fill: '#002855',
            stroke: '#002855',
          }
        : {}),
      ...(hasActive &&
        hasHover && {
          fill: '##OBSIDIAN##',
          stroke: '#002855',
          strokeWidth: '1',
        }),
    },
    line1: {
      ...line1,
      ...(hasActive &&
        hasHover && {
          stroke: '#002855',
        }),
    },
    line2: {
      ...line2,
      ...(hasActive &&
        hasHover && {
          stroke: '#002855',
        }),
    },
    line3: {
      ...line3,
      ...(hasActive &&
        hasHover && {
          stroke: '#002855',
        }),
    },
    line4: {
      ...line4,
      ...(hasActive &&
        hasHover && {
          stroke: '#002855',
        }),
    },
    line5: {
      ...line5,
      ...(hasActive &&
        hasHover && {
          stroke: '#002855',
        }),
    },
    g3: {
      ...g3,
      stroke: '#002855',
    },
    activeLine: {
      ...activeLine,
      stroke: '#002855',
    },
    height: hasActive ? 35.5 : 32,
    width: 32,
  }
}

const highSchoolProps = (hasHover, hasActive, isMobile) => {
  const {
    activeLine,
    rect2,
    line1,
    line2,
    line3,
    line4,
    line5,
    g3,
    ...rest
  } = selaProps(hasHover, hasActive, isMobile)
  return {
    ...rest,
    rect2: {
      x: 1,
      y: 1,
      ...rect2,
      ...((hasHover || hasActive) && !isMobile
        ? {
            fill: '#C62D70',
            stroke: '#C62D70',
          }
        : {}),
      ...(hasActive &&
        hasHover && {
          fill: '##OBSIDIAN##',
          stroke: '#C62D70',
          strokeWidth: '1',
        }),
    },
    line1: {
      ...line1,
      ...(hasActive &&
        hasHover && {
          stroke: '#C62D70',
        }),
    },
    line2: {
      ...line2,
      ...(hasActive &&
        hasHover && {
          stroke: '#C62D70',
        }),
    },
    line3: {
      ...line3,
      ...(hasActive &&
        hasHover && {
          stroke: '#C62D70',
        }),
    },
    line4: {
      ...line4,
      ...(hasActive &&
        hasHover && {
          stroke: '#C62D70',
        }),
    },
    line5: {
      ...line5,
      ...(hasActive &&
        hasHover && {
          stroke: '#C62D70',
        }),
    },
    g3: {
      ...g3,
      stroke: '#C62D70',
    },
    activeLine: {
      ...activeLine,
      stroke: '#C62D70',
    },
    height: hasActive ? 35.5 : 32,
    width: 32,
  }
}

export const IconVariants = ({ variant, hasHover, hasActive, isMobile }) =>
  ({
    sela: {
      ...selaProps(hasHover, hasActive, isMobile),
      variant,
    },
    default: {
      ...defaultProps(hasHover, hasActive, isMobile),
      variant,
    },
    learn: {
      ...learnProps(hasHover, hasActive, isMobile),
      variant,
    },
    highSchool: {
      ...highSchoolProps(hasHover, hasActive, isMobile),
      variant,
    },
  }[variant])
