import styled from 'styled-components'

import checkSVG from '../../../../assets/images/checkBlack.svg'
import xSVG from '../../../../assets/images/xBlack.svg'

const BOLD = 'bold'
const NORMAL = 'normal'

function fontWeight(optionProps) {
  const { isUnevaluated, isSubmitted, isCorrect, isSelected } =
    optionProps || {}

  if (isUnevaluated) {
    return isSelected ? BOLD : NORMAL
  } else {
    return isSubmitted
      ? isCorrect
        ? BOLD
        : NORMAL
      : isSelected
      ? BOLD
      : NORMAL
  }
}

export const OptionItem = styled.label.attrs(
  ({
    disabled,
    isLast,
    isSelected,
    isCorrect,
    isSubmitted,
    isUnevaluated,
    theme: {
      colorVars: { multichoice },
    },
  }) => ({
    backgroundColor: isSelected
      ? multichoice.option.selectedBackground
      : multichoice.option.defaultBackground,
    color: multichoice.option.color,
    fontWeight: fontWeight({
      isUnevaluated,
      isSubmitted,
      isCorrect,
      isSelected,
    }),
    indicatorColor: isSelected
      ? multichoice.option.indicator.selected
      : multichoice.option.indicator.default,
    indicatorBoxShadow:
      isSelected && !disabled
        ? `inset 0 0 0 0.125em ${multichoice.option.defaultBackground}`
        : 'none',
    marginBottom: isLast ? '0em' : '1.6em',
  }),
)`
  align-items: center;
  border-radius: 0.15em;
  color: ${props => props.color};
  cursor: pointer;
  display: flex;
  margin: 0;
  margin-bottom: ${props => props.marginBottom};
  transition: background-color 0.2s ease-in;

  :hover {
    background-color: ${props =>
      props.theme.colorVars.multichoice.option.hover};
    input[type='radio'] + span::before {
      background-color: ${props =>
        props.theme.colorVars.multichoice.option.indicator.hover};
    }

    input[type='checkbox'] + span::before {
      background-color: ${props =>
        props.theme.colorVars.multichoice.option.indicator.hover};
    }

    span {
      font-weight: bold;
    }
  }

  input {
    margin: 0;
    opacity: 0;
    padding: 0;
    width: 0;
    height: 0;
  }

  input + span::before {
    border: 0.125em solid
      ${props => props.theme.colorVars.multichoice.option.color};
    content: ' ';
    line-height: 1.2em;
    display: block;
    width: 1.625em;
    height: 1.625em;
    box-sizing: border-box;
    background-color: ${props => props.indicatorColor};
    box-shadow: ${props => props.indicatorBoxShadow};
    margin: 0em 1.5625em;
  }

  input[type='radio'] + span::before {
    border-radius: 1em;
  }

  input[type='checkbox'] + span::before {
    border-radius: 0.25em;
  }

  span {
    align-items: center;
    display: flex;
    position: relative;
    font-weight: ${props => props.fontWeight};
  }
`

export const OptionItemSubmitted = styled(OptionItem)`
  pointer-events: none;

  span::before {
    border: none;
    background-color: ${props =>
      props.theme.colorVars.multichoice.option.defaultBackground};
    box-shadow: none;
  }

  input + span::before {
    border: 0.125em solid
      ${props => props.theme.colorVars.multichoice.option.indicator.disabled};
  }

  span {
    color: ${props =>
      props.theme.colorVars.multichoice.option.indicator.disabled};
  }
`

export const OptionItemCorrect = styled(OptionItemSubmitted)`
  background-color: ${props =>
    props.theme.colorVars.multichoice.option.defaultBackground};
  position: relative;

  span {
    color: ${props => props.theme.colorVars.multichoice.option.color};
    font-weight: bold;
  }

  input + span::before {
    background: ${props => props.theme.colorVars.multichoice.option.correct}
      no-repeat center/1.2em url(${checkSVG});
    width: 2.3125em;
    height: 2.3125em;
    border: 0.125em solid
      ${props => props.theme.colorVars.multichoice.option.color};
    margin: 0 1.2125em;
  }

  input[type='radio'] + span::before {
    border-radius: 2em;
  }

  &::after {
    content: ' ';
    height: 100%;
    width: 100%;
    position: absolute;
    border-radius: ${props => props.theme.borderRadius};
  }
`

export const OptionItemIncorrectMarkedCorrect = styled(OptionItemSubmitted)`
  span {
    color: ${props => props.theme.colorVars.multichoice.option.color};
  }

  background-color: ${props =>
    props.theme.colorVars.multichoice.option.wrongBackground};
  input + span::before {
    background: ${props => props.theme.colorVars.multichoice.option.incorrect}
      no-repeat center/1.2em url(${xSVG});
    width: 2.3125em;
    height: 2.3125em;
    border: 0.125em solid
      ${props => props.theme.colorVars.multichoice.option.color};
    margin: 0 1.2125em;
  }

  input[type='radio'] + span::before {
    border-radius: 2em;
  }
`

export const OptionItemCorrectMarkedIncorrect = styled(OptionItemSubmitted)`
  background-color: ${props =>
    props.theme.colorVars.multichoice.option.defaultBackground};

  span {
    color: ${props => props.theme.colorVars.multichoice.option.color};
    font-weight: bold;
  }

  input + span::before {
    background: ${props =>
        props.theme.colorVars.multichoice.option.correctMarkedIncorrect}
      no-repeat center/1.2em url(${checkSVG});
    width: 2.3125em;
    height: 2.3125em;
    border: 0.125em solid
      ${props => props.theme.colorVars.multichoice.option.color};
    margin: 0 1.2125em;
  }

  input[type='radio'] + span::before {
    border-radius: 2em;
  }
`
