import styled, { css } from 'styled-components'

import { borderRadius, colors } from '../../../styles/styleGuide'

export const Wrapper = styled.div`
  flex: 1;
  margin: 0 1em;
  text-transform: uppercase;
`

export const Slides = styled.div`
  display: flex;
  flex: 1;
`

export const Slide = styled.button`
  flex: 1;
  background-color: ${({ theme }) =>
    theme.colorVars.buttonNavigation.lighterGrey};
  margin: 0.15em;
  height: 1em;
  display: inline-block;
  box-sizing: border-box;
  border: 0.094rem solid ${colors.borderGray};
  transition: background-color 0.2s ease-in;
  :focus {
    outline: 3px groove ${colors.lightBlue};
  }
  border-radius: ${borderRadius};
  &:hover {
    cursor: pointer;
    background-color: ${({ isDoneSlide, theme }) =>
      isDoneSlide
        ? theme.colorVars.doneSlide.hoverState.backgroundColor
        : theme.colorVars.buttonNavigation.darkestBlue};
  }

  ${({ completed }) =>
    completed &&
    css`
      background-color: ${({ isDoneSlide, theme }) =>
        isDoneSlide
          ? theme.colorVars.doneSlide.activeState.backgroundColor
          : theme.colorVars.buttonNavigation.grey};
      text-align: center;
      color: white;
    `}

  ${({ current }) =>
    current &&
    css`
      background-color: ${({ isDoneSlide, theme }) =>
        isDoneSlide
          ? theme.colorVars.doneSlide.activeState.backgroundColor
          : theme.colorVars.buttonNavigation.orange};
      &:hover {
        background-color: ${({ isDoneSlide, theme }) =>
          isDoneSlide
            ? theme.colorVars.doneSlide.hoverState.backgroundColor
            : theme.colorVars.buttonNavigation.orange};
        cursor: pointer;
      }
    `}
`

export const Name = styled.div`
  flex: 1;
  text-align: center;
`
