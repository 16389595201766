/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

export default () => (
  <svg
    height="18"
    viewBox="0 0 23 18"
    width="23"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M0 0.078545L14.6417042 0.078545 14.6417042 13.4166667 0 13.4166667z"
        id="path-1"
      />
    </defs>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g>
        <g transform="translate(0 3.755)">
          <mask fill="#fff" id="mask-2">
            <use xlinkHref="#path-1" />
          </mask>
          <path
            d="M13.497 1.303a3.813 3.813 0 00-5.2-.357c-.135.11-.267.225-.39.356v.001l-.578.61-.009.01-.585-.627a3.815 3.815 0 00-5.591 0c-1.526 1.623-1.524 4.21 0 5.834l5.699 6.083c.244.26.653.273.913.028l.03-.028 5.711-6.077c1.526-1.622 1.526-4.21 0-5.833"
            fill="currentColor"
            mask="url(#mask-2)"
          />
        </g>
        <path
          d="M21.615 1.39A4.324 4.324 0 0018.438.007a4.32 4.32 0 00-3.175 1.382l-.198.208-.2-.215A4.329 4.329 0 0011.685 0a4.32 4.32 0 00-3.174 1.382 4.751 4.751 0 00-1.24 2.59c.017.016.036.029.053.045a4.982 4.982 0 011.28-.858c.162-.38.387-.737.68-1.05a3.277 3.277 0 014.807-.001l.969 1.038.974-1.03a3.272 3.272 0 012.404-1.047 3.27 3.27 0 012.403 1.047c1.322 1.405 1.322 3.691 0 5.096l-5.707 6.07c-.028.027-.062.035-.081.03a.11.11 0 01-.08-.035l-1.068-1.14-.728.775 1.022 1.09v.001c.215.23.506.361.82.37l.038.002c.3 0 .583-.113.802-.318l5.756-6.118a4.787 4.787 0 000-6.55"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
)
