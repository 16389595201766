/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'
import { string } from 'prop-types'
const ArrowIconClosed = ({ fill, ...rest }) => (
  <svg {...rest}>
    <g
      fill={fill}
      fillRule="evenodd"
      stroke="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
    >
      <polyline
        points="5 -3.04112291e-12 10 5 5 10"
        stroke="#757575"
        strokeWidth="2.5"
        transform="translate(7.500000, 5.000000) rotate(-270.000000) translate(-7.500000, -5.000000) "
      />
    </g>
  </svg>
)
ArrowIconClosed.defaultProps = {
  height: '10px',
  width: '15px',
  fill: 'none',
  viewBox: '0 0 15 10',
}
ArrowIconClosed.propTypes = {
  fill: string,
  height: string,
  viewBox: string,
  width: string,
}
export default ArrowIconClosed
