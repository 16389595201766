import React from 'react'

function StepTwoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <circle cx="10" cy="10" r="10" fill="currentColor" />
        <g fill="#FFF" fillRule="nonzero" transform="translate(6.616 5.308)">
          <path d="M6.723 2.632v-.013C6.723.939 5.278 0 3.446 0 1.56 0 .017.886.017 2.936v.034h1.482v-.034c0-1.054.7-1.615 1.84-1.615 1.097 0 1.71.52 1.71 1.343v.013C5.048 5.211 0 4.553 0 8.927v.265h6.713V7.868H1.785c.903-2.335 4.938-2.045 4.938-5.236z" />
        </g>
      </g>
    </svg>
  )
}

export default StepTwoIcon
