import React from 'react'

import PropTypes from 'prop-types'

import DragQuestionDropZone from '../DragQuestionDropZone'
import { findDraggablesThatMatchDropZoneID } from '../utils'

import { DROP_ZONE_ARIA_LABEL_PREFIX, DROP_ZONE_KEY_PREFIX } from './constants'
import { DropZonesContainer } from './style'

const DragQuestionDropZonesComponent = ({
  canSubmitForm,
  draggables,
  dropZones,
  handleDrop,
  isOverDropZoneId,
  isSubmitted,
  onKeyDown,
  placeholderHeight,
}) => {
  const dropZonesJsx = dropZones.map((dropZone, index) => {
    const ariaLabel = DROP_ZONE_ARIA_LABEL_PREFIX + index
    const isLast = index === dropZones.length - 1
    const key = DROP_ZONE_KEY_PREFIX + index
    const { currentDraggables = [], id: dropZoneId, label } = dropZone || {}
    const isOver = dropZoneId === isOverDropZoneId

    //map over id list of currentdraggables. Return a draggable object in its place
    // in matchingDraggables if the draggable.id matches
    const matchingDraggables = findDraggablesThatMatchDropZoneID(
      currentDraggables,
      draggables,
    )

    return (
      <DragQuestionDropZone
        ariaLabel={ariaLabel}
        canSubmitForm={canSubmitForm}
        draggables={matchingDraggables}
        handleDrop={handleDrop}
        id={dropZoneId}
        index={index}
        isLast={isLast}
        isOver={isOver}
        isSubmitted={isSubmitted}
        key={key}
        label={label}
        onKeyDown={onKeyDown}
        placeholderHeight={placeholderHeight}
      />
    )
  })

  return <DropZonesContainer>{dropZonesJsx}</DropZonesContainer>
}

DragQuestionDropZonesComponent.propTypes = {
  canSubmitForm: PropTypes.func,
  draggables: PropTypes.array,
  dropZones: PropTypes.array,
  handleDrop: PropTypes.func,
  isOverDropZoneId: PropTypes.string,
  isSubmitted: PropTypes.bool,
  onKeyDown: PropTypes.func,
  placeholderHeight: PropTypes.number,
}

DragQuestionDropZonesComponent.defaultProps = {
  canSubmitForm() {},
  draggables: [],
  dropZones: [],
  handleDrop() {},
  onKeyDown() {},
}

export default DragQuestionDropZonesComponent
