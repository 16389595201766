import styled from 'styled-components/native'

export const Frame = styled.View.attrs(({ height, width }) => ({
  frameWidth: width,
  frameHeight: height,
}))`
  height: ${props => props.frameHeight}px;
  justify-content: center;
  width: ${props => props.frameWidth}px;
`
