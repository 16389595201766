import {
  CONTENTFUL_URL,
  LOAD_CONTENTFUL_IMAGES_PROGRESSIVELY,
} from '../lib/config'

import { isWebpSupported } from './browserHelper'

const DRAG_LABEL_TO_IMAGE = '::drag-label'
const DRAG_QUESTION = 'DragQuestion'
const DRAG_THE_WORD = '::drag-word'
const ERROR_LINKED_OBJECT_MISSING =
  'Error: Linked object was not retrieved. Ensure the layout has been published.'
const ERROR_NO_LAYOUT =
  'Error: The slide contains no layout. Ensure a layout is published for this slide.'
const FL = 'fl'
const FORMAT = 'fm'
const HEIGHT = 'h'
const HIGH_QUALITY = '80'
const IMAGE = 'image'
const INTERACTIVE = 'interactive'
const LINK = 'Link'
const PROGRESSIVE = 'progressive'
const QUALITY = 'q'
const VIDEO = 'video'
const WEBP = 'webp'
const WIDTH = 'w'

//Contentful section helpers

export const getContentfulIdForSection = section => {
  const { sys: sectionSys } = section || {}
  const { id } = sectionSys || {}

  return id
}

const getTypeOfSection = section => {
  const { sys } = section || {}
  const { type } = sys || {}

  return type
}

export const isSectionOfType = (section, type) => {
  const sectionType = getTypeOfSection(section)
  const sectionIsType = sectionType === type

  return sectionIsType
}

//Contentful slide helpers

const getFieldsForSlide = slide => {
  const { fields } = slide || {}

  return fields
}

export const getContentfulIdForSlide = slide => {
  const { sys } = slide || {}
  const { id } = sys || {}

  return id
}

const typeOfSlide = slide => {
  const { sys } = slide || {}
  const { type } = sys || {}

  return type
}

export const isSlideOfType = (slide, type) => {
  const slideType = typeOfSlide(slide)
  const slideIsType = slideType === type

  return slideIsType
}

export const getStylesForSlide = slide => {
  const slideFields = getFieldsForSlide(slide) || {}
  const { layout } = slideFields || {}
  const layoutFields = getFieldsForLayout(layout)
  const { slideStyle } = layoutFields || {}
  const { backgroundImage } = layoutFields || {}
  const { fields: slideStyleFields } = slideStyle || {}
  const { foregroundColor, foregroundOpacity } = slideStyleFields || {}
  let { backgroundColor, fontColor } = slideStyleFields || {}
  const { fields: backgroundImageFields } = backgroundImage || {}
  const { description: altText, file: backgroundImageFile } =
    backgroundImageFields || {}
  const { url: backgroundImageUrl } = backgroundImageFile || {}

  backgroundColor = formatContentfulColorToString(backgroundColor)
  fontColor = formatContentfulColorToString(fontColor)

  const formattedForegroundColor = formatToRgbaString(
    foregroundColor,
    foregroundOpacity,
  )

  return {
    altText,
    backgroundImageUrl,
    backgroundColor,
    fontColor,
    foregroundColor: formattedForegroundColor,
  }
}

export const getLayoutForSlide = slide => {
  const slideFields = getFieldsForSlide(slide) || {}
  const { layout } = slideFields || {}

  return layout
}

export const getIsCompletionRequiredForSlide = slide => {
  const fields = getFieldsForSlide(slide) || {}
  const { dontRequireCompletion: completeNotRequired } = fields || {}
  const isCompletionRequired = !completeNotRequired

  return isCompletionRequired
}

//Contentful layout helpers

const getFieldsForLayout = layout => {
  const { fields } = layout || {}

  return fields
}

export const getContentfulIdForLayout = layout => {
  const { sys } = layout || {}
  const { id } = sys || {}

  return id
}

export const getTitleForLayout = layout => {
  const layoutFields = getFieldsForLayout(layout) || {}
  const { header: title } = layoutFields || {}

  return title
}

export const getElementsForLayout = layout => {
  const layoutFields = getFieldsForLayout(layout) || {}
  const { column1, column2, column3 } = layoutFields || {}
  const elements = [column1, column2, column3].filter(c => c != null)

  return elements
}

export const getIsVideoForLayout = layout => {
  const elements = getElementsForLayout(layout)
  const numOfElements = getNumOfElementsForLayout(layout)

  let firstContent = elements[0] || []
  firstContent = firstContent[0]
  const isVideo =
    numOfElements === 1 && firstContent.sys.contentType.sys.id === VIDEO

  return isVideo
}

export const getIsImageForLayout = layout => {
  const elements = getElementsForLayout(layout)
  const numOfElements = getNumOfElementsForLayout(layout)

  let firstContent = elements[0] || []
  firstContent = firstContent[0]
  const isImage =
    numOfElements === 1 && firstContent.sys.contentType.sys.id === IMAGE

  return isImage
}

//Three types of interactives come from H5P as "DragQuestion". Find out if it is of type Drag the Word.
export const getIsDragTheWordInteractiveForLayout = layout => {
  const elements = getElementsForLayout(layout)
  const numOfElements = getNumOfElementsForLayout(layout)
  const firstContent = elements[0] || []
  const firstContentFirstModule = firstContent[0] || {}
  const isFirstModuleDragQuestion = isModuleInteractiveOfType(
    firstContentFirstModule,
    DRAG_QUESTION,
  )
  const { fields, sys: firstContentSys } = firstContentFirstModule || {}
  const { contentType: firstContentType } = firstContentSys || {}
  const { sys: firstContentTypeSys } = firstContentType || {}
  const { id: firstContentId } = firstContentTypeSys || {}
  const { title: firstContentTitle } = fields || {}
  const isDragTheWordInteractive =
    numOfElements === 1 &&
    isFirstModuleDragQuestion &&
    firstContentId === INTERACTIVE &&
    firstContentTitle.includes(DRAG_THE_WORD)

  return isDragTheWordInteractive
}

//Three types of interactives come from H5P as "DragQuestion". Find out if it is of type Drag Label to Image.
export const getIsDragLabelToImageInteractiveForLayout = layout => {
  const elements = getElementsForLayout(layout)
  const numOfElements = getNumOfElementsForLayout(layout)
  const firstContent = elements[0] || []
  const firstContentFirstModule = firstContent[0] || {}
  const isFirstModuleDragQuestion = isModuleInteractiveOfType(
    firstContentFirstModule,
    DRAG_QUESTION,
  )
  const { fields, sys: firstContentSys } = firstContentFirstModule || {}
  const { contentType: firstContentType } = firstContentSys || {}
  const { sys: firstContentTypeSys } = firstContentType || {}
  const { id: firstContentId } = firstContentTypeSys || {}
  const { title: firstContentTitle } = fields || {}
  const isDragLabelToImageInteractive =
    numOfElements === 1 &&
    isFirstModuleDragQuestion &&
    firstContentId === INTERACTIVE &&
    firstContentTitle.includes(DRAG_LABEL_TO_IMAGE)

  return isDragLabelToImageInteractive
}

const getHeroImageUrlForLayout = layout => {
  const layoutFields = getFieldsForLayout(layout) || {}
  const { heroImage } = layoutFields || {}
  const { fields: heroImageFields } = heroImage || {}
  const { image } = heroImageFields || {}
  const heroImageUrl = getImageUrlFromImageObject(image)

  return heroImageUrl
}

export const getImageUrlFromImageObject = image => {
  const { fields } = image || {}
  const { file } = fields || {}
  const { url } = file || {}

  return url
}

const getHeroImageAltTextForLayout = layout => {
  const layoutFields = getFieldsForLayout(layout) || {}
  const { heroImage } = layoutFields || {}
  const { fields: heroImageFields } = heroImage || {}
  const { accessibility } = heroImageFields || {}
  const { fields: accessibilityFields } = accessibility || {}
  const { altTextShort: altText } = accessibilityFields || {}

  return altText
}

const getHeroSizeForLayout = layout => {
  const layoutFields = getFieldsForLayout(layout) || {}
  const { heroSize } = layoutFields || {}

  return heroSize
}

const getHeroPositionForLayout = layout => {
  const layoutFields = getFieldsForLayout(layout) || {}
  const { heroPosition } = layoutFields || {}

  return heroPosition
}

//TODO: Update tests
export const getHeroDataForLayout = layout => {
  const url = getHeroImageUrlForLayout(layout)
  const altText = getHeroImageAltTextForLayout(layout)
  const size = getHeroSizeForLayout(layout)
  const position = getHeroPositionForLayout(layout)
  const heroData = {
    altText,
    position,
    size,
    url,
  }

  return heroData
}

export const getNumOfElementsForLayout = layout => {
  const elements = getElementsForLayout(layout) || []
  const numOfElements = elements.length

  return numOfElements
}

export const isLayoutOfType = (layout, type) => {
  const { sys: sysLayout } = layout || {}
  const { type: layoutType } = sysLayout || {}
  const layoutIsType = layoutType === type

  return layoutIsType
}

//Module helpers

const getFieldsForModule = module => {
  const { fields } = module || {}
  return fields
}

export const isModuleOfType = (module, type) => {
  const { sys: moduleSys } = module || {}
  const { contentType } = moduleSys || {}
  const { sys: contentSys } = contentType || {}
  const { id: moduleId } = contentSys || {}
  const moduleIsType = moduleId === type

  return moduleIsType
}

export const isModuleInteractiveOfType = (module, type) => {
  const fields = getFieldsForModule(module)
  const { interactiveType } = fields || {}
  const moduleIsInteractiveOfType = interactiveType === type

  return moduleIsInteractiveOfType
}

export const getImageModuleDimensions = module => {
  const { fields: elementFields } = module || {}
  const { image } = elementFields || {}
  const { fields: imageFields } = image || {}
  const { file } = imageFields || {}
  const { details } = file || {}
  const { image: imageDetails } = details || {}

  return imageDetails || {}
}

export const getHeroImageModuleDimensions = module => {
  const fields = getFieldsForModule(module) || {}
  const { heroImage } = fields || {}
  const { fields: heroImageFields } = heroImage || {}
  const { image: heroImageFieldsImage } = heroImageFields || {}
  const { fields: imageFields } = heroImageFieldsImage || {}
  const { file } = imageFields || {}
  const { details } = file || {}
  const { image: imageDetails } = details || {}

  return imageDetails || {}
}

export const getVideoModuleAzureVideoSource = module => {
  const fields = getFieldsForModule(module)
  const { azureVideoUrl } = fields || {}

  return azureVideoUrl
}

export const getVideoModuleVttSource = module => {
  const fields = getFieldsForModule(module)
  const { vttFile = [] } = fields || {}

  if (vttFile.length === 0) {
    return
  }

  const vttSource = vttFile[0].fields?.file?.url

  return vttSource
}

export const getVideoModuleVttPauseSource = module => {
  const fields = getFieldsForModule(module)
  const pauseSource = fields?.vttPauseFile?.fields?.file?.url
  return pauseSource
}

export const getVideoModuleVttPauseExtendedSource = module => {
  const fields = getFieldsForModule(module)
  const pauseSource = fields?.vttPauseFileExtended?.fields?.file?.url
  return pauseSource
}

export const getVideoModulePosterSource = module => {
  const fields = getFieldsForModule(module)
  const posterSource = fields?.posterImage?.fields?.file?.url

  return posterSource
}

export const getVideoModulePosterField = module => {
  const fields = getFieldsForModule(module)
  const posterField = fields?.posterImage

  return posterField
}

export const getVideoModuleTranscriptSource = module => {
  const fields = getFieldsForModule(module)
  const transcript = fields?.transcript?.fields?.file?.url
  return transcript
}

export const getVideoModuleExtendedAudioTranscriptSource = module => {
  const fields = getFieldsForModule(module)
  const extendedAudioTranscript = fields?.transcriptExtended?.fields?.file?.url
  return extendedAudioTranscript
}

//Contentful color helpers

const formatToRgbaString = (
  contentfulForegroundColor,
  contentfulForegroundOpacity,
) => {
  if (
    contentfulForegroundColor == null ||
    contentfulForegroundOpacity === '0.0'
  ) {
    return
  }

  if (contentfulForegroundColor && contentfulForegroundOpacity) {
    const colors = contentfulForegroundColor.match(
      /([0-9]+),\s?([0-9]+),\s?([0-9]+)/,
    )[0]

    return `rgba(${colors},${contentfulForegroundOpacity});`
  }
}

//Ex: "K5 LtGreen - #BEE293;" -> "#BEE293"
const formatContentfulColorToString = colorString => {
  if (!colorString) {
    return
  }

  return colorString.match(/#(?:[0-9a-fA-F]{3}){1,2}/)[0]
}

// Contentful image helpers

const getProgressiveParam = loadProgressive => {
  const shouldLoadProgressive =
    loadProgressive != null
      ? loadProgressive
      : LOAD_CONTENTFUL_IMAGES_PROGRESSIVELY
  const progressiveParam = shouldLoadProgressive ? `${FL}=${PROGRESSIVE}` : null

  return progressiveParam
}

const getDimensionParam = (source, maxWidth, maxHeight) => {
  if (maxWidth && maxHeight) {
    //Providing one dimension, either w or h, results in Contentful auto supplying the other dimension to mantain original aspect ratio.
    //With the Contentful images API, if w or h is over the original image's dimensions, the image is sent in it's original size.
    const dimensionParam =
      maxWidth >= maxHeight ? `${WIDTH}=${maxWidth}` : `${HEIGHT}=${maxHeight}`

    return dimensionParam
  }
  return null
}

const getQualityParam = () => {
  //To get the best mix of image quality and low file size, we are using a quality level of 80%.
  const qualityParam = isWebpSupported ? `${QUALITY}=${HIGH_QUALITY}` : null

  return qualityParam
}

const getWebpParam = () => {
  const webpParam = isWebpSupported && `${FORMAT}=${WEBP}`

  return webpParam
}

export const imageSourceWithOptions = (
  source,
  maxWidth,
  maxHeight,
  loadProgressive,
) => {
  if (!source) {
    return source
  }

  const params = []
  const progressiveParam = getProgressiveParam(loadProgressive)
  const dimensionParam = getDimensionParam(source, maxWidth, maxHeight)
  const webpParam = getWebpParam()
  const qualityParam = getQualityParam()
  let sourceWithParams = source

  progressiveParam && params.push(progressiveParam)
  dimensionParam && params.push(dimensionParam)
  webpParam && params.push(webpParam)
  qualityParam && params.push(qualityParam)

  for (let paramIndex = 0; paramIndex < params.length; paramIndex++) {
    const param = params[paramIndex]
    const isFirstParam = paramIndex === 0
    sourceWithParams += isFirstParam ? `?` : `&`
    sourceWithParams += param
  }

  return sourceWithParams
}

//Error helpers

const contentfulError = message => ({
  message,
})

const contentfulLinkedError = layout => {
  const layoutContentfulId = getContentfulIdForLayout(layout)
  const contentfulUrl = `${CONTENTFUL_URL}${layoutContentfulId}`
  const error = {
    message: ERROR_LINKED_OBJECT_MISSING,
    url: contentfulUrl,
  }

  return error
}

export const checkLayoutForErrors = layout => {
  const isLink = isLayoutOfType(layout, LINK)
  let error

  //If no layout, make the error a no layout error
  error = layout ? error : contentfulError(ERROR_NO_LAYOUT)

  //If there is a layout but it's type is "Link", make the error be a link to itself in Contentful
  error = isLink ? contentfulLinkedError(layout) : error

  return error
}
