import SessionTimeManager from '../lib/SessionTimeManager'
import { updateCmiLmsSessionTime } from '../lib/state/actions/cmi'
import { toggleSession } from '../lib/state/actions/player'
import { LMS_TERMINATORS } from '../lib/state/constants'
import { DURATION_ZERO_ISO } from '../utils/dateHelper'

import { sendTerminateBeacon } from './lmsHelper'

let playerSessionTimeManager

// Sessions are instantiated when a lesson is visible, and ended when
// a lesson is hidden. A poll runs while a session is active (every 15
// seconds by default) and updates session_time on the LMS. As soon as
// a session ends (when the lesson is hidden or the tab is closed), a
// terminate beacon is dispatched, which triggers the LMS to add session_time
// to total_time.
const createPlayerSessionTimeManager = store => {
  if (!store) {
    console.error(
      'Failed to initialize session time manager. No store provided.',
    )
    return
  }

  const startCallback = sessionStartDateIso => {
    store.dispatch(
      updateCmiLmsSessionTime(DURATION_ZERO_ISO, LMS_TERMINATORS.NONE),
    )
    store.dispatch(toggleSession(true, sessionStartDateIso))
  }
  const stopCallback = (durationIso, options) => {
    const { terminateWithApi } = options || {}

    !terminateWithApi && sendTerminateBeacon()
    terminateWithApi &&
      store.dispatch(
        updateCmiLmsSessionTime(durationIso, LMS_TERMINATORS.API_CONNECTOR),
      )
  }
  const intervalCallback = duration =>
    store.dispatch(updateCmiLmsSessionTime(duration, LMS_TERMINATORS.NONE))
  const sessionTimeManager = new SessionTimeManager(
    startCallback,
    stopCallback,
    intervalCallback,
  )

  return sessionTimeManager
}

// Get the instance of the player session time manager. If one doesn't exist,
// create it.
export const getPlayerSessionTimeManager = store => {
  if (!store) {
    return playerSessionTimeManager
  }

  if (!playerSessionTimeManager) {
    playerSessionTimeManager = createPlayerSessionTimeManager(store)
  }

  return playerSessionTimeManager
}
