import styled from 'styled-components'

import ssLogo from '../../../assets/images/logoSecondStep.svg'
import { colors } from '../../styles/styleGuide'

export const Brand = styled.div.attrs({
  style: {
    backgroundImage: `url(${ssLogo})`,
  },
})`
  background-color: ${colors.brand};
  background-position: 55% 32%;
  background-repeat: no-repeat;
  background-size: 2.1em;
  height: 100%;
  width: 2.6em;
`
export const MarkDoneButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 70%;
  justify-content: center;
  margin-left: 0.75rem;
`

export const Wrapper = styled.div.attrs(
  ({
    theme: {
      colorVars: { titleBar: styles },
    },
  }) => ({
    style: {
      height: `${styles.height}rem`,
    },
  }),
)`
  align-items: center;
  background-color: white;
  box-shadow: 0px -1.8em 0.7em 1.7em #000;
  display: flex;
  font-size: 1em;
  justify-content: space-between;
  user-select: none;
  z-index: 1;

  -webkit-user-select: none;
`

export const FullScreenButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 70%;
  justify-content: center;
  margin-left: 0.75rem;
  margin-right: 1.25rem;
`
