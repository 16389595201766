import styled from 'styled-components'

export const DragWordStyleContainer = styled.div.attrs(
  ({
    theme: {
      colorVars: { dragWord: colors },
    },
  }) => ({
    background: colors.container.background,
  }),
)`
  height: 100%;
  width: 100%;
`

export const DragWordDropZoneContainer = styled.div`
  height: 100%;
  width: 100%;
`
