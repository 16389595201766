import React from 'react'

import PropTypes from 'prop-types'

import LayoutTitle from '../LayoutTitle'

import { EMPTY_STRING, ORIGIN_LOCATION } from './constants'
import DragQuestionBody from './DragQuestionBody'
import DragQuestionDraggables from './DragQuestionDraggables'
import DragQuestionDropZones from './DragQuestionDropZones'
import DragQuestionEvaluationText from './DragQuestionEvaluationText'
import DragQuestionResetButton from './DragQuestionResetButton'
import DragQuestionSubmitButton from './DragQuestionSubmitButton'
import {
  BodyTextContainer,
  ColumnContainer,
  EvaluationContainer,
  InteractiveContainer,
  LeftColumn,
  RightColumn,
} from './style'

const DragQuestionComponent = ({
  bodyText,
  canSubmitForm,
  draggables,
  handleDrop,
  headerText,
  id,
  isOverDropZoneId,
  isResetButtonDisabled,
  isSubmitted,
  onKeyDown,
  onReset,
  onSubmit,
  placeholderHeight,
  resetText,
  showErrorMessage,
  submitText,
}) => {
  const headerProps = {
    title: headerText,
  }
  const bodyProps = {
    text: bodyText,
  }
  const matchingDraggables = draggables.filter(
    draggable => draggable.location === ORIGIN_LOCATION,
  )
  const draggablesProps = {
    canSubmitForm,
    dataTestId: 'drop-zone-origin',
    draggables: matchingDraggables,
    id,
    isSubmitted,
    onKeyDown,
  }
  const dropZonesProps = {
    canSubmitForm,
    handleDrop,
    id,
    isOverDropZoneId,
    onKeyDown,
    placeholderHeight,
  }
  const submitButtonProps = {
    disabled: isSubmitted,
    onSubmit,
    submitText,
  }
  const resetButtonProps = {
    disabled: isResetButtonDisabled,
    onReset: onReset,
    resetText,
  }
  const evaluationTextProps = {
    showErrorMessage,
  }

  return (
    <InteractiveContainer>
      <ColumnContainer>
        <LeftColumn>
          <LayoutTitle {...headerProps} />
          <BodyTextContainer>
            <DragQuestionBody {...bodyProps} />
          </BodyTextContainer>
          <DragQuestionDraggables {...draggablesProps} />
        </LeftColumn>

        <RightColumn>
          <DragQuestionDropZones {...dropZonesProps} />
        </RightColumn>
      </ColumnContainer>

      <EvaluationContainer>
        <DragQuestionEvaluationText {...evaluationTextProps} />
        <DragQuestionResetButton {...resetButtonProps} />
        <DragQuestionSubmitButton {...submitButtonProps} />
      </EvaluationContainer>
    </InteractiveContainer>
  )
}

DragQuestionComponent.propTypes = {
  bodyText: PropTypes.string,
  canSubmitForm: PropTypes.func,
  draggables: PropTypes.array,
  handleDrop: PropTypes.func,
  headerText: PropTypes.string,
  id: PropTypes.string,
  isOverDropZoneId: PropTypes.string,
  isResetButtonDisabled: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  onKeyDown: PropTypes.func,
  onReset: PropTypes.func,
  onSubmit: PropTypes.func,
  placeholderHeight: PropTypes.number,
  resetText: PropTypes.string,
  showErrorMessage: PropTypes.bool,
  submitText: PropTypes.string,
}

DragQuestionComponent.defaultProps = {
  // Warning: Setting a default function for canSubmitForm will break things.
  bodyText: EMPTY_STRING,
  draggables: [],
  handleDrop() {},
  onReset() {},
  onSubmit() {},
  onKeyDown() {},
}

export default DragQuestionComponent
