import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledCheckBoxAnswerText,
  StyledCheckBoxLabel,
  StyledCheckBoxWrapper,
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
  Icon,
} from './component.styles'

const FormInputCheckBox = props => {
  const { answerText, checked, dataTestId, id, onChange } = props || {}
  const checkBoxProps = { id, checked, dataTestId, onChange, value: answerText }

  return (
    <StyledCheckBoxWrapper checked={checked}>
      <CheckboxContainer>
        <HiddenCheckbox key="hidden-checkbox" {...checkBoxProps} />
        <StyledCheckbox checked={checked} key="styled-checkbox">
          <Icon />
        </StyledCheckbox>
      </CheckboxContainer>

      <StyledCheckBoxLabel dataTestId={`${dataTestId}-label`} htmlFor={id}>
        <StyledCheckBoxAnswerText>{answerText}</StyledCheckBoxAnswerText>
      </StyledCheckBoxLabel>
    </StyledCheckBoxWrapper>
  )
}

FormInputCheckBox.propTypes = {
  answerText: PropTypes.string.isRequired,
  customTheme: PropTypes.object,
  dataTestId: PropTypes.string,
  id: PropTypes.string.isRequired,
}

export default FormInputCheckBox
