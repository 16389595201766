import React from 'react'
import PropTypes from 'prop-types'
import { IconVariants } from './styleMixin'
import { ButtonStyled } from './component.styles'
import { useBreakpoints } from '../../utils/mediaHelper'

export const Adornment = ({
  hasHover,
  hasActive,
  variant,
  rect1,
  rect2,
  line,
  path1,
  g1,
  height,
  width,
}) => {
  return (
    <svg
      height={`${height}px`}
      viewBox={`0 0 ${width} ${height}`}
      width={`${width}px`}
    >
      <g
        fill="none"
        fillRule="evenodd"
        key={Math.random()}
        stroke="none"
        strokeWidth="1"
      >
        <g key={Math.random()} transform="translate(-1276.000000, -53.000000)">
          {variant === 'default ' && (
            <>
              {rect1 && <rect {...rect1} />}
              {!hasHover && <image height="37" width="75" x="1291" y="53" />}
              {rect2 && <rect {...rect2} />}
            </>
          )}
          <g
            id="Button/Dark/AudioDescription/Active"
            key={Math.random()}
            transform="translate(1276.000000, 53.000000)"
          >
            {hasActive && <line id="Active-Line" {...line} />}
            <g fillRule="nonzero" id="Group" key={Math.random()}>
              <path {...path1} />
              <g key={Math.random()} {...g1}>
                <path d="M17.8104395,0.219567323 L18.2867279,0.219567323 C18.2867279,0.219567323 20.0209063,2.7042299 19.9857953,4.64158863 C19.9216795,8.30319664 18.3340514,9.7933484 18.3340514,9.7933484 L17.8180723,9.7933484 C17.8180723,9.7933484 19.3354784,7.52018082 19.2851017,4.90636099 C19.2270922,2.37487892 17.8104395,0.219567323 17.8104395,0.219567323 Z" />
                <path d="M19.8087137,0.219567323 C19.8087137,0.219567323 21.367337,2.3522764 21.3352792,4.99031321 C21.297115,7.72360349 19.8087137,9.788505 19.8087137,9.788505 L20.3292725,9.788505 C20.3292725,9.788505 21.8787365,8.18372619 22.0084945,5.29867614 C22.147412,2.25217953 20.3292725,0.219567323 20.3292725,0.219567323 L19.8087137,0.219567323 Z" />
                <path d="M21.8588911,0.219567323 L22.3321264,0.219567323 C22.3321264,0.219567323 23.9579187,2.17952858 23.9991359,4.99031321 C24.0434063,8.01259283 22.3718171,9.788505 22.3718171,9.788505 L21.810041,9.788505 C22.790374,8.40011545 23.3252725,6.71659783 23.3366065,4.98385534 C23.3564519,2.68969971 21.8588911,0.219567323 21.8588911,0.219567323 Z" />
                <path d="M6.7397866,0 L0,10 L3.37370971,9.99031321 L4.18736911,8.71972877 L7.23286725,8.71972877 L7.27255795,9.98869874 L9.56240617,9.98869874 L9.56240617,0 L6.7397866,0 Z M5.41625433,6.49015176 L7.32598774,3.35808847 L7.35193936,6.49015176 L5.41625433,6.49015176 Z" />
                <path d="M13.9070115,0.0129157249 L10.1974574,0.0129157249 L10.1974574,9.97255408 L13.8810599,9.97255408 C13.8810599,9.97255408 17.9295116,9.29932192 17.8852412,4.9483371 C17.8424973,0.820148531 13.9070115,0.0129157249 13.9070115,0.0129157249 Z M12.3804461,7.41039716 L12.4231899,2.58314498 C12.4231899,2.58314498 15.1572687,2.46044559 15.1603318,4.84339684 C15.1664281,7.78495318 12.3804461,7.41039716 12.3804461,7.41039716 Z" />
              </g>
            </g>
          </g>
          {variant === 'default' && hasHover && (
            <g
              key={Math.random()}
              transform="translate(1291.500000, 76.500000)"
            >
              <image height="19" width="18" x="0" y="0" />
            </g>
          )}
        </g>
      </g>
    </svg>
  )
}

const AudioDescriptionButton = ({
  children,
  ariaLabel,
  hasLeftAdornment,
  hasResposive,
  ...rest
}) => {
  const { isSm: isMobile } = useBreakpoints(hasResposive)
  return (
    <ButtonStyled {...rest} aria-label={ariaLabel}>
      {hasLeftAdornment && (
        <Adornment key={ariaLabel} {...IconVariants({ ...rest, isMobile })} />
      )}
      {children}
      {!hasLeftAdornment && (
        <Adornment key={ariaLabel} {...IconVariants({ ...rest, isMobile })} />
      )}
    </ButtonStyled>
  )
}

const rectShape = PropTypes.shape({
  color: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
})
const variantShape = PropTypes.oneOf(['default', 'sela', 'learn', 'highSchool'])

Adornment.propTypes = {
  g1: PropTypes.shape({
    fill: PropTypes.string,
    transform: PropTypes.string,
  }),
  hasActive: PropTypes.bool,
  hasHover: PropTypes.bool,
  height: PropTypes.number,
  line: PropTypes.shape({
    stroke: PropTypes.string,
    strokeLinecap: PropTypes.string,
    strokeWidth: PropTypes.number,
    x1: PropTypes.number,
    x2: PropTypes.number,
    y1: PropTypes.number,
    y2: PropTypes.number,
  }),
  path1: PropTypes.shape({
    d: PropTypes.string,
    fill: PropTypes.string,
    stroke: PropTypes.string,
  }),
  rect1: rectShape.isRequired,
  rect2: rectShape,
  variant: variantShape,
  width: PropTypes.number,
}

AudioDescriptionButton.defaultProps = {
  ariaLabel: '',
  variant: 'default',
  height: 30,
  width: 30,
  hasHover: false,
  hasActive: false,
  children: null,
  hasLeftAdornment: false,
  hasResposive: true,
}
AudioDescriptionButton.propTypes = {
  ariaLabel: PropTypes.string,
  children: PropTypes.node,
  hasActive: PropTypes.bool,
  hasHover: PropTypes.bool,
  hasLeftAdornment: PropTypes.bool,
  hasResposive: PropTypes.bool,
  variant: variantShape,
}

export default AudioDescriptionButton
