const NotificationBellIcon = () => {
  return (
    <svg
      width="22px"
      height="22px"
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="ss_notificationbell"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="bell"
          transform="translate(1.3528, 0)"
          fill="#04817C"
          fillRule="nonzero"
        >
          <path
            d="M7.67742607,4 C7.59791057,4 7.52054001,3.97431734 7.45692761,3.92680695 C7.36438961,3.85769282 7.30992864,3.749225 7.30992864,3.63403477 L7.30992864,2.32753891 C7.30992864,1.49598936 7.75541236,0.727605873 8.47857044,0.311831088 C9.20172853,-0.103943696 10.092696,-0.103943696 10.8158541,0.311831088 C11.5390122,0.727605873 11.9844959,1.49598936 11.9844959,2.32753891 L11.9844959,3.63037512 C11.9844959,3.74556535 11.930035,3.85403317 11.837497,3.9231473 C11.744067,3.99344222 11.6225899,4.01518974 11.5104243,3.98170174 C10.2954699,3.61570157 8.99895468,3.61570157 7.78400032,3.98170174 C7.74969163,3.99345623 7.71370663,3.99963467 7.67742607,4 Z M9.64721229,1.11566383 C8.8665488,1.1174428 8.2346444,1.74815794 8.2346444,2.52556864 L8.2346444,3.25794483 C9.16421922,3.04501719 10.1302054,3.04501719 11.0597802,3.25794483 L11.0597802,2.52556864 C11.0597802,1.74815794 10.4278758,1.1174428 9.64721229,1.11566383 Z"
            id="Shape"
          />
          <path
            d="M9.64749528,21.2603606 C7.7088314,21.2491643 6.0432207,19.8868101 5.65551401,17.9951963 C5.63057988,17.8736835 5.66248114,17.7474728 5.74221163,17.6521951 C5.82194212,17.5569174 5.94085087,17.5029106 6.06536835,17.5054216 L13.2378193,17.5054216 C13.3603999,17.5062289 13.4761824,17.5616327 13.5534071,17.6564355 C13.6316416,17.7507976 13.6632327,17.8751369 13.6394765,17.9951963 C13.2517698,19.8868101 11.5861592,21.2491643 9.64749528,21.2603606 L9.64749528,21.2603606 Z M7.23677534,18.1668011 C7.61315396,19.166889 8.57310496,19.8295006 9.64559237,19.8295006 C10.7180798,19.8295006 11.6780308,19.166889 12.0544094,18.1668011 L7.23677534,18.1668011 Z"
            id="Shape"
          />
          <path
            d="M17.0081165,15.701888 L2.28306821,15.701888 C2.16550503,15.7010347 2.0576313,15.6368273 2.0012026,15.5341184 C1.94332853,15.4342955 1.94332853,15.3113086 2.0012026,15.2114857 C2.87031351,13.8213935 3.32947922,12.2159829 3.32629496,10.5784804 L3.32629496,8.73302144 C3.32629496,5.2584118 6.15481552,2.44168415 9.64397245,2.44168415 C13.1331294,2.44168415 15.96165,5.2584118 15.96165,8.73302144 L15.96165,10.5752541 C15.9596119,12.2130469 16.4198935,13.8184338 17.2899821,15.2082594 C17.3524655,15.3078151 17.3557115,15.4333126 17.2984578,15.5359396 C17.2412041,15.6385666 17.1324886,15.7021226 17.0145962,15.701888 L17.0081165,15.701888 Z"
            id="Shape"
          />
          <path
            d="M17.7467988,18.1668011 L1.54762575,18.1668011 C1.18634192,18.1666879 0.860088277,17.9516025 0.718880141,17.6204438 C0.577672005,17.2892851 0.648839447,16.9061471 0.89965883,16.6472011 C1.32205167,16.2072491 1.69770405,15.7250456 2.02064161,15.2082594 C2.07996799,15.1137327 2.18409079,15.0563989 2.29602755,15.0566214 L17.0113564,15.0566214 C17.1232931,15.0563989 17.2274159,15.1137327 17.2867423,15.2082594 C17.6055965,15.7244473 17.976889,16.2066287 18.3947657,16.6472011 C18.6455851,16.9061471 18.7167526,17.2892851 18.5755444,17.6204438 C18.4343363,17.9516025 18.1080827,18.1666879 17.7467988,18.1668011 L17.7467988,18.1668011 Z"
            id="Shape"
          />
          <path
            d="M18.9703474,9.05565423 C18.7914163,9.05565423 18.646364,8.91120655 18.646364,8.73302144 C18.6602191,6.53927764 17.8180664,4.42590103 16.2974839,2.83852235 C16.2096079,2.7066001 16.2308296,2.53067464 16.3475801,2.42323091 C16.4643306,2.31578718 16.642115,2.30857025 16.7672599,2.40619455 C18.4024335,4.11370053 19.3083881,6.38649368 19.2943309,8.74592675 C19.2873713,8.91905271 19.1443374,9.05579279 18.9703474,9.05565423 Z"
            id="Path"
            stroke="#04817C"
          />
          <path
            d="M0.324077165,9.05565412 C0.14514604,9.05565412 9.37031363e-05,8.91120655 9.37031363e-05,8.73302144 C-0.0106043331,6.37804415 0.89510937,4.11048071 2.5271647,2.40619455 C2.65062718,2.27701036 2.85587623,2.27195469 2.98560129,2.39490242 C3.11532635,2.51785014 3.12040319,2.72224345 2.99694072,2.85142765 C1.47947857,4.43558744 0.637567068,6.54373313 0.648060626,8.73302144 C0.648060626,8.91120655 0.503008289,9.05565412 0.324077165,9.05565412 Z"
            id="Path"
            stroke="#04817C"
          />
        </g>
      </g>
    </svg>
  )
}

export default NotificationBellIcon
