import React, { Component } from 'react'

import PropTypes from 'prop-types'

import LayoutElementComponent from './component'

class LayoutElementContainer extends Component {
  render = () => {
    const {
      accessibilityLabel,
      isModal,
      isDoneSlide,
      modules,
      onSubmit,
      slideBackgroundColor,
      title,
      theme,
    } = this.props
    const layoutElementProps = {
      accessibilityLabel,
      isModal,
      isDoneSlide,
      modules,
      onSubmit,
      slideBackgroundColor,
      title,
      theme,
    }

    return <LayoutElementComponent {...layoutElementProps} />
  }
}

LayoutElementContainer.propTypes = {
  accessibilityLabel: PropTypes.string,
  isModal: PropTypes.bool,
  isDoneSlide: PropTypes.bool,
  modules: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func,
  slideBackgroundColor: PropTypes.string,
  title: PropTypes.string,
  theme: PropTypes.object,
}

LayoutElementContainer.defaultProps = {
  onSubmit() {},
  modules: [],
}

export default LayoutElementContainer
