import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { deepMerge } from 'grommet/utils'
import { Box, Calendar, FormField, Grommet, grommet } from 'grommet'

const customHeading = deepMerge(grommet, {
  calendar: {
    heading: {
      level: '3',
    },
  },
})

const DatePicker = ({ dateRange, label }) => {
  const [date, setDate] = useState()
  const onSelect = nextDate => {
    setDate(nextDate !== date ? nextDate : undefined)
  }
  return (
    <Grommet theme={customHeading}>
      <FormField data-testid="date-picker" htmlFor="select" label={label} />
      <Box align="center" pad="large">
        <Calendar bounds={dateRange} date={date} onSelect={onSelect} />
      </Box>
    </Grommet>
  )
}

DatePicker.propTypes = {
  dateRange: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
}

export default DatePicker
