/* istanbul ignore file */
// we don't test SVGs without arguments
const LearnTranscriptActive = () => {
  return (
    <svg height="36px" viewBox="0 0 30 36" width="30px">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-1313.000000, -938.000000)">
          <rect fill="#FFFFFF" height="988" width="1366" x="0" y="0" />
          <rect
            fill="#FFFFFF"
            height="987"
            rx="20"
            stroke="#979797"
            width="1367"
            x="0.5"
            y="0.5"
          />
          <g transform="translate(1313.000000, 938.000000)">
            <line
              stroke="#002855"
              strokeLinecap="round"
              strokeWidth="2"
              x1="1"
              x2="29"
              y1="34.5138889"
              y2="34.5138889"
            />
            <g id="Group">
              <rect
                fill="#002855"
                height="29.5833333"
                rx="6"
                stroke="#002855"
                width="30"
                x="0"
                y="0"
              />
              <line
                stroke="#FFFFFF"
                strokeLinecap="round"
                strokeWidth="2"
                x1="7"
                x2="11"
                y1="9.86111111"
                y2="9.86111111"
              />
              <line
                stroke="#FFFFFF"
                strokeLinecap="round"
                strokeWidth="2"
                x1="15"
                x2="23"
                y1="9.86111111"
                y2="9.86111111"
              />
              <line
                stroke="#FFFFFF"
                strokeLinecap="round"
                strokeWidth="2"
                x1="7"
                x2="23"
                y1="14.7916667"
                y2="14.7916667"
              />
              <line
                stroke="#FFFFFF"
                strokeLinecap="round"
                strokeWidth="2"
                x1="7"
                x2="15"
                y1="19.7222222"
                y2="19.7222222"
              />
              <line
                stroke="#FFFFFF"
                strokeLinecap="round"
                strokeWidth="2"
                x1="19"
                x2="23"
                y1="19.7222222"
                y2="19.7222222"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default LearnTranscriptActive
