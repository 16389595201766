import styled from 'styled-components'

export const ButtonStyled = styled.button.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  cursor: pointer;
  background: none;
  border: none;
  display: flex;
  padding: 0;
`
