/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

const NavSaved = () => (
  <svg height="19px" viewBox="0 0 28 19" width="28px">
    <g
      fill="none"
      fillRule="evenodd"
      id="SELA_header_saved"
      stroke="none"
      strokeWidth="1"
    >
      <g
        id="Group-3"
        stroke="#6C7171"
        strokeWidth="1.6"
        transform="translate(1.000000, 1.000000)"
      >
        <path
          d="M13.6874037,16.9997564 C11.1312297,16.9997564 8.57501238,17 6.01879507,16.9996281 C4.83796584,16.999457 3.72495055,16.7498874 2.73746689,16.0850101 C1.37941144,15.1707343 0.609929021,13.8772069 0.344479685,12.296899 C0.0703206986,10.6647096 0.383478118,9.14744638 1.40645036,7.80960803 C2.11730513,6.88002017 3.07042684,6.28597187 4.18924857,5.94508591 C4.33575268,5.90043285 4.39043715,5.84696893 4.39984011,5.68892958 C4.56974328,2.81907192 6.78017448,0.451561252 9.66211538,0.0703421196 C12.2030366,-0.265796235 14.2336851,0.60442529 15.7470409,2.64550665 C15.8250378,2.75072365 15.8909885,2.76441041 16.0150035,2.7346417 C19.1599238,1.97934622 22.2302272,3.81371468 22.9947698,6.90713707 C23.0679135,7.2031561 23.1016688,7.51016731 23.1324342,7.81414177 C23.1465603,7.95366122 23.1911919,8.02089744 23.3278597,8.07243666 C24.9668339,8.69082174 25.9572641,9.86185266 26.1961078,11.570474 C26.4804931,13.6056529 25.6301972,15.4844606 23.8625282,16.453569 C23.1172682,16.8622044 22.3055808,16.9989438 21.4642979,16.9993287 C18.8719854,17.0005263 16.2796729,16.9997564 13.6874037,16.9997564"
          id="Fill-1"
        />
      </g>
      <polyline
        id="Path-4"
        points="11 11.2264893 13.3007232 14 17 8"
        stroke="#6C7171"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.6"
      />
    </g>
  </svg>
)

export default NavSaved
