import React from 'react'
import PropTypes from 'prop-types'
import { StyledProgressBarContainerSmall } from './component.styles'
import ProgressBarIndicator from '../ProgressBarIndicator'

const ProgressBarSmall = ({ progressPercent = 0, ...rest }) => {
  const value = Math.ceil(progressPercent * 100)
  return (
    <StyledProgressBarContainerSmall>
      <ProgressBarIndicator
        isValueInsideBar={false}
        percentComplete={value}
        shouldRenderIndicator
        shouldRenderTextProgress
        {...rest}
      />
    </StyledProgressBarContainerSmall>
  )
}

ProgressBarSmall.propTypes = {
  progressPercent: PropTypes.number,
}

ProgressBarSmall.displayName = 'ProgressBarSmall'

export default ProgressBarSmall
