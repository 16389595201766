import styled from 'styled-components'

export const EvaluationTextContainer = styled.div`
  margin-right: 1rem;
  flex: 1;
  align-items: flex-start;
`

export const StoryContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: gray;
`
