import styled from 'styled-components'
import { Box } from 'grommet'
import { themeGet } from 'styled-system'
import PipeIcon from '../PipeIcon'

export const MultiTextLinkWrapper = styled(Box).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  flex-direction: row;
  align-items: center;
  color: ${themeGet('colors.buttons.iconBase')};

  ${PipeIcon} {
    margin: 0 0.5rem 0.125rem;
  }
`
