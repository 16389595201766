/* istanbul ignore file */
// we don't unit test SVGs
const SelaTranscriptActiveMobile = () => {
  return (
    <svg height="42px" viewBox="0 0 50 42" width="50px">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-305.000000, -274.000000)">
          <rect fill="#FFFFFF" height="667" width="375" x="0" y="0" />
          <g transform="translate(305.000000, 274.000000)">
            <line
              stroke="#007980"
              strokeLinecap="round"
              strokeWidth="2"
              x1="1"
              x2="49"
              y1="41"
              y2="41"
            />
            <rect
              fill="#007980"
              height="30"
              rx="6"
              stroke="#007980"
              width="50"
              x="0"
              y="6"
            />
            <line
              stroke="#FFFFFF"
              strokeLinecap="round"
              strokeWidth="2"
              x1="17"
              x2="21"
              y1="16"
              y2="16"
            />
            <line
              stroke="#FFFFFF"
              strokeLinecap="round"
              strokeWidth="2"
              x1="25"
              x2="33"
              y1="16"
              y2="16"
            />
            <line
              stroke="#FFFFFF"
              strokeLinecap="round"
              strokeWidth="2"
              x1="17"
              x2="33"
              y1="21"
              y2="21"
            />
            <line
              stroke="#FFFFFF"
              strokeLinecap="round"
              strokeWidth="2"
              x1="17"
              x2="25"
              y1="26"
              y2="26"
            />
            <line
              stroke="#FFFFFF"
              strokeLinecap="round"
              strokeWidth="2"
              x1="29"
              x2="33"
              y1="26"
              y2="26"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SelaTranscriptActiveMobile
