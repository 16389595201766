export const KEYS_ACTIVATION = {
  ENTER: 'Enter',
  SPACE: ' ',
}
export const KEYS_CANCEL = {
  ESCAPE: 'Escape',
  TAB: 'Tab',
}
export const KEYS_MOVEMENT = {
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
}

export const isMovementKey = key => Object.values(KEYS_MOVEMENT).includes(key)

export const isActivationKey = key =>
  Object.values(KEYS_ACTIVATION).includes(key)

export const isCancelKey = key => Object.values(KEYS_CANCEL).includes(key)

export const getNewDropZoneIndexForKeyboardAction = (
  key,
  dropZones,
  currentDropZoneIndex,
) => {
  if (!dropZones) {
    return -1
  }

  if (currentDropZoneIndex == null) {
    return -1
  }

  let newDropZoneIndex = currentDropZoneIndex

  if (key === KEYS_MOVEMENT.ARROW_RIGHT) {
    const canMoveRight = dropZones.length - 1 > newDropZoneIndex
    newDropZoneIndex = canMoveRight ? newDropZoneIndex + 1 : newDropZoneIndex
  } else if (key === KEYS_MOVEMENT.ARROW_LEFT) {
    const canMoveLeft = newDropZoneIndex > 0
    newDropZoneIndex = canMoveLeft ? newDropZoneIndex - 1 : newDropZoneIndex
  }

  return newDropZoneIndex
}
