import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Tabs } from 'grommet'

const TabSet = styled(Tabs).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
  direction: 'row',
  role: 'tablist',
}))`
  height: 1.3125rem;
`

TabSet.propTypes = {
  children: PropTypes.node.isRequired,
  dataTestId: PropTypes.string.isRequired,
}

TabSet.displayName = 'TabSet'

export default TabSet
