import styled from 'styled-components/native'

export const InteractiveContainer = styled.View.attrs(
  ({
    theme: {
      colorVars: { imageJuxtaposition },
    },
  }) => ({
    backgroundColor: imageJuxtaposition.background,
  }),
)`
  align-items: center;
  background-color: ${props => props.backgroundColor};
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  width: 100%;
`

export const LeftColumn = styled.View`
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  width: 24%;
`

export const RightColumn = styled.View`
  display: flex;
  height: 100%;
  justify-content: center;
  width: 66%;
`

export const TextContainer = styled.View`
  flex-direction: column;
  padding: 5%;
`
