import React, { lazy, Suspense } from 'react'

import PropTypes from 'prop-types'

import LanguageToggleItemComponent from '../../LanguageToggleItem/component'

import { Container, TextIconContainer } from './style'

const ArrowIconClosed = lazy(() => import('utils/importSwap/arrowIconClosed'))
const ArrowIconOpen = lazy(() => import('utils/importSwap/arrowIconOpen'))

const LanguageToggleComponent = ({
  isActive,
  handleClick,
  language,
  handleItemClick,
  languages,
}) => {
  return (
    <Container
      role="listbox"
      data-testid="locale-toggle-switch"
      onClick={handleClick}
      isActive={isActive}
    >
      <TextIconContainer role="option" aria-selected="true" isActive={isActive}>
        {language}
        {!isActive ? (
          <Suspense fallback={<p>Please wait&hellip;</p>}>
            <ArrowIconClosed />
          </Suspense>
        ) : (
          <Suspense fallback={<p>Please wait&hellip;</p>}>
            <ArrowIconOpen />
          </Suspense>
        )}
      </TextIconContainer>
      {isActive &&
        languages.map(lang => {
          return (
            <LanguageToggleItemComponent
              aria-selected="false"
              role="option"
              key={lang}
              itemName={lang}
              onClick={() => {
                handleItemClick(lang)
              }}
            ></LanguageToggleItemComponent>
          )
        })}
    </Container>
  )
}

LanguageToggleComponent.propTypes = {
  isActive: PropTypes.bool,
  handleClick: PropTypes.func,
  language: PropTypes.string,
  handleItemClick: PropTypes.func,
  languages: PropTypes.array,
}
LanguageToggleComponent.defaultProps = {
  handleClick() {},
  handleItemClick() {},
}

export default LanguageToggleComponent
