import styled from 'styled-components'
import { themeGet } from 'styled-system'
import TextLink from 'components/TextLink'

export const AsyncLinkWrapper = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 0.5rem;

  svg {
    margin-right: 0.5rem;

    // passing a hex-value as the second argument to themeGet() as a fall back in case
    // the initial value cannot be read from the theme
    color: ${themeGet('colors.blue', '#3c6e90')};
  }

  a {
    font-family: ${themeGet('fonts.link')};
    margin-right: 0.75rem;
  }

  span {
    font-size: 0.75rem;
    color: ${themeGet('colors.deleteRed')};
  }
`

export const BasicLinkWrapper = styled(AsyncLinkWrapper).attrs(
  ({ dataTestId }) => ({
    color: themeGet('colors.link.primary', 'colors.blue'),
    'data-testid': dataTestId,
    hoverColor: themeGet('colors.darkBlue'),
  }),
)`
  a {
    color: ${({ color }) => color};
    transition: color 0.2s ease;

    svg {
      color: ${({ color }) => color};
      transition: color 0.2s ease;
    }

    &:hover {
      transition: color 0.2s ease;
      color: ${({ hoverColor }) => hoverColor};

      svg {
        transition: color 0.2s ease;
        color: ${({ hoverColor }) => hoverColor};
      }
    }
  }
`

// passing a hex-value as the second argument to themeGet() as a fall back in case
// the initial value cannot be read from the theme
export const StyledLink = styled(TextLink).attrs(({ disabled }) => ({
  linkColor: disabled
    ? themeGet('colors.disabledGray', '#aaaaaa')
    : themeGet('colors.link.primary', 'colors.blue'),
  cursor: disabled ? 'not-allowed' : 'pointer',
  hoverColor: disabled ? 'inherit' : themeGet('colors.darkBlue'),
  textDecoration: disabled ? 'none' : 'underline',
}))`
  cursor: ${({ cursor }) => cursor};
  color: ${({ linkColor }) => linkColor};
  transition: color 0.2s ease;
  opacity: 1;

  svg {
    color: ${({ linkColor }) => linkColor};
    transition: color 0.2s ease;
  }

  &:hover {
    text-decoration: ${({ textDecoration }) => textDecoration});
    color: ${({ hoverColor }) => hoverColor};
    transition: color 0.2s ease;

    svg {
      color: ${({ hoverColor }) => hoverColor};
      transition: color 0.2s ease;
    }
  }
  &:focus {
    color: ${({ linkColor }) => linkColor};
  }
`
