import { LOCALES } from 'utils/locale'

export const RESET = {
  [LOCALES.EN_US]: 'Reset',
  [LOCALES.ES_US]: 'Reiniciar',
}
export const CHECK = {
  [LOCALES.EN_US]: 'Check',
  [LOCALES.ES_US]: 'Evaluación',
}
