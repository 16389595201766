import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Wrapper, Span } from './component.styles'

const IconWithText = ({ className, dataTestId, icon, text }) => (
  <Wrapper className={className} dataTestId={dataTestId}>
    {icon} <Span dataTestId={`text-${dataTestId}`}>{text}</Span>
  </Wrapper>
)

IconWithText.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  icon: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
}

export default styled(IconWithText)``
