import styled from 'styled-components'
import playButtonHover from '../SelaVideoPlayButtonHover/playButtonHover.svg'
import defaultSvg from '../SelaVideoPlayButtonDefault/playButtonDefault.svg'
import theme from 'theme/baseTheme'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .azuremediaplayer .vjs-tech {
    height: 99%;
  }

  .amp-logo {
    display: none !important;
  }

  .vjs-poster img {
    height: 100%;
    width: 100%;
    border-radius: 0.625rem;
  }

  .azuremediaplayer {
    border-radius: 0.625rem;
  }

  .vjs-tech {
    border-radius: 0.625rem;
  }

  .amp-flush-skin.amp-big-play-centered:hover .vjs-big-play-button,
  .amp-flush-skin.amp-big-play-centered .vjs-big-play-button,
  .amp-flush-skin.amp-big-play-centered .vjs-big-play-button:focus {
    transform: none !important;
  }

  .amp-flush-skin .vjs-big-play-button {
    transition: none !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    margin: auto !important;
  }

  .vjs-big-play-button,
  .vjs-big-play-button:hover::before,
  .vjs-big-play-button::before {
    content: url(${playButtonHover});
  }

  .vjs-big-play-button::before {
    content: url(${defaultSvg});
  }

  .vjs-big-play-button {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    :focus {
      outline: 3px groove ${theme?.colors?.video?.hover?.outline};
    }
  }
  .vjs-control-bar {
    border-radius: 0 0 0.625rem 0.625rem;
    bottom: -1px;

    div {
      .vjs-play-control {
        -webkit-transition-property: none;
        -moz-transition-property: none;
        -o-transition-property: none;
        transition-property: none;

        &:hover {
          border-radius: 0.625rem;
        }
      }
    }
    .amp-controlbaricons-right {
      .vjs-fullscreen-control {
        -webkit-transition-property: none;
        -moz-transition-property: none;
        -o-transition-property: none;
        transition-property: none;

        &:hover {
          border-radius: 0.625rem;
        }
      }
    }
  }

  // Fixes floating progress bar
  .amp-flush-skin .vjs-progress-control {
    position: static;
  }

  // Fixes width of progress bar at small breakpoint
  .amp-flush-skin.amp-size-s
    .vjs-control-bar
    .amp-controlbaricons-middle
    .vjs-progress-control {
    padding: 0px;
  }

  // Fixes disappearing play button at small breakpoint
  .amp-flush-skin.amp-size-s
    .vjs-control-bar
    .amp-controlbaricons-left
    .vjs-play-control {
    display: inline-block !important;
  }
`
