import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'
import CheckIcon from '../CheckIcon/component.jsx'

export const SelectWrapper = styled(Box)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  svg {
    display: none;
    color: ${themeGet('colors.highschool.pink1')};
  }
`

export const StyledCheck = styled(CheckIcon)`
  background-color: ${themeGet('colors.white')};
`
