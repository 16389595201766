import React from 'react'
import PropTypes from 'prop-types'
import {
  toNoBreakContentArray,
  toContentArray,
} from '../../utils/componentHelpers'

const MarkdownStrong = ({
  children = [],
  className,
  'data-testid': dataTestId,
}) => {
  const markdownContent = toContentArray(children)
  const noBreakContentArray = toNoBreakContentArray(markdownContent)
  return (
    <strong className={className} data-testid={dataTestId}>
      {noBreakContentArray}
    </strong>
  )
}

MarkdownStrong.propTypes = {
  'data-testid': PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  className: PropTypes.string,
}

export default MarkdownStrong
