import styled from 'styled-components'
import PropTypes from 'prop-types'
import { themeGet } from 'styled-system'

const AutoTableArrow = styled.i.attrs(({ active, direction, dataTestid }) => ({
  active,
  className: `fas fa-caret-${direction}`,
}))`
  color: ${props =>
    props.active
      ? themeGet('colors.darkBlue')
      : themeGet('colors.backgroundNeutralGrayOneAccent')};
`

AutoTableArrow.propTypes = {
  active: PropTypes.bool.isRequired,
  direction: PropTypes.string.isRequired,
}

AutoTableArrow.displayName = 'AutoTableArrow'

export default AutoTableArrow
