import React from 'react'

const IconPDF = () => {
  return (
    <svg height="22" viewBox="0 0 18 22" width="18">
      <title>Open PDF</title>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          d="M0 2.237v17.526A2.237 2.237 0 002.237 22h13.42a2.237 2.237 0 002.238-2.237V5.206L11.766 0h-9.53A2.237 2.237 0 000 2.237z"
          fill="currentColor"
          transform="translate(-1157 -183) translate(19 123) translate(1138 54) translate(0 6)"
        />
        <path
          d="M8.077 5l.211.014c.552.068 1.17.587 1.348 1.61l.027.185.006.14c.006.555-.132 1.355-.525 2.5.419.682.93 1.298 1.587 1.874.948.01 1.831.111 2.7.33.313.07.594.244.796.482.32.355.422.813.305 1.266l-.055.177c-.04.109-.098.224-.169.327l-.155.197a1.388 1.388 0 01-1.69.304c-.893-.474-1.658-.972-2.295-1.495a9.777 9.777 0 00-2.916.619l-.21.374c-.221.385-.475.808-.793 1.323l-.465.745c-.186.263-.398.45-.671.554l-.157.028-.055.001-.247.02-.09.004c-.42 0-.792-.192-1.075-.479-.452-.535-.569-1.084-.44-1.558.064-.32.238-.606.475-.803l.286-.214c.567-.412 1.352-.87 2.274-1.268.55-1.02.978-1.919 1.299-2.713-.423-.856-.668-1.663-.737-2.253a2.633 2.633 0 01.09-1.182c.246-.793.772-1.12 1.341-1.109zm.043 5.263c-.24.613-.527 1.306-.91 2.105.646-.213 1.34-.4 2.106-.48-.479-.559-.885-1.119-1.196-1.625z"
          fill="#FFF"
          fillRule="nonzero"
          transform="translate(-1157 -183) translate(19 123) translate(1138 54) translate(0 6)"
        />
      </g>
    </svg>
  )
}

export default IconPDF
