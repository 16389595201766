import CheckBox from './component.jsx'
import {
  CheckBoxInputContainer,
  CheckBoxInputLabel,
  CheckBoxHidden,
  CheckBoxStyled,
} from './component.styles'

export default CheckBox
export {
  CheckBoxInputContainer,
  CheckBoxInputLabel,
  CheckBoxHidden,
  CheckBoxStyled,
}
