import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box, Text } from 'grommet'

export const Span = styled(Text).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))``

export const Wrapper = styled(Box).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: ${themeGet('fontFamily')};

  svg {
    margin-right: 0.5rem;
    overflow: visible;
  }

  p,
  ${Span} {
    margin: 0;
    font-size: 1rem;
    white-space: nowrap;
  }
`
