export const BLANK_PAGE = null
export const DEFAULT_THEME = 'generic'
export const ENTRY = 'entry'
export const ENTRY_ID_PARAM_ERROR = 'No contentful entry ID supplied in url.'
export const ENVIRONMENT = 'environment'
export const FETCH_PRESENTATION_ERROR =
  'An error occurred retrieving the presentation from the Contentful API.'
export const FETCH_PRESENTATION_ERROR_UNPUBLISHED =
  FETCH_PRESENTATION_ERROR + ' Did you forget to publish the presentation?'
export const FORMAT = 'format'
export const INCLUDE = 'include'
export const CACHE = 'cache'
export const LOAD_PRESENTATION_ERROR = 'Unable to load presentation.'
export const MOCK_DATA = 'mockData'
export const PREVIEW = 'preview'
export const PREVIEW_PARAM_ERROR =
  'No preview param or invalid preview param supplied in url.'
export const SUCCESS = 200
export const THEME = 'theme'
export const TOKEN = 'token'
export const LOCALE = 'locale'
export const MODE = 'mode'
export const HS_THEME = 'gradehs'
