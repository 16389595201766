import styled from 'styled-components'

export const ExitLessonButton = styled.button`
  ${({ theme }) => theme?.colorVars?.lessonComplete?.exitLesson}
  ${({ theme }) => theme?.fonts?.lessonComplete?.exitLesson}
  border: none;
  border-radius: 5px;
  color: ${props => props.color};
  cursor: pointer;
  height: 43px;
  width: 132px;
  transition: background-color 0.2s ease;

  :hover {
    ${({ theme }) => theme?.colorVars?.lessonComplete?.exitLessonHover}
  }
`
