import React from 'react'

import PropTypes from 'prop-types'

import { HoverIndicator } from '../style'

import { Container, Grip, Text } from './style'

const DATA_TEST_ID_PREFIX = 'draggable-item-'
const INVISIBLE_DATA_TEST_ID_PREFIX = 'invisible-draggable-item'

const DragLabelDraggableComponent = ({
  dragRef,
  index,
  isHovered,
  isLong,
  isVisible,
  label,
  left,
  onMouseEvent,
  top,
}) => {
  const dataTestId = !isNaN(index)
    ? `${DATA_TEST_ID_PREFIX}${index}`
    : INVISIBLE_DATA_TEST_ID_PREFIX

  return (
    <Container
      data-testid={dataTestId}
      isLong={isLong}
      isVisible={isVisible}
      left={left}
      onMouseEnter={onMouseEvent}
      onMouseLeave={onMouseEvent}
      ref={dragRef}
      top={top}
    >
      <HoverIndicator isHovered={isHovered} />
      <Grip />
      <Text>{label}</Text>
    </Container>
  )
}

DragLabelDraggableComponent.propTypes = {
  dragRef: PropTypes.func,
  index: PropTypes.number,
  isHovered: PropTypes.bool,
  isVisible: PropTypes.bool,
  label: PropTypes.string,
  left: PropTypes.number,
  isLong: PropTypes.bool,
  onMouseEvent: PropTypes.func,
  top: PropTypes.number,
}

DragLabelDraggableComponent.defaultProps = {
  dragRef() {},
  isVisible: true,
  label: '',
  onMouseEvent() {},
}

export default DragLabelDraggableComponent
