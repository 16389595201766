import { SelectOptionsWrapper } from './component.styles'

const compare = (valA, valB) => {
  if (Number.isFinite(valA) && Number.isFinite(valB)) {
    return valA - valB
  } else {
    return valA?.toString() < valB?.toString()
      ? -1
      : valB?.toString() < valA?.toString()
      ? 1
      : 0
  }
}

export const sortOptions = (sortBy, options) => {
  const isSortFunc = typeof sortBy === 'function'
  const isSortString = typeof sortBy === 'string'
  if (isSortFunc || isSortString) {
    const getVal = isSortFunc ? sortBy : val => val[sortBy]
    return [...options].sort((a, b) => {
      const valA = getVal(a)
      const valB = getVal(b)
      return compare(valA, valB)
    })
  } else {
    return [...options]
  }
}

export const formatOption = (optionKey, option) => {
  const isOptionKeyFunc = typeof optionKey === 'function'
  const isOptionKeyString = typeof optionKey === 'string'

  const formattedOption = isOptionKeyFunc
    ? optionKey(option)
    : isOptionKeyString
    ? option[optionKey]
    : option
  return formattedOption
}

export const addTestIdToOptions = (dataTestId, optionKey) => (
  option,
  index,
) => {
  return (
    <SelectOptionsWrapper data-testid={`${dataTestId}-option-${index}`}>
      {formatOption(optionKey, option)}
    </SelectOptionsWrapper>
  )
}
