import React from 'react'

function StepFiveIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <circle cx="10" cy="10" r="10" fill="currentColor" />
        <g fill="#FFF" fillRule="nonzero" transform="translate(6.767 5.4)">
          <path d="M3.384 2.967c-.718 0-1.297.157-1.765.447v-2.09h4.792V0H.054v5.083h1.471c.379-.676 1.119-.859 1.714-.859.872 0 1.81.503 1.81 1.823v.013c0 1.209-.695 1.82-1.787 1.82-1.15 0-1.78-.657-1.78-1.58v-.054H0v.066C0 8.226 1.33 9.2 3.397 9.2c2.04 0 3.326-1.17 3.326-3.11v-.013c0-2.152-1.647-3.11-3.339-3.11z" />
        </g>
      </g>
    </svg>
  )
}

export default StepFiveIcon
