import React from 'react'

import PropTypes from 'prop-types'

import HttpErrorMessageComponent from './component'
import { ERROR_DISPLAY } from './constants'

//Refresh the page (not from cache)
const retry = () => window.location.reload(true)

const HttpErrorMessageContainer = ({ statusCode }) => {
  const detailedMessage = statusCode && ERROR_DISPLAY[statusCode].message
  const showRetry = statusCode ? ERROR_DISPLAY[statusCode].showRetry : true
  return (
    <HttpErrorMessageComponent
      message={detailedMessage}
      onRetryClick={retry}
      showRetry={showRetry}
    />
  )
}

HttpErrorMessageContainer.propTypes = {
  statusCode: PropTypes.number,
}

export default HttpErrorMessageContainer
