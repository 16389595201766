import React from 'react'
import PropTypes from 'prop-types'
import { SelectWrapper, StyledCheck } from './component.styles.jsx'

const SelectOption = ({ children, value }) => {
  return (
    <SelectWrapper>
      {children}
      {value === children && <StyledCheck />}
    </SelectWrapper>
  )
}

SelectOption.propTypes = {
  children: PropTypes.object,
  value: PropTypes.string,
}

export default SelectOption
