import React from 'react'

import PropTypes from 'prop-types'

import { groupBy } from '../../../utils/arrayHelper'
import DragWordDraggableItem from '../DragWordDraggableItem'
import { DropZone } from '../DragWordDropZone/style'

import { DraggableContainer, DraggableItemContainer } from './style'

const DATA_TEST_ID = 'draggable-container'

const DragWordDraggables = props => {
  const { draggables } = props

  let groupedDraggables = (draggables || []).filter(Boolean)
  groupedDraggables = groupBy(groupedDraggables, 'label')

  const wordContainers = Object.keys(groupedDraggables).map((word, index) => {
    const draggableItems = groupedDraggables[word]
      .filter(Boolean)
      .filter(draggable => draggable.type)
      .map(draggable => {
        return (
          draggable &&
          !draggable.dropped && (
            <DragWordDraggableItem key={draggable.id} item={draggable} />
          )
        )
      })

    return (
      <DraggableItemContainer
        data-testid={`${DATA_TEST_ID}-${index}`}
        key={word}
      >
        <DropZone />
        {draggableItems}
      </DraggableItemContainer>
    )
  })

  return <DraggableContainer>{wordContainers}</DraggableContainer>
}

DragWordDraggables.propTypes = {
  draggables: PropTypes.arrayOf(PropTypes.object),
}

export default DragWordDraggables
