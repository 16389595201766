/* eslint-disable no-undef */

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Wrapper } from './component.styles'

const noop = () => {}

export default class Audio extends Component {
  static propTypes = {
    poster: PropTypes.string,
    settings: PropTypes.shape({
      autoplay: PropTypes.bool,
      controls: PropTypes.bool,
      height: PropTypes.string,
      width: PropTypes.string,
    }),
    streamingLocator: PropTypes.string,
    tracks: PropTypes.array,
  }
  static defaultProps = {
    settings: {
      autoplay: false,
      controls: true,
      corsPolicy: 'anonymous',
      height: '100%',
      logo: { enabled: false },
      width: '100%',
    },
    hasVideo: false,
    tracks: [],
    onEnded: noop,
    onPlay: noop,
    onStopped: noop,
  }

  constructor() {
    super()
    this.audioRef = React.createRef()
  }

  componentDidMount = () => {
    this.initializePlayer()
  }

  componentWillUnmount = () => {
    if (this.player) {
      this.player.dispose()
    }
  }

  initializePlayer = () => {
    const {
      streamingLocator,
      tracks,
      settings,
      poster,
      onStopped,
      onEnded,
      onPlay,
      dataTestId,
    } = this.props || {}

    if (!streamingLocator || !amp) {
      console.log('no data for audio')
      return
    }
    this.player = amp(this.audioRef.current, settings)

    this.player.addEventListener('ended', () => onEnded(dataTestId))
    this.player.addEventListener('pause', () => onStopped(dataTestId))
    this.player.addEventListener('play', () => onPlay(dataTestId))

    this.player.src({ src: streamingLocator, type: 'audio/mp3' }, tracks)
    this.player.poster(poster)
  }

  render() {
    return (
      <Wrapper className="amsWrapper">
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <audio
          className="azuremediaplayer amp-flush-skin amp-big-play-centered"
          ref={this.audioRef}
        />
      </Wrapper>
    )
  }
}
