import React from 'react'

import PropTypes from 'prop-types'

import Close from '../Close'
import ModalLayoutRouter from '../ModalLayoutRouter'

import { Background, CloseButtonContainer, Wrapper } from './style'

const DATA_TEST_ID = 'Modal container'

const ModalComponent = ({
  closeModal,
  isModalJson,
  modal,
  ModalType,
  isCloseButtonWithBorder,
}) => {
  if (!modal) {
    return null
  }
  const contentComponent = isModalJson ? (
    <ModalLayoutRouter contentfulLayout={modal} />
  ) : (
    modal
  )
  return (
    <Wrapper data-testid={DATA_TEST_ID}>
      <ModalType>
        {contentComponent}
        <CloseButtonContainer
          onClick={closeModal}
          showBorder={isCloseButtonWithBorder}
        >
          <Close isCloseButtonWithBorder={isCloseButtonWithBorder} />
        </CloseButtonContainer>
      </ModalType>
      <Background onClick={closeModal} />
    </Wrapper>
  )
}

ModalComponent.propTypes = {
  closeModal: PropTypes.func,
  isModalJson: PropTypes.bool,
  modal: PropTypes.PropTypes.object,
  ModalType: PropTypes.object,
  isCloseButtonWithBorder: PropTypes.bool,
}

ModalComponent.defaultProps = {
  closeModal() {},
  showCloseButtonBorder: true,
}

export default ModalComponent
