export default class VideoHelper {
  static #PLAY_BACK_SPEED_SETTINGS = {
    playbackSpeed: {
      enabled: true,
      inititalSpeed: 1.0,
      speedLevels: [
        { name: 'x2.0', value: 2.0 },
        { name: 'x1.75', value: 1.75 },
        { name: 'x1.5', value: 1.5 },
        { name: 'x1.25', value: 1.25 },
        { name: 'normal', value: 1.0 },
        { name: 'x0.75', value: 0.75 },
        { name: 'x0.5', value: 0.5 },
      ],
    },
  }

  static #PLUGINS_DEFAULT_SETTINGS = {
    buffering: true,
    bitrateQuality: true,
    debug: false,
    downloadInfo: true,
    end: true,
    error: true,
    fullscreen: true,
    loaded: true,
    playbackSummary: true,
    playTime: true,
    percentPlayed: true,
    play: true,
    pause: true,
    seek: true,
    viewed: true,
  }

  static #getDefaultPlayerSettings = aditionalOptions => ({
    ...(aditionalOptions?.addPlayBackSpeedControls
      ? this.#PLAY_BACK_SPEED_SETTINGS
      : {}),
    controls: true,
    height: '100%',
    width: '100%',
    ...aditionalOptions,
    plugins: {
      appInsights: {
        ...this.#PLUGINS_DEFAULT_SETTINGS,
      },
    },
  })

  static getSettings = options => {
    const settings = this.#getDefaultPlayerSettings(options)
    return settings
  }

  static getExtendedSetting = vttPauseSource => ({
    cfcInteractiveVideo: {
      vtt: vttPauseSource,
    },
    appInsights: {
      ...this.#PLUGINS_DEFAULT_SETTINGS,
    },
  })

  static #getFieldsForModule = module => {
    const { fields } = module || {}
    return fields
  }

  static getVideoModuleAzureVideoSource = module => {
    const fields = this.#getFieldsForModule(module)
    const { azureVideoUrl } = fields || module || {}

    return azureVideoUrl
  }

  static getVideoModuleBitmovinVideoSource = module => {
    const fields = this.#getFieldsForModule(module)
    const { bitmovinUrl } = fields || module || {}

    return bitmovinUrl
  }

  static getVideoModuleExtendedAudioTranscriptSource = module => {
    const fields = this.#getFieldsForModule(module)
    const extendedAudioTranscript = fields
      ? fields?.transcriptExtended?.fields?.file?.url
      : module?.transcriptExtended?.file?.url
    return extendedAudioTranscript
  }

  static getVideoModulePosterSource = module => {
    const fields = this.#getFieldsForModule(module)
    const posterSource = fields
      ? fields?.posterImage?.fields?.file?.url
      : module?.posterImage?.file?.url

    return posterSource
  }

  static getVideoModuleTranscriptSource = module => {
    const fields = this.#getFieldsForModule(module)
    const transcript = fields
      ? fields?.transcript?.fields?.file?.url
      : module?.transcript?.file?.url
    return transcript
  }

  static getVideoModuleVttPauseExtendedSource = module => {
    const fields = this.#getFieldsForModule(module)
    const pauseSource = fields?.vttPauseFileExtended?.fields?.file?.url
    return pauseSource
  }

  static getVideoModuleVttPauseSource = module => {
    const fields = this.#getFieldsForModule(module)
    const pauseSource = fields
      ? fields?.vttPauseFile?.fields?.file?.url
      : module?.vttPauseFile?.file?.url

    return pauseSource
  }

  static hasVideoSource = videoRef => {
    const source = this.getVideoModuleAzureVideoSource(videoRef)

    return source != null
  }

  static getVideoModuleBitmovinVideoSource = module => {
    const fields = this.#getFieldsForModule(module)
    const { bitmovinUrl } = fields || module || {}

    return bitmovinUrl
  }

  static hasVideoBitmovinSource = videoReference => {
    const source = this.getVideoModuleBitmovinVideoSource(videoReference)

    return source != null
  }
}
