/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

export default () => (
  <svg
    height="19px"
    version="1.1"
    viewBox="0 0 23 19"
    width="23px"
    x="0px"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
  >
    <g transform="translate(-305.000000, -296.000000)">
      <g transform="translate(40.000000, 40.000000)">
        <g transform="translate(27.000000, 138.547350)">
          <g id="resilience-icon" transform="translate(238.000000, 117.452650)">
            <path
              className="st0"
              d="M4.4,3h3.8c0.4,0,0.7-0.3,0.7-0.7S8.6,1.5,8.2,1.5H4.4C4,1.5,3.6,1.9,3.6,2.3S4,3,4.4,3"
              fill="currentColor"
            />
            <path
              className="st0"
              d="M6.5,4.6H2.7C2.3,4.6,2,4.9,2,5.3c0,0.4,0.3,0.7,0.7,0.7h3.8c0.4,0,0.7-0.3,0.7-0.7
						C7.3,4.9,6.9,4.6,6.5,4.6"
              fill="currentColor"
            />
            <path
              className="st0"
              d="M4.5,7.7H0.7C0.3,7.7,0,8,0,8.4s0.3,0.7,0.7,0.7h3.8c0.4,0,0.7-0.3,0.7-0.7S4.9,7.7,4.5,7.7"
              fill="currentColor"
            />
            <g transform="translate(7.030000, 0.328486)">
              <defs>
                <filter
                  filterUnits="userSpaceOnUse"
                  height="18.5"
                  width="15.2"
                  x="0.1"
                  y="0"
                >
                  <feColorMatrix
                    type="matrix"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                  />
                </filter>
              </defs>
              <mask
                height="18.5"
                maskUnits="userSpaceOnUse"
                width="15.2"
                x="0.1"
                y="0"
              >
                <g className="st1">
                  <polygon
                    className="st2"
                    points="0.1,0 15.4,0 15.4,18.5 0.1,18.5 								"
                  />
                </g>
              </mask>
              <path
                className="st3"
                d="M6.4,13.3C6.4,13.2,6.4,13.2,6.4,13.3c0.2-1,0.6-1.9,1-2.7c0.7,0.1,1.3,0.1,1.8-0.1
							c-0.1,0.5,0,1.1,0.2,1.6C8.4,12.3,7.4,12.8,6.4,13.3 M5.4,12.9C5.4,12.9,5.4,13,5.4,12.9c-0.6-0.6-0.9-1.5-1.2-2.3
							c0.5-0.3,1-0.6,1.3-1c0.2,0.2,0.5,0.5,0.9,0.6C6,11.1,5.7,12,5.4,12.9 M14.5,9C15,8.6,15.3,7.9,15,7.2c-0.2-0.4-0.6-0.8-1-0.9
							c0.9-0.4,1.4-1.2,1.2-2c-0.1-0.6-0.6-1-1.2-1.3c0.3-0.7,0.3-1.4-0.1-2c-0.7-1.1-2.4-1.4-3.8-0.6C9.9,0.7,9.7,0.8,9.5,1
							c-1.1-0.4-2.2-0.4-3,0.3C6.1,1.6,5.9,2.1,5.7,2.6C4.8,2.2,3.7,2.4,3,3.2C2.4,3.9,2.3,4.9,2.7,5.8C1.6,6.1,0.6,7,0.3,8
							c-0.5,1.5,0.4,2.8,2,2.9c0.3,0,0.6,0,1-0.1C3.7,12,4.1,13.1,4.9,14c-0.1,0.3-0.1,0.6-0.2,0.8l-0.7,3c-0.1,0.3,0.2,0.6,0.5,0.7
							c0.4,0,0.8,0,1.2,0c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1,0-0.2,0-0.3C6,16.7,6,15.6,6.2,14.5c0,0,0.1,0,0.1,0
							c1.2-0.6,2.5-1.2,3.8-1.6c0.1,0.1,0.2,0.2,0.3,0.3c1.3,1.2,3.2,1.1,4.3-0.1C15.7,11.9,15.6,10.2,14.5,9"
                fill="currentColor"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
