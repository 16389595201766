import PropTypes from 'prop-types'
import React from 'react'
import { MultiTextLinkWrapper } from './component.styles'
import PipeIcon from '../PipeIcon'
import IconPDF from '../IconPDF'
import TextLink from '../TextLink'

const MultiTextLink = ({
  dataTestId,
  downloadLinkLabel,
  downloadOnClick,
  textLinkLabel,
  textLinkOnClick,
}) => {
  const handleClickAndPreventDefault = (e, handler) => {
    e.preventDefault()
    handler(e)
  }

  if (!downloadLinkLabel) return null
  return (
    <MultiTextLinkWrapper dataTestId={dataTestId}>
      <TextLink
        dataTestId="text-link-with-icon"
        href=""
        onClick={e => handleClickAndPreventDefault(e, downloadOnClick)}
      >
        <IconPDF />
        {downloadLinkLabel}
      </TextLink>
      {textLinkLabel && (
        <React.Fragment key={`text-link-label-${textLinkLabel}`}>
          <PipeIcon />
          <TextLink
            dataTestId="text-link"
            href=""
            onClick={e => handleClickAndPreventDefault(e, textLinkOnClick)}
          >
            {textLinkLabel}
          </TextLink>
        </React.Fragment>
      )}
    </MultiTextLinkWrapper>
  )
}

MultiTextLink.propTypes = {
  dataTestId: PropTypes.string.isRequired,
  downloadLinkLabel: PropTypes.string.isRequired,
  downloadOnClick: PropTypes.func,
  textLinkLabel: PropTypes.string,
  textLinkOnClick: PropTypes.func,
}

export default MultiTextLink
