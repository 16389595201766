import styled from 'styled-components'

import { CONTAINER_WIDTH } from './constants'

export const Body = styled.span`
  ${props => props.theme.fonts.lessonComplete.body};
`

export const Container = styled.div.attrs(
  ({
    theme: {
      colorVars: { lessonComplete: colors },
    },
  }) => ({
    style: {
      backgroundColor: colors.container.backgroundColor,
      height: '398px',
      padding: '0 60px 28px',
      width: `${CONTAINER_WIDTH}px`,
    },
  }),
)`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
`

export const ContentContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
`

export const CheckImage = styled.img`
  min-height: 70px;
  min-width: 70px;
`

export const FeedbackLink = styled.a`
  ${({ theme }) => theme.colorVars.lessonComplete.feedbackUrl};
  text-decoration: none;
  cursor: pointer;

  :hover {
    ${({ theme }) => theme.colorVars.lessonComplete.feedbackUrlHover};
  }
`

export const FeedbackLinkContainer = styled.div`
  margin-top: 28px;
`

export const Title = styled.h1`
  ${({ theme }) => theme.colorVars.lessonComplete.title};
  ${({ theme }) => theme.fonts.lessonComplete.title};
  margin: 28px 0;
`

export const TextContainer = styled.div.attrs(() => ({
  style: {
    width: `${CONTAINER_WIDTH * 0.5}px`,
  },
}))`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.colorVars.lessonComplete.body}
  ${({ theme }) => theme.fonts.lessonComplete.body}
`
