import styled from 'styled-components'

export const ScaleableWrapper = styled.div.attrs(
  ({
    theme: {
      colorVars: { ratioWrapper },
    },
  }) => ({
    backgroundColor: ratioWrapper.backgroundColor,
  }),
)`
  background: ${props => props.backgroundColor};
  flex: 1;
  height: 100%;
`

export const ContentWrapper = styled.div.attrs(({ height, scale, width }) => ({
  height: height && `${height}px`,
  scale,
  width: width && `${width}px`,
}))`
  display: flex;
  height: ${props => props.height};
  left: 50%;
  position: relative;
  right: 50%;
  top: 50%;
  transform-origin: center center;
  transform: translate(-50%, -50%) scale(${props => props.scale});
  width: ${props => props.width};
`
