import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  updateCmiInteractives,
  updateLMSSuspendData,
} from '../../lib/state/actions/cmi'
import {
  completeSlide,
  multichoiceInitialize,
  multichoiceSubmit,
  multichoiceUpdate,
} from '../../lib/state/actions/player'

import MultiChoiceComponent from './component'

const BEHAVIOUR_MULTI = 'multi'
const BEHAVIOUR_SINGLE = 'single'
const JOIN = '[,]'
const KEY_SEPARATOR = ':::'
const OPTION_KEY_PREFIX = 'o'

export class MultiChoiceContainer extends Component {
  componentDidMount() {
    const { id, multichoiceInitialize, shouldInitialize } = this.props
    const mappedOptions = this.getMappedOptions()
    shouldInitialize &&
      multichoiceInitialize({ interactiveId: id, options: mappedOptions })
  }

  getMappedOptions() {
    const { behaviour } = this.props
    const { randomAnswers } = behaviour || {}
    const mappedOptions = this.mapOptions()
    const newOptions = randomAnswers
      ? this.randomizeOptions(mappedOptions)
      : mappedOptions

    return newOptions
  }

  mapOptions = () => {
    const { answers } = this.props
    const id = (index, text) => OPTION_KEY_PREFIX + index + KEY_SEPARATOR + text

    return answers.map(({ correct, text }, index) => ({
      id: id(index, text),
      isCorrect: correct,
      isFocused: false,
      isSelected: false,
      text,
    }))
  }

  getGroupName() {
    const groupName = btoa(~~Math.random() * 1000) //unique group name

    return groupName
  }

  getIsRadio() {
    const { behaviour, options } = this.props
    const { type } = behaviour || {}
    // if behaviour.type is set to 'single' or 'multi', honor that first; if it's anything else
    // (which would probably be 'auto') then choose based on if there is exactly one correct answer
    const isBehaviorSingle = type === BEHAVIOUR_SINGLE
    const isBehaviorMulti = type === BEHAVIOUR_MULTI
    const hasOneAnswer = options.filter(option => option.isCorrect).length === 1
    const isRadio = isBehaviorSingle
      ? true
      : isBehaviorMulti
      ? false
      : hasOneAnswer

    return isRadio
  }

  getIsUnevaluated(options = []) {
    // we call a MultiChoice question "unevaluated" (ie. a survey) if all answers are
    // "correct" or all answers are "incorrect"
    const allAnswersAreCorrect = options.every(option => option.isCorrect)
    const allAnswersAreIncorrect = options.every(option => !option.isCorrect)
    const isUnevaluated = allAnswersAreCorrect || allAnswersAreIncorrect

    return isUnevaluated
  }

  resetOptions = () => {
    const { options = [] } = this.props

    return options.map(option => {
      return { ...option, isSelected: false }
    })
  }

  randomizeOptions = options => {
    const shuffledOptions = []
    let rnd

    while (options.length) {
      rnd = ~~(Math.random() * options.length)
      shuffledOptions.push(options.splice(rnd, 1)[0])
    }

    return shuffledOptions
  }

  totalNumOfCorrectAnswers = () => {
    const { options = [] } = this.props

    return options.filter(option => option.isCorrect).length
  }

  updateSuspendData = ({
    options,
    isAnswered,
    isSubmitted,
    score,
    startTime,
    endTime,
    attempts,
  }) => {
    const { id, updateCmiInteractives, updateLMSSuspendData } = this.props

    updateCmiInteractives({
      interactives: {
        [id]: {
          isAnswered,
          isSubmitted,
          options,
          score,
          startTime,
          endTime,
          attempts,
        },
      },
    })
    updateLMSSuspendData()
  }

  textsForOptions = options => options.map(option => option.text)

  correctResponses = () => {
    const { options } = this.props
    const isUnevaluated = this.getIsUnevaluated(options)
    const allCorrectOptions = options.filter(option => option.isCorrect)
    const allCorrectOptionTexts = this.textsForOptions(allCorrectOptions)
    const allOptionTexts = this.textsForOptions(options)

    return isUnevaluated
      ? allOptionTexts
      : allCorrectOptionTexts.sort().join(JOIN)
  }

  learnerResponse = () => {
    const { options } = this.props
    const allSelectedResponses = options.filter(option => option.isSelected)
    const allSelectedResponseTexts = this.textsForOptions(allSelectedResponses)

    return allSelectedResponseTexts.sort().join(JOIN)
  }

  singleAnswerScore = () => {
    // only check the selected option, because if this is an unevaluated radio MultiChoice then we
    // might have other options that are "correct" and we don't want to flag the learner as "incorrect"
    const { options } = this.props

    return options.find(option => option.isSelected).isCorrect ? 1 : 0
  }

  multiAnswerScore() {
    // Every correct answer checked is + 1
    // Every incorrect answer checked is - 1 (down to 0)
    const { options } = this.props
    const numOfCorrectAnswersSelected = options.filter(
      option => option.isSelected && option.isCorrect,
    ).length
    const numOfIncorrectAnswersSelected = options.filter(
      option => option.isSelected && !option.isCorrect,
    ).length
    const score = numOfCorrectAnswersSelected - numOfIncorrectAnswersSelected

    return score < 0 ? 0 : score
  }

  onChange = e => {
    const {
      id,
      multichoiceUpdate,
      options,
      score,
      startTime,
      endTime,
      attempts,
    } = this.props
    const { checked, id: eventId } = e.currentTarget || {}
    const isRadio = this.getIsRadio(options)
    const newOptions = options.map(option => {
      const { id: optionId, isSelected: optionIsSelected } = option || {}
      const optionIsTarget = eventId === optionId
      const isSelected = isRadio
        ? optionIsTarget
        : optionIsTarget
        ? checked
        : optionIsSelected

      return {
        ...option,
        isSelected,
        isFocused: optionIsTarget,
      }
    })
    const newIsAnswered =
      newOptions.filter(option => option.isSelected).length > 0

    multichoiceUpdate({
      interactiveId: id,
      isAnswered: newIsAnswered,
      isSubmitted: false,
      options: newOptions,
      score,
      startTime,
      endTime,
      attempts,
    })

    this.updateSuspendData({
      options: newOptions,
      isAnswered: newIsAnswered,
      isSubmitted: false,
      score,
      startTime,
      endTime,
      attempts,
    })
  }

  onSubmit = () => {
    const {
      completeSlide,
      currentSlide,
      id,
      multichoiceSubmit,
      options,
      endTime,
      startTime,
      attempts,
    } = this.props
    const isRadio = this.getIsRadio(options)
    const score = isRadio ? this.singleAnswerScore() : this.multiAnswerScore()

    multichoiceSubmit(id, score)
    completeSlide(currentSlide)

    this.updateSuspendData({
      options,
      isAnswered: true,
      isSubmitted: true,
      score,
      startTime,
      endTime,
      attempts: attempts + 1,
    })
  }

  onReset = () => {
    const {
      attempts,
      endTime,
      id,
      multichoiceInitialize,
      score,
      startTime,
    } = this.props
    const resetOptions = this.resetOptions()
    const newStart = endTime ? endTime : startTime

    multichoiceInitialize({
      interactiveId: id,
      isAnswered: false,
      isSubmitted: false,
      options: resetOptions,
      score,
      startTime: newStart,
      endTime: null,
      attempts,
    })

    this.updateSuspendData({
      options: resetOptions,
      isAnswered: false,
      isSubmitted: false,
      score,
      startTime: newStart,
      endTime: null,
      attempts,
    })
  }

  render = () => {
    const { isSubmitted, options, score } = this.props
    const groupName = this.getGroupName()
    const isRadio = this.getIsRadio(options)
    const isUnevaluated = this.getIsUnevaluated(options)
    const totalNumOfCorrectAnswers = this.totalNumOfCorrectAnswers()

    return (
      <MultiChoiceComponent
        {...this.props}
        groupName={groupName}
        isRadio={isRadio}
        isUnevaluated={isUnevaluated}
        onOptionChange={this.onChange}
        onReset={this.onReset}
        onSubmit={this.onSubmit}
        score={score}
        submitted={isSubmitted}
        totalNumOfCorrectAnswers={totalNumOfCorrectAnswers}
      />
    )
  }
}

MultiChoiceContainer.propTypes = {
  answers: PropTypes.array.isRequired,
  attempts: PropTypes.number,
  behaviour: PropTypes.object.isRequired,
  completeSlide: PropTypes.func,
  currentSlide: PropTypes.array,
  endTime: PropTypes.string,
  id: PropTypes.string,
  isAnswered: PropTypes.bool, //clicked any answers
  isSubmitted: PropTypes.bool, //clicked check button
  multichoiceInitialize: PropTypes.func,
  multichoiceSubmit: PropTypes.func,
  multichoiceUpdate: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  score: PropTypes.number,
  shouldInitialize: PropTypes.bool,
  startTime: PropTypes.string,
  updateCmiInteractives: PropTypes.func,
  updateLMSSuspendData: PropTypes.func,
}

MultiChoiceContainer.defaultProps = {
  completeSlide() {},
  isAnswered: false,
  isSubmitted: false,
  multichoiceInitialize() {},
  multichoiceSubmit() {},
  multichoiceUpdate() {},
  options: [],
  updateCmiInteractives() {},
  updateLMSSuspendData() {},
}

const mapDispatchToProps = dispatch => ({
  completeSlide: slidePosition => dispatch(completeSlide(slidePosition)),
  multichoiceInitialize: interactive =>
    dispatch(multichoiceInitialize(interactive)),
  multichoiceSubmit: (interactiveId, score) =>
    dispatch(multichoiceSubmit(interactiveId, score)),
  multichoiceUpdate: ({
    interactiveId,
    isAnswered,
    isSubmitted,
    options,
    score,
    startTime,
    endTime,
    attempts,
  }) =>
    dispatch(
      multichoiceUpdate({
        interactiveId,
        isAnswered,
        isSubmitted,
        options,
        score,
        startTime,
        endTime,
        attempts,
      }),
    ),
  updateCmiInteractives: data => dispatch(updateCmiInteractives(data)),
  updateLMSSuspendData: () => dispatch(updateLMSSuspendData()),
})

const mapStateToProps = ({ player }, ownProps) => {
  const { id } = ownProps || {}
  const interactiveState = player.interactiveStates[id] || null

  return {
    ...interactiveState,
    currentSlide: player.currentSlide,
    shouldInitialize: interactiveState === null,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MultiChoiceContainer)
