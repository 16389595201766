import React from 'react'

import { nextSlide, prevSlide } from 'lib/state/actions/player'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getConstantByLocale, getLocaleFromSession } from 'utils/locale'

import ButtonNavigation from '../../ButtonNavigation'
import { Wrapper } from '../style'

import { BACK_BUTTON, NEXT_BUTTON } from './constants'

const currentLocale = getLocaleFromSession()
const getNextButtonText = getConstantByLocale(NEXT_BUTTON, currentLocale)
const getBackButtonText = getConstantByLocale(BACK_BUTTON, currentLocale)

export const Navigation = ({ disableBack, disableNext, prev, next }) => {
  return (
    <Wrapper>
      <ButtonNavigation
        disabled={disableBack}
        type="left"
        onClick={prev}
        aria-label="Previous Slide"
        aria-disabled={disableBack}
      >
        {getBackButtonText}
      </ButtonNavigation>
      <ButtonNavigation
        data-testid={'next'}
        disabled={disableNext}
        type="right"
        onClick={next}
        aria-label="Next Slide"
        aria-disabled={disableNext}
      >
        {getNextButtonText}
      </ButtonNavigation>
    </Wrapper>
  )
}

Navigation.propTypes = {
  disableBack: PropTypes.bool,
  disableNext: PropTypes.bool,
  next: PropTypes.func,
  prev: PropTypes.func,
}

const mapStateToProps = ({ player }) => ({
  disableBack: !player.isPrevSlide,
  disableNext: !player.isNextSlide,
})

const mapDispatchToProps = dispatch => ({
  prev: () => dispatch(prevSlide()),
  next: () => dispatch(nextSlide()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Navigation)
