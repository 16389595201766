import React, { Component } from 'react'

import PropTypes from 'prop-types'

import { H5P_IMG_ORIGIN } from '../../../lib/config'

import { ImageContainer } from './style'

class MultiChoiceImage extends Component {
  renderProps = () => {
    const { media } = this.props
    const { metadata, params } = media
    const { file, alt } = params || {}
    const { path: filePath } = file || {}
    const path = H5P_IMG_ORIGIN + filePath
    const title = { metadata } || {}

    return {
      alt,
      file,
      src: path,
      title,
    }
  }

  render = () => {
    const imgProps = this.renderProps()
    const { file } = imgProps || {}

    if (!file) {
      return null
    }

    return (
      <ImageContainer>
        <img {...imgProps} />
      </ImageContainer>
    )
  }
}

MultiChoiceImage.propTypes = {
  media: PropTypes.any.isRequired,
}

MultiChoiceImage.defaultProps = {}

export default MultiChoiceImage
