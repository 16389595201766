import styled from 'styled-components'
import SpinnerAnimatedIcon from '../SpinnerAnimatedIcon'

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  line-height: 1.375rem;
`

export const LoadingHeader = styled.span`
  margin: 0 0 0 0.5rem;
`

export const LoadingSpinner = styled(SpinnerAnimatedIcon)`
  height: 1.875rem;
  width: 1.875rem;
`
