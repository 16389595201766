import React, { Component } from 'react'

import PropTypes from 'prop-types'

import { getElementKey } from '../../utils'

import OneThirdTwoThirdColumnNoHeroComponent from './component'

class OneThirdTwoThirdColumnNoHeroContainer extends Component {
  getPropsForElementAtIndex = (elementModules, index) => {
    const { elements, onSubmit, title } = this.props
    const key = getElementKey(index)
    const firstElementIndex = 0
    const lastElementIndex = elements.length - 1
    const isFirst = index === firstElementIndex
    const isLast = index === lastElementIndex
    const elementTitle = isFirst ? title : null
    const elementProps = {
      isFirst,
      isLast,
    }
    const layoutElementProps = {
      accessibilityLabel: key,
      modules: elementModules,
      onSubmit,
      title: elementTitle,
    }

    return { elementProps, key, layoutElementProps }
  }

  getRenderProps = () => {
    const { elements, foregroundColor } = this.props
    const getElementProps = this.getPropsForElementAtIndex
    const threeColumnNoHeroProps = {
      elements,
      foregroundColor,
      getElementProps,
    }
    const renderProps = {
      threeColumnNoHeroProps,
    }

    return renderProps
  }

  render = () => {
    const { threeColumnNoHeroProps } = this.getRenderProps() || {}

    return <OneThirdTwoThirdColumnNoHeroComponent {...threeColumnNoHeroProps} />
  }
}

OneThirdTwoThirdColumnNoHeroContainer.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
  foregroundColor: PropTypes.string,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
}

OneThirdTwoThirdColumnNoHeroContainer.defaultProps = {
  elements: [],
  onSubmit() {},
}

export default OneThirdTwoThirdColumnNoHeroContainer
