import React, { Component } from 'react'

import PropTypes from 'prop-types'
import Markdown from 'react-markdown'

import { GenericCopy } from '../../text'
import { EMPTY_STRING } from '../constants'

import { QuestionTextContainer } from './style'

const ARIA_LABEL = 'DragQuestion bodyText'
const PARAGRAPH = 'paragraph'
const DISALLOWED_QUESTION_MARKDOWN_TYPES = [PARAGRAPH]

class DragQuestionBody extends Component {
  render() {
    const { text } = this.props
    const ariaLabel = ARIA_LABEL
    const textElements = text.split('\n').filter(Boolean).slice(0, 2)

    return textElements.map(text => {
      const markdown = (
        <Markdown
          disallowedTypes={DISALLOWED_QUESTION_MARKDOWN_TYPES}
          unwrapDisallowed
        >
          {text}
        </Markdown>
      )
      return (
        <QuestionTextContainer key={text}>
          <GenericCopy aria-label={ariaLabel}>{markdown}</GenericCopy>
        </QuestionTextContainer>
      )
    })
  }
}

DragQuestionBody.propTypes = {
  text: PropTypes.string,
}

DragQuestionBody.defaultProps = {
  text: EMPTY_STRING,
}

export default DragQuestionBody
