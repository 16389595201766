import styled from 'styled-components'

export const CardFaceFront = styled.div`
  align-items: center;
  backface-visibility: hidden;
  background-color: ${props =>
    props.theme.colorVars.dialogCard.textContainer.background};
  border-radius: 4px;
  box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  max-height: 100%;
  position: absolute;
  transform: rotateY(${props => (props.isFlipped ? '180deg' : '0deg')});
  transition: all 0.5s ease-in-out;
  width: 100%;
`

export const CardFaceBack = styled(CardFaceFront)`
  transform: rotateY(${props => (props.isFlipped ? '0deg' : '180deg')});
`

export const ImageContainer = styled.div`
  background-image: url(${props => props.imageUrl});
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px 4px 0 0;
  flex: ${props => (props.imageUrl ? '1' : '0')};
  height: 90%;
  max-width: 100%;
  min-height: ${props => (props.imageUrl ? '50%' : '0')};
  width: 100%;
`

export const TextContainerFront = styled.div`
  background-color: ${props =>
    props.theme.colorVars.dialogCard.textContainer.background};
  box-sizing: border-box;
  color: ${props => props.theme.colorVars.dialogCard.textContainer.fill};
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  padding: 5%;
  width: 100%;
  ${props => !props.imageUrl && 'flex: 1; border-radius: 4px 4px 0 0'};
  ${props => (props.text ? 'min-height: 20%' : '0')};

  span {
    font-size: ${props => props.theme.fonts.dialogCard.title.fontSize};
  }

  span:nth-child(n + 2) {
    margin-top: 0.75rem;
  }
`

export const TextContainerBack = styled(TextContainerFront)`
  overflow: hidden;
  span {
    position: relative;
  }
`

export const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: ${props => props.theme.fonts.dialogCard.fontFamily};
  height: 100%;
  outline: none;
  position: relative;
  transition: all 0.2s ease-in-out;
  width: 100%;

  :focus {
    outline: 4px groove
      ${props => props.theme.colorVars.dialogCard.focus.outlineColor};
  }

  :hover {
    transform: scale(1.02);

    div:last-child {
      background-color: ${props =>
        props.theme.colorVars.dialogCard.turnIndicator.backgroundHover};
      color: ${props =>
        props.theme.colorVars.dialogCard.turnIndicator.fillHover};
    }

    svg {
      fill: ${props =>
        props.theme.colorVars.dialogCard.turnIndicator.fillHover};
    }
  }
`
