import styled from 'styled-components'

export const Container = styled.div`
  align-self: center;
  align-items: center;
  border-radius: 5px;
  display: flex;
  max-width: 500px;
  padding: 20px;
  position: fixed;
  z-index: 1;
  ${({ theme }) => theme.colorVars.prepBanner};
`

export const Text = styled.span`
  ${({ theme }) => theme.fonts.prepBannerRegular};
`

export const EmphaticText = styled.span`
  ${({ theme }) => theme.fonts.prepBannerBold};
`
