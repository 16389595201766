import styled from 'styled-components'

import { CONTAINER_WIDTH } from './constants'

export const Body = styled.span`
  ${props => props.theme.fonts.lessonComplete.body};
`

export const Container = styled.div.attrs(
  ({
    theme: {
      colorVars: { lessonComplete: colors },
    },
  }) => ({
    style: {
      backgroundColor: colors.container.backgroundColor,
      height: '184px',
      padding: '0 60px 0',
      width: `${CONTAINER_WIDTH}px`,
    },
  }),
)`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  z-index: 3;
`

export const ContentContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  width: 100%;
`

export const Title = styled.h1`
  ${({ theme }) => theme.colorVars.lessonComplete.title};
  ${({ theme }) => theme.fonts.lessonComplete.title};
  margin: 28px 0;
  font-variant-ligatures: none;
`

export const Row = styled.div`
  display: flex;
  flex-direction: flex;
  ${({ theme }) => theme.colorVars.lessonComplete.body}
  ${({ theme }) => theme.fonts.lessonComplete.body}
  width: 100%;
  button {
    margin: 10px;
  }
`

export const Button = styled.button`
  ${({ theme, variant }) =>
    variant === 'blank'
      ? theme?.colorVars?.lessonComplete?.markCompleteModal?.blankButton
      : theme?.colorVars?.lessonComplete?.lessonButtonFilled}
  ${({ theme }) =>
    theme?.fonts?.lessonComplete?.exitLesson}
  border-radius: 5px;
  color: ${props => props.color};
  cursor: pointer;
  height: 43px;
  width: 100%;
  padding: 0 15px;
  transition: background-color 0.2s ease;
  z-index: 3;
  :hover {
    ${({ theme }) => theme?.colorVars?.lessonComplete?.exitLessonHover}
  }
`
