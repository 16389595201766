import React from 'react'

import PropTypes from 'prop-types'

import HttpErrorMessage from '../HttpErrorMessage'

class PlayerErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    error: PropTypes.object,
    logger: PropTypes.object,
    message: PropTypes.string,
  }
  static defaultProps = {
    suppress: false,
    showDetails: true,
  }

  static getDerivedStateFromError = error => {
    return { hasError: true, error: error }
  }

  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch = (error, info) => {
    console.error(error, info)
    if (this.props.logger) {
      this.props.logger.logException({
        error,
        exception: error,
        severityLevel: 'Error',
        properties: { error },
      })
    }
    this.setState({ error: error, info: info })
  }

  render() {
    const { hasError } = this.state
    if (hasError) {
      return <HttpErrorMessage />
    }

    return this.props.children
  }
}

export default PlayerErrorBoundary
