import * as React from 'react'

const HeartsWithStars = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={65} height={69} {...props}>
    <title>{'ss_hearts_ccmodal'}</title>
    <g fill="none" fillRule="evenodd" transform="translate(-2 1)">
      <path
        fill="#E77564"
        fillRule="nonzero"
        d="M39.903 64.003c13.458-33.36-17.461-36.09-14.935-17.32a.47.47 0 0 1-.156.46.47.47 0 0 1-.479.073C12.508 43.032 6.461 55.35 15.57 62.555c5.582 4.776 19.744 3.12 23.284 2.33.48-.092.876-.424 1.05-.882Z"
      />
      <path
        stroke="#E77564"
        strokeWidth={2}
        d="M49.311 36.981c21.554-1.14 15.198-18.646 5.631-12.513a.282.282 0 0 1-.289.032.282.282 0 0 1-.161-.242c-.723-7.491-8.954-7.656-10.564-.877-1.181 4.247 3.31 11.528 4.638 13.253a.842.842 0 0 0 .745.347Z"
      />
      <path
        fill="#E77564"
        d="m36.548 6.995-.857.45a.737.737 0 0 1-1.07-.777l.163-.955a1 1 0 0 0-.287-.885l-.694-.676a.737.737 0 0 1 .409-1.257l.958-.14a1 1 0 0 0 .753-.547l.429-.868a.737.737 0 0 1 1.322 0l.429.868a1 1 0 0 0 .753.547l.958.14a.737.737 0 0 1 .409 1.257l-.694.676a1 1 0 0 0-.287.885l.164.955a.737.737 0 0 1-1.07.777l-.858-.45a1 1 0 0 0-.93 0Z"
      />
      <path
        stroke="#3C6E90"
        strokeWidth={2}
        d="m14.264 33.06-1.829 2.896a1 1 0 0 1-1.783-.187l-1.187-3.213a1 1 0 0 0-.691-.623l-3.32-.844a1 1 0 0 1-.372-1.754l2.689-2.12a1 1 0 0 0 .378-.851l-.223-3.417a1 1 0 0 1 1.553-.897l2.848 1.902a1 1 0 0 0 .926.097l3.181-1.268a1 1 0 0 1 1.333 1.2l-.929 3.296a1 1 0 0 0 .194.91l2.189 2.634a1 1 0 0 1-.73 1.639l-3.421.135a1 1 0 0 0-.806.466Z"
      />
      <circle cx={53.013} cy={48.5} r={1.5} stroke="#3C6E90" />
      <circle cx={32.035} cy={21.522} r={2.522} fill="#3C6E90" />
      <circle cx={20.557} cy={10.043} r={2} stroke="#3C6E90" />
    </g>
  </svg>
)

export default HeartsWithStars
