import React from 'react'
import PropTypes from 'prop-types'
import YieldIcon from '../YieldIcon'
import { HEADER_TEXT, LOG_OUT, SUB_HEADER_TEXT } from './constants'
import {
  ButtonWrapper,
  ContentWrapper,
  Header,
  LogOutButton,
  StyledBodyCopy,
  StyledContainer,
  SubHeader,
} from './component.styles'

const propTypes = {
  error: PropTypes.object,
  logOut: PropTypes.func,
}

const AuthZeroErrorMessage = ({ error, logOut }) => {
  const errorMessage = error.message

  return (
    <StyledContainer>
      <ContentWrapper>
        <YieldIcon dataTestId="icon-warning" />
        <Header dataTestId="error-details-header">{HEADER_TEXT}</Header>
        <SubHeader data-testid="error-details-subheader">
          {SUB_HEADER_TEXT}
        </SubHeader>
      </ContentWrapper>
      <ButtonWrapper key="button-error-wrapper">
        <LogOutButton dataTestId="button-try-again" onClick={logOut}>
          {LOG_OUT}
        </LogOutButton>
      </ButtonWrapper>
      <StyledBodyCopy data-testid="details-error-code">
        {errorMessage}
      </StyledBodyCopy>
    </StyledContainer>
  )
}

AuthZeroErrorMessage.propTypes = propTypes
export default AuthZeroErrorMessage
