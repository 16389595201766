import { FlagsToggle } from 'flags'
import { isLocaleEnglishUS } from 'utils/locale'

import { version1 } from '../config/version'
import {
  completeSlide,
  dialogCardInitialize,
  dragLabelInitialize,
  dragQuestionInitialize,
  dragWordInitialize,
  gotoSlide,
  imageHotspotsInitialize,
  imageJuxtapositionInitialize,
  multichoiceInitialize,
  viewedSlide,
} from '../state/actions/player'

// Take the data from cmi.suspend_data (retrieved from the LMS) and apply it to Redux
const rehydrateRedux = async (store, locale) => {
  const state = store.getState()

  const { cmi, player } = state || {}
  const { location: suspendedCurrentSlide, suspend_data } = cmi || {}
  const {
    interactives: cmiInteractivesSuspendData,
    interactivesLocalized,
    slides: suspendedSlides,
  } = suspend_data || {}
  const { sections: playerSections } = player || {}

  applySlideStates({
    suspendedSlides,
    playerSections,
    suspendedCurrentSlide,
    store,
  })
  applyInteractivesSuspendedData({
    suspendData: cmiInteractivesSuspendData,
    store,
    locale,
    interactivesLocalized,
  })
}

// Apply suspended slide states from LMS cmi data to current slides
const applySlideStates = ({
  suspendedSlides,
  playerSections,
  suspendedCurrentSlide,
  store,
}) => {
  setSectionsSlideCompletionStates(playerSections, suspendedSlides, store)
  goToSuspendedCurrentSlide(suspendedCurrentSlide, store)
}

// Find the suspended current slide and go to it
const goToSuspendedCurrentSlide = (suspendedCurrentSlide, store) => {
  if (
    typeof suspendedCurrentSlide === 'string' &&
    suspendedCurrentSlide !== ''
  ) {
    const [slide, slideIndex] = suspendedCurrentSlide.split(',')

    store.dispatch(gotoSlide(Number(slide), Number(slideIndex)))
  }
}

// Set completion states for slides in an array of sections
const setSectionsSlideCompletionStates = (sections, suspendedSlides, store) => {
  sections.forEach((section, sectionIndex) =>
    setSectionSlideCompletionStates(
      section,
      sectionIndex,
      suspendedSlides,
      store,
    ),
  )
}

//Set completion states for slides in an individual section
const setSectionSlideCompletionStates = (
  section,
  sectionIndex,
  suspendedSlides,
  store,
) => {
  const { fields } = section || {}
  const { slides } = fields || {}

  if (!suspendedSlides || !fields) {
    return
  }

  slides.forEach((slide, slideIndex) =>
    setSlideCompletionState(
      slide,
      slideIndex,
      sectionIndex,
      suspendedSlides,
      store,
    ),
  )
}

// Set completion state for an individual slide
const setSlideCompletionState = (
  slide,
  slideIndex,
  sectionIndex,
  suspendedSlides,
  store,
) => {
  Object.keys(suspendedSlides).forEach(onSlideAndSection => {
    const slideAndSectionToHydrate = `${sectionIndex},${slideIndex}`

    if (onSlideAndSection == slideAndSectionToHydrate) {
      const suspendedSlideCompletionState = suspendedSlides[onSlideAndSection]
      // slide completion states:
      // 0: slide has not been visited
      // 1: slide has been visited, but is not complete
      // 2: slide has been completed
      switch (suspendedSlideCompletionState) {
        case 0:
          store.dispatch(viewedSlide(slideAndSectionToHydrate, false))
          break
        case 1:
          store.dispatch(viewedSlide(slideAndSectionToHydrate))
          break
        case 2:
          store.dispatch(completeSlide(slideAndSectionToHydrate))
          break
        default:
          break
      }
    }
  })
}

const applyInteractivesSuspendedData = ({
  interactivesLocalized = {},
  locale,
  store,
  suspendData,
}) => {
  const toggledFn = FlagsToggle({
    flagSubcriptions: ['feature-LEARN-11000-language-toggle'],
    onCode: on_applyInteractivesSuspendedData,
    offCode: off_applyInteractivesSuspendedData,
    onCodeArgs: [
      {
        interactivesLocalized,
        locale,
        store,
        suspendData,
      },
    ],
    offCodeArgs: [
      {
        interactivesLocalized,
        locale,
        store,
        suspendData,
      },
    ],
  })

  return toggledFn()
}
// Apply suspended interactive states from LMS cmi data to current interactives
const on_applyInteractivesSuspendedData = ({
  interactivesLocalized = {},
  locale,
  store,
  suspendData,
}) => {
  if (!suspendData) {
    return
  }

  const isLocaleUS = isLocaleEnglishUS(locale)
  const localeSpecificSuspendData = isLocaleUS
    ? suspendData
    : interactivesLocalized[locale]
  let localeSpecificSuspendDataKeys

  if (isLocaleUS) {
    localeSpecificSuspendDataKeys = Object.keys(localeSpecificSuspendData)
  } else {
    if (interactivesLocalized[locale]) {
      localeSpecificSuspendDataKeys = Object.keys(interactivesLocalized[locale])
    } else {
      localeSpecificSuspendDataKeys = []
    }
  }

  localeSpecificSuspendDataKeys.forEach(interactiveId => {
    const interactive = localeSpecificSuspendData[interactiveId]
    const {
      isInteractiveDialogCards,
      isInteractiveDragLabel,
      isInteractiveDragQuestion,
      isInteractiveDragWord,
      isInteractiveImageHotspots,
      isInteractiveImageJuxtaposition,
      isInteractiveMultiChoice,
    } = version1 || {}
    const isDialogCards = isInteractiveDialogCards(interactive)
    const isDragLabel = isInteractiveDragLabel(interactive)
    const isDragQuestion = isInteractiveDragQuestion(interactive)
    const isDragWord = isInteractiveDragWord(interactive)
    const isImageHotspots = isInteractiveImageHotspots(interactive)
    const isImageJuxtaposition = isInteractiveImageJuxtaposition(interactive)
    const isMultiChoice = isInteractiveMultiChoice(interactive)

    //TODO: v1 save state data does not have an interactive type or version tag. For now we avoid collision using v1 validators,
    // but we should improve this in subsequent versions.
    // Opened ticket #LEARN-7377
    // when initializing these values (currently only for multichoice), we default the values so that if there is a data mismatch because
    // of versioning, bad data or missing data can be handled so the interactive doesn't break.

    if (isMultiChoice) {
      store.dispatch(multichoiceInitialize({ interactiveId, ...interactive }))
    }
    if (isDialogCards) {
      store.dispatch(
        dialogCardInitialize(
          interactiveId,
          interactive.isFlipped,
          interactive.isComplete,
        ),
      )
    }
    if (isDragLabel) {
      store.dispatch(dragLabelInitialize({ interactiveId, ...interactive }))
    }
    if (isDragQuestion) {
      store.dispatch(
        dragQuestionInitialize({
          interactiveId,
          ...interactive,
        }),
      )
    }
    if (isImageJuxtaposition) {
      store.dispatch(
        imageJuxtapositionInitialize(interactiveId, interactive.position),
      )
    }
    if (isImageHotspots) {
      store.dispatch(
        imageHotspotsInitialize(
          interactiveId,
          interactive.targets,
          interactive.score,
        ),
      )
    }
    if (isDragWord) {
      store.dispatch(
        dragWordInitialize(interactiveId, {
          ...interactive,
        }),
      )
    }
  })
}

const off_applyInteractivesSuspendedData = ({ store, suspendData }) => {
  if (!suspendData) {
    return
  }

  Object.keys(suspendData).forEach(interactiveId => {
    const interactive = suspendData[interactiveId]
    const {
      isInteractiveDialogCards,
      isInteractiveDragLabel,
      isInteractiveDragQuestion,
      isInteractiveDragWord,
      isInteractiveImageHotspots,
      isInteractiveImageJuxtaposition,
      isInteractiveMultiChoice,
    } = version1 || {}
    const isDialogCards = isInteractiveDialogCards(interactive)
    const isDragLabel = isInteractiveDragLabel(interactive)
    const isDragQuestion = isInteractiveDragQuestion(interactive)
    const isDragWord = isInteractiveDragWord(interactive)
    const isImageHotspots = isInteractiveImageHotspots(interactive)
    const isImageJuxtaposition = isInteractiveImageJuxtaposition(interactive)
    const isMultiChoice = isInteractiveMultiChoice(interactive)

    //TODO: v1 save state data does not have an interactive type or version tag. For now we avoid collision using v1 validators,
    // but we should improve this in subsequent versions.
    // Opened ticket #LEARN-7377
    // when initializing these values (currently only for multichoice), we default the values so that if there is a data mismatch because
    // of versioning, bad data or missing data can be handled so the interactive doesn't break.

    isMultiChoice &&
      store.dispatch(multichoiceInitialize({ interactiveId, ...interactive }))
    isDialogCards &&
      store.dispatch(
        dialogCardInitialize(
          interactiveId,
          interactive.isFlipped,
          interactive.isComplete,
        ),
      )
    isDragLabel &&
      store.dispatch(dragLabelInitialize({ interactiveId, ...interactive }))
    isDragQuestion &&
      store.dispatch(
        dragQuestionInitialize({
          interactiveId,
          ...interactive,
        }),
      )
    isImageJuxtaposition &&
      store.dispatch(
        imageJuxtapositionInitialize(interactiveId, interactive.position),
      )
    isImageHotspots &&
      store.dispatch(
        imageHotspotsInitialize(
          interactiveId,
          interactive.targets,
          interactive.score,
        ),
      )
    isDragWord &&
      store.dispatch(
        dragWordInitialize(interactiveId, {
          ...interactive,
        }),
      )
  })
}

export default rehydrateRedux
