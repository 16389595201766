import FlagsComponentRouter from './src/FlagsAccessors/FlagsComponentRouter'
import FlagsSingleton from './src/FlagsManager'

import FlagsToggle from './src/FlagsAccessors/FlagsToggle'
import FlagsContext from './src/FlagsSetup/FlagsContext'
import FlagsProvider from './src/FlagsSetup/FlagsProvider'

export {
  FlagsComponentRouter,
  FlagsSingleton,
  FlagsToggle,
  FlagsContext,
  FlagsProvider,
}
