import React, { Component } from 'react'

import PropTypes from 'prop-types'

import { TitleText, Wrapper } from './style'

class TitleComponent extends Component {
  render() {
    const { title } = this.props
    const titleDataTestId = 'presentation-title'

    return (
      <Wrapper>
        <TitleText data-testid={titleDataTestId}>{title}</TitleText>
      </Wrapper>
    )
  }
}

TitleComponent.propTypes = {
  title: PropTypes.string,
}
export default TitleComponent
