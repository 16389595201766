import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const Wrapper = styled.div`
  margin-top: 0.75rem;
`

export const Label = styled.label.attrs(({ dataTestId, checked, name }) => ({
  'aria-checked': checked,
  'aria-describedby': name,
  'data-testid': `${dataTestId}`,
}))`
  display: inline-flex;
  margin-top: 0.57rem;
  line-height: 1.15rem;
  align-items: flex-start;

  @media only screen and (max-width: 580px) {
    font-size: 1rem;
  }
  & > p {
    max-width: none;
    width: 100%;
    margin: 0px;
    margin-top: -5px;
  }
  &:hover {
    cursor: pointer;
  }

  &::before {
    content: '';
    display: inline-block;
    position: relative;
    flex-shrink: 0;
    min-width: 1rem;
    min-height: 1rem;
    background-color:  background-color: ${themeGet(
      'colors.singleSelectQuestion.primaryBackground',
      '#fff',
    )};
    border: 2px solid
      ${themeGet('colors.multiSelectQuestion.borderColor', '#00a3a0')};
    background-repeat: no-repeat;
    background-position: center;
    transition: transform 0.2s, border 0.2s;
    border-radius: 50%;
    margin: 0px 12px 0px 0px;
  }
`

export const RadioButton = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  margin: 10px !important;
  padding: 0 !important;
  opacity: 0;
  height: 0;
  width: 0;
  pointer-events: none;

  &:checked + label::before {
    background-color: ${themeGet(
      'colors.singleSelectQuestion.primaryBackground',
      '#fff',
    )};
    box-shadow: none;
    border-color: ${themeGet(
      'colors.multiSelectQuestion.borderColor',
      '#00a3a0',
    )};
    transition: transform 0.2s;
  }
  &:active + label::before {
    transform: scale(0.8);
    transition: transform 0.2s;
  }
  &:checked:active + label::before {
    transform: none;
    transition: none;
  }
  &:checked + label {
    color: ${themeGet('colors.singleSelectQuestion.questionText', '#393939')};
    font-weight: 700;
  }
  &:focus + label {
    border: 1px solid
      ${themeGet('colors.singleSelectQuestion.focus', '#00a3a0')};
    box-shadow: 0 0 2px
      ${themeGet('colors.singleSelectQuestion.focus', '#00a3a0')};
    border-radious: 1px;
  }

  &:hover + label::before {
    background-color: ${themeGet(
      'colors.singleSelectQuestion.backgroundColor',
      '#b1dcdd',
    )};
  }

  &:checked + label::before {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cg class='nc-icon-wrapper' fill='%2300a3a0'%3E%3Ccircle cx='8' cy='8' r='8' fill='%2300a3a0'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
    background-size: 0.5rem;
  }
`
