import React, { useEffect, useRef } from 'react'

import PropTypes from 'prop-types'

import exclamationSvg from '../../../assets/images/exclamationWhiteInTriangleOrange.svg'
import DetailsRevealer from '../DetailsRevealer'

import {
  DEFAULT_DETAILED_MESSAGE,
  DEFAULT_MESSAGE,
  DEFAULT_MESSAGE_NO_RELOAD,
  IMAGE_DATA_TEST_ID,
  MESSAGE_CONTAINER_DATA_TEST_ID,
  MESSAGE_DATA_TEST_ID_PREFIX,
  NEW_LINE,
  RETRY_BUTTON_DATA_TEST_ID,
  RETRY_BUTTON_TEXT,
  TITLE,
  TITLE_DATA_TEST_ID,
} from './constants'
import {
  ErrorContainer,
  ExclamationImage,
  HorizontalContainer,
  Message,
  MessageContainer,
  RetryButton,
  Title,
  VerticalContainer,
} from './style'

const getMessageComponents = shouldTryAgain => {
  const message = shouldTryAgain ? DEFAULT_MESSAGE : DEFAULT_MESSAGE_NO_RELOAD
  const textElements = message
    .split(NEW_LINE)
    .filter(Boolean)
    .slice(0, 2)
  const textComponents = textElements.map((textElement, index) => {
    const key = `${index},${textElement}`
    const dataTestId = `${MESSAGE_DATA_TEST_ID_PREFIX}${index}`
    const textComponent = (
      <Message data-testid={dataTestId} key={key}>
        {textElement}
      </Message>
    )

    return textComponent
  })

  return textComponents
}

const HttpErrorMessageComponent = ({ message, onRetryClick, showRetry }) => {
  const retryButtonRef = useRef(null)
  useEffect(() => retryButtonRef.current?.focus(), []) //runs only on mount. Focus on retry button first

  const messageComponents = getMessageComponents(showRetry)

  return (
    <VerticalContainer>
      <HorizontalContainer>
        <ErrorContainer>
          <ExclamationImage
            data-testid={IMAGE_DATA_TEST_ID}
            src={exclamationSvg}
          />
          <Title data-testid={TITLE_DATA_TEST_ID}>{TITLE}</Title>
          <MessageContainer data-testid={MESSAGE_CONTAINER_DATA_TEST_ID}>
            {messageComponents}
          </MessageContainer>
          {showRetry && (
            <RetryButton
              data-testid={RETRY_BUTTON_DATA_TEST_ID}
              onClick={onRetryClick}
              ref={retryButtonRef}
            >
              {RETRY_BUTTON_TEXT}
            </RetryButton>
          )}
          <DetailsRevealer>{message}</DetailsRevealer>
        </ErrorContainer>
      </HorizontalContainer>
    </VerticalContainer>
  )
}

HttpErrorMessageComponent.propTypes = {
  message: PropTypes.string,
  onRetryClick: PropTypes.func,
  showRetry: PropTypes.bool,
}

HttpErrorMessageComponent.defaultProps = {
  message: DEFAULT_DETAILED_MESSAGE,
  onRetryClick() {},
}

export default HttpErrorMessageComponent
