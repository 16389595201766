import React, { Component } from 'react'

import PropTypes from 'prop-types'

import { MultiChoiceStyledButton } from './style'

class MultiChoiceButtonComponent extends Component {
  render = () => {
    const { ariaLabel, dataTestId, disabled, onClick, setRef, text } =
      this.props

    return (
      <MultiChoiceStyledButton
        aria-label={ariaLabel}
        data-testid={dataTestId}
        disabled={disabled}
        onClick={onClick}
        ref={setRef}
      >
        {text}
      </MultiChoiceStyledButton>
    )
  }
}

MultiChoiceButtonComponent.propTypes = {
  ariaLabel: PropTypes.string,
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  setRef: PropTypes.func,
  text: PropTypes.string,
}

MultiChoiceButtonComponent.defaultProps = {
  onClick() {},
  setRef() {},
}

export default MultiChoiceButtonComponent
