import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { themeGet } from 'styled-system'
import { Button } from 'grommet'
import EditIcon from '../EditIcon'

const EditIconButton = styled(Button).attrs(({ a11yTitle, dataTestId }) => ({
  a11yTitle,
  'data-testid': dataTestId,
  plain: true,
}))`
  svg {
    path {
      fill: ${themeGet('colors.buttons.iconBase')};
      transition: all 0.2s ease-in-out;
    }
  }
  &:hover svg {
    path {
      fill: ${themeGet('colors.buttons.iconHover')};
    }
  }
`

EditIconButton.propTypes = {
  a11yTitle: PropTypes.string.isRequired,
  dataTestId: PropTypes.string.isRequired,
  icon: PropTypes.node,
  onClick: PropTypes.func.isRequired,
}

EditIconButton.defaultProps = {
  icon: <EditIcon />,
}

export default EditIconButton
