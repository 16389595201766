import styled from 'styled-components'

export const BaseContainer = styled.div.attrs(
  ({
    height,
    theme: {
      colorVars: { dragAndDrop: colors },
    },
    width,
  }) => ({
    backgroundColor: colors.draggable.backgroundColor,
    color: colors.draggable.color,
    style: {
      height,
      width,
    },
  }),
)`
  background-color: ${props => props.backgroundColor};
  border-radius: 5px;
  color: ${props => props.color};
  cursor: grab;
  display: flex;
  user-select: none;
  vertical-align: middle;
`

export const BaseHoverIndicator = styled.div`
  height: 100%;
  width: 5px;
`

export const BaseGrip = styled.span.attrs(
  ({
    theme: {
      colorVars: { dragAndDrop: colors },
      fonts: { dragAndDrop: fonts },
    },
  }) => ({
    style: {
      color: colors.grip.color,
      fontFamily: fonts.grip.fontFamily,
      fontSize: `${fonts.grip.fontSize}rem`,
      height: '20px',
      letterSpacing: fonts.grip.letterSpacing,
      lineHeight: fonts.grip.lineHeight,
      paddingBottom: '5px',
      paddingLeft: '5px',
      paddingRight: '10px',
      paddingTop: '3px',
      textShadow: `1px 0 1px ${colors.grip.textShadowColor}`,
      width: '10px',
    },
  }),
)`
  content: '....';
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;

  &::after {
    content: '.. .. ..';
  }
`

export const BaseText = styled.div.attrs(
  ({
    theme: {
      colorVars: { dragAndDrop: colors },
      fonts: { dragAndDrop: fonts },
    },
  }) => ({
    style: {
      color: colors.draggable.color,
      fontFamily: fonts.draggable.fontFamily,
      fontSize: `${fonts.draggable.fontSize}rem`,
      fontWeight: fonts.draggable.fontWeight,
    },
  }),
)``
