import styled from 'styled-components'
import PropTypes from 'prop-types'
import { themeGet } from 'styled-system'

const CaretSortIcon = styled.i.attrs(({ active, direction, dataTestId }) => ({
  active,
  className: `fas fa-caret-${direction}`,
  'data-testid':
    dataTestId || `caret-${active ? 'active' : 'inactive'}-sort-${direction}`,
  caretColor: active
    ? themeGet('colors.darkBlue')
    : themeGet('colors.backgroundNeutralGrayOneAccent'),
}))`
  color: ${({ caretColor }) => caretColor};
`

CaretSortIcon.propTypes = {
  active: PropTypes.bool.isRequired,
  direction: PropTypes.string.isRequired,
}

CaretSortIcon.displayName = 'CaretSortIcon'

export default CaretSortIcon
