export const GET_DEFAULT_LOG_SETTINGS = () => ({
  // add additonal plugin options here
  buffering: true,
  bitrateQuality: true,
  debug: false,
  downloadInfo: true,
  end: true,
  error: true,
  fullscreen: true,
  loaded: true,
  playbackSummary: true,
  playTime: true,
  percentPlayed: true,
  play: true,
  pause: true,
  seek: true,
  viewed: true,
})

export const checkVideoPlayerTabIndex = () => {
  const vpElement = document.querySelector('[aria-label="video player"]')
  if (vpElement) {
    vpElement.tabIndex = -1
  }
}

export const getLoadDelay = (current, increment, max) => {
  if (current >= max) {
    return 0
  } else {
    return current + increment
  }
}
