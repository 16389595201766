import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const RootWrapper = styled.button.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  display: inline-block;
  position: relative;
  border: none;
  background: transparent;
  padding: 0px;
  ${themeGet('breakpoints.tablet')} {
    width: 100%;
  }
`

export const TooltipContentWrapper = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
  className: 'tooltip-content',
}))`
  position: absolute;
  border-radius: 11px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.375rem 0.875rem;
  color: ${themeGet('colors.darkerDarkBlue')};
  background-color: ${themeGet('colors.white')};
  font-size: 0.875rem;
  font-family: ${themeGet('fontFamily')};
  line-height: 1.25rem;
  z-index: 100;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.12);
  // TODO: double check tooltip styling in the LUM
  // top: calc(110px * -1);
  width: 15rem;
  font-weight: normal;
`
