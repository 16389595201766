import { backoff } from '@cfc/exponential-backoff'

import { CODE_SUCCESS, INITIAL_STATE, MAX_FIND_DEPTH } from './constants'

const getInitialState = () => Object.assign({}, INITIAL_STATE)
let state = getInitialState()

const getOverloadedParentApi = parentApi => ({
  ...parentApi,
  apiGetValue: parentApi.GetValue,
  apiSetValue: parentApi.SetValue,
  apiTerminate: parentApi.Terminate,
  GetValue: getValue,
  SetValue: setValue,
  Terminate: terminate,
})

const getStandaloneApi = () => ({
  GetValue: getValue,
  SetValue: setValue,
  Terminate: terminate,
})

export const hasParentApiInstance = () => {
  const parentApi = getParentApiInstance()
  const hasParentApi = parentApi != null

  return hasParentApi
}

// Navigate through parent containers and find nearest SCORM 2004 API window object, if it exists
const getParentApiInstance = () => {
  let depth = 0
  let win = window
  let { API_1484_11: api } = win || {}

  while (!api && depth <= MAX_FIND_DEPTH) {
    if ((!win.parent && !win.opener) || (win.parent && win.parent === win)) {
      break
    }

    win = win.parent || win.opener
    api = win.API_1484_11
    depth++
  }

  return api
}

const getInstance = async initAsyncFunction => {
  const { hasRequestedInitialization } = state || {}

  // If instance promise already exists, use that one
  if (state.instance) {
    return await state.instance // await promise
  }

  // If this is the first request for initialization, kick off initialization
  if (!hasRequestedInitialization) {
    state.hasRequestedInitialization = true
    state.instance = initAsyncFunction() // save promise
  }

  // Initialization is done. Does an instance exist now? Return the promise.
  if (state.instance) {
    return await state.instance // await promise
  }
}

const wrapApi = async () => {
  const parentApi = getParentApiInstance()

  state.isStandalone = !parentApi

  const { isStandalone } = state || {}
  const infoMessage = isStandalone
    ? 'Running standalone. Using local api.'
    : 'Running in iFrame. Using parent api.'

  console.info(infoMessage)

  parentApi && (await parentApi.Initialize())

  const api = parentApi ? getOverloadedParentApi(parentApi) : getStandaloneApi()

  return api
}

export const getApiClient = async () => {
  const apiClient = await getInstance(wrapApi)

  return apiClient
}

const checkForErrors = (api, elem) => {
  const code = api.GetLastError()
  const diag = api.GetDiagnostic()

  if (code !== CODE_SUCCESS) {
    const str = api.GetErrorString(code)

    console.error(`Error getting value ${elem} - ${code}: ${str}`)
  }

  diag &&
    console.warn(
      `Diagnostics received from API when getting value ${elem} - ${diag}`,
    )
}

const getValue = async elem => {
  const { isStandalone } = state || {}

  if (isStandalone) {
    return
  }

  const api = await getApiClient()
  let val = await backoff(() => api.apiGetValue(elem))
  val = val.result || val

  checkForErrors(api, elem)

  // check for boolean types
  if (['true', 'false'].includes(val)) {
    return val === 'true'
  }

  // check for real, float, int types
  const asNumber = Number(val)

  if (!isNaN(asNumber) && val !== '') {
    return asNumber
  }

  // left with time, interval, string, id, or enum types

  return val
}

const setValue = async (elem, val) => {
  const { isStandalone } = state || {}

  if (isStandalone) {
    return
  }

  // TODO: convert float seconds duration values to ISO duration value

  const api = await getApiClient()

  await backoff(() => api.apiSetValue(elem, val))
  checkForErrors(api, elem)
}

const terminate = async () => {
  const { isStandalone } = state || {}

  if (!isStandalone) {
    const api = await getApiClient()
    await api.apiTerminate()
  }

  state = getInitialState()
}
