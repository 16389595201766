import React from 'react'
import PropTypes from 'prop-types'
import { Paragraph } from 'grommet'

const LeadInCopy = ({ children, size, textAlign }) => (
  <Paragraph
    a11yTitle={'Lead In Copy'}
    data-testid="lead-in-copy"
    responsive
    size={size}
    textAlign={textAlign}
  >
    {children}
  </Paragraph>
)

LeadInCopy.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
  textAlign: PropTypes.string,
}

LeadInCopy.defaultProps = {
  size: 'medium',
  textAlign: 'start',
}

export default LeadInCopy
