/* istanbul ignore file */
// we don't unit test SVGs
import React from 'react'

const SpinnerAnimatedIcon = () => (
  <svg
    height="31px"
    preserveAspectRatio="xMidYMid"
    viewBox="0 0 100 100"
    width="31px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="rotate(0 50 50)">
      <rect
        fill="currentColor"
        height="17"
        rx="8.5"
        ry="8.5"
        width="17"
        x="41.5"
        y="4.5"
      >
        <animate
          attributeName="opacity"
          begin="-0.8888888888888888s"
          dur="1s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        />
      </rect>
    </g>
    <g transform="rotate(40 50 50)">
      <rect
        fill="currentColor"
        height="17"
        rx="8.5"
        ry="8.5"
        width="17"
        x="41.5"
        y="4.5"
      >
        <animate
          attributeName="opacity"
          begin="-0.7777777777777778s"
          dur="1s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        />
      </rect>
    </g>
    <g transform="rotate(80 50 50)">
      <rect
        fill="currentColor"
        height="17"
        rx="8.5"
        ry="8.5"
        width="17"
        x="41.5"
        y="4.5"
      >
        <animate
          attributeName="opacity"
          begin="-0.6666666666666666s"
          dur="1s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        />
      </rect>
    </g>
    <g transform="rotate(120 50 50)">
      <rect
        fill="currentColor"
        height="17"
        rx="8.5"
        ry="8.5"
        width="17"
        x="41.5"
        y="4.5"
      >
        <animate
          attributeName="opacity"
          begin="-0.5555555555555556s"
          dur="1s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        />
      </rect>
    </g>
    <g transform="rotate(160 50 50)">
      <rect
        fill="currentColor"
        height="17"
        rx="8.5"
        ry="8.5"
        width="17"
        x="41.5"
        y="4.5"
      >
        <animate
          attributeName="opacity"
          begin="-0.4444444444444444s"
          dur="1s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        />
      </rect>
    </g>
    <g transform="rotate(200 50 50)">
      <rect
        fill="currentColor"
        height="17"
        rx="8.5"
        ry="8.5"
        width="17"
        x="41.5"
        y="4.5"
      >
        <animate
          attributeName="opacity"
          begin="-0.3333333333333333s"
          dur="1s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        />
      </rect>
    </g>
    <g transform="rotate(240 50 50)">
      <rect
        fill="currentColor"
        height="17"
        rx="8.5"
        ry="8.5"
        width="17"
        x="41.5"
        y="4.5"
      >
        <animate
          attributeName="opacity"
          begin="-0.2222222222222222s"
          dur="1s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        />
      </rect>
    </g>
    <g transform="rotate(280 50 50)">
      <rect
        fill="currentColor"
        height="17"
        rx="8.5"
        ry="8.5"
        width="17"
        x="41.5"
        y="4.5"
      >
        <animate
          attributeName="opacity"
          begin="-0.1111111111111111s"
          dur="1s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        />
      </rect>
    </g>
    <g transform="rotate(320 50 50)">
      <rect
        fill="currentColor"
        height="17"
        rx="8.5"
        ry="8.5"
        width="17"
        x="41.5"
        y="4.5"
      >
        <animate
          attributeName="opacity"
          begin="0s"
          dur="1s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        />
      </rect>
    </g>
  </svg>
)

export default SpinnerAnimatedIcon
