/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

export default () => (
  <svg
    height="22px"
    version="1.1"
    viewBox="0 0 21 22"
    width="21px"
    x="0px"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
  >
    <g transform="translate(-69.000000, -267.000000)">
      <g transform="translate(40.000000, 40.000000)">
        <g transform="translate(27.000000, 138.547350)">
          <g id="staying-calm-icon" transform="translate(2.000000, 89.000000)">
            <defs>
              <filter
                filterUnits="userSpaceOnUse"
                height="20.6"
                width="20.6"
                x="0"
                y="0"
              >
                <feColorMatrix
                  type="matrix"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                />
              </filter>
            </defs>
            <mask
              height="20.6"
              maskUnits="userSpaceOnUse"
              width="20.6"
              x="0"
              y="0"
            >
              <g className="st0">
                <polygon
                  className="st1"
                  points="0,0 20.6,0 20.6,20.6 0,20.6 							"
                />
              </g>
            </mask>
            <path
              className="st2"
              d="M16.8,8.3c-0.7,0.6-1.6,1-2.5,1l-0.1,0c-1,0-1.8-0.4-2.5-1.1c-0.1-0.2-0.2-0.4-0.2-0.6
						c0-0.2,0.1-0.4,0.3-0.6c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.3c0.4,0.4,0.8,0.6,1.4,0.6c0,0,0,0,0,0
						c0.5,0,1-0.2,1.4-0.6c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.3C17.1,7.5,17.1,8,16.8,8.3 M15.4,14.8c-1.4,1.3-3.1,2-5,2
						c-2,0-3.8-0.8-5.1-2.2c-0.3-0.3-0.3-0.8,0-1.1c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.3c1,1.1,2.4,1.7,3.9,1.7
						c1.5,0,2.9-0.5,4-1.5c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.3C15.7,14,15.7,14.5,15.4,14.8 M3.6,7.6
						c0-0.2,0.1-0.4,0.3-0.6C4,6.9,4.2,6.8,4.4,6.8c0.2,0,0.4,0.1,0.6,0.3c0.7,0.8,2,0.8,2.8,0.1C7.9,7,8.1,6.9,8.3,6.9
						c0.2,0,0.4,0.1,0.6,0.3c0.3,0.3,0.3,0.8,0,1.1c-0.7,0.6-1.6,1-2.5,1c-1,0-1.9-0.4-2.6-1.1C3.6,8,3.6,7.8,3.6,7.6 M9.9,10.9
						c0.2,0.2,0.6,0.2,0.8,0c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.3c0.3,0.3,0.3,0.8,0,1.1c-0.4,0.4-0.9,0.6-1.5,0.6
						c-0.6,0-1.2-0.2-1.6-0.7c-0.3-0.3-0.3-0.8,0-1.1C9.1,10.5,9.6,10.5,9.9,10.9 M10.3,0C4.6,0,0,4.6,0,10.3s4.6,10.3,10.3,10.3
						c5.7,0,10.3-4.6,10.3-10.3S16,0,10.3,0"
              fill="currentColor"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
