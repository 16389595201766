import { Dimensions } from 'react-native-web'

//Tiers are used to take advantage of quicker CDN load times for common sizes
//If modifying tiers after its declared, try to keep the number of different unique widths and heights included in
//Contentful image requests small, so common size images are cached in the CDN and request times are faster.
export const DEVICE_RESOLUTION_TIERS = {
  PX_1280x720_AND_BELOW: {
    maxHeight: 720,
    maxWidth: 1280,
  },
  PX_1281x721_TO_1366x768: {
    maxHeight: 768,
    maxWidth: 1366,
    minHeight: 721,
    minWidth: 1281,
  },
  PX_1367x769_TO_1440x900: {
    maxHeight: 900,
    maxWidth: 1440,
    minHeight: 769,
    minWidth: 1367,
  },
  PX_1441x901_TO_1920x1080: {
    minHeight: 901,
    minWidth: 1441,
    maxHeight: 1080,
    maxWidth: 1920,
  },
  PX_1921x1081_TO_2560x1440: {
    minHeight: 1081,
    minWidth: 1921,
    maxHeight: 1440,
    maxWidth: 2560,
  },
  PX_2561x1441_AND_ABOVE: {
    minHeight: 1441,
    minWidth: 2561,
  },
}
const SCREEN = 'screen'

//Get the dimensions of the device screen (not the browser window)
export const getScreenDimensions = () => {
  const { height = 0, width = 0 } = Dimensions.get(SCREEN) || {}
  const heightRoundedUp = Math.ceil(height)
  const widthRoundedUp = Math.ceil(width)
  const conditionedDimensions = {
    height: heightRoundedUp,
    width: widthRoundedUp,
  }

  return conditionedDimensions
}

//When retrieving contentful images, we need to get the device resolution classification,
//so we can load images faster by reducing the size of the image downloaded.
export const getDeviceResolutionTier = dimensions => {
  const tierKeys = Object.keys(DEVICE_RESOLUTION_TIERS)
  const eligibleTiers = tierKeys.filter(tier => {
    const eligible = isTierBiggerThanDimensions(tier, dimensions)
    return eligible ? DEVICE_RESOLUTION_TIERS[tier] : null
  })
  const deviceResolutionTierKey = eligibleTiers.reduce(getLowestTier)
  const deviceResolutionObject =
    DEVICE_RESOLUTION_TIERS[deviceResolutionTierKey]

  return deviceResolutionObject
}

const isTierBiggerThanDimensions = (tier, dimensions) => {
  const { height, width } = dimensions
  const { maxWidth, maxHeight } = DEVICE_RESOLUTION_TIERS[tier] || {}
  const isMaxWidthDefined = !!maxWidth
  const isMaxHeightDefined = !!maxHeight
  const isWidthLessThanMaxWidth = isMaxWidthDefined ? width <= maxWidth : true
  const isHeightLessThanMaxHeight = isMaxHeightDefined
    ? height <= maxHeight
    : true
  const isTierEligible = isWidthLessThanMaxWidth && isHeightLessThanMaxHeight

  return isTierEligible
}

//Given two tier keys, return the tier key of the tier that has the lowest min width.
//This is used to ensure device loads smallest images possible per device
const getLowestTier = (previousTierKey, currentTierKey) => {
  const previousTier = DEVICE_RESOLUTION_TIERS[previousTierKey] || {}
  const currentTier = DEVICE_RESOLUTION_TIERS[currentTierKey] || {}
  const previousMinWidth = previousTier.minWidth || 0
  const currentMinWidth = currentTier.minWidth || 0
  const tierKey =
    currentMinWidth < previousMinWidth ? currentTierKey : previousTierKey

  return tierKey
}
