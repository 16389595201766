export const KEYS_DIALOG_CARDS = ['isComplete', 'isFlipped']
export const KEYS_DRAG_LABEL = ['buttons', 'draggables', 'dropZones']
export const KEYS_DRAG_QUESTION = ['draggables', 'dropZones', 'isSubmitted']
export const KEYS_DRAG_WORD = [
  'buttons',
  'draggables',
  'isErrorVisible',
  'questions',
]
export const KEYS_IMAGE_HOTSPOTS = ['targets']
export const KEYS_IMAGE_JUXTAPOSITION = ['position']
export const KEYS_MULTI_CHOICE = ['isAnswered', 'isSubmitted', 'options']
export const TIMEOUT_INTERVAL =
  Number(process.env.TIMEOUT_INTERVAL) || 20 * 60 * 1000 // 20 minutes
