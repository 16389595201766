import React from 'react'

import PropTypes from 'prop-types'
import { getConstantByLocale, getLocaleFromSession } from 'utils/locale'

import { CHECK, RESET } from './constants'
import {
  EvaluationCheckButton,
  EvaluationContainer,
  EvaluationResetButton,
  EvaluationText,
} from './style'

const DATA_TEST_ID_CHECK = 'check-button'
const DATA_TEST_ID_RESET = 'reset-button'
const DATA_TEST_ID_HELPER_TEXT = 'helper-text'

const currentLocale = getLocaleFromSession()
const resetButtonText = getConstantByLocale(RESET, currentLocale)
const checkButtonText = getConstantByLocale(CHECK, currentLocale)

const DragLabelEvaluation = ({
  canCheck,
  canReset,
  helperText,
  onCheck,
  onReset,
}) => {
  return (
    <EvaluationContainer>
      <EvaluationText data-testid={DATA_TEST_ID_HELPER_TEXT}>
        {helperText}
      </EvaluationText>
      <EvaluationResetButton
        data-testid={DATA_TEST_ID_RESET}
        disabled={!canReset}
        onClick={onReset}
      >
        {resetButtonText}
      </EvaluationResetButton>
      <EvaluationCheckButton
        data-testid={DATA_TEST_ID_CHECK}
        disabled={!canCheck}
        onClick={onCheck}
      >
        {checkButtonText}
      </EvaluationCheckButton>
    </EvaluationContainer>
  )
}

DragLabelEvaluation.propTypes = {
  canCheck: PropTypes.bool,
  canReset: PropTypes.bool,
  helperText: PropTypes.string,
  onCheck: PropTypes.func,
  onReset: PropTypes.func,
}

DragLabelEvaluation.defaultProps = {
  onCheck() {},
  onReset() {},
}

export default DragLabelEvaluation
