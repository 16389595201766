import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { toggleModal, visitButton } from '../../../lib/state/actions/player'
import ButtonWithStyle from '../../Button'

const Button = props => {
  const onClick = () => {
    const { id, modalContent, openModal, visitButton } = props

    visitButton(id)
    openModal(modalContent)
  }

  const { Text, buttonsVisitedStatus, id } = props
  const visited = buttonsVisitedStatus[id]

  return (
    <ButtonWithStyle onClick={onClick} visited={visited}>
      {Text}
    </ButtonWithStyle>
  )
}

Button.propTypes = {
  buttonsVisitedStatus: PropTypes.object,
  id: PropTypes.string,
  modalContent: PropTypes.object,
  openModal: PropTypes.func,
  Text: PropTypes.string,
  visitButton: PropTypes.func,
}

Button.defaultProps = {
  visitButton() {},
}

const mapDispatchToProps = dispatch => ({
  openModal: section => dispatch(toggleModal(section)),
  visitButton: buttonId => dispatch(visitButton(buttonId)),
})

const mapStateToProps = ({ player }) => {
  return {
    buttonsVisitedStatus: player.buttonsVisitedStatus,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Button)
