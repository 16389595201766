import styled from 'styled-components'

import { fonts } from '../../styles/styleGuide'

export const BodyCopy = styled.span`
  ${props =>
    props.theme.grade
      ? props => props.theme.grade.fonts
      : props => props.theme.fonts}
`

export const GenericCopy = styled.span`
  ${props =>
    props.theme.grade
      ? props => props.theme.grade.fonts.generic
      : props => props.theme.fonts.generic}
`

export const ButtonCopy = styled.span`
  ${props => props.theme.fonts.button}
`

export const FeedbackCopy = styled.span`
  ${props =>
    props.theme.grade
      ? props => props.theme.grade.fonts.feedback
      : props => props.theme.fonts.feedback}
`

export const HelperCopy = styled.span`
  ${props =>
    props.theme.grade
      ? props => props.theme.grade.fonts.helper
      : props => props.theme.fonts.helper}
`

export const ChromeButtonCopy = styled.span`
  ${fonts.body}
`

export const ChromeTitleBar = styled.span`
  ${fonts.body}
`

export const H1 = styled.h1`
  ${props => props.theme.fonts.display}
  ${props =>
    props.theme.grade
      ? props => props.theme.grade.fonts.h1
      : props => props.theme.fonts.h1}
  font-variant-ligatures: none;
`

export const H2 = styled.h2`
  ${props => props.theme.fonts.display}
  ${props =>
    props.theme.grade
      ? props => props.theme.grade.fonts.h2
      : props => props.theme.fonts.h2}
  font-variant-ligatures: none;
`

export const H3 = styled.h3`
  ${props => props.theme.fonts.display}
  ${props =>
    props.theme.grade
      ? props => props.theme.grade.fonts.h3
      : props => props.theme.fonts.h3}
  font-variant-ligatures: none;
`

export const H4 = styled.h4`
  ${props => props.theme.fonts.display}
  ${props =>
    props.theme.grade
      ? props => props.theme.grade.fonts.h4
      : props => props.theme.fonts.h4}
    font-variant-ligatures: none;
`

export const H5 = styled.h5`
  ${props => props.theme.fonts.display}
  ${props =>
    props.theme.grade
      ? props => props.theme.grade.fonts.h5
      : props => props.theme.fonts.h5}
    font-variant-ligatures: none;
`

export const H6 = styled.h6`
  ${props => props.theme.fonts.display}
  ${props =>
    props.theme.grade
      ? props => props.theme.grade.fonts.h6
      : props => props.theme.fonts.h6}
    font-variant-ligatures: none;
`
