import React from 'react'

import PropTypes from 'prop-types'

import { DRAG_AND_DROP_TYPES } from '../../utils/dragAndDropHelper'
import DraggablePreview from '../DraggablePreview'

import { Layer, Preview } from './style'

const DragLayerComponent = ({ item, scale, x, y }) => {
  const { domElement, interactiveType, label } = item || {}
  const { offsetHeight: height, offsetWidth: width } = domElement || {}

  //DragQuestion does not display draggable borders
  const showBorder = interactiveType !== DRAG_AND_DROP_TYPES.DRAG_QUESTION

  return (
    <Layer>
      <Preview scale={scale} x={x} y={y}>
        <DraggablePreview
          hasBorder={showBorder}
          height={height}
          scale={scale}
          text={label}
          width={width}
        />
      </Preview>
    </Layer>
  )
}

DragLayerComponent.propTypes = {
  item: PropTypes.object,
  scale: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
}

DragLayerComponent.defaultProps = {
  scale: 1,
  x: 0,
  y: 0,
}

export default DragLayerComponent
