import React from 'react'

import PropTypes from 'prop-types'

import { getImageUrlFromImageObject } from '../../../utils/contentfulHelper'

import ImageComponent from './component'

const ImageContainer = props => {
  const getRenderProps = () => {
    const { altText, image, src, title: titleProp } = props || {}
    const source = src || (image && getImageUrlFromImageObject(image))
    const title = altText ? altText : titleProp
    const renderProps = {
      altText,
      image,
      source,
      title,
    }

    return renderProps
  }

  const imageComponentProps = getRenderProps() || {}

  return <ImageComponent {...imageComponentProps} />
}

ImageContainer.propTypes = {
  altText: PropTypes.string,
  image: PropTypes.object,
  src: PropTypes.string,
  title: PropTypes.string,
}

ImageContainer.defaultProps = {}

export default ImageContainer
