import React from 'react'

const MultiChoiceTurnArrow = () => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 330.722 231.6279"
      fill={'#fff'}
    >
      <defs></defs>
      <path
        className="cls-1"
        d="M325.5754,105.1458,197.1063,2.9873a13.63,13.63,0,0,0-22.1132,10.6681V70.7349a13.63,13.63,0,0,1-13.63,13.63H13.63A13.63,13.63,0,0,0,0,97.9948v35.6384a13.63,13.63,0,0,0,13.63,13.63H161.3632a13.63,13.63,0,0,1,13.63,13.63v57.0795a13.63,13.63,0,0,0,22.1132,10.6681L325.5754,126.4821A13.63,13.63,0,0,0,325.5754,105.1458Z"
      />
    </svg>
  )
}

export default MultiChoiceTurnArrow
