import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { fontWeightNormal } from '../../theme/defaultValues'

export const CheckBoxInputLabel = styled.label.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  align-items: baseline;
  cursor: pointer;
  display: flex;
  padding-left: 0.5rem;
  font-family: ${themeGet('fontFamily')};
  font-size: 1rem;
  font-weight: ${fontWeightNormal};
  line-height: 1.5rem;
  ${themeGet('breakpoints.mobile')} {
    margin-bottom: 1rem;
    margin-right: 0;
  }
`

export const CheckBoxInputContainer = styled.div`
  cursor: pointer;
  display: flex;
  margin-right: 2.5rem;
`

export const CheckBoxStyled = styled.div`
  cursor: pointer;
  padding-top: 0.175rem;
  padding-left: 0.125rem;
  font-size: 0;
  line-height: 0;
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  box-sizing: border-box;
  border: 2px solid ${themeGet(`colors.borderColor`)};
  border-radius: 0.3125rem;
  transition: all 150ms;
  svg {
    display: none;
  }
`

export const CheckBoxHidden = styled.input.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
  type: 'checkbox',
}))`
  cursor: pointer;
  border: 0;
  opacity: 0;
  padding-right: 1.25rem;
  margin-top: 0.1875rem;
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  transform: scale(1.75);
  position: absolute;
  white-space: nowrap;
  &:checked ~ ${CheckBoxStyled} {
    background-color: ${themeGet(`colors.themePrimary`)};
    border-color: ${themeGet(`colors.themePrimary`)};
    svg {
      display: block;
    }
  }

  &:focus-visible + ${CheckBoxStyled} {
    outline: 2px solid ${themeGet('colors.secondStepBlue')};
  }

  &:hover + ${CheckBoxStyled} {
    border-color: ${themeGet(`colors.themeAccent`)};
  }
`
