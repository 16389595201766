import React from 'react'
import PropTypes from 'prop-types'
import { toUrlSafe } from '../../utils/stringHelpers'
import {
  FormFieldRadio,
  RadioOptionsContainer,
  RadioInputContainer,
  RadioInput,
  RadioInputLabel,
} from './component.styles'

const FormInputRadio = ({
  checked,
  dataTestId,
  inputErrors,
  label,
  onChange,
  options,
  selected = [],
}) => {
  if (!onChange || !options) {
    return null
  }
  return (
    <FormFieldRadio
      dataTestId={dataTestId}
      inputErrors={inputErrors}
      label={label}
    >
      <RadioOptionsContainer>
        {options &&
          options.map(choiceText => (
            <RadioInputContainer key={`radio-button-${choiceText}`}>
              <RadioInput
                checked={selected.includes(choiceText)}
                data-testid={`input-radio-${toUrlSafe(choiceText)}`}
                id={`input-${dataTestId}-${toUrlSafe(choiceText)}`}
                name={`radio-${toUrlSafe(label)}`}
                onChange={onChange}
                type="radio"
                value={choiceText}
              />
              <RadioInputLabel
                data-testid={`label-radio-${toUrlSafe(choiceText)}`}
                data-value={choiceText}
                htmlFor={`input-${dataTestId}-${toUrlSafe(choiceText)}`}
              >
                {choiceText}
              </RadioInputLabel>
            </RadioInputContainer>
          ))}
      </RadioOptionsContainer>
    </FormFieldRadio>
  )
}

FormInputRadio.propTypes = {
  checked: PropTypes.bool,
  dataTestId: PropTypes.string,
  inputErrors: PropTypes.object,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selected: PropTypes.arrayOf(PropTypes.string),
}

export default FormInputRadio
