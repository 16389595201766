/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'
import PropTypes from 'prop-types'
import Svg from './component.styles'
import {
  hoverButtonColor,
  iconColor,
  iconColorLight,
} from '../../theme/defaultValues.js'

const IconCertificate = ({
  className,
  colorBorder = hoverButtonColor,
  colorMedal = iconColorLight,
  colorRibbon = iconColor,
  dataTestId,
}) => (
  <Svg
    className={className}
    dataTestId={dataTestId}
    height="82"
    viewBox="0 -2 86 80"
    width="86"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path d="M0 .32h85.797v61.353H0z" id="a" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 .471)">
        <mask fill="#fff" id="b">
          <use xlinkHref="#a" />
        </mask>
        <path
          d="M82.946 61.673H2.851a2.851 2.851 0 01-2.85-2.851V3.172A2.851 2.851 0 012.85.32h80.095a2.851 2.851 0 012.851 2.85v55.65a2.851 2.851 0 01-2.85 2.852"
          fill={colorBorder}
          mask="url(#b)"
        />
      </g>
      <path
        d="M79.043 55.803H6.754a.413.413 0 01-.413-.414V7.547c0-.229.185-.414.413-.414h72.289c.229 0 .413.185.413.414v47.842a.413.413 0 01-.413.414"
        fill="#FFF"
      />
      <path
        d="M14.299 17.366h57.024m-57.024 9.401h57.024m-24.049 9.402h24.05m-19.42 9.4h19.419"
        stroke={colorBorder}
        strokeLinecap="round"
        strokeWidth="2.376"
      />
      <path
        d="M47.428 73.896l-6.432.408-3.893 5.367-13.28-23.743 10.325-5.775z"
        fill={colorRibbon}
      />
      <path
        d="M8.66 73.896l6.431.408 3.895 5.367 13.28-23.743-10.325-5.775z"
        fill={colorRibbon}
      />
      <path
        d="M44.96 50.289c0-9.343-7.574-16.916-16.916-16.916S11.13 40.946 11.13 50.29c0 9.341 7.573 16.915 16.915 16.915 9.342 0 16.916-7.574 16.916-16.915"
        fill="#FFF"
      />
      <path
        d="M44.96 50.289c0-9.343-7.574-16.916-16.916-16.916S11.13 40.946 11.13 50.29c0 9.341 7.573 16.915 16.915 16.915 9.342 0 16.916-7.574 16.916-16.915z"
        stroke={colorRibbon}
        strokeWidth="3.564"
      />
      <path
        d="M37.192 50.289a9.148 9.148 0 10-18.296.001 9.148 9.148 0 0018.296-.001"
        fill={colorMedal}
      />
    </g>
  </Svg>
)

IconCertificate.propTypes = {
  className: PropTypes.string,
  colorBorder: PropTypes.string,
  colorMedal: PropTypes.string,
  colorRibbon: PropTypes.string,
  dataTestId: PropTypes.string.isRequired,
}

export default IconCertificate
