import React from 'react'

import PropTypes from 'prop-types'

import arrowDownSvg from '../../../assets/images/arrowBlackDown.svg'

import {
  ARROW_IMAGE_DATA_TEST_ID,
  DEFAULT_DETAILED_MESSAGE,
  DETAILS_BUTTON_DATA_TEST_ID,
  DETAILS_TEXT_DATA_TEST_ID,
  HIDE_DETAILS,
  SHOW_DETAILS,
} from './constants'
import {
  ArrowImage,
  ButtonContainer,
  Container,
  DetailTextContainer,
} from './style'

const DetailsRevealerComponent = ({ message, onClick, showMessage }) => {
  const buttonText = showMessage ? HIDE_DETAILS : SHOW_DETAILS
  const detailsComponent = showMessage ? (
    <DetailTextContainer data-testid={DETAILS_TEXT_DATA_TEST_ID}>
      {message}
    </DetailTextContainer>
  ) : null
  const isUp = showMessage

  return (
    <Container>
      <ButtonContainer
        data-testid={DETAILS_BUTTON_DATA_TEST_ID}
        onClick={onClick}
        onKeyPress={onClick}
        tabIndex={0}
      >
        <ArrowImage
          data-testid={ARROW_IMAGE_DATA_TEST_ID}
          isUp={isUp}
          src={arrowDownSvg}
        />
        {buttonText}
      </ButtonContainer>
      {detailsComponent}
    </Container>
  )
}

DetailsRevealerComponent.propTypes = {
  message: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  onClick: PropTypes.func,
  showMessage: PropTypes.bool,
}

DetailsRevealerComponent.defaultProps = {
  message: DEFAULT_DETAILED_MESSAGE,
  onClick() {},
  showMessage: false,
}

export default DetailsRevealerComponent
