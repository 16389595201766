import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Dialog } from './component.styles'

const isMobileSized = () => {
  if (typeof window === 'undefined') return false

  const { innerWidth } = window
  // TODO: We can't use themeget here; we should have a "styling constants" library
  const breakpoint = 768
  const isMobile = innerWidth <= breakpoint

  return isMobile
}

const isVisible = e => {
  return !!(e.offsetWidth || e.offsetHeight || e.getClientRects().length)
}

const focusableSelector =
  'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'

const MobileModal = ({ title, description, children, close, isOpen }) => {
  const [inMobileRegime, setInMobileRegime] = useState(isMobileSized())
  const theModal = useRef(null)

  const getFirstFocusableElement = () => {
    const focusableElements = theModal.current?.querySelectorAll(
      focusableSelector,
    )
    return focusableElements?.[0]
  }

  const getLastFocusableElement = () => {
    const focusableElements = theModal.current?.querySelectorAll(
      focusableSelector,
    )
    return focusableElements?.[focusableElements.length - 1]
  }

  const handleResize = () => {
    const isMobile = isMobileSized()
    setInMobileRegime(isMobile)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleKeyUp = e => {
    if (inMobileRegime) {
      if (e.key === 'Escape') {
        close()
        e.preventDefault()
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyUp)
    return () => window.removeEventListener('keydown', handleKeyUp)
  }, [])

  const handleFocusIn = e => {
    if (inMobileRegime) {
      const firstFocusableElement = getFirstFocusableElement()
      const lastFocusableElement = getLastFocusableElement()
      const { target } = e
      const modal = theModal.current
      if (modal) {
        if (!modal.contains(target)) {
          const focusIsBeforeModal = !!(
            // see https://developer.mozilla.org/en-US/docs/Web/API/Node/compareDocumentPosition
            (
              target.compareDocumentPosition(modal) &
              Node.DOCUMENT_POSITION_FOLLOWING
            )
          )
          const elementToFocus = focusIsBeforeModal
            ? lastFocusableElement
            : firstFocusableElement

          if (isVisible(elementToFocus)) {
            elementToFocus.focus()
          }
        }
      }
    }
  }

  useEffect(() => {
    window.addEventListener('focusin', handleFocusIn)
    return () => window.removeEventListener('focusin', handleFocusIn)
  }, [])

  useEffect(() => {
    if (inMobileRegime && isOpen) {
      const firstFocusableElement = getFirstFocusableElement()
      firstFocusableElement.focus()
    }
  }, [isOpen, inMobileRegime])

  const handleSubmit = e => {
    e.preventDefault()
    close()
  }
  const saveChildren = children
  return (
    <>
      {saveChildren}
      {inMobileRegime && isOpen && (
        <style>
          {`body {
              overflow-y: hidden;
            }`}
        </style>
      )}
      <Dialog
        aria-describedby={!!description && 'mobileModalDescription'}
        aria-labelledby="mobileModalLabel"
        className={isOpen ? 'explicitly-open' : ''}
        ref={theModal}
        role="dialog"
        tabIndex="-1"
      >
        <div>
          <div>
            <div id="mobileModalLabel">{title}</div>
            {!!description && (
              <div id="mobileModalDescription">{description}</div>
            )}
          </div>
        </div>
        {/* form lets us use a true submit button */}
        <form onSubmit={handleSubmit}>{saveChildren}</form>
      </Dialog>
    </>
  )
}

MobileModal.propTypes = {
  children: PropTypes.node.isRequired,
  close: PropTypes.func.isRequired,
  description: PropTypes.string,
  isOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
}

MobileModal.defaultProps = {
  isOpen: false,
}

export default MobileModal
