import React, { Component } from 'react'

import PropTypes from 'prop-types'

import {
  checkLayoutForErrors,
  getElementsForLayout,
  getHeroDataForLayout,
  getTitleForLayout,
} from '../../utils/contentfulHelper'

import ModalLayoutRouterComponent from './component'
import { DEFAULT_MODAL_LAYOUT_DEPRECATED } from './routes'

class ModalLayoutRouterContainer extends Component {
  getModalRoute = () => DEFAULT_MODAL_LAYOUT_DEPRECATED

  getRenderProps = () => {
    const { foregroundColor, contentfulLayout, onSubmit } = this.props
    const route = this.getModalRoute(contentfulLayout)
    const title = getTitleForLayout(contentfulLayout)
    const error = checkLayoutForErrors(contentfulLayout)
    const elements = getElementsForLayout(contentfulLayout)
    const {
      altText: heroAltText,
      url: heroImage,
      position: heroPosition,
      size: heroSize,
    } = getHeroDataForLayout(contentfulLayout) || {}
    const layoutRouterProps = {
      elements,
      error,
      foregroundColor,
      heroAltText,
      heroImage,
      onSubmit,
      route,
      title,
      //TODO: heroPosition and heroSize will no longer be needed when default layout can be phased out
      heroPosition,
      heroSize,
    }

    return layoutRouterProps
  }
  render = () => {
    const layoutRouterProps = this.getRenderProps() || {}

    return <ModalLayoutRouterComponent {...layoutRouterProps} />
  }
}

ModalLayoutRouterContainer.propTypes = {
  foregroundColor: PropTypes.string,
  contentfulLayout: PropTypes.object,
  onSubmit: PropTypes.func,
}

ModalLayoutRouterContainer.defaultProps = {
  onSubmit() {},
}

export default ModalLayoutRouterContainer
