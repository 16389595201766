/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

export default () => (
  <svg width="27px" height="27px" viewBox="0 0 27 27">
    <defs>
      <polygon
        id="path-1"
        points="4.74575786e-16 0 23 0 23 23 4.74575786e-16 23"
      ></polygon>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(2, 2)">
        <mask fill="white"></mask>
        <g id="Clip-2"></g>
        <path
          d="M19.6276179,9.25693226 L7.22530073,9.25693226 L7.22530073,5.51340103 L19.6276179,5.51340103 L21.9191709,7.38516665 L19.6276179,9.25693226 Z M15.7747004,16.5216031 L3.37238327,16.5216031 L1.0808303,14.6498375 L3.37238327,12.7780718 L15.7747004,12.7780718 L15.7747004,16.5216031 Z M22.7537256,6.8698062 L19.9677468,4.59442734 L12.5633728,4.59442734 L12.5633728,2.95130239 C12.9481765,2.64657071 13.1989559,2.1859811 13.1989559,1.66363645 C13.1989559,0.744662761 12.4383585,-7.35178952e-05 11.5001883,-7.35178952e-05 C10.5616427,-7.35178952e-05 9.80104531,0.744662761 9.80104531,1.66363645 C9.80104531,2.1859811 10.0522001,2.64657071 10.4366284,2.95130239 L10.4366284,4.59442734 L6.97039174,4.59442734 C6.59347182,4.59442734 6.28675511,4.89474795 6.28675511,5.26380778 L6.28675511,9.50652551 C6.28675511,9.87558535 6.59347182,10.1759059 6.97039174,10.1759059 L10.4366284,10.1759059 L10.4366284,11.8590982 L3.03225434,11.8590982 L0.24590012,14.1348446 C0.0897261293,14.2627657 -0.000374249714,14.450604 -4.58300065e-13,14.6502051 C-4.58300065e-13,14.8498061 0.0897261293,15.0372768 0.246275538,15.1648303 L3.03225434,17.4405768 L10.4366284,17.4405768 L10.4366284,22.6676991 C10.4366284,22.8511263 10.5886728,23 10.7763819,23 L12.2236193,23 C12.4113284,23 12.5633728,22.8511263 12.5633728,22.6676991 L12.5633728,17.4405768 L16.0296094,17.4405768 C16.4065293,17.4405768 16.7132461,17.1402562 16.7132461,16.7711963 L16.7132461,12.5284786 C16.7132461,12.1594188 16.4065293,11.8590982 16.0296094,11.8590982 L12.5633728,11.8590982 L12.5633728,10.1759059 L19.9677468,10.1759059 L22.7537256,7.90052709 C22.910275,7.77260595 23,7.58476773 23,7.38516665 C23,7.18556556 22.910275,6.99772734 22.7537256,6.8698062 L22.7537256,6.8698062 Z"
          id="Fill-1"
          fill="#FFFFFF"
          mask="url(#mask-2)"
        ></path>
      </g>
    </g>
  </svg>
)
