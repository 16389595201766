import React, { useEffect, useState } from 'react'

import { COMPLETION_STATUS } from '@cfc/clifford/dist/enums'
import { updateCompletionStatus } from 'lib/state/actions/cmi'
import { toggleModal } from 'lib/state/actions/player'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import LessonComplete from '../../../LessonComplete'

import MarkDoneComponent from './component'
import { TEXT_LESSON_COMPLETE, TEXT_MARK_LESSON_DONE } from './constants'

const LESSON_COMPLETE_COMPONENT = <LessonComplete />

const MarkDoneButtonContainer = ({
  isFamilyMode,
  isCurriculumPreviewMode,
  isHighSchoolMode,
  isLessonDone,
  isNextSlide,
  isPrepMode,
  isPrevSlide,
  openModal,
  updateCompletionStatus,
}) => {
  // TODO: Remove isHighSchoolMode check once high school mark as complete is implemented
  if (isFamilyMode || isCurriculumPreviewMode || isHighSchoolMode) {
    return null
  }
  const [didClick, setDidClick] = useState(false)
  useEffect(() => {
    if (didClick && isLessonDone) {
      openModal(LESSON_COMPLETE_COMPONENT)
      setDidClick(false)
    }
  }, [didClick, isLessonDone])

  const isButtonActive = isLessonDone || !isNextSlide
  const markDoneText = isLessonDone
    ? TEXT_LESSON_COMPLETE
    : TEXT_MARK_LESSON_DONE

  const handleClick = async () => {
    if (didClick) {
      return
    }

    setDidClick(true)
    updateCompletionStatus(COMPLETION_STATUS.Completed, isLessonDone)
  }

  return (
    <MarkDoneComponent
      isButtonActive={isButtonActive}
      isLessonDone={isLessonDone}
      isPrevSlide={isPrevSlide}
      isPrepMode={isPrepMode}
      markDoneText={markDoneText}
      onClick={handleClick}
    />
  )
}

MarkDoneButtonContainer.propTypes = {
  isFamilyMode: PropTypes.bool,
  isCurriculumPreviewMode: PropTypes.bool,
  isHighSchoolMode: PropTypes.bool,
  isLessonDone: PropTypes.bool,
  isNextSlide: PropTypes.bool,
  isPrepMode: PropTypes.bool,
  isPrevSlide: PropTypes.bool,
  openModal: PropTypes.func,
  updateCompletionStatus: PropTypes.func,
}

MarkDoneButtonContainer.defaultProps = {
  isFamilyMode: false,
  isCurriculumPreviewMode: false,
  isLessonDone: false,
  isNextSlide: false,
  openModal() {},
  updateCompletionStatus() {},
}

const mapDispatchToProps = dispatch => ({
  openModal: section => dispatch(toggleModal(section)),
  updateCompletionStatus: (completionStatus, isLessonDone) =>
    dispatch(updateCompletionStatus(completionStatus, isLessonDone)),
})

const mapStateToProps = ({
  cmi: { completion_status },
  player: {
    isFamilyMode,
    isCurriculumPreviewMode,
    isNextSlide,
    isPrepMode,
    isPrevSlide,
    isHighSchoolMode,
  },
}) => ({
  isFamilyMode,
  isCurriculumPreviewMode,
  isHighSchoolMode,
  isLessonDone: completion_status === COMPLETION_STATUS.Completed,
  isNextSlide,
  isPrepMode,
  isPrevSlide,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MarkDoneButtonContainer)
