/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

const HelpIcon = () => (
  <svg height="20px" viewBox="0 0 20 20" width="20px">
    <title>Help</title>
    <defs>
      <polygon
        id="path-1"
        points="0.000142857143 0 20 0 20 20 0.000142857143 20"
      />
    </defs>
    <g
      fill="none"
      fillRule="evenodd"
      id="question-mark"
      stroke="none"
      strokeWidth="1"
    >
      <g>
        <mask fill="currentColor" id="mask-2">
          <use xlinkHref="#path-1" />
        </mask>
        <g id="Clip-2" />
        <path
          d="M11.0858571,11.032381 L11.0858571,11.7085714 C11.0858571,12.3157143 10.6291905,12.7566667 10.0001429,12.7566667 C9.37061905,12.7566667 8.91395238,12.3157143 8.91395238,11.7085714 L8.91395238,10.1409524 C8.91395238,9.53380952 9.37061905,9.09285714 10.0001429,9.09285714 C10.9468095,9.09285714 11.7168095,8.39095238 11.7168095,7.52904762 C11.7168095,6.66666667 10.9468095,5.9652381 10.0001429,5.9652381 C9.053,5.9652381 8.283,6.66666667 8.283,7.52904762 C8.283,8.13619048 7.82633333,8.57714286 7.19728571,8.57714286 C6.5782381,8.57714286 6.11157143,8.12619048 6.11157143,7.52904762 C6.11157143,5.51047619 7.85585714,3.86904762 10.0001429,3.86904762 C12.1444286,3.86904762 13.8887143,5.51047619 13.8887143,7.52904762 C13.8887143,9.14857143 12.743,10.5704762 11.0858571,11.032381 M10.0001429,16.6585714 C9.28395238,16.6585714 8.70157143,16.0995238 8.70157143,15.4119048 C8.70157143,14.7247619 9.28395238,14.1652381 10.0001429,14.1652381 C10.7163333,14.1652381 11.2982381,14.7247619 11.2982381,15.4119048 C11.2982381,16.0995238 10.7163333,16.6585714 10.0001429,16.6585714 M10.0001429,0 C4.47728571,0 0.000142857143,4.47714286 0.000142857143,10 C0.000142857143,15.5228571 4.47728571,20 10.0001429,20 C15.523,20 20.0001429,15.5228571 20.0001429,10 C20.0001429,4.47714286 15.523,0 10.0001429,0"
          fill="currentColor"
          id="Fill-1"
          mask="url(#mask-2)"
        />
      </g>
    </g>
  </svg>
)

export default HelpIcon
