import { LOCALES } from 'utils/locale'

export const NEXT_BUTTON = {
  [LOCALES.EN_US]: 'Next',
  [LOCALES.ES_US]: 'Siguiente',
}
export const BACK_BUTTON = {
  [LOCALES.EN_US]: 'Back',
  [LOCALES.ES_US]: 'Atrás',
}
