import React, { Fragment } from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { CONTENTFUL_URL } from '../../lib/config'
import ErrorMessage from '../ErrorMessage'

import Audio from './Audio'
import Button from './Button'
import Image from './Image'
import Interactive from './Interactive'
import LinkButton from './LinkButton'
import Text from './Text'
import Video from './Video'

const modules = {
  Image,
  Interactive,
  LinkButton,
  Text,
  Button,
  Video,
  Audio,
}

export const moduleMapper = (
  module,
  index,
  onSubmit,
  currentSlide,
  backgroundColor,
) => {
  const { fields, sys } = module || {}
  const { contentType, id } = sys || {}
  if (contentType == null) {
    //TODO: We should consider changing the key to a Contentful id.  Future changes to layout ordering may bite us if we don't
    const key = index
    const message =
      'Error: Content not recognized. Ensure the content has been published.'
    const url = `${CONTENTFUL_URL}${id}`
    const errorMessageProps = {
      key,
      message,
      url,
    }

    return <ErrorMessage {...errorMessageProps} />
  }

  // find the module id and make the first letter uppercase; for example, `text` => `Text`
  //workaround for weird link bug
  const moduleType =
    contentType.sys.id[0].toUpperCase() + contentType.sys.id.substr(1)

  if (moduleType in modules) {
    const Module = modules[moduleType]
    const currentSlideString = (currentSlide || '').toString()
    const key = `${currentSlideString} ${id}`

    const moduleProps = {
      ...fields,
      backgroundColor,
      id: key,
      key,
      onSubmit: onSubmit,
    }
    return <Module {...moduleProps} />
  } else {
    console.error('could not find module ' + moduleType)

    return null
  }
}

const Modules = props => {
  const { backgroundColor, currentSlide, currentSlideData, onSubmit } =
    props || {}
  return (
    <Fragment>
      {props.modules.map((module, i) => {
        const moduleClone = {
          ...module,
          fields: {
            ...module.fields,
            vtts: currentSlideData?.fields?.vtts || {},
          },
        }
        return moduleMapper(
          moduleClone,
          i,
          onSubmit,
          currentSlide,
          backgroundColor,
        )
      })}
    </Fragment>
  )
}

Modules.propTypes = {
  modules: PropTypes.array.isRequired,
  onSubmit: PropTypes.func,
  currentSlide: PropTypes.array,
  backgroundColor: PropTypes.string,
  currentSlideData: PropTypes.object,
}

Modules.defaultProps = {
  currentSlide: [],
  onSubmit() {},
  currentSlideData: {},
}

const mapStateToProps = ({ player }) => {
  return {
    currentSlide: player.currentSlide,
    currentSlideData: player.currentSlideData,
  }
}

export default connect(mapStateToProps, null)(Modules)
