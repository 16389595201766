import styled from 'styled-components'

import { GenericCopy } from '../../text'

export const QuestionContainer = styled.div.attrs()`
  width: 100%;
`
export const QuestionCopy = styled(GenericCopy)`
  margin-left: 34px;
`

export const Question = styled.div.attrs()`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`
