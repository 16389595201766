/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

const HandIcon = () => (
  <svg height="17px" viewBox="0 0 21 17" width="21px">
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#4B8344" transform="translate(-67, -1321)">
        <g transform="translate(48, 1277)">
          <g transform="translate(19, 44)">
            <path d="M12.6763314,7.79201267 C12.8214973,7.94650115 13.0635515,7.95415899 13.2183729,7.80932604 C13.224366,7.8036659 13.2296932,7.79833871 13.2360192,7.79201267 C14.3647174,6.59106452 15.4954132,5.38845161 16.6241114,4.18750346 C17.5297335,3.22461406 17.5297335,1.68938479 16.6241114,0.726495392 C15.7191551,-0.236394009 14.2125595,-0.236394009 13.3076033,0.726495392 L12.9596713,1.09407143 L12.6127381,0.7225 C12.1595941,0.24072235 11.555624,0 10.9519869,0 C10.3483498,0 9.74804224,0.24072235 9.29556413,0.7225 C8.39027496,1.68505645 8.39127381,3.22028571 9.29556413,4.18350806 L12.6763314,7.79201267 Z" />
            <path d="M20.4073145,10.8952584 C20.2075449,10.2822074 19.5999124,10.02467 19.0911659,10.2558138 L13.980394,12.6848234 C13.3214873,13.0287398 12.7957603,13.3790546 12.1381854,13.3626586 L12.0909066,13.3626586 L8.46941701,12.8895736 C8.23269005,12.8519827 8.04757024,12.5100658 8.06721424,12.2205362 C8.08819005,11.9218088 8.331576,11.6158832 8.57795848,11.6406772 L11.9457407,12.0933671 C11.9880253,12.0993657 12.029644,12.1025649 12.0725944,12.1013652 C12.5753479,12.0837695 13.1913041,11.8934157 13.5069401,11.4155319 C13.717364,11.0992088 13.7356762,10.9324493 13.717364,10.8496695 C13.638788,10.497755 13.4856313,10.4081768 12.3675875,10.1786326 C12.2014458,10.1430412 12.1881278,10.1450407 11.9936854,10.1002516 C11.5651797,10.0038751 11.1386716,9.93429198 10.5117281,9.83191687 C10.0489285,9.74233864 9.49922922,9.64636197 8.73311286,9.51199463 C7.00377415,9.20846842 5.60671885,9.66155828 3.23778452,10.8120786 C1.99888014,11.4147321 2.40474535,11.106807 0.988378991,11.1775898 C0.753649728,11.1891869 0.531905489,11.3115572 0.378415857,11.5255052 C0.0884170094,11.9298069 -0.223889442,12.7324118 0.226590972,14.105678 C0.937437747,16.2763503 2.26757024,15.5725214 2.26757024,15.5725214 C3.17585595,15.3745695 4.25228106,14.7523208 5.4389124,14.8319014 C6.19137784,14.8798898 7.0284124,15.2374029 7.92571079,15.6121118 C8.82334212,15.985621 9.72330411,16.35993 10.4814297,16.4143168 C11.8628364,16.4950971 13.5162626,15.7172862 13.7210265,15.6241089 C13.7400046,15.6157109 13.756652,15.606913 13.7752972,15.5969155 L19.8762603,12.2045401 C20.1396232,12.060975 20.3830092,11.8042374 20.4346163,11.4599211 C20.4419412,11.4107331 20.502205,11.1867875 20.4073145,10.8952584" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
export default HandIcon
