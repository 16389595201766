import { Component } from 'react'

import PropTypes from 'prop-types'
import { withIdleTimer } from 'react-idle-timer'

class IdleTimerComponent extends Component {
  render() {
    return this.props.children || null
  }
}

IdleTimerComponent.propTypes = {
  children: PropTypes.node,
}

export default withIdleTimer(IdleTimerComponent)
