import styled from 'styled-components'
import { themeGet } from 'styled-system'
import ContainedButton from '../ContainedButton'

export const Button = styled(ContainedButton)`
  z-index: 99999; // overlay the footer
  width: auto;
  min-width: 0px;
  will-change: transform;
  display: none;
  opacity: 0;

  &.hidden {
    display: none;
    opacity: 0;
  }
  &:not(.hidden) {    
    &:not(.mobileOnly) {
      display: flex;
      opacity: 1;
    }

    &.mobileOnly {
      ${themeGet('breakpoints.tablet')} {
          display: flex;
          opacity: 1;
        }
      }  
    }
  }
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  right: 2rem;
  bottom: 2.8rem;

  svg {
    transform: rotate(270deg);
  }
`
