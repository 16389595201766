import React from 'react'
import PropTypes from 'prop-types'
import BodyCopy from '../BodyCopy'
import { FilterWrapper, StyledSearchSelect } from './component.styles'

const DropdownFilter = ({
  filterSiteUsers,
  selectedFilterOption,
  userFilterOptions,
}) => {
  const buttonTextTransformer = inputString => {
    return inputString.replace(/\([^)]*\)/g, '').trim()
  }

  return (
    <FilterWrapper>
      <BodyCopy>Show:</BodyCopy>
      <StyledSearchSelect
        buttonTextTransformer={buttonTextTransformer}
        dataTestId="select-filter"
        keyProp="key"
        labelProp="name"
        name="Select Filter"
        onChange={filterSiteUsers}
        options={userFilterOptions}
        selectedOption={selectedFilterOption}
        value={selectedFilterOption}
      />
    </FilterWrapper>
  )
}

DropdownFilter.propTypes = {
  filterSiteUsers: PropTypes.func,
  selectedFilterOption: PropTypes.object,
  userFilterOptions: PropTypes.array,
}

export default DropdownFilter
