import React from 'react'

function NumberOneIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <circle cx="10" cy="10" r="10" fill="currentColor" />
        <g fill="#FFF" fillRule="nonzero" transform="translate(7.5 5.9)">
          <path d="M2.202 0C1.79.807 1.182 1.118 0 1.118v1.34c.778 0 1.423-.11 1.94-.322V9.1h1.587V0H2.202z" />
        </g>
      </g>
    </svg>
  )
}

export default NumberOneIcon
