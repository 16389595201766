/* istanbul ignore file */
// we don't unit test SVGs
const SelaTranscriptHover = () => {
  return (
    <svg height="30px" viewBox="0 0 30 30" width="30px">
      <defs>
        <rect height="1199" rx="12" width="1166" x="100" y="-43" />
        <filter
          filterUnits="objectBoundingBox"
          height="107.1%"
          width="107.3%"
          x="-3.6%"
          y="-3.5%"
        >
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius="1"
            result="shadowSpreadOuter1"
          />
          <feOffset
            dx="0"
            dy="1"
            in="shadowSpreadOuter1"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="13"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            type="matrix"
            values="0 0 0 0 0.650985054   0 0 0 0 0.650985054   0 0 0 0 0.650985054  0 0 0 0.8 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-1196.000000, -939.000000)">
          <rect fill="#C3C6C8" height="1024" width="1366" x="0" y="0" />
          <g>
            <use fill="black" fillOpacity="1" filter="url(#filter-2)" />
            <use fill="#FFFFFF" fillRule="evenodd" />
          </g>
          <g transform="translate(1196.000000, 939.000000)">
            <rect
              fill="#007980"
              height="30"
              rx="6"
              stroke="#007980"
              width="30"
              x="0"
              y="0"
            />
            <line
              stroke="#FFFFFF"
              strokeLinecap="round"
              strokeWidth="2"
              x1="7"
              x2="11"
              y1="10"
              y2="10"
            />
            <line
              stroke="#FFFFFF"
              strokeLinecap="round"
              strokeWidth="2"
              x1="15"
              x2="23"
              y1="10"
              y2="10"
            />
            <line
              stroke="#FFFFFF"
              strokeLinecap="round"
              strokeWidth="2"
              x1="7"
              x2="23"
              y1="15"
              y2="15"
            />
            <line
              stroke="#FFFFFF"
              strokeLinecap="round"
              strokeWidth="2"
              x1="7"
              x2="15"
              y1="20"
              y2="20"
            />
            <line
              stroke="#FFFFFF"
              strokeLinecap="round"
              strokeWidth="2"
              x1="19"
              x2="23"
              y1="20"
              y2="20"
            />
          </g>
          <g transform="translate(1215.500000, 960.500000)">
            <image height="19" width="18" x="0" y="0" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SelaTranscriptHover
