import React from 'react'

const AppleAndBooksIconElem = () => {
  return (
    <svg
      data-testid="icon-apple-and-books"
      height="90"
      viewBox="0 0 110 90"
      width="110"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(1)">
          <g fill="#46B446" transform="translate(56.599 .136)">
            <path d="M7.392.259C7.27.147 7.055.03 6.706.1c-2.213.457-3.94 1.385-5.28 2.838C.544 3.897.122 4.971.136 6.203c-.006.285.047.6.099.904l.028.173c.053.325.26.875 1.059.892h.115c1.14 0 2.184-.361 3.104-1.076.48-.375.9-.815 1.246-1.308.988-1.409 1.595-3.03 1.807-4.84.015-.123.056-.45-.202-.69" />
          </g>
          <path
            d="M45.176 15.5a1.187 1.187 0 01-.467.786c-.022.016-2.206 1.743-1.144 5.506.08.283.1.592-.01.865a1.192 1.192 0 01-2.256-.1c-1.596-5.437 1.83-8.073 1.98-8.185a1.196 1.196 0 011.897 1.128zm26.018 4.825c-.03-2.142-.345-3.862-.992-5.413-1.46-3.491-4.473-5.434-8.251-5.332-1.997.054-3.928.555-5.903 1.534l-.283.142c-.355.178-.69.347-1.011.463-.256.092-.454.095-.687.011-.511-.184-1.02-.425-1.557-.68l-.213-.1c-2.225-1.052-4.318-1.494-6.395-1.354-3.594.241-6.184 2.165-7.49 5.563-.646 1.676-.951 3.537-.91 5.53.088 4.118 1.318 7.892 3.656 11.217a12.89 12.89 0 003.435 3.36c2.239 1.48 4.685 1.718 7.27.706.221-.086.44-.181.656-.276.442-.193.823-.36 1.176-.418.742-.122 1.477-.011 2.243.338.12.054.238.11.357.166.547.259 1.167.551 1.888.709a7.05 7.05 0 001.508.165c1.225 0 2.45-.325 3.659-.97 1.784-.955 3.303-2.41 4.643-4.452 1.66-2.53 2.692-5.47 3.066-8.739.076-.653.12-1.346.135-2.117v-.053z"
            fill="#E1523D"
          />
          <path
            d="M50.343 4.293l-.156.132c.021.026 1.687 2.022 2.235 4.332.382.158.764.327 1.147.517l.292.145.005.003c.287.145.623.151.915.016.168-.077.344-.16.528-.248l.18-.085a10.719 10.719 0 00-.694-2.608 15.844 15.844 0 00-2.357-3.985 1.48 1.48 0 00-2.599.837c-.031.394.092.776.348 1.076l.156-.132z"
            fill="#472001"
          />
          <path
            d="M30.0223095 54.79348L82.9934014 54.79348 82.9934014 40.8214061 30.0223095 40.8214061z"
            fill="#04817C"
          />
          <path
            d="M84.9909859 54.79348L89.3041438 54.79348 89.3041438 40.8214061 84.9909859 40.8214061z"
            fill="#04817C"
          />
          <path
            d="M23.7115672 54.79348L28.0247251 54.79348 28.0247251 40.8214061 23.7115672 40.8214061z"
            fill="#04817C"
          />
          <path
            d="M100.87 40.821h-9.568v13.973h9.569a2.822 2.822 0 002.822-2.823v-8.327a2.822 2.822 0 00-2.822-2.823"
            fill="#04817C"
          />
          <path
            d="M21.714 40.821h-9.417a2.822 2.822 0 00-2.822 2.823v8.327a2.822 2.822 0 002.822 2.823h9.417V40.82z"
            fill="#04817C"
          />
          <path
            d="M91.396 59.001H77.741v13.972h13.655a2.822 2.822 0 002.823-2.822v-8.328a2.822 2.822 0 00-2.823-2.822"
            fill="#79A09F"
          />
          <path
            d="M27.752 64.887h38.52a1.153 1.153 0 010 2.306h-38.52a1.153 1.153 0 110-2.306zm-9.375 8.086h57.367V59.001H18.377v13.972z"
            fill="#79A09F"
          />
          <path
            d="M16.38 59.001H2.821A2.822 2.822 0 000 61.823v8.328a2.822 2.822 0 002.822 2.822H16.38V59.001z"
            fill="#79A09F"
          />
          <path
            d="M28.6194423 90.2187942L94.5492341 90.2187942 94.5492341 77.1691477 28.6194423 77.1691477z"
            fill="#004543"
          />
          <path
            d="M102.623 85.201a1.517 1.517 0 110-3.033 1.517 1.517 0 010 3.033m3.248-8.032h-9.324v13.05h9.324a2.822 2.822 0 002.823-2.823v-7.404a2.823 2.823 0 00-2.823-2.823"
            fill="#004543"
          />
          <path
            d="M20.56 85.197a1.517 1.517 0 11.001-3.034 1.517 1.517 0 010 3.034zm-3.263-8.028a2.823 2.823 0 00-2.822 2.822v7.406a2.822 2.822 0 002.822 2.822h9.325v-13.05h-9.325z"
            fill="#004543"
          />
        </g>
      </g>
    </svg>
  )
}

export default AppleAndBooksIconElem
