import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import DragQuestionDropZonesComponent from './component'

const DragQuestionDropZonesContainer = ({
  canSubmitForm,
  draggables,
  dropZones,
  handleDrop,
  isOverDropZoneId,
  isSubmitted,
  onKeyDown,
  placeholderHeight,
}) => {
  return (
    <DragQuestionDropZonesComponent
      canSubmitForm={canSubmitForm}
      draggables={draggables}
      dropZones={dropZones}
      handleDrop={handleDrop}
      isOverDropZoneId={isOverDropZoneId}
      isSubmitted={isSubmitted}
      onKeyDown={onKeyDown}
      placeholderHeight={placeholderHeight}
    />
  )
}

DragQuestionDropZonesContainer.propTypes = {
  canSubmitForm: PropTypes.func,
  draggables: PropTypes.array,
  dropZones: PropTypes.array,
  handleDrop: PropTypes.func,
  id: PropTypes.string,
  isOverDropZoneId: PropTypes.string,
  isSubmitted: PropTypes.bool,
  onKeyDown: PropTypes.func,
  placeholderHeight: PropTypes.number,
}

DragQuestionDropZonesContainer.defaultProps = {
  canSubmitForm() {},
  handleDrop() {},
  dropZones: [],
  draggables: [],
  onKeyDown() {},
}

const mapStateToProps = ({ player }, ownProps) => {
  const { id } = ownProps || {}
  const interactiveState = player.interactiveStates[id] || null

  return {
    ...interactiveState,
  }
}

export default connect(mapStateToProps)(DragQuestionDropZonesContainer)
