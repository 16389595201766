import {
  startVisibilityListener,
  stopVisibilityListener,
} from '../../utils/browserHelper'
import {
  addIsoDurations,
  getDurationSinceIso,
  getIsoDate,
  getZeroDuration,
  subtractIsoDurations,
} from '../../utils/dateHelper'

import { DEBUG, DEFAULT_INTERVAL, NO_OP } from './constants'

const SessionTimeManager = function(
  startCallback = NO_OP,
  stopCallback = NO_OP,
  intervalCallback = NO_OP,
  intervalTime = DEFAULT_INTERVAL,
  autorunListeners = true, // allow option to disable visibility listeners for unit testing
) {
  let pollInterval
  let pollStartDate
  // track how much time we were paused to subtract:
  let pollPauseDate
  let durationPaused = getZeroDuration()

  const startPoll = () => {
    DEBUG && console.info('Session active')

    pollStartDate = getIsoDate()
    pollPauseDate = null
    durationPaused = getZeroDuration()
    pollInterval = setInterval(onInterval, intervalTime)

    startCallback(pollStartDate)
  }

  const pausePoll = () => {
    DEBUG && console.info('Session timer paused')
    clearInterval(pollInterval)

    pollPauseDate = getIsoDate()
  }

  const resumePoll = () => {
    DEBUG && console.info('Session timer resumed')

    pollInterval = setInterval(onInterval, intervalTime)
    const timePaused = getDurationSinceIso(pollPauseDate)
    durationPaused = addIsoDurations(durationPaused, timePaused)
    pollPauseDate = null
  }

  const stopPoll = options => {
    DEBUG && console.info('Session inactive')

    let durationIso = getDurationSinceIso(pollStartDate)
    durationIso = subtractIsoDurations(durationIso, durationPaused)

    clearInterval(pollInterval)
    pollStartDate = null
    pollPauseDate = null
    durationPaused = getZeroDuration()
    stopCallback(durationIso, options)
  }

  const onInterval = async () => {
    let durationIso = getDurationSinceIso(pollStartDate)

    durationIso = subtractIsoDurations(durationIso, durationPaused)

    DEBUG && console.info('onInterval: ', durationIso)

    intervalCallback(durationIso)
  }

  const startListeners = () => {
    const terminateWithApi = false
    const options = { terminateWithApi }
    const onHiddenParams = [options]
    const onVisibleParams = []

    startVisibilityListener(
      stopPoll,
      onHiddenParams,
      startPoll,
      onVisibleParams,
    )
  }

  const stopListeners = () => stopVisibilityListener()

  if (autorunListeners) {
    startListeners()
  }

  return {
    pausePoll,
    resumePoll,
    startListeners,
    startPoll,
    stopListeners,
    stopPoll,
  }
}

export default SessionTimeManager
