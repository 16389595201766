/* istanbul ignore file */
// we don't unit test SVGs
import React from 'react'

const ScriptIcon = () => (
  <svg
    height="25"
    viewBox="0 0 25 25"
    width="25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m3.495975.0001.075625.003h15.487c1.726 0 3.126 1.399 3.126 3.125v14.633h1.923c.493 0 .893.4.893.893v3.125c0 1.726-1.399 3.125-3.125 3.125h-13.84c-.05.004-.101.004-.151 0h-.295c-1.727 0-3.125-1.399-3.125-3.125v-14.633h-3.572c-.492 0-.893-.4-.893-.893v-3.125c0-1.726 1.4-3.125 3.125-3.125h.295c.051-.004.102-.004.152 0zm19.718625 19.547h-12.5v2.232c-.002.464-.109.922-.313 1.339h11.474c.739 0 1.339-.6 1.339-1.339zm-4.156-17.758h-13.121c.204.417.31.875.312 1.339v18.651c0 .739.6 1.339 1.34 1.339.739 0 1.339-.6 1.339-1.339v-3.125c0-.493.4-.893.893-.893h10.577v-14.633c0-.74-.6-1.339-1.34-1.339zm-1.5902 11.4529c.493 0 .893.4.893.893s-.4.893-.893.893h-7.662c-.493 0-.893-.4-.893-.893s.4-.893.893-.893zm0-3.9561c.493 0 .893.4.893.893s-.4.893-.893.893h-7.662c-.493 0-.893-.4-.893-.893s.4-.893.893-.893zm-.0004-3.956c.494 0 .893.4.893.893s-.399.893-.893.893h-7.646c-.493 0-.894-.4-.894-.893s.401-.893.894-.893zm-14.3434-3.5408c-.739 0-1.339.599-1.339 1.339v2.233h2.679v-2.233c0-.74-.6-1.339-1.34-1.339z" />
  </svg>
)

export default ScriptIcon
