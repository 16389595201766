import React from 'react'

import PropTypes from 'prop-types'

import ImageHotspotsToolTip from '../ImageHotspotsToolTip'

import {
  HotspotsTarget,
  HotspotsTargetOutline,
  HotspotsTargetWrapper,
} from './style'

const ACCESSIBILITY_LABEL_PREFIX = 'This is a hotspot target labeled '
const DATA_TEST_ID_PREFIX = 'image-hotspots-target-'
const WRAPPER_DATA_TEST_ID_PREFIX = 'target-wrapper-'
const DEFAULT_ACCESSIBILITY_LABEL_PREFIX = 'This is hotspot target number '
const EMPTY_STRING = ''
const FIRST_COMMA = ','
const ID_NAME_START_INDEX = 0

const ImageHotspotsTargetComponent = props => {
  const {
    id,
    index,
    isHovered,
    isTargetClicked,
    isToolTipFlipped,
    left,
    onClick,
    handleEnter,
    handleLeave,
    scaleAnimRef,
    toolTip,
    top,
    setHotspotReference,
    reference,
  } = props || {}

  const getTargetProps = () => {
    const hotspotIdPositionStart = id.indexOf(FIRST_COMMA)
    const hotspotName = id.substring(
      ID_NAME_START_INDEX,
      hotspotIdPositionStart,
    )
    const defaultAccessibilityLabel = `${DEFAULT_ACCESSIBILITY_LABEL_PREFIX}${index}`
    const customAccessibilityLabel = `${ACCESSIBILITY_LABEL_PREFIX}${hotspotName}`
    const accessibilityLabel =
      hotspotName !== EMPTY_STRING
        ? customAccessibilityLabel
        : defaultAccessibilityLabel
    const dataTestId = `${DATA_TEST_ID_PREFIX}${index}`
    const targetProps = {
      accessibilityLabel,
      'data-testid': dataTestId,
      id,
      index,
      isHovered,
      isTargetClicked,
      onMouseEnter: handleEnter,
      onMouseLeave: handleLeave,
      scaleAnimRef,
    }

    return targetProps
  }

  const getWrapperProps = () => {
    const handleClick = () => {
      if (onClick) onClick()
      setHotspotReference(reference)
    }
    return {
      'data-testid': `${WRAPPER_DATA_TEST_ID_PREFIX}${index}`,
      left,
      onBlur: handleLeave,
      onFocus: handleEnter,
      onClick: handleClick,
      top,
    }
  }

  const targetProps = getTargetProps() || {}
  const wrapperProps = getWrapperProps() || {}

  return (
    <HotspotsTargetWrapper {...wrapperProps} ref={reference}>
      <HotspotsTarget
        {...targetProps}
        style={{
          transform: [{ scaleX: scaleAnimRef }, { scaleY: scaleAnimRef }],
        }}
      >
        <HotspotsTargetOutline
          isHovered={isHovered}
          isTargetClicked={isTargetClicked}
        />
        <ImageHotspotsToolTip
          index={index}
          isToolTipFlipped={isToolTipFlipped}
          isVisible={isHovered}
          toolTip={toolTip}
        />
      </HotspotsTarget>
    </HotspotsTargetWrapper>
  )
}

ImageHotspotsTargetComponent.propTypes = {
  id: PropTypes.string,
  index: PropTypes.number,
  isTargetClicked: PropTypes.bool,
  isToolTipFlipped: PropTypes.bool,
  left: PropTypes.number,
  onClick: PropTypes.func,
  top: PropTypes.number,
}

ImageHotspotsTargetComponent.defaultProps = {
  left: 0,
  onClick() {},
  top: 0,
}

export default ImageHotspotsTargetComponent
