const DECIMAL_TO_PERCENT_MULTIPLIER = 100
const EMPTY_STRING = ''
const NEGATIVE = '-'
const NUMBER = 'number'
const NUMERIC = /\d/g
const PERCENT_TO_DECIMAL_MULTIPLIER = 0.01
const STRING = 'string'

export const percentToDecimal = (percent = 0) => {
  switch (typeof percent) {
    case NUMBER:
      return percentNumberToDecimal(percent)
    case STRING:
      return percentStringToDecimal(percent)
    default:
      return 0
  }
}

const percentNumberToDecimal = percent => {
  const decimal = percent * PERCENT_TO_DECIMAL_MULTIPLIER

  return decimal
}

const percentStringToDecimal = percent => {
  const negativePrefix =
    percent.slice(0, 1) === NEGATIVE ? NEGATIVE : EMPTY_STRING
  let numbersString = percent.match(NUMERIC)

  if (!numbersString) {
    return 0
  }

  numbersString = numbersString.join(EMPTY_STRING)
  numbersString = `${negativePrefix}${numbersString}`

  let decimal = parseInt(numbersString)
  decimal = decimal * PERCENT_TO_DECIMAL_MULTIPLIER

  return decimal
}

export const decimalToPercent = (decimal = 0) => {
  if (typeof decimal != NUMBER) {
    return 0
  }

  const percent = decimal * DECIMAL_TO_PERCENT_MULTIPLIER

  return percent
}
