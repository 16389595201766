import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { fontColor } from '../../theme/defaultValues'

export const CharCountText = styled.p`
  color: ${fontColor};
  display: flex;
  font-family: ${themeGet('global.font.family')};
  font-size: 0.875rem;
  letter-spacing: -1px;
  margin-right: 0.75rem;
  margin-top: 0.6875rem;
  position: absolute;
  right: 0;
  top: 0;
`
