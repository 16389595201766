import React from 'react'

import PropTypes from 'prop-types'
import { getConstantByLocale, getLocaleFromSession } from 'utils/locale'

import checkSvg from '../../../../assets/images/checkWhiteInCircleGreen.svg'

import {
  DEFAULT_MESSAGE,
  FEEDBACK_LINK_DATA_TEST_ID,
  FEEDBACK_LINK_TEXT,
  IMAGE_DATA_TEST_ID,
  TEXT_DATA_TEST_ID,
  TITLE,
} from './constants'
import ExitLesson from './ExitLesson'
import {
  Body,
  CheckImage,
  Container,
  ContentContainer,
  FeedbackLink,
  FeedbackLinkContainer,
  TextContainer,
  Title,
} from './style'

const currentLocale = getLocaleFromSession()
const getDefaultMessage = getConstantByLocale(DEFAULT_MESSAGE, currentLocale)
const getTitle = getConstantByLocale(TITLE, currentLocale)
const getFeedBackLinkText = getConstantByLocale(
  FEEDBACK_LINK_TEXT,
  currentLocale,
)

const LessonCompleteComponent = ({ message }) => {
  return (
    <Container>
      <ContentContainer>
        <CheckImage data-testid={IMAGE_DATA_TEST_ID} src={checkSvg} />
        <Title>{getTitle}</Title>
        <TextContainer data-testid={TEXT_DATA_TEST_ID}>
          <Body>{message}</Body>
        </TextContainer>
      </ContentContainer>
      <ExitLesson />
      <FeedbackLinkContainer>
        <FeedbackLink data-testid={FEEDBACK_LINK_DATA_TEST_ID}>
          {getFeedBackLinkText}
        </FeedbackLink>
      </FeedbackLinkContainer>
    </Container>
  )
}

LessonCompleteComponent.propTypes = {
  feedbackUrl: PropTypes.string,
  message: PropTypes.string,
}

LessonCompleteComponent.defaultProps = {
  feedbackUrl: '',
  message: getDefaultMessage,
}

export default LessonCompleteComponent
