import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { fontWeightNormal } from '../../theme/defaultValues'
import FormField from '../FormField'

export const FormFieldRadio = styled(FormField)`
  > label {
    font-family: ${themeGet('fontFamilySemibold')};
    font-size: 1.25rem;
    font-weight: ${fontWeightNormal};
    line-height: 1.5rem;
    margin-bottom: 0.875rem;
  }
`

export const RadioOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${themeGet('breakpoints.mobile')} {
    flex-direction: column;
  }
`

export const RadioInputLabel = styled.label`
  align-items: baseline;
  cursor: pointer;
  display: flex;
  font-family: ${themeGet('fontFamily')};
  font-size: 1rem;
  font-weight: ${fontWeightNormal};
  line-height: 1.5rem;
  padding-left: 0.5rem;
  &::before {
    border: 2px solid ${themeGet('colors.borderColor')};
    border-radius: 50%;
    box-sizing: border-box;
    content: '';
    display: inline-block;
    height: 1.25rem;
    margin-left: -1.75rem;
    position: absolute;
    transition: all 150ms;
    width: 1.25rem;
  }
  ${themeGet('breakpoints.mobile')} {
    margin-bottom: 1rem;
    margin-right: 0;
  }
`

export const RadioInputContainer = styled.div`
  display: flex;
  margin-right: 2.5rem;
`

export const RadioInput = styled.input`
  box-sizing: border-box;
  cursor: pointer;
  height: 1.25rem;
  margin: 0;
  opacity: 0;
  position: relative;
  width: 1.25rem;

  &:hover ~ ${RadioInputLabel} {
    &::before {
      border-color: ${themeGet(`colors.themeAccent`)};
    }
  }

  &:checked + ${RadioInputLabel} {
    &::before {
      border-color: ${themeGet(`colors.themePrimary`)};
      border-width: 0.375rem;
    }
  }
  
  /* &:focus + ${RadioInputLabel} {
    &::before {
      outline: -webkit-focus-ring-color auto 5px;
    }
  } */
`
