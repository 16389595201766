import React from 'react'

import PropTypes from 'prop-types'
import { useDrop } from 'react-dnd'

import { DIAMETER } from '../../SubmitAnswerIcon/style'
import { DRAGGABLE_TYPE } from '../constants'
import { LARGE_DRAGGABLE_WIDTH, SMALL_DRAGGABLE_WIDTH } from '../style'

import DropZoneComponent from './component'

const DragLabelDropZoneContainer = ({
  border,
  canDrop,
  correct,
  dataTestId,
  graded,
  id,
  isLong,
  isVisible,
  left,
  onDrop,
  top,
}) => {
  const [{ isOver }, drop] = useDrop({
    accept: DRAGGABLE_TYPE,
    drop: draggable => {
      canDrop && onDrop(draggable.id, id)
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  })
  const dropRef = canDrop ? drop : null
  const isActive = canDrop && isOver
  let dropZoneWidth = isLong ? LARGE_DRAGGABLE_WIDTH : SMALL_DRAGGABLE_WIDTH
  dropZoneWidth = parseInt(dropZoneWidth.slice(0, dropZoneWidth.length - 2))
  const submitAnswerIconLeft = left + dropZoneWidth - DIAMETER / 2 //DIAMETER/2 = 13.5 which is the width of submit answer icon
  return (
    <DropZoneComponent
      border={border}
      correct={correct}
      dataTestId={dataTestId}
      dropRef={dropRef}
      graded={graded}
      isActive={isActive}
      isLong={isLong}
      isVisible={isVisible}
      left={left}
      submitAnswerIconLeft={submitAnswerIconLeft}
      top={top}
    />
  )
}

DragLabelDropZoneContainer.propTypes = {
  border: PropTypes.bool,
  correct: PropTypes.bool,
  canDrop: PropTypes.bool,
  dataTestId: PropTypes.string,
  graded: PropTypes.bool,
  id: PropTypes.string,
  isLong: PropTypes.bool,
  isVisible: PropTypes.bool,
  left: PropTypes.number,
  onDrop: PropTypes.func,
  questions: PropTypes.object,
  top: PropTypes.number,
}

DragLabelDropZoneContainer.defaultProps = {
  border: true,
  canDrop: true,
  isVisible: true,
  onDrop() {},
}

export default DragLabelDropZoneContainer
