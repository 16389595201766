import React, { useEffect } from 'react'

import { COMPLETION_STATUS } from '@cfc/clifford/dist/enums'
import { updateCompletionStatus } from 'lib/state/actions/cmi'
import { gotoDoneSlide } from 'lib/state/actions/player'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getConstantByLocale, getLocaleFromSession } from 'utils/locale'

import MarkDoneComponent from './component'
import { TEXT_LESSON_COMPLETE, TEXT_MARK_LESSON_DONE } from './constants'

const MarkDoneButtonContainer = ({
  isFamilyMode,
  isCurriculumPreviewMode,
  isHighSchoolMode,
  isLessonDone,
  isNextSlide,
  isPrepMode,
  isPrevSlide,
  sections,
  goToDoneSlide,
  isDoneSlide,
  updateLessonToComplete,
}) => {
  // TODO: Remove isHighSchoolMode check once high school mark as complete is implemented
  if (isFamilyMode || isCurriculumPreviewMode || isHighSchoolMode) {
    return null
  }
  useEffect(() => {
    if (isDoneSlide) {
      updateLessonToComplete()
    }
  }, [isDoneSlide])
  const currentLocale = getLocaleFromSession()
  const isButtonActive = isLessonDone || !isNextSlide
  const markDoneText = isLessonDone
    ? getConstantByLocale(TEXT_LESSON_COMPLETE, currentLocale)
    : getConstantByLocale(TEXT_MARK_LESSON_DONE, currentLocale)
  const handleClick = async () => goToDoneSlide(sections.length - 1, 0)

  return (
    <MarkDoneComponent
      isButtonActive={isButtonActive}
      isLessonDone={isLessonDone}
      isPrevSlide={isPrevSlide}
      isPrepMode={isPrepMode}
      markDoneText={markDoneText}
      onClick={handleClick}
    />
  )
}

MarkDoneButtonContainer.propTypes = {
  isFamilyMode: PropTypes.bool,
  isCurriculumPreviewMode: PropTypes.bool,
  isHighSchoolMode: PropTypes.bool,
  isLessonDone: PropTypes.bool,
  isNextSlide: PropTypes.bool,
  isPrepMode: PropTypes.bool,
  isPrevSlide: PropTypes.bool,
  sections: PropTypes.array,
  isDoneSlide: PropTypes.bool,
  goToDoneSlide: PropTypes.func,
  updateLessonToComplete: PropTypes.func,
}

MarkDoneButtonContainer.defaultProps = {
  isFamilyMode: false,
  isCurriculumPreviewMode: false,
  isLessonDone: false,
  isNextSlide: false,
  isDoneSlide: false,
  sections: [],
  goToDoneSlide() {},
  updateLessonToComplete() {},
}

const mapDispatchToProps = dispatch => ({
  goToDoneSlide: (sectionIndex, slideIndex) =>
    dispatch(gotoDoneSlide(sectionIndex, slideIndex)),
  updateLessonToComplete: () =>
    dispatch(updateCompletionStatus(COMPLETION_STATUS.Completed, true)),
})

const mapStateToProps = ({
  cmi: { completion_status },
  player: {
    isFamilyMode,
    isCurriculumPreviewMode,
    isHighSchoolMode,
    isNextSlide,
    isPrepMode,
    isPrevSlide,
    sections,
    isDoneSlide,
  },
}) => {
  return {
    isFamilyMode,
    isCurriculumPreviewMode,
    isHighSchoolMode,
    isLessonDone: completion_status === COMPLETION_STATUS.Completed,
    isNextSlide,
    isPrepMode,
    isPrevSlide,
    sections,
    isDoneSlide,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MarkDoneButtonContainer)
