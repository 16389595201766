/* istanbul ignore file */
import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { findNodeHandle, Image } from 'react-native-web'

export { View, Text, StyleSheet } from 'react-native-web'
export { ImageTestable as Image }

const DATA_TESTID = 'data-testid'
const NO_OP = () => {}

//Injects data-testid into a nested image for testing
class ImageTestable extends Component {
  constructor(props) {
    super(props)

    this.imageRef = React.createRef()
  }

  componentDidMount() {
    this.setDataTestId()
  }

  setDataTestId = () => {
    const { 'data-testid': dataTestId } = this.props

    try {
      const nodeHandle = findNodeHandle(this.imageRef.current)
      const imageNode =
        nodeHandle && nodeHandle.children && nodeHandle.children[1]
      imageNode && imageNode.setAttribute(DATA_TESTID, dataTestId)
    } catch (err) {
      NO_OP()
    }
  }

  onLoad = () => {
    this.setDataTestId()
  }

  render() {
    const { ...rest } = this.props
    return <Image {...rest} ref={this.imageRef} onLoad={this.onLoad} />
  }
}

ImageTestable.propTypes = {
  'data-testid': PropTypes.string,
}
