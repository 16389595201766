import {
  LINK,
  LINK_ID_AMP,
  LINK_REL_AMP,
  LINK_SOURCE_AMP,
  SCRIPT,
  SCRIPT_ID_AMP,
  SCRIPT_SOURCE_AMP,
} from './constants';

const getAzureMediaPlayerScriptTag = (
  onLoad = () => {},
  onError = () => {},
) => {
  const scriptTag = document.createElement(SCRIPT)
  scriptTag.id = SCRIPT_ID_AMP
  scriptTag.onerror = () => onError()
  scriptTag.onload = () => onLoad()
  scriptTag.src = SCRIPT_SOURCE_AMP

  return scriptTag
}

const getAzureMediaPlayerLinkTag = () => {
  const linkTag = document.createElement(LINK)
  linkTag.href = LINK_SOURCE_AMP
  linkTag.id = LINK_ID_AMP
  linkTag.rel = LINK_REL_AMP

  return linkTag
}

export const initializeAzureMediaPlayer = () => {
  return new Promise((resolve, reject) => {
    const scriptTag = getAzureMediaPlayerScriptTag(resolve, reject)
    const linkTag = getAzureMediaPlayerLinkTag()
    const firstDocumentChild = document.head.firstChild
    document.body.appendChild(scriptTag)
    document.head.insertBefore(linkTag, firstDocumentChild)
  })
}

export const initializeElmo = () => {
  // hack to retrieve elmo function which was previously a script tag
  require('@cfc/elmo');
}

export const initAmpAndElmo = async () => {
  await initializeAzureMediaPlayer()
  await initializeElmo()
}
