import styled from 'styled-components/native'

import { DIVIDER_WIDTH, FULL_HEIGHT, HALF_WIDTH } from '../constants'
import {
  ErrorMessageContainer,
  ErrorMessageTextContainer,
  ImageContainer,
} from '../ImageJuxtFrame/style'

export const LeftImageContainer = styled(ImageContainer).attrs(
  ({
    theme: {
      colorVars: { imageJuxtaposition: colors },
    },
    width,
  }) => ({
    dividerColor: colors.frame.divider,
    dividerWidth: DIVIDER_WIDTH,
    width,
  }),
)`
  border-right-color: ${props => props.dividerColor};
  border-right-width: ${props => props.dividerWidth}px;
  width: ${props => props.width}px;
`

export const LeftImage = styled.Image.attrs(({ width }) => ({
  height: FULL_HEIGHT,
  width,
}))`
  height: ${props => props.height};
  width: ${props => props.width}px;
`

export const LeftErrorMessageContainer = styled(ErrorMessageContainer)`
  flex: 1;
`

export const LeftErrorMessageTextContainer = styled(
  ErrorMessageTextContainer,
).attrs({
  width: HALF_WIDTH,
})`
  width: ${props => props.width};
`
