import React from 'react'

import PropTypes from 'prop-types'
import { isEmptyString } from 'utils/stringHelper'

import { ImageBackgroundContainer } from './style'

const DATA_TEST_ID = 'image-background'
const IMAGE = 'img'

const ContentfulImageBackgroundComponent = props => {
  const { accessibilityLabel, children, height, source, width } = props || {}

  const labelIsEmptyString = isEmptyString(accessibilityLabel)

  const switchAccessibleRole = labelIsEmptyString ? undefined : IMAGE
  const switchAccessibleLabel = labelIsEmptyString
    ? undefined
    : accessibilityLabel

  return (
    <ImageBackgroundContainer
      accessibilityRole={switchAccessibleRole}
      accessibilityLabel={switchAccessibleLabel}
      height={height}
      source={source}
      width={width}
      data-testid={DATA_TEST_ID}
    >
      {children}
    </ImageBackgroundContainer>
  )
}

ContentfulImageBackgroundComponent.propTypes = {
  accessibilityRole: PropTypes.string,
  children: PropTypes.element,
  height: PropTypes.string, //percentage string
  source: PropTypes.string,
  width: PropTypes.string, //percentage string
}

export default ContentfulImageBackgroundComponent
