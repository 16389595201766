import React, { Component } from 'react'

import PropTypes from 'prop-types'

import MultiChoiceButtonComponent from './component'

const ARIA_LABEL_SUFFIX = ' selected answers'
const RESET = 'Reset'
const SUBMIT = 'Submit'

export class MultiChoiceButtonContainer extends Component {
  setRef = multiChoiceStyledButton => {
    this.multiChoiceStyledButton = multiChoiceStyledButton
  }

  onSubmit = async () => {
    const { onSubmit } = this.props

    this.multiChoiceStyledButton.blur()
    onSubmit()
  }

  renderProps = () => {
    const { disabled, onReset, resetText, isSubmitted, submitText } = this.props
    const onClick = isSubmitted ? onReset : this.onSubmit
    const text = isSubmitted ? resetText : submitText
    const ariaLabelPrefix = isSubmitted ? RESET : SUBMIT
    const ariaLabel = ariaLabelPrefix + ARIA_LABEL_SUFFIX
    const dataTestId = ariaLabel
    const setRef = this.setRef

    return {
      ariaLabel,
      dataTestId,
      disabled,
      onClick,
      text,
      setRef,
      onSubmit: onClick,
    }
  }

  render = () => {
    const renderProps = this.renderProps()

    return <MultiChoiceButtonComponent {...renderProps} />
  }
}

MultiChoiceButtonContainer.propTypes = {
  disabled: PropTypes.bool,
  onReset: PropTypes.func,
  onSubmit: PropTypes.func,
  resetText: PropTypes.string,
  isSubmitted: PropTypes.bool,
  setRef: PropTypes.func,
  submitText: PropTypes.string,
}

MultiChoiceButtonContainer.defaultProps = {
  onReset() {},
  onSubmit() {},
  resetText: RESET,
  setRef() {},
  submitText: SUBMIT,
}

export default MultiChoiceButtonContainer
