import styled from 'styled-components'

import {
  BaseContainer,
  BaseGrip,
  BaseHoverIndicator,
  BaseText,
} from '../DraggableBase/style'

export const Container = styled(BaseContainer).attrs(
  ({
    height,
    scale,
    showBorder,
    theme: {
      colorVars: { dragAndDrop: colors },
    },
    width,
  }) => ({
    style: {
      alignItems: 'center',
      border: showBorder ? `1px solid ${colors.draggable.borderColor}` : 'none',
      borderRadius: `${5 * scale}px`,
      //These ternary backup values are here for DragWord. Otherwise, DragWord sends dimensions 0x0
      //when dragging a draggable from a question. Would be good to revisit this when there is time
      //to make structural changes to DragWord and avoid the ternary.
      height: height ? height * scale : `${45 * scale}px`,
      width: width ? width * scale : `${253 * scale}px`,
    },
  }),
)``

export const HoverIndicator = styled(BaseHoverIndicator).attrs(
  ({
    scale,
    theme: {
      colorVars: { dragAndDrop: colors },
    },
  }) => ({
    style: {
      backgroundColor: colors.hoverIndicator.colorHover,
      borderRadius: '3px 0 0 3px',
      width: `${5 * scale}px`,
    },
  }),
)``

export const Grip = styled(BaseGrip).attrs(
  ({
    scale,
    theme: {
      colorVars: { dragAndDrop: colors },
      fonts: { dragAndDrop: fonts },
    },
  }) => ({
    style: {
      fontSize: `${fonts.grip.fontSize * scale}rem`,
      height: `${20 * scale}px`,
      letterSpacing: `${2 * scale}px`,
      lineHeight: `${5 * scale}px`,
      paddingBottom: `${5 * scale}px`,
      paddingLeft: `${6 * scale}px`,
      paddingRight: `${10 * scale}px`,
      paddingTop: `${3 * scale}px`,
      textShadow: `1px 0 1px ${colors.grip.textShadowColor}`,
      width: `${10 * scale}px`,
    },
  }),
)``

export const Text = styled(BaseText).attrs(
  ({
    scale,
    theme: {
      fonts: { dragAndDrop: fonts },
    },
  }) => ({
    style: {
      fontFamily: fonts.draggable.fontFamily,
      fontSize: `${fonts.draggable.fontSize * scale}rem`,
      fontWeight: fonts.draggable.fontWeight,
      paddingRight: `${10 * scale}px`,
    },
  }),
)``
