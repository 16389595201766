import VideoButtonHover from 'assets/images/playBlackInCircleTransparent.svg'
import VideoButtonDefault from 'assets/images/playWhiteInCircleBlack.svg'
import styled from 'styled-components'

export const Wrapper = styled.div.attrs(
  ({
    dimensionsOffset,
    isTranscriptActive,
    scaleOffset,
    scale,
    backgroundColor,
    canShowAccessibilityMenu,
    menuBarRenderedForEnglish,
  }) => ({
    backgroundColor,
    dimensionsOffset,
    scaleOffset,
    scale,
    height:
      canShowAccessibilityMenu && menuBarRenderedForEnglish ? '92%' : '100%',
    posterImageHeight: isTranscriptActive ? '94%' : '100%',
  }),
)`
  background-color: ${props => props.theme.colorVars.black};
  display: flex;
  flex-direction: row;
  height: ${({ height }) => height};
  overflow: hidden;
  position: absolute;
  width: 100%;
  .azuremediaplayer {
    background-color: ${props => props.backgroundColor};
  }

  // Prevent control bar from disappearing behind video with ratiowrapper
  .vjs-control-bar {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
  }

  // **** START Override RatioWrapper scale to fix track scrubber ****

  // Default control bar is set up as display: table. Converts essentials to display: flex to fix alignment and spacing.
  .amp-controlbaricons-middle {
    align-items: center;
    display: flex !important;
    flex-grow: 1 !important;
    justify-content: space-between;

    .vjs-current-time {
      align-items: center;
      display: flex !important;
      justify-content: center;
      width: 5% !important;
    }

    .vjs-duration {
      align-items: center;
      display: flex !important;
      justify-content: center;
      width: 4.3% !important;
    }
  }

  .vjs-progress-control {
    display: flex !important;
    justify-content: center;

    .vjs-progress-holder {
      display: flex !important;

      // This undoes the ratio wrapper scaling on the progress bar and then stretches it back out to the width of the container
      min-width: ${props => props.dimensionsOffset}% !important;
      transform: scaleX(${props => props.scaleOffset}) !important;
    }

    .amp-time-tooltip {
      // Re-apply ratio wrapper scaling to tooltip so the font isn't deformed
      transform: scaleX(${props => props.scale}) !important;

      // TODO: Tooltip has issues when scrubbing past the initial load progress point on the progress bar when you first hit the play button
      left: -17px !important;
      width: 2.5rem !important;
    }
  }
  // **** END Override RatioWrapper scale to fix track scrubber ****

  .vjs-fullscreen-control {
    max-width: 50%;
    display: none;
  }

  .amp-flush-skin.amp-big-play-centered:hover .vjs-big-play-button,
  .amp-flush-skin.amp-big-play-centered .vjs-big-play-button,
  .amp-flush-skin.amp-big-play-centered .vjs-big-play-button:focus {
    transform: none !important;
  }

  .vjs-poster img {
    height: ${({ posterImageHeight }) => posterImageHeight};
  }

  .vjs-caption-settings {
    z-index: 3;
  }

  .amp-flush-skin .vjs-big-play-button {
    transition: none !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    margin: auto !important;
  }

  .vjs-big-play-button,
  .vjs-big-play-button:hover::before,
  .vjs-big-play-button::before {
    content: url(${VideoButtonHover});
    height: 110px;
    width: 110px;
  }

  .vjs-big-play-button::before {
    content: url(${VideoButtonDefault});
    height: 110px;
    width: 110px;
  }

  .vjs-big-play-button {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    :focus {
      outline: 3px groove ${props => props.theme.colorVars.blueFocus};
    }
  }

  .vjs-text-track-display {
    div {
      @media screen and (min-width: 3000px) {
        div {
          font-size: 30%;
          width: 100% !important;
        }
      }
      div {
        width: 100% !important;
      }
    }
  }

  .popup {
    background-color: white;
  }

  button {
    &.continue-btn {
      font-size: 16px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      &::after {
        content: 'Continue';
      }
    }
  }
  .amp-logo {
    display: none !important;
  }
`
export const TranscriptOuterWrapper = styled.div`
  height: 95.5%;
  font-family: 'Molde-Regular', sans-serif;
  display: block;
  flex-basis: 30%;
  background-color: ${props => props.theme.colorVars.general.white};
  border-radius: 5px;
  border: 1px solid ${props => props.theme.colorVars.general.lightWhite};
  overflow-y: scroll;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 0.93rem;

  .transcript-heading {
    font-family: 'Molde-Medium', sans-serif;
  }
  .transcript {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .description .transcript-line {
    color: #3c6e90;
  }
`
export const OuterWrapper = styled.div`
  height: 100%;
  width: 100%;
`

export const ItemBar = styled.div`
  height: 3.375rem;
  background-color: ${props => props.theme.colorVars.black};
  display: flex;
  vertical-align: top;
  justify-content: flex-end;
  align-items: center;
  column-gap: 0.75rem;
  padding-right: 1.3rem;
  div {
    cursor: pointer;
  }
  button {
    max-height: 30px;
  }
  svg {
    width: 31px;
  }
`
export const Tooltip = styled.span`
  box-sizing: border-box;
  height: 1.938rem;
  width: auto;
  padding: 0.313rem 1.25rem 0.313rem 1.25rem;
  border: 0.063rem solid #ced8df;
  border-radius: 0.375rem;
  background-color: ${props => props.theme.colorVars.black};
  box-shadow: 0 0 0.75rem 0 rgba(0, 0, 0, 0.1);
  color: ${props => props.theme.colorVars.grey};
  font-family: Molde-Regular;
  font-size: 1rem;
  letter-spacing: 0;
  text-align: center;
  position: absolute;
  right: ${props => props.right}rem;
`
