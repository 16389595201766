import styled from 'styled-components'

import { DIAMETER } from '../../SubmitAnswerIcon/style'
import {
  DRAGGABLE_HEIGHT,
  LARGE_DRAGGABLE_WIDTH,
  SMALL_DRAGGABLE_WIDTH,
} from '../style'

export const Container = styled.div.attrs(
  ({ left, isDropZoneVisible, isLong, top }) => ({
    marginVertical: isDropZoneVisible && !top && !left ? '6px' : '0px',
    position: top && left ? 'absolute' : 'relative',
    style: {
      left: `${left}%`,
      top: `${top}%`,
    },
    width: isLong ? LARGE_DRAGGABLE_WIDTH : SMALL_DRAGGABLE_WIDTH,
  }),
)`
  margin-bottom: ${props => props.marginVertical};
  margin-top: ${props => props.marginVertical};
  position: ${props => props.position};
`

export const DropZoneContainer = styled.div.attrs(
  ({
    border,
    isActive,
    isLong,
    isVisible,
    theme: {
      colorVars: { dragLabel: colors },
    },
  }) => ({
    backgroundColor: isActive
      ? colors.dropZone.backgroundHover
      : colors.dropZone.background,
    borderColor: isActive
      ? colors.dropZone.border
      : border
      ? colors.dropZone.border
      : colors.dropZone.borderOff,
    display: isVisible ? 'flex' : 'none',
    height: DRAGGABLE_HEIGHT,
    width: isLong ? LARGE_DRAGGABLE_WIDTH : SMALL_DRAGGABLE_WIDTH,
  }),
)`
  background-color: ${props => props.backgroundColor};
  border-radius: 5px;
  border: 1px solid ${props => props.borderColor};
  display: ${props => props.display};
  height: ${props => props.height};
  position: ${props => props.position};
  width: ${props => props.width};
`

export const SubmitAnswerIconContainer = styled.div.attrs(({ isLong }) => {
  const draggableHeightNumber = parseInt(
    DRAGGABLE_HEIGHT.slice(0, DRAGGABLE_HEIGHT.length - 2),
  )
  const answerIconHeightNumber = parseInt(
    DIAMETER.slice(0, DIAMETER.length - 2),
  )
  const halfDraggableHeight = draggableHeightNumber / 2
  const halfIconHeight = answerIconHeightNumber / 2
  const top = halfDraggableHeight - halfIconHeight

  return {
    left: isLong ? LARGE_DRAGGABLE_WIDTH : SMALL_DRAGGABLE_WIDTH,
    top: `${top}px`,
  }
})`
  left: ${props => props.left};
  position: absolute;
  top: ${props => props.top};
  z-index: 2;
`
