import React from 'react'

import PropTypes from 'prop-types'

import ContentfulImageBackground from '../../ContentfulImageBackground'

const FULL_WIDTH = '100%'

const ImageComponent = props => {
  const { altText, source, title } = props || {}

  return (
    <ContentfulImageBackground
      altText={altText}
      source={source}
      title={title}
      width={FULL_WIDTH}
    />
  )
}

ImageComponent.propTypes = {
  altText: PropTypes.string,
  source: PropTypes.string,
  title: PropTypes.string,
}

ImageComponent.defaultProps = {}

export default ImageComponent
