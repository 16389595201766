export const hasAllKeys = (obj, keys = []) => {
  if (!obj || typeof obj !== 'object') {
    return false
  }

  if (!keys || typeof keys !== 'object') {
    return false
  }

  const objKeys = Object.keys(obj)
  const hasAllKeys = keys.every(key => objKeys.includes(key))

  return hasAllKeys
}

export const isEmpty = obj => {
  return Object.keys(obj).length === 0
}
