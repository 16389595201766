const defaultProps = (hasHover, hasActive, isMobile) => ({
  height: hasActive ? 35.5 : 32,
  width: 32,
  rect1: {
    fill: '#000000',
    height: 771,
    width: 1366,
    x: 0,
    y: 0,
  },
  rect2: {
    fill: '#000000',
    x: 0,
    y: 41,
    height: 54,
    width: 1366,
    ...(hasHover && {
      x: 9.09494702e-13,
    }),
    ...(hasActive && {
      x: 9.09494702e-13,
    }),
  },
  path1: {
    d:
      'M1,7 L1,25 C1,28.3137085 3.6862915,31 7,31 L25,31 C28.3137085,31 31,28.3137085 31,25 L31,7 C31,3.6862915 28.3137085,1 25,1 L7,1 C3.6862915,1 1,3.6862915 1,7 Z',
    fill: '#000000',
    stroke: '#CED8DF',
    ...(hasHover && {
      fill: '#CED8DF',
    }),
    ...(hasActive && {
      fill: '#CED8DF',
    }),
    ...(hasActive &&
      hasHover && {
        fill: '##OBSIDIAN##',
      }),
  },
  g1: {
    fill: '#CED8DF',
    transform: 'translate(3.000000, 10.000000)',
    ...(hasHover && {
      fill: '#000000',
    }),
    ...(hasActive && {
      fill: '#000000',
    }),
    ...(hasActive &&
      hasHover && {
        fill: '#CED8DF',
      }),
  },
  line: {
    stroke: '#CED8DF',
    strokeLinecap: 'round',
    strokeWidth: 2,
    x1: 1,
    x2: 29,
    y1: 35,
    y2: 35,
  },
  hasHover,
  hasActive,
})

const selaProps = (hasHover, hasActive, isMobile) => {
  const {
    rect1: Rect1Def,
    path1: Path1Def,
    g1: G1Def,
    line: LineDef,
  } = defaultProps(hasHover, hasActive)
  return {
    rect1: {
      ...Rect1Def,
      fill: '#C3C6C8',
      height: 1024,
      width: 1366,
    },
    path1: {
      ...Path1Def,
      fill: '#FFFFFF',
      stroke: '#007980',
      ...(isMobile && {
        d:
          'M0,6 L0,24 C4.05812251e-16,27.3137085 2.6862915,30 6,30 L44,30 C47.3137085,30 50,27.3137085 50,24 L50,6 C50,2.6862915 47.3137085,-6.08718376e-16 44,0 L6,0 C2.6862915,6.08718376e-16 -4.05812251e-16,2.6862915 0,6 Z',
        fill: '#FFFFFF',
        stroke: '#007980',
      }),
      ...(hasHover || hasActive
        ? {
            fill: '#007980',
          }
        : {}),
      ...(hasActive &&
        hasHover && {
          fill: '#FFFFFF',
        }),
    },
    g1: {
      ...G1Def,
      fill: '#007980',
      ...(hasHover || hasActive
        ? {
            fill: '#FFFFFF',
          }
        : {}),
      ...(isMobile && {
        transform: 'translate(13.000000, 10.000000)',
      }),
      ...(hasActive &&
        hasHover && {
          fill: '#007980',
        }),
    },
    line: {
      ...LineDef,
      stroke: '#007980',
      ...(isMobile && {
        strokeLinecap: 'round',
        strokeWidth: 2,
        x1: 2,
        x2: 49,
      }),
    },

    height: hasActive ? 35.5 : 32,
    width: 32,
    ...(isMobile && {
      width: 50,
      height: hasHover ? 36 : 42,
    }),

    hasHover,
    hasActive,
  }
}

const learnProps = (hasHover, hasActive, isMobile) => {
  const { line, path1, g1, ...rest } = selaProps(hasHover, hasActive, isMobile)
  return {
    ...rest,
    path1: {
      ...path1,
      stroke: '#002855',
      ...(hasHover || hasActive
        ? {
            fill: '#002855',
          }
        : {}),
      ...(hasActive &&
        hasHover && {
          fill: '##OBSIDIAN##',
        }),
    },
    g1: {
      ...g1,
      fill: '#002855',
      ...(hasHover || hasActive
        ? {
            fill: '#FFFFFF',
          }
        : {}),
      ...(hasActive &&
        hasHover && {
          fill: '#002855',
        }),
    },
    line: {
      ...line,
      x1: 1,
      x2: 35,
      stroke: '#002855',
    },
    height: hasActive ? 35.5 : 32,
    width: 32,
  }
}

const highSchoolProps = (hasHover, hasActive, isMobile) => {
  const { line, path1, g1, ...rest } = selaProps(hasHover, hasActive, isMobile)
  return {
    ...rest,
    path1: {
      ...path1,
      stroke: '#C62D70',
      ...(hasHover || hasActive
        ? {
            fill: '#C62D70',
          }
        : {}),
      ...(hasActive &&
        hasHover && {
          fill: '##OBSIDIAN##',
        }),
    },
    g1: {
      ...g1,
      fill: '#C62D70',
      ...(hasHover || hasActive
        ? {
            fill: '#FFFFFF',
          }
        : {}),
      ...(hasActive &&
        hasHover && {
          fill: '#C62D70',
        }),
    },
    line: {
      ...line,
      x1: 1,
      x2: 35,
      stroke: '#C62D70',
    },
    height: hasActive ? 35.5 : 32,
    width: 32,
  }
}

export const IconVariants = ({ variant, hasHover, hasActive, isMobile }) =>
  ({
    sela: {
      ...selaProps(hasHover, hasActive, isMobile),
      variant,
    },
    default: {
      ...defaultProps(hasHover, hasActive, isMobile),
      variant,
    },
    learn: {
      ...learnProps(hasHover, hasActive, isMobile),
      variant,
    },
    highSchool: {
      ...highSchoolProps(hasHover, hasActive, isMobile),
      variant,
    },
  }[variant])
