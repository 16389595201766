import {
  actionChannel,
  all,
  call,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects'

import { sendTerminateBeacon } from '../../../utils/lmsHelper'
import { snakeToCamelCase } from '../../../utils/stringHelper'
import { getApiClient } from '../../APIConnector'
import { LMS_TERMINATORS } from '../constants'
import {
  cmiCompletionStatusSelector,
  cmiProgressMeasureSelector,
  cmiSuspendDataSelector,
  playerSlidesSelector,
} from '../selectors'

const SET_VALUE_QUEUE = 'API_ASYNC_SET_VALUE'

// This saga uses an actionChannel to queue all api SetValue calls.
// This ensures API calls don't stomp on each other and potentially overwrite newer values
// with older values.
// All sagas that need to call api.SetValue should use and share the actionChannel if the
// UI side-effect should be non-optimistic.
// Once the api call completes, a return action (if specified) is called.
// Specify a UI update redux action or a function as the return action in order to ensure UI
// updates only after the api response is received.
function* apiSetValueAsyncQueue() {
  const apiSetValueRequestChannel = yield actionChannel(SET_VALUE_QUEUE)
  const api = yield getApiClient()

  while (true) {
    const { key, returnAction, value } = yield take(apiSetValueRequestChannel)
    const cmiKey = `cmi.${key}`
    const reduxKey = [snakeToCamelCase(key)]

    //Blocking effect creator.  Generator paused until the api responds.
    yield call([api, 'SetValue'], cmiKey, value)
    //Call the return action, if specified, once a response is received from the API call
    //Non-blocking
    if (returnAction) {
      if (typeof returnAction === 'string') {
        yield put({
          type: returnAction,
          [reduxKey]: value,
        })
      } else if (typeof returnAction === 'function') {
        returnAction()
      }
    }
  }
}

function* updateSessionTime({
  sessionTime,
  lmsTerminator = LMS_TERMINATORS.NONE,
}) {
  yield put({ type: 'UPDATE_CMI_SESSION_TIME', sessionTime })

  const putAction = {
    key: 'session_time',
    type: SET_VALUE_QUEUE,
    value: sessionTime,
  }
  const terminateViaApiConnector =
    lmsTerminator === LMS_TERMINATORS.API_CONNECTOR
  const terminateViaBeacon = lmsTerminator === LMS_TERMINATORS.BEACON

  if (terminateViaApiConnector) {
    putAction.returnAction = 'UPDATE_CMI_LMS_SESSION_TIME_SUCCESSFUL'
  }

  yield put(putAction)

  if (terminateViaBeacon) {
    yield sendTerminateBeacon()
  }
}

function* onUpdateSessionTimeSuccessful() {
  const api = yield getApiClient()

  yield api.Terminate()
  yield put({ type: 'TOGGLE_SESSION', isActive: false })
}

function* updateSlides({ slidePosition }) {
  const progressMeasure = yield select(cmiProgressMeasureSelector)
  const completionStatus = yield select(cmiCompletionStatusSelector)

  yield put({
    key: 'location',
    type: SET_VALUE_QUEUE,
    value: slidePosition,
  })
  yield put({
    key: 'progress_measure',
    type: SET_VALUE_QUEUE,
    value: progressMeasure,
  })
  yield put({
    key: 'completion_status',
    type: SET_VALUE_QUEUE,
    value: completionStatus,
  })
  yield put({ type: 'UPDATE_LMS_SUSPEND_DATA' })
}

function* updateCompletionStatus({ completionStatus, isLessonDone }) {
  yield put({
    key: 'completion_status',
    returnAction: 'UPDATE_CMI_COMPLETION_STATUS', //Update completion status on the redux cmi object in the callback
    type: SET_VALUE_QUEUE,
    value: completionStatus,
  })
}

// Copy the save data object, cmi.suspend_data, to the LMS.
function* updateLMSSuspendData(action) {
  const { type, saveToLms } = action || {}
  const shouldSaveToLms =
    (saveToLms && type === 'UPDATE_CMI_INTERACTIVES') ||
    type === 'UPDATE_LMS_SUSPEND_DATA'

  if (shouldSaveToLms) {
    const suspendData = yield select(cmiSuspendDataSelector)
    const formattedSuspendData = encodeURIComponent(JSON.stringify(suspendData))

    yield put({
      key: 'suspend_data',
      type: SET_VALUE_QUEUE,
      value: formattedSuspendData,
    })
  }
}

// Dialog cards are a special case here until per-LayoutElement completion states are implemented.
// See LEARN-825 about future efforts to enable per-LayoutElement completion states.
function* updateDialogCard({ interactives }) {
  const slides = yield select(playerSlidesSelector) //get the latest.
  yield put({
    type: 'UPDATE_CMI_SLIDES',
    slides,
  }),
    yield put({
      type: 'UPDATE_CMI_INTERACTIVES',
      interactives,
      saveToLms: true,
    })
}

export default function* cmiSaga() {
  yield all([
    takeEvery('UPDATE_CMI_DIALOG_CARD', updateDialogCard),
    takeEvery('UPDATE_LMS_COMPLETION_STATUS', updateCompletionStatus),
    takeEvery('UPDATE_CMI_LOCATION', updateSlides),
    takeEvery('UPDATE_CMI_LMS_SESSION_TIME', updateSessionTime),
    takeEvery(
      'UPDATE_CMI_LMS_SESSION_TIME_SUCCESSFUL',
      onUpdateSessionTimeSuccessful,
    ),
    takeEvery(
      ['UPDATE_LMS_SUSPEND_DATA', 'UPDATE_CMI_INTERACTIVES'],
      updateLMSSuspendData,
    ),
    apiSetValueAsyncQueue(),
  ])
}
