import styled from 'styled-components'

import { colors } from '../../../styles/styleGuide'

export const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 1.75rem;
  margin-top: 3px;
  background: white;
  border: none;
  border-radius: 2px;
  box-shadow: 0 0 22px 0 #0003;
  color: ${colors.mediumGrey};
  height: 2.063rem;
  width: 5.8rem;
  cursor: pointer;
  :active {
    background-color: none;
  }
  font-size: 16px;
  :hover {
    font-weight: 600;
  }
`
export const ItemText = styled.div`
  margin-left: 0.625rem;
`
