/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'
import PropTypes from 'prop-types'

const IconCaretSelect = ({
  color = 'currentColor',
  open = false,
  ...props
}) => {
  let polylinePoints = '2 2 9.5 9 17 2'

  if (open) {
    polylinePoints = '2 9.5 9 2 16 9.5'
  }

  return (
    <svg height="11px" viewBox="0 0 19 11" width="19px" {...props}>
      <title>ss_platform_dropdownarrow</title>
      <g
        fill="none"
        fillRule="evenodd"
        id="ss_platform_dropdownarrow"
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <polyline
          id="Path-3"
          points={polylinePoints}
          stroke={color}
          strokeWidth="2.5"
        />
      </g>
    </svg>
  )
}

IconCaretSelect.propTypes = {
  color: PropTypes.string,
  open: PropTypes.bool,
}

export default IconCaretSelect
