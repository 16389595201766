/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'
import PropTypes from 'prop-types'

const LifeSaverIcon = ({ fill, title, ...rest }) => (
  <svg {...rest}>
    {title && <title>{title}</title>}
    <g
      fill="none"
      fillRule="evenodd"
      id="AP_icon_districtsupports"
      stroke="none"
      strokeWidth="1"
    >
      <path
        d="M11.3970588,7.5 C11.3970588,6.91610294 11.2670588,6.36213235 11.0359559,5.86441176 C11.8547059,5.59139706 12.6663971,5.32066176 13.4938235,5.04470588 C14.1330147,6.68536765 14.1332353,8.31058824 13.4941912,9.95441176 C12.6680882,9.67911765 11.85625,9.40852941 11.0361029,9.13522059 C11.2671324,8.63764706 11.3970588,8.08382353 11.3970588,7.5 M3.60294118,7.5 C3.60294118,8.08367647 3.73279412,8.63742647 3.96382353,9.135 C3.14551471,9.40772059 2.33419118,9.67823529 1.50580882,9.95441176 C0.866102941,8.31389706 0.8675,6.68867647 1.50536765,5.04448529 C2.33617647,5.32161765 3.14573529,5.59161765 3.96404412,5.86455882 C3.73286765,6.36220588 3.60294118,6.91610294 3.60294118,7.5 M9.13522059,3.96389706 C8.63757353,3.73286765 8.08375,3.60294118 7.5,3.60294118 C6.91617647,3.60294118 6.36235294,3.73286765 5.86470588,3.96397059 C5.59308824,3.14948529 5.32227941,2.33735294 5.04507353,1.50588235 C6.68294118,0.866029412 8.30786765,0.868014706 9.95514706,1.50433824 C9.67830882,2.33485294 9.40816176,3.145 9.13522059,3.96389706 M10.3676471,7.5 C10.3676471,9.08125 9.08125,10.3676471 7.5,10.3676471 C5.91875,10.3676471 4.63235294,9.08125 4.63235294,7.5 C4.63235294,5.91875 5.91875,4.63235294 7.5,4.63235294 C9.08125,4.63235294 10.3676471,5.91875 10.3676471,7.5 M9.95463235,13.4931618 C8.31786765,14.13375 6.69286765,14.1322794 5.04470588,13.4951471 C5.32139706,12.665 5.59132353,11.8548529 5.86426471,11.0358824 C6.36205882,11.2670588 6.91602941,11.3970588 7.5,11.3970588 C8.08367647,11.3970588 8.63735294,11.2672059 9.13492647,11.03625 C9.40647059,11.8500735 9.67727941,12.6619118 9.95463235,13.4931618 M7.5,0 C3.36448529,0 0,3.36448529 0,7.5 C0,11.6355147 3.36448529,15 7.5,15 C11.6355147,15 15,11.6355147 15,7.5 C15,3.36448529 11.6355147,0 7.5,0"
        fill={fill}
        id="Fill-1"
      />
    </g>
  </svg>
)
LifeSaverIcon.defaultProps = {
  title: null,
  height: '15px',
  width: '15px',
  fill: 'currentColor',
  viewBox: '0 0 15 15',
}
LifeSaverIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  title: PropTypes.string,
  viewBox: PropTypes.string,
  width: PropTypes.string,
}

export default LifeSaverIcon
