import React from 'react'

import PropTypes from 'prop-types'
import Markdown from 'react-markdown'

import { GenericCopy, HelperCopy } from '../../text'

const PARAGRAPH = 'paragraph'
const DISALLOWED_QUESTION_MARKDOWN_TYPES = [PARAGRAPH]

const MultiChoiceQuestion = ({ children }) => {
  // Split on newline, remove junk, and limit to 2
  const questionTextElements = children.split('\n').filter(Boolean).slice(0, 2)

  // Render Multiple Elements
  return questionTextElements.map((text, index) => {
    const markdown = (
      <Markdown
        disallowedTypes={DISALLOWED_QUESTION_MARKDOWN_TYPES}
        unwrapDisallowed
      >
        {text}
      </Markdown>
    )

    return index === 0 ? (
      <GenericCopy key={text}>{markdown}</GenericCopy>
    ) : (
      <HelperCopy key={text}>{markdown}</HelperCopy>
    )
  })
}

MultiChoiceQuestion.propTypes = {
  children: PropTypes.string,
}

MultiChoiceQuestion.defaultProps = {
  children: '',
}

export default MultiChoiceQuestion
