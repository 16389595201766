/* istanbul ignore file */
// we don't unit test SVGs
import React from 'react'

const SmallEditIcon = () => (
  <svg height="11" viewBox="0 0 11 11" width="11">
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="currentColor" fillRule="nonzero">
        <path d="M10.72 1.472L9.529.279a.953.953 0 00-1.348 0l-.347.348 2.54 2.54.348-.347a.954.954 0 000-1.348z" />
        <path d="M1.20022133 7.48013929L0 11 3.52008403 9.79990297z" />
        <path d="M4.07975332 9.46080847L1.53915678 6.92015456 7.38338043 1.07579897 9.92397697 3.61645287z" />
      </g>
    </g>
  </svg>
)

export default SmallEditIcon
