import styled from 'styled-components'
import { themeGet } from 'styled-system'
import HeaderOne from '../HeaderOne'
import HeaderTwo from '../HeaderTwo'
import BodyCopy from '../BodyCopy'
import BaseContainer from '../BaseContainer'
import ContainedButton from '../ContainedButton'

// TODO: [LEARN-14103] Incorporate themeGet to Unable to Authenticate
// themeGet currently does not work for the auth error screen because the theme is being set at component where Auth0ErrorHander does not access to it
export const StyledContainer = styled(BaseContainer)`
  align-items: center;
  background-color: #eef0f2;
  display: flex;
  justify-content: center;
  margin: 0.875rem 0 1.125rem;
  text-align: center;
  width: 100%;

  @media only screen and (max-width: 768px) {
    .yield-icon {
      height: 61px;
      width: 70px;
    }
  }

  @media only screen and (max-width: 600px) {
    padding: 1.5rem 0 1.75rem;
  }
`

export const ContentWrapper = styled.div`
  ${themeGet('breakpoints.tablet')} {
    padding: 0;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3.5rem;
  margin-bottom: 3rem;

  button {
    &:not(:last-of-type) {
      margin-right: 1rem;
    }
  }

  @media only screen and (max-width: 768px) {
    margin-top: 1.875rem;
    margin-bottom: 3.625rem;
  }

  @media only screen and (max-width: 600px) {
    margin-top: 2.125rem;
  }
`

export const Header = styled(HeaderOne)`
  color: #002855;
  font-family: SharpSlab-Bold;
  font-size: 2.625rem;
  line-height: 3.4375rem;
  margin: 2.875rem 0 0.75rem;
  text-align: center;

  @media only screen and (max-width: 768px) {
    font-size: 1.625rem;
    margin-top: 1.25rem;
    margin-bottom: 0;
  }

  @media only screen and (max-width: 600px) {
    letter: 0.0406rem;
    line-height: 2.125rem;
    margin-top: 1.875rem;
    margin-bottom: 0.875rem;
  }
`

export const SubHeader = styled(HeaderTwo)`
  color: #002855;
  font-family: SharpSlab-Medium;
  font-size: 2.625rem;
  margin: 0;
  text-align: center;

  @media only screen and (max-width: 768px) {
    font-size: 1.625rem;
    padding: 0 2rem 0;
  }

  @media only screen and (max-width: 600px) {
    letter: 0.0406rem;
    line-height: 2.125rem;
  }
`

export const StyledBodyCopy = styled(BodyCopy)`
  color: #555555;
  font-family: ${themeGet('fontFamily')};
  font-size: 1rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem 0;

  ${themeGet('breakpoints.tablet')} {
    padding: 0.5rem 0;
  }
`

export const LogOutButton = styled(ContainedButton)`
  background-color: #3c6e90;
  border-color: #3c6e90;
  border-radius: 0.3125rem;
  color: #ffffff;
  padding: 0.75rem 4.875rem 0.5rem 4.8125rem;
  text-transform: capitalize;

  &:hover {
    background-color: #002855;
    border-color: #002855;
  }

  &:focus {
    background-color: #002855;
    border-color: #002855;
  }
`
