import React from 'react'

function GoogleDocIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="22"
      viewBox="0 0 18 22"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill="currentColor"
          d="M0 2.237v17.526A2.237 2.237 0 002.237 22h13.42a2.237 2.237 0 002.238-2.237V5.206L11.766 0h-9.53A2.237 2.237 0 000 2.237z"
        />
        <g fill="#FFF" fillRule="nonzero" transform="translate(4.797 7.8)">
          <path d="M4.502 4.283v1.313h1.905c-.05 1.327-.656 2.133-1.992 2.133-1.297 0-2.365-.923-2.365-3.123v-.013c0-2.181 1.116-3.021 2.383-3.021 1.096 0 1.926.54 2.013 1.551l.003.05h1.759l-.004-.071C8.098 1.206 6.557 0 4.4 0 1.955 0 0 1.49 0 4.593v.013c0 3.207 1.868 4.695 4.02 4.695 1.288 0 2.19-.4 2.739-1.2v1.1h1.45V4.282H4.501z" />
        </g>
      </g>
    </svg>
  )
}

export default GoogleDocIcon
