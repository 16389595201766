import styled from 'styled-components'

import { StyledButton } from '../../Button/style'
import { FeedbackCopy } from '../../text'

export const EvaluationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 2.5rem;
  width: 100%;
`

export const EvaluationText = styled(FeedbackCopy)`
  display: ${({ isErrorVisible }) => !isErrorVisible && 'none'};
  margin-right: 1rem;
  align-items: flex-start;
  flex: 1 1 0%;
`

export const EvaluationResetButton = styled(StyledButton)`
  margin-right: 10px;
  max-height: 2.25rem;
`

export const EvaluationCheckButton = styled(StyledButton)`
  max-height: 2.25rem;
`
