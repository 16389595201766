import React from 'react'
import PropTypes from 'prop-types'
import { List, ListItem } from './component.styles'
import Card from '../Card'

const CardList = ({ cards }) => {
  return (
    <List>
      {cards.map((card, index) => (
        <ListItem key={index}>{card}</ListItem>
      ))}
    </List>
  )
}

CardList.propTypes = {
  cards: PropTypes.arrayOf(Card),
}

export default CardList
