/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

const HouseIcon = () => (
  <svg
    height="16"
    viewBox="0 0 17 16"
    width="17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="currentColor" transform="translate(-96 -39)">
        <g transform="translate(96 39)">
          <path d="M8.92 3.699l5.729 4.3V15.7c0 .166-.147.3-.328.3H2.976c-.18 0-.327-.134-.327-.3V8L8.5 3.699a.35.35 0 01.42 0zM10.376 8c-.429 0-.832.169-1.135.476l-.558.817-.559-.817A1.586 1.586 0 006.99 8c-.429 0-.832.169-1.135.476a1.641 1.641 0 000 2.3l2.615 3.127a.3.3 0 00.427 0l2.615-3.127a1.641 1.641 0 000-2.3A1.586 1.586 0 0010.377 8z" />
          <path d="M14.033 4.434V.295c0-.165-.141-.3-.314-.3l-1.25.01a.307.307 0 00-.314.3V2.85L8.875.172a.325.325 0 00-.403 0L.762 6.346a.29.29 0 00-.04.422l.677.867c.11.127.309.144.442.038l6.63-4.998a.325.325 0 01.404 0l6.581 4.997c.133.106.332.09.443-.038l.677-.867a.29.29 0 00-.039-.421l-2.504-1.912z" />
        </g>
      </g>
    </g>
  </svg>
)

export default HouseIcon
