import { hasAllKeys } from '../../../../utils/objectHelper'

import {
  KEYS_DIALOG_CARDS,
  KEYS_DRAG_LABEL,
  KEYS_DRAG_QUESTION,
  KEYS_DRAG_WORD,
  KEYS_IMAGE_HOTSPOTS,
  KEYS_IMAGE_JUXTAPOSITION,
  KEYS_MULTI_CHOICE,
} from './constants'

// Version 1 interactive save state data does not have interactiveType or version fields, so we must
// infer the type of interactive by validating its fields. For now we avoid collision using v1 validators,
// but we should improve this in subsequent versions.
// Opened ticket #LEARN-7377

// Note: Validate interactives only on the keys that are produced from the most minimal interaction with them,
// since some keys are written later (eg. after user hits Submit) and are not guaranteed to be there.

const isValidSuspendData = suspendData => {
  if (!suspendData) {
    return false
  }

  if (typeof suspendData !== 'object') {
    return false
  }

  return true
}

export const isInteractiveDialogCards = interactiveSuspendData => {
  const isValid = isValidSuspendData(interactiveSuspendData)

  if (!isValid) {
    return false
  }

  const isDialogCards = hasAllKeys(interactiveSuspendData, KEYS_DIALOG_CARDS)

  return isDialogCards
}

export const isInteractiveDragLabel = interactiveSuspendData => {
  const isValid = isValidSuspendData(interactiveSuspendData)

  if (!isValid) {
    return false
  }

  const isDragLabel = hasAllKeys(interactiveSuspendData, KEYS_DRAG_LABEL)

  return isDragLabel
}

export const isInteractiveDragQuestion = interactiveSuspendData => {
  const isValid = isValidSuspendData(interactiveSuspendData)

  if (!isValid) {
    return false
  }

  const isDragQuestion = hasAllKeys(interactiveSuspendData, KEYS_DRAG_QUESTION)

  return isDragQuestion
}

export const isInteractiveDragWord = interactiveSuspendData => {
  const isValid = isValidSuspendData(interactiveSuspendData)

  if (!isValid) {
    return false
  }

  const isDragWord = hasAllKeys(interactiveSuspendData, KEYS_DRAG_WORD)

  return isDragWord
}

export const isInteractiveImageHotspots = interactiveSuspendData => {
  const isValid = isValidSuspendData(interactiveSuspendData)

  if (!isValid) {
    return false
  }

  const isImageHotspots = hasAllKeys(
    interactiveSuspendData,
    KEYS_IMAGE_HOTSPOTS,
  )

  return isImageHotspots
}

export const isInteractiveImageJuxtaposition = interactiveSuspendData => {
  const isValid = isValidSuspendData(interactiveSuspendData)

  if (!isValid) {
    return false
  }

  const isImageJuxtaposition = hasAllKeys(
    interactiveSuspendData,
    KEYS_IMAGE_JUXTAPOSITION,
  )

  return isImageJuxtaposition
}

export const isInteractiveMultiChoice = interactiveSuspendData => {
  const isValid = isValidSuspendData(interactiveSuspendData)

  if (!isValid) {
    return false
  }

  const isMultiChoice = hasAllKeys(interactiveSuspendData, KEYS_MULTI_CHOICE)

  return isMultiChoice
}
