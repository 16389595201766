import EqualColumnsNoHero from '../layouts/page/EqualColumnsNoHero'
import ImageWithTitle from '../layouts/page/ImageWithTitle'
import Default from '../layouts/page/LayoutDefault'
import OneThirdTwoThirdColumnNoHero from '../layouts/page/OneThirdTwoThirdColumnNoHero'
import VideoWithTitle from '../layouts/page/VideoWithTitle'

export const DEFAULT_LAYOUT_DEPRECATED = 'default'
export const DRAG_LABEL_TO_IMAGE = 'dragLabelToImage'
export const DRAG_THE_WORD = 'dragTheWord'
export const IMAGE_WITH_TITLE = 'imageWithTitle'
export const ONE_THIRD_TWO_THIRD_COLUMN_NO_HERO = 'oneThirdTwoThirdColumnNoHero'
export const THREE_COLUMN_NO_HERO = 'threeColumnNoHero'
export const VIDEO_WITH_TITLE = 'videoWithTitle'

export const PAGE_LAYOUT_COMPONENTS = {
  default: {
    component: Default,
    showTitle: true,
  },
  dragLabelToImage: {
    component: EqualColumnsNoHero,
    showTitle: false,
  },
  dragTheWord: {
    component: EqualColumnsNoHero,
    showTitle: false,
  },
  imageWithTitle: {
    component: ImageWithTitle,
    showTitle: true,
  },
  oneThirdTwoThirdColumnNoHero: {
    component: OneThirdTwoThirdColumnNoHero,
    showTitle: true,
  },
  threeColumnNoHero: {
    component: EqualColumnsNoHero,
    showTitle: true,
  },
  videoWithTitle: {
    component: VideoWithTitle,
    showTitle: true,
  },
}
