import React from 'react'

import PropTypes from 'prop-types'

import { Img } from './style'

const DATA_TEST_ID = 'image-hotspots-image'

const ImageHotspotsImageComponent = props => {
  const { alt: accessibilityLabel, source } = props || {}
  const imageProps = {
    accessibilityLabel,
    'data-testid': DATA_TEST_ID,
    source,
  }

  return <Img {...imageProps} />
}

ImageHotspotsImageComponent.propTypes = {
  alt: PropTypes.string,
  source: PropTypes.string,
}

ImageHotspotsImageComponent.defaultProps = {
  alt: "' '",
}

export default ImageHotspotsImageComponent
