import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="121"
      height="115"
      viewBox="0 0 121 115"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(5 5)">
          <g stroke="#3C6E90" strokeWidth="1.5">
            <path d="M101.215 52.928V67.63c0 .862-.35 1.643-.914 2.208a3.112 3.112 0 01-2.208.914H12.907c-.862 0-1.643-.35-2.208-.914a3.112 3.112 0 01-.914-2.208V52.928h91.43z" />
            <rect
              width="4.953"
              height="33.285"
              x="16.238"
              y="70.965"
              rx="1.291"
            />
            <rect
              width="4.953"
              height="33.285"
              x="89.808"
              y="70.965"
              rx="1.291"
            />
            <rect
              width="4.953"
              height="21.69"
              x="78.192"
              y="70.965"
              rx="1.291"
            />
            <rect
              width="4.953"
              height="21.69"
              x="27.855"
              y="70.965"
              rx="1.291"
            />
            <path d="M36.89 22.652H41.843V33.391000000000005H36.89z" />
            <path d="M69.157 22.652H74.11V33.391000000000005H69.157z" />
            <path d="M1.936 46.38v5.151c0 .713.578 1.291 1.29 1.291h104.547a1.29 1.29 0 001.291-1.29V46.38h0" />
            <path
              fill="#C0DFDE"
              d="M11.867 33.497h87.51l9.678 10.728a1.29 1.29 0 01-.958 2.155H65.543h0H2.944a1.29 1.29 0 01-.95-2.165l9.873-10.718h0z"
            />
            <rect
              width="50.128"
              height="21.69"
              x="30.436"
              y="0.75"
              fill="#B1C5D3"
              rx="3.872"
            />
          </g>
          <path
            fill="#E77564"
            fillRule="nonzero"
            d="M41.941 66.133c3.35-8.302-4.345-8.981-3.717-4.31a.117.117 0 01-.038.114.117.117 0 01-.12.019c-2.942-1.042-4.447 2.024-2.18 3.817 1.389 1.189 4.913.776 5.794.58a.35.35 0 00.261-.22z"
            transform="rotate(78 38.79 62.758)"
          />
          <path
            fillRule="nonzero"
            stroke="#E77564"
            strokeWidth="1.5"
            d="M91.699 65.427c8.26-6.461.852-11.657-1.247-6.55a.137.137 0 01-.106.093.137.137 0 01-.131-.051c-2.376-2.78-5.698-.552-4.45 2.595.714 2.02 4.531 3.665 5.54 3.982.135.047.283.02.394-.07z"
          />
          <path
            stroke="#3C6E90"
            strokeWidth="1.5"
            d="M21.997 64.932l-.942 1.492a1 1 0 01-1.784-.187l-.61-1.655a1 1 0 00-.692-.623l-1.71-.435a1 1 0 01-.373-1.754l1.385-1.093a1 1 0 00.378-.85l-.114-1.761a1 1 0 011.553-.897l1.467.98a1 1 0 00.926.097l1.639-.653a1 1 0 011.333 1.2l-.479 1.698a1 1 0 00.194.91l1.128 1.358a1 1 0 01-.73 1.638l-1.763.07a1 1 0 00-.806.465z"
          />
          <circle cx="31.5" cy="56.913" r="1.5" stroke="#3C6E90" />
          <path
            fill="#3C6E90"
            d="M81.491 67.114l-.416.219a.492.492 0 01-.713-.518l.08-.464a1 1 0 00-.289-.885l-.336-.328a.492.492 0 01.272-.839l.466-.068a1 1 0 00.753-.547l.208-.421a.492.492 0 01.881 0l.209.421a1 1 0 00.752.547l.466.068a.492.492 0 01.272.839l-.336.328a1 1 0 00-.288.885l.08.464a.492.492 0 01-.714.518l-.416-.22a1 1 0 00-.93 0z"
          />
        </g>
      </g>
    </svg>
  )
}

export default Icon
