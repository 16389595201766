import React, { Component } from 'react'

import PropTypes from 'prop-types'

import MultiChoiceOption from '../MultiChoiceOption'

import { OptionsContainer } from './style'

class MultiChoiceOptions extends Component {
  options = () => {
    const {
      groupName,
      isRadio,
      isSubmitted,
      isUnevaluated,
      onOptionBlur,
      onOptionChange,
      onOptionFocus,
      options,
    } = this.props
    const optionProps = {
      disabled: isSubmitted,
      groupName,
      isRadio,
      isSubmitted,
      isUnevaluated,
      onBlur: onOptionBlur,
      onChange: onOptionChange,
      onFocus: onOptionFocus,
      options,
    }

    return options.map((option, idx) => {
      const { id } = option
      const isLast = idx === options.length - 1

      return (
        <MultiChoiceOption
          key={id}
          isLast={isLast}
          {...{ ...optionProps, ...option }}
        />
      )
    })
  }

  render = () => {
    const optionComponents = this.options()

    return <OptionsContainer>{optionComponents}</OptionsContainer>
  }
}

MultiChoiceOptions.propTypes = {
  groupName: PropTypes.string,
  isRadio: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  isUnevaluated: PropTypes.bool,
  onOptionBlur: PropTypes.func,
  onOptionChange: PropTypes.func,
  onOptionFocus: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
}

MultiChoiceOptions.defaultProps = {
  onOptionBlur() {},
  onOptionChange() {},
  onOptionFocus() {},
  options: [],
}

export default MultiChoiceOptions
