import styled from 'styled-components/native'

import { FULL_HEIGHT, FULL_WIDTH } from '../constants'
import {
  ErrorMessageContainer,
  ErrorMessageTextContainer,
  ImageContainer,
} from '../ImageJuxtFrame/style'

export const RightImageContainer = styled(ImageContainer).attrs({
  width: FULL_WIDTH,
})`
  width: ${props => props.width};
`

export const RightImage = styled.Image.attrs(
  ({
    theme: {
      colorVars: { imageJuxtaposition: colors },
    },
  }) => ({
    backgroundColor: colors.frame.background,
    height: FULL_HEIGHT,
  }),
)`
  background-color: ${props => props.backgroundColor};
  height: ${props => props.height};
`

export const RightErrorMessageContainer = styled(ErrorMessageContainer)`
  justify-content: flex-end;
  overflow: hidden;
  position: absolute;
  right: 0;
`

export const RightErrorMessageTextContainer = styled(
  ErrorMessageTextContainer,
).attrs(({ width }) => ({
  width,
}))`
  width: ${props => props.width}px;
`

// const imageContainerProps = {
//   accessibilityLabel: ACCESSIBILITY_LABEL,
// }

// const ACCESSIBILITY_LABEL = 'Image juxtaposition right image container'
