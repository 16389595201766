import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { StyledTelescopeIcon } from '../HighSchoolPreview/component.styles'
import { BOLD_TEXT } from '../HighSchoolPreview/constants'

import { Container, TelescopeIconWrapper, Title } from './component.styles'

const HighSchoolPreviewTitleModal = ({
  currentSectionIndex,
  currentSlideIndex,
  isHighSchoolPreviewMode,
}) => {
  if (
    currentSectionIndex === 0 &&
    currentSlideIndex === 0 &&
    isHighSchoolPreviewMode
  ) {
    return (
      <Container>
        <TelescopeIconWrapper>
          <StyledTelescopeIcon />
        </TelescopeIconWrapper>
        <Title>{BOLD_TEXT}</Title>
      </Container>
    )
  }

  return null
}

HighSchoolPreviewTitleModal.propTypes = {
  currentSectionIndex: PropTypes.number,
  currentSlideIndex: PropTypes.number,
  isHighSchoolPreviewMode: PropTypes.bool,
}

HighSchoolPreviewTitleModal.defaultProps = {
  isHighSchoolPreviewMode: false,
}

const mapStateToProps = ({
  player: { currentSlide, isHighSchoolPreviewMode },
}) => ({
  currentSectionIndex: currentSlide[0],
  currentSlideIndex: currentSlide[1],
  isHighSchoolPreviewMode,
})

export default connect(mapStateToProps, {})(HighSchoolPreviewTitleModal)
