import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Text } from 'grommet'

export const CourseLabelText = styled(Text).attrs({
  'data-testid': 'grade-logo',
})`
  display: flex;
  align-items: center;
  color: ${themeGet('colors.themeSecondary')};
  font-family: ${themeGet('fontFamilyHeadingExtraBold')};
  font-size: 2.875rem;
  font-weight: 800;
  letter-spacing: 0.075625rem;
  line-height: 1;
`

CourseLabelText.displayName = 'CourseLabel'
