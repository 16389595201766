import React, { Component } from 'react'

import PropTypes from 'prop-types'

import MultiChoiceButton from './MultiChoiceButton'
import MultiChoiceEvaluationText from './MultiChoiceEvaluationText'
import MultiChoiceImage from './MultiChoiceImage'
import MultiChoiceOptions from './MultiChoiceOptions'
import MultiChoiceQuestion from './MultiChoiceQuestion'
import { BottomContainer, InteractiveContainer, TextContainer } from './style'

class MultiChoiceComponent extends Component {
  renderProps() {
    const {
      groupName,
      id,
      isAnswered,
      isRadio,
      isSubmitted,
      isUnevaluated,
      media,
      onOptionBlur,
      onOptionChange,
      onOptionFocus,
      onReset,
      onSubmit,
      options,
      overallFeedback: feedbackTiers,
      question,
      score,
      totalNumOfCorrectAnswers,
      UI: { checkAnswerButton: submitText, resetAnswerButton: resetText },
    } = this.props
    const optionsProps = {
      groupName,
      isRadio,
      isSubmitted,
      isUnevaluated,
      onOptionBlur,
      onOptionChange,
      onOptionFocus,
      options,
    }
    const disabled = !isAnswered
    const buttonProps = {
      disabled,
      isSubmitted,
      onReset,
      onSubmit,
      resetText,
      submitText,
    }
    const evaluationTextProps = {
      feedbackTiers,
      id,
      score,
      totalNumOfCorrectAnswers,
    }

    return {
      buttonProps,
      evaluationTextProps,
      media,
      optionsProps,
      question,
    }
  }

  render() {
    const { buttonProps, evaluationTextProps, media, optionsProps, question } =
      this.renderProps()

    return (
      <InteractiveContainer>
        <MultiChoiceImage media={media} />
        <TextContainer>
          <MultiChoiceQuestion>{question}</MultiChoiceQuestion>
          <MultiChoiceOptions {...optionsProps} />
          <BottomContainer>
            <MultiChoiceEvaluationText {...evaluationTextProps} />
            <MultiChoiceButton {...buttonProps} />
          </BottomContainer>
        </TextContainer>
      </InteractiveContainer>
    )
  }
}

MultiChoiceComponent.propTypes = {
  groupName: PropTypes.string,
  id: PropTypes.string,
  isAnswered: PropTypes.bool,
  isRadio: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  isUnevaluated: PropTypes.bool,
  media: PropTypes.object.isRequired,
  onOptionBlur: PropTypes.func,
  onOptionChange: PropTypes.func,
  onOptionFocus: PropTypes.func,
  onReset: PropTypes.func,
  onSubmit: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  overallFeedback: PropTypes.array.isRequired,
  question: PropTypes.string.isRequired,
  score: PropTypes.number,
  totalNumOfCorrectAnswers: PropTypes.number.isRequired,
  UI: PropTypes.object.isRequired,
}

MultiChoiceComponent.defaultProps = {
  onOptionBlur() {},
  onOptionChange() {},
  onOptionFocus() {},
  onSubmit() {},
  options: [],
}

export default MultiChoiceComponent
