/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

const Icon = () => (
  <svg
    height="19px"
    version="1.1"
    viewBox="0 0 18 19"
    width="18px"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        d="M0.862619808,0.6 L11.3865815,0.6 C11.8753994,0.6 12.2492013,0.973801917 12.2492013,1.46261981 L12.2492013,11.9865815 C12.2492013,12.4753994 11.8753994,12.8492013 11.3865815,12.8492013 L0.862619808,12.8492013 C0.373801917,12.8492013 0,12.4753994 0,11.9865815 L0,1.46261981 C0,1.00255591 0.402555911,0.6 0.862619808,0.6 Z"
        fill="#3C6E90"
        fillRule="nonzero"
        id="Path"
      />
      <path
        d="M17.1373802,6.35079872 L13.399361,6.35079872 L13.399361,8.07603834 L16.2747604,8.07603834 L16.2747604,16.8747604 L7.47603834,16.8747604 L7.47603834,13.999361 L5.75079872,13.999361 L5.75079872,17.7373802 C5.75079872,18.2261981 6.12460064,18.6 6.61341853,18.6 L17.1373802,18.6 C17.6261981,18.6 18,18.2261981 18,17.7373802 L18,7.21341853 C18,6.72460064 17.5974441,6.35079872 17.1373802,6.35079872 Z"
        fill="#3C6E90"
        fillRule="nonzero"
        id="Path"
      />
    </g>
  </svg>
)

export default Icon
