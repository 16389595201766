export const cmiCompletionStatusSelector = state =>
  state?.cmi?.completion_status
export const cmiProgressMeasureSelector = state => state?.cmi?.progress_measure
export const cmiLaunchDataSelector = state => state?.cmi?.launch_data
export const cmiSuspendDataSelector = state => state?.cmi?.suspend_data

export const playerCurrentSlideSelector = ({ player }) => {
  const [sectionIndex, slideIndex] = player.currentSlide || {}
  const { sections } = player || []
  const { fields = [] } = sections[sectionIndex] || []
  const { slides = [] } = fields || []
  return { slide: slides[slideIndex], slideIndex: slideIndex } || {}
}
export const playerInteractiveSelector = interactiveId => ({ player }) => {
  const { interactiveStates } = player || {}
  return interactiveStates[interactiveId]
}
export const playerIsFullScreenSelector = state => state?.player?.isFullScreen
export const playerUserIsIdle = state => state?.player?.isIdle

export const playerMultimediaStatus = state => state?.player?.multimediaStatus
export const playerIsSessionActiveSelector = state =>
  state?.player?.session?.isActive
export const playerPresentationDataSelector = ({ player }) => {
  const { presentationId, title } = player || {}
  return { presentationId, title }
}
export const playerScaleSelector = state => state?.player?.ratioWrapper?.scale
export const playerSessionStartDateSelector = state =>
  state?.player?.session?.startDate
export const playerSlidesSelector = state => state?.player?.completeStatus
