import React from 'react'

import PropTypes from 'prop-types'

import LayoutTitle from '../../LayoutTitle'
import { GenericCopy } from '../../text'

import { BodyText } from './style'

const DATA_TEST_ID = 'body-text'

const DragLabelHeaderComponent = ({ bodyText, title }) => {
  const titleComponent = title && <LayoutTitle title={title} />
  const bodyTextComponent = bodyText && (
    <BodyText>
      <GenericCopy data-testid={DATA_TEST_ID}>{bodyText}</GenericCopy>
    </BodyText>
  )

  return (
    <>
      {titleComponent}
      {bodyTextComponent}
    </>
  )
}

DragLabelHeaderComponent.propTypes = {
  bodyText: PropTypes.string,
  title: PropTypes.string,
}

export default DragLabelHeaderComponent
