import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
// extra utility to parse duration from ISO strings
import { parse } from 'tinyduration'

dayjs.extend(duration)

export const DURATION_ZERO_ISO = 'PT0H0M0S'

export const getIsoDate = () => dayjs().toISOString()

export const validIsoString = isoString => {
  return isoString && typeof isoString === 'string'
}

export const getZeroDuration = () => DURATION_ZERO_ISO

// Given a start date iso, return an iso of the duration between then and now.
export const getDurationSinceIso = startIso => {
  if (!validIsoString(startIso)) {
    return DURATION_ZERO_ISO
  }

  const startDayJsObj = dayjs(startIso)
  const endDayJsObj = dayjs()
  const durationDayJsObj = dayjs.duration(endDayJsObj.diff(startDayJsObj))
  const durationIso = durationDayJsObj.toISOString()

  return durationIso
}

export const addIsoDurations = (startDuration, additionalIso) => {
  if (!(validIsoString(startDuration) && validIsoString(additionalIso))) {
    return DURATION_ZERO_ISO
  }

  const startDayJsDuration = dayjs.duration(parse(startDuration))
  const addtlDayJsDuration = dayjs.duration(parse(additionalIso))
  const durationSum = startDayJsDuration.add(addtlDayJsDuration)
  return durationSum.toISOString()
}

export const subtractIsoDurations = (startDuration, subtractorIso) => {
  if (!(validIsoString(startDuration) && validIsoString(subtractorIso))) {
    return DURATION_ZERO_ISO
  }

  const startDayJsDuration = dayjs.duration(parse(startDuration))
  const minusDayJsDuration = dayjs.duration(parse(subtractorIso))
  const durationDifference = startDayJsDuration.subtract(minusDayJsDuration)
  return durationDifference.toISOString()
}
