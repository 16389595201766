/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

export default () => (
  <svg
    height="23px"
    version="1.1"
    viewBox="0 0 22 23"
    width="22px"
    x="0px"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
  >
    <g transform="translate(-69.000000, -213.000000)">
      <g transform="translate(40.000000, 40.000000)">
        <g transform="translate(27.000000, 138.547350)">
          <g
            id="starting-right-icon"
            transform="translate(2.000000, 34.452650)"
          >
            <path
              className="st0"
              d="M8.2,14.3c-0.3-0.4-0.6-0.8-0.8-1.2c-0.2-0.3-0.3-0.6-0.4-1c-0.1,0.2-0.2,0.3-0.4,0.5
						c-0.4,0.5-0.8,1.1-1.2,1.6l-0.1,0.1l-0.8-0.6c-0.7-0.5-1.4-1-2-1.5c-0.2-0.1-0.4-0.3-0.7-0.3c-0.1,0-0.1,0-0.2,0
						c-0.5,0-0.9,0.3-1.1,0.7c-0.3,0.6-0.1,1.1,0.4,1.5c1.4,1.1,2.8,2.1,4.2,3.2c0.2,0.2,0.4,0.2,0.5,0.2c0.1,0,0.3,0,0.5-0.4
						c0.3-0.4,0.6-0.8,0.9-1.2c0.3-0.5,0.7-0.9,1-1.4C8.3,14.4,8.3,14.4,8.2,14.3"
              fill="currentColor"
            />
            <g transform="translate(0.000000, 22.092681)">
              <defs>
                <filter
                  filterUnits="userSpaceOnUse"
                  height="0"
                  width="0"
                  x="0"
                  y="0"
                >
                  <feColorMatrix
                    type="matrix"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                  />
                </filter>
              </defs>
              <mask height="0" maskUnits="userSpaceOnUse" width="0" x="0" y="0">
                <g className="st1">
                  <polygon
                    className="st2"
                    points="0.4,0.2 0.6,0.2 0.6,0.4 0.4,0.4 								"
                  />
                </g>
              </mask>
              <path className="st3" d="M0,0" />
            </g>
            <path
              className="st0"
              d="M19.6,10.9l-0.4,0c-0.7-0.1-1.5-0.2-2.2-0.3c-0.1,0-0.3,0-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.4
						c0.1-0.6,0.1-1.2,0.2-1.8l0.1-0.7c0-0.4,0-0.7-0.2-0.9c-0.8-0.1-1.6-0.5-2.2-1.1c-0.4-0.4-0.7-1-0.9-1.5
						c-0.2-0.1-0.3-0.1-0.5-0.1c-0.9-0.1-1.9-0.2-2.8-0.4c-0.7-0.1-1.4-0.2-2-0.3c-0.3,0-1.1,0.4-1.3,0.6C6.7,4.1,6.5,4.4,6.3,4.6
						C5.9,5.1,5.6,5.5,5.2,6C5.1,6.1,5,6.3,5,6.4C4.9,6.8,5.1,7.1,5.4,7.3c0.4,0.3,1,0.2,1.3-0.2c0.5-0.6,0.8-1,1.2-1.5
						C8,5.5,8.2,5.3,8.5,5.3c0,0,0.1,0,0.1,0c0.7,0.1,1.5,0.2,2.3,0.3L12,5.7l-0.3,0.4c-0.1,0.1-0.1,0.2-0.2,0.3
						c-1,1.2-1.9,2.4-2.9,3.6c-0.2,0.3-0.4,0.5-0.4,0.8c0,0.1,0,0.2,0,0.2l0,0c0,0,0,0,0,0l0,0.3c0,0.4,0.1,0.7,0.3,1.1
						c0.1,0.2,0.3,0.5,0.5,0.8c0.1,0.1,1.3,1.7,1.6,2.3l0,0.1c0,0.1,0,0.3,0,0.4c0,0.2-0.1,0.4-0.2,0.5c-0.9,1.4-1.8,2.8-2.6,4.2
						c-0.1,0.2-0.2,0.3-0.2,0.5c-0.1,0.5,0.1,0.8,0.6,1.2c0.6,0.4,1.1,0.2,1.5-0.4c0.5-0.7,0.9-1.5,1.4-2.2c0.6-1,1.2-2,1.9-2.9
						c0.1-0.2,0.2-0.4,0.2-0.5c0.1-0.3,0-0.6-0.2-0.9c-0.3-0.5-0.6-1.1-0.8-1.7c-0.1-0.3-0.3-0.6-0.4-0.9c0-0.1-0.1-0.2-0.1-0.3
						l0-0.1l0.2-0.2c0.6-0.7,1.2-1.4,1.8-2.2l0.8-1l0,0.7c0,0.1,0,0.1,0,0.2c0,0.4-0.1,0.7-0.1,1.1l-0.1,0.7
						c-0.1,0.5,0.1,0.6,0.4,0.6c1.5,0.2,3,0.3,4.5,0.5c0.4,0,0.8-0.2,0.9-0.6c0-0.1,0.1-0.2,0.1-0.3c0-0.3,0-0.6-0.1-0.8
						C20,11,19.8,10.9,19.6,10.9"
              fill="currentColor"
            />
            <g transform="translate(14.430000, 0.252681)">
              <defs>
                <filter
                  filterUnits="userSpaceOnUse"
                  height="4.8"
                  width="4.8"
                  x="0.3"
                  y="0.1"
                >
                  <feColorMatrix
                    type="matrix"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                  />
                </filter>
              </defs>
              <mask
                height="4.8"
                maskUnits="userSpaceOnUse"
                width="4.8"
                x="0.3"
                y="0.1"
              >
                <g className="st4">
                  <polygon
                    className="st2"
                    points="0.3,0.1 5.1,0.1 5.1,4.9 0.3,4.9 								"
                  />
                </g>
              </mask>
              <path
                className="st5"
                d="M2.7,4.9L2.7,4.9c0.6,0,1.2-0.2,1.7-0.7c0.5-0.5,0.7-1.1,0.7-1.7c0-1.3-1.1-2.4-2.4-2.4
							c0,0,0,0,0,0C2,0.1,1.5,0.4,1,0.8c-0.5,0.5-0.7,1-0.7,1.7C0.3,3.8,1.4,4.9,2.7,4.9"
                fill="currentColor"
              />
            </g>
            <path
              className="st0"
              d="M20.5,7.4L20.5,7.4c-0.4-0.2-0.9,0-1.1,0.5l-0.7,1.8l1.1,0.1c0.2,0,0.3,0.1,0.5,0.1l0.2,0.1
						L21,8.6C21.2,8.1,21,7.6,20.5,7.4"
              fill="currentColor"
            />
            <path
              className="st0"
              d="M17.2,13.7l-0.1,0l-0.6,1.4c-0.2,0.4,0,0.9,0.5,1.1c0.1,0,0.2,0.1,0.3,0.1h0
						c0.3,0,0.7-0.2,0.8-0.5l0.8-1.8l-0.3,0C18.1,13.8,17.7,13.8,17.2,13.7"
              fill="currentColor"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
