import React, { useRef } from 'react'

import PropTypes from 'prop-types'

import ImageHotspotsImage from '../ImageHotspotsImage'
import HotspotsTargets from '../ImageHotspotsTargets'

import { Frame } from './style'

const ACCESSIBILITY_LABEL = 'Image hotspots frame'
const DATA_TEST_ID = 'image-hotspots-frame'

const ImageHotspotsFrameComponent = props => {
  const frame = useRef(0)

  const getRenderProps = () => {
    const {
      height,
      hotspots,
      id,
      imageAltText,
      imageSource,
      onClickTarget,
      targetsClicked,
      width,
    } = props || {}
    const frameProps = {
      accessibilityLabel: ACCESSIBILITY_LABEL,
      'data-testid': DATA_TEST_ID,
      height,
      ref: frame,
      width,
    }
    const imageProps = {
      alt: imageAltText,
      source: imageSource,
    }
    const targetsProps = {
      containerWidth: width,
      containerHeight: height,
      hotspots,
      id,
      onClickTarget,
      targetsClicked,
    }

    return {
      frameProps,
      imageProps,
      targetsProps,
    }
  }

  const { frameProps, imageProps, targetsProps } = { ...getRenderProps() }

  return (
    <Frame {...frameProps}>
      <ImageHotspotsImage {...imageProps} />
      <HotspotsTargets {...targetsProps} />
    </Frame>
  )
}

ImageHotspotsFrameComponent.propTypes = {
  height: PropTypes.number,
  hotspots: PropTypes.array,
  id: PropTypes.string,
  imageAltText: PropTypes.string,
  imageSource: PropTypes.string,
  onClickTarget: PropTypes.func,
  targetsClicked: PropTypes.object,
  width: PropTypes.number,
}

ImageHotspotsFrameComponent.defaultProps = {
  hotspots: [],
  onClickTarget() {},
}

export default ImageHotspotsFrameComponent
