import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { dateToMMDDYYYY } from '../../utils/stringHelpers'
import {
  DateInputContainer,
  DateInputStyled,
  FormFieldStyled,
} from './component.styles'
import { addOneDayToDate } from '../../utils/dateHelper'

const FormInputDate = ({
  autofocus,
  dataTestId,
  inputErrors,
  label,
  name,
  onChange,
  placeholder,
  value = '',
}) => {
  const [val, setVal] = useState('')
  const onChangeFn = e => {
    // Fixes the odd issue that causes a one day decrease when user select a date from the datepicker
    setVal(addOneDayToDate(e.target.value))

    onChange(e)
  }
  let transformedDate = dateToMMDDYYYY(value || val)
  return (
    <DateInputContainer>
      <FormFieldStyled
        dataTestId={dataTestId}
        inputErrors={inputErrors}
        label={label}
      >
        <DateInputStyled
          autoFocus={autofocus}
          dataTestId={`input-${dataTestId}`}
          id={dataTestId}
          name={name}
          onChange={onChangeFn}
          placeholder={placeholder}
          value={transformedDate}
        />
      </FormFieldStyled>
    </DateInputContainer>
  )
}

FormInputDate.propTypes = {
  autofocus: PropTypes.bool,
  dataTestId: PropTypes.string.isRequired,
  inputErrors: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
}

FormInputDate.defaultProps = {
  label: 'Choose Date',
  placeholder: 'MM/DD/YYYY',
}

export default FormInputDate
