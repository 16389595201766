import React from 'react'

import PropTypes from 'prop-types'

import { Container, Grip, HoverIndicator, Text } from './style'

//The component that will be displayed when dragging a draggable.

const DraggablePreview = ({ hasBorder, height, scale, text, width }) => (
  <Container height={height} scale={scale} showBorder={hasBorder} width={width}>
    <HoverIndicator scale={scale} />
    <Grip scale={scale} />
    <Text scale={scale}>{text}</Text>
  </Container>
)

DraggablePreview.propTypes = {
  hasBorder: PropTypes.bool,
  height: PropTypes.number,
  scale: PropTypes.number,
  text: PropTypes.string,
  width: PropTypes.number,
}

DraggablePreview.defaultProps = {
  height: 0,
  scale: 1,
  text: '',
  width: 0,
}

export default DraggablePreview
