import React from 'react'
import PropTypes from 'prop-types'
import ContainedButton from '../ContainedButton'
import YieldIcon from '../YieldIcon'

import {
  BodyCopyStyled,
  ButtonWrapper,
  ContentWrapper,
  ErrorBodyCopy,
  Header,
  StyledContainer,
  SubHeader,
} from './component.styles'

import { ERROR_CODE, SERVICE_CODE, TRY_AGAIN } from './constants'
import { httpErrorMessage, serviceNameEnums } from './data'

const propTypes = {
  additionalDetails: PropTypes.string,
  serviceName: PropTypes.string,
  statusCode: PropTypes.number,
  userId: PropTypes.number,
}

const ErrorMessage = ({
  additionalDetails = '',
  statusCode: status,
  serviceName,
  userId,
}) => {
  const handlePageReload = () => {
    window.location.reload()
  }

  const {
    additionalMessaging,
    headerText,
    messageText,
    statusCode,
    statusMessage,
  } = httpErrorMessage(status)

  const dropdownDetails = additionalDetails || additionalMessaging
  const errorDetailsTxt = `**${ERROR_CODE}** *${statusCode} ${statusMessage}*`
  const retrievedServiceCode = serviceNameEnums[serviceName]
  const userDetails = userId ? `-${userId}` : ''
  const serviceDetails = `**${SERVICE_CODE}** *${retrievedServiceCode}${userDetails}*`

  return (
    <StyledContainer>
      <ContentWrapper>
        <YieldIcon dataTestId="icon-warning" />
        <Header dataTestId="error-details-header">{headerText}</Header>
        <SubHeader data-testid="error-details-subheader">
          {messageText}
        </SubHeader>
      </ContentWrapper>

      <ButtonWrapper key="button-error-wrapper">
        <ContainedButton
          dataTestId="button-try-again"
          onClick={handlePageReload}
        >
          {TRY_AGAIN}
        </ContainedButton>
      </ButtonWrapper>
      <BodyCopyStyled data-testid="details-error-code">
        {dropdownDetails}
      </BodyCopyStyled>
      <ErrorBodyCopy data-testid="error-code">{errorDetailsTxt}</ErrorBodyCopy>
      {serviceName && <ErrorBodyCopy>{serviceDetails}</ErrorBodyCopy>}
    </StyledContainer>
  )
}
ErrorMessage.propTypes = propTypes
export default ErrorMessage
