import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { StyledTelescopeIcon } from '../HighSchoolPreview/component.styles'
import { BOLD_TEXT } from '../HighSchoolPreview/constants'

import {
  AdditionalDetailsText,
  StyledBanner,
  TelescopeIconWrapper,
} from './component.styles'
import { DATA_TEST_ID } from './constants'

const HighSchoolPreviewBannerComponent = ({
  currentSectionIndex,
  currentSlideIndex,
  isHighSchoolPreviewMode,
}) => {
  const createBannerContent = () => {
    return (
      <>
        <TelescopeIconWrapper>
          <StyledTelescopeIcon />
        </TelescopeIconWrapper>
        <AdditionalDetailsText>{BOLD_TEXT}</AdditionalDetailsText>
      </>
    )
  }

  if (
    (currentSectionIndex > 0 || currentSlideIndex > 0) &&
    isHighSchoolPreviewMode
  ) {
    return (
      <StyledBanner
        data-testid={DATA_TEST_ID}
        additionalDetails={createBannerContent()}
      />
    )
  }

  return null
}

HighSchoolPreviewBannerComponent.propTypes = {
  currentSectionIndex: PropTypes.number,
  currentSlideIndex: PropTypes.number,
  isHighSchoolPreviewMode: PropTypes.bool,
}

HighSchoolPreviewBannerComponent.defaultProps = {
  currentSectionIndex: 0,
  currentSlideIndex: 0,
  isHighSchoolPreviewMode: false,
}

const mapStateToProps = ({
  player: { currentSlide, isHighSchoolPreviewMode },
}) => ({
  currentSectionIndex: currentSlide[0],
  currentSlideIndex: currentSlide[1],
  isHighSchoolPreviewMode,
})

export default connect(mapStateToProps, {})(HighSchoolPreviewBannerComponent)
