import React from 'react'
import PropTypes from 'prop-types'
import AutoTableHeader from './AutoTableHeader'
import AutoTableRow from './AutoTableRow'
import AutoTableFilters from './AutoTableFilters'
import {
  EmptyTablePrimaryMessage,
  EmptyTableRow,
  EmptyTableSecondaryMessage,
  Table,
  TableHead,
} from './component.styles'

const AutoTable = React.forwardRef(
  (
    {
      className,
      columns = [],
      dark,
      emptyDataPrimaryMessage,
      emptyDataSecondaryMessage,
      filter,
      filterData,
      handleRowClick,
      hover,
      id,
      multiColSpanValue,
      numOfColumns,
      onToggleSort,
      resolveCellValue,
      resolveClassName,
      role,
      rowClassName,
      rows,
      size,
      sort,
      sortData,
      sortedRows,
      striped,
      updateFilter,
    },
    ref,
  ) => {
    const styles = {
      dark: dark,
      size: size,
      hover: hover,
      striped: striped,
    }

    const theadClassName = dark ? 'thead-dark' : 'thead-light'
    const enableFilter = filter && rows.length > 1

    if (columns.length === 0)
      return <div color="warning">No columns specified</div>

    return (
      <Table className={className} {...styles} role={role}>
        <TableHead className={theadClassName}>
          <AutoTableHeader
            columns={columns}
            id={id}
            onToggleSort={onToggleSort}
            resolveClassName={resolveClassName}
            sort={sort && sortedRows.length > 1}
            sortData={sortData}
          />
          {enableFilter && (
            <AutoTableFilters
              columns={columns}
              filter={filterData}
              id={id}
              resolveClassName={resolveClassName}
              updateFilter={updateFilter}
            />
          )}
        </TableHead>
        <tbody>
          {sortedRows.length === 0 && (
            <EmptyTableRow colSpan={numOfColumns}>
              <EmptyTablePrimaryMessage>
                {emptyDataPrimaryMessage}
              </EmptyTablePrimaryMessage>
              <EmptyTableSecondaryMessage>
                {emptyDataSecondaryMessage}
              </EmptyTableSecondaryMessage>
            </EmptyTableRow>
          )}
          {sortedRows.length > 0 &&
            sortedRows.map((row, rowIndex) => (
              <AutoTableRow
                columns={columns}
                dataTestId={row.dataTestId}
                handleRowClick={handleRowClick}
                id={id}
                key={rowIndex}
                multiColSpanValue={multiColSpanValue}
                ref={ref?.current?.[rowIndex]}
                resolveCellValue={resolveCellValue}
                resolveClassName={resolveClassName}
                rowClassName={rowClassName}
                rowData={row}
                rowIndex={rowIndex}
              />
            ))}
        </tbody>
      </Table>
    )
  },
)

AutoTable.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      fieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
        .isRequired,
      sort: PropTypes.bool,
      filter: PropTypes.bool,
      sortValue: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      filterValue: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      headerClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      cellClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    }),
  ).isRequired,
  dark: PropTypes.bool,
  defaultFilter: PropTypes.arrayOf(
    PropTypes.shape({
      columnTitle: PropTypes.string.isRequired,
      query: PropTypes.string.isRequired,
    }),
  ),
  defaultSort: PropTypes.shape({
    columnTitle: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(['asc', 'desc']).isRequired,
  }),
  filter: PropTypes.bool,
  filterData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  handleRowClick: PropTypes.func,
  hover: PropTypes.bool,
  id: PropTypes.string.isRequired,
  multiColSpanValue: PropTypes.number,
  onToggleSort: PropTypes.func.isRequired,
  resolveCellValue: PropTypes.func.isRequired,
  resolveClassName: PropTypes.func.isRequired,
  role: PropTypes.string,
  rowClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rows: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ).isRequired,
  size: PropTypes.string,
  sort: PropTypes.bool,
  sortData: PropTypes.object,
  sortedRows: PropTypes.array,
  striped: PropTypes.bool,
  updateFilter: PropTypes.func.isRequired,
}

AutoTable.defaultProps = {
  emptyDataPrimaryMessage: 'No results found',
  emptyDataSecondaryMessage: 'There are no available data for this table',
}

export default AutoTable
