import React, { Component } from 'react'

import PropTypes from 'prop-types'

import { DRAG_AND_DROP_ERRORS } from '../../../utils/dragAndDropHelper'
import { FeedbackCopy } from '../../text'

import { EvaluationTextContainer } from './style'

const DATA_TEST_ID = 'dragQuestionEvaluationText'

class DragQuestionEvaluationTextComponent extends Component {
  render = () => {
    const { showErrorMessage } = this.props
    const evaluationText =
      showErrorMessage && DRAG_AND_DROP_ERRORS.DRAG_QUESTION

    return (
      <EvaluationTextContainer data-testid={DATA_TEST_ID}>
        <FeedbackCopy>{evaluationText}</FeedbackCopy>
      </EvaluationTextContainer>
    )
  }
}

DragQuestionEvaluationTextComponent.propTypes = {
  showErrorMessage: PropTypes.bool,
}

export default DragQuestionEvaluationTextComponent
