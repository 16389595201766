import { default as baseTheme } from './grade8'

const kometStack = `"Komet", Helvetica, Arial, sans-serif`

const fonts = {
  display: {
    fontFamily: kometStack,
    fontSize: '1.83em',
    fontWeight: 400,
    lineHeight: 'normal',
    margin: 0,
  },
  h1: {
    fontSize: '3rem',
    fontFamily: kometStack,
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '4rem',
  },
  h2: {
    fontSize: '2.625rem',
    fontFamily: kometStack,
    fontStyle: 'normal',
    fontWeight: '600',
  },
  h3: {
    fontSize: '2.375rem',
    fontFamily: kometStack,
    fontStyle: 'normal',
    fontWeight: '600',
    margin: '0',
  },
  h4: {
    fontSize: '2.25rem',
    fontFamily: kometStack,
    fontStyle: 'normal',
    fontWeight: '600',
  },
  h5: {
    fontSize: '2.125rem',
    fontFamily: kometStack,
    fontStyle: 'normal',
    fontWeight: '600',
  },
  h6: {
    fontSize: '2rem',
    fontFamily: kometStack,
    fontStyle: 'normal',
    fontWeight: '600',
  },
  handWriting: {
    fontFamily: '"Felt Tip", cursive',
    fontSize: '60px',
    lineHeight: '90px',
    paddingBottom: '15px',
    letterSpacing: '0.12em',
    wordSpacing: '0.16em',
  },
  p: {
    fontFamily: 'ff-tisa-sans-web-pro',
    fontSize: '2.063rem',
    lineHeight: '3.094rem',
    fontWeight: 400,
  },
  li: {
    fontFamily: 'ff-tisa-sans-web-pro',
    fontSize: '2.063rem',
    lineHeight: '3.094rem',
    fontWeight: 400,
  },
}

export default {
  colors: {
    ...baseTheme.colors,
  },
  fonts: {
    ...baseTheme.fonts,
    ...fonts,
  },
}
