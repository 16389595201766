import styled from 'styled-components'
import { themeGet } from 'styled-system'
import BodyCopy from '../BodyCopy'

const ModalSubHeader = styled(BodyCopy)`
  color: ${themeGet('colors.modals.subHeader')};
  font-family: ${themeGet('fontFamilySemibold')};
  font-size: 1.25rem;
  line-height: 1.5rem;
  ${themeGet('breakpoints.tablet')} {
    padding: 0;
  }
`

ModalSubHeader.displayName = 'ModalSubHeader'

export default ModalSubHeader
