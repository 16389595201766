import { Logger } from 'logging'
import { all, debounce, select, takeEvery } from 'redux-saga/effects'

import { playerInteractiveSelector } from '../selectors'

function* fireViewedSlide() {}

function* fireInteractive(action) {}

function* fireScoredInteractive(action) {
  try {
    const questionData = yield select(
      playerInteractiveSelector(action.interactiveId),
    )
    if (!questionData) {
      throw new Error(
        `Missing Data to Grade Interactive of type ${action.type}`,
      )
    }

    questionData.interactiveId = action.interactiveId
  } catch (error) {
    Logger.logException(error)
    console.error(error)
  }
}

export default function* playerSaga() {
  yield all([
    takeEvery(['NEXT_SLIDE', 'PREV_SLIDE', 'GOTO_SLIDE'], fireViewedSlide),
    takeEvery(
      ['DIALOG_CARD_FLIP', 'IMAGE_HOTSPOTS_TARGET_CLICK'],
      fireInteractive,
    ),
    debounce(1000, 'IMAGE_JUXTAPOSITION_UPDATE', fireInteractive),
    takeEvery(
      [
        'DRAG_QUESTION_SUBMIT',
        'DRAG_LABEL_SUBMIT',
        'DRAG_WORD_SUBMIT',
        'MULTICHOICE_SUBMIT',
      ],
      fireScoredInteractive,
    ),
  ])
}
