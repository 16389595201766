import styled from 'styled-components'

export const TranscriptWrapper = styled.div`
  margin-top: 1.25rem;
  .transcript-block {
    margin-bottom: 1rem;
  }
  .transcript-heading {
    margin-top: 1rem;
    text-transform: uppercase;
  }
  .transcript-block:not(.description) .transcript-heading {
    display: none;
  }
  .transcript-line {
    margin-bottom: 1rem;
  }
`
