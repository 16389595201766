import React from 'react'
import PropTypes from 'prop-types'
import { Anchor } from 'grommet'

const Breadcrumbs = ({ path, separator }) => {
  return (
    <>
      {path.map((item, idx) => {
        return idx !== path.length - 1 ? (
          <span key={idx}>
            <Anchor
              data-testid="breadcrumbs"
              href={item.href}
              label={item.label}
            />{' '}
            &nbsp; {separator} &nbsp;
          </span>
        ) : (
          <span key={idx}>{item.label}</span>
        )
      })}
    </>
  )
}

Breadcrumbs.propTypes = {
  path: PropTypes.array.isRequired,
  separator: PropTypes.string,
}

Breadcrumbs.defaultProps = {
  separator: '|',
}

export default Breadcrumbs
