/* eslint-disable */
const SUBDOMAIN = process.env.SUBDOMAIN
const HOST_DOMAIN = process.env.HOST_DOMAIN

const config_env = {
  default: {
    domains: {
      CURRICULUM_APP_DOMAIN: `https://learn${SUBDOMAIN}.${HOST_DOMAIN}`,
      ADMIN_APP_DOMAIN: `https://admin${SUBDOMAIN}.${HOST_DOMAIN}`,
      LOGIN_DOMAIN: `https://login${SUBDOMAIN}.${HOST_DOMAIN}`,
      WWW_DOMAIN: `https://www${SUBDOMAIN}.${HOST_DOMAIN}`,
    },
  },
  local: {
    domains: {
      CURRICULUM_APP_DOMAIN: `http://localhost:4000`,
      ADMIN_APP_DOMAIN: `http://localhost:4001`,
      LOGIN_DOMAIN: `http://localhost:5000`,
      // we don't have a localhost config for the public site
      WWW_DOMAIN: `https://www-np-dev.cfctest.org`,
    },
  },
}

// setup identical envs
config_env.test = config_env.default
config_env.localApp = config_env.local
config_env.localContentful = config_env.local

const environment = process.env.BUILD_TYPE || 'test'
let env = config_env[environment]
env.environment = environment
export const domains = env.domains
export const oidcSettings = env.oidcSettings
export default env
