import PropTypes from 'prop-types'
import React from 'react'
import { CourseLabelText } from './component.styles'

const CourseLabel = ({ gradeNumber }) => {
  gradeNumber = gradeNumber.toString().slice(0, 1)

  return <CourseLabelText>{`G${gradeNumber}`}</CourseLabelText>
}

CourseLabel.propTypes = {
  gradeNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
}

CourseLabel.displayName = 'CourseLabel'

export default CourseLabel
