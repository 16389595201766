import styled from 'styled-components'

import { H1 as HyperText } from '../text'

export const H1 = styled(HyperText)`
  ${({ isDoneSlide }) =>
    isDoneSlide &&
    `
    font-family: 'SharpSlab-Extrabold'
    `}
`
