import { isMovementKey, KEYS_MOVEMENT } from '../../utils/keyboardControlHelper'

import { DIVIDER_WIDTH } from './constants'

const halfOfDividerWidth = DIVIDER_WIDTH / 2
const INCREMENT_PERCENT = 10
const LOWER_BOUND = halfOfDividerWidth

export const createFrameMouseHandler = callback => {
  return (e, pageXOffset = 0, frameWidth = 0) => {
    const upperBound = frameWidth
    const { nativeEvent } = e || {}
    const { pageX: mousePageX = 0 } = nativeEvent || {}
    const x = mousePageX - pageXOffset
    const boundedX = boundX(x, LOWER_BOUND, upperBound)

    callback(boundedX)
  }
}

export const createFrameKeyboardHandler = callback => {
  return (e, pageXOffset = 0, frameWidth = 0, pageX) => {
    const { key } = e || {}

    if (!isMovementKey(key)) {
      return
    }

    const isRightArrowKey = key === KEYS_MOVEMENT.ARROW_RIGHT
    const onePercentOfFrameWidth = frameWidth / 100
    const increment = onePercentOfFrameWidth * INCREMENT_PERCENT
    const directionalIncrement = isRightArrowKey ? increment : -increment
    const upperBound = frameWidth
    const currentX = pageX - pageXOffset
    const newX = currentX + directionalIncrement
    const boundedX = boundX(newX, LOWER_BOUND, upperBound)

    callback(boundedX)
  }
}

const boundX = (x, lowerBound, upperBound) => {
  let boundedX = x < lowerBound ? lowerBound : x
  boundedX = boundedX > upperBound ? upperBound : boundedX

  return boundedX
}
