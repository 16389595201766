export const DATA_TEST_ID_VIDEO_WRAPPER = 'videojs-video-wrapper'
export const ERROR_NO_AZURE_VIDEO_URL = 'No video url was passed!'
export const WRAPPER_CLASSES = 'videoJsWrapper'
export const tooltipText = 'Show Transcript'
export const audioToolTipText = 'Play Video with Audio Description'
export const hideTranscript = 'Hide Transcript'
export const withoutAudioDescription = 'Play Video Without Audio Description'

export const getTranscriptToolTipText = isTranscriptActive => {
  const currentTranscriptToolTipText = isTranscriptActive
    ? hideTranscript
    : tooltipText
  return currentTranscriptToolTipText
}
export const getAudioDescToolTipText = isAudioDescActive => {
  const currentAudioDescToolTipText = isAudioDescActive
    ? withoutAudioDescription
    : audioToolTipText
  return currentAudioDescToolTipText
}
