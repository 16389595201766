import { FlagsComponentRouter } from 'flags'
import {
  isCurriculumPreviewMode,
  isFamilyMode,
  isHighSchoolPreviewMode,
  isPrepMode,
  isSampleMode,
} from 'utils/playerHelper'

import OffComponent from './flag.off/index'
import OnComponent from './flag.on/index'

const OnConditionalComponent =
  isPrepMode() ||
  isSampleMode() ||
  isFamilyMode() ||
  isCurriculumPreviewMode() ||
  isHighSchoolPreviewMode()
    ? OffComponent
    : OnComponent

const FlagToggler = FlagsComponentRouter({
  flagSubscriptions: ['feature-LEARN-11000-language-toggle'],
  OnComponent: OnConditionalComponent,
  OffComponent,
})

export default FlagToggler
