import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Anchor } from 'grommet'

export const AnchorStyled = styled(Anchor)`
  font-family: ${themeGet('fontFamilySemibold')};
  font-weight: 400;
  svg {
    margin-left: 8px;
    transform: translate(0);
    transition: 0.2s;
  }
  &:hover {
    text-decoration: none;
    svg {
      transform: translate(0.5rem);
    }
  }
`

export const NoBreak = styled.span`
  white-space: nowrap;
`
