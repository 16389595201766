import { applyMiddleware, compose, createStore } from 'redux'
import { apiMiddleware } from 'redux-api-middleware'
import createSagaMiddleware from 'redux-saga'

import { RESET_APP } from './actions'
import appReducer from './reducers'
import appSaga from './sagas'

const sagaMiddleware = createSagaMiddleware()

// reset entire app to nothing, this give us an idea of what "loading" might look like.
// state isn't being mutated, and it's receiving the initial state for all reducers since state is undefined
// take a look at this https://alligator.io/redux/reset-state-redux/#centralizing-the-resetting-of-the-state
// it includes a way to prevent reducers from being reset.
const rootReducer = (state, action) => {
  if (action.type === RESET_APP) {
    state = undefined
  }
  return appReducer(state, action)
}

const bindMiddleware = (middleware = []) => {
  if (process.env.NODE_ENV !== 'production') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__
    if (typeof devToolsExtension === 'function') {
      return compose(applyMiddleware(...middleware), devToolsExtension())
    }
  }
  return applyMiddleware(...middleware)
}

let appStore

export default function configureStore(initialState) {
  const store = createStore(
    rootReducer,
    initialState,
    bindMiddleware([sagaMiddleware, apiMiddleware]),
  )

  store.runSagaTask = () => {
    store.sagaTask = sagaMiddleware.run(appSaga)
  }

  store.runSagaTask()

  appStore = store
  return store
}

export { appStore }
