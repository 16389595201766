/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

const MediaIcon = () => (
  <svg
    height="23"
    viewBox="0 0 26 23"
    width="26"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m3.9169 16.5199c1.37049597 0 2.54404343.8592641 3.01020964 2.0673594l17.84479036.0004406c.6391769 0 1.158.5180341 1.158 1.157 0 .5971173-.4517807 1.0883623-1.031853 1.1512078l-.126147.0067922-17.84479036-.0001594c-.46616621 1.2080953-1.63971367 2.0673594-3.01020964 2.0673594-1.77829658 0-3.225-1.4467034-3.225-3.225s1.44670342-3.225 3.225-3.225zm.0004 1.6376c-.877 0-1.588.711-1.588 1.587 0 .877.711 1.588 1.588 1.588.876 0 1.587-.711 1.587-1.588 0-.876-.711-1.587-1.587-1.587z" />
    <path d="m9.8032 13.0858c-.263-.132-.395-.395-.395-.659v-10.994c0-.263.132-.527.395-.658.264-.132.527-.132.724 0l9.283 5.464c.198.132.395.394.395.658s-.132.526-.395.658l-9.283 5.465c-.065.066-.197.132-.329.132-.131 0-.263 0-.395-.066z" />
  </svg>
)

export default MediaIcon
