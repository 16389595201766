export const DEFAULT_DETAILED_MESSAGE =
  'An unexpected error occurred. Please try reloading the presentation.'
export const DEFAULT_MESSAGE = "There's been a server error.\nPlease try again."
export const DEFAULT_MESSAGE_NO_RELOAD = "There's been a server error."
export const ERROR_DISPLAY = {
  400: {
    message:
      'Error code: 400 Bad Request. Please check the URL and try again. If you still receive the same error, please contact us.',
    showRetry: false,
  },
  401: {
    message:
      'Error code: 401 Unauthorized. Please login now or register to view content. If you are logged in and still receive the same error, please contact us.',
    showRetry: true,
  },
  403: {
    message:
      'Error code: 403 Forbidden. Please login now or register to view content. If you are logged in and still receive the same error, please contact us.',
    showRetry: true,
  },
  404: {
    message: `Error code: 404 Not Found. Sorry this page is not available.`,
    showRetry: false,
  },
  408: {
    message:
      'Error code: 408 Timeout. Please check your internet connection and try again. If you still receive the same error, please contact us.',
    showRetry: true,
  },
  413: {
    message:
      'Error code: 413 Timeout. This content is too large to be retrieved. Sorry this page is not available.',
    showRetry: false,
  },
  500: {
    message: `Error code: 500. We encountered an error. Please try again in a few minutes.`,
    showRetry: true,
  },
}
export const IMAGE_DATA_TEST_ID = 'http-error-image'
export const MESSAGE_DATA_TEST_ID_PREFIX = 'message-line-'
export const MESSAGE_CONTAINER_DATA_TEST_ID = 'message-container'
export const NEW_LINE = '\n'
export const RETRY_BUTTON_DATA_TEST_ID = 'retry-button'
export const RETRY_BUTTON_TEXT = 'TRY AGAIN'
export const TITLE = "Something's gone wrong"
export const TITLE_DATA_TEST_ID = 'title'
