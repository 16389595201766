import styled from 'styled-components'
import { Box } from 'grommet'

export const Wrapper = styled(Box).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const StyledImage = styled.img.attrs(({ alt, src, dataTestId }) => ({
  'data-testid': dataTestId,
  alt: alt,
  src: src,
}))`
  width: 100%;
`
