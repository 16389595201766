import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormFieldStyled, IconStyled, SelectStyled } from './component.styles'
import { addTestIdToOptions, sortOptions } from './data.js'

const FormInputSelect = ({
  autofocus,
  className,
  dataTestId,
  defaultValue,
  disabled,
  disabledKey,
  dropAlign,
  dropHeight,
  dropProps,
  inline,
  inputErrors,
  label,
  name,
  onChange,
  optionKey,
  options,
  sortBy,
  placeholder,
  value,
  valueKey,
}) => {
  const [currentValue, setCurrentValue] = useState('')
  const [isOpen, isSetOpen] = useState(false)

  const onChangeFn = useCallback(e => {
    setCurrentValue(e.option)
    onChange(e)
  })

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  const sortedOptions = sortOptions(sortBy, options)

  if (!value && options.length === 1) {
    value = options[0]
  }

  const finalValue = currentValue !== '' ? currentValue : value

  const Icon = <IconStyled open={isOpen} />

  const resolveSelectedValue = () => {
    if (typeof options[0] === 'object' && typeof optionKey !== 'function') {
      return sortedOptions.find(so => so[optionKey] === finalValue)
    }
    return finalValue
  }

  if (inline) {
    dropProps.inline = inline
  }

  return (
    <FormFieldStyled
      className={className}
      dataTestId={dataTestId}
      inputErrors={inputErrors}
      label={label}
      labelFor={`${dataTestId}__input`}
    >
      <SelectStyled
        autoFocus={autofocus}
        // grommet requires you pass children like to customize the options
        // eslint-disable-next-line
        children={addTestIdToOptions(dataTestId, optionKey)}
        dataTestId={`input-${dataTestId}`}
        defaultValue={defaultValue}
        disabled={disabled}
        disabledKey={disabledKey}
        dropAlign={dropAlign}
        dropHeight={dropHeight}
        dropProps={dropProps}
        icon={Icon}
        id={dataTestId}
        labelKey={optionKey}
        name={name}
        onChange={onChangeFn}
        onClose={() => isSetOpen(!isOpen)}
        onOpen={() => isSetOpen(!isOpen)}
        options={sortedOptions}
        placeholder={placeholder}
        value={resolveSelectedValue()}
        valueKey={valueKey}
      />
    </FormFieldStyled>
  )
}

FormInputSelect.propTypes = {
  autofocus: PropTypes.bool,
  className: PropTypes.string,
  dataTestId: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  disabledKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  dropAlign: PropTypes.object,
  dropHeight: PropTypes.string,
  dropProps: PropTypes.object,
  inline: PropTypes.bool,
  inputErrors: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  optionKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  options: PropTypes.array,
  placeholder: PropTypes.string,
  sortBy: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  valueKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
}

FormInputSelect.defaultProps = {
  dropProps: {},
  inline: false,
  label: 'Select option',
  onChange: () => {},
  options: [],
  placeholder: 'Select from below',
}

export default FormInputSelect
