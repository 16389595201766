import { useDrag, useDragLayer, useDrop } from 'react-dnd'

import { ITEM_TYPES } from './constants'
import DragAndDropUtils from './utils'

//Connect to this main interface to handle all drag and drop duties in the app.

export default class DragAndDropInterface {
  // Create a draggable ref. Pass the ref to the component you want to drag. Pass a useState function setDomElement
  // to capture the dom element.
  createDraggableRefCallback = (setDomElement = () => {}) => {
    return (domElement, drag) => {
      drag(domElement)
      setDomElement(domElement)
    }
  }

  //Connects to the drag interface. Receive most current data about the draggable it is connected to.
  //Defaults to using a custom drag layer.
  setupUseDrag = (props, interactiveType, domElement) => {
    const {
      canSubmitForm,
      draggableId: id,
      isSubmitted,
      label,
      location: currentLocation,
    } = props || {}
    const canSubmit = canSubmitForm && canSubmitForm()
    const type = ITEM_TYPES.TEXT
    const canDrag = !isSubmitted || !canSubmit
    const item = {
      currentLocation,
      domElement,
      id,
      interactiveType,
      label,
      type,
    }
    const collect = DragAndDropUtils.handleDragPropCollection
    const dragOptions = {
      canDrag,
      item,
      collect,
    }
    const [collectedProps, dragRef, dragPreviewRef] = useDrag(dragOptions)
    const { isDragging } = collectedProps || {}
    const formattedCollectedProps = {
      isDragging,
    }

    // For player purposes we always use a custom drag layer because of aspect ratio scaling. Disable
    // default draggable preview.
    DragAndDropUtils.disableDraggableDefaultPreview(dragPreviewRef)

    return [formattedCollectedProps, dragRef]
  }

  //Connects to the drop interface. Receive most current data about the drop zone it is connected to.
  setupUseDrop = (dropZoneId, handleDrop, handleHover) => {
    const accept = ITEM_TYPES.TEXT
    const collect = DragAndDropUtils.handleDropPropCollection
    const drop = (item, monitor) => handleDrop(item, monitor, dropZoneId)
    const hover = DragAndDropUtils.createHoverHandler(handleHover)
    const dropOptions = {
      accept,
      collect,
      drop,
      hover,
    }
    const [collectedProps, dropRef] = useDrop(dropOptions)
    const { canDrop, isOver: isOverDropZone } = collectedProps || {}
    const formattedCollectedProps = {
      canDrop,
      isOverDropZone,
    }

    return [formattedCollectedProps, dropRef]
  }

  //Connects to the custom drag layer. Receive most current data about the drag layer it is connected to.
  setupUseDragLayer = () => {
    const { currentOffset, isDragging, item } = useDragLayer(monitor => ({
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
      item: monitor.getItem(),
    }))
    const formattedCollectedProps = { currentOffset, isDragging, item }

    return [formattedCollectedProps]
  }
}
