import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getLocaleFromSession } from 'utils/locale'

import LessonCompleteComponent from './component'
import { MESSAGE } from './constants'

const LessonCompleteContainer = ({ lessonName }) => {
  let message

  if (lessonName) {
    const currentLocale = getLocaleFromSession()
    message = MESSAGE(lessonName, currentLocale)
  }
  return <LessonCompleteComponent message={message} />
}

LessonCompleteContainer.propTypes = {
  lessonName: PropTypes.string,
}

const mapStateToProps = ({ player }) => ({
  lessonName: player.title,
})

export default connect(mapStateToProps)(LessonCompleteContainer)
