export const getGradeFromThemeParam = () => {
  const { search } = window.location || {}
  const params = search && new URLSearchParams(search)

  if (params?.get) {
    return params.get('theme') ?? null
  }
  // satisfy unit test case:
  return null
}
