import styled from 'styled-components'

export const Container = styled.div.attrs(
  ({
    height,
    theme: {
      colorVars: { dragLabel: colors },
    },
    top,
    width,
  }) => ({
    backgroundColor: colors.dropZones.background,
    top,
    style: { height: `${height}px`, width: `${width}px` },
  }),
)`
  background-color: ${props => props.backgroundColor};
  position: absolute;
  top: ${props => props.top};
`
