import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const Row = styled.tr.attrs({ role: 'columnrow' })`
  font-family: ${themeGet('fontFamily')};
`

export const Cell = styled.td`
  padding: 1rem;
`
