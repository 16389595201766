/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

export default () => (
  <svg
    height="23px"
    version="1.1"
    viewBox="0 0 23 23"
    width="23px"
    x="0px"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
  >
    <g transform="translate(-305.000000, -240.000000)">
      <g transform="translate(40.000000, 40.000000)">
        <g transform="translate(27.000000, 138.547350)">
          <g
            id="planning-ahead-icon"
            transform="translate(238.000000, 61.452650)"
          >
            <path
              className="st0"
              d="M10,12.2h1.6c0.3,0,0.6-0.3,0.6-0.6V10c0-0.3-0.3-0.6-0.6-0.6H10c-0.3,0-0.6,0.3-0.6,0.6v1.6
						C9.3,11.9,9.6,12.2,10,12.2"
              fill="currentColor"
            />
            <path
              className="st0"
              d="M14.7,9.4c-0.3,0-0.6,0.3-0.6,0.6v1.6c0,0.3,0.3,0.6,0.6,0.6h1.6c0.3,0,0.6-0.3,0.6-0.6V10
						c0-0.3-0.3-0.6-0.6-0.6H14.7z"
              fill="currentColor"
            />
            <path
              className="st0"
              d="M7.4,10c0-0.3-0.3-0.6-0.6-0.6H5.2c-0.3,0-0.6,0.3-0.6,0.6v1.6c0,0.3,0.3,0.6,0.6,0.6h1.6
						c0.3,0,0.6-0.3,0.6-0.6V10z"
              fill="currentColor"
            />
            <path
              className="st0"
              d="M6.8,14.1H5.2c-0.3,0-0.6,0.3-0.6,0.6v1.6c0,0.3,0.3,0.6,0.6,0.6h1.6c0.3,0,0.6-0.3,0.6-0.6
						v-1.6C7.4,14.4,7.2,14.1,6.8,14.1"
              fill="currentColor"
            />
            <path
              className="st0"
              d="M10,17h1.6c0.3,0,0.6-0.3,0.6-0.6v-1.6c0-0.3-0.3-0.6-0.6-0.6H10c-0.3,0-0.6,0.3-0.6,0.6v1.6
						C9.3,16.7,9.6,17,10,17"
              fill="currentColor"
            />
            <g transform="translate(0.000000, 0.271000)">
              <defs>
                <filter
                  filterUnits="userSpaceOnUse"
                  height="20.8"
                  width="21.7"
                  x="0"
                  y="0.1"
                >
                  <feColorMatrix
                    type="matrix"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                  />
                </filter>
              </defs>
              <mask
                height="20.8"
                maskUnits="userSpaceOnUse"
                width="21.7"
                x="0"
                y="0.1"
              >
                <g className="st1">
                  <polygon
                    className="st2"
                    points="0,0.1 21.7,0.1 21.7,20.9 0,20.9 								"
                  />
                </g>
              </mask>
              <path
                className="st3"
                d="M12.8,19.6l-0.1-0.1H2.4c-0.5,0-1-0.4-1-1V10h0l0-2.9c0-0.5,0.5-0.9,1-0.9h17
							c0.5,0,1,0.4,1,1v5.1l0.1,0.1c0.3,0.2,0.7,0.3,1,0.6l0.3,0.2l0-8.6c0-1.3-1.1-2.4-2.4-2.4h-2.1V1.8c0-0.9-0.7-1.6-1.6-1.6
							c-0.9,0-1.6,0.7-1.6,1.6v0.4H7.7V1.8c0-0.9-0.7-1.6-1.6-1.6S4.5,0.9,4.5,1.8v0.4H2.4C1.1,2.2,0,3.2,0,4.5l0,1.8
							c0,0.1,0,0.1,0,0.2v12.1c0,1.3,1.1,2.4,2.4,2.4h11.2l-0.2-0.3C13.1,20.3,13,20,12.8,19.6"
                fill="currentColor"
              />
            </g>
            <g transform="translate(13.600000, 13.471000)">
              <defs>
                <filter
                  filterUnits="userSpaceOnUse"
                  height="8.8"
                  width="8.8"
                  x="0.2"
                  y="0"
                >
                  <feColorMatrix
                    type="matrix"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                  />
                </filter>
              </defs>
              <mask
                height="8.8"
                maskUnits="userSpaceOnUse"
                width="8.8"
                x="0.2"
                y="0"
              >
                <g className="st4">
                  <polygon className="st2" points="0.2,0 9,0 9,8.8 0.2,8.8 								" />
                </g>
              </mask>
              <path
                className="st5"
                d="M7.4,3.8L7.4,3.8L4.8,5.4c-0.1,0-0.2,0.1-0.2,0.1c0,0,0,0,0,0c-0.1,0-0.3-0.1-0.4-0.2
							L2.9,3.8C2.8,3.7,2.8,3.5,2.8,3.4c0-0.1,0.1-0.3,0.2-0.3c0.2-0.2,0.5-0.2,0.7,0l1,1.2l2.2-1.3c0.1-0.1,0.3-0.1,0.4,0
							c0.1,0,0.2,0.1,0.3,0.3C7.7,3.4,7.6,3.7,7.4,3.8 M4.6,0C2.2,0,0.2,2,0.2,4.4s2,4.4,4.4,4.4C7,8.8,9,6.8,9,4.4S7,0,4.6,0"
                fill="currentColor"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
