import userContextReducer from 'auth/stores/userContext'
import { combineReducers } from 'redux'

import cmiReducer from './cmi'
import playerReducer from './player'

const appReducer = combineReducers({
  cmi: cmiReducer,
  player: playerReducer,
  userContext: userContextReducer,
})

export default appReducer
