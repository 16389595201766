import React from 'react'
import { string, func, bool } from 'prop-types'
import { Wrapper, Label, RadioButton } from './component.styles'
import { Markdown } from 'grommet'

const FormInputRadioButton = ({
  id,
  dataTestId,
  answerText,
  checked,
  name,
  onChange,
  value,
}) => {
  const radioProps = { checked, dataTestId, name, onChange, value }
  const labelProps = { name, dataTestId }
  return (
    <Wrapper>
      <RadioButton id={id} key="radio-button" {...radioProps} />
      <Label {...labelProps} htmlFor={id} key="label">
        <Markdown>{answerText}</Markdown>
      </Label>
    </Wrapper>
  )
}

FormInputRadioButton.defaultProps = {
  onChange: () => {},
  checked: false,
  dataTestId: 'radio-button',
}

FormInputRadioButton.propTypes = {
  answerText: string.isRequired,
  checked: bool,
  dataTestId: string,
  id: string.isRequired,
  name: string.isRequired,
  onChange: func,
  value: string.isRequired,
}

export default FormInputRadioButton
