import { LOCALES } from 'utils/locale'

export const CONTAINER_WIDTH = 467
export const DEFAULT_MESSAGE = {
  [LOCALES.EN_US]: "You've completed a lesson",
  [LOCALES.ES_US]: 'Ha completado la lección',
}
export const FEEDBACK_LINK_DATA_TEST_ID = 'lesson-complete-feedback-link'
export const FEEDBACK_LINK_TEXT = {
  [LOCALES.EN_US]: 'Provide feedback about this lesson',
  [LOCALES.ES_US]: 'Proporcione comentarios sobre esta lección',
}
export const IMAGE_DATA_TEST_ID = 'lesson-complete-image'
const MESSAGE_PREFIX = {
  [LOCALES.EN_US]: "You've completed ",
  [LOCALES.ES_US]: 'Ha completado ',
}
const MESSAGE_SUFFIX = {
  [LOCALES.EN_US]: ' lesson',
  [LOCALES.ES_US]: ' lección',
}
export const NEW_TAB = '_blank'
export const TEXT_DATA_TEST_ID = 'lesson-complete-text'
const THE = {
  [LOCALES.EN_US]: 'the ',
  [LOCALES.ES_US]: 'la ',
}
export const TITLE = {
  [LOCALES.EN_US]: 'Nice Work!',
  [LOCALES.ES_US]: '¡Buen trabajo!',
}
export const MESSAGE = (lessonName, locale) =>
  `${MESSAGE_PREFIX[locale]}${THE[locale]}${lessonName}${MESSAGE_SUFFIX[locale]}`
