import React, { useRef } from 'react'

import PropTypes from 'prop-types'
import { getConstantByLocale, getLocaleFromSession } from 'utils/locale'

import DragQuestionSubmitButton from './component'
import { CHECK } from './constants'

const DragQuestionSubmitButtonContainer = ({ disabled, onSubmit }) => {
  const buttonRef = useRef(null)
  const currentLocale = getLocaleFromSession()
  const checkButtonText = getConstantByLocale(CHECK, currentLocale)
  const onClick = () => {
    buttonRef.current.blur()
    onSubmit()
  }

  return (
    <DragQuestionSubmitButton
      disabled={disabled}
      onClick={onClick}
      text={checkButtonText}
      setRef={buttonRef}
      onSubmit={onSubmit}
    />
  )
}

DragQuestionSubmitButtonContainer.propTypes = {
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  submitText: PropTypes.string,
}

DragQuestionSubmitButtonContainer.defaultProps = {
  onReset() {},
  onSubmit() {},
}

export default DragQuestionSubmitButtonContainer
