import React from 'react'

import PropTypes from 'prop-types'

import { Container, EmphaticText, Text } from './style'

const BannerComponent = ({
  className,
  message,
  additionalDetails,
  'data-testid': dataTestId,
}) => {
  return (
    <Container data-testid={dataTestId} className={className}>
      <Text>
        <EmphaticText>{message}</EmphaticText>
        {additionalDetails}
      </Text>
    </Container>
  )
}

BannerComponent.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  // allow details to be its own child component:
  additionalDetails: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  'data-testid': PropTypes.string,
}

BannerComponent.defaultProps = {
  className: '',
  message: '',
  additionalDetails: '',
  'data-testid': 'banner',
}

export default BannerComponent
