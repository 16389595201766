import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { StyledSelect, IconStyled, CheckIconStyled } from './component.styles'
import { components } from 'react-select'

const Dropdown = ({ options = [], onChange, value }) => {
  const selectRef = useRef()
  const [menuIsOpen, setMenuIsOpen] = useState()
  const [menuWidth, setMenuWidth] = useState()
  const [isCalculatingWidth, setIsCalculatingWidth] = useState(false)

  useEffect(() => {
    if (!menuWidth && !isCalculatingWidth) {
      const timeout = setTimeout(() => {
        setIsCalculatingWidth(true)
        // setIsOpen doesn't trigger onOpenMenu, so calling internal method
        selectRef.current.select.openMenu()
        setMenuIsOpen(true)
      }, 1)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [menuWidth, isCalculatingWidth])

  const onMenuOpen = () => {
    if (!menuWidth && isCalculatingWidth) {
      setTimeout(() => {
        const width = selectRef.current.select.menuListRef.getBoundingClientRect()
          .width
        setMenuWidth(width)
        setIsCalculatingWidth(false)

        // setting isMenuOpen to undefined and closing menu
        selectRef.current.select.onMenuClose()
        setMenuIsOpen(undefined)
      }, 1)
    }
  }

  const styles = {
    menu: css => ({
      ...css,
      width: menuWidth ? menuWidth + 36 : 'auto',
      ...(isCalculatingWidth && { height: 0, visibility: 'hidden' }),
    }),
    control: css => ({
      ...css,
      display: 'inline-flex ',
    }),
    valueContainer: css => ({
      ...css,
      ...(menuWidth && { width: menuWidth }),
    }),
  }

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <IconStyled />
      </components.DropdownIndicator>
    )
  }

  // eslint-disable-next-line react/prop-types
  const CustomOption = ({ children, isSelected, ...props }) => {
    return (
      <components.Option isSelected {...props}>
        {children}
        {isSelected ? <CheckIconStyled /> : null}
      </components.Option>
    )
  }

  const selectedValue = options.filter(option => option.value === value)

  return (
    <StyledSelect
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator,
        Option: CustomOption,
      }}
      isSearchable={false}
      menuIsOpen={menuIsOpen}
      onChange={onChange}
      onMenuOpen={onMenuOpen}
      options={options}
      ref={selectRef}
      styles={styles}
      value={selectedValue}
    />
  )
}

Dropdown.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.string,
}

export default Dropdown
