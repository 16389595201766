import React, { useEffect, useRef, useState } from 'react'

import { toggleFullScreen } from 'lib/state/actions/player'
import { playerIsFullScreenSelector } from 'lib/state/selectors'
import { Animated, Easing } from 'react-native-web'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  addFullscreenEventListener,
  fullscreenEvent,
  isBrowserChrome,
  removeFullscreenEventListener,
  toggleBrowserFullscreen,
} from 'utils/browserHelper'

import FullScreenButtonComponent from './component'
import {
  CLICK,
  DURATION_ICON_ANIMATION,
  DURATION_TRANSITION_STATE,
  ICON_DEFAULT_SCALE,
  ICON_GROW,
  ICON_SHRINK,
  KEY_ENTER,
  KEY_SPACE,
  TOOL_TIP_TEXT_ENTER_FULL_SCREEN,
  TOOL_TIP_TEXT_EXIT_FULL_SCREEN,
  TRANSITIONS,
} from './constants'

const FullScreenButtonContainer = () => {
  useEffect(() => {
    addFullscreenEventListener(setFullscreenState)
    return () => removeFullscreenEventListener(setFullscreenState)
  })

  const dispatch = useDispatch()
  const isFullScreen = useSelector(playerIsFullScreenSelector, shallowEqual)
  const scaleAnimRef = useRef(new Animated.Value(ICON_DEFAULT_SCALE)).current
  const [transitionState, setTransitionState] = useState(TRANSITIONS.NONE)
  const [isHovering, setIsHovering] = useState(false)

  const setFullscreenState = fullscreenEvent(event =>
    dispatch(toggleFullScreen(event)),
  )

  const resetTransitionStates = () => {
    const isTransitioning = transitionState !== TRANSITIONS.NONE
    isTransitioning && setTransitionState(TRANSITIONS.NONE)
  }

  const onClick = async event => {
    const isClick = event?.type === CLICK
    const key = event?.key
    const isEnterKey = key === KEY_ENTER
    const isSpaceKey = key === KEY_SPACE
    const actionIsValid = isClick || isEnterKey || isSpaceKey

    if (!actionIsValid) {
      return
    }

    const newIsFullScreen = await toggleBrowserFullscreen(document.body)
    const newTransition = newIsFullScreen
      ? TRANSITIONS.FULL_SCREEN
      : TRANSITIONS.WINDOWED

    setIsHovering(false)
    setTransitionState(newTransition)
  }

  const animate = endValue => {
    Animated.timing(scaleAnimRef, {
      toValue: endValue,
      duration: DURATION_ICON_ANIMATION,
      easing: Easing.in,
    }).start()
  }

  const handleMouseOver = () => {
    if (isFullScreen) {
      animate(ICON_SHRINK)
    } else {
      animate(ICON_GROW)
    }
    setIsHovering(true)
  }

  const handleMouseLeave = () => {
    //Fixes styling issues related to "stuck" hover states when going full screen (non-Chrome).
    transitionState !== TRANSITIONS.NONE && setTransitionState(TRANSITIONS.NONE)
    animate(ICON_DEFAULT_SCALE)
    setIsHovering(false)
  }

  const getTooltipText = () => {
    const tooltipHasNoText =
      transitionState !== TRANSITIONS.NONE &&
      transitionState !== TRANSITIONS.FULL_SCREEN
    return tooltipHasNoText
      ? ''
      : isFullScreen
      ? TOOL_TIP_TEXT_EXIT_FULL_SCREEN
      : TOOL_TIP_TEXT_ENTER_FULL_SCREEN
  }

  //Fixes styling issues related to "stuck" hover states when going full screen (non-Chrome).
  !isBrowserChrome &&
    setTimeout(resetTransitionStates, DURATION_TRANSITION_STATE)

  const tooltipText = getTooltipText()

  return (
    <FullScreenButtonComponent
      isFullScreen={isFullScreen}
      isTooltipVisible={isHovering}
      onClick={onClick}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      scaleAnimRef={scaleAnimRef}
      tooltipText={tooltipText}
      transitionState={transitionState}
    />
  )
}

export default FullScreenButtonContainer
