import styled from 'styled-components/native'

import { FULL_HEIGHT } from '../constants'

const GRAB = 'grab'
const POINTER = 'pointer'

export const Frame = styled.View.attrs(({ isDragging }) => ({
  cursor: isDragging ? GRAB : POINTER, //ensures the cursor stays a grab hand when the pointer drifts away from the handle
}))`
  cursor: ${props => props.cursor};
  display: flex;
  height: ${props => props.height}px;
  justify-content: center;
  min-height: 300px;
  min-width: 400px;
  width: ${props => props.width}px;

  //Disable text selection
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
`

export const ImageContainer = styled.View.attrs({
  height: FULL_HEIGHT,
})`
  height: ${props => props.height};
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
`

export const ErrorMessageText = styled.Text`
  color: white;
  text-align: center;
`

export const ErrorMessageTextContainer = styled.View`
  padding: 1rem;
`

export const ErrorMessageContainer = styled.View.attrs(
  ({
    theme: {
      colorVars: { imageJuxtaposition: colors },
    },
    width,
  }) => ({
    backgroundColor: colors.frame.background,
    height: FULL_HEIGHT,
    width,
  }),
)`
  align-items: center;
  background-color: ${props => props.backgroundColor};
  flex-direction: row;
  height: ${props => props.height};
  width: ${props => props.width}px;
`
