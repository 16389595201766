/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

const NotificationsIcon = () => (
  <svg height="22px" viewBox="0 0 17 22" width="17px">
    <title>Notifications</title>
    <g
      fill="none"
      fillRule="evenodd"
      id="notifications"
      stroke="none"
      strokeWidth="1"
    >
      <g fill="currentColor" id="notification-icon">
        <path
          d="M5.71982,18.3249375 C5.62037,18.3249375 5.53622,18.4086625 5.542595,18.5081125 C5.63737,19.9484375 6.83502,21.0874375 8.299145,21.0874375 C9.763695,21.0874375 10.961345,19.9484375 11.05612,18.5081125 C11.06207,18.4086625 10.97877,18.3249375 10.87932,18.3249375 L5.71982,18.3249375 Z"
          id="Fill-1"
        />
        <path
          d="M15.975835,13.7665575 C15.720835,13.5294075 15.42376,13.3526075 15.132635,13.1690075 C14.704235,12.8987075 14.43946,12.5196075 14.347235,12.0244825 C14.10371,10.7137825 13.864435,9.4026575 13.62431,8.0911075 C13.49171,7.3681825 13.38546,6.6388825 13.107085,5.9520825 C12.46746,4.3710825 11.459785,3.2167825 9.91746,2.6859575 L9.91746,1.6183575 C9.91746,0.7245825 9.192835,-4.24999985e-05 8.299485,-4.24999985e-05 C7.40571,-4.24999985e-05 6.681085,0.7245825 6.681085,1.6183575 L6.681085,2.6749075 C6.301135,2.8066575 5.939035,2.9830325 5.60031,3.2150825 C4.31511,4.0956825 3.56116,5.3464575 3.23646,6.8369325 C2.89901,8.3856325 2.623185,9.9492075 2.37286,11.5149075 C2.24026,12.3449325 1.923635,13.0007075 1.14546,13.3827825 C1.07916,13.4150825 1.02051,13.4635325 0.958885,13.5060325 C0.76891,13.6373575 0.58531,13.7793075 0.447185,13.9654575 C-0.005015,14.5732075 -0.147815,15.2425825 0.175185,15.9429825 C0.488835,16.6208575 1.06131,16.9166575 1.80676,16.9098575 C2.87181,16.9009325 3.937285,16.9124075 5.002335,16.9030575 L11.600885,16.9039075 C12.159335,16.9136825 12.717785,16.9077325 13.276235,16.9077325 C13.828735,16.9077325 14.38166,16.9153825 14.934585,16.9056075 C15.58696,16.8941325 16.07911,16.5940825 16.394885,16.0245825 C16.785035,15.3220575 16.597185,14.3458325 15.975835,13.7665575"
          id="Fill-3"
        />
      </g>
    </g>
  </svg>
)

export default NotificationsIcon
