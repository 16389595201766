/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'
import PropTypes from 'prop-types'

const MeetingIcon = ({ height = 30, width = 50, dataTestId }) => {
  return (
    <svg
      className="meeting-icon"
      data-testid={dataTestId}
      height={height}
      viewBox="0 0 50 30"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <polygon
          id="path-1"
          points="0 0.061387203 13.8506181 0.061387203 13.8506181 8.90990655 0 8.90990655"
        />
        <polygon
          id="path-3"
          points="0.0757430065 0.0494790671 13.9203333 0.0494790671 13.9203333 8.91666667 0.0757430065 8.91666667"
        />
      </defs>
      <g
        fill="none"
        fillRule="evenodd"
        id="SELA_meeting_icon"
        stroke="none"
        strokeWidth="1"
      >
        <g id="Group-15">
          <path
            d="M24.994625,1.7149196 C21.4095417,1.71950833 18.6247917,4.51550833 18.6320275,8.10334167 C18.6390417,11.6085917 21.4699583,14.3898417 25.021875,14.3812781 C28.5007083,14.3728417 31.2940417,11.5640083 31.292459,8.07584167 C31.290875,4.518175 28.510875,1.710425 24.994625,1.7149196 M16.921182,8.094675 C16.9099583,3.60075833 20.503375,0.001175 25.0020417,8.08923422e-06 C29.432375,-0.001075 33.000375,3.603925 33.0018754,8.08275833 C33.0032917,12.499925 29.438625,16.065675 25.0139583,16.0729364 C20.5232917,16.0802583 16.9324583,12.5402583 16.921182,8.094675"
            fill="currentColor"
            id="Fill-1"
          />
          <path
            d="M36.1188083,29.1084 C35.6415583,29.1155667 35.3460583,28.8524833 35.2861417,28.3672333 C34.7426417,23.9639 31.654225,20.4694833 27.3800583,19.4220667 C21.634225,18.0139833 15.6711417,21.9597333 14.721725,27.7985667 C14.6906417,27.9899 14.6636417,28.1819 14.6383083,28.3740667 C14.5705583,28.89065 14.1968917,29.1725667 13.6733083,29.0999833 C13.2143083,29.0364 12.947725,28.6637333 13.0050583,28.1658167 C13.633725,22.7074833 17.8553917,18.34565 23.2690583,17.5609833 C29.754725,16.6209833 35.870225,21.1224 36.9238917,27.6183167 C36.967475,27.8870667 36.9903917,28.1738167 36.945475,28.4389833 C36.8720583,28.8719 36.5511417,29.1019 36.1188083,29.1084"
            fill="currentColor"
            id="Fill-3"
          />
          <path
            d="M13.6917633,13.1576333 C13.7018917,10.66405 11.688475,8.61938333 9.20080833,8.5968 C6.67514167,8.57396667 4.59630833,10.6163 4.58652471,13.1299667 C4.57689167,15.6297167 6.65305833,17.6989667 9.17330833,17.7016359 C11.6338083,17.7043 13.6816417,15.6448833 13.6917633,13.1576333 M2.89838807,13.1163833 C2.894725,9.68821667 5.69805833,6.90305 9.160225,6.895368 C12.5743083,6.8878 15.3908083,9.69313333 15.3928928,13.1033833 C15.394975,16.56705 12.6175583,19.3838 9.194475,19.3897263 C5.695725,19.3957167 2.902225,16.6123 2.89838807,13.1163833"
            fill="currentColor"
            id="Fill-5"
          />
          <path
            d="M40.7900333,8.59521667 C38.3443667,8.55296667 36.2846167,10.5637167 36.2307833,13.0458833 C36.1767,15.5436333 38.2072,17.6673833 40.68195,17.7013833 C43.20345,17.73605 45.2850333,15.7213833 45.3372833,13.1958 C45.38895,10.7008 43.3513667,8.63938333 40.7900333,8.59521667 M40.72195,19.3896567 C37.2815333,19.3803 34.5172,16.5509667 34.5325525,13.0548833 C34.5476167,9.6733 37.3697833,6.8863 40.76995,6.89536119 C44.2290333,6.90463333 47.0317833,9.69496667 47.025876,13.1233833 C47.0198667,16.6161333 44.2202833,19.3989667 40.72195,19.3896567"
            fill="currentColor"
            id="Fill-7"
          />
          <g id="Group-11" transform="translate(0.000000, 20.198083)">
            <mask fill="white" id="mask-2">
              <use xlinkHref="#path-1" />
            </mask>
            <g id="Clip-10" />
            <path
              d="M0.000275,7.96223333 C0.176608333,6.71581667 0.629108333,5.49156667 1.34844167,4.36798333 C3.91885833,0.3529 8.98869167,-1.07235 13.293775,1.0224 C13.4949417,1.12023333 13.6788583,1.34798333 13.7683583,1.55881667 C13.901525,1.87215 13.8914417,2.21715 13.6001917,2.47631667 C13.2998583,2.74356667 12.9801083,2.75173333 12.598775,2.61806667 C11.7673583,2.32648333 10.936275,1.97431667 10.074775,1.83831667 C6.21094167,1.22823333 2.35519167,4.08823333 1.743025,7.95581667 C1.63644167,8.62906667 1.29844167,8.95648333 0.763525,8.90456667 C0.285358333,8.85815 -0.0105583333,8.52348333 0.000275,7.96223333"
              fill="currentColor"
              id="Fill-9"
            />
          </g>
          <g id="Group-14" transform="translate(36.000000, 20.198083)">
            <mask fill="white" id="mask-4">
              <use xlinkHref="#path-3" />
            </mask>
            <g id="Clip-13" />
            <path
              d="M4.7692,0.0500833333 C9.32045,0.117666667 13.04495,3.26175 13.8911167,7.75058333 C14.0058667,8.35916667 13.7780333,8.80091667 13.30045,8.89633333 C12.7497,9.00633333 12.3001167,8.66275 12.1925333,8.04966667 C11.5153667,4.1895 8.18078333,1.5375 4.27528333,1.76908333 C3.28361667,1.82783333 2.34578333,2.11733333 1.4512,2.54733333 C0.87495,2.82441667 0.379533333,2.71441667 0.15695,2.26391667 C-0.0648,1.815 0.182283333,1.26175 0.7192,1.005 C1.96286667,0.4105 3.56695,0.03225 4.7692,0.0500833333"
              fill="currentColor"
              id="Fill-12"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

MeetingIcon.propTypes = {
  dataTestId: PropTypes.string.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
}

export default MeetingIcon
