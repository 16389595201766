import React, { Fragment } from 'react'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Provider } from 'react-redux'

import { useInitApp } from '../../../hooks/initApp'
import GlobalStyles from '../../../styles/GlobalStyles'
import ThemeProvider from '../../../styles/ThemeProvider'
import CFCPlayer from '../../CFCPlayer'
import HttpErrorMessage from '../../HttpErrorMessage'
import PlayerErrorBoundary from '../../PlayerErrorBoundary'

const AppWrapperContainer = () => {
  const { initialStore, error } = useInitApp()

  if (!initialStore) {
    return <Fragment />
  }

  const getContentComponent = () => {
    if (error) {
      const { data, status } = error || {}
      const { message } = data || {}

      return <HttpErrorMessage message={message} statusCode={status} />
    }

    return (
      <DndProvider backend={HTML5Backend}>
        <GlobalStyles />
        <PlayerErrorBoundary>
          <CFCPlayer />
        </PlayerErrorBoundary>
      </DndProvider>
    )
  }

  const contentComponent = getContentComponent()

  return (
    <Provider store={initialStore}>
      <ThemeProvider>{contentComponent}</ThemeProvider>
    </Provider>
  )
}

export default AppWrapperContainer
