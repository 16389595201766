import React, { Component } from 'react'

import PropTypes from 'prop-types'

import { EMPTY_STRING } from '../constants'

import ImageJuxtRightImageComponent from './component'

class ImageJuxtRightImageContainer extends Component {
  constructor(props) {
    super(props)

    this.state = { isError: false }
  }

  onError = () => this.setState({ isError: true })

  getRenderProps = () => {
    const { alt, errorMessageTextWidth, errorMessageWidth, source } = this.props
    const { isError } = this.state || {}
    const showError = isError || !source || source === EMPTY_STRING
    const rightImageProps = {
      alt,
      errorMessageTextWidth,
      errorMessageWidth,
      onError: this.onError,
      showError,
      source,
    }

    return {
      rightImageProps,
    }
  }

  render = () => {
    const { rightImageProps } = this.getRenderProps()

    return <ImageJuxtRightImageComponent {...rightImageProps} />
  }
}

ImageJuxtRightImageContainer.propTypes = {
  alt: PropTypes.string,
  errorMessageTextWidth: PropTypes.number,
  errorMessageWidth: PropTypes.number,
  source: PropTypes.string,
}

ImageJuxtRightImageContainer.defaultProps = {
  errorMessageTextWidth: 0,
  errorMessageWidth: 0,
}

export default ImageJuxtRightImageContainer
