// This should be eliminated in favor of themes for each grade..
import { fontNames } from './GlobalStyles'

export const colors = {
  babyBlue: '#3C6E90',
  black: '#000',
  blackWithOpacity: 'rgb(0, 0, 0, 0.4)',
  blue: '#4DA8EF',
  brand: '#FF4B21',
  darkBlue: '#4F738F',
  mediumGrey: '#757575',
  darkerBlue: '#062f4f',
  darkerGrey: '#535353',
  darkestBlue: '#002044',
  darkestGrey: '#363636',
  darkGreen: '#57B25A',
  darkGrey: '#666666',
  darkRed: '#C8171D',
  darkYellow: '#CC841E',
  disabled: '#bdbdbd',
  exitLessonDarkBlue: '#002044',
  exitLessonLightBlue: '#3C6E90',
  flipCardTurnIndicator: '#3a3939',
  grey: '#666666',
  gray91: '#e8e8e8',
  gray92: '#ced8df',
  gray93: '#999999',
  gray94: '#555555',
  doneSlideActiveBackground: '#008A20',
  doneSlideHoverBackground: '#006F1A',
  hotspotsActiveBackground: '#CCC',
  lessonCompleteGrey: '#555',
  lightBlack: '#7E7E7E',
  lightBlue: '#9FD5FD',
  lighterGrey: '#CCCCCC',
  lightestBlue: '#9FD5FD',
  lightGreen: '#4ED474',
  lightGrey: '#888',
  lightYellow: '#FAD7A7',
  lightestGrey: '#e8e8e8',
  lightWhite: '#CED8DF',
  orange: '#E1523D',
  teal: '#14aba1',
  transparent: 'transparent',
  white: '#FFFFFF',
  yellow: '#F4AD49',
  lightTeal: '#04817C',
  lighterTeal: '#e4f3f3',
  borderGray: '#949494',
}

export const borderRadius = '0.15rem'

const fontFamilies = {
  body: `${fontNames.MoldeMedium}, sans-serif`,
}

export const fonts = {
  body: {
    fontFamily: fontFamilies.body,
    fontSize: '1.2rem',
  },
}
