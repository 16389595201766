import React, { lazy, Suspense } from 'react'

import VideoButtonDefault from 'assets/images/videoPlayButtonDefault.svg'
import VideoButtonHover from 'assets/images/videoPlayButtonHover.svg'
import { Logger } from 'logging'
import PropTypes from 'prop-types'
import { TranscriptComponent, VideoV3 } from 'secondstepVideo'

import { DATA_TEST_ID_VIDEO_WRAPPER, WRAPPER_CLASSES } from './constants'
import MenuComponent from './menuComponent'
import { OuterWrapper, TranscriptOuterWrapper, Wrapper } from './style'

const ErrorBoundary = lazy(() => import('utils/importSwap/errorBoundary'))

const VideoComponent = ({
  audioDescriptionTranscript,
  canShowAccessibilityMenu,
  canShowAudioDescriptionButton,
  canShowTranscriptButton,
  hasVideoSource,
  onEnded,
  onPlay,
  onStopped,
  videoUrl,
  tracks,
  settings,
  poster,
  showTranscript,
  transcript,
  transcriptRendered,
  handleAudioMouseOver,
  handleAudioMouseOut,
  isHovering,
  isAudioHovering,
  handleTranscriptClick,
  isTranscriptActive,
  isAudioDescActive,
  menuBarRenderedForEnglish,
  vtts,
  canShowAudioDesc,
  handleClickAudioDesc,
}) => {
  const appInsightInstance = window.appInsight

  return (
    <OuterWrapper>
      {canShowAccessibilityMenu && menuBarRenderedForEnglish && (
        <MenuComponent
          canShowAudioDescriptionButton={canShowAudioDescriptionButton}
          canShowTranscriptButton={canShowTranscriptButton}
          showTranscript={showTranscript}
          isHovering={isHovering}
          isAudioHovering={isAudioHovering}
          handleTranscriptClick={handleTranscriptClick}
          isTranscriptActive={isTranscriptActive}
          isAudioDescActive={isAudioDescActive}
          transcriptRendered={transcriptRendered}
          canShowAudioDesc={canShowAudioDesc}
          handleClickAudioDesc={handleClickAudioDesc}
          handleAudioMouseOver={handleAudioMouseOver}
          handleAudioMouseOut={handleAudioMouseOut}
        />
      )}
      <Wrapper
        canShowAccessibilityMenu={canShowAccessibilityMenu}
        className={WRAPPER_CLASSES}
        data-testid={DATA_TEST_ID_VIDEO_WRAPPER}
        menuBarRenderedForEnglish={menuBarRenderedForEnglish}
      >
        {hasVideoSource && (
          <Suspense fallback={<div>Loading...</div>}>
            <VideoV3
              appInsightInstance={appInsightInstance}
              isAudioDescActive={isAudioDescActive}
              logger={Logger}
              onEnded={onEnded}
              onPlay={onPlay}
              onStopped={onStopped}
              poster={poster}
              settings={settings}
              tracks={tracks}
              videoUrl={videoUrl}
              transcript={transcript}
              vtts={vtts}
              styles={{
                bigPlayButton: {
                  default: VideoButtonDefault,
                  hover: VideoButtonHover,
                },
              }}
            />
          </Suspense>
        )}
        {transcriptRendered && (
          <TranscriptOuterWrapper data-testid={'transcript-outer-wrapper'}>
            <Suspense fallback={<p>Please wait&hellip;</p>}>
              <ErrorBoundary message="Problem loading video transcript">
                <TranscriptComponent
                  transcript={transcript}
                  isAudioDescActive={isAudioDescActive}
                  audioDescriptionTranscript={audioDescriptionTranscript}
                ></TranscriptComponent>
              </ErrorBoundary>
            </Suspense>
          </TranscriptOuterWrapper>
        )}
      </Wrapper>
    </OuterWrapper>
  )
}

VideoComponent.propTypes = {
  appInsightInstance: PropTypes.object,
  audioDescriptionTranscript: PropTypes.string,
  canShowAccessibilityMenu: PropTypes.bool,
  canShowAudioDescriptionButton: PropTypes.bool,
  canShowTranscriptButton: PropTypes.bool,
  handleAudioMouseOut: PropTypes.func,
  handleAudioMouseOver: PropTypes.func,
  handleMouseOut: PropTypes.func,
  handleMouseOver: PropTypes.func,
  hasVideoSource: PropTypes.bool,
  isAudioHovering: PropTypes.bool,
  isHovering: PropTypes.bool,
  menuBarRenderedForEnglish: PropTypes.bool,
  onEnded: PropTypes.func,
  onPlay: PropTypes.func,
  onStopped: PropTypes.func,
  videoComponentRef: PropTypes.object,
  videoUrl: PropTypes.string,
  tracks: PropTypes.array,
  settings: PropTypes.object,
  transcript: PropTypes.string,
  transcriptRendered: PropTypes.bool,
  poster: PropTypes.string,
  showTranscript: PropTypes.func,
  handleTranscriptClick: PropTypes.func,
  isTranscriptActive: PropTypes.bool,
  isAudioDescActive: PropTypes.bool,
  vtts: PropTypes.object,
  canShowAudioDesc: PropTypes.bool,
  handleClickAudioDesc: PropTypes.func,
}

VideoComponent.defaultProps = {
  vtts: {},
  canShowAudioDesc: false,
}

export default VideoComponent
