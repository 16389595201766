import React, { useRef } from 'react'

import PropTypes from 'prop-types'
import { Animated } from 'react-native-web'

import {
  ALT_TEXT_ENTER_FULL_SCREEN,
  ALT_TEXT_EXIT_FULL_SCREEN,
  DATA_TEST_ID,
  DATA_TEST_ID_TOOLTIP,
  TRANSITIONS,
} from './constants'
import { enterFullScreenSvg } from './enterFullScreenSvg'
import { exitFullScreenSvg } from './exitFullScreenSvg'
import { Button, Tooltip } from './style'

const FullScreenButtonComponent = ({
  onMouseOver,
  isFullScreen,
  isTooltipVisible,
  onClick,
  onMouseLeave,
  scaleAnimRef,
  tooltipText,
  transitionState,
}) => {
  const buttonRef = useRef(null)
  const altText = isFullScreen
    ? ALT_TEXT_EXIT_FULL_SCREEN
    : ALT_TEXT_ENTER_FULL_SCREEN
  const svg = isFullScreen ? exitFullScreenSvg() : enterFullScreenSvg()

  return (
    <Button
      alt={altText}
      data-testid={DATA_TEST_ID}
      isFullScreen={isFullScreen}
      onClick={onClick}
      onKeyPress={onClick}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      ref={buttonRef}
      tabIndex={0}
      transitionState={transitionState}
      aria-label={altText}
      role="button"
    >
      <Animated.View
        style={{
          transform: [{ scaleX: scaleAnimRef }, { scaleY: scaleAnimRef }],
        }}
        aria-hidden="true"
      >
        {svg}
      </Animated.View>
      <Tooltip data-testid={DATA_TEST_ID_TOOLTIP} visible={isTooltipVisible}>
        {tooltipText}
      </Tooltip>
    </Button>
  )
}

FullScreenButtonComponent.propTypes = {
  isFullScreen: PropTypes.bool,
  isTooltipVisible: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onMouseOver: PropTypes.func,
  scaleAnimRef: PropTypes.object,
  tooltipText: PropTypes.string,
  transitionState: PropTypes.string,
}

FullScreenButtonComponent.defaultProps = {
  onClick() {},
  onMouseLeave() {},
  onMouseOver() {},
  scaleAnimRef: {},
  tooltipText: '',
  transitionState: TRANSITIONS.NONE,
}

export default FullScreenButtonComponent
