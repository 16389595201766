import { LOCALES } from 'utils/locale'

export function hydrateCmi(data) {
  return {
    type: 'HYDRATE_CMI',
    data,
  }
}

export function updateCmiCompletionStatus(completionStatus) {
  return {
    type: 'UPDATE_CMI_COMPLETION_STATUS',
    completionStatus,
  }
}

export function updateCmiInteractives({ interactives, locale }) {
  return {
    type: 'UPDATE_CMI_INTERACTIVES',
    interactives,
    locale,
  }
}

export function updateCmiLmsSessionTime(durationIso, lmsTerminator) {
  return {
    type: 'UPDATE_CMI_LMS_SESSION_TIME',
    sessionTime: durationIso,
    lmsTerminator,
  }
}

export function updateCmiLocale(locale = LOCALES.EN_US) {
  return {
    type: 'UPDATE_CMI_LOCALE',
    locale,
  }
}

export function updateCmiProgress(progressMeasure) {
  return {
    type: 'UPDATE_CMI_PROGRESS',
    progressMeasure,
  }
}

export function updateCmiSlides(slides) {
  return {
    type: 'UPDATE_CMI_SLIDES',
    slides,
  }
}

export function updateCompletionStatus(completionStatus, isLessonDone) {
  return {
    type: 'UPDATE_LMS_COMPLETION_STATUS',
    completionStatus,
    isLessonDone,
  }
}

export function updateLMSLocation(slidePosition) {
  return {
    type: 'UPDATE_LMS_LOCATION',
    slidePosition,
  }
}

export function updateLMSSuspendData() {
  return {
    type: 'UPDATE_LMS_SUSPEND_DATA',
  }
}

// Note that dialog cards are a special case here due to there needing to be 1-3
// interactives on one slide and they all need to be able to control slide completion state.
// See LEARN-825
export function updateDialogCardLMSSuspendData(interactives) {
  return {
    type: 'UPDATE_CMI_DIALOG_CARD',
    interactives,
  }
}

export function updateLMSProgressMeasure() {
  return {
    type: 'UPDATE_LMS_PROGRESS_MEASURE',
  }
}

export function updateSlides(slidePosition) {
  return {
    type: 'UPDATE_CMI_LOCATION',
    slidePosition,
  }
}
