import React, { useEffect, useState } from 'react'
import { Collapsible } from 'grommet'
import PropTypes from 'prop-types'

import {
  AccordionContent,
  AccordionContentWrapper,
  AccordionPanel,
  AccordionStatus,
  AccordionTitleWrapper,
  AccordionTitle,
  AccordionWrapper,
  StyledCaret,
} from './component.styles'

const callAllOnClicks = (...fns) => (...args) =>
  fns.forEach(fn => fn && fn(...args))

const Accordion = ({
  accordionPanelDataTestId,
  ariaLabel,
  children,
  className,
  contentPadding,
  dataTestId,
  followActiveProp,
  fontColor,
  iconColor,
  isActive,
  onClick,
  panelColor,
  status,
  title,
}) => {
  const [active, setActive] = useState(isActive)
  const [isKeyboardFocused, setIsKeyboardFocused] = useState(false)
  const [isMouseFocused, setIsMouseFocused] = useState(false)
  const toggleAccordion = () => {
    setActive(prevState => {
      return !prevState
    })
  }
  const label = typeof title === 'string' ? title : ariaLabel
  const wrapperClasses = isKeyboardFocused
    ? `${className} keyboard-focus`
    : className
  const caretClasses = isKeyboardFocused
    ? active
      ? 'rotate keyboard-focus'
      : 'keyboard-focus'
    : active
    ? 'rotate'
    : ''

  useEffect(() => {
    if (followActiveProp) setActive(isActive)
  }, [isActive])

  const setFocus = () => {
    if (isMouseFocused) return
    setIsKeyboardFocused(true)
  }
  const setBlur = () => {
    setIsKeyboardFocused(false)
    setIsMouseFocused(false)
  }
  const setKeyDown = () => {
    setIsMouseFocused(false)
    setIsKeyboardFocused(true)
  }
  const setMouseDown = () => {
    setIsMouseFocused(true)
    setIsKeyboardFocused(false)
  }

  return (
    <AccordionWrapper
      className={wrapperClasses}
      dataTestId={dataTestId}
      panelColor={panelColor}
    >
      <AccordionPanel
        aria-label={label}
        dataTestId={accordionPanelDataTestId}
        onBlur={setBlur}
        onClick={callAllOnClicks(toggleAccordion, onClick)}
        onFocus={setFocus}
        onKeyDown={setKeyDown}
        onMouseDown={setMouseDown}
        panelColor={panelColor}
      >
        <AccordionTitleWrapper>
          {/* iconcolor must be passed lowercase to avoid React warning */}
          <StyledCaret className={caretClasses} iconcolor={iconColor} />
          <AccordionTitle fontColor={fontColor}>{title}</AccordionTitle>
        </AccordionTitleWrapper>
        {status && <AccordionStatus>{status}</AccordionStatus>}
      </AccordionPanel>
      <AccordionContentWrapper>
        <Collapsible open={active}>
          <AccordionContent contentPadding={contentPadding}>
            {children}
          </AccordionContent>
        </Collapsible>
      </AccordionContentWrapper>
    </AccordionWrapper>
  )
}
Accordion.propTypes = {
  accordionPanelDataTestId: PropTypes.string,
  ariaLabel: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  contentPadding: PropTypes.string,
  dataTestId: PropTypes.string,
  followActiveProp: PropTypes.bool,
  fontColor: PropTypes.string,
  iconColor: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  panelColor: PropTypes.string,
  status: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default Accordion
