import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Button } from 'grommet'

export const CollapsibleButtonStyled = styled(Button).attrs(
  ({ dataTestId }) => ({
    'data-testid': dataTestId,
    plain: true,
  }),
)`
  align-items: center;
  color: ${themeGet('colors.buttons.iconBase')};
  display: flex;
  font-family: ${themeGet('fontFamilySemibold')};
  justify-content: center;
  padding-top: 1.5rem;
  padding-bottom: 0.9375rem;
  &:hover {
    color: ${themeGet('colors.buttons.iconHover')};
  }
  svg {
    margin-bottom: 2px;
    margin-right: 0.5rem;
    transform: ${props => (props.isOpen ? 'rotate(-180deg)' : 'rotate(0deg)')};
    transition: transform 0.3s;
    width: ${props => (props.small ? '1rem' : 'auto')};
  }
`
