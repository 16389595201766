import React from 'react'

import PropTypes from 'prop-types'

import DragLabelNode from '../DragLabelNode'

import { Column, Container } from './style'

const COLUMN = 'column'
const DROP_ZONE_DATA_TEST_ID_PREFIX = 'origin-drop-zone-'
const ORIGIN_NODES_DATA_TEST_ID = 'origin-nodes-container'
const ROWS_PER_COLUMN = 3

const DragLabelOriginNodesComponent = ({
  draggables,
  longLabels,
  numOfColumns,
  onDragEnd,
  onDragStart,
}) => {
  const getColumn = columnNumber => {
    const originNodeComponentsForColumn = []
    const firstNodeInColumnIndex = columnNumber * ROWS_PER_COLUMN
    const lastNodeInColumnIndex = firstNodeInColumnIndex + ROWS_PER_COLUMN

    for (
      let nodeIndex = firstNodeInColumnIndex;
      nodeIndex < lastNodeInColumnIndex;
      nodeIndex++
    ) {
      const draggableAtIndex = draggables[nodeIndex]

      if (!draggableAtIndex) {
        return null
      }

      const { id, label } = draggableAtIndex || {}
      const key = id || Math.random(1000000)
      const showDraggable = id != null
      const showDropZone = id == null
      const dropZoneDataTestId = `${DROP_ZONE_DATA_TEST_ID_PREFIX}${nodeIndex}`

      originNodeComponentsForColumn.push(
        <DragLabelNode
          canDrop={false}
          draggableId={id}
          draggableIndex={nodeIndex}
          dropZoneBorder={false}
          dropZoneDataTestId={dropZoneDataTestId}
          isLong={longLabels}
          key={key}
          label={label}
          onDragEnd={onDragEnd}
          onDragStart={onDragStart}
          showDraggable={showDraggable}
          showDropZone={showDropZone}
        />,
      )
    }

    return originNodeComponentsForColumn
  }

  const getOriginNodeComponents = () => {
    const originNodeComponents = []

    for (let columnIndex = 0; columnIndex < numOfColumns; columnIndex++) {
      const key = `${COLUMN}${columnIndex}`
      const columnComponent = getColumn(columnIndex)

      originNodeComponents.push(
        <Column key={key} longLabels={longLabels} numOfColumns={numOfColumns}>
          {columnComponent}
        </Column>,
      )
    }

    return originNodeComponents
  }

  const draggableComponents = getOriginNodeComponents()

  return (
    <Container data-testid={ORIGIN_NODES_DATA_TEST_ID}>
      {draggableComponents}
    </Container>
  )
}

DragLabelOriginNodesComponent.propTypes = {
  draggables: PropTypes.arrayOf(PropTypes.object),
  longLabels: PropTypes.bool,
  numOfColumns: PropTypes.number,
  onDragEnd: PropTypes.func,
  onDragStart: PropTypes.func,
}

DragLabelOriginNodesComponent.defaultProps = {
  draggables: [],
  numOfColumns: 2,
  onDragEnd() {},
  onDragStart() {},
}

export default DragLabelOriginNodesComponent
