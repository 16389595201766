import React, { Component } from 'react'

import PropTypes from 'prop-types'

import { colors } from '../../../../styles/styleGuide'
import { getElementKey } from '../../utils'

import ImageWithTitleComponent from './component'

const WHITE = colors.white
const DEFAULT_BACKGROUND_COLOR = `${WHITE};`

class ImageWithTitleContainer extends Component {
  getPropsForElementAtIndex = (elementModules, index) => {
    const { onSubmit } = this.props
    //Make the keys unique so it doesn't try to use same components and not run lifecycle methods
    const randomNumber = Math.round(Math.random() * 1000000)
    const key = `${getElementKey(index)} ${randomNumber}`
    const layoutElementProps = {
      accessibilityLabel: key,
      modules: elementModules,
      onSubmit,
    }

    return { key, layoutElementProps }
  }

  getRenderProps = () => {
    const { elements, title } = this.props
    const getElementProps = this.getPropsForElementAtIndex
    const imageWithTitleProps = {
      elements,
      getElementProps,
      title,
    }

    return imageWithTitleProps
  }

  render = () => {
    const imageWithTitleProps = this.getRenderProps() || {}

    return <ImageWithTitleComponent {...imageWithTitleProps} />
  }
}

ImageWithTitleContainer.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
  onSubmit: PropTypes.func,
  title: PropTypes.string,
}

ImageWithTitleContainer.defaultProps = {
  elements: [],
  onSubmit() {},
  slideBackgroundColor: DEFAULT_BACKGROUND_COLOR,
}

export default ImageWithTitleContainer
