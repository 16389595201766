import PropTypes from 'prop-types'
import React from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { Text } from 'grommet'
import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(localizedFormat)

const CreationDate = ({ className, createdDate, dataTestId }) => {
  const formattedCreatedDate = dayjs(createdDate).format('LL')

  return (
    <Text className={className} data-testid={dataTestId} size="small">
      Created: {formattedCreatedDate}
    </Text>
  )
}

CreationDate.propTypes = {
  className: PropTypes.string.isRequired,
  createdDate: PropTypes.string.isRequired,
  dataTestId: PropTypes.string.isRequired,
}

export default styled(CreationDate)``
