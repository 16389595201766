import React, { Component } from 'react'

import PropTypes from 'prop-types'

import { getImageModuleDimensions } from '../../../../utils/contentfulHelper'
import AspectRatioWrapper from '../../../AspectRatioWrapper'
import LayoutElement from '../../../LayoutElement'
import LayoutTitle from '../../../LayoutTitle'

import {
  Container,
  Element,
  ElementsContainer,
  ElementTitleContainer,
} from './style'

const ELEMENTS_ACCESSIBILITY_LABEL = 'Layout elements container'
const LAYOUT_ACCESSIBILITY_LABEL = 'Image with title layout'

class ImageWithTitleComponent extends Component {
  getElementComponent = (elementModules, index, width, height) => {
    const { getElementProps } = this.props
    const { key, layoutElementProps } =
      getElementProps(elementModules, index) || {}

    return (
      <AspectRatioWrapper
        aspectRatioX={width}
        aspectRatioY={height}
        key={key}
        render={({ height, width }) => (
          <Element height={height} width={width}>
            <LayoutElement {...layoutElementProps} />
          </Element>
        )}
      />
    )
  }

  getElementComponents = () => {
    const { elements } = this.props
    const firstElement = elements[0] || []
    const imageModule = firstElement[0] || {}
    const { width, height } = getImageModuleDimensions(imageModule) || {}
    const elementComponents = elements.map((elementModules, index) =>
      this.getElementComponent(elementModules, index, width, height),
    )

    return elementComponents
  }

  getRenderProps = () => {
    const { title } = this.props
    const layoutElements = this.getElementComponents()
    const containerProps = {
      accessibilityLabel: LAYOUT_ACCESSIBILITY_LABEL,
    }
    const elementsContainerProps = {
      accessibilityLabel: ELEMENTS_ACCESSIBILITY_LABEL,
    }
    const layoutTitleProps = {
      title,
    }
    const renderProps = {
      containerProps,
      elementsContainerProps,
      layoutElements,
      layoutTitleProps,
    }

    return renderProps
  }

  render = () => {
    const {
      containerProps,
      elementsContainerProps,
      layoutElements,
      layoutTitleProps,
    } = this.getRenderProps() || {}

    return (
      <Container {...containerProps}>
        <ElementsContainer {...elementsContainerProps}>
          <ElementTitleContainer>
            <LayoutTitle {...layoutTitleProps} />
          </ElementTitleContainer>
          {layoutElements}
        </ElementsContainer>
      </Container>
    )
  }
}

ImageWithTitleComponent.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
  getElementProps: PropTypes.func,
  title: PropTypes.string,
}

ImageWithTitleComponent.defaultProps = {
  elements: [],
  getElementProps() {},
  title: '',
}

export default ImageWithTitleComponent
