/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

export default () => (
  <svg
    height="16px"
    version="1.1"
    viewBox="0 0 22 16"
    width="22px"
    x="0px"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
  >
    <g transform="translate(-290.000000, -601.000000)">
      <g transform="translate(40.000000, 398.000000)">
        <g transform="translate(27.000000, 137.547350)">
          <g
            id="decision-making-icon"
            transform="translate(223.000000, 65.452650)"
          >
            <defs>
              <filter
                filterUnits="userSpaceOnUse"
                height="15.6"
                width="22"
                x="0"
                y="0"
              >
                <feColorMatrix
                  type="matrix"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                />
              </filter>
            </defs>
            <mask
              height="15.6"
              maskUnits="userSpaceOnUse"
              width="22"
              x="0"
              y="0"
            >
              <g className="st0">
                <polygon className="st1" points="0,0 22,0 22,15.6 0,15.6 							" />
              </g>
            </mask>
            <path
              className="st2"
              d="M5.8,3.1l0.5,0.3c2,1,3.5,2.7,4.5,4.9c0.1,0.2,0.1,0.3,0.2,0.5L11.1,9
						c0.1,0.2,0.1,0.4,0.2,0.5l0,0.1c0.1,0.4,0.3,0.8,0.4,1.2c0.4,1.5,0.5,2.9,0.6,3.6l-2.5,0C9.7,12.7,9,7.6,5.1,5.6L4.6,5.4
						l-1,1.9L1.4,2.4l5.3-1L5.8,3.1z M21.9,1.9c0-0.1-0.1-0.2-0.2-0.2L15,0.4c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2,0,0.3l1,1.9
						c-1.7,0.9-3.1,2.3-4.2,4.1C10.4,5,9,3.6,7.4,2.6l0.8-1.5c0.1-0.3,0.1-0.6-0.1-0.8C7.9,0.1,7.6,0,7.3,0L0.6,1.3
						c-0.2,0-0.4,0.2-0.5,0.4C0,1.9,0,2.2,0.1,2.4l2.9,6.2C3,8.8,3.3,9,3.6,9c0.3,0,0.5-0.1,0.7-0.4L5.1,7c1.1,0.7,3.2,2.8,3.5,7.8
						c0,0.5,0.4,0.9,0.9,0.9l3,0c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.2,0-0.6-0.1-1.1c0.3-2,1.2-5.6,4-7.2l1,2
						c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2-0.1,0.3-0.2l2.9-6.2C22,2.1,22,2,21.9,1.9L21.9,1.9z"
              fill="currentColor"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
