import React from 'react'

const VideoPlayButtonIcon = () => (
  <svg
    height="60px"
    version="1.1"
    viewBox="0 0 60 60"
    width="60px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Play Video</title>
    <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
      <g transform="translate(-1258.000000, -495.000000)">
        <g transform="translate(916.000000, 299.000000)">
          <g transform="translate(342.000000, 196.000000)">
            <circle cx="30" cy="30" fill="currentColor" r="30" />
            <path
              d="M33.8837879,22.1693772 L42.222883,37.9210012 C42.4812902,38.4091038 42.2950854,39.0142696 41.8069828,39.2726768 C41.6628316,39.3489922 41.5022012,39.3888889 41.3390951,39.3888889 L24.6609049,39.3888889 C24.1086202,39.3888889 23.6609049,38.9411736 23.6609049,38.3888889 C23.6609049,38.2257828 23.7008017,38.0651524 23.777117,37.9210012 L32.1162121,22.1693772 C32.3746193,21.6812746 32.9797851,21.4950697 33.4678877,21.753477 C33.6450785,21.8472839 33.789981,21.9921864 33.8837879,22.1693772 Z"
              fill="#FFFFFF"
              stroke="#FFFFFF"
              strokeWidth="2"
              transform="translate(33.000000, 29.944444) rotate(-270.000000) translate(-33.000000, -29.944444) "
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default VideoPlayButtonIcon
