import styled from 'styled-components'
import Select from 'react-select'
import { themeGet } from 'styled-system'
import IconCaretSelect from '../IconCaretSelect'
import CheckIcon from '../CheckIcon'

export const IconStyled = styled(IconCaretSelect).attrs(({ theme }) => ({
  color: theme.select.colors.primary,
  rotation: 180,
}))``

export const CheckIconStyled = styled(CheckIcon).attrs(({ theme }) => ({
  color: 'currentColor',
}))`
  float: right;
  margin-top: 0.5rem;
`

export const StyledSelect = styled(Select).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  color: ${themeGet('select.colors.primary')};
  font-family: ${themeGet('select.font')};

  svg {
    color: ${themeGet('select.colors.primary')};
    padding-right: ${themeGet('spacing.green4_0_25rem')};
  }

  [class*='control'] {
    border-radius: 1.5625rem;
    border: 0;
    box-shadow: none;
  }

  .css-1pahdxg-control:nth-last-child(2) {
    opacity: 0.75;
  }

  [class*='singleValue'] {
    color: inherit;
    font-family: inherit;
    padding-left: ${themeGet('spacing.green4_0_25rem')};
    margin: 0px;
  }

  [class*='option'] {
    color: inherit;
    font-family: inherit;
    background-color: ${themeGet('select.colors.secondary')};

    &:hover {
      color: ${themeGet('select.colors.secondary')};
      background: ${themeGet('select.colors.primary')};
      svg {
        color: ${themeGet('select.colors.secondary')};
      }
    }
  }
`
