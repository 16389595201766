import styled from 'styled-components'

export const Wrapper = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  :hover {
    // This controls background of the radio. Grommet theming is broken for this currently
    label > div > div {
      background-color: #bacdd9;
    }
  }
`
