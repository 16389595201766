import { createGlobalStyle } from 'styled-components'

export const fontNames = {
  MoldeMedium: 'Molde-Medium',
}

export default createGlobalStyle`
  html,
  body {
    font-family: '${fontNames.MoldeMedium}', sans-serif;
    margin: 0;
    padding: 0;
    height: 100%;
  }

  #root {
    height: 100%;
  }

  button, h1, h2, h3, h4, h5, h6 {
    font-size: 1em;
  }
`
