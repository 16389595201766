import styled from 'styled-components'

export const ImageContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex: 0 0 50%;
  height: 100%;
  justify-content: center;
  padding: 1em;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`
