/* istanbul ignore file */
import React from 'react'

const IconDots = () => (
  <svg
    width="202px"
    height="20px"
    preserveAspectRatio="none"
    viewBox="0 0 202 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Dots</title>
    <g
      id="Dots"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeDasharray="0,5"
      strokeLinecap="round"
    >
      <line
        x1="8"
        y1="10"
        x2="197"
        y2="10"
        id="Line-3"
        stroke="#3C6E90"
        strokeWidth="2"
      />
    </g>
  </svg>
)

export default IconDots
