/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'
import PropTypes from 'prop-types'

const IncompleteIcon = ({ size }) => {
  return (
    <svg height={size} viewBox={`0 0 23 23`} width={size}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <circle
          cx="11.5"
          cy="11.5"
          r="10.5"
          stroke="currentColor"
          strokeWidth="2"
        />
      </g>
    </svg>
  )
}

IncompleteIcon.propTypes = {
  size: PropTypes.string,
}

IncompleteIcon.defaultProps = {
  size: '1.4375rem',
}

export default IncompleteIcon
