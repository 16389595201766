import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { Animated } from 'react-native-web'

import {
  isActivationKey,
  isMovementKey,
} from '../../../utils/keyboardControlHelper'
import JuxtHandleSVG from '../assets/imageJuxtHandle.svg'
import JuxtHandleDraggingSVG from '../assets/imageJuxtHandleDragging.svg'
import { HALF_OF_DIVIDER_WIDTH } from '../constants'

import ImageJuxtHandleComponent from './component'
import { INITIAL_HANDLE_RADIUS } from './style'

const ANIMATION_DURATION = 200
const HANDLE_HOVER_ANIM_VALUE_START = 0
const HANDLE_HOVER_ANIM_VALUE_END = 0.1

class ImageJuxtHandleContainer extends Component {
  constructor(props) {
    super(props)

    this.handleSize = new Animated.Value(HANDLE_HOVER_ANIM_VALUE_START)
    this.state = {
      isDragging: false,
      isHovering: false,
      isMovingDraggableWithKeyboard: false,
    }
  }

  componentDidUpdate() {
    const { dragDetectorRef } = this
    //https://stackoverflow.com/questions/30096038/react-native-getting-the-position-of-an-element
    dragDetectorRef &&
      dragDetectorRef.measure((fx, fy, width, height, px) => {
        //TODO: May want to put this in state instead of making it a property of this.....
        this.px = px
        this.fx = fx
      })
  }

  animate = (toValue, duration) => {
    Animated.timing(this.handleSize, {
      toValue,
      duration,
    }).start()
  }

  scaleUp = () => this.animate(HANDLE_HOVER_ANIM_VALUE_END, ANIMATION_DURATION)

  scaleDown = () =>
    this.animate(HANDLE_HOVER_ANIM_VALUE_START, ANIMATION_DURATION)

  onMouseEnter = () => {
    this.setState({ isHovering: true })
    this.scaleUp()
  }

  onMouseLeave = () => {
    const { isDragging } = this.state || {}

    this.setState({ isHovering: false })
    !isDragging && this.scaleDown()
  }

  onDragStart = () => {
    const { onDragStart } = this.props

    this.setState({ isDragging: true }, onDragStart)
  }

  onDragEnd = () => {
    const { onDragEnd } = this.props
    const { isHovering } = this.state || {}

    this.setState({ isDragging: false }, () => {
      !isHovering && this.scaleDown()
      onDragEnd()
    })
  }

  activateKeyboardControls = () => {
    this.setState({ isMovingDraggableWithKeyboard: true })
  }

  deactivateKeyboardControls = () => {
    this.setState({ isMovingDraggableWithKeyboard: false })
  }

  onDragWithMouse = e => {
    const { frameWidth, onDrag, pageXOffset, scale } = this.props
    const { isDragging: isDraggingWithMouse } = this.state || {}
    const scaledFrameWidth = frameWidth * scale

    isDraggingWithMouse && onDrag(e, pageXOffset, scaledFrameWidth)
  }

  onDragWithKeyboard = e => {
    const { px } = this
    const { frameWidth, onKeyDown, pageXOffset, scale } = this.props
    const { key } = e || {}
    const { isMovingDraggableWithKeyboard } = this.state || {}
    const isDraggingWithKeyboard =
      isMovementKey(key) && isMovingDraggableWithKeyboard

    const isActivating = isActivationKey(key)
    if (isActivating) {
      isMovingDraggableWithKeyboard
        ? this.deactivateKeyboardControls()
        : this.activateKeyboardControls()
    } else if (isDraggingWithKeyboard) {
      const scaledFrameWidth = frameWidth * scale
      const scaledHandleRadius = INITIAL_HANDLE_RADIUS * scale
      const scaledBorderOffset = HALF_OF_DIVIDER_WIDTH * scale
      const centerOfHandlePageX = px + scaledHandleRadius + scaledBorderOffset

      onKeyDown(e, pageXOffset, scaledFrameWidth, centerOfHandlePageX)
    }
  }

  render = () => {
    const { frameWidth, position } = this.props
    const { isDragging, isHovering } = this.state || {}
    const svg = isDragging ? JuxtHandleDraggingSVG : JuxtHandleSVG
    const frameDidLoad = frameWidth > 0

    return frameDidLoad ? (
      <ImageJuxtHandleComponent
        dragDetectorRef={view => (this.dragDetectorRef = view)}
        isDragging={isDragging}
        isHovering={isHovering}
        onDrag={this.onDragWithMouse}
        onDragEnd={this.onDragEnd}
        onDragStart={this.onDragStart}
        onKeyDown={this.onDragWithKeyboard}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        position={position}
        size={this.handleSize}
        svg={svg}
      />
    ) : null
  }
}

ImageJuxtHandleContainer.propTypes = {
  frameWidth: PropTypes.number,
  onDrag: PropTypes.func,
  onDragEnd: PropTypes.func,
  onDragStart: PropTypes.func,
  onKeyDown: PropTypes.func,
  pageXOffset: PropTypes.number,
  position: PropTypes.number,
  scale: PropTypes.number,
}

ImageJuxtHandleContainer.defaultProps = {
  frameWidth: 0,
  onDrag() {},
  onDragEnd() {},
  onDragStart() {},
  onKeyDown() {},
  pageXOffset: 0,
  position: 0,
  scale: 1,
}

export default ImageJuxtHandleContainer
