import React from 'react'

import FocusTrap from 'focus-trap-react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import { connect } from 'react-redux'

import { toggleModal } from '../../lib/state/actions/player'
import {
  getElementsForLayout,
  getHeroDataForLayout,
  getHeroImageModuleDimensions,
} from '../../utils/contentfulHelper'
import ModalConfirmBox from '../ConfirmationModal'

import ModalComponent from './component'
import { DEFAULT_ASPECT_RATIO, MODAL_ROOT } from './constants'
import {
  ModalBox,
  ModalBoxImageTall,
  ModalBoxImageWide,
  ModalBoxTextImage,
  ModalBoxTextOnly,
} from './style'

const getisModalJson = modalData =>
  modalData && !React.isValidElement(modalData)

const getAspectRatio = dimensions => {
  const { height, width } = dimensions || {}
  const aspectRatio = height && width ? width / height : DEFAULT_ASPECT_RATIO

  return aspectRatio
}

export function getModalType(modalData) {
  if (!modalData) {
    return ModalBoxTextOnly
  }

  if (!getisModalJson(modalData)) {
    return ModalBox
  }

  // Image ratios = wide: 5000x1250 | tall: 5000x3000
  const imageTallRatio = 1.7
  const heroData = getHeroDataForLayout(modalData)
  const { url: hasHero } = heroData || {}
  const elements = getElementsForLayout(modalData) || []
  const column1 = elements[0]
  const isImageOnly = hasHero && !column1
  const isImageAndText = hasHero && column1

  if (isImageOnly) {
    const dimensions = getHeroImageModuleDimensions(modalData)
    const aspectRatio = getAspectRatio(dimensions)
    const isWide = aspectRatio > imageTallRatio
    const modalBox = isWide ? ModalBoxImageWide : ModalBoxImageTall

    return modalBox
  } else if (isImageAndText) {
    return ModalBoxTextImage
  } else {
    return ModalBoxTextOnly
  }
}

const ModalContainer = ({
  toggleModal,
  modal,
  hasPortal,
  hasModalCompletion,
}) => {
  const modalType = getModalType(modal)
  const isModalJson = getisModalJson(modal)
  const isConfirmationModal = modal && hasPortal && hasModalCompletion
  const modalComponent = isConfirmationModal ? (
    <ModalConfirmBox closeModal={toggleModal} />
  ) : (
    modal
  )

  const modalProps = {
    closeModal: toggleModal,
    isCloseButtonWithBorder: isConfirmationModal ? false : isModalJson,
    isModalJson: isConfirmationModal ? false : isModalJson,
    modal: modalComponent,
    ModalType: isConfirmationModal ? ModalBox : modalType,
  }
  const Modal = () =>
    hasPortal ? (
      <FocusTrap>
        <div>
          <ModalComponent {...modalProps} />
        </div>
      </FocusTrap>
    ) : (
      <ModalComponent {...modalProps} />
    )
  if (hasPortal) {
    return createPortal(<Modal />, document.getElementById(MODAL_ROOT))
  }
  return <Modal />
}

ModalContainer.propTypes = {
  modal: PropTypes.object,
  toggleModal: PropTypes.func,
  hasPortal: PropTypes.bool,
  hasModalCompletion: PropTypes.bool,
}

ModalContainer.defaultProps = {
  toggleModal() {},
}

const mapDispatchToProps = dispatch => ({
  toggleModal: () => dispatch(toggleModal()),
})

const mapStateToProps = ({ player }) => {
  if (player && player.modal) {
    const { metadata } = player.modal
    return {
      modal: player.modal,
      hasPortal: metadata?.hasPortal || false,
      hasModalCompletion: metadata?.hasModalCompletion || false,
    }
  } else {
    return {}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer)
