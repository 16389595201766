import React from 'react'

import PropTypes from 'prop-types'

import FullScreenButton from '../FullScreenButton'

import LanguageToggle from './LanguageToggle'
import MarkDoneButton from './MarkDoneButton'
import {
  Brand,
  FullScreenButtonContainer,
  MarkDoneButtonContainer,
  Wrapper,
} from './style'
import Title from './Title'

const TitleBarComponent = () => (
  <Wrapper>
    <Brand />
    <Title />
    <LanguageToggle />
    <MarkDoneButtonContainer>
      <MarkDoneButton />
    </MarkDoneButtonContainer>
    <FullScreenButtonContainer>
      <FullScreenButton />
    </FullScreenButtonContainer>
  </Wrapper>
)

TitleBarComponent.propTypes = {
  close: PropTypes.func,
}

export default TitleBarComponent
