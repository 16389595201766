import React, { useEffect, useState } from 'react'

import { backoff } from '@cfc/exponential-backoff'

import Ajax from '../../../lib/Ajax'
import hydrate from '../../../lib/APIConnector/hydrate'
import rehydrateRedux from '../../../lib/APIConnector/rehydrateRedux'
import { CONTENT_FUNCTION_URL, IS_CONNECTED_TO_API } from '../../../lib/config'
import { hydrateCmi } from '../../../lib/state/actions/cmi'
import {
  addTheme,
  initializePlayer,
  togglePrepMode,
} from '../../../lib/state/actions/player'
import { cmiLaunchDataSelector } from '../../../lib/state/selectors'
import configureStore from '../../../lib/state/store'
import { getBoolFromString } from '../../../utils/boolHelper'
import { getPlayerSessionTimeManager } from '../../../utils/sessionHelper'

import AppWrapperComponent from './component'
import {
  CACHE,
  DEFAULT_THEME,
  ENTRY,
  ENTRY_ID_PARAM_ERROR,
  ENVIRONMENT,
  FETCH_PRESENTATION_ERROR,
  FETCH_PRESENTATION_ERROR_UNPUBLISHED,
  FORMAT,
  INCLUDE,
  LOAD_PRESENTATION_ERROR,
  MOCK_DATA,
  PREVIEW,
  PREVIEW_PARAM_ERROR,
  SUCCESS,
  THEME,
  TOKEN,
} from './constants'

const AppWrapperContainer = () => {
  const [initialStore, setInitialStore] = useState()
  const [error, setError] = useState()

  useEffect(() => {
    prepareStore()
  }, [])

  const prepareStore = async () => {
    let initialStore = configureStore()

    await hydrateStore(initialStore)
    initialStore = await populateStoreWithParams(initialStore)

    setInitialStore(initialStore)
  }

  const hydrateStore = async store => {
    const hydratedData = await hydrate()
    //Hydrate the store with data from the api and feature flags
    hydratedData && (await store.dispatch(hydrateCmi(hydratedData)))
  }

  const getParams = (urlParams, store) => {
    const contentfulId = getEntryId(urlParams, store)
    const themeId = getTheme(urlParams, store)
    const preview = getPreviewParam(urlParams)
    const token = getTokenParam(urlParams)
    const cache = getCacheParam(urlParams)
    const paramsObject = {
      contentfulId,
      preview,
      themeId,
      token,
      cache,
    }

    return paramsObject
  }

  const getEntryId = (urlParams, store) => {
    const storeState = store.getState() || {}
    const launchData = cmiLaunchDataSelector(storeState) || {}
    const { entry } = launchData || {}
    const hasEntryId =
      urlParams && typeof urlParams === 'object' && urlParams.has(ENTRY)
    const entryId = hasEntryId ? urlParams.get(ENTRY) : entry

    return entryId
  }

  const getTheme = (urlParams, store) => {
    const storeState = store.getState() || {}
    const launchData = cmiLaunchDataSelector(storeState) || {}
    const paramsTheme = urlParams.get(THEME)
    const { theme: launchDataTheme } = launchData || {}
    const themeId = paramsTheme || launchDataTheme || DEFAULT_THEME

    return themeId
  }

  // Returns a string of either 'true' or 'false'
  const getPreviewParam = urlParams => {
    const hasPreview = urlParams.has(PREVIEW)
    const preview = hasPreview
      ? urlParams.get(PREVIEW)?.toLowerCase() === 'true'
        ? 'true'
        : 'false'
      : 'false'

    return preview
  }

  const getTokenParam = urlParams => {
    return urlParams.get(TOKEN)
  }

  const getCacheParam = urlParams => {
    return urlParams.get(CACHE)
  }

  const loadPresentationJson = async (contentfulId, preview, token, cache) => {
    const ajaxUrl = new URL(CONTENT_FUNCTION_URL)

    contentfulId && ajaxUrl.searchParams.append('entryId', contentfulId)
    // This is only needed in preview mode
    preview &&
      ajaxUrl.searchParams.append(ENVIRONMENT, 'preview.contentful.com')
    // Tell the api to not format our data
    ajaxUrl.searchParams.append(FORMAT, false)
    // Get all the levels of data
    ajaxUrl.searchParams.append(INCLUDE, 10)

    if (cache) {
      ajaxUrl.searchParams.append(CACHE, cache)
    }

    const url = ajaxUrl.toString()

    try {
      return await backoff(() => Ajax.get(url, { token }))
    } catch (error) {
      const errorMessage = !preview
        ? FETCH_PRESENTATION_ERROR_UNPUBLISHED
        : FETCH_PRESENTATION_ERROR
      console.error(errorMessage)
    }
  }

  const populateStoreWithParams = async store => {
    const { search } = location || {}
    const params = search && new URLSearchParams(search)

    if (!search || search === '') {
      console.error(PREVIEW_PARAM_ERROR)

      return
    }

    const {
      contentfulId,
      preview: previewString,
      themeId,
      token,
      cache,
      isPrepMode,
    } = getParams(params, store) || {}

    if (!contentfulId) {
      console.error(ENTRY_ID_PARAM_ERROR)

      return
    }

    const previewBool = getBoolFromString(previewString)

    if (previewBool === null) {
      console.error(PREVIEW_PARAM_ERROR)

      return
    }

    const hasParams = Object.keys(params).length > 0
    const hasMockData = params.has(MOCK_DATA)
    const useMockData = hasMockData || (!hasParams && !contentfulId)
    let presentationJson

    if (!useMockData) {
      presentationJson = await loadPresentationJson(
        contentfulId,
        previewBool,
        token,
        cache,
      )
    }

    await store.dispatch(addTheme(themeId))

    store = await setPresentation(presentationJson, store, isPrepMode)

    return store
  }

  const setPresentation = async (presentationJson, store, isPrepMode) => {
    const { data: presentation, status } = presentationJson || {}
    const didFail = status !== SUCCESS

    if (didFail) {
      console.error(LOAD_PRESENTATION_ERROR)

      setError(presentationJson)
      return store
    }

    await store.dispatch(togglePrepMode(isPrepMode))
    await store.dispatch(initializePlayer(presentation))
    await rehydrateRedux(store)

    IS_CONNECTED_TO_API && getPlayerSessionTimeManager(store)

    return store
  }

  return <AppWrapperComponent error={error} store={initialStore} />
}

export default AppWrapperContainer
