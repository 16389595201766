import React from 'react'
import PropTypes from 'prop-types'
import { StyledProgressBarContainer } from './component.styles'
import ProgressBarIndicator from '../ProgressBarIndicator'

const ProgressBarMedium = ({ progressPercent = 0, ...rest }) => {
  const value = Math.ceil(progressPercent * 100)

  return (
    <StyledProgressBarContainer>
      <ProgressBarIndicator
        isValueInsideBar={false}
        percentComplete={value}
        shouldRenderIndicator
        shouldRenderTextProgress
        {...rest}
      />
    </StyledProgressBarContainer>
  )
}

ProgressBarMedium.propTypes = {
  progressPercent: PropTypes.number,
}

ProgressBarMedium.displayName = 'ProgressBarMedium'

export default ProgressBarMedium
