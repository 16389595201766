/* istanbul ignore file */
// we don't unit test SVGs
import React from 'react'
import PropTypes from 'prop-types'

const SuccessIcon = ({ dataTestId, className }) => {
  return (
    <svg
      className={className}
      data-testid={dataTestId}
      height="70"
      viewBox="0 0 70 70"
      width="70"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-348 -336)">
          <g transform="translate(146 260)">
            <g transform="translate(202 76)">
              <circle cx="35" cy="35" fill="#4ED474" r="35" />
              <path
                d="M18 35.5013296L30.7311476 49 52 24"
                stroke="#FFF"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="7"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

SuccessIcon.propTypes = {
  className: PropTypes.object,
  dataTestId: PropTypes.string.isRequired,
}

export default SuccessIcon
