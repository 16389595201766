import { LOCALES } from 'utils/locale'

export const SLIDE_STATUS = {
  NOT_VIEWED: 0,
  VIEWED: 1,
  COMPLETED: 2,
}
export const LMS_TERMINATORS = {
  BEACON: 'beacon',
  API_CONNECTOR: 'apiConnector',
  NONE: 'none',
}

export const PLAYER_MULTIMEDIA_STATUS = {
  ENDED: 'ENDED',
  STOPPED: 'STOPPED',
  PLAYING: 'PLAYING',
}

export const DONE_SLIDE_ID = 'done-slide'

export const DONE_SECTION_SLIDE = {
  [LOCALES.ES_US]: {
    name: 'Completa',
    title: 'Completa',
  },
  [LOCALES.EN_US]: {
    name: 'Done',
    title: 'Done',
  },
  slides: [
    {
      metadata: {
        tags: [],
      },
      sys: {
        type: 'Entry',
        id: DONE_SLIDE_ID,
        contentType: {
          sys: {
            type: 'Link',
            linkType: 'ContentType',
            id: DONE_SLIDE_ID,
          },
        },
        locale: 'en-US',
      },
      fields: {
        title: '',
        layout: {
          metadata: {
            tags: [],
            hasPortal: true,
            hasModalCompletion: true,
          },
          sys: {
            type: 'Entry',
            id: '3N4YCA3rf6p4KyEY4geNSu',
            contentType: {
              sys: {
                type: 'Link',
                linkType: 'ContentType',
                id: 'layout',
              },
            },
            locale: 'en-US',
          },
          fields: {
            internalTitle: 'G7 Layout: Wrap-Up 2: Take Action 7-1a',
            header: '',
            column1: [
              {
                metadata: {
                  tags: [],
                },
                sys: {
                  type: 'Entry',
                  id: '45YH7k0b3YKXXwdaIrrZZu',
                  contentType: {
                    sys: {
                      type: 'Link',
                      linkType: 'ContentType',
                      id: 'layout',
                    },
                  },
                  locale: 'en-US',
                },
                fields: {
                  modalContent: {
                    metadata: {
                      tags: [],
                    },
                    sys: {
                      type: 'Entry',
                      id: '72VHPa9PmO0TyMfxaW86nz',
                      contentType: {
                        sys: {
                          type: 'Link',
                          linkType: 'ContentType',
                          id: 'layoutModal',
                        },
                      },
                      locale: 'en-US',
                    },
                    fields: {
                      header: 'Mark this lesson done?',
                      column1: [
                        {
                          metadata: {
                            tags: [],
                          },
                          sys: {
                            type: 'Entry',
                            id: '6NFdu0lMfmLk3QgEhJgRYc',
                            contentType: {
                              sys: {
                                type: 'Link',
                                linkType: 'ContentType',
                                id: 'text',
                              },
                            },
                            locale: 'en-US',
                          },
                          fields: {
                            internalTitle: '',
                            header: '',
                            column1: [
                              {
                                metadata: {
                                  tags: [],
                                },
                                sys: {
                                  type: 'Entry',
                                  id: '6NFdu0lMfmLk3QgEhJgRYc',
                                  contentType: {
                                    sys: {
                                      type: 'Link',
                                      linkType: 'ContentType',
                                      id: 'text',
                                    },
                                  },
                                  locale: 'en-US',
                                },
                                fields: {
                                  internalTitle: '',
                                  content: '',
                                },
                              },
                            ],
                          },
                        },
                        {
                          metadata: {
                            tags: [],
                          },
                          sys: {
                            type: 'Entry',
                            id: '45YH7k0b3YKXXwdaIrrZZu',
                            contentType: {
                              sys: {
                                type: 'Link',
                                linkType: 'ContentType',
                                id: 'button',
                              },
                            },
                            locale: 'en-US',
                          },
                          fields: {
                            Text: 'Cancel',
                          },
                        },
                        {
                          metadata: {
                            tags: [],
                          },
                          sys: {
                            type: 'Entry',
                            id: '45YH7k0b3YKXXwdaIrrZZu',
                            contentType: {
                              sys: {
                                type: 'Link',
                                linkType: 'ContentType',
                                id: 'LinkButton',
                              },
                            },
                            locale: 'en-US',
                          },
                          fields: {
                            buttonText: '',
                          },
                        },
                      ],
                    },
                  },
                },
              },
              {
                metadata: {
                  tags: [],
                },
                sys: {
                  type: 'Entry',
                  id: '4xr5RrZHVxZUYeiZLato4',
                  contentType: {
                    sys: {
                      type: 'Link',
                      linkType: 'ContentType',
                      id: 'text',
                    },
                  },
                  locale: 'en-US',
                },
                fields: {
                  internalTitle: 'g71a-text',
                  content: '',
                },
              },
              {
                metadata: {
                  tags: [],
                },
                sys: {
                  type: 'Entry',
                  id: '45YH7k0b3YKXXwdaIrrZZu',
                  contentType: {
                    sys: {
                      type: 'Link',
                      linkType: 'ContentType',
                      id: 'LinkButton',
                    },
                  },
                  locale: 'en-US',
                },
                fields: {
                  Text: '',
                },
              },
            ],
          },
        },
      },
    },
  ],
}

export const DONE_SLIDE_CONTENT_STRINGS = {
  default: {
    [LOCALES.EN_US]: {
      title: 'Lesson Complete!',
      body:
        'What did you think of this lesson? We’d love to get your feedback in a quick survey.',
      button: 'Take Survey',
    },
    [LOCALES.ES_US]: {
      title: '¡Lección completa!',
      body:
        '¿Qué pensaste de esta lección? Nos gustaría saber tus comentarios en una encuesta rápida.',
      button: 'Haz la encuesta',
    },
  },
  MD: {
    [LOCALES.EN_US]: {
      title: 'Lesson Complete!',
      body:
        'What did you think of this lesson? We’d love to get your feedback in a quick survey.',
      button: 'Take Survey',
    },
    [LOCALES.ES_US]: {
      title: '¡Lección completa!',
      body:
        '¿Qué pensaste de esta lección? Nos gustaría saber tus comentarios en una encuesta rápida.',
      button: 'Haz la encuesta',
    },
  },
  KG: {
    [LOCALES.EN_US]: {
      title: 'Nice Work!',
      body:
        'What did you think of this lesson? We’d love to get your feedback in a quick survey.',
      button: 'Take Survey',
    },
    [LOCALES.ES_US]: {
      title: '¡Buen trabajo!',
      body:
        '¿Qué pensaste de esta lección? Nos gustaría saber tus comentarios en una encuesta rápida.',
      button: 'Haz la encuesta',
    },
  },
}
