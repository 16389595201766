import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1em;

  button span {
    font-size: 0.85em;
    width: 4.25em;
    height: 35px;
    margin: auto;
    text-align: center;
    text-transform: uppercase;
  }

  & > *:not(:first-child) {
    margin-left: 0.6em;
  }
`
