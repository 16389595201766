import {
  HS_THEME,
  MODE,
  PREVIEW,
  THEME,
} from '../components/AppWrapper/constants'
import { getModeParams } from '../lib/url'

const MIN_HEIGHT = 0
const MIN_WIDTH = 0
const REM_PIXELS = 16

//Get the cumulative height of top and bottom chrome
const getChromeHeight = theme => {
  const { colorVars } = theme || {}
  const { menuBar, titleBar } = colorVars || {}
  const { height: menuBarHeightRems = 0 } = menuBar || {}
  const { height: titleBarHeightRems = 0 } = titleBar || {}
  const topChromeHeightPx = titleBarHeightRems * REM_PIXELS
  const bottomChromeHeightPx = menuBarHeightRems * REM_PIXELS
  const chromeHeight = topChromeHeightPx + bottomChromeHeightPx

  return chromeHeight
}

//Get the dimensions of everything inside of player chrome.
export const getPlayerContentDimensions = (theme, deviceResolutionTier) => {
  const { maxHeight: deviceMaxHeight = 0, maxWidth: deviceMaxWidth = 0 } =
    deviceResolutionTier || {}
  const chromeHeight = getChromeHeight(theme)
  let height = deviceMaxHeight - chromeHeight
  let width = deviceMaxWidth
  height = height >= MIN_HEIGHT ? height : MIN_HEIGHT
  width = width >= MIN_WIDTH ? width : MIN_WIDTH

  const playerContentDimensions = { height, width }

  return playerContentDimensions
}

export const isLMS = () => {
  const hasLMS =
    !!window.parent.__CFC_PLAYER_DATA__ &&
    !!window.parent.API_1484_11 &&
    window.location.host.includes('lms')
  window.parent.isLMS = hasLMS
  return hasLMS
}

function getParams() {
  const { search } = window.location || {}
  return new URLSearchParams(search)
}

function getModes() {
  return getModeParams(getParams())
}

export const hasParam = param => {
  const params = getParams()
  const previewParam = params.get(param) || false
  return !!previewParam
}

export const isSampleMode = () => !isLMS()
export const isPrepMode = () => isLMS() && getModes().isPrepMode
export const isFamilyMode = () => !isLMS() && getModes().isFamilyMode
export const isHighSchoolMode = () => getParams().get(THEME) === HS_THEME
export const isHighSchoolPreviewMode = () => {
  return !isLMS() && isHighSchoolMode() && getParams().get(MODE) === PREVIEW
}
export const isCurriculumPreviewMode = () =>
  !isLMS() && getModes().isCurriculumPreviewMode && !isHighSchoolPreviewMode()
