export const CURRENT_YEAR_TEXT = 'Current Year'
export const CURRENT_YEAR_TEXT_CONTENT = 'Current Year'
export const ERROR_TEXT = 'There was a problem preparing the report. Try again.'
export const HELP_TEXT = ' ' // 'What’s This?'
export const LOADING_TEXT = 'Preparing report...'
export const SEPARATOR_TEXT = 'PAST REPORTS'
export const LINK_LABEL_TEXT = 'July {0}-June {1} (CSV)'
export const LINK_FILE_NAME = 'July {0}-June {1}.csv'
export const DATA_TEST_ID = 'csv-download-menu'
export const DATA_TEST_ID_CSV_LINK = 'csv-link'
export const DATA_TEST_ID_CURRENT = 'csv-download-menu-current-year'
export const DATA_TEST_ID_SEPARATOR = 'csv-download-menu-separator'
export const DATA_TEST_ID_LINK = 'csv-download-menu-link'
export const DATA_TEST_ID_BUTTON = 'csv-download-menu-button'
export const DATA_TEST_ID_LINKS = 'csv-download-menu-links'
export const DATA_TEST_ID_INFOBOX = 'csv-download-menu-infobox'
export const DATA_TEST_ID_MESSAGES = 'csv-download-menu-messages'
export const DATA_TEST_ID_LOADING = 'csv-download-menu-loading'
export const DATA_TEST_ID_ERROR = 'csv-download-menu-error'
export const DATA_TEST_ID_HELP = 'csv-download-menu-help'

export const EVENT_CLICK = 'click'
export const EVENT_KEYDOWN = 'keydown'
export const KEY_ESCAPE = 'Escape'
export const KEY_ENTER = 'Enter'
export const KEY_ARROW_DOWN = 'ArrowDown'
export const KEY_ARROW_UP = 'ArrowUp'

export const stringFormat = (string, values) => {
  return string.replace(/\{(\d*)\}/g, (m, p) => values[+p])
}
