import styled from 'styled-components'

export const Link = styled.a.attrs(
  ({
    theme: {
      colorVars: { button },
      fonts,
    },
  }) => ({
    backgroundColor: button.enabled.background,
    borderColor: button.enabled.border,
    borderRadius: button.cornerRadius,
    borderStyle: button.borderStyle,
    borderWidth: button.borderWidth,
    color: button.enabled.color,
    dropShadow: button.hover.dropshadow,
    fontFamily: fonts.button.fontFamily,
    fontSize: fonts.button.fontSize,
    fontWeight: fonts.button.fontWeight,
    height: `${button.height}px`,
    margin: button.margin,
    width: `${button.width}px`,
    selectedColor: button.selected.color,
    selectedBackgroundColor: button.selected.background,
  }),
)`
  display: block;
  background-color: ${props => props.backgroundColor};
  border-color: ${props => props.borderColor};
  border-radius: ${props => props.borderRadius};
  border-style: ${props => props.borderStyle};
  border-width: ${props => props.borderWidth};
  color: ${props => props.color};
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  height: ${props => props.height};
  margin: ${props => props.margin};
  width: ${props => props.width};
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  :hover {
    box-shadow: ${props => props.dropShadow};
  }

  :active,
  :visited {
    background-color: ${props => props.selectedBackgroundColor};
    color: ${props => props.selectedColor};
  }
`
