import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import AutoTable from './component.jsx'

class AutoTableContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      id: _.uniqueId('autotable_'),
      sortData: { ...props.defaultSort },
      filterData: [...props.defaultFilter],
    }
  }

  onToggleSort = columnTitle => {
    const sortData = { ...this.state.sortData }
    let { direction } = sortData || {}
    direction = direction === 'asc' ? 'desc' : 'asc'
    this.setState({ sortData: { columnTitle, direction } })
  }

  updateFilter = (columnTitle, query) => {
    const filterData = [...this.state.filterData]
    let found = false
    for (let i = 0; i < filterData.length; i++) {
      if (filterData[i].columnTitle === columnTitle) {
        filterData[i].query = query
        found = true
        break
      }
    }
    if (!found) filterData.push({ columnTitle: columnTitle, query: query })
    this.setState({ filterData })
  }

  getRows = () => {
    const { filter, sort } = this.props
    const { sortData, filterData } = this.state
    const { columns, rows = [] } = this.props
    const enableFilter = filter && rows.length > 1
    const enableSort = sort && rows.length > 1
    if (rows.length === 0) return []
    let result = []
    if (enableFilter && filterData.length > 0) {
      result = _.cloneDeep(rows) // don't mutate the rows collection
      result = this.filterRows(result)
    } else {
      result = rows
    }
    if (enableSort && sortData.columnTitle) {
      const col = columns.find(c => c.title === sortData.columnTitle)
      if (!col) return result
      const sortingValue = row => this.resolveSortValue(col, row)
      result = _.orderBy(result, sortingValue, [sortData.direction])
    }
    return result || []
  }

  filterRows = rows => {
    const { filterData } = this.state
    for (let i = 0; i < filterData.length; i++) {
      rows = this.filterApply(filterData[i], rows)
    }
    return rows
  }

  filterApply = (filter, rows) => {
    if (!filter.columnTitle && !filter.query) return rows
    const { columns } = this.props
    const column = columns.find(c => c.title === filter.columnTitle)
    if (column) {
      const expression = new RegExp(RegExp.escape(filter.query), 'i')
      return _.filter(rows, row => {
        const filterValue = this.resolveFilterValue(column, row)
        const match = expression.test(filterValue)
        return match
      })
    } else console.warn(`column ${filter.columnTitle} not found`)
    return rows
  }

  resolveClassName = (className, datum) => {
    try {
      const result =
        typeof className === 'function' ? className(datum) : className
      return result || ''
    } catch (err) {
      console.error('Problem interpreting className', className, datum, err)
    }
  }

  resolveCellValue = (column, datum) => {
    try {
      const { fieldValue } = column
      const result =
        typeof fieldValue === 'function' ? fieldValue(datum) : datum[fieldValue]
      if (typeof result === 'string') return result || ''
      else return result
    } catch (err) {
      console.error('Problem interpreting fieldValue', column.title, datum, err)
    }
  }

  resolveFilterValue = (column, datum) => {
    try {
      const { filterValue } = column
      let filterResult
      if (!filterValue) {
        filterResult = this.resolveCellValue(column, datum)
      } else {
        filterResult =
          typeof filterValue === 'function'
            ? filterValue(datum)
            : datum[filterValue]
      }

      return typeof filterResult === 'string' ||
        typeof filterResult === 'number'
        ? filterResult
        : ''
    } catch (err) {
      console.error(
        'Problem interpreting filterValue',
        column.title,
        datum,
        err,
      )
    }
  }

  resolveSortValue = (column, datum) => {
    try {
      const { sortValue } = column
      if (!sortValue) return this.resolveCellValue(column, datum)

      const result =
        typeof sortValue === 'function' ? sortValue(datum) : datum[sortValue]
      if (typeof result === 'string') return result || ''
      else return result
    } catch (err) {
      console.error('Problem interpreting sortValue', column.title, datum, err)
    }
  }

  render() {
    const {
      defaultSort,
      defaultFilter,
      emptyDataPrimaryMessage,
      emptyDataSecondaryMessage,
      filter,
      sort,
      columns,
      rows,
      className,
      dark,
      handleRowClick,
      hover,
      multiColSpanValue,
      numOfColumns,
      role,
      rowClassName,
      size,
      striped,
      forwardedRef,
    } = this.props
    const { id, filterData, sortData } = this.state

    const sortedRows = this.getRows()

    return (
      <AutoTable
        className={className}
        columns={columns}
        dark={dark}
        defaultFilter={defaultFilter}
        defaultSort={defaultSort}
        emptyDataPrimaryMessage={emptyDataPrimaryMessage}
        emptyDataSecondaryMessage={emptyDataSecondaryMessage}
        filter={filter}
        filterData={filterData}
        handleRowClick={handleRowClick}
        hover={hover}
        id={id}
        multiColSpanValue={multiColSpanValue}
        numOfColumns={numOfColumns}
        onToggleSort={this.onToggleSort}
        ref={forwardedRef}
        resolveCellValue={this.resolveCellValue}
        resolveClassName={this.resolveClassName}
        role={role}
        rowClassName={rowClassName}
        rows={rows}
        size={size}
        sort={sort}
        sortData={sortData}
        sortedRows={sortedRows}
        striped={striped}
        updateFilter={this.updateFilter}
      />
    )
  }
}

AutoTableContainer.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      fieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
        .isRequired,
      sort: PropTypes.bool,
      filter: PropTypes.bool,
      sortValue: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      filterValue: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      headerClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      cellClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    }),
  ).isRequired,
  dark: PropTypes.bool,
  defaultFilter: PropTypes.arrayOf(
    PropTypes.shape({
      columnTitle: PropTypes.string.isRequired,
      query: PropTypes.string.isRequired,
    }),
  ),
  defaultSort: PropTypes.shape({
    columnTitle: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(['asc', 'desc']).isRequired,
  }),
  emptyDataPrimaryMessage: PropTypes.string,
  emptyDataSecondaryMessage: PropTypes.string,
  filter: PropTypes.bool,
  forwardedRef: PropTypes.object,
  handleRowClick: PropTypes.func,
  hover: PropTypes.bool,
  multiColSpanValue: PropTypes.number,
  numOfColumns: PropTypes.number,
  role: PropTypes.string,
  rowClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rows: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ).isRequired,
  size: PropTypes.string,
  sort: PropTypes.bool,
  striped: PropTypes.bool,
}

AutoTableContainer.defaultProps = {
  rows: [],
  sort: false,
  filter: false,
  defaultSort: {
    columnTitle: '',
    direction: 'asc',
  },
  defaultFilter: [],
}

export default React.forwardRef((props, ref) => (
  <AutoTableContainer {...props} forwardedRef={ref} />
))
