import React from 'react'
import PropTypes from 'prop-types'
import { SubtleDetails, BigWarning, Wrapper } from './styles'

// React doesn't have a way to handle componentDidCatch with hooks
class ErrorBoundary extends React.Component {
  static propTypes = {
    appInsights: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    error: PropTypes.object,
    history: PropTypes.object,
    isLicenseAdmin: PropTypes.bool,
    isSetupAdmin: PropTypes.bool,
    licensesLoaded: PropTypes.bool,
    logger: PropTypes.object,
    message: PropTypes.string,
    showDetails: PropTypes.bool,
    suppress: PropTypes.bool,
  }
  static defaultProps = {
    suppress: false,
    showDetails: true,
  }
  static getDerivedStateFromError = error => {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error }
  }

  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch = (error, info) => {
    console.error(error, info)
    if (this.props.logger) {
      this.props.logger.logException({
        error,
        exception: error,
        severityLevel: 'Error',
        properties: { error },
      })
    }
    this.setState({ error: error, info: info })
  }

  render() {
    const { hasError, error, info } = this.state
    const { licensesLoaded, isSetupAdmin, isLicenseAdmin } = this.props
    const {
      message = 'We are sorry, but something went wrong.',
      suppress,
      showDetails = true,
    } = this.props

    if (hasError) {
      if (suppress) return null
      return (
        <div className="container">
          {/* TODO: ErrorMessage is specifically for retryable HTTP errors; otherwise <ErrorMessage message={message} /> */}
          <Wrapper>
            <BigWarning dataTestId="icon-warning" />
            <div>
              {message}
              {showDetails && (
                <SubtleDetails>
                  <summary>Advanced details</summary>
                  <pre>
                    {error && error.toString()}
                    <br />
                    {info && info.componentStack}
                  </pre>
                </SubtleDetails>
              )}
            </div>
          </Wrapper>
        </div>
      )
    }

    // access error - from secondstep-admin ErrorBoundary container.js file
    if (licensesLoaded && !isSetupAdmin && !isLicenseAdmin) {
      return (
        <div className="container">
          You are not authorized to view this page.
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
