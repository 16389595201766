import React from 'react'

import PropTypes from 'prop-types'

import { StyledButton } from './style'

const Button = props => {
  const { children, disabled, onClick, visited } = props
  const buttonProps = {
    onClick: disabled ? () => {} : onClick,
    visited,
  }

  return <StyledButton {...buttonProps}>{children}</StyledButton>
}

Button.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  visited: PropTypes.bool,
}

Button.defaultProps = {
  onClick() {},
}

export default Button
