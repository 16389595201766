/* istanbul ignore file */
// visual logic only
import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const ModalBackdropLight = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${themeGet('colors.backgroundModalLight')};
  z-index: 1000;
`
export const ModalBackdropDark = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${themeGet('colors.backgroundModalDark')};
  z-index: 1000;
`

export const ModalBackdropContainer = styled.div`
  margin: calc(35vh) auto;
  width: 32rem;
  height: 36rem;
`
