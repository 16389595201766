import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Button } from 'grommet'

export const Wrapper = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  position: relative;
`

export const InputWrapper = styled.div`
  position: relative;
`

export const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  right: 0.75rem;
  color: ${themeGet('colors.gray1200')};
  z-index: 4;
`

export const SearchInput = styled.input.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  position: relative;
  height: 2.688rem;
  line-height: 2.688rem;
  vertical-align: middle;
  background-color: ${themeGet('colors.white')};
  border: 1.5px solid ${themeGet('colors.blueGrayMedium')};
  box-sizing: border-box;
  font-size: inherit;
  font-family: inherit;
  appearance: none;
  color: inherit;
  width: 100%;
  padding: 0.25rem 2.5rem 0 1rem;
  margin: 0px;
  border-radius: 4px;
  z-index: 3;

  &:focus,
  &:focus-visible {
    outline: none;
    border-color: ${themeGet('colors.darkBlue')};
  }
`

export const DropdownButton = styled(Button).attrs(
  ({ dataTestId, isOpen }) => ({
    'data-testid': dataTestId,
    degrees: `${isOpen ? 180 : 0}deg`,
    id: dataTestId,
    marginBottom: `${isOpen ? '0.125rem' : '0'}`,
    marginTop: `${isOpen ? '-0.125rem' : '0'}`,
  }),
)`
  appearance: none;
  height: 2.688rem;
  background: ${themeGet('colors.white')};
  border: solid 0.125rem
    ${themeGet('colors.borders.primary', 'colors.beaconGray')};
  width: 100%;
  border-radius: 0.3125rem;
  padding: 0.8125rem 1.0625rem 0.6875rem 0.9375rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border: 1.5px solid ${themeGet('colors.blueGrayMedium')};
  padding-right: 0.9375rem;
  z-index: 3;

  span {
    padding-right: 1.125rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  svg:last-child {
    padding-bottom: 0.125rem;
    margin-top: ${({ marginTop }) => marginTop};
    margin-bottom: ${({ marginBottom }) => marginBottom};
    color: ${themeGet('colors.blue')};
    transform: rotate(${({ degrees }) => degrees});

    width: 17.5px;
    height: 9.5px;
    color: ${themeGet('colors.gray1200')};
  }

  &:focus,
  &:focus-visible {
    outline: none;
    border-color: ${themeGet('colors.darkBlue')};
  }

  ${themeGet('breakpoints.mobile')} {
    padding: 0.6875rem 0.9375rem 0.5625rem 0.8125rem;
  }
`

export const DropdownOptions = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  position: absolute;
  width: calc(100% - 2px);
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  border: 1.5px solid ${themeGet('colors.borders.primary', 'colors.beaconGray')};
  border-top: none;
  border-radius: 0 0 0.3125rem 0.3125rem;
  background-color: ${themeGet('colors.white')};
  border-color: ${themeGet('colors.blueGrayMedium')};
  overflow: auto;
  max-height: 550px;
  z-index: 4;
`

// TODO: remove dependency on grommet
export const DropdownOption = styled(Button).attrs(
  ({ dataTestId, isDefaultValue }) => ({
    'data-testid': dataTestId,
    displaySvg: `${isDefaultValue ? 'block' : 'none'}`,
  }),
)`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: ${themeGet('spacing.blue16_1rem')};
  padding-right: 0.625rem;
  cursor: pointer;
  svg {
    min-width: 10px;
    width: 10px;
    display: ${({ displaySvg }) => displaySvg};
    color: ${themeGet('colors.darkGray')};
  }
  span {
    margin-top: 0.3rem;
    margin-bottom: 0.2rem;
    color: ${themeGet('colors.darkGray')};
  }
  &:hover,
  &.arrow-selected {
    background-color: ${themeGet('colors.secondStepBlue')};
    span,
    svg {
      color: ${themeGet('colors.white')};
    }
  }
  &.no-match {
    pointer-events: none;
    background-color: inherit;
  }
`

export const DropdownOptionSeparator = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  display: flex;
  flex-direction: row;
  padding: 0 ${themeGet('spacing.blue16_1rem')};
  margin: 0.5rem 0;
  span {
    margin-right: ${themeGet('spacing.blue16_1rem')};
    margin-left: ${themeGet('spacing.blue16_1rem')};
    color: ${themeGet('colors.mediumGray')};
    font-family: ${themeGet('font.molde.regular')};
    font-size: 0.75rem;
    line-height: 0.625rem;
    opacity: 0.8;
  }

  div {
    border-top: solid 0.125rem
      ${themeGet('colors.borders.primary', 'colors.beaconGray')};
    width: 100%;
    height: 0;
    margin: auto 0;
  }
`

export const DropdownSpaceFiller = styled.div`
  position: absolute;
  width: calc(100% - 2px);
  height: 8px;
  margin-top: -0.3125rem;
  background: ${themeGet('colors.white')};
  border-left: 1.5px solid
    ${themeGet('colors.borders.primary', 'colors.beaconGray')};
  border-right: 1.5px solid
    ${themeGet('colors.borders.primary', 'colors.beaconGray')};
  border-color: ${themeGet('colors.blueGrayMedium')};
  z-index: 1;
`

export const LabelWrapper = styled.div`
  position: relative;
  display: flex;
`

export const StyledLabel = styled.div`
  color: ${themeGet('colors.darkerGray')};
  font-family: ${themeGet('fontFamilySemibold')};
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.4375rem;
  margin-right: 0.25rem;

  ${({ theme }) => theme?.breakpoints?.mobile} {
    font-size: 1rem;
  }
`
