import { BODYTEXT, EMPTY_STRING, ORIGIN_LOCATION } from './constants'

const cloneDeep = obj => JSON.parse(JSON.stringify(obj))

//Due to H5P limitiations, body text must be defined in a draggable.
//This function filters any draggable out that is not actually intended to be
//a draggable.
export const filterBodyTextFromDraggables = (draggables = []) => {
  return draggables.filter(element => {
    const { type } = element || {}
    const { metadata } = type || {}
    const { title } = metadata || EMPTY_STRING

    return title.toLowerCase() !== BODYTEXT
  })
}

//H5P does not supply id's for drop zones. It supplies id's for draggables, but deeply nested.
//Create new unique id's and append to the top level of draggable and dropzone objects.
export const createDraggablesAndDropZonesWithIds = (
  rawDraggables,
  rawDropZones,
) => {
  let dropZones = appendIdsToDropZones(rawDropZones)
  let draggables = appendIdsToDraggables(rawDraggables)
  const dropZonesIdsDictionary = getIdsDictionaryFromDropZones(dropZones)
  draggables = replaceDraggablesIndicesWithLongId(
    draggables,
    dropZonesIdsDictionary,
  )
  const draggableIdsDictionary = getIdsDictionaryFromDraggables(draggables)
  dropZones = replaceDropZoneIndicesWithLongId(
    dropZones,
    draggableIdsDictionary,
  )
  draggables = addLocationsToDraggables(draggables, ORIGIN_LOCATION)

  return { draggables, dropZones }
}

export const appendIdsToDraggables = (draggables = []) => {
  const draggablesWithIdsAppended = draggables.map(appendIdToDraggable)
  return draggablesWithIdsAppended
}

export const appendIdsToDropZones = (dropZones = []) => {
  const dropZonesWithIdsAppended = dropZones.map(appendIdToDropZone)
  return dropZonesWithIdsAppended
}

const appendIdToDraggable = draggable => {
  const { type } = draggable || {}
  const { subContentId } = type || {}
  draggable.id = subContentId

  return draggable
}

const appendIdToDropZone = dropZone => {
  if (!dropZone) {
    return {}
  }

  dropZone.id = dropZone.label

  return dropZone
}

const addLocationsToDraggables = (draggables, originLocationId) => {
  draggables = draggables.map(draggable => {
    draggable.originLocation = originLocationId
    draggable.location = originLocationId
    return draggable
  })

  return draggables
}

const getIdsArrayFromDraggables = draggables =>
  draggables && draggables.map(draggable => draggable.id)

export const getAddedDraggables = (prevDraggables, draggables) => {
  if (!prevDraggables || !draggables) {
    return []
  }

  const draggableIds = getIdsArrayFromDraggables(draggables)
  const prevDraggableIds = getIdsArrayFromDraggables(prevDraggables)
  const getNewDraggables = draggableId =>
    !prevDraggableIds.includes(draggableId)
  const addedDraggableId = draggableIds.filter(getNewDraggables)

  return addedDraggableId
}

export const getIdsDictionaryFromDraggables = (draggables = []) => {
  const draggableIdsDictionary = {}

  draggables.forEach((draggable, index) => {
    const { id: draggableId } = draggable || {}
    draggableIdsDictionary[index] = draggableId
  })

  return draggableIdsDictionary
}

export const getIdsDictionaryFromDropZones = (dropZones = []) => {
  const dropZoneIdsIndex = {}

  dropZones.forEach((dropZone, index) => {
    const { id: dropZoneId } = dropZone
    dropZoneIdsIndex[index] = dropZoneId
  })

  return dropZoneIdsIndex
}

export const replaceDraggablesIndicesWithLongId = (
  draggables = [],
  dropZoneIdsDictionary = [],
) => {
  draggables = draggables.map(draggable => {
    const { dropZones = [] } = draggable || {}
    draggable.dropZones = dropZones.map(dropZoneNumber =>
      getLongDraggableIdWithShortId(dropZoneNumber, dropZoneIdsDictionary),
    )

    return draggable
  })

  return draggables
}

const getLongDraggableIdWithShortId = (shortId, dropZoneIdsDictionary = []) => {
  const longIdFromShortId = dropZoneIdsDictionary[shortId]
  const newDropZoneId = longIdFromShortId ? longIdFromShortId : shortId

  return newDropZoneId
}

export const replaceDropZoneIndicesWithLongId = (
  dropZones = [],
  draggableIdsDictionary = [],
) => {
  dropZones = dropZones.map(dropZone => {
    const { correctElements: draggables = [] } = dropZone || {}
    dropZone.correctElements = draggables.map(draggableNumber =>
      getLongDropZoneIdWithShortId(draggableNumber, draggableIdsDictionary),
    )

    return dropZone
  })

  return dropZones
}

const getLongDropZoneIdWithShortId = (shortId, draggableIdsDictionary = []) => {
  const longIdFromShort = draggableIdsDictionary[shortId]
  const newDropZoneId = longIdFromShort ? longIdFromShort : shortId

  return newDropZoneId
}

export const moveDraggable = (
  draggableId,
  destinationDropZoneId,
  draggables,
) => {
  //Create duplicate, modify, then send to redux
  const draggableIndex = indexOfDraggableInDraggables(draggableId, draggables)
  const draggable = draggables[draggableIndex]

  if (!draggable) {
    return
  }

  const movedDraggable = cloneDeep(draggable)
  movedDraggable.location = destinationDropZoneId

  return { movedDraggable, draggableIndex }
}

const indexOfDraggableInDraggables = (id, draggables = []) => {
  let indexOfDraggable

  draggables.forEach((draggable, index) => {
    const { id: draggableId } = draggable
    indexOfDraggable = draggableId === id ? index : indexOfDraggable
  })

  return indexOfDraggable > -1 ? indexOfDraggable : -1
}

export const indexOfDropZoneInDropZones = (id, dropZones) => {
  if (!dropZones) {
    return -1
  }

  let indexOfDropZone

  dropZones.forEach((dropZone, index) => {
    const { id: dropZoneId } = dropZone
    indexOfDropZone = dropZoneId === id ? index : indexOfDropZone
  })

  return indexOfDropZone > -1 ? indexOfDropZone : -1
}

const updateDraggablePosition = (
  draggables,
  draggableId,
  destinationDropZoneId,
) => {
  const { draggableIndex, movedDraggable } = moveDraggable(
    draggableId,
    destinationDropZoneId,
    draggables,
  )
  const newDraggables = cloneDeep(draggables) //Deep copy draggables so redux sees the update
  newDraggables[draggableIndex] = movedDraggable

  return { newDraggables }
}

export const resetDraggables = draggables => {
  const resetDraggables = cloneDeep(draggables).map(draggable => {
    draggable.location = ORIGIN_LOCATION

    return draggable
  })

  return resetDraggables
}

//Preserve y order of draggables as provided by H5P
export const sortByAscendDraggablesByYIndex = (currentItem, nextItem) =>
  currentItem['y'] - nextItem['y']

//Preserve x order of drop zones as provided by H5P
export const sortByAscendDropZonesByXIndex = (currentItem, nextItem) =>
  currentItem['x'] - nextItem['x']

export const setCurrentDraggablesFieldToOriginalState = dropZone => {
  dropZone.currentDraggables = []

  return dropZone
}

export const findDraggablesThatMatchDropZoneID = (
  currentDraggables,
  draggables,
) => {
  return currentDraggables.map(draggableIDInDropZone =>
    draggables.find(draggable => draggable.id === draggableIDInDropZone),
  )
}

export const normalizeDragQuestionDataForTesting = initialData => {
  const dragQuestionSortMock = cloneDeep(initialData)
  const elements = dragQuestionSortMock.params.question.task.elements
  const rawDraggables = filterBodyTextFromDraggables(elements)
  const rawDropZones = dragQuestionSortMock.params.question.task.dropZones
  const dropZonesWithCurrentDraggableField = rawDropZones.map(
    setCurrentDraggablesFieldToOriginalState,
  )

  return createDraggablesAndDropZonesWithIds(
    rawDraggables,
    dropZonesWithCurrentDraggableField,
  )
}

export const createDropHandler = (callback, interactiveId) => {
  return (
    item,
    monitor,
    draggables = [],
    dropZones = [],
    destinationDropZoneId,
    isErrorShowing,
  ) => {
    const { id: draggableID, currentLocation: currentDraggableLocation } =
      item || {}
    const dropZonesWithDraggableIDRemoved = dropZones.map(dropZone => {
      const { id: dropZoneID } = dropZone

      if (currentDraggableLocation === dropZoneID) {
        const newDraggables = dropZone.currentDraggables.filter(
          id => id !== draggableID,
        )
        dropZone.currentDraggables = newDraggables
      }

      return dropZone
    })
    const updatedDropZones = dropZonesWithDraggableIDRemoved
    const dropZonesIndexToAddTo = dropZonesWithDraggableIDRemoved.findIndex(
      currentDropZone => destinationDropZoneId === currentDropZone.id,
    )
    const dropZoneToAddID = dropZonesWithDraggableIDRemoved.find(
      currentDropZone => destinationDropZoneId === currentDropZone.id,
    )

    //always add draggable id to end of currentDraggables
    dropZoneToAddID.currentDraggables.push(draggableID)
    updatedDropZones[dropZonesIndexToAddTo] = dropZoneToAddID

    if (monitor && monitor.isOver()) {
      const { newDraggables } = updateDraggablePosition(
        draggables,
        draggableID,
        destinationDropZoneId,
      )
      const newDraggablesAtOrigin = newDraggables.filter(
        element => element.location === ORIGIN_LOCATION,
      )
      const allDraggablesNowInDropZone = newDraggablesAtOrigin.length === 0

      callback({
        interactiveId,
        draggables: newDraggables,
        isSubmitted: false,
        showErrorMessage:
          isErrorShowing && allDraggablesNowInDropZone ? false : isErrorShowing,
        dropZones: updatedDropZones,
      })
    }
  }
}

export const isInCorrectDropZone = (dropZoneIds = [], draggableLocation) => {
  const inDropZones = dropZoneIds.indexOf(draggableLocation)
  const inCorrectDropZone = inDropZones > -1

  return inCorrectDropZone
}
