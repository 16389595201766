import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { H5P_IMG_ORIGIN } from '../../lib/config'
import {
  updateCmiInteractives,
  updateLMSSuspendData,
} from '../../lib/state/actions/cmi'
import {
  completeSlide,
  imageJuxtapositionInitialize,
  imageJuxtapositionUpdate,
} from '../../lib/state/actions/player'
import { decimalToPercent, percentToDecimal } from '../../utils/numberHelper'

import ImageJuxtComponent from './component'
import { createFrameKeyboardHandler, createFrameMouseHandler } from './utils'

const SLIDE_COMPLETION_PERCENTAGE_THRESHOLD_MAX = 90
const SLIDE_COMPLETE = 2

class ImageJuxtContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      frameWidth: 0,
      frameWidthScaled: 0,
    }

    this.initialize()
  }

  initialize = () => {
    const { id, imageJuxtapositionInitialize, shouldInitialize } = this.props

    if (shouldInitialize) {
      const startingPosition = this.getStartingPosition()

      imageJuxtapositionInitialize(id, startingPosition)
    }

    this.onDrag = createFrameMouseHandler(this.handleDrag)
    this.onKeyDown = createFrameKeyboardHandler(this.handleKeyboardDrag)
  }

  getHeaderText = () => {
    const { currentSlideData } = this.props
    const { fields: currentSlideFields } = currentSlideData || {}
    const { layout: currentSlideLayout } = currentSlideFields || {}
    const { fields: currentSlideLayoutFields } = currentSlideLayout || {}
    const { header } = currentSlideLayoutFields || ''

    return header
  }

  getImagePaths = () => {
    const {
      imageAfter: imageAfterObject,
      imageBefore: imageBeforeObject,
    } = this.props
    const { imageAfter } = imageAfterObject || {}
    const { imageBefore } = imageBeforeObject || {}
    const { path: pathLeft } = imageAfter || {}
    const { path: pathBefore } = imageBefore || {}
    const rightUrl = H5P_IMG_ORIGIN + pathBefore
    const leftUrl = H5P_IMG_ORIGIN + pathLeft

    return { leftUrl, rightUrl }
  }

  getImageAltTexts = () => {
    const { imageAfter, imageBefore } = this.props
    const { labelAfter: leftAltText } = imageAfter || {}
    const { labelBefore: rightAltText } = imageBefore || {}

    return { leftAltText, rightAltText }
  }

  getStartingPosition = () => {
    const { behavior } = this.props
    const { startingPosition = 0 } = behavior || {}

    return startingPosition
  }

  receiveFrameMeasurements = measurements => {
    const {
      componentWidthScaled: frameWidthScaled,
      componentWidth: frameWidth,
    } = measurements || {}

    this.setState({
      frameWidthScaled,
      frameWidth,
    })
  }

  getPositionDecimal = () => {
    const { position } = this.props
    const positionDecimal = percentToDecimal(position)

    return positionDecimal
  }

  getPositionPercent = x => {
    const { frameWidthScaled } = this.state || {}
    const positionDecimal = x / frameWidthScaled
    const positionPercent = decimalToPercent(positionDecimal)
    return positionPercent
  }

  handleDrag = (x = 0) => {
    const {
      completeStatus,
      currentSlide,
      id,
      imageJuxtapositionUpdate,
      maxThresholdReached,
    } = this.props || {}
    const currentSlideCompleteStatus = completeStatus[currentSlide]
    const isSlideIncomplete = currentSlideCompleteStatus != SLIDE_COMPLETE
    const positionPercent = this.getPositionPercent(x)
    let newMaxThresholdReached = maxThresholdReached

    if (isSlideIncomplete) {
      const {
        maxThresholdReached: updatedMaxThresholdReached,
      } = this.getUpdatedThresholdStatus(positionPercent)
      newMaxThresholdReached = updatedMaxThresholdReached
    }

    imageJuxtapositionUpdate(id, positionPercent, newMaxThresholdReached)
  }

  handleKeyboardDrag = (x = 0) => {
    const {
      completeStatus,
      currentSlide,
      id,
      imageJuxtapositionUpdate,
      maxThresholdReached,
    } = this.props || {}
    const currentSlideCompleteStatus = completeStatus[currentSlide]
    const isSlideIncomplete = currentSlideCompleteStatus != SLIDE_COMPLETE
    const positionPercent = this.getPositionPercent(x)
    let newMaxThresholdReached = maxThresholdReached

    if (isSlideIncomplete) {
      const {
        maxThresholdReached: updatedMaxThresholdReached,
      } = this.getUpdatedThresholdStatus(positionPercent)
      newMaxThresholdReached = updatedMaxThresholdReached
    }

    imageJuxtapositionUpdate(id, positionPercent, newMaxThresholdReached)
    this.updateSuspendData()
  }

  getUpdatedThresholdStatus = positionPercent => {
    const { maxThresholdReached } = this.props

    const isUnderMaxThreshold =
      positionPercent >= SLIDE_COMPLETION_PERCENTAGE_THRESHOLD_MAX

    const newMaxThresholdReached = !maxThresholdReached
      ? isUnderMaxThreshold
      : maxThresholdReached

    newMaxThresholdReached && this.completeSlide()

    return {
      maxThresholdReached: newMaxThresholdReached,
    }
  }

  completeSlide = () => {
    const { completeSlide, currentSlide } = this.props
    const currentSlideString = currentSlide.toString()

    completeSlide(currentSlideString)
  }

  debounce = (callback, delay) => {
    let timeout
    return function() {
      clearTimeout(timeout)
      timeout = setTimeout(callback, delay)
    }
  }

  updateSuspendData = this.debounce(() => {
    const {
      id,
      position,
      updateCmiInteractives,
      updateLMSSuspendData,
    } = this.props

    updateCmiInteractives({
      interactives: {
        [id]: {
          position,
        },
      },
    })
    updateLMSSuspendData()
  }, 250)

  getRenderProps = () => {
    const { scale, title: bodyText } = this.props
    const { frameWidth } = this.state || {}
    const headerText = this.getHeaderText()
    const positionDecimal = this.getPositionDecimal()
    const positionPixels = frameWidth * positionDecimal
    const { leftUrl: imageRightUrl, rightUrl: imageLeftUrl } =
      this.getImagePaths() || {}
    const { leftAltText: imageRightAltText, rightAltText: imageLeftAltText } =
      this.getImageAltTexts() || {}

    return {
      bodyText,
      headerText,
      imageLeftAltText,
      imageLeftUrl,
      imageRightAltText,
      imageRightUrl,
      onDrag: this.onDrag,
      onDragEnd: this.updateSuspendData,
      onKeyDown: this.onKeyDown,
      position: positionPixels,
      receiveFrameMeasurements: this.receiveFrameMeasurements,
      scale,
    }
  }

  render() {
    const renderProps = this.getRenderProps()

    return <ImageJuxtComponent {...renderProps} />
  }
}

ImageJuxtContainer.propTypes = {
  behavior: PropTypes.object,
  completeSlide: PropTypes.func,
  completeStatus: PropTypes.object,
  currentSlide: PropTypes.array,
  currentSlideData: PropTypes.object,
  id: PropTypes.string,
  imageAfter: PropTypes.object,
  imageBefore: PropTypes.object,
  imageJuxtapositionInitialize: PropTypes.func,
  imageJuxtapositionUpdate: PropTypes.func,
  maxThresholdReached: PropTypes.bool,
  position: PropTypes.number,
  scale: PropTypes.number,
  shouldInitialize: PropTypes.bool,
  title: PropTypes.string,
  updateCmiInteractives: PropTypes.func,
  updateLMSSuspendData: PropTypes.func,
}

ImageJuxtContainer.defaultProps = {
  completeSlide() {},
  completeStatus: {},
  currentSlide: [],
  imageJuxtapositionInitialize() {},
  imageJuxtapositionUpdate() {},
  position: 0,
  scale: 1,
  updateCmiInteractives() {},
  updateLMSSuspendData() {},
}

const mapDispatchToProps = dispatch => ({
  completeSlide: slidePosition => dispatch(completeSlide(slidePosition)),
  imageJuxtapositionInitialize: (id, position) =>
    dispatch(imageJuxtapositionInitialize(id, position)),
  imageJuxtapositionUpdate: (id, position, maxThresholdReached) =>
    dispatch(imageJuxtapositionUpdate(id, position, maxThresholdReached)),
  updateCmiInteractives: data => dispatch(updateCmiInteractives(data)),
  updateLMSSuspendData: () => dispatch(updateLMSSuspendData()),
})

const mapStateToProps = ({ player }, ownProps) => {
  const { id } = ownProps || {}
  const interactiveState = player.interactiveStates[id] || null

  return {
    ...interactiveState,
    completeStatus: player.completeStatus,
    currentSlide: player.currentSlide,
    currentSlideData: player.currentSlideData,
    scale: player.ratioWrapper.scale,
    shouldInitialize: interactiveState === null,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageJuxtContainer)
