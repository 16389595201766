/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

const NavSaving = () => (
  <svg height="17px" viewBox="0 0 20 17" width="20px">
    <defs>
      <polygon
        id="path-1"
        points="0 0.0017094625 10.7554234 0.0017094625 10.7554234 14.5999943 0 14.5999943"
      />
      <polygon
        id="path-3"
        points="0.012778648 0.000433333333 10.7688554 0.000433333333 10.7688554 14.6036677 0.012778648 14.6036677"
      />
    </defs>
    <g
      fill="none"
      fillRule="evenodd"
      id="SELA_header_saving"
      stroke="none"
      strokeWidth="1"
    >
      <g id="Group-7">
        <g id="Group-3" transform="translate(0.000000, 2.332900)">
          <mask fill="white" id="mask-2">
            <use xlinkHref="#path-1" />
          </mask>
          <g id="Clip-2" />
          <path
            d="M8.0915,10.7180433 C7.78243333,10.4109433 7.4738,10.1033767 7.16406667,9.79694333 C7.00283333,9.63744333 6.9106,9.44941 6.91546667,9.21901 C6.92206667,8.90754333 7.0644,8.67607667 7.3411,8.53847667 C7.6253,8.39714333 7.90033333,8.43347667 8.15376667,8.62231 C8.20676667,8.66177667 8.2527,8.71114333 8.29973333,8.75811 C9.0217,9.47934333 9.74336667,10.2008433 10.4649333,10.9224433 C10.8508,11.3083433 10.8528667,11.7464767 10.4674333,12.1328433 C9.72713333,12.8749433 8.98806667,13.6182767 8.243,14.3555767 C7.8029,14.7910767 7.10223333,14.6195433 6.94276667,14.0396767 C6.8631,13.7499767 6.93656667,13.4911433 7.1501,13.2763767 C7.41993333,13.00501 7.6909,12.7347767 7.96103333,12.46371 C8.00306667,12.42151 8.04323333,12.37751 8.11823333,12.2985767 C8.02233333,12.2985767 7.96556667,12.29901 7.9088,12.29851 C7.17686667,12.29221 6.4429,12.3185767 5.71343333,12.27271 C4.0598,12.1686767 2.66923333,11.4814767 1.5661,10.2492767 C0.4582,9.01174333 -0.0486666667,7.54387667 0.00366666667,5.88537667 C0.091,3.11831 2.1831,0.668276667 4.9086,0.127276667 C5.5835,-0.00669 6.26486667,-0.00899 6.94626667,0.0106766667 C7.37143333,0.0229766667 7.68793333,0.37591 7.6821,0.783676667 C7.6763,1.18931 7.36816667,1.51587667 6.9454,1.53787667 C6.58573333,1.55664333 6.22433333,1.54104333 5.86443333,1.55707667 C3.74913333,1.65141 1.90123333,3.33017667 1.5942,5.43097667 C1.24213333,7.83977667 2.69203333,10.0360433 5.03866667,10.6207767 C5.39523333,10.70961 5.77173333,10.74131 6.14053333,10.75331 C6.78283333,10.7741767 7.4263,10.75911 8.06926667,10.75911 C8.07666667,10.74541 8.0841,10.7317433 8.0915,10.7180433"
            fill="#6C7171"
            id="Fill-1"
            mask="url(#mask-2)"
          />
        </g>
        <g id="Group-6" transform="translate(9.200000, 0.032900)">
          <mask fill="white" id="mask-4">
            <use xlinkHref="#path-3" />
          </mask>
          <g id="Clip-5" />
          <path
            d="M2.69545667,2.30398333 C2.75449,2.30398333 2.81352333,2.30351667 2.87255667,2.30405 C3.59359,2.31075 4.31645667,2.28708333 5.03525667,2.33058333 C6.67732333,2.42995 8.06185667,3.10701667 9.16699,4.31958333 C10.3076567,5.57121667 10.8361233,7.06358333 10.7620233,8.75348333 C10.6443567,11.4370167 8.75252333,13.7395167 6.14682333,14.40785 C5.38009,14.6044833 4.59955667,14.62135 3.81725667,14.5928167 C3.39655667,14.57745 3.08862333,14.23465 3.08702333,13.8312167 C3.08542333,13.4210167 3.39965667,13.08805 3.82965667,13.06555 C4.12845667,13.0499167 4.42875667,13.0641833 4.72805667,13.0548833 C7.03782333,12.98315 8.88442333,11.3217167 9.19785667,9.03625 C9.53735667,6.56058333 7.72035667,4.17491667 5.24742333,3.89748333 C4.55619,3.81995 3.85195667,3.85751667 3.15369,3.84365 C3.00055667,3.84058333 2.84729,3.84318333 2.65655667,3.84318333 C2.71719,3.91211667 2.74965667,3.95318333 2.78635667,3.99001667 C3.06049,4.26505 3.33745667,4.53731667 3.60965667,4.81425 C3.92909,5.13918333 3.93822333,5.60715 3.63579,5.91771667 C3.33245667,6.22925 2.86129,6.23281667 2.53449,5.90931667 C1.76572333,5.14825 1.00112333,4.38291667 0.24039,3.61385 C-0.0689766667,3.30111667 -0.0604766667,2.83668333 0.250623333,2.52411667 C1.00592333,1.76528333 1.76272333,1.00791667 2.52142333,0.25245 C2.84815667,-0.0728833333 3.31209,-0.0830166667 3.62362333,0.220783333 C3.93729,0.52665 3.93455667,1.00301667 3.60952333,1.33568333 C3.30372333,1.64861667 2.99089,1.95471667 2.68115667,2.26381667 C2.68592333,2.27721667 2.69069,2.29061667 2.69545667,2.30398333"
            fill="#6C7171"
            id="Fill-4"
            mask="url(#mask-4)"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default NavSaving
