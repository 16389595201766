export function loadPresentation(
  contentfulId,
  preview,
  token,
  cache,
  locale,
  isPrepMode,
  themeId,
  setError,
  setInitialStore,
) {
  return {
    type: 'GET_PRESENTATION',
    cache,
    contentfulId,
    isPrepMode,
    locale,
    preview,
    setError,
    setInitialStore,
    themeId,
    token,
  }
}

export function setPresentation(
  presentationJson,
  isPrepMode,
  setError,
  setInitialStore,
  locale,
) {
  return {
    type: 'SET_PRESENTATION',
    isPrepMode,
    locale,
    presentationJson,
    setError,
    setInitialStore,
  }
}
