const modeQueryParamMap = {
  TEACHER_PREP: 'prep',
  FAMILY: 'family',
  CURRICULUM_PREVIEW: 'preview',
  DEFAULT: null,
}

export const {
  TEACHER_PREP,
  FAMILY,
  CURRICULUM_PREVIEW,
  DEFAULT,
} = modeQueryParamMap

export function isValidMode(mode) {
  return (
    mode === null ||
    (typeof mode === 'string' &&
      Object.values(modeQueryParamMap).includes(mode))
  )
}

// export function getModeFromQueryParam(mode: ModeQueryParam): string {
export function getModeFromQueryParam(mode) {
  if (!isValidMode(mode)) {
    return modeQueryParamMap.DEFAULT
  }
  return mode // as ModeQueryParam
}
