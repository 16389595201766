export default class LogBase {
  init() {
    this.loggerInstance = {}
  }

  isInitialized = () => !!this.loggerInstance

  checkForInitialization(func) {
    return function() {
      if (!this.isInitialized()) {
        console.error(`${this.constructor.name} Not Initialized`)
      } else {
        return func.apply(this, arguments)
      }
    }
  }
}
