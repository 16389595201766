import React from 'react'

import PropTypes from 'prop-types'
import { useDrop } from 'react-dnd'

import { HoverIndicator } from '../DragWordDraggableItem/style'

import { DropZone } from './style'

const DragWordDropZone = props => {
  const { dataTestId, onDrop } = props
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'DraggableItem',
    drop: onDrop,
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  })

  const isActive = isOver && canDrop
  const tabIndex = '0'

  return (
    <DropZone
      data-testid={dataTestId}
      isActive={isActive}
      ref={drop}
      tabIndex={tabIndex}
    >
      <HoverIndicator isHovered={isActive} />
    </DropZone>
  )
}

DragWordDropZone.propTypes = {
  onDrop: PropTypes.func,
  dataTestId: PropTypes.string,
}

export default DragWordDropZone
