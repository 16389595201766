import styled from 'styled-components'

const VERTICAL_MARGIN = '25px'

export const BodyText = styled.div.attrs(() => ({
  marginBottom: VERTICAL_MARGIN,
  marginTop: VERTICAL_MARGIN,
}))`
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
`
