import React from 'react'

import PropTypes from 'prop-types'

import AspectRatioWrapper from '../AspectRatioWrapper'

import ImageHotspotsFrame from './ImageHotspotsFrame'
import { HotspotsWrapper } from './style'

const ACCESSIBILITY_LABEL = 'Hotspots interactive container'
const DATA_TEST_ID = 'hotspots-interactive-container'

const HotspotsComponent = props => {
  const onResize = dimensions => {
    const {
      id,
      imageAltText,
      imageSource,
      hotspots,
      onClickTarget,
      targetsClicked,
    } = props || {}
    const { height, width } = dimensions || {}

    const frameProps = {
      hotspots,
      id,
      imageAltText,
      imageSource,
      onClickTarget,
      targetsClicked,
    }

    return <ImageHotspotsFrame height={height} width={width} {...frameProps} />
  }

  const getRenderProps = () => {
    const hotspotsWrapperProps = {
      accessibilityLabel: ACCESSIBILITY_LABEL,
      'data-testid': DATA_TEST_ID,
    }
    const aspectRatioWrapperProps = {
      render: onResize,
    }
    const renderProps = {
      aspectRatioWrapperProps,
      hotspotsWrapperProps,
    }

    return renderProps
  }

  const { hotspotsWrapperProps, aspectRatioWrapperProps } = {
    ...getRenderProps(),
  }

  return (
    <HotspotsWrapper {...hotspotsWrapperProps}>
      <AspectRatioWrapper {...aspectRatioWrapperProps} />
    </HotspotsWrapper>
  )
}

HotspotsComponent.propTypes = {
  hotspots: PropTypes.array,
  id: PropTypes.string,
  imageAltText: PropTypes.string,
  imageSource: PropTypes.string,
  onClickTarget: PropTypes.func,
}

HotspotsComponent.defaultProps = {
  hotspots: [],
  onClickTarget() {},
}

export default HotspotsComponent
