import styled from 'styled-components'
import PropTypes from 'prop-types'
import { themeGet } from 'styled-system'
import { Heading } from 'grommet'
import {
  toNoBreakContentArray,
  toContentArray,
} from '../../utils/componentHelpers'

const HeaderSix = styled(Heading).attrs(({ dataTestId, children }) => {
  const contentArray = toContentArray(children)
  const noBreakContentArray = toNoBreakContentArray(contentArray)
  return {
    a11yTitle: 'SubHeader',
    'data-testid': dataTestId,
    level: 6,
    responsive: false,
    children: noBreakContentArray,
  }
})`
  color: ${themeGet('colors.heading.level.6')};
  letter-spacing: 0;

  ${themeGet('breakpoints.tablet')} {
    font-size: ${themeGet('heading.level.6.tablet.size')};
    line-height: ${themeGet(`heading.level.6.tablet.height`)};
  }

  ${themeGet('breakpoints.mobile')} {
    font-size: ${themeGet(`heading.level.6.mobile.size`)};
    line-height: ${themeGet(`heading.level.6.mobile.height`)};
  }
`

HeaderSix.propTypes = {
  children: PropTypes.node.isRequired,
  dataTestId: PropTypes.string.isRequired,
  textAlign: PropTypes.string,
}

HeaderSix.defaultProps = {
  textAlign: 'start',
}

HeaderSix.displayName = 'HeaderSix'

export default HeaderSix
