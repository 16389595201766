/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'
import PropTypes from 'prop-types'

const IconCalendar = ({ color = 'currentColor' }) => (
  <svg height={18} viewBox="0 0 16 18" width={16}>
    <title>{'calendar icon'}</title>
    <g fill="none" fillRule="evenodd">
      <rect fill={color} height={15.75} rx={1.875} width={15.75} y={2.25} />
      <rect fill="#FFF" height={10.5} rx={1.125} width={12.75} x={1.5} y={6} />
      <rect fill={color} height={2.25} rx={0.75} width={2.25} x={3} y={8.25} />
      <rect fill={color} height={3.75} rx={1.125} width={2.25} x={3} />
      <rect fill={color} height={2.25} rx={0.75} width={2.25} x={3} y={12} />
      <rect
        fill={color}
        height={2.25}
        rx={0.75}
        width={2.25}
        x={6.75}
        y={8.25}
      />
      <rect fill={color} height={2.25} rx={0.75} width={2.25} x={6.75} y={12} />
      <rect
        fill={color}
        height={2.25}
        rx={0.75}
        width={2.25}
        x={10.5}
        y={8.25}
      />
      <rect fill={color} height={3.75} rx={1.125} width={2.25} x={10.5} />
      <rect fill={color} height={2.25} rx={0.75} width={2.25} x={10.5} y={12} />
    </g>
  </svg>
)

IconCalendar.propTypes = {
  color: PropTypes.string,
}

export default IconCalendar
