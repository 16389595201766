/* istanbul ignore file */
// we don't unit test SVGs
import React from 'react'
import PropTypes from 'prop-types'

const SearchIcon = ({ color = 'currentColor', ...props }) => (
  <svg
    height="18px"
    viewBox="0 0 15 18"
    width="15px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>ss_schoolsearchfield_magnifyingglass</title>
    <g
      fill="none"
      fillRule="evenodd"
      id="ss_schoolsearchfield_magnifyingglass"
      stroke="none"
      strokeWidth="1"
    >
      <path
        d="M7.20337383,14.3986832 C8.23099327,14.3991017 9.24651109,14.1781431 10.1810659,13.7506472 L12.819913,17.491237 C13.0667323,17.8417164 13.4819139,18.0338025 13.9086295,17.9950853 C14.3355432,17.9563756 14.7093169,17.6927345 14.8891811,17.303554 C15.0690667,16.914577 15.027857,16.4588252 14.7808342,16.1083993 L12.1691891,12.4038055 C13.7938034,10.8506187 14.5997385,8.62721966 14.3480678,6.39321462 C14.09644,4.15915602 12.8161433,2.17117758 10.8865255,1.01844684 C8.95717553,-0.134283907 6.60025316,-0.319245768 4.51497446,0.518573077 C2.42958866,1.35639192 0.854933623,3.12046635 0.258420648,5.28799653 C-0.338360063,7.45531245 0.11137538,9.77727213 1.47409696,11.5648083 C2.83681318,13.3523444 4.9560942,14.4006223 7.20362015,14.3985333 L7.20337383,14.3986832 Z M7.20337383,2.39999892 C8.47597054,2.39999892 9.69625189,2.90552384 10.5960548,3.80566621 C11.495697,4.70583536 12.0012337,5.9265408 12.0012337,7.19931195 C12.0012337,8.47235093 11.4956756,9.69305636 10.5960548,10.5931719 C9.69619834,11.4931268 8.475917,11.9988392 7.20337383,11.9988392 C5.93099131,11.9988392 4.71070996,11.4931054 3.81090709,10.5931719 C2.91105066,9.69300279 2.40572814,8.47229736 2.40572814,7.19931195 C2.40572814,5.92648723 2.91107744,4.7057818 3.81090709,3.80566621 C4.71076351,2.90549706 5.93104486,2.39999892 7.20337383,2.39999892 Z"
        fill={color}
        fillRule="nonzero"
      />
    </g>
  </svg>
)

SearchIcon.propTypes = {
  color: PropTypes.string,
}

export default SearchIcon
