/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

export default () => (
  <svg
    height="19px"
    version="1.1"
    viewBox="0 0 22 19"
    width="22px"
    x="0px"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
  >
    <g transform="translate(-69.000000, -626.000000)">
      <g transform="translate(40.000000, 398.000000)">
        <g transform="translate(27.000000, 137.547350)">
          <g
            id="thoughts-and-emotions-icon"
            transform="translate(2.000000, 90.452650)"
          >
            <g transform="translate(0.660000, 15.687210)">
              <defs>
                <filter
                  filterUnits="userSpaceOnUse"
                  height="3.2"
                  width="3.6"
                  x="0.2"
                  y="0.1"
                >
                  <feColorMatrix
                    type="matrix"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                  />
                </filter>
              </defs>
              <mask
                height="3.2"
                maskUnits="userSpaceOnUse"
                width="3.6"
                x="0.2"
                y="0.1"
              >
                <g className="st0">
                  <polygon
                    className="st1"
                    points="0.2,0.1 3.8,0.1 3.8,3.3 0.2,3.3 								"
                  />
                </g>
              </mask>
              <path
                className="st2"
                d="M1.4,0.1C0.5,0.4,0,1.3,0.3,2.1C0.6,3,1.6,3.5,2.5,3.2C3.5,3,4,2.1,3.7,1.2
							C3.4,0.4,2.4-0.1,1.4,0.1"
                fill="currentColor"
              />
            </g>
            <g transform="translate(0.000000, 0.177210)">
              <defs>
                <filter
                  filterUnits="userSpaceOnUse"
                  height="16.1"
                  width="21.6"
                  x="0"
                  y="0.2"
                >
                  <feColorMatrix
                    type="matrix"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                  />
                </filter>
              </defs>
              <mask
                height="16.1"
                maskUnits="userSpaceOnUse"
                width="21.6"
                x="0"
                y="0.2"
              >
                <g className="st3">
                  <polygon
                    className="st1"
                    points="0,0.2 21.6,0.2 21.6,16.3 0,16.3 								"
                  />
                </g>
              </mask>
              <path
                className="st4"
                d="M17,8c-0.2,0.2-0.4,0.4-0.5,0.6c-1.1,1.2-2.1,2.4-3.2,3.6c-0.1,0.1-0.2,0.1-0.2,0.1
							c-0.1,0-0.1-0.1,0-0.2c0.4-1.1,0.8-2.2,1.2-3.3c0,0,0,0,0-0.1c-0.3,0-0.6,0.1-1,0.1c-0.1,0-0.3,0-0.4,0c-0.1,0-0.1,0-0.2-0.1
							c0-0.1,0-0.1,0-0.2c0.6-0.7,1.2-1.4,1.9-2.1c0.6-0.7,1.2-1.4,1.9-2.1c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0c0,0,0,0.1,0,0.2
							c-0.1,0.3-0.2,0.6-0.3,0.8c-0.3,0.8-0.6,1.7-0.9,2.5c0,0,0,0,0,0.1c0.5,0,0.9-0.1,1.4-0.1c0.1,0,0.1,0,0.1,0
							C17,7.9,17,7.9,17,8 M10.6,8.6C9.8,9.4,9,10.3,8.2,11.1c0,0,0,0,0,0c-0.1,0.1-0.3,0.1-0.4,0L5.5,8.6c-0.6-0.7-0.6-1.7,0-2.4
							c0.3-0.3,0.7-0.5,1.1-0.5c0.4,0,0.8,0.2,1.2,0.5L8,6.4l0.2-0.3c0.6-0.7,1.7-0.7,2.3,0C11.2,6.9,11.2,7.9,10.6,8.6 M19,5.6
							c-0.1-0.1-0.2-0.1-0.1-0.2C19,4.6,19,3.8,18.7,3.1c-0.8-2.5-3.7-3.7-6.1-2.5C12,1,11.5,1.4,11.1,2C11,2,11,1.9,10.9,1.9
							C9.7,0.6,7.8,0.1,6.3,0.8C4.4,1.5,3.4,3.3,3.6,5.2c0,0.1,0,0.2-0.1,0.2c-0.3,0-0.6,0.1-0.9,0.3c-2,0.9-2.9,2.9-2.3,5
							c0.6,1.9,2.7,3.1,4.7,2.6c0.1,0,0.2,0,0.3,0.1c2.2,3.6,7.2,3.9,9.7,0.5c0.1-0.1,0.1-0.1,0.3-0.1c1.3,0.6,2.6,0.6,3.9-0.1
							c1.7-0.8,2.6-2.2,2.6-4.1C21.5,7.8,20.7,6.4,19,5.6"
                fill="currentColor"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
