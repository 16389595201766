import styled from 'styled-components'
import PropTypes from 'prop-types'
import { themeGet } from 'styled-system'
import { Button } from 'grommet'

const ContainedButton = styled(Button).attrs(({ children, dataTestId }) => ({
  children,
  'data-testid': dataTestId,
}))`
  min-width: 110px;
  padding: 0.5625rem 0.8125rem 0.375rem;
  background: ${themeGet('colors.buttons.primaryBackground')};
  color: ${themeGet('colors.buttons.primaryFont')};
  border: 2px solid ${themeGet('colors.buttons.primaryBackground')};
  border-radius: ${themeGet('buttons.borderRadius')};

  font-family: ${themeGet('fontFamilySemibold')};
  text-align: center;
  text-transform: capitalize;
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;

  :disabled {
    background: ${themeGet('colors.buttons.primaryBackgroundDisabled')};
    border-color: ${themeGet('colors.buttons.primaryBackgroundDisabled')};
  }

  &:not([disabled]):hover {
    background: ${themeGet('colors.buttons.primaryBackgroundHover')};
    border-color: ${themeGet('colors.buttons.primaryBackgroundHover')};
  }
`

ContainedButton.propTypes = {
  dataTestId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

ContainedButton.defaultProps = {
  disabled: false,
}

export default ContainedButton
