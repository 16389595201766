import styled from 'styled-components'

export const Wrapper = styled.div.attrs(
  ({
    theme: {
      colorVars: { menuBar: styles },
    },
  }) => ({
    style: {
      height: `${styles.height}rem`,
    },
  }),
)`
  background-color: #fff;
  box-shadow: 0px 1.8em 0.7em 1.7em #000;
  display: flex;
  font-size: 1em;
  overflow: hidden;
  user-select: none;
  z-index: 1;

  -webkit-user-select: none;
`
