/* istanbul ignore file */
// we don't test SVGs without arguments
const LearnTranscriptDefault = () => {
  return (
    <svg height="30px" viewBox="0 0 30 30" width="30px">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-1313.000000, -938.000000)">
          <rect fill="#FFFFFF" height="988" width="1366" x="0" y="0" />
          <rect
            fill="#FFFFFF"
            height="987"
            rx="20"
            stroke="#979797"
            width="1367"
            x="0.5"
            y="0.5"
          />
          <g stroke="#002855" transform="translate(1313.000000, 938.000000)">
            <rect fill="#FFFFFF" height="30" rx="6" width="30" x="0" y="0" />
            <line
              strokeLinecap="round"
              strokeWidth="2"
              x1="7"
              x2="11"
              y1="10"
              y2="10"
            />
            <line
              strokeLinecap="round"
              strokeWidth="2"
              x1="15"
              x2="23"
              y1="10"
              y2="10"
            />
            <line
              strokeLinecap="round"
              strokeWidth="2"
              x1="7"
              x2="23"
              y1="15"
              y2="15"
            />
            <line
              strokeLinecap="round"
              strokeWidth="2"
              x1="7"
              x2="15"
              y1="20"
              y2="20"
            />
            <line
              strokeLinecap="round"
              strokeWidth="2"
              x1="19"
              x2="23"
              y1="20"
              y2="20"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default LearnTranscriptDefault
