import React from 'react'

function StartIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <circle cx="10" cy="10" r="10" fill="currentColor" />
        <path
          fill="#FFF"
          d="M6.595 6.7v6.6a1 1 0 001.486.875l5.94-3.3a1 1 0 000-1.75l-5.94-3.3a1 1 0 00-1.486.875z"
        />
      </g>
    </svg>
  )
}

export default StartIcon
