import React, { Component } from 'react'

import PropTypes from 'prop-types'

import ErrorMessageComponent from './component'

const DEFAULT_ERROR_MESSAGE =
  'An unexpected error occurred. Please try reloading the presentation.'

export class ErrorMessageContainer extends Component {
  render() {
    const { message = DEFAULT_ERROR_MESSAGE, url } = this.props

    return <ErrorMessageComponent message={message} url={url} />
  }
}

ErrorMessageContainer.propTypes = {
  message: PropTypes.string,
  url: PropTypes.string,
}

export default ErrorMessageContainer
