import styled from 'styled-components'
import PropTypes from 'prop-types'
import { themeGet } from 'styled-system'
import { Heading } from 'grommet'
import {
  toNoBreakContentArray,
  toContentArray,
} from '../../utils/componentHelpers'

const HeaderThree = styled(Heading).attrs(({ dataTestId, children }) => {
  const contentArray = toContentArray(children)
  const noBreakContentArray = toNoBreakContentArray(contentArray)
  return {
    a11yTitle: 'SubHeader',
    'data-testid': dataTestId,
    level: 3,
    responsive: false,
    children: noBreakContentArray,
  }
})`
  color: ${themeGet('colors.heading.level.3')};
  letter-spacing: 0;
  max-width: none;

  ${themeGet('breakpoints.tablet')} {
    font-size: ${themeGet('heading.level.3.tablet.size')};
    line-height: ${themeGet(`heading.level.3.tablet.height`)};
  }

  ${themeGet('breakpoints.mobile')} {
    font-size: ${themeGet(`heading.level.3.mobile.size`)};
    line-height: ${themeGet(`heading.level.3.mobile.height`)};
  }
`

HeaderThree.propTypes = {
  children: PropTypes.node.isRequired,
  dataTestId: PropTypes.string.isRequired,
  textAlign: PropTypes.string,
}

HeaderThree.defaultProps = {
  textAlign: 'start',
}

HeaderThree.displayName = 'HeaderThree'

export default HeaderThree
