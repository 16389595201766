import styled from 'styled-components'
import { themeGet } from 'styled-system'
import BodyCopy from '../BodyCopy'

export const Background = styled.div.attrs(props => ({
  'data-testid': props.dataTestId,
  border: props.shouldRenderIndicator ? '2px solid' : 'none',
  borderColor: props.shouldRenderIndicator
    ? themeGet('colors.grayBlue')(props)
    : 'transparent',
  height: props.shouldRenderIndicator ? '1rem' : '1.25rem',
}))`
  margin: 0;
  margin-top: 0.25rem;
  padding: 0;
  height: ${({ height }) => height};
  border-radius: 0.625rem;
  background-color: ${themeGet('colors.paleGray')};
  width: 100%;
  border: ${({ border }) => border};
  border-color: ${({ borderColor }) => borderColor};
`

export const IndicatorWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  top: -0.125rem;
  left: -0.125rem;
  overflow: hidden;
  border-radius: 0.625rem;
  padding: 0.125rem;
`

export const Indicator = styled.div.attrs(
  ({ animation_time, dataTestId, percentComplete }) => ({
    animationTime: animation_time,
    'data-testid': dataTestId,
    visible: percentComplete > 0 ? 'visible' : 'hidden',
    width: percentComplete,
  }),
)`
  margin: -0.125rem;
  height: 1rem;
  border-radius: 0.625rem;
  border: 2px solid ${themeGet('colors.blueGray')};
  background-color: ${themeGet('colors.elemDigital1')};
  visibility: ${({ visible }) => visible};
  width: ${({ width }) => `${width}%`};

  animation-name: slidein;
  animation-duration: ${({ animationTime }) => `${animationTime}ms`};
  animation-fill-mode: forwards;

  @keyframes slidein {
    from {
      transform: translateX(-100%);
    }

    to {
      transform: translateX(0%);
    }
  }
`

export const ProgressInsideBar = styled(BodyCopy).attrs(props => ({
  color:
    props.progress_width >= 35
      ? themeGet('colors.linkLight')(props)
      : themeGet('colors.blueGray')(props),
  padding_left: props.progress_width >= 35 ? 0 : '0.625rem',
  display_effect: `visibility ${props.animation_time};`,
}))`
  color: ${({ color }) => color};
  display: flex;
  font-family: ${themeGet('fontFamilySemibold')};
  font-size: 0.875rem;
  justify-content: flex-end;
  line-height: 1rem;
  padding: 0.0625rem 0.5rem 0 0;
  padding-left: ${({ padding_left }) => padding_left};
  transition: ${({ display_effect }) => display_effect};
`

export const ProgressOutsideBar = styled(BodyCopy)`
  color: ${themeGet('darkGray')};
  display: flex;
  font-family: ${themeGet('fontFamilySemibold')};
  font-size: 1rem;
  justify-content: flex-end;
  line-height: 1rem;
  padding: 0.4375rem 0 0 0.5rem;
`

export const Wrapper = styled.div`
  display: flex;
`
