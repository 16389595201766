import styled from 'styled-components'
import { Markdown } from 'grommet'
import { themeGet } from 'styled-system'
import CheckIcon from '../CheckIcon'

export const StyledCheckBoxWrapper = styled.div.attrs(({ checked }) => ({
  fontWeight: checked ? 700 : 400,
  letterSpacing: checked ? '-0.0626rem' : 'normal',
  hoverBackgroundColor: themeGet(
    'colors.multiSelectQuestion.backgroundColor',
    '#b1dcdd',
  ),
}))`
  display: flex;
  margin-top: 0.75rem;
  font-weight: ${({ fontWeight }) => fontWeight};
  /* Safari only override */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      letter-spacing: ${({ letterSpacing }) => letterSpacing};
    }
  }
`

// Custom label was created because Grommet input labels do not accept Markdown
export const StyledCheckBoxLabel = styled.label.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  font-family: ${themeGet('fontFamily')};
  margin-top: -0.125rem;

  p {
    font-size: inherit;
    line-height: 1.625rem;
    margin: 0;
    max-width: none;
  }

  ul {
    margin: 0;
    padding-left: 1.1rem;
  }

  :hover {
    cursor: pointer;
    font-weight: 700;
  }
  /* Safari only override */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      :hover {
        letter-spacing: -0.0626rem;
      }
    }
  }

  @media only screen and (max-width: 375px) {
    font-size: 1rem;

    p {
      line-height: 1.5rem;
    }
  }
`

export const StyledCheckBoxAnswerText = styled(Markdown)`
  color: ${themeGet('colors.multiSelectQuestion.questionText', '#393939')};
`

export const HiddenCheckbox = styled.input.attrs(
  ({ id, checked, dataTestId }) => ({
    name: id,
    'data-testid': dataTestId,
    'aria-checked': checked,
    type: 'checkbox',
  }),
)`
  cursor: pointer;
  border: 0;
  opacity: 0;
  padding-right: 1.25rem;
  margin-top: 0.1875rem;
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  transform: scale(1.75);
  position: absolute;
  white-space: nowrap;
`

export const CheckboxContainer = styled.div`
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
`

export const Icon = styled(CheckIcon).attrs(() => ({
  width: 24,
}))``

export const StyledCheckbox = styled.div.attrs(({ checked }) => ({
  'aria-checked': checked,
}))`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid ${themeGet(`colors.multiSelectQuestion.borderColor`)};
  transition: all 150ms;

  box-sizing: border-box;

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }

  ${HiddenCheckbox}:checked + & {
    background-color: ${themeGet(
      'colors.multiSelectQuestion.backgroundColor',
      '#00a3a0',
    )};
  }

  ${HiddenCheckbox}:focus + & {
    border: 2px solid ${themeGet('colors.multiSelectQuestion.focus', '#6FFFB0')};
    box-shadow: 0 0 2px
      ${themeGet('colors.multiSelectQuestion.borderColor', '#6FFFB0')};
    border-radious: 1px;
  }

  ${HiddenCheckbox}:hover + & {
    background-color: ${themeGet(
      'colors.multiSelectQuestion.hover',
      '#b1dcdd',
    )};
  }
`
