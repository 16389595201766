import React, { Component } from 'react'

import PropTypes from 'prop-types'

import closeSVG from '../../../assets/images/xBlackSmall.svg'

import { CloseIcon } from './style'

const ALT_TEXT = 'Close Button'
const DEFAULT_TEST_LABEL = 'Close'

export class Close extends Component {
  render = () => {
    const {
      ariaLabel,
      dataTestId,
      isCloseButtonWithBorder,
      onClick,
    } = this.props
    return (
      <CloseIcon
        autoFocus
        aria-label={ariaLabel}
        data-testid={dataTestId}
        isCloseButtonWithBorder={isCloseButtonWithBorder}
        onClick={onClick}
      >
        <img alt={ALT_TEXT} src={closeSVG} />
      </CloseIcon>
    )
  }
}

Close.propTypes = {
  ariaLabel: PropTypes.string,
  dataTestId: PropTypes.string,
  isCloseButtonWithBorder: PropTypes.bool,
  onClick: PropTypes.func,
}

Close.defaultProps = {
  ariaLabel: DEFAULT_TEST_LABEL,
  dataTestId: DEFAULT_TEST_LABEL,
}

export default Close
