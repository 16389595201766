import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ThemeProvider as WebThemeProvider } from 'styled-components'
import { ThemeProvider as NativeThemeProvider } from 'styled-components/native'

export const ThemeProvider = ({ children, theme }) => (
  <WebThemeProvider theme={theme}>
    <NativeThemeProvider theme={theme}>{children}</NativeThemeProvider>
  </WebThemeProvider>
)

ThemeProvider.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.object,
}

const mapStateToProps = ({ player }) => ({
  theme: player.theme,
})

export default connect(mapStateToProps)(ThemeProvider)
