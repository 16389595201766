import styled from 'styled-components'

import {
  BaseContainer,
  BaseGrip,
  BaseHoverIndicator,
  BaseText,
} from '../../DraggableBase/style'
import { EMPTY_STRING } from '../constants'

//This accessibility container is also necessary to render correctly in Safari
export const AccessibilityContainer = styled.div`
  :focus {
    outline: 3px groove ${props => props.theme.colorVars.blueFocus};
    z-index: 1;
  }
`

export const DraggableContainer = styled(BaseContainer).attrs(
  ({
    isDragging,
    isInDropZone,
    theme: {
      colorVars: {
        dragAndDrop: dragAndDropColors,
        dragQuestion: dragQuestionColors,
      },
    },
  }) => ({
    backgroundColor: isDragging
      ? dragQuestionColors.draggable.backgroundColorInactive
      : dragAndDropColors.draggable.backgroundColor,
    border: isInDropZone
      ? `solid ${dragAndDropColors.draggable.borderColor}`
      : EMPTY_STRING,
    borderWidth: isInDropZone ? '2px 0px 0px 0px' : '2px',
    borderRadius: isInDropZone ? '0px' : '5px',
    marginBottom: isInDropZone ? '0px' : '4px',
    marginTop: isInDropZone ? '0px' : '4px',
  }),
)`
  background-color: ${props => props.backgroundColor};
  border: ${props => props.border};
  border-radius: ${props => props.borderRadius};
  border-width: ${props => props.borderWidth};
  margin-bottom: ${props => props.marginBottom};
  margin-top: ${props => props.marginTop};
  position: relative;
`

export const HoverIndicator = styled(BaseHoverIndicator).attrs(
  ({
    isHovered,
    isInDropZone,
    theme: {
      colorVars: { dragAndDrop: colors },
    },
  }) => ({
    style: {
      backgroundColor: isHovered
        ? colors.hoverIndicator.colorHover
        : colors.hoverIndicator.colorNoHover,
      borderRadius: isInDropZone ? '0' : '3px 0 0 3px',
      height: 'auto',
    },
  }),
)``

export const Grip = styled(BaseGrip)`
  align-self: center;
`

export const Text = styled(BaseText)`
  padding-bottom: 10px;
  padding-right: 10px;
  padding-top: 10px;
`
