import styled from 'styled-components/native'

export const ImageBackgroundContainer = styled.View.attrs(
  ({ height, source, width }) => ({
    backgroundPosition: height || width ? 'center' : null,
    containerHeight: height || '100%',
    containerWidth: width || '100%',
    source,
  }),
)`
  background-image: url(${props => props.source});
  background-position: ${props => props.backgroundPosition};
  background-repeat: no-repeat;
  background-size: cover;
  height: ${props => props.containerHeight};
  overflow: hidden;
  width: ${props => props.containerWidth};
`
