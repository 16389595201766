import React, { Component } from 'react'

import PropTypes from 'prop-types'

import ImageJuxtHandle from '../ImageJuxtHandle'
import ImageJuxtLeftImage from '../ImageJuxtLeftImage'
import ImageJuxtRightImage from '../ImageJuxtRightImage'

import { Frame } from './style'

const ACCESSIBILITY_LABEL = 'Image juxtaposition image frame'

class ImageJuxtFrameComponent extends Component {
  getRenderProps = () => {
    const {
      componentRef: ref,
      height,
      imageLeftAltText,
      imageLeftUrl,
      imageRightAltText,
      imageRightUrl,
      isDragging,
      onDrag,
      onDragEnd,
      onDragStart,
      onKeyDown,
      onLayout,
      pageXOffset,
      position,
      rightErrorMessageTextWidth: errorMessageTextWidth,
      rightErrorMessageWidth: errorMessageWidth,
      scale,
      selfRenderedWidth,
      width,
    } = this.props
    const handleProps = {
      frameWidth: selfRenderedWidth,
      onDrag,
      onDragEnd,
      onDragStart,
      onKeyDown,
      pageXOffset,
      position,
      scale,
    }
    const frameProps = {
      accessibilityLabel: ACCESSIBILITY_LABEL,
      height,
      isDragging,
      onLayout,
      ref,
      width,
    }
    const leftImageProps = {
      alt: imageLeftAltText,
      containerWidth: position,
      imageWidth: selfRenderedWidth,
      source: imageLeftUrl,
    }
    const rightImageProps = {
      alt: imageRightAltText,
      errorMessageTextWidth,
      errorMessageWidth,
      source: imageRightUrl,
    }

    return {
      frameProps,
      handleProps,
      leftImageProps,
      rightImageProps,
    }
  }

  render = () => {
    const { frameProps, handleProps, leftImageProps, rightImageProps } =
      this.getRenderProps()

    return (
      <Frame {...frameProps}>
        <ImageJuxtRightImage {...rightImageProps} />
        <ImageJuxtLeftImage {...leftImageProps} />
        <ImageJuxtHandle {...handleProps} />
      </Frame>
    )
  }
}

ImageJuxtFrameComponent.propTypes = {
  componentRef: PropTypes.func,
  height: PropTypes.number,
  imageLeftAltText: PropTypes.string,
  imageLeftUrl: PropTypes.string,
  imageRightAltText: PropTypes.string,
  imageRightUrl: PropTypes.string,
  isDragging: PropTypes.bool,
  onDrag: PropTypes.func,
  onDragEnd: PropTypes.func,
  onDragStart: PropTypes.func,
  onKeyDown: PropTypes.func,
  onLayout: PropTypes.func,
  pageXOffset: PropTypes.number,
  position: PropTypes.number,
  rightErrorMessageTextWidth: PropTypes.number,
  rightErrorMessageWidth: PropTypes.number,
  scale: PropTypes.number,
  selfRenderedWidth: PropTypes.number,
  width: PropTypes.number,
}

ImageJuxtFrameComponent.defaultProps = {
  componentRef() {},
  onDrag() {},
  onDragEnd() {},
  onDragStart() {},
  onKeyDown() {},
  onLayout() {},
  pageXOffset: 0,
  position: 0,
  rightErrorMessageTextWidth: 0,
  rightErrorMessageWidth: 0,
  scale: 1,
  selfRenderedWidth: 0,
}

export default ImageJuxtFrameComponent
