import styled, { css } from 'styled-components'

const strong = css`
  strong {
    font-weight: bold;
  }
`

export const OrderedListContainer = styled.ol`
  margin-left: 35px;
  ${strong}
`

export const UnorderedListContainer = styled.ul`
  margin-left: 35px;
  ${strong}
`

export const ParagraphContainer = styled.p`
  margin: 12px 0;
  ${strong}
`

export const LinkContainer = styled.a`
  color: inherit;
  font-size: 70%;
  font-weight: 700;

  :hover {
    text-decoration: none;
  }
`
