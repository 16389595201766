import React from 'react'

import DialogcardTurnArrow from '../../DialogcardTurnArrow'
import { getConstantByLocale, getLocaleFromSession } from 'utils/locale'
import { ArrowContainer, TurnIndicatorContainer } from './style'
import { TURN } from './constants'

const DialogcardTurnIndicator = () => {
  const currentLocale = getLocaleFromSession()
  const text = getConstantByLocale(TURN, currentLocale)
  return (
    <TurnIndicatorContainer>
      {text}
      <ArrowContainer>
        <DialogcardTurnArrow />
      </ArrowContainer>
    </TurnIndicatorContainer>
  )
}

export default DialogcardTurnIndicator
