import React, { useRef } from 'react'

import PropTypes from 'prop-types'
import { getConstantByLocale, getLocaleFromSession } from 'utils/locale'

import DragQuestionResetButton from './component'
import { RESET } from './constants'

const DragQuestionResetButtonContainer = ({ disabled, onReset }) => {
  const buttonRef = useRef(null)
  const currentLocale = getLocaleFromSession()
  const resetButtonText = getConstantByLocale(RESET, currentLocale)
  const onClick = () => {
    buttonRef.current.blur()
    onReset()
  }

  return (
    <DragQuestionResetButton
      disabled={disabled}
      onClick={onClick}
      setRef={buttonRef}
      text={resetButtonText}
    />
  )
}

DragQuestionResetButtonContainer.propTypes = {
  disabled: PropTypes.bool,
  onReset: PropTypes.func,
  checkButtonText: PropTypes.string,
}

DragQuestionResetButtonContainer.defaultProps = {
  onReset() {},
}

export default DragQuestionResetButtonContainer
