import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, StyledImage } from './component.styles'

const Image = ({ src, alt, dataTestId, ...props }) => {
  return (
    <Wrapper {...props} dataTestId={dataTestId}>
      <StyledImage alt={alt} dataTestId={dataTestId} src={src} />
    </Wrapper>
  )
}

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  src: PropTypes.string.isRequired,
}

export default Image
