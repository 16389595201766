import React from 'react'
import PropTypes from 'prop-types'

import {
  LoadingHeader,
  LoadingContainer,
  LoadingSpinner,
} from './component.styles'

const InlineLoading = props => {
  const { message, ...rest } = props
  return (
    <LoadingContainer {...rest}>
      <LoadingSpinner />
      <LoadingHeader>{props.message}</LoadingHeader>
    </LoadingContainer>
  )
}

InlineLoading.defaultProps = {
  message: 'Loading...',
}

InlineLoading.propTypes = {
  message: PropTypes.string,
}

export default InlineLoading
