import styled from 'styled-components'

import { StyledButton } from '../../Button/style'

export const MultiChoiceStyledButton = styled(StyledButton)`
  ${props => props.theme.fonts.display}
  ${props => props.theme.fonts.multiChoiceButton}
  margin-top: 1px;
  min-width: 8em;
  border-radius: 4px ${props => props.theme.colorVars.button.cornerRadius};
  border-style: solid;
  transition: background-color 0.2s ease-in, color 0.2s ease-in,
    filter 0.2s ease-in;

  :hover:enabled {
    background-color: ${props => props.theme.colorVars.button.hover.background};
    color: ${props => props.theme.colorVars.button.hover.color};
    filter: drop-shadow(
      ${props => props.theme.colorVars.button.hover.dropshadow}
    );
    path {
      stroke: ${props => props.theme.colorVars.button.stroke};
    }
  }
  :active {
    background-color: ${props =>
      props.theme.colorVars.button.selected.background};
    color: ${props => props.theme.colorVars.button.selected.color};
    path {
      stroke: ${props => props.theme.colorVars.button.stroke};
    }
  }
`
