/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

export default () => (
  <svg
    className={'icon'}
    height="49px"
    role="img"
    viewBox="0 0 49 49"
    width="49px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33 4h6v5l-6-5zm9 14.525V1.749A1.75 1.75 0 0040.25 0H28a1.75 1.75 0 00-1.138 3.078L38.5 13.055v5.47l-15.75 15.75-7.514-7.512a1.748 1.748 0 00-2.473 0L.513 39.013A1.748 1.748 0 000 40.25v7C0 48.216.784 49 1.75 49h45.5A1.75 1.75 0 0049 47.25v-21a1.75 1.75 0 00-.512-1.238L42 18.525z"
      fill="#FFF"
      fillRule="evenodd"
      opacity="0.249"
    />
  </svg>
)
