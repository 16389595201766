/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'
import PropTypes from 'prop-types'
import { DashIconStyleContainer } from './component.styles'

const DashIcon = ({ color = 'currentColor', ...props }) => (
  <DashIconStyleContainer>
    <svg
      height="17px"
      version="1.1"
      viewBox="0 0 17 17"
      width="17px"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>ss_icon_selectall_mixed</title>
      <g
        fill={color}
        fillRule="evenodd"
        id="ss_icon_selectall_mixed"
        stroke="none"
        strokeWidth="1"
      >
        <rect
          height="15"
          id="Rectangle"
          rx="3"
          stroke="#3C6E90"
          strokeWidth="2"
          width="15"
          x="1"
          y="1"
        />
        <line
          id="Path-5"
          stroke="#3C6E90"
          strokeLinecap="round"
          strokeWidth="2"
          x1="5"
          x2="12"
          y1="8.5"
          y2="8.5"
        />
      </g>
    </svg>
  </DashIconStyleContainer>
)

DashIcon.propTypes = {
  color: PropTypes.string,
}

export default DashIcon
