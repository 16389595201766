import React from 'react'
import PropTypes from 'prop-types'
import {
  toNoBreakContentArray,
  toContentArray,
} from '../../utils/componentHelpers'

const MarkdownSpan = ({
  children = [],
  className,
  'data-testid': dataTestId,
}) => {
  const markdownContent = toContentArray(children)
  const noBreakContentArray = toNoBreakContentArray(markdownContent)
  return (
    <span className={className} data-testid={dataTestId}>
      {noBreakContentArray}
    </span>
  )
}

MarkdownSpan.propTypes = {
  'data-testid': PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  className: PropTypes.string,
}

export default MarkdownSpan
