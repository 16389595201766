import styled from 'styled-components/native'

export const ErrorMessage = styled.Text.attrs(
  ({
    hasUrl,
    theme: {
      colorVars: { errorMessage },
    },
  }) => ({
    color: errorMessage.color,
    textDecorationLine: hasUrl ? 'underline' : 'none',
  }),
)`
  color: ${props => props.color};
  font-weight: 700;
  text-decoration-line: ${props => props.textDecorationLine};
`
