/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

const IconAudio = () => {
  return (
    <svg height="18px" viewBox="0 0 18.0 18.0" width="18px">
      <title>Play Audio</title>
      <path
        d="M14.896,0 C15.485,0 15.963,0.479 15.963,1.067 L15.963,10.944 L15.961,10.953 C15.978,11.083 16,11.211 16,11.345 C16,13.033 14.642,14.401 12.966,14.401 C11.29,14.401 9.931,13.033 9.931,11.345 C9.931,9.658 11.29,8.29 12.966,8.29 C13.268,8.29 13.554,8.348 13.83,8.431 L13.83,2.134 L6.033,2.134 L6.033,12.091 C6.047,12.212 6.069,12.331 6.069,12.456 C6.069,14.144 4.711,15.512 3.035,15.512 C1.359,15.512 0,14.144 0,12.456 C0,10.769 1.359,9.401 3.035,9.401 C3.337,9.401 3.623,9.459 3.899,9.542 L3.899,1.067 C3.899,0.479 4.377,0 4.966,0 Z"
        fill="currentColor"
        stroke="none"
      />
    </svg>
  )
}

export default IconAudio
