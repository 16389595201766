import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

import DragAndDrop from '../../../lib/dragAndDrop'
import { DRAG_AND_DROP_TYPES } from '../../../utils/dragAndDropHelper'
import { DRAGGABLE_TYPE } from '../constants'

import DragLabelDraggableComponent from './component'

const DEFAULT_ON_DRAG_MESSAGE =
  'Using default on drag event. Did you lose the reference to the original draggable?'
const DEFAULT_ON_DRAG_EVENT = () => console.warn(DEFAULT_ON_DRAG_MESSAGE)
const MOUSE_ENTER_EVENT = 'mouseenter'

const setupUseDrag = (props, domElement) => {
  const { id, label, onDragStart, onDragEnd } = props || {}
  const item = {
    domElement,
    id,
    interactiveType: DRAG_AND_DROP_TYPES.DRAG_LABEL,
    label,
    type: DRAGGABLE_TYPE,
  }
  const collect = monitor => ({
    isDragging: monitor.isDragging(),
  })

  return useDrag({
    begin: () => {
      onDragStart()
    },
    collect,
    end: () => {
      onDragEnd()
    },
    item,
  })
}

const DragLabelDraggableContainer = ({
  id,
  index,
  isLong,
  isVisible,
  label,
  left,
  onDragEnd,
  onDragStart,
  top,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const [domElement, setDomElement] = useState(null)
  const dragProps = {
    id,
    isLong,
    label,
    onDragStart,
    onDragEnd,
  }
  const [{ isDragging }, drag, preview] = setupUseDrag(dragProps, domElement)
  const refCallback = DragAndDrop.createDraggableRefCallback(setDomElement)

  //We're using a custom drag layer, turn off the default preview
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])

  const onMouseEvent = ({ type }) => {
    const isHovered = type === MOUSE_ENTER_EVENT

    setIsHovered(isHovered)
  }

  return (
    <DragLabelDraggableComponent
      dragRef={childElement => refCallback(childElement, drag)}
      index={index}
      isDragging={isDragging}
      isHovered={isHovered}
      isLong={isLong}
      isVisible={isVisible}
      label={label}
      left={left}
      onMouseEvent={onMouseEvent}
      top={top}
    />
  )
}

DragLabelDraggableContainer.propTypes = {
  id: PropTypes.string,
  index: PropTypes.number,
  isLong: PropTypes.bool,
  isVisible: PropTypes.bool,
  label: PropTypes.string,
  left: PropTypes.number,
  onDragEnd: PropTypes.func,
  onDragStart: PropTypes.func,
  top: PropTypes.number,
}

DragLabelDraggableContainer.defaultProps = {
  isVisible: true,
  label: '',
  onDragEnd: DEFAULT_ON_DRAG_EVENT,
  onDragStart: DEFAULT_ON_DRAG_EVENT,
  showDraggable: true,
}

export default DragLabelDraggableContainer
