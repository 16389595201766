import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { TouchableOpacity } from 'react-native-web'

import { ErrorMessage } from './style'

const ACCESSIBILITY_LABEL = 'Error message'
const ACCESSIBILITY_LABEL_WITH_LINK = 'Error message with link'
const LINK = 'link'
const NEW_WINDOW = '_blank'

class ErrorMessageComponent extends Component {
  render() {
    const { message, url } = this.props
    const hasUrl = url != null
    const errorMessageTextComponent = (
      <ErrorMessage accessibilityLabel={ACCESSIBILITY_LABEL} hasUrl={hasUrl}>
        {message}
      </ErrorMessage>
    )
    const errorComponent = url ? (
      <TouchableOpacity
        accessibilityRole={LINK}
        accessibilityLabel={ACCESSIBILITY_LABEL_WITH_LINK}
        href={url}
        target={NEW_WINDOW}
      >
        {errorMessageTextComponent}
      </TouchableOpacity>
    ) : (
      errorMessageTextComponent
    )

    return errorComponent
  }
}

ErrorMessageComponent.propTypes = {
  message: PropTypes.string,
  url: PropTypes.string,
}

export default ErrorMessageComponent
