import React, { Component } from 'react'

import PropTypes from 'prop-types'

import ContentfulImageBackground from '../../ContentfulImageBackground'
import ErrorMessage from '../../ErrorMessage'
import PageLayoutRouter from '../../PageLayoutRouter'
import CurriculumPreviewBanner from '../CurriculumPreviewBanner'
import CurriculumPreviewTitleModal from '../CurriculumPreviewTitleModal'
import FamilyBanner from '../FamilyBanner'
import HighSchoolPreviewBanner from '../HighSchoolPreviewBanner'
import HighSchoolPreviewTitleModal from '../HighSchoolPreviewTitleModal'
import PrepBanner from '../PrepBanner'

import { Container } from './style'

const SLIDE_CONTAINER_ACCESSIBILITY_LABEL = 'Slide container'
class SlideComponent extends Component {
  wrapContentInImageBackground = contentComponent => {
    const { backgroundImageUrl, backgroundImageAccessibilityLabel } = this.props

    if (!backgroundImageUrl) {
      return contentComponent
    }

    const contentWithImageBackground = (
      <ContentfulImageBackground
        accessibilityLabel={backgroundImageAccessibilityLabel}
        source={backgroundImageUrl}
      >
        {contentComponent}
      </ContentfulImageBackground>
    )

    return contentWithImageBackground
  }

  getRenderProps = () => {
    const {
      backgroundColor,
      error,
      fontColor,
      foregroundColor,
      contentfulLayout,
      onSubmit,
    } = this.props
    const contentComponentProps = error
      ? error
      : {
          foregroundColor,
          contentfulLayout,
          onSubmit,
          slideBackgroundColor: backgroundColor,
        }
    let contentComponent = error ? (
      <ErrorMessage {...contentComponentProps} />
    ) : (
      <PageLayoutRouter {...contentComponentProps} />
    )
    contentComponent = error
      ? contentComponent
      : this.wrapContentInImageBackground(contentComponent)
    const containerProps = {
      accessibilityLabel: SLIDE_CONTAINER_ACCESSIBILITY_LABEL,
      backgroundColor,
      fontColor,
    }
    const renderProps = {
      containerProps,
      contentComponent,
    }

    return renderProps
  }

  render() {
    const { containerProps, contentComponent } = this.getRenderProps() || {}

    return (
      <Container {...containerProps}>
        <PrepBanner />
        <FamilyBanner />
        <CurriculumPreviewBanner />
        <HighSchoolPreviewBanner />
        <CurriculumPreviewTitleModal />
        <HighSchoolPreviewTitleModal />
        {contentComponent}
      </Container>
    )
  }
}

SlideComponent.propTypes = {
  backgroundColor: PropTypes.string,
  backgroundImageAccessibilityLabel: PropTypes.string,
  backgroundImageUrl: PropTypes.string,
  error: PropTypes.shape({
    message: PropTypes.string,
    url: PropTypes.string,
  }),
  fontColor: PropTypes.string,
  foregroundColor: PropTypes.string,
  contentfulLayout: PropTypes.object,
  onSubmit: PropTypes.func,
}

SlideComponent.defaultProps = {
  backgroundImageAccessibilityLabel: "' '",
  onSubmit() {},
}

export default SlideComponent
