import PropTypes from 'prop-types'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Markdown } from 'grommet'
import { fontWeightNormal } from '../../theme/defaultValues.js'

import TextLink from '../TextLink'
import HeaderOne from '../HeaderOne'
import HeaderTwo from '../HeaderTwo'
import HeaderThree from '../HeaderThree'
import HeaderFour from '../HeaderFour'
import HeaderFive from '../HeaderFive'
import HeaderSix from '../HeaderSix'
import MarkdownP from '../MarkdownP'
import MarkdownSpan from '../MarkdownSpan'
import MarkdownEm from '../MarkdownEm'
import MarkdownStrong from '../MarkdownStrong'

const components = {
  a: {
    component: TextLink,
    props: { dataTestId: 'text-link' },
  },
  h1: {
    component: HeaderOne,
    props: { dataTestId: 'header-one' },
  },
  h2: {
    component: HeaderTwo,
    props: { dataTestId: 'header-two' },
  },
  h3: {
    component: HeaderThree,
    props: { dataTestId: 'header-three' },
  },
  h4: {
    component: HeaderFour,
    props: { dataTestId: 'header-four' },
  },
  h5: {
    component: HeaderFive,
    props: { dataTestId: 'header-five' },
  },
  h6: {
    component: HeaderSix,
    props: { dataTestId: 'header-six' },
  },
  p: {
    component: MarkdownP,
  },
  span: {
    component: MarkdownSpan,
  },
  strong: {
    component: MarkdownStrong,
  },
  em: {
    component: MarkdownEm,
  },
}

// wanna default to empty string so that it doesn't blow up if undefined is passed in
const BodyCopy = styled(Markdown).attrs(
  ({ children = '', 'data-testid': dataTestId }) => {
    return {
      children: children || '',
      components,
      'data-testid': dataTestId,
    }
  },
)`
  max-width: 100%;

  p,
  span {
    max-width: 100%;
  }
  p:not(:last-child) {
    margin-block-end: 1rem;
  }
  strong {
    font-family: ${themeGet('fontFamilyBold')};
    font-weight: ${fontWeightNormal};
  }
  line-height: 1.375rem;
  a {
    color: black;
    display: inline;
    text-decoration: underline;
  }
  li {
    ::marker {
      color: ${themeGet('colors.markerOverride') ?? 'inherit'};
    }
  }
`

BodyCopy.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BodyCopy
