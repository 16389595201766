import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { fontWeightNormal } from '../../theme/defaultValues'
import FormField from '../FormField'

export const FormFieldCheckBox = styled(FormField)`
  > label {
    font-family: ${themeGet('fontFamilySemibold')};
    font-size: 1.25rem;
    font-weight: ${fontWeightNormal};
    line-height: 1.5rem;
    margin-bottom: 0.875rem;
  }
`

export const CheckBoxOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${themeGet('breakpoints.mobile')} {
    flex-direction: column;
  }
`
