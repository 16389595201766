/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

export default () => (
  <svg
    className={'icon'}
    height="47px"
    role="img"
    viewBox="0 0 61 47"
    width="61px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#FFF" fillRule="evenodd" opacity="0.153">
      <path
        d="M30.575.25c3.58 0 6.508 2.85 6.508 6.385v3.031h-3.5V6.635c0-1.58-1.34-2.885-3.008-2.885H6.757c-1.67 0-3.007 1.303-3.007 2.885v13.904c0 1.58 1.34 2.884 3.007 2.884h6.517v8.507L23 24.272v4.454l-8.55 6.732-.143.109c-2.118 1.529-4.533.35-4.533-2.342v-6.303H6.757c-3.58 0-6.507-2.849-6.507-6.383V6.635C.25 3.099 3.174.25 6.757.25h23.818zm6.508 13.416v6.873l-.004.23c-.123 3.427-2.999 6.154-6.504 6.154L27 26.92v-3.499h3.575c1.67 0 3.008-1.302 3.008-2.883v-6.873h3.5z"
        fillRule="nonzero"
      />
      <path d="M50.81 36.84v8.052c0 1.275-.83 1.655-1.845.858l-11.317-8.91h-5.89c-2.627 0-4.758-2.077-4.758-4.634V18.3c0-2.56 2.137-4.634 4.758-4.634h23.818c2.628 0 4.757 2.076 4.757 4.634v13.905c0 2.558-2.135 4.633-4.757 4.633H50.81z" />
    </g>
  </svg>
)
