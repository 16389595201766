import React from 'react'

function StepFourIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <circle cx="10" cy="10" r="10" fill="currentColor" />
        <g fill="#FFF" fillRule="nonzero" transform="translate(6.546 5.4)">
          <path d="M6.917 6.02h-.94V0H4.174L0 6.135v1.161h4.403L4.402 9.1h1.575V7.296h.94V6.02zm-5.171 0l2.662-3.967-.003 3.967h-2.66z" />
        </g>
      </g>
    </svg>
  )
}

export default StepFourIcon
