import styled from 'styled-components'

import BannerComponent from '../../Banner'

export const AdditionalDetailsText = styled.span`
  margin-left: 5rem;
  text-align: left;
  font-size: 1.125rem;
  line-height: 1.375rem;
  letter-spacing: 0.05rem;
  display: block;
  a {
    color: white;
  }
  strong {
    ${({ theme }) => theme.fonts.prepBannerBold};
    font-size: 1.125rem;
  }
`

export const StyledBanner = styled(BannerComponent)`
  border-radius: 0.6rem;
  width: 35%;
  height: 3.75rem;
`

export const TelescopeIconWrapper = styled.div`
  float: left;
  margin-top: 0.5rem;
  margin-left: 0.6rem;
`
