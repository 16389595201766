import styled from 'styled-components'
import PropTypes from 'prop-types'
import { themeGet } from 'styled-system'
import { TextArea } from 'grommet'

export const FormInputTextArea = styled(TextArea).attrs(
  ({ dataTestId, disabled, label, onChange }) => ({
    'aria-label': label,
    'data-testid': dataTestId,
    onChange,
    plain: true,
    resize: false,
    borderColor: disabled
      ? themeGet('colors.input.textarea.borderDisabled')
      : themeGet('colors.input.textarea.borderEnabled'),
    hoverBorderColor: !disabled && themeGet('colors.input.textarea.hover'),
  }),
)`
  background-color: ${themeGet('colors.input.textarea.background')};
  border: 2px solid ${({ borderColor }) => borderColor};
  border-radius: 5px;
  box-sizing: border-box;
  color: ${themeGet('colors.input.textarea.foreground')};
  font-weight: 400;
  height: 5.8125rem;
  line-height: 1.625rem;
  padding: 0.5rem 0.75rem 0.75rem;

  :focus {
    border-color: ${themeGet('colors.input.textarea.focus')};
  }

  :hover {
    border-color: ${({ hoverBorderColor }) => hoverBorderColor};
  }

  ::placeholder {
    font-style: italic;
    color: ${themeGet('colors.input.textarea.placeholder')};
  }
`

FormInputTextArea.propTypes = {
  dataTestId: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}
