/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

export default () => (
  <svg
    height="19px"
    version="1.1"
    viewBox="0 0 23 19"
    width="23px"
    x="0px"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
  >
    <g transform="translate(-290.000000, -572.000000)">
      <g transform="translate(40.000000, 398.000000)">
        <g transform="translate(27.000000, 137.547350)">
          <g id="conflicts-icon" transform="translate(223.000000, 36.452650)">
            <path
              className="st0"
              d="M15.2,7.4c-0.1-0.1-0.2-0.1-0.3,0c-0.8,0.3-1.6,0.6-2.5,0.8c0-0.1,0-0.1,0-0.1
						c0.2-1.7,0.3-3.3,0.5-5C12.9,2.6,13,2,13,1.5c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0-0.1,0.1-0.2,0.2
						C11.7,2.9,10.8,4.4,10,6c-0.8,1.5-1.6,3.1-2.4,4.6c-0.1,0.1-0.1,0.3,0,0.4c0.1,0.1,0.2,0.1,0.4,0.1c0.2-0.1,0.5-0.2,0.7-0.3
						c0.6-0.2,1.1-0.4,1.7-0.6c0,0,0,0.1,0,0.1c-0.2,2.2-0.4,4.4-0.6,6.5c0,0.3,0,0.4,0.2,0.4c0.2,0,0.3,0,0.4-0.3
						c1.4-2.7,2.8-5.3,4.2-8c0.2-0.4,0.5-0.9,0.7-1.3C15.3,7.6,15.3,7.5,15.2,7.4"
              fill="currentColor"
            />
            <g transform="translate(15.510000, 0.314886)">
              <defs>
                <filter
                  filterUnits="userSpaceOnUse"
                  height="4.1"
                  width="4.1"
                  x="0"
                  y="0"
                >
                  <feColorMatrix
                    type="matrix"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                  />
                </filter>
              </defs>
              <mask
                height="4.1"
                maskUnits="userSpaceOnUse"
                width="4.1"
                x="0"
                y="0"
              >
                <g className="st1">
                  <polygon className="st2" points="0,0 4.1,0 4.1,4.1 0,4.1 								" />
                </g>
              </mask>
              <path
                className="st3"
                d="M0.8,4.1C1,4.1,1.1,4,1.3,3.9l2.6-2.6C4,1.1,4.1,0.9,4.1,0.7S4,0.4,3.9,0.2
							c-0.3-0.3-0.7-0.3-1,0L0.2,2.9C0,3.1,0,3.6,0.2,3.9C0.4,4,0.6,4.1,0.8,4.1"
                fill="currentColor"
              />
            </g>
            <path
              className="st0"
              d="M6,14.3l-2.6,2.6c-0.3,0.3-0.3,0.7,0,1c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2L7,15.3
						c0.1-0.1,0.2-0.3,0.2-0.5c0-0.2-0.1-0.4-0.2-0.5C6.8,14,6.3,14,6,14.3"
              fill="currentColor"
            />
            <g transform="translate(2.970000, 0.314886)">
              <defs>
                <filter
                  filterUnits="userSpaceOnUse"
                  height="4.1"
                  width="4.1"
                  x="0.2"
                  y="0"
                >
                  <feColorMatrix
                    type="matrix"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                  />
                </filter>
              </defs>
              <mask
                height="4.1"
                maskUnits="userSpaceOnUse"
                width="4.1"
                x="0.2"
                y="0"
              >
                <g className="st4">
                  <polygon
                    className="st2"
                    points="0.2,0 4.3,0 4.3,4.1 0.2,4.1 								"
                  />
                </g>
              </mask>
              <path
                className="st5"
                d="M3.1,3.9C3.2,4,3.4,4.1,3.6,4.1S3.9,4,4.1,3.9c0.3-0.3,0.3-0.7,0-1L1.4,0.2
							c-0.3-0.3-0.7-0.3-1,0C0.3,0.4,0.2,0.5,0.2,0.7c0,0.2,0.1,0.4,0.2,0.5L3.1,3.9z"
                fill="currentColor"
              />
            </g>
            <g transform="translate(15.510000, 13.844886)">
              <defs>
                <filter
                  filterUnits="userSpaceOnUse"
                  height="4.1"
                  width="4.1"
                  x="0"
                  y="0.2"
                >
                  <feColorMatrix
                    type="matrix"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                  />
                </filter>
              </defs>
              <mask
                height="4.1"
                maskUnits="userSpaceOnUse"
                width="4.1"
                x="0"
                y="0.2"
              >
                <g className="st6">
                  <polygon
                    className="st2"
                    points="0,0.2 4.1,0.2 4.1,4.3 0,4.3 								"
                  />
                </g>
              </mask>
              <path
                className="st7"
                d="M1.3,0.4c-0.3-0.3-0.7-0.3-1,0C0.1,0.6,0,0.7,0,0.9s0.1,0.4,0.2,0.5l2.6,2.6
							C3,4.2,3.2,4.3,3.4,4.3c0.2,0,0.4-0.1,0.5-0.2c0.3-0.3,0.3-0.7,0-1L1.3,0.4z"
                fill="currentColor"
              />
            </g>
            <path
              className="st0"
              d="M22.7,8.4c-1.1,0-1.7,0-2.9,0c0,0-0.1,0-0.1-0.1l0-2.4c0-0.1-0.1-0.1-0.1,0l-3.4,3.2
						c0,0,0,0.1,0,0.1l3.4,3.2c0,0,0.1,0,0.1,0l0-2.4c0,0,0-0.1,0.1-0.1c1.1,0,1.7,0,2.9,0c0,0,0.1,0,0.1-0.1
						C22.8,9.5,22.8,9,22.7,8.4C22.8,8.5,22.8,8.4,22.7,8.4"
              fill="currentColor"
            />
            <g transform="translate(0.000000, 5.924886)">
              <defs>
                <filter
                  filterUnits="userSpaceOnUse"
                  height="6.5"
                  width="6.5"
                  x="0"
                  y="0"
                >
                  <feColorMatrix
                    type="matrix"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                  />
                </filter>
              </defs>
              <mask
                height="6.5"
                maskUnits="userSpaceOnUse"
                width="6.5"
                x="0"
                y="0"
              >
                <g className="st8">
                  <polygon className="st2" points="0,0 6.5,0 6.5,6.6 0,6.6 								" />
                </g>
              </mask>
              <path
                className="st9"
                d="M6.5,3.2L3.1,0c0,0-0.1,0-0.1,0l0,2.4c0,0,0,0.1-0.1,0.1c-1.1,0-1.7,0-2.9,0
							c0,0-0.1,0-0.1,0.1C0,3,0,3.5,0,4c0,0.1,0,0.1,0.1,0.1c1.1,0,1.7,0,2.9,0c0,0,0.1,0,0.1,0.1l0,2.4c0,0.1,0.1,0.1,0.1,0
							L6.5,3.2C6.5,3.3,6.5,3.3,6.5,3.2"
                fill="currentColor"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
