import React from 'react'

import PropTypes from 'prop-types'

import {
  checkLayoutForErrors,
  getElementsForLayout,
  getHeroDataForLayout,
  getIsDragLabelToImageInteractiveForLayout,
  getIsDragTheWordInteractiveForLayout,
  getIsImageForLayout,
  getIsVideoForLayout,
  getNumOfElementsForLayout,
  getTitleForLayout,
  isModuleInteractiveOfType,
} from '../../utils/contentfulHelper'

import PageLayoutRouterComponent from './component'
import {
  DEFAULT_LAYOUT_DEPRECATED,
  DRAG_LABEL_TO_IMAGE,
  DRAG_THE_WORD,
  IMAGE_WITH_TITLE,
  ONE_THIRD_TWO_THIRD_COLUMN_NO_HERO,
  PAGE_LAYOUT_COMPONENTS,
  THREE_COLUMN_NO_HERO,
  VIDEO_WITH_TITLE,
} from './routes'

const PageLayoutRouterContainer = ({
  contentfulLayout,
  foregroundColor,
  onSubmit,
  slideBackgroundColor,
}) => {
  const getPageRoute = layout => {
    const numOfElements = getNumOfElementsForLayout(layout) || {}
    let getRouteFunction

    switch (numOfElements) {
      case 1: {
        getRouteFunction = getOneElementPageRoute
        break
      }
      case 2: {
        getRouteFunction = getTwoElementPageRoute
        break
      }
      case 3: {
        getRouteFunction = getThreeElementPageRoute
        break
      }
      default: {
        getRouteFunction = () => DEFAULT_LAYOUT_DEPRECATED
        break
      }
    }

    const route = getRouteFunction(layout) || DEFAULT_LAYOUT_DEPRECATED

    return route
  }

  const getOneElementPageRoute = layout => {
    const title = getTitleForLayout(layout)
    const isVideo = getIsVideoForLayout(layout)
    const isVideoWithTitle = title && isVideo
    const isImage = getIsImageForLayout(layout)
    const isImageWithTitle = title && isImage
    const isDragTheWord = getIsDragTheWordInteractiveForLayout(layout)
    const isDragLabelToImage = getIsDragLabelToImageInteractiveForLayout(layout)

    if (isVideoWithTitle) {
      return VIDEO_WITH_TITLE
    } else if (isImageWithTitle) {
      return IMAGE_WITH_TITLE
    } else if (isDragTheWord) {
      return DRAG_THE_WORD
    } else if (isDragLabelToImage) {
      return DRAG_LABEL_TO_IMAGE
    } else {
      return DEFAULT_LAYOUT_DEPRECATED
    }
  }

  const getTwoElementPageRoute = layout => {
    const elements = getElementsForLayout(layout) || []
    const secondElement = elements[1] || {}
    const secondElementFirstModule = secondElement[0] || {}
    const isHotspotsInteractive = isModuleInteractiveOfType(
      secondElementFirstModule,
      'ImageHotspots',
    )
    const route = isHotspotsInteractive
      ? ONE_THIRD_TWO_THIRD_COLUMN_NO_HERO
      : DEFAULT_LAYOUT_DEPRECATED

    return route
  }

  const getThreeElementPageRoute = layout => {
    const { url } = getHeroDataForLayout(layout) || {}
    const hasHero = url != null
    const isThreeColumnNoHero = !hasHero
    const route = isThreeColumnNoHero
      ? THREE_COLUMN_NO_HERO
      : DEFAULT_LAYOUT_DEPRECATED

    return route
  }

  const getRenderProps = () => {
    const routeName = getPageRoute(contentfulLayout)
    const routeObject = PAGE_LAYOUT_COMPONENTS[routeName] || {}
    const { showTitle: showTitleForRoute = true } = routeObject
    const showTitle = showTitleForRoute
    const title = showTitle ? getTitleForLayout(contentfulLayout) : null
    const error = checkLayoutForErrors(contentfulLayout)
    const elements = getElementsForLayout(contentfulLayout)
    const {
      altText: heroAccessibilityLabel,
      position: heroPosition,
      size: heroSize,
      url: heroImage,
    } = getHeroDataForLayout(contentfulLayout) || {}
    const layoutRouterProps = {
      elements,
      error,
      foregroundColor,
      heroImage,
      heroAccessibilityLabel,
      onSubmit,
      route: routeName,
      slideBackgroundColor,
      title,
      //TODO: heroPosition and heroSize will no longer be needed when default layout can be phased out
      heroPosition,
      heroSize,
    }

    return layoutRouterProps
  }

  const layoutRouterProps = getRenderProps() || {}

  return <PageLayoutRouterComponent {...layoutRouterProps} />
}

PageLayoutRouterContainer.propTypes = {
  foregroundColor: PropTypes.string,
  contentfulLayout: PropTypes.object,
  onSubmit: PropTypes.func,
  slideBackgroundColor: PropTypes.string,
}

PageLayoutRouterContainer.defaultProps = {
  onSubmit() {},
}

export default PageLayoutRouterContainer
