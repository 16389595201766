/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

const TelescopeV2Icon = props => (
  <svg
    height="17px"
    version="1.1"
    viewBox="0 0 21 17"
    width="21px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(0.036685, -0.000000)">
        <g
          fill="currentColor"
          transform="translate(10.171001, 5.961182) rotate(-17.000000) translate(-10.171001, -5.961182) translate(0.540924, 2.671833)"
        >
          <rect
            height="5.19370979"
            rx="0.568430612"
            width="6.75924425"
            x="4.94717991"
            y="0.839421727"
          />
          <rect
            height="6.57869907"
            rx="0.568430612"
            transform="translate(15.260153, 3.289350) scale(-1, 1) translate(-15.260153, -3.289350)"
            width="8"
            x="11.2601528"
            y="0"
          />
          <rect
            height="3.11622587"
            rx="0.568430612"
            transform="translate(3.540839, 3.598333) scale(-1, 1) translate(-3.540839, -3.598333)"
            width="3.91324667"
            x="1.58421571"
            y="2.04022013"
          />
          <rect
            height="5.19370979"
            rx="0.568430612"
            width="2.13449819"
            x="0"
            y="1.16353463"
          />
        </g>
        <line
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="1.5"
          x1="10.3301955"
          x2="7.83994761"
          y1="8.56271755"
          y2="15.9162425"
        />
        <line
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="1.5"
          x1="10.3301955"
          x2="13.1761931"
          y1="8.56271755"
          y2="15.9162425"
        />
      </g>
    </g>
  </svg>
)

export default TelescopeV2Icon
