import React from 'react'

import PropTypes from 'prop-types'

import { EMPTY_STRING } from '../constants'
import DragQuestionDraggables from '../DragQuestionDraggables'

import {
  ARIA_LABEL,
  DATA_TEST_ID_DROP_ZONE_PREFIX,
  DATA_TEST_ID_DROP_ZONE_TITLE_SUFFIX,
  DATA_TEST_ID_PLACEHOLDER,
  NO_PLACEHOLDER_HEIGHT,
} from './constants'
import {
  DraggablePlaceholder,
  DropZoneContainer,
  NoPlaceholder,
  TitleBar,
  TitleText,
} from './style'

const DragQuestionDropZoneComponent = ({
  canSubmitForm,
  draggables,
  dropRef,
  focusOnDraggable,
  index,
  isLast,
  isOver,
  isSubmitted,
  label,
  onKeyDown,
  placeholderHeight,
}) => {
  const getPlacementZone = () =>
    isOver ? (
      <DraggablePlaceholder
        data-testid={DATA_TEST_ID_PLACEHOLDER}
        placeholderHeight={placeholderHeight}
      />
    ) : (
      <NoPlaceholder />
    )

  const dropZoneContainerDataTestId = `${DATA_TEST_ID_DROP_ZONE_PREFIX}${index}`
  const dropZoneTitleDataTestId = `${dropZoneContainerDataTestId}${DATA_TEST_ID_DROP_ZONE_TITLE_SUFFIX}`

  return (
    <DropZoneContainer
      aria-label={ARIA_LABEL}
      data-testid={dropZoneContainerDataTestId}
      isLast={isLast}
      isOver={isOver}
      ref={dropRef}
    >
      <TitleBar data-testid={dropZoneTitleDataTestId}>
        <TitleText>{label}</TitleText>
      </TitleBar>
      <DragQuestionDraggables
        canSubmitForm={canSubmitForm}
        draggables={draggables}
        focusOnDraggable={focusOnDraggable}
        isSubmitted={isSubmitted}
        onKeyDown={onKeyDown}
      />
      {getPlacementZone()}
    </DropZoneContainer>
  )
}

DragQuestionDropZoneComponent.propTypes = {
  canSubmitForm: PropTypes.func,
  draggables: PropTypes.array,
  dropRef: PropTypes.func,
  focusOnDraggable: PropTypes.string,
  index: PropTypes.number,
  isLast: PropTypes.bool,
  isOver: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  label: PropTypes.string,
  onKeyDown: PropTypes.func,
  placeholderHeight: PropTypes.number,
}

DragQuestionDropZoneComponent.defaultProps = {
  canSubmitForm: () => {},
  draggables: [],
  dropRef() {},
  index: 0,
  label: EMPTY_STRING,
  onKeyDown() {},
  placeholderHeight: NO_PLACEHOLDER_HEIGHT,
}

export default DragQuestionDropZoneComponent
