import styled from 'styled-components'
import IconCaretSelect from '../IconCaretSelect'
import { themeGet } from 'styled-system'

export const AccordionWrapper = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  display: flex;
  flex-direction: column;
  background: ${props => props.panelColor || '#eee'};
  border-radius: 10px;
  box-sizing: border-box;
  &.keyboard-focus {
    box-shadow: 0 0 2px 2px #3c6e90;
  }
`

export const AccordionTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const AccordionTitle = styled.span`
  margin: 0rem 1.25rem 0rem 0rem;
  line-height: 2.188rem;
  font-weight: 500;
  font-size: 1.25rem;
  text-align: left;

  color: ${props => props.fontColor || 'inherit'};

  ${({ theme }) => theme?.breakpoints?.mobile} {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
`
export const AccordionStatus = styled.div`
  margin-left: auto;

  ${themeGet('breakpoints.tablet')} {
    margin-left: 2rem;
  }
`

export const AccordionPanel = styled.button.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  padding: 1.75rem;
  background-color: ${props => props.panelColor || '#eee'};
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  border-radius: 10px;

  ${({ theme }) => theme?.breakpoints?.mobile} {
    padding: 1.25rem;
  }

  ${themeGet('breakpoints.tablet')} {
    flex-direction: column;
    align-items: start;
  }
`
export const AccordionContentWrapper = styled.div`
  overflow: hidden;
  border-radius: 10px;
  background: white;
  margin: 0px 3px 3px 3px;
`

export const AccordionContent = styled.div`
  line-height: 26px;
  padding: ${props => props.contentPadding || '1.75rem'};
  border-radius: 10px;
  font-family: ${themeGet('fontFamily')};
  ${({ theme }) => theme?.breakpoints?.mobile} {
    padding: ${props => props.contentPadding || '1.25rem'};
  }
`

export const StyledCaret = styled(IconCaretSelect)`
  height: 16px;
  padding: 0.75rem;
  color: ${props => props.iconcolor || themeGet('colors.themePrimary')};
  transition: transform 200ms ease;
  &.rotate {
    transform: rotate(-180deg);
  }
  ${AccordionPanel}:hover & {
    color: inherit;
  }
  &.keyboard-focus {
    border-radius: 50%;
    box-shadow: 0 0 2px 2px #3c6e90;
  }
`
