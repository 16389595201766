import { optimisticResponse } from '../../utils/reduxHelpers'

import {
  NAMESPACE,
  SUCCESS_TYPE,
  REQUEST_TYPE,
  FAILURE_TYPE,
  SET_SITE_ID_TYPE,
  UPDATE_SITE_PROGRAM_PREFERENCES_TYPE,
} from './types'

export const initialState = { hsActiveSiteId: null }
export const REDUCER_KEY = NAMESPACE

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case REQUEST_TYPE:
      return optimisticResponse(state, REDUCER_KEY)

    case FAILURE_TYPE:
      const error =
        action.payload || action.error || 'Error fetching user context'
      return {
        ...state,
        isFetching: false,
        userContext: {},
        error,
      }

    case SUCCESS_TYPE:
      const { location, paks, sites } = action.payload
      return {
        ...state,
        error: action.error,
        isFetching: false,
        lastFetched: Date.now(),
        location,
        paks,
        sites,
      }

    case SET_SITE_ID_TYPE:
      const hsActiveSiteId = action.meta.siteId
      return {
        ...state,
        hsActiveSiteId,
      }

    case UPDATE_SITE_PROGRAM_PREFERENCES_TYPE: {
      const { siteId, programName, preferences } = action.payload
      const preferencesWithoutNulls = Object.entries(preferences).reduce(
        (newObj, [key, value]) => {
          if (value !== null) {
            newObj[key] = value
          }
          return newObj
        },
        {},
      )
      const siteIndex = state.sites.findIndex(site => site.siteId === siteId)
      if (siteIndex !== -1) {
        const newSites = [...state.sites]
        const siteToUpdate = newSites[siteIndex]
        newSites[siteIndex] = {
          ...siteToUpdate,
          programs: {
            ...siteToUpdate.programs,
            [programName]: {
              ...(siteToUpdate.programs[programName] || {}),
              ...preferencesWithoutNulls,
            },
          },
        }
        return {
          ...state,
          sites: newSites,
        }
      }
      return state
    }

    default: {
      return state
    }
  }
}
