import Dialogcards from '../../Dialogcards'
import DragLabel from '../../DragLabel'
import DragQuestion from '../../DragQuestion'
import DragWord from '../../DragWord'
import ImageHotspots from '../../ImageHotspots'
import ImageJuxtaposition from '../../ImageJuxtaposition'
import MultiChoice from '../../MultiChoice'

export default {
  Dialogcards,
  DragLabel,
  DragQuestion,
  DragWord,
  ImageHotspots,
  ImageJuxtaposition,
  MultiChoice,
}
