/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

const BackpackV2Icon = props => (
  <svg
    height="51px"
    version="1.1"
    viewBox="0 0 38 51"
    width="38px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(2.000000, 2.000000)">
        <path
          d="M17,5.47852761 C26.3888407,5.47852761 34,13.0896869 34,22.4785276 L34,42.6410256 C34,45.0484207 32.0484207,47 29.6410256,47 L4.35897436,47 C1.9515793,47 4.57121355e-15,45.0484207 0,42.6410256 L0,22.4785276 C-1.14980138e-15,13.0896869 7.61115925,5.47852761 17,5.47852761 Z"
          fill="transparent"
          stroke="currentColor"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          stroke="currentColor"
          strokeLinecap="square"
          strokeWidth="1.30769231"
          x1="4.79487179"
          x2="29.2051282"
          y1="35.4325153"
          y2="35.4325153"
        />
        <rect
          height="10.3803681"
          rx="1.74358974"
          stroke="currentColor"
          strokeWidth="1.5"
          width="25.2820513"
          x="4.35897436"
          y="32.2944785"
        />
        <path
          d="M14.1165644,-1.91143621 L15.0885638,-1.91143621 C17.7366984,-1.91143621 19.8834356,0.235301013 19.8834356,2.88343558 C19.8834356,5.53157015 17.7366984,7.67830738 15.0885638,7.67830738 L14.1165644,7.67830738 L14.1165644,7.67830738"
          stroke="currentColor"
          strokeWidth="1.5"
          transform="translate(17.000000, 2.883436) rotate(-90.000000) translate(-17.000000, -2.883436) "
        />
        <path
          d="M31.1783975,19.5469159 C31.1783975,11.7771596 24.8305127,5.47852761 17,5.47852761 C9.16948728,5.47852761 2.82160249,11.7771596 2.82160249,19.5469159 C2.82160249,27.3166722 31.1783975,27.3166722 31.1783975,19.5469159 Z"
          fill="#FFFFFF"
          stroke="currentColor"
          strokeWidth="1.5"
          transform="translate(17.000000, 15.426380) rotate(-180.000000) translate(-17.000000, -15.426380) "
        />
        <rect
          fill="#FFFFFF"
          height="4.32515337"
          rx="0.871794872"
          stroke="currentColor"
          strokeWidth="1.30769231"
          width="2.61538462"
          x="15.6923077"
          y="22.7791411"
        />
        <rect
          fill="currentColor"
          height="2.59509202"
          rx="0.653846154"
          width="1.30769231"
          x="26.1538462"
          y="35"
        />
      </g>
    </g>
  </svg>
)

export default BackpackV2Icon
