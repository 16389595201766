import styled from 'styled-components'
import { themeGet } from 'styled-system'
import TextLink from '../TextLink'

export const StyledTextLink = styled(TextLink)`
  flex-shrink: 0; // firefox fix: was reducing width of anchor element by 2px
  color: ${themeGet('colors.linkLight')};
  transition: color 0.2s ease;

  &:hover {
    color: ${themeGet('colors.linkLightHover')};
    text-decoration: none;
  }

  svg {
    width: 20px;
    height: 20px;
    margin: 0 0.25rem 0.1875rem 0;
  }
`
