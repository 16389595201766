/* istanbul ignore file */
// we don't unit test SVGs
import React from 'react'
import PropTypes from 'prop-types'

const ThreeStarIcon = ({ height = 48, width = 103 }) => {
  return (
    <svg
      data-testid="three-star-icon"
      height={height}
      viewBox="0 0 103 48"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        fill="none"
        fillRule="evenodd"
        id="three-star-icon"
        stroke="none"
        strokeWidth="1"
      >
        <path
          d="M51.8564065,36.1861049 L39.0162514,43.2108551 C38.5317371,43.4759292 37.9240756,43.2980377 37.6590015,42.8135234 C37.5479878,42.6106076 37.5107478,42.3754832 37.5536236,42.1481938 L40.266732,27.7657135 L40.266732,27.7657135 L29.6179714,17.7247676 C29.2161478,17.3458795 29.1975549,16.7129875 29.5764431,16.3111639 C29.7351223,16.1428793 29.9472311,16.0348044 30.1766455,16.0053455 L44.6935937,14.141234 L44.6935937,14.141234 L50.9524528,0.910837962 C51.1886265,0.411598119 51.784797,0.198340888 52.2840368,0.434514605 C52.4931196,0.533424697 52.6614501,0.701755175 52.7603602,0.910837962 L59.0192192,14.141234 L59.0192192,14.141234 L73.5361674,16.0053455 C74.0839544,16.0756864 74.4710009,16.5767782 74.4006599,17.1245652 C74.371201,17.3539796 74.2631262,17.5660884 74.0948415,17.7247676 L63.4460809,27.7657135 L63.4460809,27.7657135 L66.1591893,42.1481938 C66.2615666,42.6909068 65.904604,43.2138557 65.361891,43.316233 C65.1346016,43.3591088 64.8994772,43.3218688 64.6965615,43.2108551 L51.8564065,36.1861049 L51.8564065,36.1861049 Z"
          fill="#00A3A0"
          id="Star"
        />
        <path
          d="M87.8564065,40.7991071 L80.3063187,44.9297022 C79.8218043,45.1947763 79.2141428,45.0168848 78.9490687,44.5323704 C78.8380551,44.3294547 78.8008151,44.0943303 78.8436909,43.8670409 L80.4390148,35.4100566 L80.4390148,35.4100566 L74.17748,29.5059206 C73.7756564,29.1270324 73.7570636,28.4941405 74.1359517,28.0923169 C74.2946309,27.9240322 74.5067397,27.8159574 74.7361542,27.7864984 L83.2722063,26.6903898 L83.2722063,26.6903898 L86.9524528,18.910838 C87.1886265,18.4115981 87.784797,18.1983409 88.2840368,18.4345146 C88.4931196,18.5334247 88.6614501,18.7017552 88.7603602,18.910838 L92.4406066,26.6903898 L92.4406066,26.6903898 L100.976659,27.7864984 C101.524446,27.8568394 101.911492,28.3579311 101.841151,28.9057181 C101.811692,29.1351326 101.703618,29.3472413 101.535333,29.5059206 L95.2737981,35.4100566 L95.2737981,35.4100566 L96.869122,43.8670409 C96.9714993,44.4097538 96.6145367,44.9327028 96.0718238,45.03508 C95.8445343,45.0779559 95.6094099,45.0407158 95.4064942,44.9297022 L87.8564065,40.7991071 L87.8564065,40.7991071 Z"
          fill="#6A5F94"
          id="Star"
          transform="translate(87.856406, 33.000000) rotate(-330.000000) translate(-87.856406, -33.000000) "
        />
        <path
          d="M14.8564065,40.7991071 L7.3063187,44.9297022 C6.82180434,45.1947763 6.21414283,45.0168848 5.94906874,44.5323704 C5.8380551,44.3294547 5.80081506,44.0943303 5.84369089,43.8670409 L7.43901479,35.4100566 L7.43901479,35.4100566 L1.17748004,29.5059206 C0.775656426,29.1270324 0.757063558,28.4941405 1.13595171,28.0923169 C1.29463093,27.9240322 1.5067397,27.8159574 1.73615415,27.7864984 L10.2722063,26.6903898 L10.2722063,26.6903898 L13.9524528,18.910838 C14.1886265,18.4115981 14.784797,18.1983409 15.2840368,18.4345146 C15.4931196,18.5334247 15.6614501,18.7017552 15.7603602,18.910838 L19.4406066,26.6903898 L19.4406066,26.6903898 L27.9766588,27.7864984 C28.5244458,27.8568394 28.9114923,28.3579311 28.8411513,28.9057181 C28.8116923,29.1351326 28.7036175,29.3472413 28.5353329,29.5059206 L22.2737981,35.4100566 L22.2737981,35.4100566 L23.869122,43.8670409 C23.9714993,44.4097538 23.6145367,44.9327028 23.0718238,45.03508 C22.8445343,45.0779559 22.6094099,45.0407158 22.4064942,44.9297022 L14.8564065,40.7991071 L14.8564065,40.7991071 Z"
          fill="#FF8286"
          id="Star"
          opacity="0.925130208"
          transform="translate(14.856406, 33.000000) scale(-1, 1) rotate(-330.000000) translate(-14.856406, -33.000000) "
        />
      </g>
    </svg>
  )
}

ThreeStarIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
}

export default ThreeStarIcon
