/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'
import PropTypes from 'prop-types'

const WebinarIcon = ({ fill, title, ...rest }) => (
  <svg {...rest}>
    {title && <title>{title}</title>}
    <g
      fill="none"
      fillRule="evenodd"
      id="webinar"
      stroke="none"
      strokeWidth="1"
    >
      <>
        <path
          d="M17,1 C17.5522847,1 18,1.44771525 18,2 L18,11 C18,11.5522847 17.5522847,12 17,12 L10.5,12 L10.5,13.75 L14.25,13.75 C14.5261424,13.75 14.75,13.9738576 14.75,14.25 L14.75,14.75 C14.75,15.0261424 14.5261424,15.25 14.25,15.25 L5.25,15.25 C4.97385763,15.25 4.75,15.0261424 4.75,14.75 L4.75,14.25 C4.75,13.9738576 4.97385763,13.75 5.25,13.75 L9,13.75 L9,12 L2,12 C1.44771525,12 1,11.5522847 1,11 L1,2 C1,1.44771525 1.44771525,1 2,1 L17,1 Z"
          fill="#3C6E90"
          id="webinar-icon"
        />
        <path
          d="M7.29525663,3.35993068 L12.6753383,6.2741416 C12.7724623,6.32675039 12.8085489,6.44813272 12.7559401,6.54525663 C12.7374601,6.57937355 12.7094553,6.6073784 12.6753383,6.6258584 L7.29525663,9.54006932 C7.19813272,9.59267811 7.07675039,9.55659147 7.02414161,9.45946756 C7.00829766,9.43021719 7,9.39747674 7,9.36421093 L7,3.53578907 C7,3.42533212 7.08954305,3.33578907 7.2,3.33578907 C7.23326582,3.33578907 7.26600627,3.34408673 7.29525663,3.35993068 Z"
          fill="#FFFFFF"
          id="webinar-inner-icon"
        />
      </>
    </g>
  </svg>
)
WebinarIcon.defaultProps = {
  title: null,
  height: '20px',
  width: '20px',
  viewBox: '0 0 20 20',
}
WebinarIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  title: PropTypes.string,
  viewBox: PropTypes.string,
  width: PropTypes.string,
}

export default WebinarIcon
