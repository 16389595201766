import React from 'react'

import PropTypes from 'prop-types'
import { CalloutWrapper } from './component.styles'

const Callout = ({
  background,
  children,
  dataTestId,
  direction,
  margin,
  pad,
  round,
}) => {
  return (
    <CalloutWrapper
      background={background}
      dataTestId={dataTestId}
      direction={direction}
      margin={margin}
      pad={pad}
      round={round}
    >
      {children}
    </CalloutWrapper>
  )
}

Callout.propTypes = {
  background: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  dataTestId: PropTypes.string,
  direction: PropTypes.string,
  margin: PropTypes.object,
  pad: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  round: PropTypes.string,
}

Callout.defaultProps = {
  background: '#fff',
  dataTestId: 'callout-display',
  direction: 'column',
  margin: { vertical: '1.75rem' },
  pad: '2rem 1.75rem 1.75rem',
  round: '.688rem',
}

export default Callout
