import React from 'react'

import PropTypes from 'prop-types'

import { ORIGIN_LOCATION } from '../constants'
import DragQuestionDraggable from '../DragQuestionDraggable'

import { DRAGGABLE_KEY_PREFIX } from './constants'
import { DraggablesContainer } from './style'

const DragQuestionDraggablesComponent = ({
  canSubmitForm,
  dataTestId,
  draggables,
  focusOnDraggable,
  isSubmitted,
  onKeyDown,
}) => {
  const draggablesJsx = draggables.map((draggable, i) => {
    const key = DRAGGABLE_KEY_PREFIX + i
    const lastIndex = draggables.length - 1
    const isLast = i === lastIndex
    const {
      type,
      dropZones: dropZoneIds = [],
      location,
      id: draggableId,
    } = draggable || {}
    const { params } = type || {}
    const { text } = params || {}
    const isInDropZone = location !== ORIGIN_LOCATION
    const shouldFocus = draggableId === focusOnDraggable

    return (
      <DragQuestionDraggable
        canSubmitForm={canSubmitForm}
        draggableId={draggableId}
        dropZoneIds={dropZoneIds}
        isInDropZone={isInDropZone}
        isLast={isLast}
        isSubmitted={isSubmitted}
        key={key}
        location={location}
        label={text}
        onKeyDown={onKeyDown}
        shouldFocus={shouldFocus}
      />
    )
  })

  return (
    <DraggablesContainer data-testid={dataTestId}>
      {draggablesJsx}
    </DraggablesContainer>
  )
}

DragQuestionDraggablesComponent.propTypes = {
  canSubmitForm: PropTypes.func,
  dataTestId: PropTypes.string,
  draggables: PropTypes.array,
  focusOnDraggable: PropTypes.string,
  isSubmitted: PropTypes.bool,
  onKeyDown: PropTypes.func,
}

DragQuestionDraggablesComponent.defaultProps = {
  // Warning: Setting a default function for canSubmitForm will break things.
  draggables: [],
  onKeyDown() {},
}

export default DragQuestionDraggablesComponent
