/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'
import PropTypes from 'prop-types'

const GraphIcon = ({ fill, title, ...rest }) => (
  <svg {...rest}>
    {title && <title>{title}</title>}
    <g
      fill="none"
      fillRule="evenodd"
      id="AP_icon_reports"
      stroke="none"
      strokeWidth="1"
    >
      <g fill={fill} id="Group-4">
        <rect height="13" id="Rectangle" rx="1" width="3" x="5" y="0" />
        <rect height="10" id="Rectangle" rx="1" width="3" x="10" y="3" />
        <rect height="7" id="Rectangle" rx="1" width="3" x="0" y="6" />
      </g>
    </g>
  </svg>
)
GraphIcon.defaultProps = {
  title: null,
  height: '13px',
  width: '13px',
  fill: 'currentColor',
  viewBox: '0 0 13 13',
}

GraphIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  title: PropTypes.string,
  viewBox: PropTypes.string,
  width: PropTypes.string,
}

export default GraphIcon
