import styled from 'styled-components'
import { themeGet } from 'styled-system'
import BaseModal from './baseModal'

const StyledBaseModal = styled(BaseModal).attrs(
  ({ children, dataTestId, isModalOpen, modalClose }) => ({
    children,
    dataTestId,
    isModalOpen,
    modalClose,
  }),
)`
  & .Overlay {
    background-color: ${(props) => props.overlayBgColor || themeGet('colors.modals.background')};
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }
`

export default StyledBaseModal
