import * as React from 'react'
import PropTypes from 'prop-types'

const ZoomInPanIcon = props => (
  <svg
    fill="none"
    height={22}
    viewBox="0 0 24 24"
    width={22}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m20 20-5.05-5.05m0 0a7 7 0 1 0-9.9-9.9 7 7 0 0 0 9.9 9.9ZM7 10h6m-3-3v6"
      stroke={props.htmlColor || '#ffff'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
  </svg>
)
export default ZoomInPanIcon

ZoomInPanIcon.propTypes = {
  htmlColor: PropTypes.string,
}
