import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Button } from 'grommet'

const mediumGray = '#666666'
const errorRed = '#ce1443'
const turquoise20_1_25rem = '1.25rem'
const blue16_1rem = '1rem'
const yellow12_0_75rem = '0.75rem'

export const Wrapper = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  max-width: 19.0625rem;
`

export const DownloadButton = styled(Button).attrs(
  ({ dataTestId, isOpen }) => ({
    'data-testid': dataTestId,
    degrees: `${isOpen ? 180 : 0}deg`,
  }),
)`
  border: solid 0.125rem
    ${themeGet('colors.borders.primary', 'colors.beaconGray')};
  width: 100%;
  border-radius: 0.3125rem;
  padding: ${yellow12_0_75rem};
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    color: ${mediumGray};
    font-family: ${themeGet('font.molde.regular')};
    font-size: 1rem;
    line-height: 1.25rem;
  }

  svg {
    color: ${themeGet('colors.gray600')};
    transform: rotate(${({ degrees }) => degrees});
  }
`
export const ReportLinks = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  position: absolute;
  margin-top: ${themeGet('spacing.pink8_0_5rem')};
  display: flex;
  flex-direction: column;
  width: 18.75rem;
  border: solid 0.0625rem
    ${themeGet('colors.borders.primary', 'colors.beaconGray')};
  border-radius: 0.3125rem;
  background-color: ${themeGet('colors.white')};
  z-index: 1;
`

export const ReportLink = styled(Button).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 1.875rem;
  margin-top: calc(${turquoise20_1_25rem} - 0.625rem) !important;
  margin-bottom: calc(${turquoise20_1_25rem} - 0.5625rem) !important;
  padding-left: ${blue16_1rem};
  cursor: pointer;

  svg {
    margin-top: 0.3125rem;
    color: ${themeGet('colors.darkGray')};
  }

  span {
    margin-top: 0.3125rem;
    margin-left: ${themeGet('spacing.pink8_0_5rem')};
    color: ${themeGet('colors.darkGray')};
    font-family: ${themeGet('font.molde.regular')};
    font-size: 1rem;
  }

  &:hover {
    background-color: ${themeGet('colors.paleGray')};
  }
`

export const ReportLinkSeparator = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  display: flex;
  flex-direction: row;
  padding: 0;
  padding-left: ${blue16_1rem};
  margin: 0;

  span {
    margin-top: 0.0625rem;
    margin-bottom: -0.125rem;
    margin-right: ${blue16_1rem};
    color: ${mediumGray};
    font-family: ${themeGet('font.molde.regular')};
    font-size: 0.75rem;
    line-height: 0.625rem;
    opacity: 0.8;
  }

  div {
    border-top: solid 0.125rem
      ${themeGet('colors.borders.primary', 'colors.beaconGray')};
    width: 11.4375rem;
    height: 0;
    margin: auto 0;
  }
`
export const InfoBox = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  margin: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
`
export const Messages = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  flex-basis: 11.25rem;
  width: 11.25rem;
`

export const LoadingMsg = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  width: 100%;
  color: ${mediumGray};
  font-family: ${themeGet('font.molde.regular')};
  font-size: 0.75rem;
  line-height: 1.25rem;
  opacity: 0.8;
`

export const ErrorMsg = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  width: 100%;
  color: ${errorRed};
  font-family: ${themeGet('font.molde.regular')};
  font-size: 0.75rem;
  line-height: 1rem;
  opacity: 0.8;
`

export const HelpLink = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  flex-basis: 5rem;
  width: 5rem;
  color: ${mediumGray};
  font-family: ${themeGet('font.molde.regular')};
  font-size: 0.75rem;
  line-height: 1.25rem;
  opacity: 0.8;
`

export const DropdownSpaceFiller = styled.div`
  position: absolute;
  width: calc(100% - 22px);
  height: 8px;
  margin-top: -0.3125rem;
  background: ${themeGet('colors.white')};
  border-left: 1.5px solid
    ${themeGet('colors.borders.primary', 'colors.beaconGray')};
  border-right: 1.5px solid
    ${themeGet('colors.borders.primary', 'colors.beaconGray')};
  z-index: 1;
`
