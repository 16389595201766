import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { TelescopeIcon } from 'secondstep-components'

export const StyledTelescopeIcon = styled(TelescopeIcon)`
  rect {
    stroke: ${themeGet('colors.white')};
  }
  path {
    stroke: ${themeGet('colors.babyBlue')};
    fill: ${themeGet('colors.babyBlue')};
  }
`
