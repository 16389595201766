import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import RatioWrapper from './ratioWrapper'

const RatioWrapperController = props => {
  const { children, flags } = props
  const { wrapPresentationPlayer } = flags || {}

  return (
    <Fragment>
      {wrapPresentationPlayer && <RatioWrapper>{children}</RatioWrapper>}
      {!wrapPresentationPlayer && <Fragment>{children}</Fragment>}
    </Fragment>
  )
}

RatioWrapperController.defaultProps = {
  flags: {
    wrapPresentationPlayer: false,
  },
}

RatioWrapperController.propTypes = {
  children: PropTypes.node.isRequired,
  flags: PropTypes.object,
  wrapPresentationPlayer: PropTypes.bool,
}

export const RatioWrapperControllerTestable = RatioWrapperController
export default RatioWrapperController
