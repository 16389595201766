import React from 'react'

function ExcelIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
    >
      <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2">
        <rect width="16" height="17" x="1" y="1" rx="2" />
        <path strokeLinecap="square" d="M1.5 12.5L16.5 12.5" />
        <path strokeLinecap="square" d="M1 7L16 7" />
        <path strokeLinecap="square" d="M9 17.5L9 1.5" />
      </g>
    </svg>
  )
}

export default ExcelIcon
