import React, { Component } from 'react'

import PropTypes from 'prop-types'

import { GET_DEFAULT_LOG_SETTINGS, getLoadDelay } from './helper'
import { Wrapper } from './component.styles'

const noop = () => {}

const AMP_LOAD_DELAY_BETWEEN_MS = 300
const MAX_CURRENT_AMP_LOAD_DELAY = 900
let currentAmpLoadDelayMs = 0

const tickLoadDelay = () => {
  // add this variable in to start from 0
  const previousDelay = currentAmpLoadDelayMs
  currentAmpLoadDelayMs = getLoadDelay(
    currentAmpLoadDelayMs,
    AMP_LOAD_DELAY_BETWEEN_MS,
    MAX_CURRENT_AMP_LOAD_DELAY,
  )
  return previousDelay
}

export default class VideoV2 extends Component {
  static propTypes = {
    appInsightInstance: PropTypes.func,
    onEnded: PropTypes.func,
    onPlay: PropTypes.func,
    onStopped: PropTypes.func,
    poster: PropTypes.string,
    settings: PropTypes.shape({
      autoplay: PropTypes.bool,
      controls: PropTypes.bool,
      height: PropTypes.string,
      width: PropTypes.string,
    }),
    tracks: PropTypes.array,
    videoUrl: PropTypes.string,
  }
  static defaultProps = {
    settings: {
      autoplay: false,
      controls: true,
      height: '100%',
      logo: { enabled: false },
      width: '100%',
    },
    hasVideo: false,
    tracks: [],
    onEnded: noop,
    onPlay: noop,
    onStopped: noop,
    onSourceSet: noop,
  }

  constructor() {
    super()
    this.videoRef = React.createRef()
    this.initializePlayer = this.initializePlayer.bind(this)
    this.loadPlayerDelay = null
  }

  componentDidMount = () => {
    this.initializePlayer(window.amp)
  }

  componentWillUnmount = () => {
    if (this.player) {
      this.player.dispose()
    }
    if (this.loadPlayerDelay) {
      clearTimeout(this.loadPlayerDelay)
    }
  }

  initializePlayer = async amp => {
    const {
      appInsightInstance,
      videoUrl,
      tracks,
      settings,
      poster,
      onStopped,
      onEnded,
      onPlay,
      dataTestId,
      logger,
      onSourceSet,
    } = this.props || {}

    if (!amp) {
      return
    }

    const defaultLogSettings = GET_DEFAULT_LOG_SETTINGS()
    if (appInsightInstance && amp) {
      logger.loadAppInsightsPlugin(
        appInsightInstance,
        amp,
        settings?.plugins?.appInsights || defaultLogSettings,
      )
    }

    this.loadPlayerDelay = setTimeout(
      function() {
        this.player = amp(this.videoRef.current, settings)

        this.player.addEventListener('ended', () => onEnded(dataTestId))
        this.player.addEventListener('pause', () => onStopped(dataTestId))
        this.player.addEventListener('play', () => onPlay(dataTestId))
        this.player.addEventListener('sourceset', () => onSourceSet())

        this.player.poster(poster)
        if (videoUrl && this.player) {
          this.player.src({ src: videoUrl }, tracks)
        }
      }.bind(this),
      tickLoadDelay(),
    )
  }

  render() {
    return (
      <Wrapper className="amsWrapper">
        <div
          className="azuremediaplayer amp-flush-skin amp-big-play-centered"
          ref={this.videoRef}
        />
      </Wrapper>
    )
  }
}
