import React, { Fragment, lazy, Suspense, useState } from 'react'

import PropTypes from 'prop-types'

import { getAudioDescToolTipText, getTranscriptToolTipText } from './constants'
import { ItemBar, Tooltip } from './style'

const AudioDescriptionButton = lazy(() =>
  import('utils/importSwap/audioDescriptionButton'),
)
const TranscriptButton = lazy(() => import('utils/importSwap/transcriptButton'))

const MenuComponent = ({
  canShowAudioDescriptionButton,
  canShowTranscriptButton,
  handleClickAudioDesc,
  isTranscriptActive,
  isAudioDescActive,
  showTranscript,
}) => {
  const [isAudioHovering, setIsAudioHovering] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const currentTranscriptToolTipText = getTranscriptToolTipText(
    isTranscriptActive,
  )
  const currentAudioDescToolTipText = getAudioDescToolTipText(isAudioDescActive)
  return (
    <Fragment>
      <ItemBar>
        {isAudioHovering && (
          <Tooltip right={6.7}>{currentAudioDescToolTipText}</Tooltip>
        )}
        {!isHovering && canShowAudioDescriptionButton && (
          <Suspense fallback={<p>Please wait&hellip;</p>}>
            <AudioDescriptionButton
              ariaLabel="Audio Description button"
              hasHover={isAudioHovering}
              hasActive={isAudioDescActive}
              onClick={handleClickAudioDesc}
              onMouseEnter={() => setIsAudioHovering(true)}
              onMouseLeave={() => setIsAudioHovering(false)}
              hasResposive={false}
              variant="default"
            ></AudioDescriptionButton>
          </Suspense>
        )}
        {isHovering && (
          <Tooltip right={3.9}>{currentTranscriptToolTipText}</Tooltip>
        )}
        {canShowTranscriptButton && (
          <Suspense fallback={<p>Please wait&hellip;</p>}>
            <TranscriptButton
              id="transcript-button"
              ariaLabel="Transcription button"
              onClick={showTranscript}
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
              hasResposive={false}
              hasHover={isHovering}
              hasActive={isTranscriptActive}
              variant="default"
            ></TranscriptButton>
          </Suspense>
        )}
      </ItemBar>
    </Fragment>
  )
}

MenuComponent.propTypes = {
  canShowAudioDescriptionButton: PropTypes.bool,
  canShowTranscriptButton: PropTypes.bool,
  isHovering: PropTypes.bool,
  transcript: PropTypes.string,
  isTranscriptActive: PropTypes.bool,
  isAudioDescActive: PropTypes.bool,
  showTranscript: PropTypes.func,
  canShowAudioDesc: PropTypes.bool,
  handleClickAudioDesc: PropTypes.func,
}

export default MenuComponent
