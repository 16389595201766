import styled from 'styled-components'

export const ProgressStatusWrapper = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  position: relative;
  text-transform: capitalize;
`

ProgressStatusWrapper.displayName = 'ProgressStatusWrapper'
