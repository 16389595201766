import React from 'react'
import { string, oneOf, object } from 'prop-types'
import styled from 'styled-components'
import { letterVariant, letters } from './styleMixin'

const AlphabetIconWrap = ({
  fill,
  name,
  letter,
  transformCoords,
  id,
  ...rest
}) => (
  <svg role="img" {...rest}>
    <title> {name} </title>
    <defs>
      <circle cx="22.5" cy="22.5" id="path-1" r="22.5" />
    </defs>
    <g fill="none" fillRule="evenodd" id={name} stroke="none" strokeWidth="1">
      <g id="navy03">
        <mask fill="white" id="mask-2">
          <use xlinkHref="#path-1" />
        </mask>
        <use fill="#E1523D" id="Mask" xlinkHref="#path-1" />
        <path
          d="M-23.7705746,6.55715278 C-19.4739632,1.15147644 -11.6066173,-1.37058153 -5.60031644,0.747491257 C0.405353724,2.86493084 4.54997877,9.60287179 11.4871175,12.3262891 C18.4438063,15.0490733 28.1734586,13.7389709 33.5692913,17.4812161 C38.9663852,21.2430906 39.9905591,30.0408494 37.0580414,36.937725 C34.1267849,43.8346006 27.2602789,48.8502223 20.2771029,52.2163433 C13.2932961,55.5830976 6.21300011,57.2990848 -1.08297785,56.9571538 C-8.38021712,56.5943269 -15.9120578,54.1558521 -20.6835479,49.0832419 C-25.4367492,44.0093653 -27.4687002,36.28489 -28.1214219,28.278005 C-28.7747742,20.27112 -28.0671861,11.9628291 -23.7705746,6.55715278"
          fill="#E77564"
          mask="url(#mask-2)"
        />
      </g>
      <g fill="#FFFFFF" fillRule="nonzero" id={id} transform={transformCoords}>
        {letter}
      </g>
    </g>
  </svg>
)

const AlphabetIcon = styled(AlphabetIconWrap).attrs(props => ({
  ...letterVariant(props),
}))``

AlphabetIconWrap.propTypes = {
  fill: string,
  height: string,
  id: string,
  letter: object,
  name: string,
  transformCoords: string,
  width: string,
}

AlphabetIcon.defaultProps = {
  height: '45px',
  width: '45px',
  fill: 'currentColor',
  name: 'ss_icon_adminpanel_A',
  viewBox: '0 0 45 45',
  letter: letters['A'],
  transformCoords: 'translate(13.000000, 13.000000)',
}
AlphabetIcon.propTypes = {
  fill: string,
  height: string,
  letter: oneOf([
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ]),
  name: string,
  transformCoords: string,
  viewBox: string,
  width: string,
}

export default AlphabetIcon
