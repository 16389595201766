import React, { useState } from 'react'

import PropTypes from 'prop-types'

import AspectRatioWrapperComponent from './component'

//A higher order component that mantains a View's aspect ratio when the browser
//window is resized. Child component is fed a width and height that adheres to the aspect ratio.
const AspectRatioWrapperContainer = props => {
  const [height, setHeight] = useState(null)
  const [width, setWidth] = useState(null)

  //Update state with new frame dimensions every time there is a window resize.
  const setAspectRatio = event => {
    const { ratioHeight: height, ratioWidth: width } =
      calculateAspectRatio(event) || {}

    setHeight(height)
    setWidth(width)
  }

  // Remove Dep on lodash round
  const round = (num, precision) => {
    const modifier = 10 ** precision
    return Math.round(num * modifier) / modifier
  }

  //Based on the window height and width, get the dimensions necessary to keep the child component
  //in the correct ratio as provided in props.
  const calculateAspectRatio = event => {
    const { nativeEvent } = event || {}
    const { layout } = nativeEvent || {}
    const { height = 0, width = 0 } = layout || {}
    const { aspectRatioX, aspectRatioY } = props || {}
    let originalAspectRatioHeightMultiplier, originalAspectRatioWidthMultiplier

    //Landscape frame
    if (aspectRatioX > aspectRatioY) {
      originalAspectRatioHeightMultiplier = aspectRatioY / aspectRatioX
      originalAspectRatioWidthMultiplier = aspectRatioX / aspectRatioY
    }
    //Portrait frame
    else {
      originalAspectRatioWidthMultiplier = aspectRatioX / aspectRatioY
      originalAspectRatioHeightMultiplier = aspectRatioY / aspectRatioX
    }

    let ratioHeight = width * originalAspectRatioHeightMultiplier
    let ratioWidth = height * originalAspectRatioWidthMultiplier
    ratioHeight = ratioHeight >= height ? height : ratioHeight
    ratioWidth = ratioWidth >= width ? width : ratioWidth
    const aspectRatioDecimal = ratioWidth / ratioHeight
    const roundedAspectRatioDecimal = round(aspectRatioDecimal, 3)
    const roundedOriginalAspectRatioWidthMultiplier = round(
      originalAspectRatioWidthMultiplier,
      3,
    )

    !isNaN(roundedAspectRatioDecimal) &&
      console.assert(
        roundedAspectRatioDecimal === roundedOriginalAspectRatioWidthMultiplier,
        `Aspect Ratio Wrapper w/h decimal not ${roundedOriginalAspectRatioWidthMultiplier}). Instead it is ${roundedAspectRatioDecimal}.`,
      )

    return { ratioHeight, ratioWidth }
  }

  const { render: renderProp } = props || {}

  return (
    <AspectRatioWrapperComponent
      height={height}
      render={renderProp}
      setAspectRatio={setAspectRatio}
      width={width}
    />
  )
}

AspectRatioWrapperContainer.propTypes = {
  aspectRatioX: PropTypes.number,
  aspectRatioY: PropTypes.number,
  render: PropTypes.func,
}

AspectRatioWrapperContainer.defaultProps = {
  aspectRatioX: 4,
  aspectRatioY: 3,
  render() {},
}

export default AspectRatioWrapperContainer
