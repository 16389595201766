import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
  grid-rows: auto;

  margin: 0; // Cards should only occur within CardLists
  padding: 1.25rem;

  ${themeGet('breakpoints.mobileLarge')} {
    grid-template-columns: repeat(1, auto);
  }

  background-color: ${themeGet('colors.cards.background')};
  color: ${themeGet('colors.cards.foreground')};
  border-radius: ${themeGet('cards.borderRadius')};
`

export const Header = styled.header`
  grid-column: 1 / 4;
  grid-row: 1;

  ${themeGet('breakpoints.mobileLarge')} {
    grid-column: 1;
    grid-row: 1;
  }
`

export const Actions = styled.div`
  grid-column: 4;
  grid-row: 1/3;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;

  ${themeGet('breakpoints.mobileLarge')} {
    grid-column: 1;
    grid-row: 3;
    align-items: start;
  }
`

export const Content = styled.div`
  grid-column: 1 / 4;
  grid-row: 2;

  ${themeGet('breakpoints.mobileLarge')} {
    grid-column: 1;
    grid-row: 2;
  }
`
