/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

export default () => (
  <svg
    height="19px"
    version="1.1"
    viewBox="0 0 23 19"
    width="23px"
    x="0px"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
  >
    <g transform="translate(-68.000000, -242.000000)">
      <g transform="translate(40.000000, 40.000000)">
        <g transform="translate(27.000000, 138.547350)">
          <g id="relationships-icon" transform="translate(1.000000, 63.000000)">
            <path
              className="st0"
              d="M21.2,2.6C20.2,1.5,19.4,1,18.6,1c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3,0.1-0.5,0.2
						c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.2C16.9,2,16.5,2.3,16,2.6c-0.1,0.1-0.3,0.1-0.4,0.1c-2,0-4,0.1-6,0.1
						c-0.1,0-0.3,0-0.4,0.1C8.3,3.8,7.5,4.5,6.7,5.3C6.3,5.7,5.9,6.1,5.6,6.5C5.3,6.9,5.4,7.3,5.8,7.4c0.4,0.2,0.8,0.1,1.3-0.1
						C8.3,7,9,6.2,9.8,5.4c0.1-0.1,0.2-0.1,0.4-0.1c0.8,0,1.5,0.1,2.2,0.1c0.7,0,1.4-0.2,2.1-0.2c0.2,0,0.3,0.1,0.4,0.2
						c1.5,1.7,3.1,3.3,4.6,4.9c0.1,0.1,0.1,0.1,0.2,0.2c0.1-0.7,0.1-1.3,0.2-2c0-0.2,0.1-0.4,0.3-0.5c0.5-0.4,0.9-0.9,1.4-1.3
						c0.1-0.1,0.3-0.2,0.4-0.4c0,0,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0-0.1,0.1-0.2,0.1-0.3C22.5,4.9,22.4,4,21.2,2.6"
              fill="currentColor"
            />
            <path
              className="st0"
              d="M4.8,11.2c0-0.3-0.2-0.6-0.5-0.8c-0.3-0.1-0.5-0.1-0.8,0.2c-0.6,0.6-1.2,1.2-1.8,1.8
						c-0.3,0.3-0.3,0.7,0,1c0.3,0.3,0.7,0.3,1,0c0.6-0.6,1.2-1.2,1.8-1.8C4.7,11.5,4.8,11.3,4.8,11.2"
              fill="currentColor"
            />
            <path
              className="st0"
              d="M6.2,12.8c-0.3-0.3-0.7-0.3-1-0.1c-0.5,0.5-0.9,0.9-1.4,1.4c-0.2,0.2-0.3,0.5-0.1,0.8
						c0.1,0.3,0.4,0.4,0.7,0.5c0.2,0,0.3,0,0.5-0.2c0.5-0.5,1-1,1.4-1.4C6.5,13.5,6.5,13.1,6.2,12.8"
              fill="currentColor"
            />
            <path
              className="st0"
              d="M7.4,14.6c-0.2,0-0.3,0.1-0.5,0.2c-0.4,0.4-0.7,0.7-1.1,1.1c-0.3,0.3-0.2,0.7,0.1,1
						c0.3,0.3,0.7,0.3,1,0c0.3-0.3,0.7-0.7,1-1c0.2-0.2,0.3-0.5,0.2-0.8C8,14.8,7.7,14.6,7.4,14.6"
              fill="currentColor"
            />
            <g transform="translate(0.000000, 1.051240)">
              <defs>
                <filter
                  filterUnits="userSpaceOnUse"
                  height="17.5"
                  width="20.6"
                  x="0"
                  y="0.1"
                >
                  <feColorMatrix
                    type="matrix"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                  />
                </filter>
              </defs>
              <mask
                height="17.5"
                maskUnits="userSpaceOnUse"
                width="20.6"
                x="0"
                y="0.1"
              >
                <g className="st1">
                  <polygon
                    className="st2"
                    points="0,0.1 20.6,0.1 20.6,17.6 0,17.6 								"
                  />
                </g>
              </mask>
              <path
                className="st3"
                d="M20.5,12.1C20.5,12.1,20.5,12.1,20.5,12.1c-0.1-0.1-0.1-0.2-0.2-0.3c-2-2.1-3.9-4.2-5.9-6.3
							c-0.1-0.1-0.2-0.2-0.4-0.1c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c-0.5,0.1-1,0.1-1.5,0.1c-0.5,0-1.1,0-1.6-0.1c-0.1,0-0.3,0-0.4,0
							c0,0,0,0,0,0c-0.8,0.8-1.6,1.6-3,2l0,0C7.1,7.6,6.7,7.7,6.3,7.7c0,0,0,0,0,0c-0.5,0-0.9-0.1-1.3-0.4C4.8,7.2,4.7,7.1,4.6,7
							C4.4,6.8,4.3,6.5,4.2,6.3c-0.1-0.5,0-1.1,0.4-1.6C5,4.2,5.4,3.8,5.8,3.5l0,0c0.5-0.5,1-1,1.6-1.5C7.4,1.9,7.3,1.8,7.2,1.7
							c-0.4-0.4-0.5-0.6-1-1L6,0.4C5.9,0.3,5.6,0.2,5.3,0.1C4.5,0.1,2.5,0.6,1.5,1.6C0.2,3,0,3.9,0,4.4C0,4.7,0.1,4.9,0.2,5l0,0
							C0.7,5.5,1,5.9,1.5,6.3C1.8,6.6,2,6.9,2,7.4c0,0.5,0.1,1,0.3,1.5c0,0,0,0,0,0.1c0.1-0.1,0.2-0.2,0.3-0.3
							c0.5-0.5,1-0.6,1.3-0.6c0.3,0,0.5,0.1,0.8,0.2C5.5,8.6,5.9,9.2,6,10c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0c0.4,0.1,0.7,0.3,1,0.6
							c0.4,0.4,0.6,1,0.6,1.5c0.7,0.1,1.3,0.5,1.6,1.2c0.3,0.7,0.2,1.5-0.4,2.1c0,0,0,0,0.1,0.1c0.5,0.5,1.1,1.1,1.6,1.6
							c0,0,0,0,0,0c0.2,0.2,0.4,0.3,0.7,0.3c0.5,0,0.9-0.4,0.9-0.9c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1-0.1-0.2c0,0,0,0,0-0.1
							c0,0-0.1-0.1-0.1-0.1l-1.2-1.2c-0.2-0.2-0.2-0.6,0-0.8s0.6-0.2,0.8,0l2.8,2.8c0.2,0.2,0.4,0.3,0.6,0.3c0.5,0,0.9-0.4,0.9-0.9
							c0-0.3-0.1-0.5-0.3-0.7c0,0,0,0,0,0l-2.6-2.6c-0.2-0.2-0.2-0.6,0-0.8c0.2-0.2,0.6-0.2,0.8,0l3.4,3.4c0,0,0,0,0,0
							c0.2,0.2,0.4,0.3,0.6,0.3c0.5,0,0.9-0.4,0.9-0.9c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.2L18.2,14c0,0,0,0,0,0
							c-0.9-0.8-1.7-1.7-2.5-2.5l-0.8-0.8c0,0,0-0.1-0.1-0.1c0,0,0,0,0-0.1c0,0,0-0.1-0.1-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
							c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.1-0.3c0.2-0.2,0.6-0.2,0.8,0l3.4,3.4c0,0,0,0,0,0c0.1,0.1,0.3,0.2,0.5,0.2
							c0.5,0,0.9-0.4,0.9-0.9C20.6,12.4,20.6,12.2,20.5,12.1"
                fill="currentColor"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
