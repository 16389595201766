import { getLearnBaseUrl } from 'utils/domainHelper'
import { getGradeFromThemeParam } from 'utils/gradeHelper'

export const getClassesLinkForGrade = () => {
  return getClassesLink(getGradeFromThemeParam())
}

// export for testability
export const getClassesLink = grade => {
  const learnBaseUrl = getLearnBaseUrl()
  if (['grade6', 'grade7', 'grade8'].includes(grade)) {
    return `${learnBaseUrl}/product/middle-school/lessons`
  }
  return `${learnBaseUrl}/product/elementary/lessons`
}

// temporary version, see: https://stackoverflow.com/a/67177072
// this only works for messages with exactly one element
// it's written to mimic the style of messages that react-i18next produces
export const extractClassesLink = message => {
  return message.match(/^(.*?)<0>(.*?)<\/0>(.*?)$/)
}
