import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { gotoSlide } from '../../../lib/state/actions/player'
import { DONE_SLIDE_ID, SLIDE_STATUS } from '../../../lib/state/constants'

import { Name, Slide, Slides, Wrapper } from './style'

const DATA_TEST_ID_PREFIX = 'slide-section-'

const ProgressSection = props => {
  const {
    currentSlide,
    name,
    title,
    slides,
    sectionIndex,
    goto,
    completeStatus,
  } = props

  if (!slides) {
    return null
  }

  const dataTestId = `${DATA_TEST_ID_PREFIX}${sectionIndex}`

  return (
    <Wrapper nonLinear>
      <Slides data-testid={dataTestId}>
        {slides.map((slide, i) => {
          const hasPastThisSlide =
            sectionIndex < currentSlide[0] ||
            (sectionIndex === currentSlide[0] && i < currentSlide[1])
          const isCurrentSlide =
            sectionIndex === currentSlide[0] && i === currentSlide[1]
          return (
            <Slide
              past={hasPastThisSlide}
              viewed={
                completeStatus[sectionIndex + ',' + i] == SLIDE_STATUS.VIEWED
              }
              current={isCurrentSlide}
              completed={
                completeStatus[sectionIndex + ',' + i] == SLIDE_STATUS.COMPLETED
              }
              key={i}
              isDoneSlide={slide?.sys?.id === DONE_SLIDE_ID || false}
              onClick={() => goto(sectionIndex, i)}
              aria-label={`${name ? name : title} slide ${i + 1}`}
              aria-current={isCurrentSlide ? 'page' : 'false'}
            />
          )
        })}
      </Slides>
      <Name>{name ? name : title}</Name>
    </Wrapper>
  )
}

ProgressSection.propTypes = {
  currentSlide: PropTypes.array,
  name: PropTypes.string,
  title: PropTypes.string,
  slides: PropTypes.array,
  sectionIndex: PropTypes.number,
  goto: PropTypes.func,
  completeStatus: PropTypes.object,
}

const mapDispatchToProps = dispatch => ({
  goto: (section, slide) => dispatch(gotoSlide(section, slide)),
})

const mapStateToProps = ({ player }) => ({
  currentSlide: [...player.currentSlide],
  completeStatus: player.completeStatus,
})

export default connect(mapStateToProps, mapDispatchToProps)(ProgressSection)
