/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

export default () => (
  <svg
    height="24"
    viewBox="0 0 19 24"
    width="19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="currentColor" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        d="M12.02 19.417c-.756 0-1.513-.016-2.268.006-.5.015-.904.267-1.19.683-.184.267-.145.6.064.855.151.185.35.313.534.46.314.25.565.533.538.98a1.168 1.168 0 01-.323.73c-.408.449-.932.682-1.506.798-.928.189-1.789.002-2.548-.579-.397-.304-.654-.7-.529-1.25.063-.274.246-.462.45-.632.166-.138.343-.26.499-.407.355-.335.374-.783.03-1.127-.316-.314-.688-.52-1.152-.519-1.464.006-2.927 0-4.39.006-.17.001-.23-.024-.229-.22.01-1.523-.001-3.045.01-4.567.003-.504.24-.915.616-1.228.3-.25.669-.215.954.055.172.163.297.366.442.552.505.649 1.17.697 1.718.087.597-.666.816-1.48.746-2.367-.05-.624-.25-1.2-.64-1.697a1.605 1.605 0 00-.36-.342c-.459-.311-.965-.253-1.335.163-.166.186-.305.397-.469.586-.392.452-.865.454-1.244-.011-.272-.333-.441-.709-.437-1.166.012-1.494.007-2.988.001-4.482 0-.16.031-.208.197-.208 1.505.008 3.01.013 4.514 0 .494-.005.894-.257 1.186-.656.218-.298.172-.64-.079-.919-.169-.187-.381-.32-.57-.482-.605-.513-.628-1.198-.052-1.748C5.677.313 6.267.088 6.904.02c.858-.092 1.65.12 2.326.686.34.285.544.646.47 1.121-.043.28-.207.483-.411.656-.185.157-.385.296-.56.464-.356.342-.366.777-.012 1.123.324.317.703.516 1.176.513 1.47-.01 2.94 0 4.41-.007.176-.001.215.05.214.223a536.87 536.87 0 00-.001 4.566c.002.5.234.903.592 1.22.3.266.675.235.968-.044.152-.144.266-.32.391-.487.567-.757 1.295-.767 1.886-.027.868 1.087.863 2.897-.017 3.976-.318.388-.705.625-1.227.48-.228-.063-.396-.217-.542-.398-.161-.2-.308-.414-.484-.6-.285-.301-.667-.33-.979-.056-.36.316-.588.721-.59 1.22-.006 1.515-.007 3.03.003 4.546 0 .194-.056.228-.23.226-.756-.01-1.512-.005-2.267-.005"
        fill="#currentColor"
        transform="rotate(-180 9.5 12)"
      />
    </g>
  </svg>
)
