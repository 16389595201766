import React, { Component } from 'react'

import PropTypes from 'prop-types'

import LayoutElement from '../../../LayoutElement'
import LayoutTitle from '../../../LayoutTitle'

import {
  Container,
  Element,
  ElementsContainer,
  ElementTitleContainer,
} from './style'

const ELEMENTS_ACCESSIBILITY_LABEL = 'Layout elements container'
const LAYOUT_ACCESSIBILITY_LABEL = 'Video with title layout'

class VideoWithTitleComponent extends Component {
  getElementComponent = (elementModules, index) => {
    const { getElementProps } = this.props
    const { key, layoutElementProps } =
      getElementProps(elementModules, index) || {}

    return (
      <Element key={key}>
        <LayoutElement {...layoutElementProps} />
      </Element>
    )
  }

  getElementComponents = () => {
    const { elements } = this.props
    const elementComponents = elements.map(this.getElementComponent)

    return elementComponents
  }

  getRenderProps = () => {
    const { title } = this.props
    const layoutElements = this.getElementComponents()
    const containerProps = {
      accessibilityLabel: LAYOUT_ACCESSIBILITY_LABEL,
    }
    const elementsContainerProps = {
      accessibilityLabel: ELEMENTS_ACCESSIBILITY_LABEL,
    }
    const layoutTitleProps = {
      title,
    }
    const renderProps = {
      containerProps,
      elementsContainerProps,
      layoutElements,
      layoutTitleProps,
    }

    return renderProps
  }

  render = () => {
    const {
      containerProps,
      elementsContainerProps,
      layoutElements,
      layoutTitleProps,
    } = this.getRenderProps() || {}

    return (
      <Container {...containerProps}>
        <ElementsContainer {...elementsContainerProps}>
          <ElementTitleContainer>
            <LayoutTitle {...layoutTitleProps} />
          </ElementTitleContainer>
          {layoutElements}
        </ElementsContainer>
      </Container>
    )
  }
}

VideoWithTitleComponent.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
  getElementProps: PropTypes.func,
  title: PropTypes.string,
}

VideoWithTitleComponent.defaultProps = {
  elements: [],
  getElementProps() {},
  title: '',
}

export default VideoWithTitleComponent
