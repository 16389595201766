import React from 'react'
import PropTypes from 'prop-types'
import { Grommet, grommet, RadioButton, Paragraph } from 'grommet'
import { deepMerge } from 'grommet/utils'
import { Wrapper } from './component.styles'

const FormInputRadioGeneric = ({
  customTheme,
  description,
  dataTestId,
  disabled,
  ...props
}) => {
  return (
    // TODO simplify this component to remove the custom theme merge and instead use styled component approach only.
    <Grommet theme={deepMerge(grommet, customTheme)}>
      <Wrapper dataTestId={`wrapper-${dataTestId}`} disabled={disabled}>
        <RadioButton disabled={disabled} {...props} />
      </Wrapper>
      {description && (
        <Paragraph disabled={disabled} {...props}>
          {description}
        </Paragraph>
      )}
    </Grommet>
  )
}

FormInputRadioGeneric.propTypes = {
  customTheme: PropTypes.object,
  dataTestId: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
}

export default FormInputRadioGeneric
