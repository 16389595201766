import styled from 'styled-components'

import { ARROW_IMAGE_SIZE, DOWN_POSITION, UP_POSITION } from './constants'

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`

export const ButtonContainer = styled.div.attrs(
  ({
    theme: {
      colorVars: { detailsRevealer: colors },
      fonts: { detailsRevealer: fonts },
    },
  }) => ({
    color: colors.button.color,
    font: fonts.button,
  }),
)`
  ${props => props.font};
  align-items: center;
  color: ${props => props.color};
  cursor: pointer;
  display: flex;
`

export const ArrowImage = styled.img.attrs(({ isUp }) => ({
  height: ARROW_IMAGE_SIZE,
  rotate: isUp ? UP_POSITION : DOWN_POSITION,
  width: ARROW_IMAGE_SIZE,
}))`
  height: ${props => props.height};
  margin-right: 7px;
  transform: rotate(${props => props.rotate});
  transition: all 0.3s ease-in-out;
  width: ${props => props.width};
`

export const DetailTextContainer = styled.div.attrs(
  ({
    theme: {
      colorVars: { detailsRevealer: colors },
      fonts: { detailsRevealer: fonts },
    },
  }) => ({
    color: colors.message.color,
    font: fonts.message,
  }),
)`
  ${props => props.font};
  color: ${props => props.color};
  margin-top: 10px;
  width: 200px;
`
