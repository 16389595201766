/* istanbul ignore file */
// we don't unit test SVGs
import React from 'react'
import { string } from 'prop-types'

const ReportingInProgress = ({ fill, ...rest }) => (
  <svg className="in-progress-icon" {...rest}>
    <title>ss_reporting_inprogress</title>
    <g
      fill="none"
      fillRule="evenodd"
      id="ss_reporting_inprogress"
      stroke={fill}
      strokeWidth="1"
    >
      <circle cx="10" cy="10" fill="#FFAB00" id="Oval" r="10" />
      <polyline
        id="Path-22"
        points="10 3 10 10.753049 5.2066771 8"
        stroke="#FFFFFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
)
ReportingInProgress.defaultProps = {
  height: '20px',
  width: '20px',
  fill: 'none',
  viewBox: '0 0 20 20',
}
ReportingInProgress.propTypes = {
  fill: string,
  height: string,
  viewBox: string,
  width: string,
}
export default ReportingInProgress
