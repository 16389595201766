import { initialize as ldClientInitialize } from 'launchdarkly-js-client-sdk'
import createActivityDetector from 'activity-detector'
import { popularEmailProviderDomains } from './data'
import Envs from '../config/env'

/*
singleton declared only in this file so we have one instance of client
and flags in one location
*/
export default class FlagsManager {
  #pendingUpdates
  #updateIntervalForIdleUsers
  #updateIntervalForActiveUsers
  #timerId
  #stopped = false
  #activityDetector

  /* updateIntervalForIdleUsers:
    expects a minUpdateInterval and a maxUpdateInterval key:

   {
       minUpdateInterval: 5,
       maxUpdateInterval: 5000
   }
  */

  /* updateIntervalForActiveUsers:
    expects a minUpdateInterval and a maxUpdateInterval key:

   {
       minUpdateInterval: 5,
       maxUpdateInterval: 5000
   }
  */

  initialize = configOptions => {
    const { clientOptions, appLevelSubscribedFlags, localDevFlagOverride } =
      configOptions || {}
    const {
      user = Envs.LD_CREDS.user,
      options = {},
      clientInitializer = ldClientInitialize,
      clientID = Envs.LD_CREDS.clientSideID,
      idleTime,
      updateIntervalForIdleUsers,
      updateIntervalForActiveUsers,
    } = clientOptions || {}
    this.client = clientInitializer(clientID, user, options)

    //  app level subscribed flags are required to run the app
    //  make sure to put every flag you want to listen to for the app
    //  in this config array. Array of flag names as strings
    this.appLevelSubscribedFlags = appLevelSubscribedFlags || []

    //  an object with the key of a flag name and the value of true or false. If set, that flag value cannot be changed
    //  by launch darkly (its overridden by your local version). DO NOT PUSH the items in this object to GIT. For local dev only!
    this.localDevFlagOverride = localDevFlagOverride || {}

    this.#updateIntervalForActiveUsers = updateIntervalForActiveUsers || {
      minUpdateInterval:
        process.env.LD_MIN_UPDATE_INTERVAL_FOR_ACTIVE_USERS || 60000, // 1 minute
      maxUpdateInterval:
        process.env.LD_MAX_UPDATE_INTERVAL_FOR_ACTIVE_USERS || 180000, // 3 minutes
    }
    this.#updateIntervalForIdleUsers = updateIntervalForIdleUsers || {
      minUpdateInterval:
        process.env.LD_MIN_UPDATE_INTERVAL_FOR_IDLE_USERS || 120000, // 2 minutes
      maxUpdateInterval:
        process.env.LD_MAX_UPDATE_INTERVAL_FOR_IDLE_USERS || 120000 * 5,
    }

    this.#activityDetector = createActivityDetector({
      timeToIdle: idleTime || process.env.LD_TIME_TO_IDLE || 30000, // 30 seconds
      autoInit: true,
    })

    this.#activityDetector.on('idle', () => this.handleIdleUsers())

    this.#activityDetector.on('active', () => this.handleActiveUsers())
  }

  // if you need this function to run on the next tick, set delay to at least 1 millisecond
  applyChangesAfterDelay(delay) {
    if (!this.#pendingUpdates || this.#stopped) return
    if (delay === 0) {
      // if delay is 0, we want to apply changes immediately (this avoids race conditions with updating Launch Darkly contexts):
      this.#pendingUpdates()
      this.#pendingUpdates = null
      return null
    }
    return setTimeout(() => {
      this.#pendingUpdates()
      this.#pendingUpdates = null
    }, delay)
  }

  calcDelayForUpdate(minDelay, maxDelay) {
    return Math.floor(Math.random() * (maxDelay - minDelay + 1)) + minDelay // +1 to avoid delay === 0
  }

  // takes an array of flagNames and tells us if their value is true from singleton cache
  checkAllFlagsTrue = flagNames => {
    let allFlagsTrue = false

    if (flagNames) {
      allFlagsTrue = flagNames.every(flag => {
        return this.getFlagValue(flag) === true
      })
    }

    return allFlagsTrue
  }

  // runs over an object with given flag/value pairs and checks at all values strictly equal true
  checkAllFlagValuesTrue = (flags = {}) => {
    const flagValues = Object.values(flags)

    const allValuesTrue =
      flagValues.length > 0 ? flagValues.every(flag => flag === true) : false

    return allValuesTrue
  }

  // checks if at least one flag is true
  checkAtLeastOneFlagTrue = (flags = {}) => {
    const flagValues = Object.values(flags)
    const atLeastOneTrue = flagValues.some(flag => flag)
    return atLeastOneTrue
  }

  cleanup() {
    clearTimeout(this.#timerId)
    this.#activityDetector.stop()
    this.#stopped = true
  }

  /**
   * Private function that creates a custom user context for LaunchDarkly in the updateUserContext function
   * @private
   * @param {string} email
   * @param {string[]} roles
   * @returns
   */
  createCustomUserContext(email, roles) {
    const isTestUser = email.includes('@cfctest.org')
    const isCfchildrenUser = email.includes('@cfchildren.org')
    const parsedEmailName = email.toLowerCase().split('@')[0]
    const parsedEmailDomain = email.toLowerCase().split('@')[1]
    const isRandomUserEmail = popularEmailProviderDomains.includes(
      parsedEmailDomain,
    )
    if (isTestUser) {
      return {
        email: 'testuser@cfctest.org',
        key: 'cfc_test_user',
        kind: 'automation',
        name: 'CFC Test User',
        roles: roles || [],
      }
    } else if (isCfchildrenUser) {
      return {
        email: email,
        key: `cfc_user_${parsedEmailName}`,
        kind: 'user',
        name: `CFC User ${parsedEmailName}`,
        roles: roles || [],
      }
    } else if (isRandomUserEmail) {
      return {
        email: `random_user@${parsedEmailDomain}`,
        key: `random_user_${parsedEmailDomain}`,
        kind: 'user',
        name: 'Random Non CFC Non District User',
        roles: roles || [],
      }
    } else {
      return {
        email: `anonymous_district_user@${parsedEmailDomain}`,
        key: `district_user_@${parsedEmailDomain}`,
        kind: 'district',
        name: 'Non CFC District User',
        roles: roles || [],
      }
    }
  }

  handleActiveUsers() {
    const updateInterval = this.calcDelayForUpdate(
      this.#updateIntervalForActiveUsers.minUpdateInterval,
      this.#updateIntervalForActiveUsers.maxUpdateInterval,
    )
    this.handleUpdates(updateInterval)
  }

  handleIdleUsers() {
    const updateInterval = this.calcDelayForUpdate(
      this.#updateIntervalForIdleUsers.minUpdateInterval,
      this.#updateIntervalForIdleUsers.maxUpdateInterval,
    )
    this.handleUpdates(updateInterval)
  }

  handleUpdates(updateInterval) {
    clearTimeout(this.#timerId)
    this.#timerId = this.applyChangesAfterDelay(updateInterval)
  }

  /**
   * Simple function that returns the current LaunchDarkly client context
   * @returns {object} LaunchDarkly client context
   **/
  getUserContext = () => this.client.getContext()

  getFlags = () => {
    const clientFlags = this.client.allFlags() || {}
    const appSubscribedFlags = this.appLevelSubscribedFlags
    const flagsOnSubscription = Object.entries(clientFlags).reduce(
      (filteredFlags, [flagKey, flagValue]) => {
        if (appSubscribedFlags.includes(flagKey)) {
          filteredFlags[flagKey] = flagValue
        }
        return filteredFlags
      },
      {},
    )

    const overrideFlags = this.getLocalRunFlagOverrides()
    const flags = { ...flagsOnSubscription, ...overrideFlags }

    return flags
  }

  getFlagValue = (flagName, defaultValue = false) => {
    const overrideFlags = this.getLocalRunFlagOverrides()
    if (flagName in overrideFlags) {
      return overrideFlags[flagName]
    }

    const clientFlag = this.client.variation(flagName, defaultValue)
    return clientFlag
  }

  getLocalRunFlagOverrides = () => {
    const didFlagsImportProperly = typeof this.localDevFlagOverride === 'object'
    return didFlagsImportProperly ? this.localDevFlagOverride : {}
  }

  getUpdatedSubscribedFlags = (allFlags, flagSubscriptions) => {
    const subscribed = flagSubscriptions.reduce((accum, subscription) => {
      const flagValue = allFlags[subscription]
      if (flagValue !== undefined) {
        accum[subscription] = flagValue
      }

      return accum
    }, {})
    return subscribed
  }

  /**
   * @deprecated
   */
  reloadAppOnFlagChanges = () => this.updateAppOnFlagChanges()

  /**
   *
   * @param {Function | undefined} setState if unset perform a hard reload,
   * otherwise the setState method of the consuming component.
   */
  updateAppOnFlagChanges = (setState, allOriginalFlags) => {
    let clonedFlags = { ...allOriginalFlags }
    this.client.on('change', changes => {
      const diffFlags = this.transformFlagChangeLogToFlagChangeValue(changes)
      const appSubscribedFlags = this.appLevelSubscribedFlags

      const subscribedFlagsThatChanged = appSubscribedFlags.filter(flagName => {
        const diffChangedFlagValue = diffFlags[flagName]
        return diffChangedFlagValue !== undefined
      })

      // if flag is in local override we do NOT reload the app if it changes remotely
      const localOverrides = this.getLocalRunFlagOverrides()
      const subscribedFlagsNotInLocalOverrides = subscribedFlagsThatChanged.filter(
        flagName => {
          const diffChangedFlagValue = localOverrides[flagName]
          return diffChangedFlagValue === undefined
        },
      )

      const didSubscribedFlagsChange =
        subscribedFlagsNotInLocalOverrides.length > 0
      if (didSubscribedFlagsChange) {
        if (setState) {
          const flags = subscribedFlagsNotInLocalOverrides.reduce(
            (soFar, flagName) => {
              return { ...soFar, [flagName]: changes[flagName].current }
            },
            {},
          )
          clonedFlags = { ...clonedFlags, ...flags }
          this.#pendingUpdates = () => setState({ flags: clonedFlags })
        } else if (window && window.location && window.location.reload) {
          this.#pendingUpdates = () => window.location.reload()
        } else {
          throw new Error('Window object not available and app should crash')
        }
        this.handleActiveUsers()
      }
    })
  }

  /**
   * Sends a new user context to LaunchDarkly using the identify function on the LDClient object
   * @param {Object} userConfig
   * @param {string} userConfig.userEmail
   * @param {string[]} userConfig.roles
   * @param {string} userConfig.name
   */
  updateUserContext = async userConfig => {
    const { userEmail, roles } = userConfig
    const hasCorrectUserContext = userEmail && Array.isArray(roles)
    if (hasCorrectUserContext) {
      const newUser = this.createCustomUserContext(userEmail, roles)
      await this.client.identify(newUser, null)
      this.handleUpdates(0)
    } else {
      console.warn(
        'New user context not properly passed, no additional action taken',
      )
    }
  }

  transformFlagChangeLogToFlagChangeValue = changes => {
    const flattened = {}
    for (const key in changes) {
      const flagKey = key
      flattened[flagKey] = changes[key].current
    }

    return flattened
  }

  async waitForFlagSystemReady() {
    return new Promise(resolve => {
      this.client.on('ready', () => {
        resolve('ready')
      })
    })
  }
}
