export const NAMESPACE = 'USER_CONTEXT'

const getType = step => {
  return `${NAMESPACE}${step}`
}

const REQUEST = '_REQUEST'
const SUCCESS = '_SUCCESS'
const FAILURE = '_FAILURE'
const SET_SITE_ID = '_SET_SITE_ID'
const UPDATE_SITE_PROGRAM_PREFERENCES = '_UPDATE_SITE_PROGRAM_PREFERENCES'

export const REQUEST_TYPE = getType(REQUEST)
export const SUCCESS_TYPE = getType(SUCCESS)
export const FAILURE_TYPE = getType(FAILURE)
export const SET_SITE_ID_TYPE = getType(SET_SITE_ID)
export const UPDATE_SITE_PROGRAM_PREFERENCES_TYPE = getType(
  UPDATE_SITE_PROGRAM_PREFERENCES,
)

// Enable usage for saga lifecycle where name matches value
export default {
  REQUEST_TYPE,
  SUCCESS_TYPE,
  FAILURE_TYPE,
  SET_SITE_ID_TYPE,
  UPDATE_SITE_PROGRAM_PREFERENCES_TYPE,
}
