/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

const FilterIcon = props => (
  <svg height={23} width={22} xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>{'Icon/search'}</title>
    <g fill="none" fillRule="evenodd" stroke="#555" strokeWidth={1.5}>
      <g transform="translate(4 4.5)">
        <circle cx={3} cy={3} r={2.25} />
        <path d="M5.487 3h7.401" strokeLinecap="round" />
      </g>
      <g transform="matrix(-1 0 0 1 17.888 12.5)">
        <circle cx={3} cy={3} r={2.25} />
        <path d="M5.487 3h7.401" strokeLinecap="round" />
      </g>
    </g>
  </svg>
)

export default FilterIcon
