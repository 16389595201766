import React from 'react'

import PropTypes from 'prop-types'
import Markdown from 'react-markdown'

import { BodyCopy } from '../../text'
import {
  LinkContainer,
  OrderedListContainer,
  ParagraphContainer,
  UnorderedListContainer,
} from '../../text/text.style'

const DATA_TESTID = 'slide-body-copy'
const NEW_WINDOW = '_blank'

const renderers = {
  list: properties =>
    properties.ordered ? (
      <OrderedListContainer>{properties.children}</OrderedListContainer>
    ) : (
      <UnorderedListContainer>{properties.children}</UnorderedListContainer>
    ),
  paragraph: properties => (
    <ParagraphContainer>{properties.children}</ParagraphContainer>
  ),
  link: properties => <LinkContainer {...properties} target={NEW_WINDOW} />,
}

const Text = ({ content }) => (
  <BodyCopy data-testid={DATA_TESTID}>
    <Markdown source={content} renderers={renderers} />
  </BodyCopy>
)

Text.propTypes = {
  content: PropTypes.string,
}

export default Text
