const LD_CLIENT_ID = process.env.LD_CLIENT_ID || '5e2b83e9f2d059086d229acd'

const LD_CREDS = {
  clientSideID: LD_CLIENT_ID,
  user: {
    kind: 'user',
    email: 'fakeUser@notrealdomain.org',
    key: 'default_fake_user_key',
    name: 'Default Fake User',
  },
}

export default class Envs {
  static get LD_CREDS() {
    return LD_CREDS
  }
}
