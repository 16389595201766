import styled from 'styled-components'

export const Background = styled.div.attrs(
  ({
    theme: {
      colorVars: { modal: colors },
    },
  }) => ({
    backgroundColor: colors.overlay.background,
  }),
)`
  background-color: ${props => props.backgroundColor};
  cursor: pointer;
  height: 100%;
  position: absolute;
  width: 100%;
`

export const Wrapper = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;

  //preserve z-index when css transforms happen here or elsewhere
  -webkit-transform: translate3d(0, 0, 0);
`

export const CloseButtonContainer = styled.div.attrs(
  ({
    showBorder,
    theme: {
      colorVars: { modal: colors },
    },
  }) => ({
    backgroundColor: colors.background,
    border: showBorder ? `2px solid ${colors.border}` : 'none',
  }),
)`
  align-items: center;
  background-color: ${props => props.backgroundColor};
  border-radius: 0.25em;
  border: ${props => props.border};
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 2em;
  justify-content: center;
  padding: 0.4375em;
  position: absolute;
  right: 0.75em;
  top: 0.75em;
  width: 2em;
  z-index: 3;
`

export const ModalBox = styled.div.attrs(
  ({
    theme: {
      colorVars: { modal: colors },
    },
  }) => ({
    backgroundColor: colors.background,
    color: colors.color,
  }),
)`
  align-items: center;
  background-color: ${props => props.backgroundColor};
  border-radius: 0.75rem;
  box-sizing: border-box;
  color: ${props => props.color};
  display: flex;
  height: auto;
  padding: 0.25em;
  position: relative;
  width: auto;
  z-index: 1;
`

export const ModalBoxImageTall = styled(ModalBox)`
  border-radius: 0.25rem;
  height: calc(100% - 5em);
  max-width: calc(100% - 5em);
`

export const ModalBoxTextImage = styled(ModalBox)`
  border-radius: 0.25rem;
  height: calc(100% - 5em);
  width: calc(100% - 5em);
`

export const ModalBoxTextOnly = styled(ModalBox)`
  border-radius: 0.25rem;
  max-height: calc(100% - 5em);
  min-height: 40%;
  width: calc(100% - 5em);
`

export const ModalBoxImageWide = styled(ModalBox)`
  border-radius: 0.25rem;
  max-height: calc(100% - 5em);
  width: calc(100% - 5em);
`

export const ModalConfirmBox = styled.div.attrs(
  ({
    theme: {
      colorVars: { lessonComplete: colors },
    },
  }) => ({
    style: {
      backgroundColor: colors.container.backgroundColor,
      height: '398px',
      padding: '0 60px 28px',
      width: `1em`,
    },
  }),
)`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
`
