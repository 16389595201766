import React from 'react'

import PropTypes from 'prop-types'

import { percentToDecimal } from '../../../utils/numberHelper'
import { HOTSPOT_DIAMETER } from '../constants'

import ImageHotspotsTargetsComponent from './component'

const BOUNDARY_SIZE = 20
const BOTTOM_EDGE_BOUNDARY = BOUNDARY_SIZE
const TOOLTIP_MAX_WIDTH = 146
const HOTSPOT_TARGET_MAX_WIDTH = 30
const LEFT_EDGE_BOUNDARY = BOUNDARY_SIZE
const RIGHT_EDGE_BOUNDARY = BOUNDARY_SIZE
const TOP_EDGE_BOUNDARY = BOUNDARY_SIZE

const ImageHotspotsTargetsContainer = props => {
  const getHotspotPixelPositionInBounds = hotspot => {
    // X and y for top and left of hotspot comes from H5P, and into this component, as a percentage.
    // The coordinates provided from H5P are for the top left of the hotspot, not center. From here forward,
    // pixel values for top and left are used for placement precision in the frame.
    const { containerHeight, containerWidth } = props || {}
    const { position } = hotspot || {}
    const { x: xPercent, y: yPercent } = position || {}
    const xDecimal = percentToDecimal(xPercent)
    const yDecimal = percentToDecimal(yPercent)
    const xPixels = containerWidth * xDecimal
    const yPixels = containerHeight * yDecimal
    const pixelPositionInBounds = getPositionInBounds(xPixels, yPixels)

    return pixelPositionInBounds
  }

  const getPositionInBounds = (xPixels, yPixels) => {
    //Hotspot can never be placed within 20px of the edge of the image. Move hotspot to the boundary
    //if hotspot is outside of the boundary
    const { containerHeight, containerWidth } = props || {}
    const rightEdgeBoundary =
      containerWidth - RIGHT_EDGE_BOUNDARY - HOTSPOT_DIAMETER
    const bottomEdgeBoundary =
      containerHeight - BOTTOM_EDGE_BOUNDARY - HOTSPOT_DIAMETER
    let adjustedLeft =
      xPixels < LEFT_EDGE_BOUNDARY ? LEFT_EDGE_BOUNDARY : xPixels
    adjustedLeft =
      xPixels > rightEdgeBoundary ? rightEdgeBoundary : adjustedLeft
    let adjustedTop = yPixels < TOP_EDGE_BOUNDARY ? TOP_EDGE_BOUNDARY : yPixels
    adjustedTop =
      yPixels > bottomEdgeBoundary ? bottomEdgeBoundary : adjustedTop
    const adjustedPosition = {
      left: adjustedLeft,
      top: adjustedTop,
    }

    return adjustedPosition
  }

  const shouldHotspotToolTipFlip = hotspotLeftPos => {
    const { containerWidth } = props || {}
    const distanceToRightEdge =
      containerWidth - hotspotLeftPos - HOTSPOT_TARGET_MAX_WIDTH

    if (distanceToRightEdge < TOOLTIP_MAX_WIDTH) {
      return true
    }

    return false
  }

  const getRenderProps = () => {
    const { hotspots, id, onClickTarget, targetsClicked } = props || {}
    const renderProps = {
      getHotspotPixelPositionInBounds,
      hotspots,
      id,
      onClickTarget,
      shouldHotspotToolTipFlip,
      targetsClicked,
    }

    return renderProps
  }

  const targetsProps = getRenderProps() || {}

  return <ImageHotspotsTargetsComponent {...targetsProps} />
}

ImageHotspotsTargetsContainer.propTypes = {
  containerHeight: PropTypes.number,
  containerWidth: PropTypes.number,
  hotspots: PropTypes.array,
  id: PropTypes.string,
  onClickTarget: PropTypes.func,
  targetsClicked: PropTypes.object,
}

ImageHotspotsTargetsContainer.defaultProps = {
  containerHeight: 0,
  containerWidth: 0,
  hotspots: [],
  onClickTarget() {},
}

export default ImageHotspotsTargetsContainer
