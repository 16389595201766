/* eslint-disable no-console */
import React from 'react'

import PropTypes from 'prop-types'

import { ButtonCopy, ChromeButtonCopy } from '../text'

import {
  ArrowEndLeftSvg,
  ArrowEndRightSvg,
  ArrowIconSvg,
  LeftButton,
  RightButton,
  SpinSvg,
  StyledButtonNavigation,
} from './style'

// THIS WHOLE FILE NEEDS A HUGE REFACTOR
// PLEASE DON"T ADD MORE CODE TO THIS UNTIL ITS FIXED

const ButtonNavigation = props => {
  const { children, type, icon, disabled } = props
  let leftIcon, rightIcon

  const propsClone = {
    ...props,
    onClick: disabled ? () => {} : props.onClick,
  }

  switch (icon) {
    case 'arrow-right':
      rightIcon = <ArrowIconSvg />
      break
    case 'arrow-left':
      leftIcon = <ArrowIconSvg style={{ transform: 'rotate(180deg)' }} />
      break
    case 'flip-left':
      leftIcon = <SpinSvg />
      break
    case 'flip-right':
      rightIcon = <SpinSvg style={{ paddingRight: 0, paddingLeft: '0.8em' }} />
      break
    default:
      break
  }

  switch (type) {
    case 'right':
      return (
        <RightButton {...propsClone}>
          {leftIcon}
          <ChromeButtonCopy>{children}</ChromeButtonCopy>

          {rightIcon}

          <ArrowEndRightSvg />
        </RightButton>
      )
    case 'left':
      return (
        <LeftButton {...propsClone}>
          <ArrowEndLeftSvg />
          {leftIcon}
          <ChromeButtonCopy>{children}</ChromeButtonCopy>
          {rightIcon}
        </LeftButton>
      )
    default:
      return (
        <StyledButtonNavigation {...propsClone}>
          {leftIcon}
          <ButtonCopy>{children}</ButtonCopy>
          {rightIcon}
        </StyledButtonNavigation>
      )
  }
}

ButtonNavigation.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
}

export default ButtonNavigation
