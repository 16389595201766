/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'
import PropTypes from 'prop-types'

const CompleteIcon = ({ stroke, size }) => {
  return (
    <svg
      className="complete-icon"
      height={size}
      viewBox="0 0 20 20"
      width={size}
    >
      <title>Complete</title>
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        transform="translate(-204 -744) translate(204 744)"
      >
        <circle
          cx="10"
          cy="10"
          fill="currentColor"
          r="10"
          stroke="currentColor"
          strokeWidth="2"
        />
        <path
          d="M5.92592593 9.81901924L9.31583002 13.5188148 14.0681357 6.89556036"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.852"
        />
      </g>
    </svg>
  )
}

CompleteIcon.propTypes = {
  size: PropTypes.string,
  stroke: PropTypes.string,
}

CompleteIcon.defaultProps = {
  size: '1.25rem',
  stroke: '#fff',
}

export default CompleteIcon
