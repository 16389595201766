import { domains } from '../../config/env'

export const setEnvSpecificLearnLink = href => {
  if (!href) {
    return ''
  }
  let newHref = href.replace(
    /https:\/\/learn.secondstep.org|https:\/\/learn.secondstep.org/,
    domains.CURRICULUM_APP_DOMAIN,
  )
  newHref = href.replace(
    /https:\/\/admin.secondstep.org|https:\/\/admin.secondstep.org/,
    domains.ADMIN_APP_DOMAIN,
  )
  newHref = href.replace(
    /https:\/\/login.secondstep.org|https:\/\/login.secondstep.org/,
    domains.LOGIN_DOMAIN,
  )
  newHref = href.replace(
    /https:\/\/www.secondstep.org|https:\/\/www.secondstep.org/,
    domains.WWW_DOMAIN,
  )
  return newHref
}
