import React, { useEffect, useRef, useState } from 'react'

import PropTypes from 'prop-types'

import DragAndDrop from '../../../lib/dragAndDrop'
import { DRAG_AND_DROP_TYPES } from '../../../utils/dragAndDropHelper'
import { isInCorrectDropZone } from '../utils'

import DragQuestionDraggable from './component'
import { MOUSE_ENTER_EVENT } from './constants'

const DragQuestionDraggableContainer = props => {
  const {
    shouldFocus,
    location: currentLocation,
    onKeyDown,
    canSubmitForm,
    draggableId,
    dropZoneIds,
    isInDropZone,
    isSubmitted,
    label,
  } = props || {}
  const [domElement, setDomElement] = useState(null)
  const [isFocused, setIsFocused] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const accessibilityRef = useRef(null)
  const refCallback = DragAndDrop.createDraggableRefCallback(setDomElement)
  const [{ isDragging }, dragRef] = DragAndDrop.setupUseDrag(
    props,
    DRAG_AND_DROP_TYPES.DRAG_QUESTION,
    domElement,
  )

  // Set state on mount
  useEffect(() => {
    shouldFocus && setIsFocused(true)
  }, [])

  //If shouldFocus prop changes, update isFocused state
  useEffect(() => {
    setIsFocused(shouldFocus)
  }, [shouldFocus])

  //If isFocused state changes, hover and focus/blur as needed
  useEffect(() => {
    setIsHovered(isFocused)
    isFocused && accessibilityRef.current.focus()
  }, [isFocused])

  const onMouseEvent = ({ type }) => {
    const isHovered = type === MOUSE_ENTER_EVENT

    setIsHovered(isHovered)
  }

  const onKeyDownWithLocation = (event, draggableId) =>
    onKeyDown(event, draggableId, currentLocation, domElement)
  const hover = () => setIsHovered(true)
  const unhover = () => setIsHovered(false)
  const canSubmit = canSubmitForm()
  const shouldShowSubmitAnswer = canSubmit && isSubmitted
  const inCorrectLocation = isInCorrectDropZone(dropZoneIds, currentLocation)

  return (
    <DragQuestionDraggable
      accessibilityRef={accessibilityRef}
      draggableId={draggableId}
      inCorrectLocation={inCorrectLocation}
      isDragging={isDragging}
      isHovered={isHovered}
      isInDropZone={isInDropZone}
      onBlur={unhover}
      onFocus={hover}
      onKeyDown={onKeyDownWithLocation}
      onMouseEvent={onMouseEvent}
      refCallback={childElement => refCallback(childElement, dragRef)}
      shouldShowSubmitAnswer={shouldShowSubmitAnswer}
      text={label}
    />
  )
}

DragQuestionDraggableContainer.propTypes = {
  canSubmitForm: PropTypes.func,
  draggableId: PropTypes.string,
  dropZoneIds: PropTypes.array,
  isInDropZone: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  label: PropTypes.string,
  location: PropTypes.string,
  onKeyDown: PropTypes.func,
  shouldFocus: PropTypes.bool,
}

DragQuestionDraggableContainer.defaultProps = {
  // Warning: Setting a default function for canSubmitForm will break things.
  canSubmitForm() {},
  dropZones: [],
  onKeyDown() {},
}

export default DragQuestionDraggableContainer
