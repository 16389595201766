/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

export default () => (
  <svg width="29px" height="25px" viewBox="0 0 29 25">
    <title>{'ss_programtheme_confidence'}</title>
    <defs>
      <polygon
        id="path-1"
        points="0 0 13.4633954 0 13.4633954 13.4048616 0 13.4048616"
      />
      <polygon
        id="path-3"
        points="0 0 10.974777 0 10.974777 10.3339348 0 10.3339348"
      />
      <polygon
        id="path-5"
        points="0 0 9.96489552 0 9.96489552 10.3995032 0 10.3995032"
      />
    </defs>
    <g
      id="ss_programtheme_confidence"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <g id="Group-12" transform="translate(2, 2)">
        <g id="Group-3" transform="translate(11.5366, 0)">
          <mask id="mask-2" fill="white">
            <use xlinkHref="#path-1" />
          </mask>
          <g id="Clip-2" />
          <path
            d="M13.4436868,9.07478924 C13.4216413,9.00778912 13.3868095,8.94256383 13.3462458,8.8844379 C12.7391136,8.016986 12.1403588,7.14332217 11.5182357,6.28696301 C11.1412582,5.76693558 11.0283854,5.22383526 11.1853492,4.59554274 C11.4397538,3.5798919 11.6615312,2.55536687 11.8974177,1.53483522 C11.9366587,1.36445081 11.9745769,1.18829817 11.8299586,1.05341051 C11.6848994,0.918522851 11.5133857,0.970436852 11.3476038,1.02235085 C10.2889805,1.35291436 9.2307981,1.68525271 8.17085206,2.01137913 C7.72597455,2.14848534 7.29079704,2.09657134 6.887365,1.8685047 C6.50156933,1.65108709 6.12856004,1.4110403 5.75025983,1.18119882 C5.14974132,0.816469687 4.55010463,0.449965713 3.9482634,0.0878988343 C3.62199049,-0.107777016 3.36890853,0.0324351579 3.3530358,0.411806704 C3.35215398,0.434435884 3.35259489,0.457065064 3.35215398,0.479250534 C3.33672215,1.58231213 3.32040851,2.68537372 3.30674032,3.78799161 C3.30056759,4.28805212 3.11714931,4.71135089 2.74899001,5.04280182 C2.28603522,5.45988867 1.81117587,5.86366423 1.34160743,6.27320802 C0.939498119,6.62373845 0.537388809,6.97426889 0.135720408,7.32524303 C-0.0812069833,7.54621032 -0.0340296849,7.80400549 0.248152287,7.93135009 C0.29268413,7.95131701 0.339861428,7.96595942 0.386156908,7.98193296 C1.43508021,8.34133758 2.48532623,8.69541769 3.53072226,9.06458392 C3.71149509,9.12847808 3.88080427,9.24206769 4.03600435,9.35876326 C4.26924539,9.53447219 4.42180002,9.77585011 4.53467281,10.0482877 C4.95794577,11.0688193 5.38695054,12.0866887 5.81683714,13.1045581 C5.96674632,13.4595256 6.26480102,13.5061151 6.48481478,13.2106047 C7.1519106,12.3138679 7.81636096,11.4149127 8.48257496,10.5172885 C8.79341604,10.0984268 9.20302081,9.85349925 9.72461655,9.80824089 C10.8211581,9.71284337 11.9176996,9.61833327 13.0138002,9.52160461 C13.0975729,9.51406155 13.1839912,9.49986285 13.2611503,9.4692469 C13.432664,9.40135936 13.501005,9.25005446 13.4436868,9.07478924"
            id="Fill-1"
            fill="#FFFFFF"
            mask="url(#mask-2)"
          />
        </g>
        <path
          d="M4.88602494,6.58691057 C6.61306679,5.64358437 8.60200878,4.98378847 10.5142326,4.67896011 C11.0525828,4.6172845 11.4172149,4.06176032 11.256283,3.53951434 C11.1165147,3.06829495 10.6235781,2.80029446 10.1553324,2.94095034 C9.06628631,3.26840789 8.10642669,3.68150135 7.13686707,4.16159493 C5.23081603,5.11557017 3.44116506,6.39123472 2.12284616,8.08221129 C1.48529126,8.93945787 1.00999101,9.89698278 0.711495388,10.8944415 C1.55671858,8.99048445 3.11136488,7.57416401 4.88602494,6.58691057"
          id="Fill-4"
          fill="#FFFFFF"
        />
        <g id="Group-8" transform="translate(3.3028, 11.6661)">
          <mask id="mask-4" fill="white">
            <use xlinkHref="#path-3" />
          </mask>
          <g id="Clip-7" />
          <path
            d="M9.9037054,0.020885674 C6.20668066,0.967761556 2.44616497,3.0678382 0.78481861,6.65966588 C0.243381951,7.81996599 0.0171954639,9.09208087 0,10.3340235 C0.163577362,9.09962393 0.5339412,7.89894626 1.17458246,6.86643446 C3.06431985,3.77023019 6.73136276,2.23898902 10.1744237,1.77486897 C11.4151426,1.62445148 11.1267879,-0.215167732 9.9037054,0.020885674"
            id="Fill-6"
            fill="#FFFFFF"
            mask="url(#mask-4)"
          />
        </g>
        <g id="Group-11" transform="translate(0, 7.8801)">
          <mask id="mask-6" fill="white">
            <use xlinkHref="#path-5" />
          </mask>
          <g id="Clip-10" />
          <path
            d="M9.89475494,0.545160935 C9.70516393,0.0921336267 9.18665456,-0.12084689 8.73648613,0.0699481562 C7.58571277,0.560247055 6.61659406,1.15526137 5.62102079,1.86342159 C3.48481508,3.40398067 1.70089593,5.46767314 0.689890831,7.91206828 C0.605236239,8.18805553 0.346422462,8.87536141 0.275876969,9.14114335 C0.21547239,9.41979286 0.0620359429,10.1084299 -0.000132272799,10.3995032 C0.101276873,10.1288405 0.330990635,9.46105783 0.431958872,9.19616331 C0.541304386,8.9410304 0.870663657,8.3136253 0.987945539,8.05627384 C0.987945539,8.05627384 1.43723215,7.34500765 1.43723215,7.34500765 C3.2542195,4.60687688 6.19155747,2.68960852 9.28409552,1.75693134 C9.81010035,1.63402383 10.1094778,1.04522144 9.89475494,0.545160935"
            id="Fill-9"
            fill="#FFFFFF"
            mask="url(#mask-6)"
          />
        </g>
      </g>
    </g>
  </svg>
)
