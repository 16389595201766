export const popularEmailProviderDomains = [
  'gmail.com',
  'yahoo.com',
  'outlook.com',
  'hotmail.com',
  'aol.com',
  'aim.com',
  'icloud.com',
  'protonmail.com',
  'yandex.com',
  'zoho.com',
  'mail.com',
  'fastmail.com',
  'gmx.com',
  'inbox.com',
  'mail.ru',
  'cox.net',
  'rr.com',
  'fastmail.com',
  '10minutemail.com',
  'test.com',
  'mail.com',
]
