import React, { Component } from 'react'

import PropTypes from 'prop-types'

import ErrorMessage from '../ErrorMessage'

import {
  DEFAULT_MODAL_LAYOUT_DEPRECATED,
  MODAL_LAYOUT_COMPONENTS,
} from './routes'

class ModalLayoutRouterComponent extends Component {
  getLayoutComponent = () => {
    const { route } = this.props
    const layoutNotFoundMessage = `Could not find modal layout ${route}. Using default layout "${DEFAULT_MODAL_LAYOUT_DEPRECATED}".`

    console.assert(route in MODAL_LAYOUT_COMPONENTS, layoutNotFoundMessage)

    const LayoutComponent = MODAL_LAYOUT_COMPONENTS[route]

    return LayoutComponent
  }

  getRenderProps = () => {
    const {
      elements,
      error,
      foregroundColor,
      heroImage,
      heroAltText,
      onSubmit,
      title,
      //TODO: heroPosition and heroSize will no longer be needed when default layout can be phased out
      heroPosition,
      heroSize,
    } = this.props
    const isModal = true
    let ErrorComponent, LayoutComponent

    if (error) {
      ErrorComponent = ErrorMessage
    } else {
      LayoutComponent = this.getLayoutComponent()
    }

    const layoutComponentProps = {
      elements,
      error,
      ErrorComponent,
      foregroundColor,
      heroImage,
      heroAltText,
      isModal,
      LayoutComponent,
      onSubmit,
      title,
      //TODO: heroPosition and heroSize should only necessary for layout default. New layouts will not require these props.
      heroPosition,
      heroSize,
    }

    return layoutComponentProps
  }

  render = () => {
    const layoutComponentProps = this.getRenderProps() || {}
    const { error, ErrorComponent, LayoutComponent } =
      layoutComponentProps || {}

    if (ErrorComponent) {
      const { url, message } = error

      return <ErrorComponent message={message} url={url} />
    }

    return LayoutComponent ? (
      <LayoutComponent {...layoutComponentProps} />
    ) : null
  }
}

ModalLayoutRouterComponent.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.array),
  error: PropTypes.shape({
    message: PropTypes.string,
    url: PropTypes.string,
  }),
  foregroundColor: PropTypes.string,
  heroImage: PropTypes.string,
  heroAltText: PropTypes.string,
  heroPosition: PropTypes.string,
  heroSize: PropTypes.string,
  onSubmit: PropTypes.func,
  route: PropTypes.string,
  title: PropTypes.string,
}

ModalLayoutRouterComponent.defaultProps = {
  elements: [],
  onSubmit() {},
  route: DEFAULT_MODAL_LAYOUT_DEPRECATED,
}

export default ModalLayoutRouterComponent
