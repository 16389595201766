/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

export default () => (
  <svg
    height="21px"
    version="1.1"
    viewBox="0 0 20 21"
    width="20px"
    x="0px"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
  >
    <g>
      <g transform="translate(-306.000000, -214.000000)">
        <g transform="translate(40.000000, 40.000000)">
          <g transform="translate(27.000000, 138.547350)">
            <g
              id="growth-mindset-icon"
              transform="translate(239.000000, 35.000000)"
            >
              <path
                className="st0"
                d="M12.4,13.9c-0.4-1.6-1.2-2.9-2.2-4.2c-1.6-2-3.6-3.4-5.8-4.5C3.3,4.7,2,4.3,0.8,4.2
						c-0.4,0-0.6,0.1-0.6,0.5c-0.2,1.8-0.2,3.6,0,5.5c0.2,1.4,0.4,2.7,0.9,4c0.8,2.1,2.2,3.8,4.2,5c1.3,0.7,2.6,1.2,4.1,1.3
						c0.2,0,0.5,0,0.7,0.1c0.1-7.4-6.1-10.1-6.4-10.3c-0.3-0.1-0.5-0.5-0.4-0.8c0.1-0.3,0.5-0.5,0.8-0.4c0.1,0,6.9,3,7.2,10.8
						c0,0,0,0,0-0.1l0,0C12.6,17.9,12.9,16,12.4,13.9"
                fill="currentColor"
              />
              <g transform="translate(8.800000, 0.116600)">
                <defs>
                  <filter
                    filterUnits="userSpaceOnUse"
                    height="15.3"
                    width="10.7"
                    x="0.2"
                    y="0.3"
                  >
                    <feColorMatrix
                      type="matrix"
                      values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                    />
                  </filter>
                </defs>
                <mask
                  height="15.3"
                  maskUnits="userSpaceOnUse"
                  width="10.7"
                  x="0.2"
                  y="0.3"
                >
                  <g className="st1">
                    <polygon
                      className="st2"
                      points="0.2,0.3 10.9,0.3 10.9,15.6 0.2,15.6 								"
                    />
                  </g>
                </mask>
                <path
                  className="st3"
                  d="M10.8,0.9c0-0.5-0.2-0.6-0.6-0.6C9.5,0.3,8.8,0.5,8.1,0.7C5.5,1.4,3.4,2.9,1.6,4.8
							C1.1,5.4,0.6,6,0.2,6.6C1,7.3,1.7,8,2.4,8.8c0,0,0,0,0,0.1C3.9,6,6.9,4.6,7,4.6c0.3-0.1,0.7,0,0.8,0.3c0.1,0.3,0,0.7-0.3,0.8
							c0,0-3.2,1.5-4.3,4.4c0.7,1.1,1.2,2.3,1.5,3.5C5,14.2,5,14.9,5,15.6c0.2-0.1,0.4-0.2,0.6-0.3c1.6-0.8,2.7-2.1,3.6-3.7
							c1-1.9,1.4-4,1.6-6.1C11,4,10.9,2.4,10.8,0.9"
                  fill="currentColor"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
