import React from 'react'

import PropTypes from 'prop-types'

const CheckMark = ({ isButtonActive }) => {
  const stroke = isButtonActive ? '#228B22' : '#888'
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.13 42.02">
      <line
        stroke={stroke}
        fill="none"
        strokeLinecap="round"
        strokeWidth="7.8px"
        className="cls-1"
        x1="3.9"
        y1="24.45"
        x2="17.28"
        y2="38.07"
      />
      <line
        stroke={stroke}
        fill="none"
        strokeLinecap="round"
        strokeWidth="7.8px"
        className="cls-1"
        x1="41.23"
        y1="3.9"
        x2="17.6"
        y2="38.12"
      />
    </svg>
  )
}

CheckMark.propTypes = {
  isButtonActive: PropTypes.bool,
}

export default CheckMark
