/* istanbul ignore file */
import styled from 'styled-components'

import { fontNames } from '../../../../styles/GlobalStyles'
import { colors } from '../../../../styles/styleGuide'

export const Container = styled.button`
  background: ${props => (props.isActive ? colors.lightestGrey : 'none')};
  cursor: pointer;
  height: 1.75rem;
  width: 5.813rem;
  position: relative;
  border: none;
  border-radius: 2px;
  color: ${colors.mediumGrey};
  &:hover {
    background-color: ${colors.lightestGrey};
    g {
      polyline {
        stroke: ${colors.mediumGrey};
      }
    }
  }
  font-size: 16px;
  font-family: ${fontNames.MoldeMedium};
`
export const TextIconContainer = styled.div`
  svg {
    g {
      polyline {
        stroke: ${props =>
          props.isActive ? colors.mediumGrey : colors.lighterGrey};
      }
    }
  }
  font-weight: 600;
`
