import { LOCALES } from 'utils/locale'

export const ALT_TEXT_ENTER_FULL_SCREEN = 'Enter full screen mode'
export const ALT_TEXT_EXIT_FULL_SCREEN = 'Exit full screen mode'
export const BUTTON_SIZE = '2em'
export const CLICK = 'click'
export const DATA_TEST_ID = 'full-screen-button'
export const DATA_TEST_ID_ENTER_FULL_SCREEN_ICON = 'enter-full-screen-icon'
export const DATA_TEST_ID_EXIT_FULL_SCREEN_ICON = 'exit-full-screen-icon'
export const DATA_TEST_ID_TOOLTIP = 'tooltip'
export const DURATION_ICON_ANIMATION = 200
export const DURATION_TRANSITION_ANIMATION = '0.2s'
export const DURATION_TRANSITION_STATE = 0
export const ICON_DEFAULT_SCALE = 1
export const ICON_GROW = 1.1
export const ICON_SHRINK = 0.8
export const ICON_SIZE = '1.375em'
export const KEY_ENTER = 'Enter'
export const KEY_SPACE = ' '
export const TOOL_TIP_TEXT_ENTER_FULL_SCREEN = {
  [LOCALES.EN_US]: 'Teach in Full Screen',
  [LOCALES.ES_US]: 'Enseñar en pantalla completa',
}
export const TOOL_TIP_TEXT_EXIT_FULL_SCREEN = {
  [LOCALES.EN_US]: 'Exit Full Screen',
  [LOCALES.ES_US]: 'Salir de pantalla completa',
}
export const TRANSITIONS = {
  NONE: 'none',
  FULL_SCREEN: 'fullScreen',
  WINDOWED: 'windowed',
}
