import React, { useEffect } from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { H5P_IMG_ORIGIN } from '../../lib/config'
import {
  updateCmiInteractives,
  updateLMSSuspendData,
} from '../../lib/state/actions/cmi'
import {
  completeSlide,
  dragLabelInitialize,
  dragLabelShowFrameDropZones,
  dragLabelSubmit,
  dragLabelUpdate,
} from '../../lib/state/actions/player'
import { DRAG_AND_DROP_ERRORS } from '../../utils/dragAndDropHelper'

import DragLabelComponent from './component'

const BASE_SIZE_PX = 16
const BODY_TEXT = 'BodyText'
const EMPTY_STRING = ''
const IMAGE = 'Image'
const LABEL_LENGTH_THRESHOLD = 5
const NO_OP = () => {}
const THREE_COLUMNS = 3
const TITLE_DRAG_TYPE_INDICATOR = '::'
const TWO_COLUMNS = 2

const DragLabelContainer = ({
  attempts,
  buttons,
  completeSlide,
  currentSlide,
  draggables,
  dragLabelInitialize,
  dragLabelShowFrameDropZones,
  dragLabelUpdate,
  dragLabelSubmit,
  dropZones,
  endTime,
  helperText,
  id,
  question,
  shouldInitialize,
  showFrameDropZones,
  startTime,
  title,
  updateCmiInteractives,
  updateLMSSuspendData,
}) => {
  useEffect(() => (shouldInitialize ? initializeInteractive() : NO_OP()), []) //runs only on mount

  const initializeInteractive = shouldInitializeCMI => {
    const formattedDraggables = getFormattedDraggables()
    const formattedDropZones = getFormattedDropZones()
    const initialButtonState = {
      checkEnabled: true,
      resetEnabled: false,
    }
    const initialHelperTextState = {
      helperText: null,
    }
    const newStartTime = endTime || startTime

    dragLabelInitialize({
      interactiveId: id,
      draggables: formattedDraggables,
      dropZones: formattedDropZones,
      buttons: initialButtonState,
      helperText: initialHelperTextState,
      attempts,
      startTime: newStartTime,
    })
    shouldInitializeCMI &&
      updateSuspendData(
        formattedDraggables,
        formattedDropZones,
        initialButtonState,
        initialHelperTextState,
        attempts,
        newStartTime,
        endTime,
      )
  }

  const updateSuspendData = (
    draggables,
    updatedDropZones,
    updatedButtons,
    updatedHelperText,
    attempts,
    startTime,
    endTime,
  ) => {
    updateCmiInteractives({
      interactives: {
        [id]: {
          buttons: updatedButtons,
          draggables,
          dropZones: updatedDropZones,
          helperText: updatedHelperText,
          attempts,
          startTime,
          endTime,
        },
      },
    })
    updateLMSSuspendData()
  }

  const getTitle = () => {
    const parsedTitle = title && title.split(TITLE_DRAG_TYPE_INDICATOR)[0]

    return parsedTitle
  }

  const getElements = () => {
    const { task } = question || {}
    const { elements } = task || {}

    return elements
  }

  const getElementMetaData = element => {
    const { type } = element || {}
    const { metadata } = type || {}

    return metadata
  }

  const getBodyText = () => {
    const elements = getElements() || []
    const bodyTextElements = elements.filter(isElementBodyText)
    const { type } = bodyTextElements[0] || {}
    const { params } = type || {}
    const { text } = params || {}

    return text
  }

  const isElementImage = element => {
    const metadata = getElementMetaData(element) || {}
    const { contentType } = metadata || {}
    const isImage = contentType === IMAGE

    return isImage
  }

  const isElementBodyText = element => {
    const metadata = getElementMetaData(element) || {}
    const { extraTitle, title } = metadata || {}
    const isBodyText = title === BODY_TEXT || extraTitle === BODY_TEXT

    return isBodyText
  }

  const getImage = () => {
    const elements = getElements() || []
    const imageElement = elements.filter(isElementImage)[0] || {}
    const { type, width, height, x } = imageElement || {}
    const { params } = type || {}
    const { alt, file } = params || {}
    const { path } = file || {}
    const source = path && H5P_IMG_ORIGIN + path
    const image = {
      alt,
      source,
      width,
      height,
      x,
    }

    return image
  }

  const getFormattedDraggable = draggable => {
    const { dropZones, type } = draggable || {}
    const { params, subContentId } = type || {}
    const { text } = params || {}
    const formattedDraggable = {
      id: subContentId,
      label: text,
      zones: dropZones,
    }

    return formattedDraggable
  }

  const getFormattedDraggables = () => {
    const elements = getElements() || []
    const formattedDraggables = elements
      .filter(element => !isElementBodyText(element))
      .filter(element => !isElementImage(element))
      .map(getFormattedDraggable)

    return formattedDraggables
  }

  //H5P sends dropzone positions relative to the play area. Use this function to get x position relative to the image.
  const getDropZoneXRelativeToImage = dropZone => {
    const { x: dropZoneX } = dropZone || {}
    const { x: trimmedPercent, width: imageWidth } = getImage() || {}
    const imageWidthInPixels = imageWidth * BASE_SIZE_PX
    const { settings } = question || {}
    const { size } = settings || {}
    const { width: h5pTaskAreaWidth } = size || {}
    const imageToTaskArea = imageWidthInPixels / h5pTaskAreaWidth
    const adjustedDropZoneX = (dropZoneX - trimmedPercent) / imageToTaskArea

    return adjustedDropZoneX
  }

  const formatDropZone = dropZone => {
    const { label, y: dropZoneY } = dropZone || {}
    const adjustedDropZoneX = getDropZoneXRelativeToImage(dropZone)
    const formattedDropZone = {
      correct: false,
      draggable: null,
      graded: false,
      id: label,
      label,
      left: adjustedDropZoneX,
      top: dropZoneY,
    }

    return formattedDropZone
  }

  const getFormattedDropZones = () => {
    const { task } = question || {}
    const { dropZones = [] } = task || {}
    const formattedDropZones = dropZones.map(formatDropZone)

    return formattedDropZones
  }

  const draggableTextIsLong = draggable => {
    const { label = EMPTY_STRING } = draggable || {}

    return label.length > LABEL_LENGTH_THRESHOLD
  }

  const getDraggableWithId = id => {
    const matchingDraggables = draggables.reduce(
      (accumulator, draggable, index) => {
        const { id: currentDraggableId } = draggable || {}

        if (currentDraggableId === id) {
          const draggableWithIndex = {
            ...draggable,
            index,
          }

          accumulator.push(draggableWithIndex)
        }
        return accumulator
      },
      [],
    )
    const draggable = matchingDraggables ? matchingDraggables[0] : []

    return draggable
  }

  const onDrop = (draggableId, dropZoneId) => {
    const draggable = getDraggableWithId(draggableId)
    const updatedDropzones = dropZones.map(dropZone => {
      //Clear any dropzones with the draggable being moved
      const { draggable: dropZoneDraggable } = dropZone
      const { id: dropZoneDraggableId } = dropZoneDraggable || {}

      if (dropZoneDraggableId === draggableId) {
        dropZone.draggable = null
      }

      //Populate the dropzone the draggable is being moved to
      const { id } = dropZone
      const { label, index } = draggable || {}

      if (dropZoneId === id) {
        const newDropZoneDraggable = { id: draggableId, index, label }

        dropZone.draggable = newDropZoneDraggable
      }

      return dropZone
    })
    const updatedButtons = {
      checkEnabled: true,
      resetEnabled: updatedDropzones.some(dropZoneHasDraggable),
    }

    dragLabelUpdate(id, draggables, updatedDropzones, updatedButtons)
    updateSuspendData(
      draggables,
      updatedDropzones,
      updatedButtons,
      helperText,
      attempts,
      startTime,
      endTime,
    )
  }

  const isDraggableInDropZone = draggableId => {
    const isInDropZone = dropZones.reduce((accumulator, dropZone) => {
      const { draggable: dropZoneDraggable } = dropZone || {}
      const { id: dropZoneDraggableId } = dropZoneDraggable || {}

      accumulator = dropZoneDraggableId === draggableId || accumulator

      return accumulator
    }, false)

    return isInDropZone
  }

  const getDisplayDraggables = () => {
    const displayDraggables = draggables.map(draggable => {
      const { id } = draggable
      const isInDropZone = isDraggableInDropZone(id)
      const placeHolderDraggable = { id: null, index: null, label: null }

      return isInDropZone ? placeHolderDraggable : draggable
    })

    return displayDraggables
  }

  const getNumOfOriginColumns = isLongLabels => {
    let numOfColumns = TWO_COLUMNS

    if (!isLongLabels) {
      const isSixOrLessDraggables = draggables.length <= 6

      numOfColumns = isSixOrLessDraggables ? TWO_COLUMNS : THREE_COLUMNS
    }

    return numOfColumns
  }

  const onCheck = () => {
    const allDropZonesOccupied = dropZones.every(dropZone => dropZone.draggable)
    const someDropZonesOccupied = dropZones.some(
      dropZone => !dropZone.draggable,
    )
    const updatedButtons = {
      checkEnabled: false,
      resetEnabled: true,
    }
    const updatedHelperText = {
      enabledText: DRAG_AND_DROP_ERRORS.DRAG_LABEL,
    }

    if (someDropZonesOccupied) {
      dragLabelUpdate(
        id,
        draggables,
        dropZones,
        updatedButtons,
        updatedHelperText,
      )
      updateSuspendData(
        draggables,
        dropZones,
        updatedButtons,
        updatedHelperText,
        attempts,
        startTime,
        endTime,
      )
    }

    if (allDropZonesOccupied) {
      updatedHelperText.enabledText = null
      let score = 0
      const updatedDropZones = dropZones.map((dropZone, index) => {
        const isCorrect =
          dropZone.draggable && dropZone.draggable.index === index
        if (isCorrect) score++
        return {
          ...dropZone,
          graded: true,
          correct: isCorrect,
        }
      })
      const completed = updatedDropZones.every(
        dropZone => dropZone.graded && dropZone.correct,
      )

      if (completed) {
        completeSlide(currentSlide)
      }

      dragLabelSubmit(id, updatedDropZones, updatedButtons, score)
      updateSuspendData(
        draggables,
        updatedDropZones,
        updatedButtons,
        updatedHelperText,
        attempts + 1,
        startTime,
        endTime,
      )
    }
  }

  const dropZoneHasDraggable = dropZone => {
    const hasDraggable = !!dropZone.draggable

    return hasDraggable
  }

  const onReset = () => initializeInteractive(true)

  const revealFrameDropZones = () => dragLabelShowFrameDropZones(id, true)
  const hideFrameDropZones = () => dragLabelShowFrameDropZones(id, false)
  const formattedTitle = getTitle()
  const bodyText = getBodyText()
  const { alt: imageAlt, source: imageSource } = getImage() || {}
  const isLongLabels = draggables.some(draggableTextIsLong)
  const displayDraggables = getDisplayDraggables()
  const numOfOriginColumns = getNumOfOriginColumns(isLongLabels)
  const { checkEnabled, resetEnabled } = buttons || {}
  const { enabledText } = helperText || {}

  return (
    <DragLabelComponent
      bodyText={bodyText}
      canCheck={checkEnabled}
      canReset={resetEnabled}
      draggables={displayDraggables}
      dropZones={dropZones}
      helperText={enabledText}
      imageAlt={imageAlt}
      imageSource={imageSource}
      longLabels={isLongLabels}
      numOfOriginColumns={numOfOriginColumns}
      onCheck={onCheck}
      onDragEnd={hideFrameDropZones}
      onDragStart={revealFrameDropZones}
      onDrop={onDrop}
      onReset={onReset}
      showFrameDropZones={showFrameDropZones}
      title={formattedTitle}
    />
  )
}

DragLabelContainer.propTypes = {
  attempts: PropTypes.number,
  buttons: PropTypes.object,
  checkEnabled: PropTypes.bool,
  completeSlide: PropTypes.func,
  currentSlide: PropTypes.array,
  draggables: PropTypes.array,
  dragLabelInitialize: PropTypes.func,
  dragLabelShowFrameDropZones: PropTypes.func,
  dragLabelSubmit: PropTypes.func,
  dragLabelUpdate: PropTypes.func,
  dropZones: PropTypes.array,
  endTime: PropTypes.string,
  helperText: PropTypes.object,
  id: PropTypes.string,
  question: PropTypes.object,
  resetEnabled: PropTypes.bool,
  shouldInitialize: PropTypes.bool,
  showFrameDropZones: PropTypes.bool,
  startTime: PropTypes.string,
  title: PropTypes.string,
  updateCmiInteractives: PropTypes.func,
  updateLMSSuspendData: PropTypes.func,
}

DragLabelContainer.defaultProps = {
  checkEnabled: false,
  completeSlide() {},
  draggables: [],
  dragLabelInitialize() {},
  dragLabelShowFrameDropZones() {},
  dragLabelSubmit() {},
  dragLabelUpdate() {},
  dropZones: [],
  resetEnabled: false,
  showFrameDropZones: false,
  updateCmiInteractives() {},
  updateLMSSuspendData() {},
}

const mapDispatchToProps = dispatch => ({
  completeSlide: slidePosition => dispatch(completeSlide(slidePosition)),
  dragLabelInitialize: (id, draggables, dropZones, buttons, helperText) =>
    dispatch(
      dragLabelInitialize(id, draggables, dropZones, buttons, helperText),
    ),
  dragLabelShowFrameDropZones: (id, showFrameDropZones) =>
    dispatch(dragLabelShowFrameDropZones(id, showFrameDropZones)),
  dragLabelUpdate: (id, draggables, dropZones, buttons, helperText) =>
    dispatch(dragLabelUpdate(id, draggables, dropZones, buttons, helperText)),
  dragLabelSubmit: (id, dropZones, buttons, score) =>
    dispatch(dragLabelSubmit(id, dropZones, buttons, score)),
  updateCmiInteractives: data => dispatch(updateCmiInteractives(data)),
  updateLMSSuspendData: () => dispatch(updateLMSSuspendData()),
})

const mapStateToProps = ({ player }, ownProps) => {
  const { id } = ownProps || {}
  const interactiveState = player.interactiveStates[id] || null

  return {
    ...interactiveState,
    currentSlide: player.currentSlide,
    currentSlideData: player.currentSlideData,
    shouldInitialize: interactiveState === null,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DragLabelContainer)
