/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'
import PropTypes from 'prop-types'

const IconPpt = ({ color = 'currentColor' }) => {
  const height = 22
  const width = 18
  const heightWithUnits = `${height}px`
  const widthWithUnits = `${width}px`

  const viewBox = `0 0 ${width} ${height}`

  return (
    <svg height={heightWithUnits} viewBox={viewBox} width={widthWithUnits}>
      <title>Open PowerPoint</title>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-100.000000, -160.000000)">
          <g id="ppt-icon" transform="translate(100.000000, 160.000000)">
            <path
              d="M2.24911987,0 C1.0078793,0 0,1.002 0,2.238 L0,2.238 L0,19.764 C0,20.999 1.0078793,22.001 2.24911987,22.001 L2.24911987,22.001 L15.7488684,22.001 C16.9921207,22.001 18,20.999 18,19.764 L18,19.764 L18,5.207 L11.8350377,0 L2.24911987,0 Z"
              fill={color}
            />
            <path
              d="M3.78567001,18 L3.78567001,15.55338 L4.15472005,15.55338 C5.61134003,15.55338 6.45415997,14.76138 6.45415997,13.47812 L6.45415997,12.22445 C6.45415997,11.0130199 5.61134003,10.2999998 4.15472005,10.2999998 L2.27581007,10.2999998 L2.27581007,18 L3.78567001,18 Z M4.05153999,14.3581198 L3.78567001,14.3581198 L3.78567001,11.5264998 L4.05153999,11.5264998 C4.56513002,11.5264998 4.89732999,11.7826898 4.89732999,12.2772498 L4.89732999,13.5309198 C4.89732999,14.0738799 4.56513002,14.3581198 4.05153999,14.3581198 Z M8.70267001,18 L8.70267001,15.55338 L9.07172005,15.55338 C10.52834,15.55338 11.37116,14.76138 11.37116,13.47812 L11.37116,12.22445 C11.37116,11.0130199 10.52834,10.2999998 9.07172005,10.2999998 L7.19281007,10.2999998 L7.19281007,18 L8.70267001,18 Z M8.96853999,14.3581198 L8.70267001,14.3581198 L8.70267001,11.5264998 L8.96853999,11.5264998 C9.48213002,11.5264998 9.81432999,11.7826898 9.81432999,12.2772498 L9.81432999,13.5309198 C9.81432999,14.0738799 9.48213002,14.3581198 8.96853999,14.3581198 Z M14.6652199,18.0000002 L14.6652199,11.5642301 L16.03505,11.5642301 L16.03505,10.30022 L11.77629,10.30022 L11.77629,11.5642301 L13.1553599,11.5642301 L13.1553599,18.0000002 L14.6652199,18.0000002 Z"
              fill="#FFFFFF"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

IconPpt.propTypes = {
  color: PropTypes.string,
}

export default IconPpt
