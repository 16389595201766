import React, { Component } from 'react'

import PropTypes from 'prop-types'

import LayoutElement from '../../../LayoutElement'

import { Container, Element, ElementsContainer } from './style'

const ELEMENTS_ACCESSIBILITY_LABEL = 'Layout elements container'
const LAYOUT_ACCESSIBILITY_LABEL = 'Equal columns no hero layout container'
const LAYOUT_DATA_TEST_ID = 'equal-columns-no-hero-layout-container'

class EqualColumnsNoHeroComponent extends Component {
  getElementComponent = (elementModules, index) => {
    const { getElementProps } = this.props
    const { elementProps, key, layoutElementProps } =
      getElementProps(elementModules, index) || {}

    return (
      <Element key={key} {...elementProps}>
        <LayoutElement {...layoutElementProps} />
      </Element>
    )
  }

  getElementComponents = () => {
    const { elements } = this.props
    const elementComponents = elements.map((elementModules, index) =>
      this.getElementComponent(elementModules, index),
    )

    return elementComponents
  }

  getRenderProps = () => {
    const { foregroundColor } = this.props
    const layoutElements = this.getElementComponents()
    const containerProps = {
      accessibilityLabel: LAYOUT_ACCESSIBILITY_LABEL,
      'data-testid': LAYOUT_DATA_TEST_ID,
    }
    const elementsContainerProps = {
      accessibilityLabel: ELEMENTS_ACCESSIBILITY_LABEL,
      foregroundColor,
    }
    const renderProps = {
      containerProps,
      elementsContainerProps,
      layoutElements,
    }

    return renderProps
  }

  render = () => {
    const { containerProps, elementsContainerProps, layoutElements } =
      this.getRenderProps() || {}

    return (
      <Container {...containerProps}>
        <ElementsContainer {...elementsContainerProps}>
          {layoutElements}
        </ElementsContainer>
      </Container>
    )
  }
}

EqualColumnsNoHeroComponent.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
  foregroundColor: PropTypes.string,
  getElementProps: PropTypes.func,
}

EqualColumnsNoHeroComponent.defaultProps = {
  elements: [],
  getElementProps() {},
}

export default EqualColumnsNoHeroComponent
