import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Header, Actions, Content } from './component.styles'

const Card = ({ className, title, actions, children }) => {
  return (
    <Wrapper className={className}>
      <Header>{title}</Header>
      {actions && <Actions>{actions}</Actions>}
      <Content>{children}</Content>
    </Wrapper>
  )
}

Card.propTypes = {
  actions: PropTypes.node,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.node.isRequired,
}

export default Card
