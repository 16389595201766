import { LOCALES } from 'utils/locale'

export const EN_US_LANGUAGE = {
  [LOCALES.EN_US]: 'English',
  [LOCALES.ES_US]: 'Inglés',
}
export const ES_US_LANGUAGE = {
  [LOCALES.EN_US]: 'Spanish',
  [LOCALES.ES_US]: 'Español',
}
