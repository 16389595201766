import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

import DragAndDrop from '../../../lib/dragAndDrop'
import SubmitAnswerIcon from '../../SubmitAnswerIcon'

import { Container, Grip, HoverIndicator, Text } from './style'

const DATA_TEST_ID = 'draggable-item'

const setupUseDrag = (item, domElement, canDrag) => {
  const itemObj = { domElement, ...item }
  const collect = monitor => ({
    isDragging: monitor.isDragging(),
  })

  return useDrag({
    item: itemObj,
    canDrag,
    collect,
  })
}

const DragWordDraggableItem = props => {
  const { item, correct, graded } = props

  if (!item || (item && !item.type)) {
    return null
  }

  const [domElement, setDomElement] = useState(null)
  const [isHovered, setIsHovered] = useState(false)
  const refCallback = DragAndDrop.createDraggableRefCallback(setDomElement)
  const canDrag = !graded
  const [{ isDragging }, drag, preview] = setupUseDrag(
    item,
    domElement,
    canDrag,
  )

  //We're using a custom drag layer, turn off the default preview
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])

  const onMouseEvent = event => setIsHovered(event.type === 'mouseenter')

  const { dropped: isInDropZone, index, label } = item || {}
  const tabIndex = '0'

  return (
    <Container
      data-testid={`${DATA_TEST_ID}-${index}`}
      isDragging={isDragging}
      isInDropZone={isInDropZone}
      onMouseEnter={onMouseEvent}
      onMouseLeave={onMouseEvent}
      ref={childElement => refCallback(childElement, drag)}
      tabIndex={tabIndex}
    >
      <HoverIndicator isHovered={isHovered} />
      <Grip />
      <Text>{label}</Text>
      <SubmitAnswerIcon inCorrectLocation={correct} isSubmitted={graded} />
    </Container>
  )
}

DragWordDraggableItem.propTypes = {
  isSubmitted: PropTypes.bool,
  correct: PropTypes.bool,
  graded: PropTypes.bool,
  item: PropTypes.shape({
    dropped: PropTypes.bool,
    id: PropTypes.string,
    index: PropTypes.number,
    interactiveType: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    zones: PropTypes.arrayOf(PropTypes.number),
  }),
}

export default DragWordDraggableItem
