import { createAction } from 'redux-api-middleware'

// class prevents additional calls for an entry if one is already in flight
export class InProgressFetchesCache {
  progressCache = {}

  startedFetch = url => {
    this.progressCache[url] = true
  }

  finishedFetch = url => {
    delete this.progressCache[url]
  }

  inProgress = url => {
    const inProgressNow = this.progressCache[url] || false
    return inProgressNow
  }
}

export const inProgressFetches = new InProgressFetchesCache()

export const getAuthorization = accessToken => {
  return `Bearer ${accessToken}`
}

// includes bailout feature and authorization from bearer token
export const get = (url, types, accessToken) => {
  return createAction({
    bailout: () => {
      const bail = inProgressFetches.inProgress(url)
      return bail
    },
    endpoint: url,
    fetch: async (url, config) => {
      inProgressFetches.startedFetch(url)
      const Authorization = getAuthorization(accessToken)

      const newConfig = {
        ...config,
        cache: 'no-cache',
        headers: {
          Authorization,
          'Content-Type': 'application/json',
        },
      }
      const res = await fetch(url, newConfig)
      inProgressFetches.finishedFetch(url)
      return res
    },
    method: 'GET',
    types,
  })
}

export const createType = (type, meta = {}) => ({
  meta,
  type,
})
