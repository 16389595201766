import { getLocaleFromSession, getLocaleObject } from 'utils/locale'

export const GET_CAPTION_TRACK_WITH_SOURCE = (source = '') => ({
  kind: 'captions',
  label: 'English',
  src: source,
  srclang: 'en',
})

export const GET_ALL_CAPTION_TRACKS = vtts => {
  return Object.entries(vtts).map(([label, source]) => ({
    kind: 'captions',
    src: source.url,
    ...getLocaleObject(label),
  }))
}

export const ShowMenuBarForEnglish = () => {
  const currentLocale = getLocaleFromSession()
  if (currentLocale === 'es-US') {
    return false
  }
  return true
}
