import React from 'react'
import PropTypes from 'prop-types'
import {
  toNoBreakContentArray,
  toContentArray,
} from '../../utils/componentHelpers'

const MarkdownP = ({ children = [], className, 'data-testid': dataTestId }) => {
  const markdownContent = toContentArray(children)
  const noBreakContentArray = toNoBreakContentArray(markdownContent)
  return (
    <p className={className} data-testid={dataTestId}>
      {noBreakContentArray}
    </p>
  )
}

MarkdownP.propTypes = {
  'data-testid': PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  className: PropTypes.string,
}

export default MarkdownP
