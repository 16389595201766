import styled from 'styled-components'
import WarningIcon from '../WarningIcon'

export const SubtleDetails = styled.details`
  font-size: 0.6rem;
  summary {
    cursor: pointer;
  }
`
export const BigWarning = styled(WarningIcon)`
  height: 1.3rem;
  width: 1.3rem;
  vertical-align: middle;
  margin-right: 0.5rem;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: top;
  padding: 0.5rem;
`
