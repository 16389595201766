import styled from 'styled-components'
import { themeGet } from 'styled-system'

const Bullet = styled.span`
  display: inline-block;
  height: 0.8125rem;
  width: 0.8125rem;
  margin-right: 0.5rem;
  border-radius: 50%;
  background-color: ${themeGet('colors.darkGray')};
`

export default Bullet
