import styled from 'styled-components'
import PropTypes from 'prop-types'
import { themeGet } from 'styled-system'
import LoadingIndicator from '../LoadingIndicator'
import { colors } from '../../theme/defaultValues'

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`

export const LoadingContainer = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  align-items: center;
  display: flex;
  flex-direction: column;

  ${LoadingIndicator} {
    top: -0.3rem;
    margin-right: 1rem;
  }
  svg {
    width: 11.5rem;
  }
`

LoadingContainer.propTypes = {
  dataTestId: PropTypes.string,
}

export const LoadingMessage = styled.div`
  color: ${themeGet('colors.secondStepOrange', colors.secondStepOrange)};

  display: flex;
  padding-right: 2rem;
  margin-top: 0.7rem;
`

export const LoadingText = styled.span`
  flex: 1;
  white-space: nowrap;
`
