import styled from 'styled-components'

import { StyledButton } from '../../Button/style'

export const DragQuestionSubmitStyledButton = styled(StyledButton)`
  max-height: 2.25rem;
`

export const StoryContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: gray;
`
