/* istanbul ignore file */
// test suite uses ProgressStatusMedium
import PropTypes from 'prop-types'
import React from 'react'
import { ProgressStatusWrapper } from './component.styles'

const ProgressStatus = ({
  className,
  dataTestId,
  totalAmount = 0,
  totalComplete = 0,
  unitName = 'Lessons',
  status = 'Complete',
}) => {
  return (
    <ProgressStatusWrapper className={className} dataTestId={dataTestId}>
      <span>
        {totalComplete}/{totalAmount} {unitName} {status}
      </span>
    </ProgressStatusWrapper>
  )
}

ProgressStatus.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string.isRequired,
  status: PropTypes.string,
  totalAmount: PropTypes.number,
  totalComplete: PropTypes.number,
  unitName: PropTypes.string,
}

export default ProgressStatus
