import React from 'react'

import PropTypes from 'prop-types'

import interactiveTypes from './interactiveTypes'

const DRAG_LABEL_TO_IMAGE = 'DragLabel'
const DRAG_LABEL_TO_IMAGE_SUFFIX = '::drag-label'
const DRAG_QUESTION = 'DragQuestion'
const DRAG_WORD = 'DragWord'
const DRAG_WORD_SUFFIX = '::drag-word'

const Interactive = props => {
  const { interactiveType, title } = props || {}
  let finalInteractiveType = interactiveType

  //The H5P key DragQuestion key can be one of these types: Drag & Drop Sort Concept, Drag the Word,
  //or Drag Label to Image. Figure that out here.
  const isDragDropVariant = interactiveType === DRAG_QUESTION

  if (isDragDropVariant) {
    const titleIndicatesDragWord = title && title.includes(DRAG_WORD_SUFFIX)
    const titleIndicatesDragLabelToImage =
      title && title.includes(DRAG_LABEL_TO_IMAGE_SUFFIX)

    if (titleIndicatesDragWord) {
      finalInteractiveType = DRAG_WORD
    } else if (titleIndicatesDragLabelToImage) {
      finalInteractiveType = DRAG_LABEL_TO_IMAGE
    } else {
      finalInteractiveType = DRAG_QUESTION
    }
  }

  const InteractiveModule = interactiveTypes[finalInteractiveType]

  if (!InteractiveModule) {
    console.warn(
      'Could not find associated interactive module for Contentful interactive type',
    )

    return null
  }

  return (
    <InteractiveModule
      {...props}
      {...props.config.params}
      id={props.id}
      onSubmit={props.onSubmit}
    />
  )
}

Interactive.propTypes = {
  id: PropTypes.string,
  interactiveType: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
}

Interactive.defaultProps = {
  onSubmit: () => {},
}

export default Interactive
