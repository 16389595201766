import generic from './generic'
import grade1 from './grade1'
import grade2 from './grade2'
import grade3 from './grade3'
import grade4 from './grade4'
import grade5 from './grade5'
import grade6 from './grade6'
import grade7 from './grade7'
import grade8 from './grade8'
import gradehs from './gradehs'
import gradek from './gradek'

export default {
  generic,
  gradek,
  grade1,
  grade2,
  grade3,
  grade4,
  grade5,
  grade6,
  grade7,
  grade8,
  gradehs,
}
