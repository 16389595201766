import styled from 'styled-components'

export const DraggableContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 15px;
`

export const DraggableItemContainer = styled.div`
  display: flex;
`
