/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

const DownloadIcon = () => (
  <svg
    height="20"
    viewBox="0 0 30 20"
    width="30"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Download</title>
    <defs>
      <path d="M0 -2.36847579e-15L30.0574677 -2.36847579e-15 30.0574677 19.9105 0 19.9105z" />
    </defs>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g>
        <mask fill="#fff" />
        <path
          d="M19.83 12.808l-4.665 4.665a.193.193 0 01-.273 0l-4.665-4.665a.193.193 0 01.136-.33h2.622a.193.193 0 00.193-.192V5.88c0-.106.086-.193.192-.193h3.317c.106 0 .193.087.193.193v6.406c0 .106.086.192.193.192h2.621c.172 0 .258.208.136.33m10.165.743c-.276-2-1.42-3.372-3.316-4.097-.158-.06-.21-.139-.226-.302a7.7 7.7 0 00-.159-1.062c-.884-3.623-4.433-5.772-8.069-4.887-.143.035-.22.019-.31-.105-1.749-2.39-4.097-3.41-7.034-3.016-3.332.447-5.887 3.22-6.084 6.581-.01.185-.074.248-.243.3-1.293.4-2.395 1.095-3.217 2.184C.154 10.714-.208 12.49.109 14.402c.307 1.851 1.197 3.366 2.766 4.437 1.142.779 2.429 1.07 3.794 1.071h17.856c.972 0 1.91-.16 2.772-.64 2.044-1.135 3.027-3.335 2.698-5.719"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
)

export default DownloadIcon
