import React from 'react'
import { contentHasDash } from './stringHelpers'
import NoBreakSpan from '../components/NoBreakSpan'

export const toNoBreakContentArray = contentArray =>
  contentArray.map((content, index) => {
    if (contentHasDash(content)) {
      return <NoBreakSpan key={index}>{content}</NoBreakSpan>
    }
    return content
  })

export const toContentArray = content =>
  Array.isArray(content) ? content : [content]
