export const isStringNullOrWhiteSpace = string => {
  const isNullOrWhiteSpace = string == null || string.match(/^\s*$/) != null

  return isNullOrWhiteSpace
}

export const snakeToCamelCase = snakeCaseString => {
  if (!snakeCaseString || typeof snakeCaseString !== 'string') {
    return ''
  }

  const regExp = /([-_][a-z])/g
  const camelCaseString = snakeCaseString.replace(regExp, group =>
    group
      .toUpperCase()
      .replace('-', '')
      .replace('_', ''),
  )

  return camelCaseString
}

export const isEmptyString = string => {
  return (
    string === '' ||
    string === ' ' ||
    string === "''" ||
    string === "' '" ||
    string === '""' ||
    string === '" "'
  )
}

export const isNullString = string => {
  return string === null || string === 'null'
}

export const isUndefinedString = string => {
  return string === undefined || string === 'undefined'
}

export const isEmptyOrNullOrUndefinedString = string => {
  return (
    isEmptyString(string) || isNullString(string) || isUndefinedString(string)
  )
}
