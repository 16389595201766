import styled from 'styled-components'
import { themeGet } from 'styled-system'
import SearchSelect from '../SearchSelect'

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const StyledSearchSelect = styled(SearchSelect)`
  [class*='DropdownButton'] {
    border: transparent;
    color: ${themeGet('colors.darkBlue')};
    font-family: ${themeGet('fontFamilySemibold')};
    height: 1.5625rem;
    padding: 0 0.25rem;
    z-index: 1;

    span {
      padding-right: 0.5rem;
    }
  }

  [class*='DropdownOptions'] {
    box-sizing: border-box;
    width: 279px;
    border: 1px solid #ced8df;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    padding: 0.125rem 0;

    button:not(:last-child) {
      border-bottom: solid 1px ${themeGet('colors.backgroundModalGray')};
    }

    [class*='DropdownOption'] {
      padding: 0.125rem 0.75rem 0.125rem;
      font-family: ${themeGet('fontFamilySemibold')};

      span {
        color: ${themeGet('colors.darkBlue')};
      }

      &:hover,
      &.arrow-selected {
        span {
          color: ${themeGet('colors.white')};
        }
      }
    }
  }

  [class*='LabelWrapper'] {
    display: none;
  }
`
