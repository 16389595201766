import { IS_CONNECTED_TO_API, PLAYER_INSTANCE } from '../lib/config'

const AUTHORIZATION_PREFIX = 'Bearer '
const REQUEST_TYPE = 'application/json'
const TERMINATE = 'Terminate'

// export for testability
export const isLocalStandaloneWithLms = hostLocation => {
  return ['lms.secondstep.local:8091', 'localhost:8091'].includes(hostLocation)
}

const getScormApiUrl = () => {
  const hostLocation = window?.location?.host
  const { learner, instance, sco, prepId } = PLAYER_INSTANCE || {}
  const protocol = isLocalStandaloneWithLms(hostLocation) ? 'http' : 'https'
  const scormApiUrl = `${protocol}://${hostLocation}/api/v1/scorm/${learner}/${instance}/${sco}${
    prepId ? `/${prepId}` : ''
  }`

  return scormApiUrl
}

// sendBeacon requires us to manually send an API call in order for the request to be guaranteed to finish.
// Manually create the terminate beacon.
const getTerminateBeacon = () => {
  const { parent } = window || {}
  const { __CFC_TOKEN__: jwtToken } = parent || {}
  const scormApiUrl = getScormApiUrl()
  const method = TERMINATE
  const request = {
    method,
  }
  const state = {}
  const body = {
    request,
    state,
  }
  const authorization = jwtToken
    ? `${AUTHORIZATION_PREFIX}${jwtToken}`
    : process.env.CFC_TOKEN
  const headers = {
    type: REQUEST_TYPE,
    Authorization: authorization,
  }
  const blobParts = [JSON.stringify(body)]
  const blob = new Blob(blobParts, headers)

  return { url: scormApiUrl, blob }
}

// Normally we use api.terminate(), but some things, such as closing a window, require us to
// send a terminate beacon.
export const sendTerminateBeacon = () => {
  const beacon = getTerminateBeacon()
  const { url, blob } = beacon || {}

  // Only send beacon if backed by an api (eg. not standalone localhost without lms backing)
  IS_CONNECTED_TO_API && navigator.sendBeacon(url, blob)
}
