import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Tab } from 'grommet'

export const TabButtonWrapper = styled.div`
  display: inline-block;
  line-height: 1;
  margin-right: 1.625rem;
  &:last-of-type {
    margin-right: 0;
  }
`

export const StyledTab = styled(Tab).attrs(
  ({ ariaControls, dataTestId, isActive }) => ({
    'data-testid': dataTestId,
    plain: true,
    active: isActive,
    'aria-controls': ariaControls,
  }),
)`
  color: ${({ isActive }) =>
    isActive
      ? themeGet('colors.buttons.tabActive')
      : themeGet('colors.buttons.tabDefault')};
  font-family: ${({ isActive }) =>
    isActive ? themeGet('font.molde.medium') : themeGet('font.molde.regular')};
  font-size: 1.125rem;
  line-height: 1;
  transition: 0.2s;
  white-space: nowrap;
  &:hover {
    color: ${themeGet('colors.buttons.tabActive')};
  }
`
export const Underline = styled.div`
  background-color: ${({ isActive }) =>
    isActive ? themeGet('colors.buttons.tabActive') : 'transparent'};
  border-radius: 0.09375rem;
  height: 0.1875rem;
`

TabButtonWrapper.displayName = 'TabButton'
Underline.displayName = 'Underline'
