import React, { useRef } from 'react'

import PropTypes from 'prop-types'

import AspectRatioWrapper from '../../AspectRatioWrapper'
import DragLabelFrameNodes from '../DragLabelFrameNodes'

import { Frame, Image } from './style'

const FRAME_DATA_TEST_ID = 'image-and-drop-zones-frame'
const IMAGE_DATA_TEST_ID = 'image-with-drop-zones'

//The frame contains the image and dropzones and mantains its aspect ratio.

const DragLabelFrameComponent = ({
  dropZones,
  imageAlt,
  imageSource,
  longLabels,
  onDragEnd,
  onDragStart,
  onDrop,
  showDropZones,
}) => {
  const imageRef = useRef(0)

  const onResize = dimensions => {
    const { height, width } = dimensions || {}
    const { current } = imageRef || {}
    const { offsetTop: imageOffsetTop } = current || {}

    return (
      <Frame data-testid={FRAME_DATA_TEST_ID} height={height} width={width}>
        <DragLabelFrameNodes
          dropZones={dropZones}
          height={height}
          longLabels={longLabels}
          onDragEnd={onDragEnd}
          onDragStart={onDragStart}
          onDrop={onDrop}
          showDropZones={showDropZones}
          top={imageOffsetTop}
          width={width}
        />
        <Image
          alt={imageAlt}
          data-testid={IMAGE_DATA_TEST_ID}
          ref={imageRef}
          src={imageSource}
        />
      </Frame>
    )
  }

  return <AspectRatioWrapper render={onResize} />
}

DragLabelFrameComponent.propTypes = {
  dropZones: PropTypes.arrayOf(PropTypes.object),
  imageAlt: PropTypes.string,
  imageSource: PropTypes.string,
  longLabels: PropTypes.bool,
  onDragEnd: PropTypes.func,
  onDragStart: PropTypes.func,
  onDrop: PropTypes.func,
  showDropZones: PropTypes.bool,
}

DragLabelFrameComponent.defaultProps = {
  imageAlt: "' '",
  dropZones: [],
  onDrop() {},
  onDragEnd() {},
  onDragStart() {},
}

export default DragLabelFrameComponent
