/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'
import PropTypes from 'prop-types'

const IconTrees = ({ color = 'currentColor', height = 69, width = 63 }) => {
  const heightWithUnits = `${height}px`
  const widthWithUnits = `${width}px`

  const viewBox = `0 0 ${width} ${height}`

  return (
    <svg height={heightWithUnits} viewBox={viewBox} width={widthWithUnits}>
      <defs>
        <path
          d="M13.5856698,0 C13.5856698,0 18.2959502,13.0716511 25.953271,20.258567 C24.7819315,20.4672897 23.3426791,20.3831776 21.9314642,20.1744548 C23.9314642,23.5981308 26.3146417,27.0124611 29.0529595,29.7850467 C27.6012461,29.9906542 25.8317757,29.8193146 24.1028037,29.4984424 C26.2242991,33.2741433 28.728972,37.0031153 31.6074766,40.105919 C29.9937695,40.2803738 28.0685358,40.05919 26.165109,39.6666667 C28.4890966,43.5981308 31.165109,47.423676 34.1869159,50.6666667 C32.1962617,50.8878505 29.7975078,50.6012461 27.4454829,50.1028037 C30.2897196,55.0841121 33.6261682,59.9844237 37.4423676,64.0716511 C32.7538941,64.5981308 26.0747664,62.6479751 21.9781931,61.2211838 L21.9781931,69 L13.3800623,69 L13.3800623,62.3302181 C9.62305296,64.0778816 4.37352025,66.1931464 0,66.7538941 C3.8317757,62.7071651 6.79439252,56.8909657 9.0529595,50.7943925 C6.50155763,51.788162 3.71308411,52.6635514 1.24299065,52.9750779 C4.20872274,49.8535826 6.58224299,45.529595 8.47040498,40.894081 C6.91869159,41.4080997 5.34267913,41.8161994 3.894081,42 C6.64174455,39.1152648 8.78785047,35.0124611 10.4548287,30.6791277 C8.84735202,31.3208723 7.15233645,31.8691589 5.61962617,32.1121495 C8.04641745,29.4049844 9.84735202,25.5140187 11.1744548,21.4890966 C9.86292835,22.0809969 8.46105919,22.6074766 7.18971963,22.8566978 C12.9626168,15.8909657 13.5856698,0 13.5856698,0 Z"
          id="path-1"
        />
        <path
          d="M16.8193146,0 C16.8193146,0 17.3302181,12.9781931 22.046729,18.6635514 C21.0090343,18.4610592 19.8629283,18.0311526 18.7912773,17.5482866 C19.8753894,20.834891 21.3489097,24.0124611 23.3302181,26.2211838 C22.0778816,26.0280374 20.6915888,25.576324 19.3800623,25.0529595 C20.7383178,28.5919003 22.4953271,31.9439252 24.7383178,34.2959502 C23.5545171,34.1495327 22.2676012,33.8161994 21,33.3956386 C22.5420561,37.1806854 24.482866,40.7133956 26.9034268,43.2616822 C23.4358255,42.8224299 19.205296,41.0280374 16.4919003,39.7258567 L16.4919003,44.8909657 L11.2554517,44.8909657 L11.2554517,39.2616822 C8.32679128,40.2928349 3.42679128,41.7570093 0,41.376947 C2.46728972,38.728972 4.65389408,35.6043614 6.55140187,32.3925234 C4.99688474,32.7133956 3.42367601,32.894081 2.105919,32.7507788 C4.45482866,30.2180685 6.50155763,27.1744548 8.23676012,24.0903427 C6.82211838,24.3520249 5.37694704,24.4922118 4.19314642,24.3239875 C6.42679128,22.05919 8.37383178,19.271028 10.0062305,16.4766355 C8.85358255,16.6448598 7.6788162,16.7165109 6.7258567,16.5451713 C12.9781931,10.6760125 16.8193146,0 16.8193146,0 Z"
          id="path-3"
        />
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-208, -160)">
          <g transform="translate(208, 160)">
            <g>
              <mask fill={color} id="mask-2">
                <use xlinkHref="#path-1" />
              </mask>
              <g id="Clip-742" />
              <polygon
                fill={color}
                id="Fill-741"
                mask="url(#mask-2)"
                points="-2044.57632 799.080997 604.657009 799.080997 604.657009 -525.517134 -2044.57632 -525.517134"
              />
            </g>
            <g id="Group-761" transform="translate(22.7352, 23.7508)">
              <mask fill={color} id="mask-4">
                <use xlinkHref="#path-3" />
              </mask>
              <g id="Clip-760" />
              <polygon
                fill={color}
                id="Fill-759"
                mask="url(#mask-4)"
                points="-2067.31153 778.330218 581.921807 778.330218 581.921807 -546.267913 -2067.31153 -546.267913"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

IconTrees.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
}

export default IconTrees
