//NOTE: This component is being deprecated in favor of individual layouts. Please
//see the README file for more information.

import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  imageSourceWithOptions,
  isModuleInteractiveOfType,
} from '../../../../utils/contentfulHelper'
import { getPlayerContentDimensions } from '../../../../utils/playerHelper'
import {
  isEmptyOrNullOrUndefinedString,
  isStringNullOrWhiteSpace,
} from '../../../../utils/stringHelper'
import ContentfulImageBackground from '../../../ContentfulImageBackground'
import LayoutElement from '../../../LayoutElement'
import { getElementKey } from '../../utils'

import {
  Columns,
  Content,
  ContentWrapper,
  Header,
  HeroModal,
  Wrapper,
} from './style'

const BOTTOM = 'bottom'
const COLUMNS_DATA_TEST_ID = 'Layout default elements'
const CONTENT_DATA_TEST_ID = 'Layout content'
const DRAG_QUESTION = 'DragQuestion'
const HERO_IMAGE_ACCESSIBILITY_LABEL = 'Hero image background'
const IMAGE_JUXTAPOSITION = 'ImageJuxtaposition'
const LEFT = 'left'
const MODAL_IMAGE_ACCESSIBILITY_LABEL = 'Modal image'
const RIGHT = 'right'
const TEXT = 'text'
const THIRTY_THREE_PERCENT = '33%'
const TOP = 'top'
const WRAPPER_ARIA_LABEL = 'Default layout container'
const VIDEO = 'video'

const LayoutDefault = props => {
  const {
    deviceResolutionTier,
    elements,
    foregroundColor,
    heroAccessibilityLabel,
    heroImage,
    heroAltText,
    heroPosition,
    heroSize,
    isModal,
    isDoneSlide,
    onSubmit,
    theme,
    title,
  } = props || {}

  const getLayoutElements = () => {
    const { elements, isModal, title } = props || {}
    const layoutElements = elements.map((elementModules, index) => {
      const key = getElementKey(index, isModal)
      const isFirstElement = index === 0
      const isInteractiveWithInlineTitle =
        isModuleInteractiveOfType(elementModules[0], DRAG_QUESTION) ||
        isModuleInteractiveOfType(elementModules[0], IMAGE_JUXTAPOSITION)
      const elementTitle =
        title && isFirstElement && !isInteractiveWithInlineTitle && !isModal
          ? title
          : null

      const layoutElementProps = {
        accessibilityLabel: key,
        isModal,
        isDoneSlide,
        modules: elementModules,
        onSubmit,
        theme,
        title: elementTitle,
      }

      return <LayoutElement key={key} {...layoutElementProps} />
    })

    return layoutElements
  }

  const contentLength = elements.length
  const hasContent = contentLength > 0
  const showHero = heroImage && hasContent
  let firstContent = elements[0] || []
  firstContent = firstContent[0]
  const contentIsText =
    contentLength === 1 && firstContent.sys.contentType.sys.id === TEXT
  const contentIsVideo =
    contentLength === 1 && firstContent.sys.contentType.sys.id === VIDEO
  const hasTextBackgroundBubble = foregroundColor != null
  const heroPositionIsTop = heroPosition === TOP
  const heroPositionIsRight = heroPosition === RIGHT
  const heroPositionIsBottom = heroPosition === BOTTOM
  const heroPositionIsLeft = heroPosition === LEFT
  const heroPositionIsTopOrBottom =
    heroPosition === TOP || heroPosition === BOTTOM
  const isFullSizeImageModal = heroImage && !hasContent && !title
  const showTitleOutsideOfElements = isModal && title
  let modalHeroImageSourceWithOptions
  const heroAccessibilityLabelHasCharacters =
    heroAccessibilityLabel && !isStringNullOrWhiteSpace(heroAccessibilityLabel)
  const heroImageAccessibilityLabel = heroAccessibilityLabelHasCharacters
    ? heroAccessibilityLabel
    : HERO_IMAGE_ACCESSIBILITY_LABEL
  let modalImageAccessibilityLabel = MODAL_IMAGE_ACCESSIBILITY_LABEL

  if (isFullSizeImageModal) {
    const { height: playerContentHeight, width: playerContentWidth } =
      getPlayerContentDimensions(theme, deviceResolutionTier) || {}
    modalHeroImageSourceWithOptions = imageSourceWithOptions(
      heroImage,
      playerContentWidth,
      playerContentHeight,
    )
    const modalImageAccessibilityLabelHasCharacters =
      heroAccessibilityLabel &&
      !isStringNullOrWhiteSpace(heroAccessibilityLabel)
    modalImageAccessibilityLabel = modalImageAccessibilityLabelHasCharacters
      ? heroAccessibilityLabel
      : modalImageAccessibilityLabel
  }

  const defaultModalAccesibilityLabel = heroAltText
    ? heroAltText
    : modalImageAccessibilityLabel

  return (
    <Wrapper
      aria-label={WRAPPER_ARIA_LABEL}
      heroPositionIsTopOrBottom={heroPositionIsTopOrBottom}
    >
      {isFullSizeImageModal && (
        <HeroModal>
          {/*Using ContentfulImageBackground here leads to modals that won't expand vertically to display image content.*/}
          {/*Due to the calc functions in Modal css, using an img here is easier than using a RN Image*/}
          {/*This can be converted to a RN Image when modals are fixed/modal layouts are created and this implementation can be deprecated*/}
          <img
            data-testid={modalImageAccessibilityLabel}
            src={modalHeroImageSourceWithOptions}
            alt={
              defaultModalAccesibilityLabel
                ? defaultModalAccesibilityLabel
                : "' '"
            }
            aria-hidden={isEmptyOrNullOrUndefinedString(
              defaultModalAccesibilityLabel,
            )}
          />
        </HeroModal>
      )}

      {showHero && heroPositionIsTop && (
        <ContentfulImageBackground
          accessibilityLabel={heroImageAccessibilityLabel}
          source={heroImage}
          height={heroSize}
        />
      )}
      {showHero && heroPositionIsLeft && (
        <ContentfulImageBackground
          accessibilityLabel={heroImageAccessibilityLabel}
          source={heroImage}
          width={heroSize}
        />
      )}

      {hasContent && (
        <ContentWrapper>
          <Content data-testid={CONTENT_DATA_TEST_ID} isVideo={contentIsVideo}>
            {showTitleOutsideOfElements && <Header>{title}</Header>}
            <Columns
              center={contentIsText}
              data-testid={COLUMNS_DATA_TEST_ID}
              foregroundColor={foregroundColor}
              hasTextBackgroundBubble={hasTextBackgroundBubble}
              isDoneSlide={isDoneSlide}
            >
              {getLayoutElements()}
            </Columns>
          </Content>
        </ContentWrapper>
      )}

      {showHero && heroPositionIsBottom && (
        <ContentfulImageBackground
          accessibilityLabel={heroImageAccessibilityLabel}
          source={heroImage}
          height={heroSize}
        />
      )}
      {showHero && heroPositionIsRight && (
        <ContentfulImageBackground
          accessibilityLabel={heroImageAccessibilityLabel}
          source={heroImage}
          width={heroSize}
        />
      )}
    </Wrapper>
  )
}

LayoutDefault.propTypes = {
  deviceResolutionTier: PropTypes.object,
  elements: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
  heroAccessibilityLabel: PropTypes.string,
  heroImage: PropTypes.string,
  heroAltText: PropTypes.string,
  heroPosition: PropTypes.string,
  heroSize: PropTypes.string,
  isModal: PropTypes.bool,
  onSubmit: PropTypes.func,
  theme: PropTypes.object,
  title: PropTypes.string,
}

LayoutDefault.defaultProps = {
  elements: [],
  heroPosition: TOP,
  heroSize: THIRTY_THREE_PERCENT,
  onSubmit() {},
}

const mapStateToProps = ({ player }) => {
  return {
    deviceResolutionTier: player.deviceResolutionTier,
    theme: player.theme,
    isDoneSlide: player.isDoneSlide && !player.isNextSlide,
  }
}

export default connect(mapStateToProps)(LayoutDefault)
