import { setMultimediaStatus } from 'lib/state/actions/player'
import { PLAYER_MULTIMEDIA_STATUS } from 'lib/state/constants'

export const onVideoPlay = dispatch => {
  dispatch(setMultimediaStatus(PLAYER_MULTIMEDIA_STATUS.PLAYING))
}

export const onVideoStopped = dispatch => {
  dispatch(setMultimediaStatus(PLAYER_MULTIMEDIA_STATUS.STOPPED))
}

export const onVideoEnded = dispatch => {
  dispatch(setMultimediaStatus(PLAYER_MULTIMEDIA_STATUS.ENDED))
}
