import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  initializeRatioWrapper,
  setRatioWrapperHeight,
  updateRatioWrapper,
} from '../../lib/state/actions/player'
import { isBrowserSafari } from '../../utils/browserHelper'

import {
  CONTENT_DATA_TEST_ID,
  RESIZE_EVENT,
  SCALE_MIN,
  WRAPPER_DATA_TEST_ID,
} from './constants'
import { ContentWrapper, ScaleableWrapper } from './ratioWrapper.style'

class RatioWrapper extends Component {
  constructor(props) {
    super(props)

    this.scaleableWrapperRef = React.createRef()
    this.contentWrapperRef = React.createRef()
  }

  componentDidMount() {
    this.handleResize()
    window.addEventListener(RESIZE_EVENT, this.handleResize)
  }

  componentDidUpdate(prevProps) {
    const { contentWrapperRef, props } = this
    const { initializeRatioWrapper, isFullScreen } = props || {}
    const { isFullScreen: prevIsFullScreen, height: prevHeight } =
      prevProps || {}
    const { current: contentWrapper } = contentWrapperRef || {}
    const { offsetHeight: contentHeight } = contentWrapper || {}
    const didExitFullScreen = prevIsFullScreen && !isFullScreen
    const didHeightChange = prevHeight !== contentHeight
    const isSafariGoingFullScreen = isFullScreen && isBrowserSafari //necessary to trigger render when going full screen in Safari

    if (didExitFullScreen) {
      initializeRatioWrapper()
      this.handleResize()
    } else if (didHeightChange || isSafariGoingFullScreen) {
      this.handleResize()
    }
  }

  canScale = () => {
    const { contentWrapperRef, scaleableWrapperRef } = this
    const { current: scaleableWrapper } = scaleableWrapperRef || {}
    const { current: contentWrapper } = contentWrapperRef || {}
    const canScale = scaleableWrapper !== null && contentWrapper !== null

    return canScale
  }

  getScale = () => {
    const { contentWrapperRef, props, scaleableWrapperRef } = this
    const { isFullScreen } = props || {}
    const isSafariFullScreen = isFullScreen && isBrowserSafari
    const { current: contentWrapper } = contentWrapperRef || {}
    const { current: scaleableWrapper } = scaleableWrapperRef || {}
    const {
      offsetHeight: scaleableWrapperHeight = 0,
      offsetWidth: scaleableWrapperWidth = 0,
    } = scaleableWrapper || {}
    const {
      offsetHeight: contentWrapperHeight = 0,
      offsetWidth: contentWrapperWidth = 0,
    } = contentWrapper || {}
    let newScale

    const heightScale = scaleableWrapperHeight / contentWrapperHeight
    const widthScale = scaleableWrapperWidth / contentWrapperWidth
    newScale = Math.min(heightScale, widthScale)

    //Special conditional for Safari since the scaleable wrapper width doesn't grow for Safari when it goes full screen.
    if (isSafariFullScreen) {
      const heightScale = window.screen.height / contentWrapperHeight
      const widthScale = window.screen.width / contentWrapperWidth
      newScale = Math.min(heightScale, widthScale)
    }

    newScale = newScale < SCALE_MIN ? SCALE_MIN : newScale

    return newScale
  }

  handleResize = () => {
    const { contentWrapperRef, props } = this
    const { updateRatioWrapper } = props || {}
    const { current: contentWrapper } = contentWrapperRef || {}
    const {
      offsetHeight: contentWrapperHeight,
      offsetWidth: contentWrapperWidth,
    } = contentWrapper || {}
    const newHeight = contentWrapperHeight
    const newScale = this.getScale()
    const newWidth = contentWrapperWidth

    updateRatioWrapper(newScale, newWidth, newHeight)
  }

  render() {
    const { contentWrapperRef, props, scaleableWrapperRef } = this
    const { children, height, scale, width } = props || {}

    return (
      <ScaleableWrapper
        data-testid={WRAPPER_DATA_TEST_ID}
        ref={scaleableWrapperRef}
      >
        <ContentWrapper
          data-testid={CONTENT_DATA_TEST_ID}
          height={height}
          ref={contentWrapperRef}
          scale={scale}
          width={width}
        >
          {children}
        </ContentWrapper>
      </ScaleableWrapper>
    )
  }
}

RatioWrapper.propTypes = {
  children: PropTypes.node,
  height: PropTypes.number,
  initializeRatioWrapper: PropTypes.func,
  isFullScreen: PropTypes.bool,
  scale: PropTypes.number,
  setRatioWrapperHeight: PropTypes.func,
  updateRatioWrapper: PropTypes.func,
  width: PropTypes.number,
}

RatioWrapper.defaultProps = {
  initializeRatioWrapper() {},
  scale: 1,
  setRatioWrapperHeight() {},
  setRatioWrapperScale() {},
}

const mapDispatchToProps = dispatch => ({
  initializeRatioWrapper: () => dispatch(initializeRatioWrapper()),
  setRatioWrapperHeight: height => dispatch(setRatioWrapperHeight(height)),
  updateRatioWrapper: (scale, width, height) =>
    dispatch(updateRatioWrapper(scale, width, height)),
})

const mapStateToProps = ({ player }) => {
  const ratioWrapper = player?.ratioWrapper ?? {}

  return {
    height: ratioWrapper.height,
    isFullScreen: player.isFullScreen,
    scale: ratioWrapper.scale,
    width: ratioWrapper.width,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RatioWrapper)
