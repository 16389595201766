import styled from 'styled-components/native'

import { DIVIDER_WIDTH } from '../constants'

const CIRCLE_BORDER_RADIUS = '50%'
const INITIAL_HANDLE_DIAMETER = 68
export const INITIAL_HANDLE_RADIUS = INITIAL_HANDLE_DIAMETER / 2

export const DragDetector = styled.View.attrs(({ position }) => ({
  dividerXOffset: DIVIDER_WIDTH / 2,
  left: position - INITIAL_HANDLE_RADIUS,
  size: INITIAL_HANDLE_DIAMETER,
}))`
  height: ${props => props.size}px;
  left: ${props => props.left - props.dividerXOffset}px;
  width: ${props => props.size}px;
`

export const HoverDetector = styled.View`
  cursor: grab;
  height: 100%;
  width: 100%;
`

export const Circle = styled.View.attrs(
  ({
    isDragging,
    theme: {
      colorVars: { imageJuxtaposition: colors },
    },
  }) => ({
    backgroundColor: isDragging
      ? colors.handle.backgroundDrag
      : colors.handle.background,
    borderColor: isDragging ? colors.handle.borderDrag : colors.handle.border,
    borderRadius: CIRCLE_BORDER_RADIUS,
    size: INITIAL_HANDLE_DIAMETER,
    shadowColor: colors.handle.dropShadow,
  }),
)`
  background-color: ${props => props.backgroundColor};
  border-color: ${props => props.borderColor};
  border-radius: ${props => props.borderRadius};
  border-width: 2px;
  height: ${props => props.size}px;
  justify-content: center;
  shadow-color: ${props => props.shadowColor};
  shadow-offset: 5px 5px;
  shadow-opacity: 0.3;
  shadow-radius: 5px;
  width: ${props => props.size}px;
`

export const HandleImage = styled.Image`
  height: 100%;
  width: 100%;
`
