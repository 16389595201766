import styled from 'styled-components'

import { BaseHoverIndicator } from '../DraggableBase/style'

export const DRAGGABLE_HEIGHT = '45px'
export const FULL_HEIGHT = '100%'
export const FULL_WIDTH = '100%'
export const LARGE_DRAGGABLE_WIDTH = '190px' //used for 2 column
export const SMALL_DRAGGABLE_WIDTH = '120px' //used for 3 column

export const Container = styled.div.attrs(
  ({
    theme: {
      colorVars: { dragLabel: colors },
    },
  }) => ({
    backgroundColor: colors.container.background,
    style: {
      height: FULL_HEIGHT,
      width: FULL_WIDTH,
    },
  }),
)`
  align-items: center;
  background-color: ${props => props.backgroundColor};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const ColumnsContainer = styled.div.attrs(
  ({
    theme: {
      colorVars: { dragLabel: colors },
    },
  }) => ({
    backgroundColor: colors.container.background,
    style: {
      height: FULL_HEIGHT,
      width: FULL_WIDTH,
    },
  }),
)`
  background-color: ${props => props.backgroundColor};
  display: flex;
  min-height: 475px;
  min-width: 200px;
`

export const LeftColumn = styled.div`
  margin-right: 0.625rem;
  width: 33%;
`

export const HoverIndicator = styled(BaseHoverIndicator).attrs(
  ({
    isHovered,
    theme: {
      colorVars: { dragAndDrop: colors },
    },
  }) => ({
    style: {
      backgroundColor: isHovered
        ? colors.hoverIndicator.colorHover
        : colors.hoverIndicator.colorNoHover,
      borderRadius: '3px 0 0 3px',
    },
  }),
)``
