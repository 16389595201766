/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

export default () => (
  <svg
    className={'icon'}
    height="51px"
    role={'img'}
    viewBox="0 0 62 51"
    width="62px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#FFF" fillRule="evenodd" opacity="0.176">
      <path
        d="M60.666 33.631c-1.13-2.429-3.917-3.572-6.402-2.628l-10.446 4.172a3.054 3.054 0 00-.076-.89c-.618-2.365-2.517-2.691-5.398-3.185l-1.376-.244c-1.19-.222-2.297-.375-3.968-.605a466.18 466.18 0 01-4.63-.653c-4.923-.696-8.98-.042-15.527 2.6-3.108 1.288-7 1.545-10.673 1.67-.647 0-1.17.528-1.17 1.182v.034c.015.664.554 1.19 1.21 1.182 3.928-.139 8.034-.451 11.486-1.85 6.19-2.502 9.806-3.121 14.325-2.461 2.002.292 3.438.501 4.648.696 1.638.223 2.752.375 3.872.584.51.097.977.173 1.411.25 2.922.5 3.322.695 3.528 1.46.047.18 0 .543-.55 1.231-.86 1.085-2.573 1.488-3.914 1.495l-9.401-1.084a1.173 1.173 0 00-1.263 1.079 1.18 1.18 0 001.015 1.27l9.464 1.03h.123a8.06 8.06 0 004.814-1.475l13.356-5.284a2.74 2.74 0 013.439 1.39 2.74 2.74 0 01.082 2.135 2.73 2.73 0 01-1.458 1.62l-16.03 7.42c-.041 0-4.683 1.926-8.522 1.74-1.98-.12-4.332-.933-6.678-1.746-2.344-.814-4.532-1.593-6.498-1.697-3.102-.174-10.798 1.3-13.171 1.73a1.188 1.188 0 00-.935 1.391c.116.64.723 1.061 1.353.944l.022-.005c6.946-1.321 10.572-1.815 12.635-1.703 1.623.09 3.679.814 5.859 1.579 2.462.863 5.007 1.752 7.331 1.87h.687c4.203 0 8.68-1.849 8.941-1.947l15.942-7.44c2.56-1.144 3.719-4.17 2.588-6.76-.015-.032-.029-.065-.045-.097z"
        stroke="#FFF"
        strokeWidth="1.1"
      />
      <path d="M33.595.446a7.107 7.107 0 00-5.204 2.27c-2.844 3.024-2.841 7.846 0 10.873l10.62 11.337a1.203 1.203 0 001.758 0L51.414 13.6c2.846-3.024 2.846-7.848 0-10.872a7.116 7.116 0 00-10.42 0l-1.092 1.154-1.09-1.167a7.137 7.137 0 00-5.217-2.27" />
    </g>
  </svg>
)
