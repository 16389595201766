import React, { Component } from 'react'

import PropTypes from 'prop-types'

import { colors } from '../../../../styles/styleGuide'
import { getElementKey } from '../../utils'

import VideoWithTitleComponent from './component'

const WHITE = colors.white
const DEFAULT_BACKGROUND_COLOR = `${WHITE};`

class VideoWithTitleContainer extends Component {
  getPropsForElementAtIndex = (elementModules, index) => {
    const { onSubmit, slideBackgroundColor } = this.props
    const key = getElementKey(index)
    const layoutElementProps = {
      modules: elementModules,
      onSubmit,
      slideBackgroundColor,
    }

    return { key, layoutElementProps }
  }

  getRenderProps = () => {
    const {
      elements,
      foregroundColor,
      slideBackgroundColor,
      title,
    } = this.props
    const getElementProps = this.getPropsForElementAtIndex
    const videoWithTitleProps = {
      elements,
      foregroundColor,
      getElementProps,
      slideBackgroundColor,
      title,
    }

    return videoWithTitleProps
  }

  render = () => {
    const videoWithTitleProps = this.getRenderProps() || {}

    return <VideoWithTitleComponent {...videoWithTitleProps} />
  }
}

VideoWithTitleContainer.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
  foregroundColor: PropTypes.string,
  onSubmit: PropTypes.func,
  slideBackgroundColor: PropTypes.string,
  title: PropTypes.string,
}

VideoWithTitleContainer.defaultProps = {
  elements: [],
  onSubmit() {},
  slideBackgroundColor: DEFAULT_BACKGROUND_COLOR,
}

export default VideoWithTitleContainer
