import React from 'react'
import PropTypes from 'prop-types'
import ErrorMessage from '../ErrorMessage/component.jsx'

const propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default function NotFound(props) {
  const additionalDetails = `No match for ${props.location.pathname}`
  return <ErrorMessage additionalDetails={additionalDetails} statusCode={404} />
}

NotFound.propTypes = propTypes
