/** A library wrapper for the npm package react-csv: https://www.npmjs.com/package/react-csv */
import React, { useState, useEffect, useRef, Fragment } from 'react'
import PropTypes from 'prop-types'
import { CSVLink } from 'react-csv'
import {
  AsyncLinkWrapper,
  BasicLinkWrapper,
  StyledLink,
} from './component.styles'
import { iconMap } from './data'
import {
  DOWNLOAD_DATA_TEST_ID,
  DOWNLOAD_LOADING_TEXT,
  ERROR_MESSAGE,
} from './constants'

let shouldFocus = false

const CsvDownloadLink = ({
  asyncOnClick,
  children,
  data,
  filename,
  headers,
  isAsync,
  isDisabled, // Added for Lesson Reports to prevent downloading csv on component re-render
}) => {
  const [isCsvLoading, setCsvLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const csvInstance = useRef()

  useEffect(() => {
    const dataHasPopulated =
      data?.length > 0 && csvInstance.current && csvInstance.current.link
    if (dataHasPopulated) {
      setCsvLoading(false)
      const isDataValid = validateIncomingData()
      if (!isDataValid) {
        setErrorMessage(ERROR_MESSAGE)
        return null
      }
      const timeout = setTimeout(() => {
        if (shouldFocus) {
          csvInstance.current.link.focus()
          shouldFocus = false
        }
      })
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [data])

  const handleAsyncClick = async event => {
    if (event.type !== 'click' && event.key !== 'Enter') {
      return
    }
    try {
      setCsvLoading(true)
      await asyncOnClick()
      shouldFocus = true
    } catch (error) {
      console.error(error)
      setCsvLoading(false)
      setErrorMessage(ERROR_MESSAGE)
    }
  }

  const validateIncomingData = () => {
    const isFileNameValid = typeof filename === 'string'
    if (!isFileNameValid) {
      console.error('Filename must be a string:', filename)
    }
    const areHeadersValid =
      Array.isArray(headers) &&
      headers.every(item => {
        const { label, key } = item || {}
        return label && key
      })

    if (!areHeadersValid) {
      console.error('Headers should be a Array of objects:  ', headers)
    }
    const isDataValid =
      Array.isArray(data) &&
      data.every(item => {
        return typeof item === 'object' && item !== null
      })

    if (!isDataValid) {
      console.error('Data should be a Array of objects: ', data)
    }
    return isFileNameValid && areHeadersValid && isDataValid
  }

  const hasError = errorMessage
  if (!isAsync && !hasError) {
    const isAllCSVDataValid = validateIncomingData()
    if (!isAllCSVDataValid) {
      setErrorMessage(ERROR_MESSAGE)
      return null
    }
  }
  const Icon = isCsvLoading ? iconMap['loading'] : iconMap['download']
  const downloadLinkText = isCsvLoading ? DOWNLOAD_LOADING_TEXT : children

  const displayAsyncLink = isAsync && asyncOnClick && !hasError
  const displayBasicLink = !isAsync && !asyncOnClick && !hasError

  // assigned a className to various components below to target for styling because using it
  // as a variable in the component.styles.js stylesheet file causes this error:
  // "Invalid hook call. Hooks can only be called inside of the body of a function component."
  //
  // ex. const StyledComponent = styled.div`
  //       ${CsvDownloadLink} {
  //         color: blue;
  //       }
  //     `

  return (
    <Fragment key={new Date().getMilliseconds()}>
      {displayAsyncLink && (
        <AsyncLinkWrapper className="csv-download-link">
          <StyledLink
            dataTestId={DOWNLOAD_DATA_TEST_ID}
            disabled={isCsvLoading || isDisabled}
            onClick={handleAsyncClick}
            onKeyPress={handleAsyncClick}
          >
            <Icon />
            {downloadLinkText}
          </StyledLink>
          {data?.length > 0 ? (
            <CSVLink
              data={data}
              filename={filename}
              headers={headers}
              ref={csvInstance}
            />
          ) : (
            undefined
          )}
          {errorMessage && <span>{errorMessage}</span>}
        </AsyncLinkWrapper>
      )}
      {displayBasicLink && !errorMessage && (
        <BasicLinkWrapper
          className="csv-download-link"
          dataTestId={DOWNLOAD_DATA_TEST_ID}
        >
          <CSVLink
            data={data}
            filename={filename}
            headers={headers}
            ref={csvInstance}
          >
            <Icon />
            {children}
          </CSVLink>
          {errorMessage && <span>{errorMessage}</span>}
        </BasicLinkWrapper>
      )}
    </Fragment>
  )
}

CsvDownloadLink.propTypes = {
  asyncOnClick: PropTypes.func,
  children: PropTypes.string,
  data: PropTypes.array,
  filename: PropTypes.string,
  headers: PropTypes.array,
  isAsync: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
}

export default CsvDownloadLink
