/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

const CollapsibleIcon = () => (
  <svg height="13" viewBox="0 0 21 13" width="21">
    <path
      d="M17.039 12.065c1.034 0 1.88.793 1.956 1.815l.005.147c0 .081-.005.161-.014.24l.001.079v10.718c0 1.105-.888 2-2 2-1.104 0-2-.898-2-2V15.99H5.961A1.957 1.957 0 014 14.027c0-1.084.871-1.962 1.961-1.962h11.078z"
      fill="currentColor"
      fillRule="evenodd"
      stroke="none"
      strokeWidth="1"
      transform="translate(-48 -748) translate(20 637) translate(27.107 94) scale(1 -1) rotate(-45 -35.733 0)"
    />
  </svg>
)

export default CollapsibleIcon
