import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Paragraph } from 'grommet'

const FormErrorMessage = ({ children, className, textAlign }) => (
  <Paragraph
    a11yTitle={'Error Message'}
    className={className}
    color={'status-critical'}
    data-testid="error-message"
    responsive
    size={'medium'}
    textAlign={textAlign}
  >
    {children}
  </Paragraph>
)

FormErrorMessage.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  textAlign: PropTypes.string,
}

FormErrorMessage.defaultProps = {
  textAlign: 'start',
}

export default styled(FormErrorMessage)``
