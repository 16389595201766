import React from 'react'

import PropTypes from 'prop-types'

import { AspectRatioContainer } from './style'

const DATA_TEST_ID = 'Aspect ratio container'

const AspectRatioWrapperComponent = props => {
  const { height, render: renderProp, setAspectRatio, width } = props || {}

  return (
    <AspectRatioContainer data-testid={DATA_TEST_ID} onLayout={setAspectRatio}>
      {renderProp({ height, width })}
    </AspectRatioContainer>
  )
}

AspectRatioWrapperComponent.propTypes = {
  height: PropTypes.number,
  render: PropTypes.func,
  setAspectRatio: PropTypes.func,
  width: PropTypes.number,
}

AspectRatioWrapperComponent.defaultProps = {
  height: 0,
  render() {},
  setAspectRatio() {},
  width: 0,
}

export default AspectRatioWrapperComponent
