import { getEmptyImage } from 'react-dnd-html5-backend'

//DragAndDropUtils is built to be used internally by DragAndDropInterface.

class DragAndDropUtils {
  handleDragPropCollection = monitor => {
    const isDragging =
      monitor && monitor.isDragging ? monitor.isDragging() : false

    return {
      isDragging,
    }
  }

  handleDropPropCollection = monitor => {
    const isOverCurrentTarget = { shallow: true } //is over _just_ the current target
    const canDrop = monitor && monitor.canDrop ? monitor.canDrop() : false
    const isOver =
      monitor && monitor.isOver ? monitor.isOver(isOverCurrentTarget) : false

    return {
      canDrop,
      isOver,
    }
  }

  createHoverHandler = callback => {
    return item => {
      const { domElement } = item || {}
      const { offsetHeight = 0 } = domElement || {}

      callback(offsetHeight)
    }
  }

  //Disable the default draggable preview (to use our own custom drag layer)
  disableDraggableDefaultPreview = dragPreviewRef => {
    if (!dragPreviewRef) {
      console.warn(
        'Unable to disable the default draggable preview ref because no dragPreviewRef was provided.',
      )

      return
    }

    dragPreviewRef(getEmptyImage(), { captureDraggingState: true })
  }
}

export default new DragAndDropUtils()
