import React, { Component } from 'react'

import PropTypes from 'prop-types'
import Markdown from 'react-markdown'

import { GenericCopy } from '../../text'

import {
  OptionItem,
  OptionItemCorrect,
  OptionItemCorrectMarkedIncorrect,
  OptionItemIncorrectMarkedCorrect,
  OptionItemSubmitted,
} from './style'

const ARIA_LABEL_SUFFIX = 'answer'
const CHECKBOX = 'checkbox'
const CORRECT = 'correct'
const DATA_TEST_ID = 'multiChoiceOption'
const INCORRECT = 'incorrect'
const PARAGRAPH = 'paragraph'
const DISALLOWED_OPTION_MARKDOWN_TYPES = [PARAGRAPH]
const SELECTED = 'Selected'
const SPACE = ' '
const RADIO = 'radio'
const UNSELECTED = 'Unselected'

class MultiChoiceOption extends Component {
  option = () => {
    // determine if the option was:
    //    1. unevaluated, and the learner marked it as correct
    //    2. correct, and the learner marked it as correct
    //    3. unevaluated, and the learner did not mark it as correct
    //    4. incorrect, and the learner did not mark it as correct
    //    5. correct, but the learner did not mark it as correct
    //    6. incorrect, but the learner marked it as correct
    const { disabled, isCorrect, isSelected, isUnevaluated } = this.props

    if (disabled) {
      switch (true) {
        case isUnevaluated && isSelected: // #1
        case isSelected && isCorrect: // #2
          return OptionItemCorrect
        case isUnevaluated && !isSelected: // #3
        case !isSelected && !isCorrect: // #4
          return OptionItemSubmitted
        case isSelected && !isCorrect: // #5
          return OptionItemIncorrectMarkedCorrect
        case !isSelected && isCorrect: // #6
          return OptionItemCorrectMarkedIncorrect
      }
    }

    return OptionItem
  }

  input = () => {
    const { disabled, groupName, id, isRadio, onBlur, onChange, onFocus } =
      this.props
    const ariaLabel = this.ariaLabel()
    const type = isRadio ? RADIO : CHECKBOX
    const inputProps = {
      'aria-label': ariaLabel,
      disabled,
      id,
      name: groupName,
      onBlur,
      onChange,
      onFocus,
      type,
    }

    return <input {...inputProps} />
  }

  label = () => {
    const { text } = this.props

    return (
      <span>
        <GenericCopy>
          <Markdown
            disallowedTypes={DISALLOWED_OPTION_MARKDOWN_TYPES}
            unwrapDisallowed
          >
            {text}
          </Markdown>
        </GenericCopy>
      </span>
    )
  }

  ariaLabel = () => {
    const { isSelected, isCorrect, disabled } = this.props
    let ariaLabel = isSelected ? SELECTED : UNSELECTED

    ariaLabel += SPACE

    if (disabled) {
      ariaLabel += isCorrect ? CORRECT : INCORRECT
      ariaLabel += SPACE
    }

    ariaLabel += ARIA_LABEL_SUFFIX

    return ariaLabel
  }

  render = () => {
    const {
      disabled,
      isCorrect,
      isFocused,
      isLast,
      isSelected,
      isSubmitted,
      isUnevaluated,
    } = this.props
    const optionProps = {
      disabled,
      isCorrect,
      isFocused,
      isLast,
      isSelected,
      isSubmitted,
      isUnevaluated,
      'data-testid': DATA_TEST_ID,
    }
    const input = this.input()
    const label = this.label()
    const Option = this.option()

    return (
      <Option {...optionProps}>
        {input}
        {label}
      </Option>
    )
  }
}

MultiChoiceOption.propTypes = {
  disabled: PropTypes.bool,
  groupName: PropTypes.string,
  id: PropTypes.string.isRequired,
  isCorrect: PropTypes.bool,
  isFocused: PropTypes.bool,
  isLast: PropTypes.bool,
  isRadio: PropTypes.bool,
  isSelected: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  isUnevaluated: PropTypes.bool,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
}

MultiChoiceOption.defaultProps = {
  onBlur() {},
  onChange() {},
  onFocus() {},
}

export default MultiChoiceOption
