const EMPTY_OBJECT = {}
const EMPTY_STRING = ''

export const sortHotspotsLeftToRight = (a, b) =>
  a.position.x < b.position.x ? -1 : 1

const getHotspotsDictionaryFromHotspots = hotspotsArray => {
  if (!hotspotsArray) {
    return EMPTY_OBJECT
  }

  const hotspotsObject = hotspotsArray.reduce(function (
    accumulator,
    hotspotObject,
  ) {
    const hotspotId = createHotspotIdFromHotspot(hotspotObject)
    accumulator[hotspotId] = hotspotObject

    return accumulator
  },
  EMPTY_OBJECT)

  return hotspotsObject
}

export const getHotspotById = (hotspots, hotspotId) => {
  const hotspotsDictionary = getHotspotsDictionaryFromHotspots(hotspots) || {}
  const hotspot = hotspotsDictionary[hotspotId] || {}

  return hotspot
}

const createHotspotId = (header, x = 0, y = 0) => {
  const hotspotId = `${header},x${x},y${y}`

  return hotspotId
}

export const createHotspotIdFromHotspot = hotspot => {
  if (!hotspot) {
    return EMPTY_STRING
  }

  const { header, position } = hotspot || {}
  const { x = 0, y = 0 } = position || {}
  const hotspotId = createHotspotId(header, x, y)

  return hotspotId
}

// Given a hotspot JSON object, formats hotspot modal data into a Contentful-like data structure,
// so that it can be used by the Modal component
export const createContentfulLayoutForHotspot = hotspot => {
  const { content = [] } = hotspot || {}
  const firstContent = content[0] || {}
  const { metadata, params } = firstContent || {}
  const { text = '', alt = '' } = params || {}
  const { title = '' } = metadata || {}

  const layout = {
    fields: {
      internalTitle: text,
      header: title,
      column1: [
        {
          sys: {
            contentType: {
              sys: {
                type: 'Link',
                linkType: 'ContentType',
                id: 'text',
              },
            },
            locale: 'en-US',
          },
          fields: {
            content: text || alt,
          },
        },
      ],
    },
  }

  return layout
}
