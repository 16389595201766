import React from 'react'

import PropTypes from 'prop-types'

import checkSvg from '../../../../../assets/images/checkWhiteInCircleGreen.svg'

import CheckMarkIcon from './CheckMark'
import {
  ButtonTextWrapper,
  ButtonWrapper,
  CheckImage,
  CheckMarkWrapper,
  DoneButtonTextWrapper,
  DoneButtonWrapper,
} from './style'

const BUTTON_DATA_TESTID = 'mark-done-button'
const BUTTON_TEXT_DATA_TESTID = 'mark-done-text'
const ICON_DATA_TESTID = 'check-mark-icon'
const IMAGE_DATA_TEST_ID = 'check-mark-image'

const MarkDoneButtonComponent = ({
  isButtonActive,
  isLessonDone,
  isPrepMode,
  isPrevSlide,
  markDoneText,
  onClick,
}) => {
  return isPrevSlide && !isPrepMode && !isLessonDone ? (
    <ButtonWrapper
      data-testid={BUTTON_DATA_TESTID}
      isButtonActive={isButtonActive}
      onClick={onClick}
    >
      <CheckMarkWrapper isButtonActive={isButtonActive}>
        <CheckMarkIcon
          data-testid={ICON_DATA_TESTID}
          isButtonActive={isButtonActive}
        />
      </CheckMarkWrapper>
      <ButtonTextWrapper data-testid={BUTTON_TEXT_DATA_TESTID}>
        {markDoneText}
      </ButtonTextWrapper>
    </ButtonWrapper>
  ) : isPrevSlide && !isPrepMode && isLessonDone ? (
    <DoneButtonWrapper
      data-testid={BUTTON_DATA_TESTID}
      isButtonActive={isButtonActive}
      onClick={onClick}
    >
      <CheckImage
        data-testid={IMAGE_DATA_TEST_ID}
        src={checkSvg}
        isButtonActive={isButtonActive}
        alt={""}
      />
      <DoneButtonTextWrapper data-testid={BUTTON_TEXT_DATA_TESTID}>
        {markDoneText}
      </DoneButtonTextWrapper>
    </DoneButtonWrapper>
  ) : null
}

MarkDoneButtonComponent.propTypes = {
  isButtonActive: PropTypes.bool,
  isLessonDone: PropTypes.bool,
  isPrepMode: PropTypes.bool,
  isPrevSlide: PropTypes.bool,
  markDoneText: PropTypes.string,
  onClick: PropTypes.func,
}

MarkDoneButtonComponent.defaultProps = {
  markDoneText: '',
  onClick: () => {},
}

export default MarkDoneButtonComponent
