/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

export default () => (
  <svg
    height="19px"
    version="1.1"
    viewBox="0 0 23 19"
    width="23px"
    x="0px"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
  >
    <g transform="translate(-68.000000, -600.000000)">
      <g transform="translate(40.000000, 398.000000)">
        <g transform="translate(27.000000, 137.547350)">
          <g
            id="helping-others-icon"
            transform="translate(1.000000, 64.452650)"
          >
            <path
              className="st0"
              d="M13.9,8.6c0.2,0.2,0.4,0.2,0.6,0c0,0,0,0,0,0c1.2-1.3,2.5-2.6,3.7-4c1-1.1,1-2.7,0-3.8
						c-1-1.1-2.6-1.1-3.6,0l-0.4,0.4l-0.4-0.4C13.4,0.3,12.7,0,12,0c-0.7,0-1.3,0.3-1.8,0.8c-1,1.1-1,2.7,0,3.8L13.9,8.6z"
              fill="currentColor"
            />
            <g transform="translate(0.000000, 11.456643)">
              <defs>
                <filter
                  filterUnits="userSpaceOnUse"
                  height="6.7"
                  width="22.5"
                  x="0"
                  y="0.2"
                >
                  <feColorMatrix
                    type="matrix"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                  />
                </filter>
              </defs>
              <mask
                height="6.7"
                maskUnits="userSpaceOnUse"
                width="22.5"
                x="0"
                y="0.2"
              >
                <g className="st1">
                  <polygon
                    className="st2"
                    points="0,0.2 22.5,0.2 22.5,6.9 0,6.9 								"
                  />
                </g>
              </mask>
              <path
                className="st3"
                d="M22.4,1.9c-0.2-0.6-0.9-1-1.4-0.8l-5.6,2.2c-0.7,0.3-1.3,0.6-2,0.6h-0.1l-4-0.4
							C9,3.5,8.8,3.2,8.9,2.9c0-0.3,0.3-0.7,0.6-0.7l3.7,0.4c0,0,0.1,0,0.1,0c0.6,0,1.2-0.2,1.6-0.6c0.2-0.3,0.3-0.4,0.2-0.5
							c-0.1-0.3-0.3-0.4-1.5-0.6c-0.2,0-0.2,0-0.4-0.1c-0.5-0.1-0.9-0.2-1.6-0.2c-0.5-0.1-1.1-0.2-2-0.3C7.7,0,6.2,0.4,3.6,1.5
							C2.2,2,2.6,1.7,1.1,1.8c-0.3,0-0.5,0.1-0.7,0.3C0.1,2.5-0.2,3.6,0.2,4.8c0.8,2,2.2,1.3,2.2,1.3C3.5,6,4.7,5.4,6,5.5
							c0.8,0,1.7,0.4,2.7,0.7c1,0.3,2,0.7,2.8,0.7C13,7,14.9,6.3,15.1,6.2c0,0,0,0,0.1,0l6.7-3.1c0.3-0.1,0.6-0.4,0.6-0.7
							C22.5,2.3,22.5,2.1,22.4,1.9"
                fill="currentColor"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
