import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import TitleComponent from './component'

const EMPTY_STRING = ''

const TitleContainer = props => {
  const { title } = props

  return <TitleComponent title={title} />
}

TitleContainer.propTypes = {
  title: PropTypes.string,
}

TitleContainer.defaultProps = {
  title: EMPTY_STRING,
}

const mapStateToProps = ({ player }) => ({
  title: player.title,
})

export default connect(mapStateToProps)(TitleContainer)
