import PropTypes from 'prop-types'
import React from 'react'
import { Heading } from 'grommet'

const VideoTitle = ({ children, testid }) => {
  return (
    <Heading data-testid={testid} level={6}>
      {children}
    </Heading>
  )
}

VideoTitle.propTypes = {
  children: PropTypes.node.isRequired,
  testid: PropTypes.string.isRequired,
}

export default VideoTitle
