import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { Animated } from 'react-native-web'

import { Circle, DragDetector, HandleImage, HoverDetector } from './style'

const ACCESSIBILITY_LABEL = 'Image juxtaposition slider handle'
const INPUT_RANGE_START = 0
const INPUT_RANGE_END = 1
const NO_OP = () => {}
const OUTPUT_RANGE_START = 1
const OUTPUT_RANGE_END = 1.5
const START_RESPONDER = () => true
const SLIDER_HANDLE_ALT_TEXT = 'Slider handle'

class ImageJuxtHandleComponent extends Component {
  getRenderProps = () => {
    const {
      disabled,
      dragDetectorRef,
      isDragging,
      onDrag,
      onDragEnd,
      onDragStart,
      onKeyDown,
      onMouseEnter,
      onMouseLeave,
      position,
      size,
      svg,
    } = this.props
    const interpolatedSize = size.interpolate({
      inputRange: [INPUT_RANGE_START, INPUT_RANGE_END],
      outputRange: [OUTPUT_RANGE_START, OUTPUT_RANGE_END],
    })
    const transform = [
      {
        scaleX: interpolatedSize,
      },
      {
        scaleY: interpolatedSize,
      },
    ]
    const onResponderEndFunc = disabled ? NO_OP : onDragEnd
    const onResponderMoveFunc = disabled ? NO_OP : onDrag
    const onResponderStartFunc = disabled ? NO_OP : onDragStart
    const dragDetectorProps = {
      accessibilityLabel: ACCESSIBILITY_LABEL,
      accessible: true,
      onKeyDown,
      onResponderEnd: onResponderEndFunc,
      onResponderMove: onResponderMoveFunc,
      onResponderStart: onResponderStartFunc,
      onStartShouldSetResponder: START_RESPONDER,
      position,
      ref: dragDetectorRef,
    }
    const hoverDetectorProps = {
      as: Animated.View,
      onMouseEnter,
      onMouseLeave,
      style: { transform },
    }
    const circleProps = { isDragging }
    const handleProps = { alt: SLIDER_HANDLE_ALT_TEXT, source: svg }

    return { circleProps, dragDetectorProps, handleProps, hoverDetectorProps }
  }

  render = () => {
    const { circleProps, dragDetectorProps, handleProps, hoverDetectorProps } =
      this.getRenderProps()

    return (
      <DragDetector {...dragDetectorProps}>
        <HoverDetector {...hoverDetectorProps}>
          <Circle {...circleProps}>
            <HandleImage {...handleProps} />
          </Circle>
        </HoverDetector>
      </DragDetector>
    )
  }
}

ImageJuxtHandleComponent.propTypes = {
  disabled: PropTypes.bool,
  dragDetectorRef: PropTypes.func,
  isDragging: PropTypes.bool,
  onDrag: PropTypes.func,
  onDragEnd: PropTypes.func,
  onDragStart: PropTypes.func,
  onKeyDown: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  position: PropTypes.number,
  size: PropTypes.instanceOf(Animated.Value),
  svg: PropTypes.string,
}

ImageJuxtHandleComponent.defaultProps = {
  dragDetectorRef() {},
  onDrag() {},
  onDragEnd() {},
  onDragStart() {},
  onKeyDown() {},
  onMouseEnter() {},
  onMouseLeave() {},
  position: 0,
  size: Animated.Value(0),
}

export default ImageJuxtHandleComponent
