const getPath = dimension => <path d={dimension} id="Shape" />
const getPolygon = coords => <polygon id="Path" points={coords} />

const transforms = {
  A: 'translate(13.000000, 13.000000)',
  B: 'translate(14.000000, 13.000000)',
  C: 'translate(13.000000, 13.000000)',
  D: 'translate(14.000000, 13.000000)',
  E: 'translate(15.000000, 13.000000)',
  F: 'translate(15.000000, 13.000000)',
  G: 'translate(12.378000, 12.760000)',
  H: 'translate(13.000000, 13.000000)',
  I: 'translate(19.000000, 13.000000)',
  J: 'translate(16.000000, 13.000000)',
  K: 'translate(14.000000, 13.000000)',
  L: 'translate(15.000000, 13.000000)',
  M: 'translate(11.000000, 13.000000)',
  N: 'translate(13.000000, 13.000000)',
  O: 'translate(12.500000, 13.000000)',
  P: 'translate(14.000000, 13.000000)',
  Q: 'translate(12.000000, 13.000000)',
  R: 'translate(14.000000, 13.000000)',
  S: 'translate(15.000000, 13.000000)',
  T: 'translate(15.000000, 13.000000)',
  U: 'translate(13.000000, 13.000000)',
  V: 'translate(12.500000, 14.000000)',
  W: 'translate(8.500000, 14.000000)',
  X: 'translate(14.000000, 13.000000)',
  Y: 'translate(14.000000, 13.000000)',
  Z: 'translate(15.000000, 13.000000)',
}

export const letters = {
  A: getPath(
    'M0,18.98 L6.916,18.98 L6.916,16.276 L4.732,16.276 L5.876,13.078 L13.286,13.078 L14.43,16.276 L12.324,16.276 L12.324,18.98 L19.24,18.98 L19.24,16.276 L17.316,16.276 L12.324,2.704 L14.742,2.704 L14.742,0 L4.368,0 L4.368,2.704 L6.812,2.704 L1.82,16.276 L0,16.276 L0,18.98 Z M6.812,10.426 L9.568,2.704 L12.35,10.426 L6.812,10.426 Z',
  ),
  B: getPath(
    'M0,18.98 L10.192,18.98 C14.066,18.98 16.562,17.03 16.562,13.52 C16.562,11.44 15.6,9.932 13.858,9.152 C15.418,8.346 16.224,6.916 16.224,5.2 C16.224,1.95 13.832,0 9.984,0 L0,0 L0,2.704 L2.184,2.704 L2.184,16.276 L0,16.276 L0,18.98 Z M9.984,2.574 C12.22,2.574 13.39,3.562 13.39,5.2 C13.39,6.786 12.35,7.956 10.062,7.956 L4.966,7.956 L4.966,2.574 L9.984,2.574 Z M9.984,10.582 C12.61,10.582 13.728,11.674 13.728,13.468 C13.728,15.236 12.428,16.38 10.192,16.38 L4.966,16.38 L4.966,10.582 L9.984,10.582 Z',
  ),
  C: getPath(
    'M9.75,19.5 C13.624,19.5 16.77,17.342 18.408,14.17 L15.86,12.818 C14.768,15.106 12.636,16.666 9.724,16.666 C5.824,16.666 2.912,13.572 2.912,9.75 C2.912,5.876 5.85,2.782 9.62,2.782 C12.61,2.782 14.404,4.16 15.262,6.344 L17.914,6.344 L17.914,0.26 L15.184,0.26 L15.184,1.976 L15.34,2.964 C14.352,0.936 12.194,0 9.568,0 C4.368,0 0,4.212 0,9.75 C0,15.132 4.134,19.5 9.75,19.5 Z',
  ),
  D: getPath(
    'M0,18.98 L7.852,18.98 C13.65,18.98 17.732,14.846 17.732,9.36 C17.732,3.978 14.092,0 7.93,0 L0,0 L0,2.704 L2.184,2.704 L2.184,16.276 L0,16.276 L0,18.98 Z M7.644,2.678 C12.402,2.678 14.82,5.382 14.82,9.36 C14.82,13.338 12.168,16.302 7.722,16.302 L4.992,16.302 L4.992,2.678 L7.644,2.678 Z',
  ),
  E: getPolygon(
    '0 18.98 15.08 18.98 15.08 13.338 12.376 13.338 12.376 16.276 4.966 16.276 4.966 10.738 11.232 10.738 11.232 8.034 4.966 8.034 4.966 2.678 12.376 2.678 12.376 5.642 15.08 5.642 15.08 0 0 0 0 2.704 2.184 2.704 2.184 16.276 0 16.276',
  ),
  F: getPolygon(
    '0 18.98 7.332 18.98 7.332 16.276 4.966 16.276 4.966 11.518 11.024 11.518 11.024 8.814 4.966 8.814 4.966 2.678 11.778 2.678 11.778 5.824 14.482 5.824 14.482 0 0 0 0 2.704 2.184 2.704 2.184 16.276 0 16.276',
  ),
  G: getPath(
    'M9.464,19.474 C13.468,19.474 16.042,17.368 16.874,14.326 L16.536,16.484 L16.536,19.24 L19.318,19.24 L19.318,8.84 L9.23,8.84 L9.23,11.518 L16.484,11.518 C15.782,14.586 13.234,16.77 9.776,16.77 C5.694,16.77 2.86,13.624 2.86,9.75 C2.86,5.902 5.46,2.73 9.724,2.73 C12.896,2.73 14.976,4.186 15.99,6.5 L18.72,6.5 L18.72,0.26 L15.912,0.26 L15.912,2.158 L16.12,3.51 C15.21,1.352 12.818,0 9.594,0 C4.238,0 0,4.342 0,9.75 C0,15.132 4.056,19.474 9.464,19.474 Z',
  ),
  H: getPolygon(
    '0 18.98 7.202 18.98 7.202 16.276 4.966 16.276 4.966 10.738 13.624 10.738 13.624 16.276 11.388 16.276 11.388 18.98 18.59 18.98 18.59 16.276 16.406 16.276 16.406 2.704 18.59 2.704 18.59 0 11.388 0 11.388 2.704 13.624 2.704 13.624 8.086 4.966 8.086 4.966 2.704 7.202 2.704 7.202 0 0 0 0 2.704 2.184 2.704 2.184 16.276 0 16.276',
  ),
  I: getPolygon(
    '0 18.98 7.696 18.98 7.696 16.276 5.252 16.276 5.252 2.704 7.696 2.704 7.696 0 0 0 0 2.704 2.444 2.704 2.444 16.276 0 16.276',
  ),
  J: getPath(
    'M5.382,19.24 C8.606,19.24 10.556,17.134 10.556,13.572 L10.556,2.704 L12.74,2.704 L12.74,0 L5.33,0 L5.33,2.704 L7.748,2.704 L7.748,13.754 C7.748,15.47 6.89,16.614 5.33,16.614 C4.03,16.614 3.094,15.73 2.704,14.534 L0,15.704 C0.728,17.81 2.73,19.24 5.382,19.24 Z',
  ),
  K: getPolygon(
    '0 18.98 7.202 18.98 7.202 16.276 4.966 16.276 4.966 13.676 8.58 9.568 12.168 16.276 10.114 16.276 10.114 18.98 17.316 18.98 17.316 16.276 15.288 16.276 10.608 7.618 14.872 2.704 16.926 2.704 16.926 0 9.724 0 9.724 2.704 11.544 2.704 4.966 10.348 4.966 2.704 7.202 2.704 7.202 0 0 0 0 2.704 2.184 2.704 2.184 16.276 0 16.276',
  ),
  L: getPolygon(
    '0 18.98 14.508 18.98 14.508 12.532 11.804 12.532 11.804 16.276 5.018 16.276 5.018 2.704 7.202 2.704 7.202 0 0 0 0 2.704 2.184 2.704 2.184 16.276 0 16.276',
  ),
  M: getPolygon(
    '0 18.98 7.202 18.98 7.202 16.276 4.966 16.276 4.966 4.602 11.128 12.974 17.472 4.42 17.472 16.276 15.236 16.276 15.236 18.98 22.438 18.98 22.438 16.276 20.254 16.276 20.254 2.704 22.438 2.704 22.438 0 17.576 0 11.18 8.58 4.992 0 0 0 0 2.704 2.184 2.704 2.184 16.276 0 16.276',
  ),
  N: getPolygon(
    '0 18.98 7.202 18.98 7.202 16.276 4.94 16.276 4.94 4.264 14.456 18.98 17.29 18.98 17.29 2.704 19.474 2.704 19.474 0 12.298 0 12.298 2.704 14.56 2.704 14.56 14.378 5.278 0 0 0 0 2.704 2.184 2.704 2.184 16.276 0 16.276',
  ),
  O: getPath(
    'M9.724,19.5 C15.132,19.5 19.474,15.132 19.474,9.75 C19.474,4.368 15.132,0 9.724,0 C4.342,0 0,4.368 0,9.75 C0,15.132 4.342,19.5 9.724,19.5 Z M9.724,16.692 C5.902,16.692 2.912,13.546 2.912,9.75 C2.912,5.954 5.902,2.782 9.724,2.782 C13.572,2.782 16.562,5.954 16.562,9.75 C16.562,13.546 13.572,16.692 9.724,16.692 Z',
  ),
  P: getPath(
    'M0,18.98 L7.332,18.98 L7.332,16.276 L4.966,16.276 L4.966,12.454 L9.88,12.454 C14.066,12.454 16.744,9.542 16.744,6.032 C16.744,2.496 14.066,0 9.932,0 L0,0 L0,2.704 L2.184,2.704 L2.184,16.276 L0,16.276 L0,18.98 Z M9.698,2.678 C12.428,2.678 13.884,3.926 13.884,6.032 C13.884,8.112 12.428,9.802 9.698,9.802 L4.966,9.802 L4.966,2.678 L9.698,2.678 Z',
  ),
  Q: getPath(
    'M9.724,19.5 C11.908,19.5 13.988,18.72 15.652,17.42 L18.044,19.708 L19.89,17.81 L17.498,15.548 C18.746,13.91 19.474,11.908 19.474,9.75 C19.474,4.368 15.132,0 9.724,0 C4.342,0 0,4.368 0,9.75 C0,15.132 4.342,19.5 9.724,19.5 Z M9.724,16.692 C5.902,16.692 2.912,13.546 2.912,9.75 C2.912,5.954 5.902,2.782 9.724,2.782 C13.572,2.782 16.562,5.954 16.562,9.75 C16.562,11.154 16.146,12.48 15.444,13.572 L11.726,10.062 L9.88,11.96 L13.598,15.496 C12.506,16.25 11.18,16.692 9.724,16.692 Z',
  ),
  R: getPath(
    'M0,18.98 L7.202,18.98 L7.202,16.276 L4.966,16.276 L4.966,11.622 L9.438,11.622 L13.442,18.98 L17.42,18.98 L17.42,16.276 L15.132,16.276 L12.22,11.206 C14.82,10.504 16.666,8.528 16.666,5.824 C16.666,2.418 14.17,0 9.958,0 L0,0 L0,2.704 L2.184,2.704 L2.184,16.276 L0,16.276 L0,18.98 Z M9.646,2.626 C12.506,2.626 13.806,3.822 13.806,5.746 C13.806,7.67 12.48,9.074 9.594,9.074 L4.966,9.074 L4.966,2.626 L9.646,2.626 Z',
  ),
  S: getPath(
    'M8.736,19.5 C13,19.5 15.028,16.77 15.028,13.962 C15.028,9.828 11.83,9.178 7.982,8.268 C5.434,7.722 3.12,7.28 3.12,5.2 C3.12,3.692 4.056,2.574 6.318,2.574 C8.918,2.574 10.738,4.03 11.648,6.084 L14.014,6.084 L14.014,0.26 L11.414,0.26 L11.414,2.106 L11.518,2.704 C10.504,0.858 8.268,0 6.032,0 C2.418,0 0.208,2.392 0.208,5.2 C0.208,9.152 3.484,10.088 6.422,10.686 C9.282,11.336 12.116,11.674 12.116,13.962 C12.116,15.678 10.998,16.822 8.71,16.822 C5.876,16.822 3.666,14.924 2.522,12.376 L0,12.376 L0,19.24 L2.652,19.24 L2.652,16.848 L2.522,16.146 C3.64,18.2 6.214,19.5 8.736,19.5 Z',
  ),
  T: getPolygon(
    '4.108 18.98 11.804 18.98 11.804 16.276 9.36 16.276 9.36 2.6 13.208 2.6 13.208 5.408 15.912 5.408 15.912 0 0 0 0 5.408 2.704 5.408 2.704 2.6 6.552 2.6 6.552 16.276 4.108 16.276',
  ),
  U: getPath(
    'M9.412,19.24 C13.728,19.24 16.77,16.406 16.77,11.856 L16.77,2.704 L18.85,2.704 L18.85,0 L11.752,0 L11.752,2.704 L13.962,2.704 L13.962,11.96 C13.962,14.534 12.09,16.536 9.438,16.536 C6.786,16.536 4.888,14.534 4.888,11.96 L4.888,2.704 L7.098,2.704 L7.098,0 L0,0 L0,2.704 L2.08,2.704 L2.08,11.856 C2.08,16.406 5.096,19.24 9.412,19.24 Z',
  ),
  V: getPolygon(
    '8.294 18.98 11.154 18.98 17.29 2.704 19.396 2.704 19.396 0 12.22 0 12.22 2.704 14.3 2.704 9.698 15.236 5.044 2.704 7.202 2.704 7.202 0 0 0 0 2.704 2.054 2.704',
  ),
  W: getPolygon(
    '6.396 18.98 9.23 18.98 13.988 5.876 18.746 18.98 21.502 18.98 26.286 2.704 28.08 2.704 28.08 0 21.216 0 21.216 2.704 23.452 2.704 19.994 14.976 15.496 2.704 17.55 2.704 17.55 0 10.582 0 10.582 2.704 12.506 2.704 8.06 14.898 4.758 2.704 6.942 2.704 6.942 0 0 0 0 2.704 1.82 2.704',
  ),
  X: getPolygon(
    '0 18.98 7.072 18.98 7.072 16.276 5.122 16.276 8.58 11.31 12.038 16.276 10.166 16.276 10.166 18.98 17.238 18.98 17.238 16.276 15.288 16.276 10.244 9.178 14.872 2.704 16.978 2.704 16.978 0 9.906 0 9.906 2.704 11.752 2.704 8.606 7.228 5.486 2.704 7.306 2.704 7.306 0 0.234 0 0.234 2.704 2.262 2.704 6.89 9.256 1.898 16.276 0 16.276',
  ),
  Y: getPolygon(
    '4.706 18.98 12.402 18.98 12.402 16.276 9.958 16.276 9.958 11.31 15.236 2.704 17.134 2.704 17.134 0 10.114 0 10.114 2.704 12.168 2.704 8.58 8.658 5.018 2.704 7.098 2.704 7.098 0 0 0 0 2.704 1.898 2.704 7.15 11.258 7.15 16.276 4.706 16.276',
  ),
  Z: getPolygon(
    '0 18.98 14.43 18.98 14.43 12.532 11.726 12.532 11.726 16.276 3.354 16.276 14.17 2.574 14.17 0 0.39 0 0.39 6.292 3.068 6.292 3.068 2.626 10.868 2.626 0 16.38',
  ),
}

export const letterVariant = ({ letter, ...rest }) => ({
  ...rest,
  id: letter,
  transformCoords: transforms[letter.toUpperCase()],
  name: `ss_icon_adminpanel_${letter}`,
  letter: letters[letter.toUpperCase()],
})
