import React, { Component } from 'react'

import PropTypes from 'prop-types'

import { H1 } from './style'

const TITLE_TEXT_DATA_TEST_ID = 'slide-title'

class LayoutTitle extends Component {
  render = () => {
    const { title, isDoneSlide } = this.props

    return (
      <H1 data-testid={TITLE_TEXT_DATA_TEST_ID} isDoneSlide={isDoneSlide}>
        {title}
      </H1>
    )
  }
}

LayoutTitle.propTypes = {
  title: PropTypes.string,
  isDoneSlide: PropTypes.bool,
}

LayoutTitle.defaultProps = {
  title: '',
  isDoneSlide: false,
}

export default LayoutTitle
