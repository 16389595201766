import styled from 'styled-components'

export const ToolTip = styled.div.attrs(
  ({
    theme: {
      colorVars: { imageHotspots: colors },
    },
    isToolTipFlipped,
    isVisible,
  }) => ({
    background: colors.target.toolTip.background,
    border: colors.target.toolTip.border,
    display: isVisible ? 'block' : 'none',
    left: isToolTipFlipped ? 'auto' : '100%',
    right: isToolTipFlipped ? '100%' : 'auto',
  }),
)`
  background-color: ${props => props.background};
  border: ${props => '1px solid ' + props.border};
  border-radius: 4px;
  display: ${props => props.display};
  font: 1rem filson-pro;
  left: ${props => props.left};
  line-height: 1rem;
  padding: 10px;
  pointer-events: none;
  position: absolute;
  right: ${props => props.right};
  top: 100%;
  white-space: nowrap;
`
