import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { StyledTelescopeIcon } from '../CurriculumPreview/component.styles'
import {
  ADDITIONAL_DETAILS_ACTION_TEXT,
  ADDITIONAL_DETAILS_BOLD_TEXT,
} from '../CurriculumPreview/constants'
import {
  extractClassesLink,
  getClassesLinkForGrade,
} from '../CurriculumPreview/helpers'

import {
  AdditionalDetailsText,
  StyledBanner,
  TelescopeIconWrapper,
} from './component.styles'
import { DATA_TEST_ID, EMPHATIC_MESSAGE } from './constants'

const CurriculumPreviewBannerComponent = ({
  currentSectionIndex,
  currentSlideIndex,
  isCurriculumPreviewMode,
}) => {
  const createAdditionalDetails = () => {
    const actionTextParts = extractClassesLink(ADDITIONAL_DETAILS_ACTION_TEXT)
    return (
      <>
        <TelescopeIconWrapper>
          <StyledTelescopeIcon />
        </TelescopeIconWrapper>
        <AdditionalDetailsText>
          <strong>{ADDITIONAL_DETAILS_BOLD_TEXT}</strong> {actionTextParts[1]}
          <a href={getClassesLinkForGrade()}>{actionTextParts[2]}</a>
          {actionTextParts[3]}
        </AdditionalDetailsText>
      </>
    )
  }

  if (
    (currentSectionIndex > 0 || currentSlideIndex > 0) &&
    isCurriculumPreviewMode
  ) {
    return (
      <StyledBanner
        data-testid={DATA_TEST_ID}
        message={EMPHATIC_MESSAGE}
        additionalDetails={createAdditionalDetails()}
      />
    )
  }

  return null
}

CurriculumPreviewBannerComponent.propTypes = {
  currentSectionIndex: PropTypes.number,
  currentSlideIndex: PropTypes.number,
  isCurriculumPreviewMode: PropTypes.bool,
}

CurriculumPreviewBannerComponent.defaultProps = {
  currentSectionIndex: 0,
  currentSlideIndex: 0,
  isCurriculumPreviewMode: false,
}

const mapStateToProps = ({
  player: { currentSlide, isCurriculumPreviewMode },
}) => ({
  currentSectionIndex: currentSlide[0],
  currentSlideIndex: currentSlide[1],
  isCurriculumPreviewMode,
})

export default connect(mapStateToProps, {})(CurriculumPreviewBannerComponent)
