import styled from 'styled-components'
import { themeGet } from 'styled-system'
import HeaderTwo from '../HeaderTwo'

const ModalHeader = styled(HeaderTwo)`
  font-size: 1.625rem;
  line-height: 2rem;
  ${themeGet('breakpoints.tablet')} {
    padding: 0;
  }
`

ModalHeader.displayName = 'ModalHeader'

export default ModalHeader
