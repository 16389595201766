import { Animated } from 'react-native-web'
import styled from 'styled-components'
import styledNative from 'styled-components/native'

import { HOTSPOT_DIAMETER } from '../constants'

export const HotspotsTargetWrapper = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  display: flex;
  height: 28px;
  justify-content: center;
  left: ${props => props.left + 'px'};
  outline: none;
  position: absolute;
  top: ${props => props.top + 'px'};
  width: 28px;
`

export const HotspotsTarget = styledNative(Animated.View).attrs(
  ({
    theme: {
      colorVars: { imageHotspots: colors },
    },
  }) => ({
    active: colors.target.active,
    borderColor: colors.target.border,
    default: colors.target.default,
    height: HOTSPOT_DIAMETER,
    hover: colors.target.hover,
    selected: colors.target.selected,
    toolTipBackground: colors.target.toolTip.background,
    toolTipBorder: colors.target.toolTip.border,
    width: HOTSPOT_DIAMETER,
  }),
)`
  align-items: center;
  background-color: ${props =>
    props.isHovered
      ? props.hover.background
      : props.isTargetClicked
      ? props.selected.background
      : props.default.background};
  border-radius: 50%;
  border: 2px solid
    ${props =>
      props.isHovered
        ? props.hover.outerBorder
        : props.isTargetClicked
        ? props.selected.innerBorder
        : props.default.innerBorder};
  cursor: pointer;
  display: flex;
  height: ${props => (props.isTargetClicked ? '28px' : '30px')};
  justify-content: center;
  width: ${props => (props.isTargetClicked ? '28px' : '30px')};
`

export const HotspotsTargetOutline = styledNative.View`
  background-color: none;
  border: 2px solid #000;
  border-radius: 50%;
  display: ${props =>
    props.isTargetClicked && !props.isHovered ? 'block' : 'none'};
  height: 32px;
  width: 32px;
`
