export const httpErrorMessage = statusCode => {
  const httpMapper = {
    400: {
      headerText: 'Sorry! An error has occurred',
      messageText: 'Please check the URL and try again.',
      statusCode,
      statusMessage: 'Bad Request',
      additionalMessaging:
        'Please check the URL and try again. If you still receive the same error, please contact us.',
    },
    401: {
      headerText: 'Sorry, you are not authorized to view this page',
      messageText: 'Please login now or register to view content.',
      statusCode,
      statusMessage: 'Unauthorized',
      additionalMessaging:
        'Please login now or register to view content. If you are logged in and still receive the same error, please contact us.',
    },
    403: {
      headerText: 'Sorry! An error has occurred',
      messageText: 'Please check the URL and try again.',
      statusCode,
      statusMessage: 'Forbidden',
      additionalMessaging:
        'Please login now or register to view content. If you are logged in and still receive the same error, please contact us.',
    },
    404: {
      headerText: 'Page not found. Sorry, this page is not available.',
      messageText: 'Please try using the site navigation.',
      statusCode,
      statusMessage: 'Not Found',
      additionalMessaging: 'Sorry this page is not available.',
    },
    408: {
      headerText: 'Problem connecting.',
      messageText: 'Refresh this page or try again in a few minutes.',
      statusCode,
      statusMessage: 'Timeout',
      additionalMessaging:
        'Please check your internet connection and try again. If you still receive the same error, please contact us.',
    },
    500: {
      headerText: "Sorry! There's been an error.",
      messageText: 'Please try again.',
      statusCode,
      statusMessage: 'Server Error',
      additionalMessaging: 'Please try again in a few minutes.',
    },
    413: {
      headerText: "Sorry! There's been an error.",
      messageText: 'If you still receive the same error, please contact us.',
      statusCode,
      statusMessage: 'Payload Too Large',
      additionalMessaging:
        'If you still receive the same error, please contact us.',
    },
    default: {
      headerText: "Sorry! There's been an error.",
      messageText: 'Please try again.',
      statusCode: 500,
      statusMessage: 'Server Error',
      additionalMessaging: 'Please try again in a few minutes.',
    },
  }
  const errorMessage = httpMapper[`${statusCode}`] || httpMapper.default
  return errorMessage
}

export const serviceNameEnums = {
  profileError: 1,
  licenseError: 2,
  lmsError: 3,
  contentfulError: 4,
  reportingError: 5,
  accessError: 6,
  contentServiceError: 7,
  routeServiceError: 8,
  courseManagerError: 9,
}
