export const CONTAINER_WIDTH = 467
export const DEFAULT_MESSAGE = "You've completed a lesson"
export const FEEDBACK_LINK_DATA_TEST_ID = 'lesson-complete-feedback-link'
export const FEEDBACK_LINK_TEXT = 'Provide feedback about this lesson'
export const IMAGE_DATA_TEST_ID = 'lesson-complete-image'
const MESSAGE_PREFIX = "You've completed "
const MESSAGE_SUFFIX = ' lesson'
export const NEW_TAB = '_blank'
export const TEXT_DATA_TEST_ID = 'lesson-complete-text'
const THE = 'the '
export const TITLE = 'Nice Work!'
export const MESSAGE = lessonName =>
  `${MESSAGE_PREFIX}${THE}${lessonName}${MESSAGE_SUFFIX}`
