import React from 'react'

import PropTypes from 'prop-types'
import { getConstantByLocale, getLocaleFromSession } from 'utils/locale'

import { DATA_TESTID, EXIT_LESSON_TEXT } from './constants'
import { ExitLessonButton } from './style'

const ExitLessonComponent = ({ onClick }) => {
  const currentLocale = getLocaleFromSession()
  const getExitLessonText = getConstantByLocale(EXIT_LESSON_TEXT, currentLocale)

  return (
    <ExitLessonButton data-testid={DATA_TESTID} onClick={onClick}>
      {getExitLessonText}
    </ExitLessonButton>
  )
}

ExitLessonComponent.propTypes = {
  onClick: PropTypes.func,
}

ExitLessonComponent.defaultProps = {
  onClick() {},
}

export default ExitLessonComponent
