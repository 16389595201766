import React from 'react'
import PropTypes from 'prop-types'

const DEFAULT_COLOR = 'currentColor'
const DEFAULT_WIDTH = 22
const DEFAULT_HEIGHT = 17
const DEFAULT_VIEWBOX_WIDTH = 24

const ClassMeetingIcon = ({ color, height, width, viewBoxWidth }) => {
  return (
    <svg
      height={height}
      version="1.1"
      viewBox={`0 0 ${viewBoxWidth} ${height}`}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>ss_classmeetings</title>
      <g
        fill="none"
        fillRule="evenodd"
        id="ss_classmeetings"
        stroke="none"
        strokeWidth="1"
      >
        <g id="class-meetings-icon" transform="translate(1.000000, 1.000000)">
          <path
            d="M8.97959184,8.91923198 L8.97959184,12.0186651 C8.97959184,12.5096688 8.66665306,12.6559442 8.28412245,12.3491226 C6.67615477,11.0570483 5.47017902,10.0879926 4.66619518,9.44195542 C4.5216348,9.32579466 4.30479423,9.15155351 4.01567347,8.91923198 L1.79457143,8.91923198 C0.803673469,8.91923198 0,8.12006879 0,7.13583155 L0,1.7838464 C0,0.798717224 0.805918367,0 1.79457143,0 L10.7773061,0 C11.7682041,0 12.5714286,0.799163185 12.5714286,1.7838464 L12.5714286,7.13583155 C12.5714286,8.12051476 11.7659592,8.91923198 10.7773061,8.91923198 L8.97959184,8.91923198 Z"
            id="Path"
            stroke={color}
            strokeWidth="1.32"
            transform="translate(6.285714, 6.254717) scale(-1, 1) translate(-6.285714, -6.254717) "
          />
          <g
            fill={color}
            id="Group-8"
            stroke="#FFFFFF"
            strokeWidth="1.50857143"
            transform="translate(9.428571, 4.490566)"
          >
            <path
              d="M10.7773061,-0.754285714 C11.4804653,-0.754285714 12.1168762,-0.470487701 12.5776473,-0.0123557478 C13.0401108,0.447458935 13.3257143,1.08274042 13.3257143,1.7838464 L13.3257143,1.7838464 L13.3257143,7.13583155 C13.3257143,7.83660325 13.0398104,8.47157294 12.5773521,8.93126749 C12.1161061,9.38975696 11.4792591,9.67351769 10.7773061,9.67351769 L10.7773061,9.67351769 L9.73387755,9.67351769 L9.73387755,13.3788822 L3.79952554,9.67351769 L1.79457143,9.67351769 C1.09149997,9.67351769 0.4550002,9.38976822 -0.00587187703,8.93176501 C-0.468563763,8.47195333 -0.754285714,7.83670709 -0.754285714,7.13583155 L-0.754285714,7.13583155 L-0.754285714,1.7838464 C-0.754285714,1.08288279 -0.468304691,0.447861123 -0.00580950849,-0.0118700141 C0.455572287,-0.470494419 1.09259696,-0.754285714 1.79457143,-0.754285714 L1.79457143,-0.754285714 Z"
              id="Fill-4"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

ClassMeetingIcon.defaultProps = {
  color: DEFAULT_COLOR,
  height: DEFAULT_HEIGHT,
  viewBoxWidth: DEFAULT_VIEWBOX_WIDTH,
  width: DEFAULT_WIDTH,
}

ClassMeetingIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  viewBoxWidth: PropTypes.string,
  width: PropTypes.string,
}

export default ClassMeetingIcon
