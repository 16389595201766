import styled from 'styled-components/native'

export const Container = styled.View.attrs(
  ({
    backgroundColor,
    fontColor,
    theme: {
      colorVars: { slide: colors },
    },
  }) => ({
    backgroundColor: backgroundColor || colors.backgroundColor,
    fontColor,
  }),
)`
  background-color: ${props => props.backgroundColor};
  color: ${props => props.fontColor};
  flex: 1;
  height: 100%;
  overflow: hidden;
  width: 100%;
`
