/* istanbul ignore file */
// visual only
import styled, { keyframes } from 'styled-components'
import { themeGet } from 'styled-system'
import { colors } from '../../theme/defaultValues'

const jumpHeightREM = 1.87

const bounce = keyframes`
    0% {
      top: ${jumpHeightREM}rem;
      height: 0.31rem;
      border-radius: 60px 60px 20px 20px;
      -webkit-transform: scaleX(2);
      transform: scaleX(2);
    }
    35% {
      height: 1rem;
      border-radius: 50%;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
    100% {
      top: 0;
    }
`

export const LoadingIndicator = styled.div`
  position: relative;
  display: inline-block;
  width: 1rem;
  height: ${jumpHeightREM}rem;

  &:before {
    position: absolute;
    top: 0;
    content: '';
    display: block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: ${themeGet(
      'colors.secondStepOrange',
      colors.secondStepOrange,
    )};
    transform-origin: 50%;
    animation: ${bounce} 500ms alternate infinite ease;
  }
`
