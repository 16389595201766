/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

const CloseIcon = () => (
  <svg
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="none"
      strokeLinecap="round"
      strokeWidth="1"
    >
      <g stroke="currentColor" strokeWidth="3" transform="translate(-331 -259)">
        <g transform="translate(16 247)">
          <g>
            <g transform="translate(315 11.997)">
              <path d="M2.244 2.244L13.911 13.911" />
              <path d="M2.244 13.911L13.911 2.244" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default CloseIcon
