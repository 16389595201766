import React from 'react'

import PropTypes from 'prop-types'
import { getConstantByLocale, getLocaleFromSession } from 'utils/locale'

import { DRAG_AND_DROP_ERRORS } from '../../../utils/dragAndDropHelper'

import { CHECK, RESET } from './constants'
import {
  EvaluationCheckButton,
  EvaluationContainer,
  EvaluationResetButton,
  EvaluationText,
} from './style'

const DATA_TEST_ID_CHECK = 'check-button'
const DATA_TEST_ID_RESET = 'reset-button'
const DATA_TEST_ID_HELPER_TEXT = 'helper-text'
const currentLocale = getLocaleFromSession()
const resetButtonText = getConstantByLocale(RESET, currentLocale)
const checkButtonText = getConstantByLocale(CHECK, currentLocale)

const DragWordEvaluation = ({ buttons, isErrorVisible, onCheck, onReset }) => {
  const { resetDisabled, checkDisabled } = buttons || {}

  return (
    <EvaluationContainer>
      <EvaluationText
        data-testid={DATA_TEST_ID_HELPER_TEXT}
        isErrorVisible={isErrorVisible}
      >
        {DRAG_AND_DROP_ERRORS.DRAG_WORD}
      </EvaluationText>
      <EvaluationResetButton
        data-testid={DATA_TEST_ID_RESET}
        disabled={resetDisabled}
        onClick={onReset}
      >
        {resetButtonText}
      </EvaluationResetButton>
      <EvaluationCheckButton
        data-testid={DATA_TEST_ID_CHECK}
        disabled={checkDisabled}
        onClick={onCheck}
      >
        {checkButtonText}
      </EvaluationCheckButton>
    </EvaluationContainer>
  )
}

DragWordEvaluation.propTypes = {
  buttons: PropTypes.object,
  isErrorVisible: PropTypes.bool,
  onCheck: PropTypes.func,
  onReset: PropTypes.func,
}

DragWordEvaluation.defaultProps = {
  buttons: {},
  isErrorVisible: false,
  onReset() {},
  onCheck() {},
}

export default DragWordEvaluation
