const ELEMENT_KEY_PREFIX = 'Page layout element'
const MODAL_ELEMENT_KEY_PREFIX = 'Modal layout element'
const NUMBER = 'number'

//Given an element index, get a key for an element component
export const getElementKey = (index, isModal) => {
  const indexIsNan = typeof index !== NUMBER
  const indexLessThanZero = index < 0
  let key

  if (indexIsNan || indexLessThanZero) {
    console.warn(
      'Invalid index argument for getElementKey. Returning non-unique element component key...',
    )
    key = ELEMENT_KEY_PREFIX
  } else {
    const elementNumber = index + 1
    key = isModal
      ? `${MODAL_ELEMENT_KEY_PREFIX} ${elementNumber}`
      : `${ELEMENT_KEY_PREFIX} ${elementNumber}`
  }

  return key
}
