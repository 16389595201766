import styled, { css } from 'styled-components'

import {
  BUTTON_SIZE,
  DURATION_TRANSITION_ANIMATION,
  ICON_SIZE,
  TRANSITIONS,
} from './constants'

export const Button = styled.div.attrs(
  ({
    isFullScreen,
    theme: {
      colorVars: { blueFocus: focusColor, fullScreenButton: colors },
    },
    transitionState,
  }) => {
    let backgroundColorHover
    let svgFill
    let svgFillHover

    //Dynamically styling by transition state is necessary to fix browser-specific full screen transition issues
    if (transitionState === TRANSITIONS.FULL_SCREEN) {
      backgroundColorHover =
        colors.transitioningToFullScreen.backgroundColorHover
      svgFill = colors.transitioningToFullScreen.fill
      svgFillHover = colors.transitioningToFullScreen.fillHover
    } else if (transitionState === TRANSITIONS.WINDOWED) {
      backgroundColorHover = colors.transitioningToWindowed.backgroundColorHover
      svgFill = colors.transitioningToWindowed.fill
      svgFillHover = colors.transitioningToWindowed.fillHover
    } else {
      backgroundColorHover = isFullScreen
        ? colors.maximized.backgroundColorHover
        : colors.windowed.backgroundColorHover
      svgFill = isFullScreen ? colors.maximized.fill : colors.windowed.fill
      svgFillHover = isFullScreen
        ? colors.maximized.fillHover
        : colors.windowed.fillHover
    }

    return {
      backgroundColor: isFullScreen
        ? colors.maximized.backgroundColor
        : colors.windowed.backgroundColor,
      backgroundColorHover,
      border: isFullScreen ? '2px solid' : 'none',
      borderColor: isFullScreen
        ? colors.maximized.borderColor
        : colors.windowed.borderColor,
      focusColor,
      fill: isFullScreen ? colors.maximized.fill : colors.windowed.fill,
      fillHover: isFullScreen
        ? colors.maximized.fillHover
        : colors.windowed.fillHover,
      height: BUTTON_SIZE,
      svgFill,
      svgFillHover,
      width: BUTTON_SIZE,
    }
  },
)`
  align-items: center;
  background-color: ${props => props.backgroundColor};

  //Order matters here. Do not change the order of the next 2 lines
  border: ${props => props.border};
  border-color: ${props => props.borderColor};

  border-radius: 0.1875rem;
  cursor: pointer;
  display: flex;
  height: ${props => props.height};
  justify-content: center;
  transition: ${`background-color ${DURATION_TRANSITION_ANIMATION} ease-in`};
  width: ${props => props.width};

  svg {
    fill: ${props => props.svgFill};
  }

  ${props =>
    props.transitionState === TRANSITIONS.NONE &&
    css`
      :hover {
        svg {
          fill: ${props => props.svgFillHover};
        }
      }
    `}

  ${props =>
    props.transitionState === TRANSITIONS.NONE &&
    css`
      :hover {
        background-color: ${props => props.backgroundColorHover};
      }
    `}

  :focus {
    outline: 3px groove ${props => props.focusColor};
  }
`

export const Svg = styled.svg.attrs(() => ({
  height: ICON_SIZE,
  width: ICON_SIZE,
}))`
  height: ${props => props.height};
  //Since the svg has almost 1 px of right padding as is, skip adding margin-right
  margin-bottom: 1px;
  margin-left: 1px;
  margin-top: 1px;
  transition: ${`all ${DURATION_TRANSITION_ANIMATION} ease-in-out}`};
  width: ${props => props.width};
`

export const Tooltip = styled.span.attrs(
  ({
    visible,
    theme: {
      colorVars: { fullScreenButton: colors },
    },
  }) => ({
    backgroundColor: colors.hover.background,
    color: colors.hover.color,
    visibility: visible ? 'visible' : 'hidden',
  }),
)`
  visibility: ${props => props.visibility};
  top: 6%;
  background-color: ${props => props.backgroundColor};
  color: ${props => props.color};
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
`
