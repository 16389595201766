import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const AutoTableHeaderTr = styled.tr`
  border-bottom: 1px solid ${themeGet('colors.gray400')};
  white-space: nowrap;
  background-color: ${themeGet('colors.grayBlue')};
`

export const AutoTableHeaderTh = styled.th.attrs({
  role: 'columnheader',
})`
  &:first-child {
    border-radius: 10px 0 0 0;
  }
  &:last-child {
    border-radius: 0 10px 0 0;
  }

  cursor: ${props =>
    props.sort && props.column.sort !== false ? 'pointer' : 'default'};
  font-family: ${themeGet('fontFamily')};
  font-weight: bold;
  padding: ${props =>
    props.sort && props.column.sort !== false
      ? 0`${themeGet('spacing.turquoise20_1_25rem')}`
      : 0};
  background-color: ${themeGet('colors.gray100')};
  color: ${themeGet('colors.darkBlue')};
  line-height: 22px;
`

export const AutoTableHeaderSpan = styled.span`
  margin-right: ${themeGet('spacing.yellow12_0_75rem')};
  color: ${themeGet('colors.darkBlue')};
`

export const AutoTableHeaderSort = styled.div`
  width: 100%;
  text-align: left;
  padding: 1rem 0;
`

export const AutoTableHeaderContent = styled.div.attrs(
  ({ active, isSortable }) => ({
    active,
    position: isSortable ? 'absolute' : 'static',
  }),
)`
  display: flex;
  position: relative;

  button {
    z-index: 3;
  }

  font-family: ${props =>
    props.active ? 'Molde-medium' : `${themeGet('fontFamily')}`};
  font-weight: normal;

  [class*='FlexContainer'] {
    position: ${props => props.position};
    top: 16px;
    left: 18px;
    width: 100%;
  }
`

export const HeaderSortContainer = styled.div.attrs(({ active }) => ({
  active,
}))`
  display: flex;
  position: relative;
  width: 100%;
  z-index: 2;
`
