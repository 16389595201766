import React from 'react'

import { DATA_TEST_ID_EXIT_FULL_SCREEN_ICON } from './constants'
import { Svg } from './style'

export const exitFullScreenSvg = () => {
  return (
    <Svg data-testid={DATA_TEST_ID_EXIT_FULL_SCREEN_ICON} viewBox="0 0 19 18">
      <g transform="translate(-40.000000, -143.000000)">
        <g transform="translate(40.000000, 143.000000)">
          <path
            d="M18.2801652,17.5422352 L18.2767117,11.4099419 C18.2772873,11.2482023 18.0821636,11.1670446 17.967622,11.2815863 L11.8399335,17.4161819 C11.7253918,17.5307235 11.8065495,17.7258471 11.9682891,17.7252728 L18.0994312,17.7229692 C18.1990076,17.7223936 18.2801652,17.6418116 18.2801652,17.5422352"
            transform="translate(15.033240, 14.476620) rotate(-180.000000) translate(-15.033240, -14.476620) "
          />
          <path
            d="M13.5897119,14.5383652 L15.0977472,13.0303299 C15.12998,12.9980971 15.182934,12.9980971 15.2151668,13.0303299 L18.4326925,16.2478557 C18.4649253,16.2800885 18.4649253,16.3330424 18.4326925,16.3652752 L16.9246572,17.8733105 C16.8924244,17.9061189 16.8394705,17.9061189 16.8066621,17.8733105 L13.5897119,14.6563604 C13.5569035,14.623552 13.5569035,14.5705981 13.5897119,14.5383652"
            transform="translate(16.010986, 15.452036) rotate(-180.000000) translate(-16.010986, -15.452036) "
          />
          <path
            d="M18.2801652,0.356140837 L18.2767117,6.48843408 C18.2767117,6.65017375 18.081588,6.73133137 17.967622,6.61678976 L11.8399335,0.482194171 C11.7253918,0.368228143 11.8065495,0.173104489 11.9682891,0.173104489 L18.1000068,0.175406833 C18.1995832,0.175406833 18.2801652,0.256564459 18.2801652,0.356140837"
            transform="translate(15.033240, 3.421757) rotate(-180.000000) translate(-15.033240, -3.421757) "
          />
          <path
            d="M13.5897119,3.35955159 L15.0977472,4.86758691 C15.12998,4.89981972 15.182934,4.89981972 15.2151668,4.86758691 L18.4326925,1.65006117 C18.4649253,1.61782835 18.4649253,1.56487444 18.4326925,1.53264162 L16.9246572,0.0246063015 C16.8924244,-0.0082021005 16.8394705,-0.0082021005 16.8066621,0.0246063015 L13.5897119,3.24155646 C13.5569035,3.27436486 13.5569035,3.32674318 13.5897119,3.35955159"
            transform="translate(16.010986, 2.445881) rotate(-180.000000) translate(-16.010986, -2.445881) "
          />
          <g transform="translate(3.500000, 14.491402) rotate(-180.000000) translate(-3.500000, -14.491402) translate(-0.000000, 10.991402)">
            <path d="M0.0004029102,6.72382298 L0.0038564262,0.590954146 C0.0038564262,0.42921448 0.19898008,0.34863244 0.312946108,0.463174054 L6.44063466,6.59776964 C6.55460069,6.71173567 6.47401865,6.90743491 6.31227899,6.90743491 L0.180561328,6.90455698 C0.0809849502,6.90455698 0.0004029102,6.82339935 0.0004029102,6.72382298"></path>
          </g>
          <path
            d="M5.19619981,14.3951115 L3.68816449,12.8870762 C3.65593168,12.8548433 3.60297777,12.8548433 3.57074495,12.8870762 L0.35321921,16.1046019 C0.320986394,16.1368347 0.320986394,16.1897886 0.35321921,16.2220214 L1.86125453,17.7300568 C1.89406293,17.7628652 1.94644126,17.7628652 1.97924966,17.7300568 L5.19619981,14.5131066 C5.22900822,14.4802982 5.22900822,14.4279199 5.19619981,14.3951115"
            transform="translate(2.774925, 15.308782) rotate(-180.000000) translate(-2.774925, -15.308782) "
          />
          <path
            d="M0.176817019,0.356140837 L0.180270535,6.48843408 C0.180270535,6.65017375 0.375394189,6.73133137 0.489360217,6.61678976 L6.61704877,0.482194171 C6.73159039,0.368228143 6.65043276,0.173104489 6.4886931,0.173104489 L0.356975437,0.175406833 C0.257399059,0.175406833 0.176817019,0.256564459 0.176817019,0.356140837"
            transform="translate(3.423742, 3.421757) rotate(-180.000000) translate(-3.423742, -3.421757) "
          />
          <path
            d="M4.86727033,3.35955159 L3.35923501,4.86758691 C3.3270022,4.89981972 3.27404829,4.89981972 3.24181547,4.86758691 L0.0242897292,1.65006117 C-0.0079430868,1.61782835 -0.0079430868,1.56487444 0.0242897292,1.53264162 L1.53232505,0.0246063015 C1.56455787,-0.0082021005 1.61751178,-0.0082021005 1.65032018,0.0246063015 L4.86727033,3.24155646 C4.90007874,3.27436486 4.90007874,3.32674318 4.86727033,3.35955159"
            transform="translate(2.445996, 2.445881) rotate(-180.000000) translate(-2.445996, -2.445881) "
          />
        </g>
      </g>
    </Svg>
  )
}
