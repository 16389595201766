/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

export default () => (
  <svg
    height="22px"
    version="1.1"
    viewBox="0 0 22 22"
    width="22px"
    x="0px"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
  >
    <g transform="translate(-291.000000, -624.000000)">
      <g transform="translate(40.000000, 398.000000)">
        <g transform="translate(27.000000, 137.547350)">
          <g
            id="perspective-taking-icon"
            transform="translate(224.000000, 88.452650)"
          >
            <path
              className="st0"
              d="M10.6,14c-1.6,0-2.9-1.3-2.9-2.9c0-1.6,1.3-2.9,2.9-2.9s2.9,1.3,2.9,2.9
						C13.5,12.7,12.2,14,10.6,14 M18.8,12.8c0.9-0.9,0.9-2.4,0-3.3c0,0,0,0,0,0c-1.2-1.2-2.5-2.2-4-3c-1.1-0.6-2.3-1-3.6-1.2
						c-0.1,0-0.1,0-0.2,0l0,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0l0,0c-0.1,0-0.1,0-0.2,0
						C8.7,5.5,7.5,5.9,6.4,6.5c-1.5,0.8-2.8,1.9-4,3c0,0,0,0,0,0c-0.9,0.9-0.9,2.4,0,3.3c0.3,0.3,0.6,0.6,0.9,0.9
						c1.2,1.1,2.5,2,3.9,2.6c0.8,0.3,1.5,0.6,2.3,0.7c0,0,0.1,0,0.1,0c0.2,0,0.4,0,0.6,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0
						c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0.2,0,0.4,0,0.6,0c0,0,0.1,0,0.1,0c0.8-0.1,1.6-0.3,2.3-0.7c1.5-0.6,2.8-1.6,3.9-2.6
						C18.2,13.4,18.5,13.1,18.8,12.8"
              fill="currentColor"
            />
            <path
              className="st0"
              d="M0.4,5.3l1.8-1.8c0,0,0,0,0.1,0C2.9,4.2,3.3,4.6,4,5.3c0,0,0,0,0.1,0C4.4,5,4.7,4.7,5,4.4
						c0,0,0,0,0-0.1C4.3,3.6,3.9,3.2,3.2,2.5c0,0,0,0,0-0.1l1.8-1.8c0,0,0-0.1,0-0.1L0.1,0.4c0,0-0.1,0-0.1,0.1l0.2,4.8
						C0.2,5.3,0.3,5.3,0.4,5.3"
              fill="currentColor"
            />
            <g transform="translate(16.100000, 0.269325)">
              <defs>
                <filter
                  filterUnits="userSpaceOnUse"
                  height="5"
                  width="5"
                  x="0.2"
                  y="0.1"
                >
                  <feColorMatrix
                    type="matrix"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                  />
                </filter>
              </defs>
              <mask
                height="5"
                maskUnits="userSpaceOnUse"
                width="5"
                x="0.2"
                y="0.1"
              >
                <g className="st1">
                  <polygon
                    className="st2"
                    points="0.2,0.1 5.1,0.1 5.1,5.1 0.2,5.1 								"
                  />
                </g>
              </mask>
              <path
                className="st3"
                d="M1.1,5C1.1,5.1,1.1,5.1,1.1,5C1.9,4.3,2.2,4,3,3.3c0,0,0,0,0.1,0L4.8,5c0,0,0.1,0,0.1,0
							l0.2-4.8c0,0,0-0.1-0.1-0.1L0.2,0.3c-0.1,0-0.1,0.1,0,0.1L2,2.2c0,0,0,0,0,0.1C1.3,3,0.9,3.4,0.2,4.1c0,0,0,0,0,0.1
							C0.5,4.4,0.8,4.7,1.1,5"
                fill="currentColor"
              />
            </g>
            <path
              className="st0"
              d="M20.9,17l-1.8,1.8c0,0,0,0-0.1,0c-0.7-0.7-1.1-1.1-1.8-1.8c0,0,0,0-0.1,0
						c-0.3,0.3-0.6,0.6-0.9,0.9c0,0,0,0,0,0.1c0.7,0.7,1.1,1.1,1.8,1.8c0,0,0,0,0,0.1l-1.8,1.8c0,0,0,0.1,0,0.1l4.8,0.2
						c0,0,0.1,0,0.1-0.1L21,17.1C21,17,20.9,17,20.9,17"
              fill="currentColor"
            />
            <g transform="translate(0.000000, 16.719325)">
              <defs>
                <filter
                  filterUnits="userSpaceOnUse"
                  height="5"
                  width="5"
                  x="0"
                  y="0.3"
                >
                  <feColorMatrix
                    type="matrix"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                  />
                </filter>
              </defs>
              <mask
                height="5"
                maskUnits="userSpaceOnUse"
                width="5"
                x="0"
                y="0.3"
              >
                <g className="st4">
                  <polygon className="st2" points="0,0.3 5,0.3 5,5.2 0,5.2 								" />
                </g>
              </mask>
              <path
                className="st5"
                d="M4.1,0.3C4,0.2,4,0.3,4.1,0.3C3.3,1,2.9,1.4,2.2,2.1c0,0,0,0-0.1,0L0.4,0.3c0,0-0.1,0-0.1,0
							L0,5.2c0,0,0,0.1,0.1,0.1L4.9,5C5,5,5,4.9,4.9,4.9L3.2,3.1c0,0,0,0,0-0.1C3.9,2.4,4.3,2,5,1.3c0,0,0,0,0-0.1
							C4.7,0.9,4.4,0.6,4.1,0.3"
                fill="currentColor"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
