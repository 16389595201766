import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { CONTENTFUL_URL } from '../../../lib/config'
import {
  updateCmiProgress,
  updateCmiSlides,
  updateSlides,
} from '../../../lib/state/actions/cmi'
import { completeSlide, viewedSlide } from '../../../lib/state/actions/player'
import { SLIDE_STATUS } from '../../../lib/state/constants'
import {
  getContentfulIdForSection,
  getContentfulIdForSlide,
  getElementsForLayout,
  getIsCompletionRequiredForSlide,
  getLayoutForSlide,
  getStylesForSlide,
  isModuleInteractiveOfType,
  isModuleOfType,
  isSectionOfType,
  isSlideOfType,
} from '../../../utils/contentfulHelper'
import { getNumberOfCompletedSlides, getNumberOfSlides } from '../../selectors'

import SlideComponent from './component'
import {
  DEFAULT_FONT_COLOR,
  DIALOG_CARDS,
  ERROR_LINKED_OBJECT_MISSING,
  ERROR_NO_LESSON_CONTENT_FOUND,
  ERROR_NO_SLIDES,
  FIRST_SECTION,
  INTERACTIVE,
  LINK,
} from './constants'

export class SlideContainer extends Component {
  componentDidMount() {
    const { currentSlide, sections } = this.props
    const { length: numOfSections } = sections || {}
    const section = currentSlide[0] || FIRST_SECTION
    const slide = numOfSections ? sections[section] : null

    if (slide == null) {
      return
    }

    this.viewAndCompleteSlide()
    this.updateCMI()
  }

  // This basically checks to see if currentSlide or completeStatus was updated and if it did, re-render and fire off Redux updates if they have
  // TODO: This should probably be handled differently when there's time to work on something at a Slide level.
  shouldComponentUpdate(nextProps) {
    const { completeStatus, currentSlide, currentSlideData } = this.props || {}
    const {
      completeStatus: nextCompleteStatus,
      currentSlide: nextCurrentSlide,
      currentSlideData: nextCurrentSlideData,
    } = nextProps || {}
    const completeStatusString = JSON.stringify(completeStatus)
    const currentSlideString = currentSlide.toString()
    const nextCompleteStatusString = JSON.stringify(nextCompleteStatus)
    const nextCurrentSlideString = nextCurrentSlide.toString()
    // Compare values to see if they have changed. Trigger componentDidUpdate() if they have changed.
    const currentSlideChanged =
      currentSlideString !== nextCurrentSlideString ||
      completeStatusString !== nextCompleteStatusString ||
      nextCurrentSlideData !== currentSlideData

    return currentSlideChanged
  }

  componentDidUpdate() {
    const { currentSlide, sections } = this.props
    const { length } = sections
    const section = length ? sections[currentSlide[0]] : null

    if (!section) {
      return
    }

    this.viewAndCompleteSlide()
    this.updateCMI()
  }

  slideContainsInteractive(slide) {
    const layout = getLayoutForSlide(slide)
    const elements = getElementsForLayout(layout) || []
    const interactive = elements.find(element =>
      element.find(module => {
        const isElementInteractive = isModuleOfType(module, INTERACTIVE)
        const isElementDialogCards = isModuleInteractiveOfType(
          module,
          DIALOG_CARDS,
        )

        // TODO: DialogCards are exempt here until per-LayoutElement completion states are ready
        // See LEARN-825 about future efforts to enable per-LayoutElement completion states.
        return isElementInteractive || isElementDialogCards
      }),
    )

    return !!interactive
  }

  viewAndCompleteSlide() {
    const {
      completeSlide,
      completeStatus,
      currentSlide,
      currentSlideData: slide,
      viewedSlide,
    } = this.props
    const currentSlideString = currentSlide.toString()
    const currentSlideCompleteStatus = completeStatus[currentSlideString]
    const isCompletionRequired = getIsCompletionRequiredForSlide(slide)
    const isNotCompleted = currentSlideCompleteStatus !== SLIDE_STATUS.COMPLETED
    const slideContainsInteractive = this.slideContainsInteractive(slide)
    const slideNotViewed =
      !currentSlideCompleteStatus ||
      currentSlideCompleteStatus === SLIDE_STATUS.NOT_VIEWED
    const canComplete = !slideContainsInteractive || !isCompletionRequired
    const shouldComplete = isNotCompleted && canComplete

    slideNotViewed && viewedSlide(currentSlideString)
    shouldComplete && completeSlide(currentSlideString)
  }

  getProgressPercentage = () => {
    const { numberOfCompletedslides, numberOfSlides } = this.props
    const progressPercentage = (
      numberOfCompletedslides / numberOfSlides
    ).toFixed(7) // round to 7 digits

    return progressPercentage
  }

  updateCMI() {
    const {
      completeStatus,
      currentSlide,
      updateCmiProgress,
      updateSlides,
      updateCmiSlides,
    } = this.props
    const currentSlidePosition = currentSlide.toString()
    const progressPercentage = this.getProgressPercentage()

    updateCmiProgress(progressPercentage)
    updateCmiSlides(completeStatus)
    updateSlides(currentSlidePosition)
  }

  checkForErrors = () => {
    const { currentSlide, currentSlideData: slide, sections } = this.props
    const numOfSections = sections.length
    const section = numOfSections > 0 ? sections[currentSlide[0]] : null
    const isSectionDefined = section !== null
    const isSlideDefined = slide !== null
    const isSectionOfTypeLink = isSectionOfType(section, LINK)
    const isSlideOfTypeLink = isSlideOfType(slide, LINK)
    const slideContentfulId = getContentfulIdForSlide(slide)
    const sectionContentfulId = getContentfulIdForSection(section)
    const errorWithMessage = message => ({
      message,
    })
    const errorWithLink = contentfulId => ({
      message: ERROR_LINKED_OBJECT_MISSING,
      url: `${CONTENTFUL_URL}${contentfulId}`,
    })
    let error
    error = !isSectionDefined
      ? errorWithMessage(ERROR_NO_LESSON_CONTENT_FOUND)
      : error
    error = isSectionOfTypeLink ? errorWithLink(sectionContentfulId) : error
    error = !isSlideDefined ? errorWithMessage(ERROR_NO_SLIDES) : error
    error = isSlideOfTypeLink ? errorWithLink(slideContentfulId) : error

    return error
  }

  getRenderProps = () => {
    const { currentSlideData } = this.props
    const {
      altText: backgroundImageAccessibilityLabel,
      backgroundImageUrl,
      backgroundColor,
      fontColor = DEFAULT_FONT_COLOR,
      foregroundColor,
    } = getStylesForSlide(currentSlideData) || {}
    const contentfulLayout = getLayoutForSlide(currentSlideData)

    const error = this.checkForErrors()
    const slideProps = {
      backgroundColor,
      backgroundImageAccessibilityLabel,
      backgroundImageUrl,
      error,
      fontColor,
      foregroundColor,
      contentfulLayout,
      onSubmit: this.onSubmit,
    }

    return slideProps
  }

  render() {
    const slideProps = this.getRenderProps()

    return <SlideComponent {...slideProps} />
  }
}

SlideContainer.propTypes = {
  completeSlide: PropTypes.func,
  completeStatus: PropTypes.object,
  currentSlide: PropTypes.array,
  currentSlideData: PropTypes.object,
  interactiveStates: PropTypes.object,
  numberOfCompletedslides: PropTypes.number,
  numberOfSlides: PropTypes.number,
  sections: PropTypes.array,
  updateCmiProgress: PropTypes.func,
  updateCmiSlides: PropTypes.func,
  updateSlides: PropTypes.func,
  viewedSlide: PropTypes.func,
}

SlideContainer.defaultProps = {
  completeSlide() {},
  currentSlide: [],
  sections: [],
  updateCmiProgress() {},
  updateCmiSlides() {},
  updateSlides() {},
  viewedSlide() {},
}

const mapDispatchToProps = dispatch => ({
  completeSlide: slide => dispatch(completeSlide(slide)),
  updateCmiProgress: progressMeasure =>
    dispatch(updateCmiProgress(progressMeasure)),
  updateCmiSlides: slides => dispatch(updateCmiSlides(slides)),
  updateSlides: slidePosition => dispatch(updateSlides(slidePosition)),
  viewedSlide: slide => dispatch(viewedSlide(slide)),
})

const mapStateToProps = ({ player }) => ({
  completeStatus: player.completeStatus,
  currentSlide: player.currentSlide,
  currentSlideData: player.currentSlideData,
  interactiveStates: player.interactiveStates,
  numberOfCompletedslides: getNumberOfCompletedSlides(player),
  numberOfSlides: getNumberOfSlides(player),
  sections: player.sections,
})

export default connect(mapStateToProps, mapDispatchToProps)(SlideContainer)
