import AcademicSuccessIcon from './AcademicSuccessIcon.jsx'
import AgencyIcon from './AgencyIcon.jsx'
import BelongingIcon from './BelongingIcon.jsx'
import BullyingAndHarassmentIcon from './BullyingAndHarassmentIcon.jsx'
import ConfidenceIcon from './ConfidenceIcon.jsx'
import ConflictsIcon from './ConflictsIcon.jsx'
import DecisionMakingIcon from './DecisionMakingIcon.jsx'
import EmpathyIcon from './EmpathyIcon.jsx'
import GrowthMindsetIcon from './GrowthMindsetIcon.jsx'
import GuidingPrinciplesIcon from './GuidingPrinciplesIcon.jsx'
import HelpingOthersIcon from './HelpingOthersIcon.jsx'
import SelfConceptIcon from './SelfConceptIcon.jsx'
import PerspectiveTakingIcon from './PerspectiveTakingIcon.jsx'
import PlanningAheadIcon from './PlanningAheadIcon.jsx'
import ProblemSolvingIcon from './ProblemSolvingIcon.jsx'
import RelationshipsIcon from './RelationshipsIcon.jsx'
import ResilienceIcon from './ResilienceIcon.jsx'
import StartingRightIcon from './StartingRightIcon.jsx'
import StayingCalmIcon from './StayingCalmIcon.jsx'
import ThoughtsAndEmotionsIcon from './ThoughtsAndEmotionsIcon.jsx'
import ValuesIcon from './ValuesIcon.jsx'

export default {
  AcademicSuccessIcon,
  AgencyIcon,
  BelongingIcon,
  BullyingAndHarassmentIcon,
  ConflictsIcon,
  ConfidenceIcon,
  DecisionMakingIcon,
  EmpathyIcon,
  GrowthMindsetIcon,
  GuidingPrinciplesIcon,
  HelpingOthersIcon,
  PerspectiveTakingIcon,
  PlanningAheadIcon,
  ProblemSolvingIcon,
  RelationshipsIcon,
  ResilienceIcon,
  SelfConceptIcon,
  StartingRightIcon,
  StayingCalmIcon,
  ThoughtsAndEmotionsIcon,
  ValuesIcon,
}
