import React from 'react'

import PropTypes from 'prop-types'

import SubmitAnswerIcon from '../../SubmitAnswerIcon'
import { HoverIndicator } from '../style'

import {
  Container,
  DropZoneContainer,
  SubmitAnswerIconContainer,
} from './style'

const CONTAINER_DATA_TEST_ID_PREFIX = 'container-'

const DragLabelDropZoneComponent = ({
  border,
  correct,
  dataTestId,
  dropRef,
  graded,
  isActive,
  isVisible,
  left,
  isLong,
  top,
}) => {
  const containerDataTestId = `${CONTAINER_DATA_TEST_ID_PREFIX}${dataTestId}`

  return (
    <Container
      data-testid={containerDataTestId}
      isDropZoneVisible={isVisible}
      isLong={isLong}
      left={left}
      top={top}
    >
      <DropZoneContainer
        border={border}
        data-testid={dataTestId}
        isActive={isActive}
        isVisible={isVisible}
        correct={correct}
        graded={graded}
        isLong={isLong}
        ref={dropRef}
      >
        <HoverIndicator isHovered={isActive} />
      </DropZoneContainer>
      <SubmitAnswerIconContainer isLong={isLong}>
        <SubmitAnswerIcon inCorrectLocation={correct} isSubmitted={graded} />
      </SubmitAnswerIconContainer>
    </Container>
  )
}

DragLabelDropZoneComponent.propTypes = {
  border: PropTypes.bool,
  correct: PropTypes.bool,
  dataTestId: PropTypes.string,
  dropRef: PropTypes.func,
  graded: PropTypes.bool,
  isActive: PropTypes.bool,
  isVisible: PropTypes.bool,
  left: PropTypes.number,
  isLong: PropTypes.bool,
  top: PropTypes.number,
}

DragLabelDropZoneComponent.defaultProps = {
  border: true,
  dropRef() {},
  isVisible: true,
}

export default DragLabelDropZoneComponent
