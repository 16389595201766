import React, { useEffect, useState } from 'react'

import { playerScaleSelector } from 'lib/state/selectors'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { VideoHelper } from 'secondstepVideo'

import VideoComponent from './component'
import { GET_ALL_CAPTION_TRACKS, ShowMenuBarForEnglish } from './data'
import { onVideoEnded, onVideoPlay, onVideoStopped } from './helpers'

const VideoContainer = ({ backgroundColor, videoRef, vtts }) => {
  // LEARN-13214: sometimes vtts comes through unpopulated but captions are set in videoRef file
  if (videoRef?.fields?.vttFile) {
    const transformedVttFile = videoRef.fields.vttFile.reduce((map, obj) => {
      map[obj.sys.locale] = obj.fields.file
      return map
    }, {})
    vtts = Object.assign(transformedVttFile, vtts)
  }

  const [transcript, setTranscript] = useState(null)
  const [audioDescriptionTranscript, setAudioDescriptionTranscript] = useState(
    null,
  )

  const [isTranscriptActive, setIsTranscriptActive] = useState(false)
  const [isAudioDescActive, setIsAudioDescActive] = useState(false)
  const [transcriptRendered, setRenderTranscript] = useState(false)
  const [isFetchingTranscript, setIsTranscriptFetching] = useState(false)
  // EA = "Extended Audio"
  const [isFetchingEATranscript, setIsFetchingEATranscript] = useState(false)

  const dispatch = useDispatch()

  const { fields } = videoRef || {}
  const { azureVideoUrl, azureVideoUrlExtended } = fields || {}
  const hasVideo = VideoHelper.hasVideoSource(videoRef)
  const settings = VideoHelper.getSettings({
    addPlayBackSpeedControls: true,
  })
  const poster = VideoHelper.getVideoModulePosterSource(videoRef)
  const transcriptSrc = VideoHelper.getVideoModuleTranscriptSource(videoRef)
  const extendedAudioTranscriptSrc = VideoHelper.getVideoModuleExtendedAudioTranscriptSource(
    videoRef,
  )

  const tracks = vtts ? GET_ALL_CAPTION_TRACKS(vtts) : null

  const vttPauseSource = VideoHelper.getVideoModuleVttPauseSource(videoRef)
  const vttExtendedPauseSource = VideoHelper.getVideoModuleVttPauseExtendedSource(
    videoRef,
  )

  if (vttPauseSource || vttExtendedPauseSource) {
    settings.plugins = VideoHelper.getExtendedSetting(
      isAudioDescActive ? vttExtendedPauseSource : vttPauseSource,
    )
  }

  const handleLoad = async () => {
    setIsTranscriptFetching(true)
    const r = await fetch(transcriptSrc)
    if (!r.ok) {
      return
    }
    const text = await r.text()
    setTranscript(text)
    setIsTranscriptFetching(false)
  }

  const handleExtendedAudioTranscriptLoad = async () => {
    setIsFetchingEATranscript(true)
    const r = await fetch(extendedAudioTranscriptSrc)
    if (!r.ok) {
      return
    }
    const text = await r.text()
    setAudioDescriptionTranscript(text)
    setIsFetchingEATranscript(false)
  }

  const handleTranscriptActiveStatus = () => {
    setIsTranscriptActive(true)
  }

  const handleVideoPlay = () => {
    onVideoPlay(dispatch)
  }

  const handleVideoStop = () => {
    onVideoStopped(dispatch)
  }

  const handleVideoEnd = () => {
    onVideoEnded(dispatch)
  }

  const shouldWaitForTranscript =
    transcriptSrc && isFetchingTranscript && !transcript
  const shoulWaitForEATranscript =
    extendedAudioTranscriptSrc &&
    isFetchingEATranscript &&
    !audioDescriptionTranscript

  useEffect(() => {
    if (!transcript && transcriptSrc && !isFetchingTranscript) {
      handleLoad()
    }
  })

  useEffect(() => {
    if (
      extendedAudioTranscriptSrc &&
      !audioDescriptionTranscript &&
      !isFetchingEATranscript
    ) {
      handleExtendedAudioTranscriptLoad()
    }
  })

  const getRatioWrapperOffset = scale => {
    const scaleOffset = 1 / scale
    const dimensionsOffset = 100 * scale

    return {
      dimensionsOffset,
      scaleOffset,
    }
  }
  const scale = useSelector(playerScaleSelector, shallowEqual)
  const { dimensionsOffset, scaleOffset } = getRatioWrapperOffset(scale) || {}

  const showTranscript = () => {
    if (!transcriptRendered && transcriptSrc) {
      setRenderTranscript(true)
      setIsTranscriptActive(true)
    }
    if (transcriptRendered) {
      setRenderTranscript(false)
      setIsTranscriptActive(false)
    }
  }
  const menuBarRenderedForEnglish = ShowMenuBarForEnglish()
  const handleClickAudioDesc = () => setIsAudioDescActive(!isAudioDescActive)
  const canShowAudioDescriptionButton = azureVideoUrlExtended !== undefined
  const canShowTranscriptButton = transcriptSrc !== undefined
  const canShowAccessibilityMenu =
    canShowAudioDescriptionButton === true || canShowTranscriptButton === true

  if (!azureVideoUrl || shouldWaitForTranscript || shoulWaitForEATranscript) {
    return null
  }

  const videoUrl = isAudioDescActive ? azureVideoUrlExtended : azureVideoUrl

  return (
    <VideoComponent
      accessibilityUrl={azureVideoUrlExtended}
      backgroundColor={backgroundColor}
      audioDescriptionTranscript={audioDescriptionTranscript}
      canShowAccessibilityMenu={canShowAccessibilityMenu}
      canShowAudioDesc={canShowAudioDescriptionButton}
      canShowAudioDescriptionButton={canShowAudioDescriptionButton}
      canShowTranscriptButton={canShowTranscriptButton}
      handleTranscriptActiveStatus={handleTranscriptActiveStatus}
      handleClickAudioDesc={handleClickAudioDesc}
      hasVideoSource={hasVideo}
      isTranscriptActive={isTranscriptActive}
      dimensionsOffset={dimensionsOffset}
      scaleOffset={scaleOffset}
      isAudioDescActive={isAudioDescActive}
      key={videoUrl}
      menuBarRenderedForEnglish={menuBarRenderedForEnglish}
      onEnded={handleVideoEnd}
      onPlay={handleVideoPlay}
      onStopped={handleVideoStop}
      poster={poster}
      settings={settings}
      showTranscript={showTranscript}
      tracks={tracks}
      transcript={transcript}
      transcriptRendered={transcriptRendered}
      videoUrl={videoUrl}
      vtts={vtts}
    />
  )
}

VideoContainer.propTypes = {
  backgroundColor: PropTypes.string,
  videoRef: PropTypes.object,
  vtts: PropTypes.object,
  scale: PropTypes.number,
}

VideoContainer.defaultProps = {
  scale: 1,
}

export default VideoContainer
