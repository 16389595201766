export const optimisticResponse = (state, key) => {
  const apiState = state[key]
  const { payload } = apiState || {}
  const isFetching = !payload
  return {
    ...state,
    isFetching,
    payload,
  }
}
