import styled from 'styled-components'

import { StyledButton } from '../Button/style'

export const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

export const HorizontalContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 88px;
`

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`

export const ExclamationImage = styled.img`
  margin: 12px;
`

export const Title = styled.div.attrs(
  ({
    theme: {
      colorVars: { httpErrorMessage: colors },
      fonts: { httpErrorMessage: fonts },
    },
  }) => ({
    color: colors.title.color,
    font: fonts.title,
  }),
)`
  ${props => props.font};
  color: ${props => props.color};
  margin: 8px;
`

export const MessageContainer = styled.div``

export const Message = styled.div.attrs(
  ({
    theme: {
      colorVars: { httpErrorMessage: colors },
      fonts: { httpErrorMessage: fonts },
    },
  }) => ({
    color: colors.message.color,
    font: fonts.message,
  }),
)`
  ${props => props.font};
  color: ${props => props.color};
`

export const RetryButton = styled(StyledButton).attrs(
  ({
    theme: {
      colorVars: { httpErrorMessage: colors },
      fonts: { httpErrorMessage: fonts },
    },
  }) => ({
    backgroundColor: colors.button.enabled.backgroundColor,
    backgroundColorSelected: colors.button.selected.backgroundColor,
    color: colors.button.enabled.color,
    colorSelected: colors.button.selected.color,
    font: fonts.button,
  }),
)`
  ${props => props.font};
  align-self: center;
  background-color: ${props => props.backgroundColor};
  color: ${props => props.color};
  margin: 24px;
  padding: 6px;
  width: auto;

  :active {
    background-color: ${props => props.backgroundColorSelected};
    color: ${props => props.colorSelected};
  }

  :focus {
    background-color: ${props => props.backgroundColorSelected};
    border-color: ${props => props.backgroundColorSelected};
    color: ${props => props.colorSelected};
  }

  :hover:enabled {
    background-color: ${props => props.backgroundColorSelected};
    border-color: ${props => props.backgroundColorSelected};
    color: ${props => props.colorSelected};
  }
`
