import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { toPascalCase } from '../../utils/stringHelpers'
import ProgramThemeIcons from '../lib/icons/ProgramThemes'
import { StyledTextLink } from './component.styles'

const ProgramThemeLink = ({
  children,
  className,
  dataTestId,
  href,
  programTheme = '',
}) => {
  const Icon = ProgramThemeIcons[`${toPascalCase(programTheme)}Icon`]

  return (
    <StyledTextLink
      className={className}
      dataTestId={dataTestId}
      href={href}
      tabIndex="0"
    >
      {Icon && <Icon />}
      {children}
    </StyledTextLink>
  )
}

ProgramThemeLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  dataTestId: PropTypes.string.isRequired,
  href: PropTypes.string,
  programTheme: PropTypes.string.isRequired,
}

export default styled(ProgramThemeLink)``
