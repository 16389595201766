import { LOCALES } from 'utils/locale'

export const CONTAINER_WIDTH = 500
export const DEFAULT_MESSAGE = {
  [LOCALES.EN_US]: "You've completed a lesson",
  [LOCALES.ES_US]: 'Ha completado la lección',
}

const MESSAGE_PREFIX = {
  [LOCALES.EN_US]: "You've completed ",
  [LOCALES.ES_US]: 'Ha completado ',
}
const MESSAGE_SUFFIX = {
  [LOCALES.EN_US]: ' lesson',
  [LOCALES.ES_US]: ' lección',
}
export const TEXT_DATA_TEST_ID = 'lesson-complete-text'
const THE = {
  [LOCALES.EN_US]: 'the ',
  [LOCALES.ES_US]: 'la ',
}

export const TITLE = {
  [LOCALES.EN_US]: 'Mark this lesson done?',
  [LOCALES.ES_US]: '¿Marcar esta lección como concluida?',
}

export const CONFIRM_TEXT = {
  [LOCALES.EN_US]: 'YES, MARK DONE',
  [LOCALES.ES_US]: 'SÍ, MARCAR HECHA',
}

export const CANCEL_TEXT = {
  [LOCALES.EN_US]: 'CANCEL',
  [LOCALES.ES_US]: 'CANCELAR',
}

export const MESSAGE = (lessonName, locale) =>
  `${MESSAGE_PREFIX[locale]}${THE[locale]}${lessonName}${MESSAGE_SUFFIX[locale]}`

export const CONFIRM_DATA_TESTID = 'confirm-lesson-button'

export const CANCEL_DATA_TESTID = 'cancel-lesson-button'
