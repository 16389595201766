/* istanbul ignore file */
// we don't unit test SVGs
import React from 'react'

const SecondStepLogoIcon = () => (
  <svg height="22px" viewBox="0 0 18 22" width="18px">
    <title>Home</title>
    <g
      fill="currentColor"
      fillRule="evenodd"
      id="ss-logo"
      stroke="none"
      strokeWidth="1"
    >
      <path
        d="M13.0830516,8.93491169 C10.1664837,8.85112407 6.79654495,10.0328887 6.35686048,8.91302564 C5.917176,7.7932442 9.56307958,6.7445924 11.7986014,6.49265791 C15.1731883,6.11226541 17.5522015,5.30534006 17.9268974,3.49533161 C18.4412972,1.01085737 16.2914226,-0.949250142 9.87158176,0.487307278 C5.0334171,1.56993144 0.0727639999,5.56103111 0.542919947,9.35115483 C1.00240227,13.0561843 5.75388676,12.2784131 8.44897697,11.7201557 C11.2067317,11.1487503 14.1912148,11.4511534 12.3757518,12.9403026 C11.3349873,13.79394 8.34215465,13.7882235 7.10616614,13.8718477 C4.39093377,14.0554292 -0.194679158,14.7818335 0.00639815668,18.2527313 C0.232954446,22.1652699 5.3514739,22.1304809 8.29489804,21.9292599 C11.2665556,21.7260791 14.5502446,20.0112912 16.1390653,17.6058681 C18.7109784,13.712194 18.04732,9.07749763 13.0830516,8.93491169"
        fill="currentColor"
      />
    </g>
  </svg>
)

export default SecondStepLogoIcon
