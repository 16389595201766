import styled from 'styled-components'

export const TurnIndicatorContainer = styled.div`
  align-items: center;
  font-size: ${props => props.theme.fonts.dialogCard.turnIndicator.fontSize};
  font-style: ${props => props.theme.fonts.dialogCard.turnIndicator.fontStyle};
  font-weight: ${props =>
    props.theme.fonts.dialogCard.turnIndicator.fontWeight};
  background-color: ${props =>
    props.theme.colorVars.dialogCard.turnIndicator.backgroundDefault};
  border-radius: 0 0 4px 4px;
  box-sizing: border-box;
  color: ${props => props.theme.colorVars.dialogCard.turnIndicator.fillDefault};
  display: flex;
  justify-content: center;
  max-height: 10%;
  min-height: 10%;
  padding: 0 25px;
  position: relative;
  transition: all 0.2s ease-in-out;
  width: 100%;
`

export const ArrowContainer = styled.span`
  height: 25px;
  width: 25px;
  position: absolute;
  right: 25px;

  svg {
    transition: all 0.2s ease-in-out;
  }
`
