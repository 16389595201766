import React from 'react'
import PropTypes from 'prop-types'
import { noBreakEnEmDash } from '../../utils/stringHelpers'

const NoBreakSpan = ({ children }) => {
  const noBreakString = noBreakEnEmDash(children)
  return <span dangerouslySetInnerHTML={{ __html: noBreakString }} />
}

NoBreakSpan.propTypes = {
  children: PropTypes.string,
}

export default NoBreakSpan
