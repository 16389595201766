import React from 'react'

import PropTypes from 'prop-types'

import { ARIA_LABEL, DATA_TEST_ID } from './constants'
import { DragQuestionSubmitStyledButton } from './style'

const DragQuestionSubmitButton = ({ disabled, onClick, setRef, text }) => {
  return (
    <DragQuestionSubmitStyledButton
      aria-label={ARIA_LABEL}
      data-testid={DATA_TEST_ID}
      disabled={disabled}
      onClick={onClick}
      ref={setRef}
    >
      {text}
    </DragQuestionSubmitStyledButton>
  )
}

DragQuestionSubmitButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  setRef: PropTypes.object,
  text: PropTypes.string,
}

DragQuestionSubmitButton.defaultProps = {
  onClick() {},
  setRef: {},
}

export default DragQuestionSubmitButton
