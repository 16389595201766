import * as React from 'react'
const BackpackSuccess = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={88} height={120} {...props}>
    <title>{'ss_backpack_successmodal'}</title>
    <g fill="none" fillRule="evenodd" transform="translate(7 7)">
      <g transform="translate(6.443 27.37)">
        <path
          stroke="#3C6E90"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M30 9.791c16.569 0 30 13.432 30 30v36.517A7.692 7.692 0 0 1 52.308 84H7.692A7.692 7.692 0 0 1 0 76.308V39.79c0-16.568 13.431-30 30-30Z"
        />
        <path
          stroke="#E77564"
          strokeLinecap="square"
          strokeWidth={2}
          d="M8.462 63.129h43.076"
        />
        <rect
          width={44.615}
          height={18.552}
          x={7.692}
          y={57.718}
          stroke="#3C6E90"
          strokeWidth={2}
          rx={3.077}
        />
        <path
          stroke="#E77564"
          strokeWidth={2}
          d="M21.538 10.307V8.462a8.462 8.462 0 0 1 16.924 0v1.845"
        />
        <path
          fill="#C4D3DE"
          stroke="#3C6E90"
          strokeWidth={2}
          d="M4.98 20.206C4.98 34.093 16.18 45.35 30 45.35c13.819 0 25.02-11.257 25.02-25.144 0-13.886-50.04-13.886-50.04 0Z"
        />
        <rect
          width={4.615}
          height={7.73}
          x={27.692}
          y={40.712}
          fill="#FFF"
          stroke="#3C6E90"
          strokeWidth={2}
          rx={1.538}
        />
        <rect
          width={2.308}
          height={4.638}
          x={46.154}
          y={62.356}
          fill="#E77564"
          rx={1.154}
        />
      </g>
      <path
        fill="#E77564"
        fillRule="nonzero"
        d="M18.562 28.758c5.69-14.103-7.381-15.256-6.313-7.321a.199.199 0 0 1-.066.194.198.198 0 0 1-.203.03c-4.999-1.768-7.555 3.439-3.705 6.485 2.36 2.02 8.347 1.319 9.844.985a.593.593 0 0 0 .443-.373ZM51.727 8.999c10.472-.554 7.384-9.06 2.736-6.08a.137.137 0 0 1-.14.015.137.137 0 0 1-.08-.117c-.35-3.64-4.35-3.72-5.132-.426-.574 2.063 1.609 5.601 2.253 6.44.084.115.22.178.363.168Z"
      />
      <path
        fill="#E77564"
        d="m54.491 24.614-.71.373a.655.655 0 0 1-.951-.69l.136-.791a1 1 0 0 0-.288-.886l-.575-.56a.655.655 0 0 1 .364-1.118l.794-.115a1 1 0 0 0 .753-.547l.355-.72a.655.655 0 0 1 1.175 0l.355.72a1 1 0 0 0 .753.547l.794.115a.655.655 0 0 1 .364 1.118l-.575.56a1 1 0 0 0-.287.886l.135.79a.655.655 0 0 1-.95.691l-.711-.373a1 1 0 0 0-.93 0Z"
      />
      <path
        fill="#3C6E90"
        d="m37.997 16.932-.942 1.492a1 1 0 0 1-1.784-.187l-.61-1.655a1 1 0 0 0-.693-.623l-1.71-.435a1 1 0 0 1-.372-1.754l1.385-1.093a1 1 0 0 0 .378-.85l-.114-1.761a1 1 0 0 1 1.553-.897l1.467.98a1 1 0 0 0 .926.097l1.639-.653a1 1 0 0 1 1.333 1.2l-.479 1.698a1 1 0 0 0 .194.91l1.128 1.358a1 1 0 0 1-.73 1.638l-1.763.07a1 1 0 0 0-.806.465Z"
      />
      <circle cx={62.457} cy={33.869} r={1.5} stroke="#3C6E90" />
      <circle cx={18.5} cy={2.913} r={1.5} stroke="#3C6E90" />
      <circle cx={2} cy={39.413} r={2} stroke="#3C6E90" />
    </g>
  </svg>
)

export default BackpackSuccess
