/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { IconVariants } from './styleMixin'
import { ButtonStyled } from './component.styles'
import { useBreakpoints } from '../../utils/mediaHelper'

const Lines = props => {
  const {
    g4,
    hasActive,
    rect3,
    line6,
    line7,
    line8,
    line9,
    line10,
    line11,
  } = props
  return (
    <g {...g4}>
      {hasActive && <line {...line6} />}
      <rect {...rect3} />
      <line {...line7} />
      <line {...line8} />
      <line {...line9} />
      <line {...line10} />
      <line {...line11} />
    </g>
  )
}
export const Adornment = props => {
  const {
    activeLine,
    hasActive,
    isMobile,
    variant,
    rect1,
    rect2,
    line1,
    line2,
    line3,
    line4,
    line5,
    g1,
    g2,
    g3,
    height,
    width,
  } = props

  return (
    <svg
      height={`${height}px`}
      viewBox={`0 0 ${width} ${height}`}
      width={`${width}px`}
    >
      {!isMobile && variant !== 'default' && (
        <defs>
          <rect height="1199" rx="12" width="1166" x="100" y="-43" />
          <filter
            filterUnits="objectBoundingBox"
            height="107.1%"
            width="107.3%"
            x="-3.6%"
            y="-3.5%"
          >
            <feMorphology
              in="SourceAlpha"
              operator="dilate"
              radius="1"
              result="shadowSpreadOuter1"
            />
            <feOffset
              dx="0"
              dy="1"
              in="shadowSpreadOuter1"
              result="shadowOffsetOuter1"
            />
            <feGaussianBlur
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
              stdDeviation="13"
            />
            <feColorMatrix
              in="shadowBlurOuter1"
              type="matrix"
              values="0 0 0 0 0.650985054   0 0 0 0 0.650985054   0 0 0 0 0.650985054  0 0 0 0.8 0"
            />
          </filter>
        </defs>
      )}
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g {...g1}>
          {!isMobile && (
            <>
              <rect {...rect1} />
              {variant === 'default' && (
                <>
                  <image height="37" width="75" x="1291" y="53" />
                  <rect fill="#000000" height="54" width="1366" x="0" y="0" />
                </>
              )}
              {variant !== 'default' && (
                <g>
                  <use fill="black" fillOpacity="1" filter="url(#filter-2)" />
                  <use fill="#FFFFFF" fillRule="evenodd" />
                </g>
              )}
            </>
          )}
          <g {...g2}>
            {hasActive && <line id="Active-Line" {...activeLine} />}
            <g {...g3}>
              {variant === 'default' && (
                <line
                  stroke="#CED8DF"
                  strokeLinecap="round"
                  strokeWidth="2"
                  x1="1"
                  x2="29"
                  y1="34.5138889"
                  y2="34.5138889"
                />
              )}
              <rect {...rect2} />
              <line {...line1} />
              <line {...line2} />
              <line {...line3} />
              <line {...line4} />
              <line {...line5} />
              {variant === 'default' && <Lines {...props} />}
            </g>

            {variant !== 'default' && <Lines {...props} />}
          </g>
        </g>
      </g>
    </svg>
  )
}

const TranscriptButton = ({
  children,
  ariaLabel,
  hasLeftAdornment,
  hasResposive,
  ...rest
}) => {
  const { isSm: isMobile } = useBreakpoints(hasResposive) || false

  return (
    <ButtonStyled {...rest} aria-label={ariaLabel}>
      {hasLeftAdornment && (
        <Adornment key={ariaLabel} {...IconVariants({ ...rest, isMobile })} />
      )}
      {children}
      {!hasLeftAdornment && (
        <Adornment key={ariaLabel} {...IconVariants({ ...rest, isMobile })} />
      )}
    </ButtonStyled>
  )
}

const rectShape = PropTypes.shape({
  color: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
})
const lineShape = PropTypes.shape({
  stroke: PropTypes.string,
  strokeLinecap: PropTypes.string,
  strokeWidth: PropTypes.number,
  x1: PropTypes.number,
  x2: PropTypes.number,
  y1: PropTypes.number,
  y2: PropTypes.number,
})
const gShape = PropTypes.shape({
  fill: PropTypes.string,
  transform: PropTypes.string,
})

const variantShape = PropTypes.oneOf(['default', 'sela', 'learn', 'highSchool'])

Adornment.propTypes = {
  g1: gShape,
  g2: gShape,
  g3: gShape,
  g4: gShape,
  hasActive: PropTypes.bool,
  hasHover: PropTypes.bool,
  height: PropTypes.number,
  isMobile: PropTypes.bool,
  line1: lineShape,
  line10: lineShape,
  line11: lineShape,
  line2: lineShape,
  line3: lineShape,
  line4: lineShape,
  line5: lineShape,
  line6: lineShape,
  line7: lineShape,
  line8: lineShape,
  line9: lineShape,
  rect1: rectShape,
  rect2: rectShape,
  rect3: rectShape,
  variant: variantShape,
  width: PropTypes.number,
}

TranscriptButton.defaultProps = {
  ariaLabel: '',
  variant: 'default',
  height: 30,
  width: 30,
  hasHover: false,
  hasActive: false,
  children: null,
  hasLeftAdornment: false,
  hasResposive: true,
}
TranscriptButton.propTypes = {
  ariaLabel: PropTypes.string,
  children: PropTypes.node,
  hasActive: PropTypes.bool,
  hasHover: PropTypes.bool,
  hasLeftAdornment: PropTypes.bool,
  hasResposive: PropTypes.bool,
  variant: variantShape,
}

export default TranscriptButton
