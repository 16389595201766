export const getDomainSuffix = () => {
  const { hostname } = window.location ?? {}
  if (hostname === 'localhost' || !hostname) {
    return '-np-dev'
  }
  if (hostname.includes('secondstep')) {
    return ''
  }
  return hostname.split('.')[0].replace('player', '')
}

export const getHostnameBase = () => {
  const { hostname } = window.location ?? {}
  if (hostname === 'localhost' || !hostname) {
    return 'cfctest'
  }
  return hostname.split('.')[1]
}

export const getLearnBaseUrl = () => {
  return `https://learn${getDomainSuffix()}.${getHostnameBase()}.org`
}
