import styled from 'styled-components'
import ProgressStatus from '../ProgressStatus'

const ProgressStatusMedium = styled(ProgressStatus)`
  font-size: 1rem;
`

ProgressStatusMedium.displayName = 'ProgressStatusMedium'

export default ProgressStatusMedium
