import React from 'react'

import PropTypes from 'prop-types'

import ImageHotspotsTarget from '../ImageHotspotsTarget'
import { createHotspotIdFromHotspot } from '../utils'

const ImageHotspotsTargetsComponent = props => {
  const getTargetProps = (hotspot, id, index) => {
    const {
      id: interactiveId,
      getHotspotPixelPositionInBounds,
      onClickTarget: onClick,
      shouldHotspotToolTipFlip,
      targetsClicked,
    } = props || {}
    const { header } = hotspot || {}
    const { left, top } = getHotspotPixelPositionInBounds(hotspot) || {}
    const isToolTipFlipped = shouldHotspotToolTipFlip(left)
    const isTargetClicked = (targetsClicked && targetsClicked[index]) || false
    const targetProps = {
      isToolTipFlipped,
      id,
      index,
      interactiveId,
      isTargetClicked,
      left,
      onClick,
      toolTip: header,
      top,
    }

    return targetProps
  }

  const { hotspots } = props || {}

  const mapHotspotToComponent = (hotspot, index) => {
    const id = createHotspotIdFromHotspot(hotspot)
    const targetProps = getTargetProps(hotspot, id, index) || {}

    return <ImageHotspotsTarget key={id} {...targetProps} />
  }
  const hotspotTargetComponents = hotspots.map(mapHotspotToComponent)

  return hotspotTargetComponents
}

ImageHotspotsTargetsComponent.propTypes = {
  getHotspotPixelPositionInBounds: PropTypes.func,
  hotspots: PropTypes.array,
  id: PropTypes.string,
  onClickTarget: PropTypes.func,
}

ImageHotspotsTargetsComponent.defaultProps = {
  getHotspotPixelPositionInBounds() {},
  hotspots: [],
  onClickTarget() {},
  shouldHotspotToolTipFlip() {},
}

export default ImageHotspotsTargetsComponent
