import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'

import ProgressSection from './ProgressSection'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-right: 0.5em;
`
const DATA_TEST_ID = 'progress-wrapper'

const Progress = ({ sections }) => (
  <Wrapper
    data-testid={DATA_TEST_ID}
    role="navigation"
    aria-label="Lesson Slide"
  >
    {sections.map((section, i) => (
      <ProgressSection {...section.fields} sectionIndex={i} key={i} />
    ))}
  </Wrapper>
)

Progress.propTypes = {
  sections: PropTypes.array,
}

const mapStateToProps = ({ player }) => ({
  sections: player.sections,
})

export default connect(mapStateToProps, null)(Progress)
