import styled from 'styled-components'

export const StyledButton = styled.button.attrs(
  ({
    theme: {
      colorVars: { button },
      fonts,
    },
  }) => ({
    backgroundColor: button.enabled.background,
    borderColor: button.enabled.border,
    borderRadius: button.cornerRadius,
    borderStyle: button.borderStyle,
    borderWidth: button.borderWidth,
    color: button.enabled.color,
    disabledBackgroundColor: button.disabled.background,
    disabledBorderColor: button.disabled.border,
    disabledColor: button.disabled.color,
    disabledOpacity: button.disabled.opacity,
    dropShadow: button.hover.dropshadow,
    fontFamily: fonts.button.fontFamily,
    fontSize: fonts.button.fontSize,
    fontWeight: fonts.button.fontWeight,
    hoverBackgroundColor: button.hover.background,
    hoverColor: button.hover.color,
    margin: button.margin,
    selectedBackgroundColor: button.selected.background,
    selectedColor: button.selected.color,
    width: `${button.width + 4}px`,
  }),
)`
  background-color: ${props =>
    !props.visited ? props.backgroundColor : props.selectedBackgroundColor};
  border-color: ${props => props.borderColor};
  border-radius: ${props => props.borderRadius};
  border-style: ${props => props.borderStyle};
  border-width: ${props => props.borderWidth};
  color: ${props => (!props.visited ? props.color : props.selectedColor)};
  cursor: pointer;
  display: block;
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  margin: ${props => props.margin};
  width: ${props => props.width};

  :hover:enabled {
    background-color: ${props => props.hoverBackgroundColor};
    box-shadow: ${props => props.dropShadow};
    color: ${props => props.hoverColor};
  }

  :focus {
    background-color: ${props => props.selectedBackgroundColor};
    color: ${props => props.selectedColor};
    outline: 3px groove ${props => props.theme.colorVars.blueFocus};
  }

  :active {
    background-color: ${props => props.selectedBackgroundColor};
    color: ${props => props.selectedColor};
  }

  :disabled {
    background-color: ${props => props.disabledBackgroundColor};
    border-color: ${props => props.disabledBorderColor};
    color: ${props => props.disabledColor};
    cursor: default;
    svg {
      opacity: ${props => props.disabledOpacity};
    }
  }
`
