/* istanbul ignore file */
// we don't unit test SVGs
import React from 'react'
import PropTypes from 'prop-types'

const SpinnerAnimatedIconMedium = ({ className }) => (
  <svg
    className={className}
    display="block"
    height="200"
    preserveAspectRatio="xMidYMid"
    style={{ margin: 'auto', background: 'none' }}
    viewBox="0 0 100 100"
    width="200"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill="#3c6e90" height="6" rx="3" ry="3" width="6" x="47" y="27">
      <animate
        attributeName="opacity"
        begin="-0.9166666666666666s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      />
    </rect>
    <rect
      fill="#3c6e90"
      height="6"
      rx="3"
      ry="3"
      transform="rotate(30 50 50)"
      width="6"
      x="47"
      y="27"
    >
      <animate
        attributeName="opacity"
        begin="-0.8333333333333334s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      />
    </rect>
    <rect
      fill="#3c6e90"
      height="6"
      rx="3"
      ry="3"
      transform="rotate(60 50 50)"
      width="6"
      x="47"
      y="27"
    >
      <animate
        attributeName="opacity"
        begin="-0.75s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      />
    </rect>
    <rect
      fill="#3c6e90"
      height="6"
      rx="3"
      ry="3"
      transform="rotate(90 50 50)"
      width="6"
      x="47"
      y="27"
    >
      <animate
        attributeName="opacity"
        begin="-0.6666666666666666s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      />
    </rect>
    <rect
      fill="#3c6e90"
      height="6"
      rx="3"
      ry="3"
      transform="rotate(120 50 50)"
      width="6"
      x="47"
      y="27"
    >
      <animate
        attributeName="opacity"
        begin="-0.5833333333333334s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      />
    </rect>
    <rect
      fill="#3c6e90"
      height="6"
      rx="3"
      ry="3"
      transform="rotate(150 50 50)"
      width="6"
      x="47"
      y="27"
    >
      <animate
        attributeName="opacity"
        begin="-0.5s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      />
    </rect>
    <rect
      fill="#3c6e90"
      height="6"
      rx="3"
      ry="3"
      transform="rotate(180 50 50)"
      width="6"
      x="47"
      y="27"
    >
      <animate
        attributeName="opacity"
        begin="-0.4166666666666667s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      />
    </rect>
    <rect
      fill="#3c6e90"
      height="6"
      rx="3"
      ry="3"
      transform="rotate(210 50 50)"
      width="6"
      x="47"
      y="27"
    >
      <animate
        attributeName="opacity"
        begin="-0.3333333333333333s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      />
    </rect>
    <rect
      fill="#3c6e90"
      height="6"
      rx="3"
      ry="3"
      transform="rotate(240 50 50)"
      width="6"
      x="47"
      y="27"
    >
      <animate
        attributeName="opacity"
        begin="-0.25s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      />
    </rect>
    <rect
      fill="#3c6e90"
      height="6"
      rx="3"
      ry="3"
      transform="rotate(270 50 50)"
      width="6"
      x="47"
      y="27"
    >
      <animate
        attributeName="opacity"
        begin="-0.16666666666666666s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      />
    </rect>
    <rect
      fill="#3c6e90"
      height="6"
      rx="3"
      ry="3"
      transform="rotate(300 50 50)"
      width="6"
      x="47"
      y="27"
    >
      <animate
        attributeName="opacity"
        begin="-0.08333333333333333s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      />
    </rect>
    <rect
      fill="#3c6e90"
      height="6"
      rx="3"
      ry="3"
      transform="rotate(330 50 50)"
      width="6"
      x="47"
      y="27"
    >
      <animate
        attributeName="opacity"
        begin="0s"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        values="1;0"
      />
    </rect>
  </svg>
)

SpinnerAnimatedIconMedium.propTypes = {
  className: PropTypes.string,
}

export default SpinnerAnimatedIconMedium
