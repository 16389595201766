import styled from 'styled-components'

import { FULL_HEIGHT, FULL_WIDTH } from '../style'

export const Frame = styled.div.attrs(({ height, width }) => ({
  style: { height: `${height}px`, width: `${width}px` },
}))`
  justify-content: center;
  min-height: 300px;
  min-width: 400px;
  object-fit: contain;
  user-select: none;
`

export const Image = styled.img`
  height: ${FULL_HEIGHT};
  width: ${FULL_WIDTH};
`
