import styled from 'styled-components'

const LARGE_MARGIN_DRAGGABLE = '30px' //used for 2 columns of small draggables
const SMALL_MARGIN_DRAGGABLE = '12px' //used for 2 columns of large draggables, and 3 columns of short draggables

export const Container = styled.div.attrs(
  ({
    theme: {
      colorVars: { dragLabel: colors },
    },
  }) => ({
    backgroundColor: colors.container.background,
  }),
)`
  background-color: ${props => props.backgroundColor};
  display: flex;
  flex: 1;
  padding-bottom: 6px;
  padding-top: 6px;
`

export const Column = styled.div.attrs(
  ({
    longLabels,
    numOfColumns,
    theme: {
      colorVars: { dragLabel: colors },
    },
  }) => ({
    backgroundColor: colors.container.background,
    marginRight:
      !longLabels && numOfColumns === 2
        ? LARGE_MARGIN_DRAGGABLE
        : SMALL_MARGIN_DRAGGABLE,
  }),
)`
  background-color: ${props => props.backgroundColor};
  display: flex;
  flex-direction: column;
  margin-right: ${props => props.marginRight};

  :last-child {
    margin-right: 0;
  }
`
