import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import MultiChoiceEvaluationTextComponent from './component'

const SCORE_MULTIPLIER = 100

export class MultiChoiceEvaluationTextContainer extends Component {
  evaluationText = () => {
    const { isSubmitted, feedbackTiers, score, totalNumOfCorrectAnswers } =
      this.props
    let message = ''

    if (isSubmitted) {
      // H5P sets the feedback ranges in integers, such as 0 - 25, 26 - 75, 76 - 100;
      // because of this, we floor the score and multiply by 100 so .255 is in the 0 - 25 group.
      let currentScore = (score / totalNumOfCorrectAnswers) * SCORE_MULTIPLIER

      //If no answers are correct, that means this is an unevaluated question. Override the score and assign
      // an arbitrary score that will show as success
      if (totalNumOfCorrectAnswers === 0) {
        currentScore = 100
      }

      const feedbackIndex = feedbackTiers.findIndex(feedbackTier => {
        const { from, to } = feedbackTier || {}
        return currentScore >= from && ~~currentScore <= to
      })

      if (feedbackIndex !== -1 && feedbackTiers[feedbackIndex].feedback) {
        message = feedbackTiers[feedbackIndex].feedback
      }
    }

    return message
  }

  render = () => {
    return (
      <MultiChoiceEvaluationTextComponent
        getSubmitMessage={this.evaluationText}
      />
    )
  }
}

MultiChoiceEvaluationTextContainer.propTypes = {
  feedbackTiers: PropTypes.arrayOf(
    PropTypes.shape({
      from: PropTypes.number,
      to: PropTypes.number,
      feedback: PropTypes.string,
    }),
  ),
  isSubmitted: PropTypes.bool,
  score: PropTypes.number,
  totalNumOfCorrectAnswers: PropTypes.number,
}

MultiChoiceEvaluationTextContainer.defaultProps = {
  feedbackTiers: [],
  options: [],
}

const mapStateToProps = ({ player }, ownProps) => {
  const { id } = ownProps || {}
  const interactiveState = player.interactiveStates[id] || null

  return {
    ...interactiveState,
  }
}

export default connect(mapStateToProps)(MultiChoiceEvaluationTextContainer)
