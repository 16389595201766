/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

const NavUnabletoSave = () => (
  <svg height="17px" viewBox="0 0 20 17" width="20px">
    <g
      fill="none"
      fillRule="evenodd"
      id="SELA_header_unabletosave"
      stroke="none"
      strokeWidth="1"
    >
      <path
        d="M11.9149859,1.85830975 L18.5826051,12.9710085 C19.1509014,13.918169 18.8437713,15.1466895 17.8966108,15.7149859 C17.5857799,15.9014843 17.2301071,16 16.8676192,16 L3.53238076,16 C2.42781126,16 1.53238076,15.1045695 1.53238076,14 C1.53238076,13.6375121 1.63089642,13.2818393 1.81739491,12.9710085 L8.48501415,1.85830975 C9.05331047,0.91114922 10.281831,0.604019093 11.2289915,1.17231541 C11.5104907,1.34121494 11.7460863,1.57681054 11.9149859,1.85830975 Z"
        id="Triangle"
        stroke="#6C7171"
        strokeWidth="1.5"
      />
      <line
        id="Line-4"
        stroke="#6C7171"
        strokeLinecap="round"
        strokeWidth="1.75"
        x1="10.2"
        x2="10.2"
        y1="6"
        y2="10"
      />
      <circle cx="10.2" cy="13" fill="#6C7171" id="Oval" r="1" />
    </g>
  </svg>
)

export default NavUnabletoSave
