import { useEffect, useRef } from 'react'

// Lets us check previous props using hooks
// Usage: const prevProps = usePrevious({ (props_you_want_to_access_previous_value_of })
// More info: https://blog.logrocket.com/how-to-get-previous-props-state-with-react-hooks/
export function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })

  return ref.current
}
