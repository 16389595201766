import React from 'react'

//TODO: Possibly remove lazy load after migration
const VideoV3 = React.lazy(() => import('./src/VideoV3'))
import VideoV2 from './src/Video'
import VideoPlayButton from './src/VideoPlayButton'
import VideoTitle from './src/VideoTitle'
import VideoPlayIcon from './src/VideoPlayIcon'
import TranscriptComponent from './src/Transcript'

import {
  initAmpAndElmo,
  initializeAzureMediaPlayer,
  initializeElmo,
} from './src/Video/videoSystemInitializer'
import VideoHelper from './src/utils/videoHelper'
export {
  initAmpAndElmo,
  initializeAzureMediaPlayer,
  initializeElmo,
  TranscriptComponent,
  VideoV2,
  VideoV3,
  VideoPlayButton,
  VideoPlayIcon,
  VideoTitle,
  VideoHelper,
}
