import styled from 'styled-components'

export const DropZoneContainer = styled.div.attrs(
  ({
    isLast,
    theme: {
      colorVars: { dragQuestion },
    },
  }) => ({
    backgroundColor: dragQuestion.dropZone.background,
    backgroundColorHover: dragQuestion.dropZone.backgroundHover,
    borderColor: dragQuestion.dropZone.border,
    marginRight: isLast ? '0em' : '1em',
  }),
)`
  background-color: ${props =>
    props.isOver ? props.backgroundColorHover : props.backgroundColor};
  border: 2px solid ${props => props.borderColor};
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  margin-right: ${props => props.marginRight};
  z-index: 0;
`

export const TitleBar = styled.div.attrs(
  ({
    theme: {
      colorVars: { dragQuestion },
    },
  }) => ({
    backgroundColor: dragQuestion.dropZone.titleBar.background,
    color: dragQuestion.dropZone.titleBar.color,
  }),
)`
  background-color: ${props => props.backgroundColor};
  min-height: 3.125rem;
  text-align: center;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  //Disable text selection
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
`

export const TitleText = styled.div.attrs(
  ({
    theme: {
      colorVars: { dragQuestion: colors },
      fonts: { dragQuestion: fonts },
    },
  }) => ({
    color: colors.dropZone.titleBar.color,
    font: fonts.dropZone.title,
  }),
)`
  ${props => props.font};
  color: ${props => props.color};
  margin: 0 0.3125rem;
`

export const DraggablePlaceholder = styled.div.attrs(
  ({
    theme: {
      colorVars: { dragQuestion: colors },
    },
  }) => ({
    background: colors.dropZone.placeholder.background,
    borderLeft: colors.dropZone.placeholder.borderLeft,
  }),
)`
  background-color: ${props => props.background};
  border-left: 5px solid ${props => props.borderLeft};
  border-radius: 5px;
  height: ${props => props.placeholderHeight}px;
  margin: 0.25rem;
  width: auto;
`

export const NoPlaceholder = styled.div``
