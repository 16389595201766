/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

const DeleteIcon = () => (
  <svg height="12" viewBox="0 0 12 12" width="12">
    <defs>
      <path d="M0 0L12 0 12 12 0 12z" id="path-delete-icon" />
    </defs>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g>
        <mask fill="#fff" id="mask-delete-icon">
          <use xlinkHref="#path-delete-icon" />
        </mask>
        <path
          d="M8.775 4.006l-.812 5.85a.333.333 0 01-.66-.092l.812-5.85a.333.333 0 11.66.092zM6.333 9.873a.333.333 0 01-.666 0V3.967a.333.333 0 01.666 0v5.906zm-1.92.266a.333.333 0 01-.376-.284l-.812-5.85a.333.333 0 11.66-.09l.811 5.849a.333.333 0 01-.284.375zM5.247.955c.501-.002 1.003-.002 1.504 0 .407.002.662.306.602.72H4.647c-.06-.415.194-.718.601-.72zm6.238.728H8.325V1.55C8.312.676 7.645.01 6.775.003a91.908 91.908 0 00-1.577 0 1.523 1.523 0 00-1.483 1.214c-.032.148-.037.302-.056.466H.523c-.234 0-.407.107-.485.296-.131.316.093.649.45.66.254.006.509 0 .772 0 .012.065.022.112.029.16.157 1.104.313 2.208.471 3.311.216 1.512.435 3.023.649 4.535.108.766.734 1.344 1.506 1.349 1.395.008 2.79.008 4.185 0a1.514 1.514 0 001.469-1.245c.067-.374.114-.751.168-1.127.15-1.044.297-2.088.447-3.131l.453-3.168c.032-.226.066-.452.1-.683h.148c.206 0 .412.003.618-.001.225-.004.393-.12.464-.308.123-.326-.114-.647-.481-.648z"
          fill="currentColor"
          mask="url(#mask-delete-icon)"
        />
      </g>
    </g>
  </svg>
)

export default DeleteIcon
