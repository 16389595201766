import React, { Component } from 'react'

import PropTypes from 'prop-types'

import AspectRatioWrapper from '../AspectRatioWrapper'
import LayoutTitle from '../LayoutTitle'

import ImageJuxtBody from './ImageJuxtBody'
import ImageJuxtFrame from './ImageJuxtFrame'
import {
  InteractiveContainer,
  LeftColumn,
  RightColumn,
  TextContainer,
} from './style'

class ImageJuxtComponent extends Component {
  getRenderProps = () => {
    const {
      bodyText,
      headerText,
      imageLeftAltText,
      imageLeftUrl,
      imageRightAltText,
      imageRightUrl,
      onDrag,
      onDragEnd,
      onKeyDown,
      position,
      receiveFrameMeasurements: updateFrameMeasurements,
      scale,
    } = this.props
    const headerProps = {
      title: headerText,
    }
    const bodyProps = {
      text: bodyText,
    }
    const frameProps = {
      imageLeftAltText,
      imageLeftUrl,
      imageRightAltText,
      imageRightUrl,
      onDrag,
      onDragEnd,
      onKeyDown,
      position,
      scale,
      updateFrameMeasurements,
    }

    return { bodyProps, frameProps, headerProps }
  }

  render() {
    const { bodyProps, frameProps, headerProps } = this.getRenderProps() || {}

    return (
      <InteractiveContainer>
        <LeftColumn>
          <TextContainer>
            <LayoutTitle {...headerProps} />
            <ImageJuxtBody {...bodyProps} />
          </TextContainer>
        </LeftColumn>
        <RightColumn>
          <AspectRatioWrapper
            render={({ height, width }) => (
              <ImageJuxtFrame {...frameProps} height={height} width={width} />
            )}
          />
        </RightColumn>
      </InteractiveContainer>
    )
  }
}

ImageJuxtComponent.propTypes = {
  bodyText: PropTypes.string,
  headerText: PropTypes.string,
  imageLeftAltText: PropTypes.string,
  imageLeftUrl: PropTypes.string,
  imageRightAltText: PropTypes.string,
  imageRightUrl: PropTypes.string,
  onDrag: PropTypes.func,
  onDragEnd: PropTypes.func,
  onKeyDown: PropTypes.func,
  position: PropTypes.number,
  receiveFrameMeasurements: PropTypes.func,
  scale: PropTypes.number,
}

ImageJuxtComponent.defaultProps = {
  onDrag() {},
  onDragEnd() {},
  onKeyDown() {},
  position: 0,
  receiveFrameMeasurements() {},
  scale: 1,
}

export default ImageJuxtComponent
