import React from 'react'
import ReactModal from 'react-modal'
import PropTypes from 'prop-types'
import { StyledReactModal, ModalContentWrapper } from './component.styles'

ReactModal.setAppElement('body')

const BaseModal = props => {
  const {
    children,
    className,
    dataTestId,
    isModalOpen,
    modalClose,
    contentLabel,
  } = props
  return (
    <StyledReactModal
      ariaHideApp={false}
      bodyOpenClassName="portalOpen"
      contentLabel={contentLabel || ''}
      isModalOpen={isModalOpen}
      modalClose={modalClose}
      portalClassName={className}
    >
      <ModalContentWrapper dataTestId={dataTestId}>
        {children}
      </ModalContentWrapper>
    </StyledReactModal>
  )
}

BaseModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
  contentLabel: PropTypes.string,
  dataTestId: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool,
  modalClose: PropTypes.func,
}

export default BaseModal
