import styled from 'styled-components'

export const BodyTextContainer = styled.div`
  display: block;
  margin: 1.25rem 0;
`

export const EvaluationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 2.5em;
  width: 100%;
`

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  min-height: 475px;
  width: 100%;
`

export const InteractiveContainer = styled.div.attrs(
  ({
    theme: {
      colorVars: { dragQuestion: colors },
    },
  }) => ({
    background: colors.container.background,
  }),
)`
  align-items: center;
  background-color: ${props => props.background};
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;
`

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 0.625em;
  width: 32%;
`

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 0.625em;
  width: 66%;
`
