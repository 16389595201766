import React, { Component } from 'react'

import PropTypes from 'prop-types'

import LayoutTitle from '../LayoutTitle'
import Modules from '../modules'

import { Container } from './style'

class LayoutElementComponent extends Component {
  getRenderProps = () => {
    const {
      accessibilityLabel,
      isModal,
      isDoneSlide,
      modules,
      onSubmit,
      slideBackgroundColor,
      title,
      theme,
    } = this.props
    const containerProps = { accessibilityLabel, isModal, isDoneSlide, theme }
    const modulesProps = {
      backgroundColor: slideBackgroundColor,
      modules,
      onSubmit,
    }
    const titleComponent = title ? (
      <LayoutTitle isDoneSlide={isDoneSlide} title={title} />
    ) : null
    const renderProps = {
      containerProps,
      modulesProps,
      titleComponent,
    }

    return renderProps
  }

  render = () => {
    const {
      containerProps,
      modulesProps,
      titleComponent,
    } = this.getRenderProps()

    return (
      <Container {...containerProps}>
        {titleComponent}
        <Modules {...modulesProps} />
      </Container>
    )
  }
}

LayoutElementComponent.propTypes = {
  accessibilityLabel: PropTypes.string,
  isModal: PropTypes.bool,
  isDoneSlide: PropTypes.bool,
  modules: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func,
  slideBackgroundColor: PropTypes.string,
  title: PropTypes.string,
  theme: PropTypes.object,
}

LayoutElementComponent.defaultProps = {
  modules: [],
  onSubmit() {},
}

export default LayoutElementComponent
