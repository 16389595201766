import React, { useEffect, useState } from 'react'

import { hasParentApiInstance } from 'lib/APIConnector'
import { playerIsSessionActiveSelector } from 'lib/state/selectors'
import { useSelector } from 'react-redux'
import { getPlayerSessionTimeManager } from 'utils/sessionHelper'

import ExitLessonComponent from './component'

const ExitLessonContainer = () => {
  const [didClick, setDidClick] = useState(false)
  const isSessionActive = useSelector(playerIsSessionActiveSelector)

  const closeWindow = () => {
    setDidClick(false)
    top?.window?.close()
  }

  useEffect(() => {
    const didDeactivateSession = isSessionActive === false

    if (didClick && didDeactivateSession) {
      console.info('LMS terminated. Closing player window.')

      closeWindow()
    }
  }, [isSessionActive])

  const onClick = () => {
    setDidClick(true)

    if (hasParentApiInstance()) {
      const sessionTimeManager = getPlayerSessionTimeManager()
      const options = { terminateWithApi: true }

      sessionTimeManager.stopListeners()
      sessionTimeManager.stopPoll(options) //Last call in stop poll callback is toggleSession. This triggers the window to close.
    } else {
      closeWindow()
    }
  }

  return <ExitLessonComponent onClick={onClick} />
}

export default ExitLessonContainer
