import { COMPLETION_STATUS } from '@cfc/clifford/dist/enums'
import deepmerge from 'deepmerge'
import { isLocaleEnglishUS } from 'utils/locale'
import {
  AI_PRESENTATION_MARK_DONE_KEY,
  sendApplicationInsightsEvent,
} from 'utils/sendAnalyticsDataHelper'

import { SLIDE_STATUS } from '../../../lib/state/constants'
import { DURATION_ZERO_ISO } from '../../../utils/dateHelper'

const initialState = {
  completion_status: COMPLETION_STATUS.Unknown,
  progress_measure: 0,
  session_time: DURATION_ZERO_ISO,
  suspend_data: '',
}

function hydrateCmi(state, action) {
  const cmi = deepmerge(state, action.data)

  return cmi
}

function updateCmiCompletionStatus(state, { completionStatus }) {
  if (completionStatus === 'completed') {
    sendApplicationInsightsEvent(AI_PRESENTATION_MARK_DONE_KEY)
  }
  return {
    ...state,
    completion_status: completionStatus,
  }
}

function updateCmiSessionTime(state, { sessionTime }) {
  return {
    ...state,
    session_time: sessionTime,
  }
}

function updateCmiSlides(state, { slides }) {
  return {
    ...state,
    suspend_data: {
      ...state.suspend_data,
      slides: {
        ...state.suspend_data.slides,
        ...slides,
      },
    },
  }
}

function updateCmiInteractives(state, { interactives }) {
  const locale = state.student_preference?.language

  // If not default en-US
  if (!isLocaleEnglishUS(locale)) {
    const localeState =
      state.suspend_data?.interactivesLocalized &&
      state.suspend_data?.interactivesLocalized[locale]
        ? state.suspend_data?.interactivesLocalized[locale]
        : {}

    return {
      ...state,
      suspend_data: {
        ...state.suspend_data,
        interactives: {
          ...state.suspend_data.interactives,
        },
        interactivesLocalized: {
          ...state.suspend_data?.interactivesLocalized,
          [locale]: {
            ...localeState,
            ...interactives,
          },
        },
      },
    }
  }

  // else default en-US
  return {
    ...state,
    suspend_data: {
      ...state.suspend_data,
      interactives: {
        ...state.suspend_data.interactives,
        ...interactives,
      },
    },
  }
}

function updateCmiLocale(state, { locale }) {
  return {
    ...state,
    student_preference: {
      ...state.student_preference,
      language: locale,
    },
  }
}

function updateCmiSuspendData(state, { interactives, slide }) {
  return {
    ...state,
    suspend_data: {
      ...state.suspend_data,
      interactives: {
        ...state.suspend_data.interactives,
        ...interactives,
      },
      slides: {
        ...state.suspend_data.slides,
        [slide]: SLIDE_STATUS.COMPLETED,
      },
    },
  }
}

function updateCmiProgress(state, { progressMeasure }) {
  let { completion_status: completionStatus } = state || {}

  if (state.completion_threshold) {
    //Currently only reachable in test conditions. We do not use completion_threshold
    //in live environment at this time.
    completionStatus =
      progressMeasure >= state.completion_threshold
        ? COMPLETION_STATUS.Completed
        : COMPLETION_STATUS.Incomplete
  }

  return {
    ...state,
    completion_status: completionStatus,
    progress_measure: progressMeasure,
  }
}

function updateCmiLocation(state, { slidePosition }) {
  return {
    ...state,
    location: slidePosition,
  }
}

function createReducer(initialState, handlers) {
  return function reducer(state = initialState, action) {
    if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
      return handlers[action.type](state, action)
    }

    return state
  }
}

const cmiReducer = createReducer(initialState, {
  HYDRATE_CMI: hydrateCmi,
  UPDATE_CMI_COMPLETION_STATUS: updateCmiCompletionStatus,
  UPDATE_CMI_INTERACTIVES: updateCmiInteractives,
  UPDATE_CMI_LOCALE: updateCmiLocale,
  UPDATE_CMI_LOCATION: updateCmiLocation,
  UPDATE_CMI_PROGRESS: updateCmiProgress,
  UPDATE_CMI_SESSION_TIME: updateCmiSessionTime,
  UPDATE_CMI_SLIDES: updateCmiSlides,
  UPDATE_CMI_SUSPEND_DATA: updateCmiSuspendData,
})

export default cmiReducer
