import styled from 'styled-components'
import ProgressStatus from '../ProgressStatus'

const ProgressStatusSmall = styled(ProgressStatus)`
  font-size: 0.875rem;
  line-height: 0.875rem;
`

ProgressStatusSmall.displayName = 'ProgressStatusSmall'

export default ProgressStatusSmall
