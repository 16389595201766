import { grommet } from 'grommet/themes'
import { deepMerge } from 'grommet/utils'

import {
  allGradesBackground,
  allGradesForeground,
  blueFocus,
  bodySizes,
  breakpoints,
  disabledButtonColor,
  enabledButtonColor,
  fontColor,
  fontColorSecondary,
  fontWeightNormal,
  headingLevels,
  hoverButtonColor,
  hoverButtonFontColor,
  headingMarginExtension,
  hoverFontColor,
  iconColor,
  iconColorLight,
  modalBodyCopyColor,
  secondStepBlue,
  singleGradeBackground,
  singleGradeForeground,
} from './defaultValues'

const grommetBaseTheme = {
  global: {
    anchor: {
      color: secondStepBlue,
    },
    font: {
      family: 'Molde-regular',
      size: '16px',
    },
    colors: {
      placeholder: '#666666E2',
    },
  },

  colors: {
    icon: {
      light: iconColorLight,
    },
    input: {
      border: disabledButtonColor,
    },
    progressBar: {
      background: {
        small: '#dddddd',
        medium: '#eeeeee',
      },
    },
    lessonReportWidget: {
      background: {
        lightBlue: allGradesBackground,
        blue: singleGradeBackground,
      },
      foreground: {
        navyBlue: allGradesForeground,
        teal: singleGradeForeground,
      },
      innerWrapper: {
        background: hoverButtonFontColor,
      },
    },
    video: {
      hover: {
        outline: blueFocus,
      },
    },
  },

  anchor: {
    color: secondStepBlue,
    extend: 'font-family: Molde-medium',
    fontWeight: fontWeightNormal,
  },
  heading: {
    extend: headingMarginExtension,
    font: {
      family: 'Molde-Semibold',
    },
    level: headingLevels,
  },
  paragraph: bodySizes,
  progressBar: {
    borderRadius: '0.35rem',
    width: {
      small: '7.19rem',
      medium: '10rem',
    },
  },
  select: {
    colors: {
      primary: secondStepBlue,
      secondary: 'white',
    },
    font: 'Molde-Semibold',
    icons: {
      margin: '0 0.75rem',
    },
    options: {
      container: {
        margin: '0',
        overflow: 'hidden',
        pad: '0.375rem 1.125rem 0.25rem',
      },
    },
  },
  spacing: {
    pink8_0_5rem: '0.5rem',
    green4_0_25rem: '0.25rem',
  },
  text: bodySizes,
}

export const baseTheme = {
  breakpoints: breakpoints,
  fontFamily: 'Molde-regular',
  fontFamilySemibold: 'Molde-medium',
  fontFamilyBold: 'Molde-Semibold',

  fontFamilyHeading: 'SharpSlab',
  fontFamilyHeadingMedium: 'SharpSlab-Medium',
  fontFamilyHeadingBold: 'SharpSlab-Bold',
  fontFamilyHeadingExtraBold: 'SharpSlab-Extrabold',

  colors: {
    buttons: {
      primaryBackground: enabledButtonColor,
      primaryBackgroundDisabled: disabledButtonColor,
      primaryBackgroundHover: hoverButtonColor,
      primaryFont: '#ffffff',
      resumeBackground: enabledButtonColor,
      resumeBackgroundHover: hoverButtonColor,
      reviewBackground: enabledButtonColor,
      reviewBackgroundHover: hoverButtonColor,
      secondaryFont: fontColorSecondary,
      startBackground: enabledButtonColor,
      startBackgroundHover: hoverButtonColor,
      iconBase: enabledButtonColor,
      iconHover: hoverButtonColor,
      tabActive: hoverButtonColor,
      tabDefault: fontColor,
    },
    heading: {
      level: {
        1: fontColor,
        2: fontColor,
        3: fontColor,
        4: fontColor,
        5: fontColor,
        6: fontColor,
      },
    },
    inputLabel: fontColorSecondary,
    modals: {
      background: 'rgba(50, 82, 118, 0.85)',
      subHeader: modalBodyCopyColor,
      bodyCopy: modalBodyCopyColor,
    },
    cards: {
      background: '#EFF7FB',
      foreground: 'inherit',
    },
    themePrimary: iconColor,
    themeSecondary: fontColorSecondary,
    themeAccent: hoverFontColor,
    themeHighlight: iconColorLight,
    white: '#fff',
  },
  buttons: {
    borderRadius: '5px',
  },
  cards: {
    borderRadius: '12px',
  },
  anchor: {
    color: secondStepBlue,
  },
}

const mergedTheme = deepMerge(grommet, grommetBaseTheme, baseTheme)
export default mergedTheme
