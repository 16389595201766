import React from 'react'
import PropTypes from 'prop-types'

const BackpackIcon = ({ fill, title, ...rest }) => (
  <svg {...rest}>
    {title && <title>{title}</title>}
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        d="M10.277 11.826H1.722l.001-.709c.002-.521.368-.871.904-.871 2.248-.002 4.496-.002 6.743 0 .542 0 .906.352.907.88v.7m0 3.71c-.001.429-.252.75-.655.844a1.364 1.364 0 01-.307.026c-1.106.002-2.213.002-3.32.002-1.11 0-2.22-.002-3.331 0-.303 0-.561-.08-.755-.32a.843.843 0 01-.186-.55c-.001-.95-.002-1.902-.001-2.853h6.347l.29 1.1a.352.352 0 00.43.247.342.342 0 00.254-.418l-.245-.929h1.479c0 .95.002 1.9 0 2.851M7.233 6.314l-1.001.974a.335.335 0 01-.464 0l-1-.974a.313.313 0 010-.45l1-.975a.335.335 0 01.464 0l1 .974a.313.313 0 010 .451M6 .858c.765 0 1.396.546 1.515 1.258a6.98 6.98 0 00-3.03-.006A1.527 1.527 0 016 .858m5.996 6.716a6.75 6.75 0 00-.07-.92 5.012 5.012 0 00-.781-2.052c-.688-1.05-1.51-1.77-2.725-2.218l.001-.028C8.421 1.056 7.335 0 6 0 4.665 0 3.578 1.057 3.578 2.356l.002.034A5.546 5.546 0 00.84 4.626C.266 5.518 0 6.503 0 7.556c.002 1.664 0 2.597 0 4.26 0 1.44.002 2.88 0 4.32 0 .406.099.781.349 1.109.392.513.925.755 1.578.755h8.14c.06 0 .122 0 .182-.005a1.87 1.87 0 001.104-.436c.445-.376.648-.856.647-1.427-.003-1.57-.001-3.14-.001-4.711 0-1.526.004-2.322-.003-3.847"
        fill={fill}
      />
    </g>
  </svg>
)

BackpackIcon.defaultProps = {
  title: null,
  height: '18px',
  width: '12px',
  fill: 'currentColor',
  viewBox: '0 0 12 18',
}
BackpackIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  title: PropTypes.string,
  viewBox: PropTypes.string,
  width: PropTypes.string,
}
export default BackpackIcon
