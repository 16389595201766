import {
  getConstantByLocale,
  getLocaleFromSession,
  LOCALES,
} from 'utils/locale'

export const BUTTON_STATES = {
  ON_RESET: { checkDisabled: false, resetDisabled: true },
  ON_CHECK: { checkDisabled: true, resetDisabled: false },
  ON_DROP: { checkDisabled: false, resetDisabled: false },
}
export const DRAG_AND_DROP_TYPES = {
  DRAG_LABEL: 'dragLabel',
  DRAG_QUESTION: 'dragQuestion',
  DRAG_WORD: 'dragWord',
}
export const DRAG_QUESTION_ERROR = {
  [LOCALES.EN_US]:
    'To continue, drag each item on the left to a spot on the right',
  [LOCALES.ES_US]:
    'Para continuar, arraste cada elemento de la izquierda a un espacio de la derecha',
}
export const DRAG_WORD_ERROR = {
  [LOCALES.EN_US]: 'To continue, drag each of the top items to a spot below',
  [LOCALES.ES_US]:
    'Para continuar, arraste cada elemento de arriba a un espacio más abajo',
}
export const DRAG_LABEL_ERROR = {
  [LOCALES.EN_US]: 'To continue, drag each item to an open spot on the image',
  [LOCALES.ES_US]:
    'Para continuar arraste cada artículo a un lugar abierto en la imagen',
}
const currentLocale = getLocaleFromSession()
const dragQuestionHelperText = getConstantByLocale(
  DRAG_QUESTION_ERROR,
  currentLocale,
)
const dragWordHelperText = getConstantByLocale(DRAG_WORD_ERROR, currentLocale)
const dragLabelHelperText = getConstantByLocale(DRAG_LABEL_ERROR, currentLocale)
export const DRAG_AND_DROP_ERRORS = {
  DRAG_LABEL: dragLabelHelperText,
  DRAG_QUESTION: dragQuestionHelperText,
  DRAG_WORD: dragWordHelperText,
}

export const getDraggableWithId = (draggables, draggableId) => {
  const matchingDraggables = draggables.reduce(
    (accumulator, draggable, index) => {
      const { id: currentDraggableId } = draggable || {}

      if (currentDraggableId === draggableId) {
        const draggableWithIndex = {
          ...draggable,
          index,
        }

        accumulator.push(draggableWithIndex)
      }
      return accumulator
    },
    [],
  )
  const draggable = matchingDraggables ? matchingDraggables[0] : {}

  return draggable
}
