import React from 'react'

import PropTypes from 'prop-types'

import { TranscriptWrapper } from './component.styles'
import { htmlifyTranscript } from './format'

const TranscriptComponent = props => {
  const { transcript, audioDescriptionTranscript, isAudioDescActive } =
    props || {}
  const formattedTranscript = transcript ? htmlifyTranscript(transcript) : ''
  const formattedAudioDescriptionTranscript = audioDescriptionTranscript
    ? htmlifyTranscript(audioDescriptionTranscript)
    : ''
  const activeTranscript = isAudioDescActive
    ? formattedAudioDescriptionTranscript
    : formattedTranscript
  return (
    <TranscriptWrapper
      className="transcript"
      dangerouslySetInnerHTML={{ __html: activeTranscript }}
    />
  )
}
TranscriptComponent.propTypes = {
  transcript: PropTypes.string,
}

export default TranscriptComponent
