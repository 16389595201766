import PropTypes from 'prop-types'
import styled from 'styled-components'
import { themeGet } from 'styled-system'

const BaseContainer = styled.div.attrs(({ boxShadow, dataTestId, theme }) => {
  return {
    boxShadowValue: boxShadow
      ? `1px 1px 10px 1px ${theme.colors.boxShadow}`
      : 'none',
    'data-testid': dataTestId,
  }
})`
  background-color: ${themeGet('colors.white')};
  border-radius: 0.75rem;
  box-shadow: ${({ boxShadowValue }) => boxShadowValue};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  padding: 1.5rem 1.75rem 1.75rem;
`

BaseContainer.propTypes = {
  boxShadow: PropTypes.bool,
  dataTestId: PropTypes.string.isRequired,
}

export default BaseContainer
