import React from 'react'
import PropTypes from 'prop-types'
import { toUrlSafe } from '../../utils/stringHelpers'
import { CheckBoxOptionsContainer, FormFieldCheckBox } from './component.styles'
import CheckBox from '../CheckBox'

const FormInputCheckBox = ({
  className,
  dataTestId,
  inputErrors,
  label,
  options,
  onChange,
  selected = {},
}) => {
  if (!onChange || !options) {
    return null
  }
  return (
    <FormFieldCheckBox
      className={className}
      dataTestId={dataTestId}
      inputErrors={inputErrors}
      label={label}
    >
      <CheckBoxOptionsContainer>
        {options &&
          options.map(choiceText => (
            <CheckBox
              alternateId={dataTestId}
              dataTestId={`input-checkbox-${toUrlSafe(choiceText)}`}
              isChecked={selected[choiceText]}
              key={`checkbox-${choiceText}`}
              onChange={onChange}
              text={choiceText}
            />
          ))}
      </CheckBoxOptionsContainer>
    </FormFieldCheckBox>
  )
}

FormInputCheckBox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  inputErrors: PropTypes.object,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selected: PropTypes.object,
}
export default FormInputCheckBox
