/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'
import PropTypes from 'prop-types'

const ClockIcon = ({ color = 'currentColor' }) => {
  return (
    <svg
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g stroke={color}>
          <circle cx="8.7" cy="8.7" r="7.7" strokeWidth="1.5" />
          <path
            d="M13.5280381 6.97753462L11.3671338 9.59081822 6.48524552 9.59081822"
            strokeLinecap="round"
            strokeWidth="1.75"
            transform="rotate(90 10.007 8.284)"
          />
        </g>
      </g>
    </svg>
  )
}

ClockIcon.propTypes = {
  color: PropTypes.string,
}

export default ClockIcon
