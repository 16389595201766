export const ARROW_IMAGE_SIZE = '16px'
export const ARROW_IMAGE_DATA_TEST_ID = 'arrow-image'
export const CLICK = 'click'
export const DEFAULT_DETAILED_MESSAGE =
  'We encountered an error. Please try again in a few minutes.'
export const DETAILS_BUTTON_DATA_TEST_ID = 'details-button'
export const DETAILS_TEXT_DATA_TEST_ID = 'error-details-text'
export const DOWN_POSITION = '0deg'
export const ENTER_KEY = 'Enter'
export const HIDE_DETAILS = 'Hide Details'
export const NEW_LINE = '\n'
export const SHOW_DETAILS = 'Show Details'
export const SPACE_KEY = ' '
export const TITLE = "Something's gone wrong"
export const UP_POSITION = '-180deg'
