/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

export default () => (
  <svg
    height="19px"
    version="1.1"
    viewBox="0 0 23 19"
    width="23px"
    x="0px"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
  >
    <g>
      <g transform="translate(-305.000000, -269.000000)">
        <g transform="translate(40.000000, 40.000000)">
          <g transform="translate(27.000000, 138.547350)">
            <g
              id="academic-success-icon"
              transform="translate(238.000000, 90.000000)"
            >
              <g transform="translate(0.000000, 0.285519)">
                <defs>
                  <filter
                    filterUnits="userSpaceOnUse"
                    height="13.4"
                    width="21.7"
                    x="0"
                    y="0.1"
                  >
                    <feColorMatrix
                      type="matrix"
                      values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                    />
                  </filter>
                </defs>
                <mask
                  height="13.4"
                  maskUnits="userSpaceOnUse"
                  width="21.7"
                  x="0"
                  y="0.1"
                >
                  <g className="st0">
                    <polygon
                      className="st1"
                      points="0,0.1 21.7,0.1 21.7,13.5 0,13.5 								"
                    />
                  </g>
                </mask>
                <path
                  className="st2"
                  d="M0.2,6.9l10.2,6.6c0.1,0.1,0.2,0.1,0.4,0l10.9-6.7c0.2-0.1,0.2-0.4,0-0.6L10.9,0.1
							c-0.1-0.1-0.2-0.1-0.3,0L0.2,6.4C-0.1,6.5-0.1,6.8,0.2,6.9"
                  fill="currentColor"
                />
              </g>
              <g transform="translate(3.120000, 10.815519)">
                <defs>
                  <filter
                    filterUnits="userSpaceOnUse"
                    height="8.4"
                    width="14.3"
                    x="0.3"
                    y="0.1"
                  >
                    <feColorMatrix
                      type="matrix"
                      values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                    />
                  </filter>
                </defs>
                <mask
                  height="8.4"
                  maskUnits="userSpaceOnUse"
                  width="14.3"
                  x="0.3"
                  y="0.1"
                >
                  <g className="st3">
                    <polygon
                      className="st1"
                      points="0.3,0.1 14.7,0.1 14.7,8.6 0.3,8.6 								"
                    />
                  </g>
                </mask>
                <path
                  className="st4"
                  d="M8.3,4.1C8.1,4.3,7.8,4.3,7.6,4.4c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0
							c0,0,0,0-0.1,0c-0.2,0-0.4-0.1-0.6-0.2l-6.2-4v4.4c0,0.5,0.2,1,0.6,1.4c1.7,1.6,6.9,5.3,13.1,0c0.4-0.3,0.6-0.9,0.6-1.4V0.2
							L8.3,4.1z"
                  fill="currentColor"
                />
              </g>
              <g transform="translate(19.500000, 8.475519)">
                <defs>
                  <filter
                    filterUnits="userSpaceOnUse"
                    height="10"
                    width="2.9"
                    x="0.1"
                    y="0.2"
                  >
                    <feColorMatrix
                      type="matrix"
                      values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                    />
                  </filter>
                </defs>
                <mask
                  height="10"
                  maskUnits="userSpaceOnUse"
                  width="2.9"
                  x="0.1"
                  y="0.2"
                >
                  <g className="st5">
                    <polygon
                      className="st1"
                      points="0.1,0.2 3,0.2 3,10.2 0.1,10.2 								"
                    />
                  </g>
                </mask>
                <path
                  className="st6"
                  d="M2.1,4.7C2.1,4.6,2.1,4.6,2.1,4.7c0-1.5,0-3.6,0-4.4l-1,0.6c0,0.8,0,1.5,0,2.3l0,0
							c0,0.5,0,1.1,0,1.6C1,4.8,1,4.9,1,5C0.7,6.4,0.4,7.7,0.1,9.1C0,9.5,0.2,9.8,0.5,10c0.7,0.3,1.4,0.3,2.1,0C3,9.8,3.1,9.5,3,9.1
							C2.7,7.7,2.4,6.2,2.1,4.7"
                  fill="currentColor"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
