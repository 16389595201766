import styled from 'styled-components'

import { BaseContainer, BaseHoverIndicator } from '../../DraggableBase/style'

export { BaseGrip as Grip, BaseText as Text } from '../../DraggableBase/style'

export const Container = styled(BaseContainer).attrs(
  ({
    isDragging,
    isInDropZone,
    theme: {
      colorVars: { dragAndDrop: dragAndDropColors, dragWord: dragWordColors },
    },
  }) => ({
    backgroundColor:
      isInDropZone && isDragging
        ? dragWordColors.draggable.backgroundColorInactive
        : dragAndDropColors.draggable.backgroundColor,
    boxShadow: `4px 4px 4px ${dragAndDropColors.draggable.boxShadowColor}`,
    style: {
      border: `1px solid ${dragAndDropColors.draggable.borderColor}`,
      height: '45px',
      width: '253px',
    },
  }),
)`
  align-items: center;
  background-color: ${props => props.backgroundColor};
  position: absolute;

  :last-child {
    margin-right: 0;
  }

  :focus,
  :hover {
    box-shadow: ${props => props.boxShadow};
  }
`

export const HoverIndicator = styled(BaseHoverIndicator).attrs(
  ({
    isHovered,
    isInDropZone,
    theme: {
      colorVars: { dragAndDrop: colors },
    },
  }) => ({
    style: {
      backgroundColor:
        isInDropZone && !isHovered
          ? colors.hoverIndicator.colorNoHover
          : isHovered
          ? colors.hoverIndicator.colorHover
          : colors.hoverIndicator.colorNoHover,
      borderRadius: '3px 0 0 3px',
    },
  }),
)``
